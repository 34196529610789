import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AppConstants } from '../../constants';

@Component({
  selector: 'app-custom-view',
  templateUrl: './custom-view.component.html',
  styleUrl: './custom-view.component.scss'
})
export class CustomViewComponent implements ICellRendererAngularComp {
  content: string = '';
  toolTip: string = '';
  params: any;
  isDisabled: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (params.data.recipeName && params.data.recipeName !== "") {
      this.content = params.data.recipeName;
      this.toolTip = AppConstants.ViewRecipeDetails;
      if (params.data.recipeName[0] === 'C') {
        this.isDisabled = true;
        this.toolTip = 'Recipe details view is not avaliable for competitive recipe'
      }
    }
    else if (params.data.countryLabelCount !== undefined && params.data.countryLabelCount !== "") {
      this.content = params.data.countryLabelCount;
      this.toolTip = AppConstants.ViewIngredientLabelDetails;
    }
    else {
      this.content = '';
      this.toolTip = '';
    }
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  buttonClicked() {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        rowData: this.params.data
      }
      this.params.onClick(params);
    }
  }
}
