"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PowerRatio = exports.PowerRatioUnits = void 0;
const base_unit_1 = require("../base-unit");
/** PowerRatioUnits enumeration */
var PowerRatioUnits;
(function (PowerRatioUnits) {
  /** */
  PowerRatioUnits["DecibelWatts"] = "DecibelWatt";
  /** */
  PowerRatioUnits["DecibelMilliwatts"] = "DecibelMilliwatt";
})(PowerRatioUnits = exports.PowerRatioUnits || (exports.PowerRatioUnits = {}));
/** The strength of a signal expressed in decibels (dB) relative to one watt. */
class PowerRatio extends base_unit_1.BaseUnit {
  /**
   * Create a new PowerRatio.
   * @param value The value.
   * @param fromUnit The ‘PowerRatio’ unit to create from.
   * The default unit is DecibelWatts
   */
  constructor(value, fromUnit = PowerRatioUnits.DecibelWatts) {
    super();
    this.decibelwattsLazy = null;
    this.decibelmilliwattsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of PowerRatio is DecibelWatts.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return PowerRatioUnits.DecibelWatts;
  }
  /** */
  get DecibelWatts() {
    if (this.decibelwattsLazy !== null) {
      return this.decibelwattsLazy;
    }
    return this.decibelwattsLazy = this.convertFromBase(PowerRatioUnits.DecibelWatts);
  }
  /** */
  get DecibelMilliwatts() {
    if (this.decibelmilliwattsLazy !== null) {
      return this.decibelmilliwattsLazy;
    }
    return this.decibelmilliwattsLazy = this.convertFromBase(PowerRatioUnits.DecibelMilliwatts);
  }
  /**
   * Create a new PowerRatio instance from a DecibelWatts
   *
   * @param value The unit as DecibelWatts to create a new PowerRatio from.
   * @returns The new PowerRatio instance.
   */
  static FromDecibelWatts(value) {
    return new PowerRatio(value, PowerRatioUnits.DecibelWatts);
  }
  /**
   * Create a new PowerRatio instance from a DecibelMilliwatts
   *
   * @param value The unit as DecibelMilliwatts to create a new PowerRatio from.
   * @returns The new PowerRatio instance.
   */
  static FromDecibelMilliwatts(value) {
    return new PowerRatio(value, PowerRatioUnits.DecibelMilliwatts);
  }
  /**
   * Gets the base unit enumeration associated with PowerRatio
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return PowerRatioUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return PowerRatioUnits.DecibelWatts;
  }
  /**
   * Create API DTO represent a PowerRatio unit.
   * @param holdInUnit The specific PowerRatio unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = PowerRatioUnits.DecibelWatts) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a PowerRatio unit from an API DTO representation.
   * @param dtoPowerRatio The PowerRatio API DTO representation
   */
  static FromDto(dtoPowerRatio) {
    return new PowerRatio(dtoPowerRatio.value, dtoPowerRatio.unit);
  }
  /**
   * Convert PowerRatio to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case PowerRatioUnits.DecibelWatts:
        return this.DecibelWatts;
      case PowerRatioUnits.DecibelMilliwatts:
        return this.DecibelMilliwatts;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case PowerRatioUnits.DecibelWatts:
        return this.value;
      case PowerRatioUnits.DecibelMilliwatts:
        return super.internalAdd(this.value, 30);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case PowerRatioUnits.DecibelWatts:
        return this.value;
      case PowerRatioUnits.DecibelMilliwatts:
        return this.value + 30;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case PowerRatioUnits.DecibelWatts:
        return value;
      case PowerRatioUnits.DecibelMilliwatts:
        return super.internalSubtract(value, 30);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case PowerRatioUnits.DecibelWatts:
        return value;
      case PowerRatioUnits.DecibelMilliwatts:
        return value - 30;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the PowerRatio to string.
   * Note! the default format for PowerRatio is DecibelWatts.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the PowerRatio.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the PowerRatio.
   */
  toString(unit = PowerRatioUnits.DecibelWatts, fractionalDigits) {
    switch (unit) {
      case PowerRatioUnits.DecibelWatts:
        return super.truncateFractionDigits(this.DecibelWatts, fractionalDigits) + ` dBW`;
      case PowerRatioUnits.DecibelMilliwatts:
        return super.truncateFractionDigits(this.DecibelMilliwatts, fractionalDigits) + ` dBmW`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get PowerRatio unit abbreviation.
   * Note! the default abbreviation for PowerRatio is DecibelWatts.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the PowerRatio.
   * @returns The abbreviation string of PowerRatio.
   */
  getUnitAbbreviation(unitAbbreviation = PowerRatioUnits.DecibelWatts) {
    switch (unitAbbreviation) {
      case PowerRatioUnits.DecibelWatts:
        return `dBW`;
      case PowerRatioUnits.DecibelMilliwatts:
        return `dBmW`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given PowerRatio are equals to the current PowerRatio.
   * @param powerRatio The other PowerRatio.
   * @returns True if the given PowerRatio are equal to the current PowerRatio.
   */
  equals(powerRatio) {
    return super.internalEquals(this.value, powerRatio.BaseValue);
  }
  /**
   * Compare the given PowerRatio against the current PowerRatio.
   * @param powerRatio The other PowerRatio.
   * @returns 0 if they are equal, -1 if the current PowerRatio is less then other, 1 if the current PowerRatio is greater then other.
   */
  compareTo(powerRatio) {
    return super.internalCompareTo(this.value, powerRatio.BaseValue);
  }
  /**
   * Add the given PowerRatio with the current PowerRatio.
   * @param powerRatio The other PowerRatio.
   * @returns A new PowerRatio instance with the results.
   */
  add(powerRatio) {
    return new PowerRatio(super.internalAdd(this.value, powerRatio.BaseValue));
  }
  /**
   * Subtract the given PowerRatio with the current PowerRatio.
   * @param powerRatio The other PowerRatio.
   * @returns A new PowerRatio instance with the results.
   */
  subtract(powerRatio) {
    return new PowerRatio(super.internalSubtract(this.value, powerRatio.BaseValue));
  }
  /**
   * Multiply the given PowerRatio with the current PowerRatio.
   * @param powerRatio The other PowerRatio.
   * @returns A new PowerRatio instance with the results.
   */
  multiply(powerRatio) {
    return new PowerRatio(super.internalMultiply(this.value, powerRatio.BaseValue));
  }
  /**
   * Divide the given PowerRatio with the current PowerRatio.
   * @param powerRatio The other PowerRatio.
   * @returns A new PowerRatio instance with the results.
   */
  divide(powerRatio) {
    return new PowerRatio(super.internalDivide(this.value, powerRatio.BaseValue));
  }
  /**
   * Modulo the given PowerRatio with the current PowerRatio.
   * @param powerRatio The other PowerRatio.
   * @returns A new PowerRatio instance with the results.
   */
  modulo(powerRatio) {
    return new PowerRatio(super.internalModulo(this.value, powerRatio.BaseValue));
  }
  /**
   * Pow the given PowerRatio with the current PowerRatio.
   * @param powerRatio The other PowerRatio.
   * @returns A new PowerRatio instance with the results.
   */
  pow(powerRatio) {
    return new PowerRatio(super.internalPow(this.value, powerRatio.BaseValue));
  }
}
exports.PowerRatio = PowerRatio;