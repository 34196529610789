"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpecificVolume = exports.SpecificVolumeUnits = void 0;
const base_unit_1 = require("../base-unit");
/** SpecificVolumeUnits enumeration */
var SpecificVolumeUnits;
(function (SpecificVolumeUnits) {
  /** */
  SpecificVolumeUnits["CubicMetersPerKilogram"] = "CubicMeterPerKilogram";
  /** */
  SpecificVolumeUnits["CubicFeetPerPound"] = "CubicFootPerPound";
  /** */
  SpecificVolumeUnits["MillicubicMetersPerKilogram"] = "MillicubicMeterPerKilogram";
})(SpecificVolumeUnits = exports.SpecificVolumeUnits || (exports.SpecificVolumeUnits = {}));
/** In thermodynamics, the specific volume of a substance is the ratio of the substance's volume to its mass. It is the reciprocal of density and an intrinsic property of matter as well. */
class SpecificVolume extends base_unit_1.BaseUnit {
  /**
   * Create a new SpecificVolume.
   * @param value The value.
   * @param fromUnit The ‘SpecificVolume’ unit to create from.
   * The default unit is CubicMetersPerKilogram
   */
  constructor(value, fromUnit = SpecificVolumeUnits.CubicMetersPerKilogram) {
    super();
    this.cubicmetersperkilogramLazy = null;
    this.cubicfeetperpoundLazy = null;
    this.millicubicmetersperkilogramLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of SpecificVolume is CubicMetersPerKilogram.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return SpecificVolumeUnits.CubicMetersPerKilogram;
  }
  /** */
  get CubicMetersPerKilogram() {
    if (this.cubicmetersperkilogramLazy !== null) {
      return this.cubicmetersperkilogramLazy;
    }
    return this.cubicmetersperkilogramLazy = this.convertFromBase(SpecificVolumeUnits.CubicMetersPerKilogram);
  }
  /** */
  get CubicFeetPerPound() {
    if (this.cubicfeetperpoundLazy !== null) {
      return this.cubicfeetperpoundLazy;
    }
    return this.cubicfeetperpoundLazy = this.convertFromBase(SpecificVolumeUnits.CubicFeetPerPound);
  }
  /** */
  get MillicubicMetersPerKilogram() {
    if (this.millicubicmetersperkilogramLazy !== null) {
      return this.millicubicmetersperkilogramLazy;
    }
    return this.millicubicmetersperkilogramLazy = this.convertFromBase(SpecificVolumeUnits.MillicubicMetersPerKilogram);
  }
  /**
   * Create a new SpecificVolume instance from a CubicMetersPerKilogram
   *
   * @param value The unit as CubicMetersPerKilogram to create a new SpecificVolume from.
   * @returns The new SpecificVolume instance.
   */
  static FromCubicMetersPerKilogram(value) {
    return new SpecificVolume(value, SpecificVolumeUnits.CubicMetersPerKilogram);
  }
  /**
   * Create a new SpecificVolume instance from a CubicFeetPerPound
   *
   * @param value The unit as CubicFeetPerPound to create a new SpecificVolume from.
   * @returns The new SpecificVolume instance.
   */
  static FromCubicFeetPerPound(value) {
    return new SpecificVolume(value, SpecificVolumeUnits.CubicFeetPerPound);
  }
  /**
   * Create a new SpecificVolume instance from a MillicubicMetersPerKilogram
   *
   * @param value The unit as MillicubicMetersPerKilogram to create a new SpecificVolume from.
   * @returns The new SpecificVolume instance.
   */
  static FromMillicubicMetersPerKilogram(value) {
    return new SpecificVolume(value, SpecificVolumeUnits.MillicubicMetersPerKilogram);
  }
  /**
   * Gets the base unit enumeration associated with SpecificVolume
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return SpecificVolumeUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return SpecificVolumeUnits.CubicMetersPerKilogram;
  }
  /**
   * Create API DTO represent a SpecificVolume unit.
   * @param holdInUnit The specific SpecificVolume unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = SpecificVolumeUnits.CubicMetersPerKilogram) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a SpecificVolume unit from an API DTO representation.
   * @param dtoSpecificVolume The SpecificVolume API DTO representation
   */
  static FromDto(dtoSpecificVolume) {
    return new SpecificVolume(dtoSpecificVolume.value, dtoSpecificVolume.unit);
  }
  /**
   * Convert SpecificVolume to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case SpecificVolumeUnits.CubicMetersPerKilogram:
        return this.CubicMetersPerKilogram;
      case SpecificVolumeUnits.CubicFeetPerPound:
        return this.CubicFeetPerPound;
      case SpecificVolumeUnits.MillicubicMetersPerKilogram:
        return this.MillicubicMetersPerKilogram;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case SpecificVolumeUnits.CubicMetersPerKilogram:
        return this.value;
      case SpecificVolumeUnits.CubicFeetPerPound:
        return super.internalMultiply(this.value, 16.01846353);
      case SpecificVolumeUnits.MillicubicMetersPerKilogram:
        return super.internalDivide(this.value, 0.001);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case SpecificVolumeUnits.CubicMetersPerKilogram:
        return this.value;
      case SpecificVolumeUnits.CubicFeetPerPound:
        return this.value * 16.01846353;
      case SpecificVolumeUnits.MillicubicMetersPerKilogram:
        return this.value / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case SpecificVolumeUnits.CubicMetersPerKilogram:
        return value;
      case SpecificVolumeUnits.CubicFeetPerPound:
        return super.internalDivide(value, 16.01846353);
      case SpecificVolumeUnits.MillicubicMetersPerKilogram:
        return super.internalMultiply(value, 0.001);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case SpecificVolumeUnits.CubicMetersPerKilogram:
        return value;
      case SpecificVolumeUnits.CubicFeetPerPound:
        return value / 16.01846353;
      case SpecificVolumeUnits.MillicubicMetersPerKilogram:
        return value * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the SpecificVolume to string.
   * Note! the default format for SpecificVolume is CubicMetersPerKilogram.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the SpecificVolume.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the SpecificVolume.
   */
  toString(unit = SpecificVolumeUnits.CubicMetersPerKilogram, fractionalDigits) {
    switch (unit) {
      case SpecificVolumeUnits.CubicMetersPerKilogram:
        return super.truncateFractionDigits(this.CubicMetersPerKilogram, fractionalDigits) + ` m³/kg`;
      case SpecificVolumeUnits.CubicFeetPerPound:
        return super.truncateFractionDigits(this.CubicFeetPerPound, fractionalDigits) + ` ft³/lb`;
      case SpecificVolumeUnits.MillicubicMetersPerKilogram:
        return super.truncateFractionDigits(this.MillicubicMetersPerKilogram, fractionalDigits) + ` mm³/kg`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get SpecificVolume unit abbreviation.
   * Note! the default abbreviation for SpecificVolume is CubicMetersPerKilogram.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the SpecificVolume.
   * @returns The abbreviation string of SpecificVolume.
   */
  getUnitAbbreviation(unitAbbreviation = SpecificVolumeUnits.CubicMetersPerKilogram) {
    switch (unitAbbreviation) {
      case SpecificVolumeUnits.CubicMetersPerKilogram:
        return `m³/kg`;
      case SpecificVolumeUnits.CubicFeetPerPound:
        return `ft³/lb`;
      case SpecificVolumeUnits.MillicubicMetersPerKilogram:
        return `mm³/kg`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given SpecificVolume are equals to the current SpecificVolume.
   * @param specificVolume The other SpecificVolume.
   * @returns True if the given SpecificVolume are equal to the current SpecificVolume.
   */
  equals(specificVolume) {
    return super.internalEquals(this.value, specificVolume.BaseValue);
  }
  /**
   * Compare the given SpecificVolume against the current SpecificVolume.
   * @param specificVolume The other SpecificVolume.
   * @returns 0 if they are equal, -1 if the current SpecificVolume is less then other, 1 if the current SpecificVolume is greater then other.
   */
  compareTo(specificVolume) {
    return super.internalCompareTo(this.value, specificVolume.BaseValue);
  }
  /**
   * Add the given SpecificVolume with the current SpecificVolume.
   * @param specificVolume The other SpecificVolume.
   * @returns A new SpecificVolume instance with the results.
   */
  add(specificVolume) {
    return new SpecificVolume(super.internalAdd(this.value, specificVolume.BaseValue));
  }
  /**
   * Subtract the given SpecificVolume with the current SpecificVolume.
   * @param specificVolume The other SpecificVolume.
   * @returns A new SpecificVolume instance with the results.
   */
  subtract(specificVolume) {
    return new SpecificVolume(super.internalSubtract(this.value, specificVolume.BaseValue));
  }
  /**
   * Multiply the given SpecificVolume with the current SpecificVolume.
   * @param specificVolume The other SpecificVolume.
   * @returns A new SpecificVolume instance with the results.
   */
  multiply(specificVolume) {
    return new SpecificVolume(super.internalMultiply(this.value, specificVolume.BaseValue));
  }
  /**
   * Divide the given SpecificVolume with the current SpecificVolume.
   * @param specificVolume The other SpecificVolume.
   * @returns A new SpecificVolume instance with the results.
   */
  divide(specificVolume) {
    return new SpecificVolume(super.internalDivide(this.value, specificVolume.BaseValue));
  }
  /**
   * Modulo the given SpecificVolume with the current SpecificVolume.
   * @param specificVolume The other SpecificVolume.
   * @returns A new SpecificVolume instance with the results.
   */
  modulo(specificVolume) {
    return new SpecificVolume(super.internalModulo(this.value, specificVolume.BaseValue));
  }
  /**
   * Pow the given SpecificVolume with the current SpecificVolume.
   * @param specificVolume The other SpecificVolume.
   * @returns A new SpecificVolume instance with the results.
   */
  pow(specificVolume) {
    return new SpecificVolume(super.internalPow(this.value, specificVolume.BaseValue));
  }
}
exports.SpecificVolume = SpecificVolume;