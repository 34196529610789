"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RatioChangeRate = exports.RatioChangeRateUnits = void 0;
const base_unit_1 = require("../base-unit");
/** RatioChangeRateUnits enumeration */
var RatioChangeRateUnits;
(function (RatioChangeRateUnits) {
  /** */
  RatioChangeRateUnits["PercentsPerSecond"] = "PercentPerSecond";
  /** */
  RatioChangeRateUnits["DecimalFractionsPerSecond"] = "DecimalFractionPerSecond";
})(RatioChangeRateUnits = exports.RatioChangeRateUnits || (exports.RatioChangeRateUnits = {}));
/** The change in ratio per unit of time. */
class RatioChangeRate extends base_unit_1.BaseUnit {
  /**
   * Create a new RatioChangeRate.
   * @param value The value.
   * @param fromUnit The ‘RatioChangeRate’ unit to create from.
   * The default unit is DecimalFractionsPerSecond
   */
  constructor(value, fromUnit = RatioChangeRateUnits.DecimalFractionsPerSecond) {
    super();
    this.percentspersecondLazy = null;
    this.decimalfractionspersecondLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of RatioChangeRate is DecimalFractionsPerSecond.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return RatioChangeRateUnits.DecimalFractionsPerSecond;
  }
  /** */
  get PercentsPerSecond() {
    if (this.percentspersecondLazy !== null) {
      return this.percentspersecondLazy;
    }
    return this.percentspersecondLazy = this.convertFromBase(RatioChangeRateUnits.PercentsPerSecond);
  }
  /** */
  get DecimalFractionsPerSecond() {
    if (this.decimalfractionspersecondLazy !== null) {
      return this.decimalfractionspersecondLazy;
    }
    return this.decimalfractionspersecondLazy = this.convertFromBase(RatioChangeRateUnits.DecimalFractionsPerSecond);
  }
  /**
   * Create a new RatioChangeRate instance from a PercentsPerSecond
   *
   * @param value The unit as PercentsPerSecond to create a new RatioChangeRate from.
   * @returns The new RatioChangeRate instance.
   */
  static FromPercentsPerSecond(value) {
    return new RatioChangeRate(value, RatioChangeRateUnits.PercentsPerSecond);
  }
  /**
   * Create a new RatioChangeRate instance from a DecimalFractionsPerSecond
   *
   * @param value The unit as DecimalFractionsPerSecond to create a new RatioChangeRate from.
   * @returns The new RatioChangeRate instance.
   */
  static FromDecimalFractionsPerSecond(value) {
    return new RatioChangeRate(value, RatioChangeRateUnits.DecimalFractionsPerSecond);
  }
  /**
   * Gets the base unit enumeration associated with RatioChangeRate
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return RatioChangeRateUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return RatioChangeRateUnits.DecimalFractionsPerSecond;
  }
  /**
   * Create API DTO represent a RatioChangeRate unit.
   * @param holdInUnit The specific RatioChangeRate unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = RatioChangeRateUnits.DecimalFractionsPerSecond) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a RatioChangeRate unit from an API DTO representation.
   * @param dtoRatioChangeRate The RatioChangeRate API DTO representation
   */
  static FromDto(dtoRatioChangeRate) {
    return new RatioChangeRate(dtoRatioChangeRate.value, dtoRatioChangeRate.unit);
  }
  /**
   * Convert RatioChangeRate to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case RatioChangeRateUnits.PercentsPerSecond:
        return this.PercentsPerSecond;
      case RatioChangeRateUnits.DecimalFractionsPerSecond:
        return this.DecimalFractionsPerSecond;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case RatioChangeRateUnits.PercentsPerSecond:
        return super.internalMultiply(this.value, 1e2);
      case RatioChangeRateUnits.DecimalFractionsPerSecond:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case RatioChangeRateUnits.PercentsPerSecond:
        return this.value * 1e2;
      case RatioChangeRateUnits.DecimalFractionsPerSecond:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case RatioChangeRateUnits.PercentsPerSecond:
        return super.internalDivide(value, 1e2);
      case RatioChangeRateUnits.DecimalFractionsPerSecond:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case RatioChangeRateUnits.PercentsPerSecond:
        return value / 1e2;
      case RatioChangeRateUnits.DecimalFractionsPerSecond:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the RatioChangeRate to string.
   * Note! the default format for RatioChangeRate is DecimalFractionsPerSecond.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the RatioChangeRate.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the RatioChangeRate.
   */
  toString(unit = RatioChangeRateUnits.DecimalFractionsPerSecond, fractionalDigits) {
    switch (unit) {
      case RatioChangeRateUnits.PercentsPerSecond:
        return super.truncateFractionDigits(this.PercentsPerSecond, fractionalDigits) + ` %/s`;
      case RatioChangeRateUnits.DecimalFractionsPerSecond:
        return super.truncateFractionDigits(this.DecimalFractionsPerSecond, fractionalDigits) + ` /s`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get RatioChangeRate unit abbreviation.
   * Note! the default abbreviation for RatioChangeRate is DecimalFractionsPerSecond.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the RatioChangeRate.
   * @returns The abbreviation string of RatioChangeRate.
   */
  getUnitAbbreviation(unitAbbreviation = RatioChangeRateUnits.DecimalFractionsPerSecond) {
    switch (unitAbbreviation) {
      case RatioChangeRateUnits.PercentsPerSecond:
        return `%/s`;
      case RatioChangeRateUnits.DecimalFractionsPerSecond:
        return `/s`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given RatioChangeRate are equals to the current RatioChangeRate.
   * @param ratioChangeRate The other RatioChangeRate.
   * @returns True if the given RatioChangeRate are equal to the current RatioChangeRate.
   */
  equals(ratioChangeRate) {
    return super.internalEquals(this.value, ratioChangeRate.BaseValue);
  }
  /**
   * Compare the given RatioChangeRate against the current RatioChangeRate.
   * @param ratioChangeRate The other RatioChangeRate.
   * @returns 0 if they are equal, -1 if the current RatioChangeRate is less then other, 1 if the current RatioChangeRate is greater then other.
   */
  compareTo(ratioChangeRate) {
    return super.internalCompareTo(this.value, ratioChangeRate.BaseValue);
  }
  /**
   * Add the given RatioChangeRate with the current RatioChangeRate.
   * @param ratioChangeRate The other RatioChangeRate.
   * @returns A new RatioChangeRate instance with the results.
   */
  add(ratioChangeRate) {
    return new RatioChangeRate(super.internalAdd(this.value, ratioChangeRate.BaseValue));
  }
  /**
   * Subtract the given RatioChangeRate with the current RatioChangeRate.
   * @param ratioChangeRate The other RatioChangeRate.
   * @returns A new RatioChangeRate instance with the results.
   */
  subtract(ratioChangeRate) {
    return new RatioChangeRate(super.internalSubtract(this.value, ratioChangeRate.BaseValue));
  }
  /**
   * Multiply the given RatioChangeRate with the current RatioChangeRate.
   * @param ratioChangeRate The other RatioChangeRate.
   * @returns A new RatioChangeRate instance with the results.
   */
  multiply(ratioChangeRate) {
    return new RatioChangeRate(super.internalMultiply(this.value, ratioChangeRate.BaseValue));
  }
  /**
   * Divide the given RatioChangeRate with the current RatioChangeRate.
   * @param ratioChangeRate The other RatioChangeRate.
   * @returns A new RatioChangeRate instance with the results.
   */
  divide(ratioChangeRate) {
    return new RatioChangeRate(super.internalDivide(this.value, ratioChangeRate.BaseValue));
  }
  /**
   * Modulo the given RatioChangeRate with the current RatioChangeRate.
   * @param ratioChangeRate The other RatioChangeRate.
   * @returns A new RatioChangeRate instance with the results.
   */
  modulo(ratioChangeRate) {
    return new RatioChangeRate(super.internalModulo(this.value, ratioChangeRate.BaseValue));
  }
  /**
   * Pow the given RatioChangeRate with the current RatioChangeRate.
   * @param ratioChangeRate The other RatioChangeRate.
   * @returns A new RatioChangeRate instance with the results.
   */
  pow(ratioChangeRate) {
    return new RatioChangeRate(super.internalPow(this.value, ratioChangeRate.BaseValue));
  }
}
exports.RatioChangeRate = RatioChangeRate;