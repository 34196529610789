"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Entropy = exports.EntropyUnits = void 0;
const base_unit_1 = require("../base-unit");
/** EntropyUnits enumeration */
var EntropyUnits;
(function (EntropyUnits) {
  /** */
  EntropyUnits["JoulesPerKelvin"] = "JoulePerKelvin";
  /** */
  EntropyUnits["CaloriesPerKelvin"] = "CaloriePerKelvin";
  /** */
  EntropyUnits["JoulesPerDegreeCelsius"] = "JoulePerDegreeCelsius";
  /** */
  EntropyUnits["KilojoulesPerKelvin"] = "KilojoulePerKelvin";
  /** */
  EntropyUnits["MegajoulesPerKelvin"] = "MegajoulePerKelvin";
  /** */
  EntropyUnits["KilocaloriesPerKelvin"] = "KilocaloriePerKelvin";
  /** */
  EntropyUnits["KilojoulesPerDegreeCelsius"] = "KilojoulePerDegreeCelsius";
})(EntropyUnits = exports.EntropyUnits || (exports.EntropyUnits = {}));
/** Entropy is an important concept in the branch of science known as thermodynamics. The idea of "irreversibility" is central to the understanding of entropy.  It is often said that entropy is an expression of the disorder, or randomness of a system, or of our lack of information about it. Entropy is an extensive property. It has the dimension of energy divided by temperature, which has a unit of joules per kelvin (J/K) in the International System of Units */
class Entropy extends base_unit_1.BaseUnit {
  /**
   * Create a new Entropy.
   * @param value The value.
   * @param fromUnit The ‘Entropy’ unit to create from.
   * The default unit is JoulesPerKelvin
   */
  constructor(value, fromUnit = EntropyUnits.JoulesPerKelvin) {
    super();
    this.joulesperkelvinLazy = null;
    this.caloriesperkelvinLazy = null;
    this.joulesperdegreecelsiusLazy = null;
    this.kilojoulesperkelvinLazy = null;
    this.megajoulesperkelvinLazy = null;
    this.kilocaloriesperkelvinLazy = null;
    this.kilojoulesperdegreecelsiusLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Entropy is JoulesPerKelvin.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return EntropyUnits.JoulesPerKelvin;
  }
  /** */
  get JoulesPerKelvin() {
    if (this.joulesperkelvinLazy !== null) {
      return this.joulesperkelvinLazy;
    }
    return this.joulesperkelvinLazy = this.convertFromBase(EntropyUnits.JoulesPerKelvin);
  }
  /** */
  get CaloriesPerKelvin() {
    if (this.caloriesperkelvinLazy !== null) {
      return this.caloriesperkelvinLazy;
    }
    return this.caloriesperkelvinLazy = this.convertFromBase(EntropyUnits.CaloriesPerKelvin);
  }
  /** */
  get JoulesPerDegreeCelsius() {
    if (this.joulesperdegreecelsiusLazy !== null) {
      return this.joulesperdegreecelsiusLazy;
    }
    return this.joulesperdegreecelsiusLazy = this.convertFromBase(EntropyUnits.JoulesPerDegreeCelsius);
  }
  /** */
  get KilojoulesPerKelvin() {
    if (this.kilojoulesperkelvinLazy !== null) {
      return this.kilojoulesperkelvinLazy;
    }
    return this.kilojoulesperkelvinLazy = this.convertFromBase(EntropyUnits.KilojoulesPerKelvin);
  }
  /** */
  get MegajoulesPerKelvin() {
    if (this.megajoulesperkelvinLazy !== null) {
      return this.megajoulesperkelvinLazy;
    }
    return this.megajoulesperkelvinLazy = this.convertFromBase(EntropyUnits.MegajoulesPerKelvin);
  }
  /** */
  get KilocaloriesPerKelvin() {
    if (this.kilocaloriesperkelvinLazy !== null) {
      return this.kilocaloriesperkelvinLazy;
    }
    return this.kilocaloriesperkelvinLazy = this.convertFromBase(EntropyUnits.KilocaloriesPerKelvin);
  }
  /** */
  get KilojoulesPerDegreeCelsius() {
    if (this.kilojoulesperdegreecelsiusLazy !== null) {
      return this.kilojoulesperdegreecelsiusLazy;
    }
    return this.kilojoulesperdegreecelsiusLazy = this.convertFromBase(EntropyUnits.KilojoulesPerDegreeCelsius);
  }
  /**
   * Create a new Entropy instance from a JoulesPerKelvin
   *
   * @param value The unit as JoulesPerKelvin to create a new Entropy from.
   * @returns The new Entropy instance.
   */
  static FromJoulesPerKelvin(value) {
    return new Entropy(value, EntropyUnits.JoulesPerKelvin);
  }
  /**
   * Create a new Entropy instance from a CaloriesPerKelvin
   *
   * @param value The unit as CaloriesPerKelvin to create a new Entropy from.
   * @returns The new Entropy instance.
   */
  static FromCaloriesPerKelvin(value) {
    return new Entropy(value, EntropyUnits.CaloriesPerKelvin);
  }
  /**
   * Create a new Entropy instance from a JoulesPerDegreeCelsius
   *
   * @param value The unit as JoulesPerDegreeCelsius to create a new Entropy from.
   * @returns The new Entropy instance.
   */
  static FromJoulesPerDegreeCelsius(value) {
    return new Entropy(value, EntropyUnits.JoulesPerDegreeCelsius);
  }
  /**
   * Create a new Entropy instance from a KilojoulesPerKelvin
   *
   * @param value The unit as KilojoulesPerKelvin to create a new Entropy from.
   * @returns The new Entropy instance.
   */
  static FromKilojoulesPerKelvin(value) {
    return new Entropy(value, EntropyUnits.KilojoulesPerKelvin);
  }
  /**
   * Create a new Entropy instance from a MegajoulesPerKelvin
   *
   * @param value The unit as MegajoulesPerKelvin to create a new Entropy from.
   * @returns The new Entropy instance.
   */
  static FromMegajoulesPerKelvin(value) {
    return new Entropy(value, EntropyUnits.MegajoulesPerKelvin);
  }
  /**
   * Create a new Entropy instance from a KilocaloriesPerKelvin
   *
   * @param value The unit as KilocaloriesPerKelvin to create a new Entropy from.
   * @returns The new Entropy instance.
   */
  static FromKilocaloriesPerKelvin(value) {
    return new Entropy(value, EntropyUnits.KilocaloriesPerKelvin);
  }
  /**
   * Create a new Entropy instance from a KilojoulesPerDegreeCelsius
   *
   * @param value The unit as KilojoulesPerDegreeCelsius to create a new Entropy from.
   * @returns The new Entropy instance.
   */
  static FromKilojoulesPerDegreeCelsius(value) {
    return new Entropy(value, EntropyUnits.KilojoulesPerDegreeCelsius);
  }
  /**
   * Gets the base unit enumeration associated with Entropy
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return EntropyUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return EntropyUnits.JoulesPerKelvin;
  }
  /**
   * Create API DTO represent a Entropy unit.
   * @param holdInUnit The specific Entropy unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = EntropyUnits.JoulesPerKelvin) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Entropy unit from an API DTO representation.
   * @param dtoEntropy The Entropy API DTO representation
   */
  static FromDto(dtoEntropy) {
    return new Entropy(dtoEntropy.value, dtoEntropy.unit);
  }
  /**
   * Convert Entropy to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case EntropyUnits.JoulesPerKelvin:
        return this.JoulesPerKelvin;
      case EntropyUnits.CaloriesPerKelvin:
        return this.CaloriesPerKelvin;
      case EntropyUnits.JoulesPerDegreeCelsius:
        return this.JoulesPerDegreeCelsius;
      case EntropyUnits.KilojoulesPerKelvin:
        return this.KilojoulesPerKelvin;
      case EntropyUnits.MegajoulesPerKelvin:
        return this.MegajoulesPerKelvin;
      case EntropyUnits.KilocaloriesPerKelvin:
        return this.KilocaloriesPerKelvin;
      case EntropyUnits.KilojoulesPerDegreeCelsius:
        return this.KilojoulesPerDegreeCelsius;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case EntropyUnits.JoulesPerKelvin:
        return this.value;
      case EntropyUnits.CaloriesPerKelvin:
        return super.internalDivide(this.value, 4.184);
      case EntropyUnits.JoulesPerDegreeCelsius:
        return this.value;
      case EntropyUnits.KilojoulesPerKelvin:
        return super.internalDivide(this.value, 1000);
      case EntropyUnits.MegajoulesPerKelvin:
        return super.internalDivide(this.value, 1000000);
      case EntropyUnits.KilocaloriesPerKelvin:
        {
          const v3 = super.internalDivide(this.value, 4.184);
          return super.internalDivide(v3, 1000);
        }
      case EntropyUnits.KilojoulesPerDegreeCelsius:
        return super.internalDivide(this.value, 1000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case EntropyUnits.JoulesPerKelvin:
        return this.value;
      case EntropyUnits.CaloriesPerKelvin:
        return this.value / 4.184;
      case EntropyUnits.JoulesPerDegreeCelsius:
        return this.value;
      case EntropyUnits.KilojoulesPerKelvin:
        return this.value / 1000;
      case EntropyUnits.MegajoulesPerKelvin:
        return this.value / 1000000;
      case EntropyUnits.KilocaloriesPerKelvin:
        return this.value / 4.184 / 1000;
      case EntropyUnits.KilojoulesPerDegreeCelsius:
        return this.value / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case EntropyUnits.JoulesPerKelvin:
        return value;
      case EntropyUnits.CaloriesPerKelvin:
        return super.internalMultiply(value, 4.184);
      case EntropyUnits.JoulesPerDegreeCelsius:
        return value;
      case EntropyUnits.KilojoulesPerKelvin:
        return super.internalMultiply(value, 1000);
      case EntropyUnits.MegajoulesPerKelvin:
        return super.internalMultiply(value, 1000000);
      case EntropyUnits.KilocaloriesPerKelvin:
        {
          const v3 = super.internalMultiply(value, 4.184);
          return super.internalMultiply(v3, 1000);
        }
      case EntropyUnits.KilojoulesPerDegreeCelsius:
        return super.internalMultiply(value, 1000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case EntropyUnits.JoulesPerKelvin:
        return value;
      case EntropyUnits.CaloriesPerKelvin:
        return value * 4.184;
      case EntropyUnits.JoulesPerDegreeCelsius:
        return value;
      case EntropyUnits.KilojoulesPerKelvin:
        return value * 1000;
      case EntropyUnits.MegajoulesPerKelvin:
        return value * 1000000;
      case EntropyUnits.KilocaloriesPerKelvin:
        return value * 4.184 * 1000;
      case EntropyUnits.KilojoulesPerDegreeCelsius:
        return value * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Entropy to string.
   * Note! the default format for Entropy is JoulesPerKelvin.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Entropy.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Entropy.
   */
  toString(unit = EntropyUnits.JoulesPerKelvin, fractionalDigits) {
    switch (unit) {
      case EntropyUnits.JoulesPerKelvin:
        return super.truncateFractionDigits(this.JoulesPerKelvin, fractionalDigits) + ` J/K`;
      case EntropyUnits.CaloriesPerKelvin:
        return super.truncateFractionDigits(this.CaloriesPerKelvin, fractionalDigits) + ` cal/K`;
      case EntropyUnits.JoulesPerDegreeCelsius:
        return super.truncateFractionDigits(this.JoulesPerDegreeCelsius, fractionalDigits) + ` J/C`;
      case EntropyUnits.KilojoulesPerKelvin:
        return super.truncateFractionDigits(this.KilojoulesPerKelvin, fractionalDigits) + ` kJ/K`;
      case EntropyUnits.MegajoulesPerKelvin:
        return super.truncateFractionDigits(this.MegajoulesPerKelvin, fractionalDigits) + ` MJ/K`;
      case EntropyUnits.KilocaloriesPerKelvin:
        return super.truncateFractionDigits(this.KilocaloriesPerKelvin, fractionalDigits) + ` kcal/K`;
      case EntropyUnits.KilojoulesPerDegreeCelsius:
        return super.truncateFractionDigits(this.KilojoulesPerDegreeCelsius, fractionalDigits) + ` kJ/C`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Entropy unit abbreviation.
   * Note! the default abbreviation for Entropy is JoulesPerKelvin.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Entropy.
   * @returns The abbreviation string of Entropy.
   */
  getUnitAbbreviation(unitAbbreviation = EntropyUnits.JoulesPerKelvin) {
    switch (unitAbbreviation) {
      case EntropyUnits.JoulesPerKelvin:
        return `J/K`;
      case EntropyUnits.CaloriesPerKelvin:
        return `cal/K`;
      case EntropyUnits.JoulesPerDegreeCelsius:
        return `J/C`;
      case EntropyUnits.KilojoulesPerKelvin:
        return `kJ/K`;
      case EntropyUnits.MegajoulesPerKelvin:
        return `MJ/K`;
      case EntropyUnits.KilocaloriesPerKelvin:
        return `kcal/K`;
      case EntropyUnits.KilojoulesPerDegreeCelsius:
        return `kJ/C`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Entropy are equals to the current Entropy.
   * @param entropy The other Entropy.
   * @returns True if the given Entropy are equal to the current Entropy.
   */
  equals(entropy) {
    return super.internalEquals(this.value, entropy.BaseValue);
  }
  /**
   * Compare the given Entropy against the current Entropy.
   * @param entropy The other Entropy.
   * @returns 0 if they are equal, -1 if the current Entropy is less then other, 1 if the current Entropy is greater then other.
   */
  compareTo(entropy) {
    return super.internalCompareTo(this.value, entropy.BaseValue);
  }
  /**
   * Add the given Entropy with the current Entropy.
   * @param entropy The other Entropy.
   * @returns A new Entropy instance with the results.
   */
  add(entropy) {
    return new Entropy(super.internalAdd(this.value, entropy.BaseValue));
  }
  /**
   * Subtract the given Entropy with the current Entropy.
   * @param entropy The other Entropy.
   * @returns A new Entropy instance with the results.
   */
  subtract(entropy) {
    return new Entropy(super.internalSubtract(this.value, entropy.BaseValue));
  }
  /**
   * Multiply the given Entropy with the current Entropy.
   * @param entropy The other Entropy.
   * @returns A new Entropy instance with the results.
   */
  multiply(entropy) {
    return new Entropy(super.internalMultiply(this.value, entropy.BaseValue));
  }
  /**
   * Divide the given Entropy with the current Entropy.
   * @param entropy The other Entropy.
   * @returns A new Entropy instance with the results.
   */
  divide(entropy) {
    return new Entropy(super.internalDivide(this.value, entropy.BaseValue));
  }
  /**
   * Modulo the given Entropy with the current Entropy.
   * @param entropy The other Entropy.
   * @returns A new Entropy instance with the results.
   */
  modulo(entropy) {
    return new Entropy(super.internalModulo(this.value, entropy.BaseValue));
  }
  /**
   * Pow the given Entropy with the current Entropy.
   * @param entropy The other Entropy.
   * @returns A new Entropy instance with the results.
   */
  pow(entropy) {
    return new Entropy(super.internalPow(this.value, entropy.BaseValue));
  }
}
exports.Entropy = Entropy;