"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Magnetization = exports.MagnetizationUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MagnetizationUnits enumeration */
var MagnetizationUnits;
(function (MagnetizationUnits) {
  /** */
  MagnetizationUnits["AmperesPerMeter"] = "AmperePerMeter";
})(MagnetizationUnits = exports.MagnetizationUnits || (exports.MagnetizationUnits = {}));
/** In classical electromagnetism, magnetization is the vector field that expresses the density of permanent or induced magnetic dipole moments in a magnetic material. */
class Magnetization extends base_unit_1.BaseUnit {
  /**
   * Create a new Magnetization.
   * @param value The value.
   * @param fromUnit The ‘Magnetization’ unit to create from.
   * The default unit is AmperesPerMeter
   */
  constructor(value, fromUnit = MagnetizationUnits.AmperesPerMeter) {
    super();
    this.amperespermeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Magnetization is AmperesPerMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MagnetizationUnits.AmperesPerMeter;
  }
  /** */
  get AmperesPerMeter() {
    if (this.amperespermeterLazy !== null) {
      return this.amperespermeterLazy;
    }
    return this.amperespermeterLazy = this.convertFromBase(MagnetizationUnits.AmperesPerMeter);
  }
  /**
   * Create a new Magnetization instance from a AmperesPerMeter
   *
   * @param value The unit as AmperesPerMeter to create a new Magnetization from.
   * @returns The new Magnetization instance.
   */
  static FromAmperesPerMeter(value) {
    return new Magnetization(value, MagnetizationUnits.AmperesPerMeter);
  }
  /**
   * Gets the base unit enumeration associated with Magnetization
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MagnetizationUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MagnetizationUnits.AmperesPerMeter;
  }
  /**
   * Create API DTO represent a Magnetization unit.
   * @param holdInUnit The specific Magnetization unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MagnetizationUnits.AmperesPerMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Magnetization unit from an API DTO representation.
   * @param dtoMagnetization The Magnetization API DTO representation
   */
  static FromDto(dtoMagnetization) {
    return new Magnetization(dtoMagnetization.value, dtoMagnetization.unit);
  }
  /**
   * Convert Magnetization to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MagnetizationUnits.AmperesPerMeter:
        return this.AmperesPerMeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MagnetizationUnits.AmperesPerMeter:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MagnetizationUnits.AmperesPerMeter:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MagnetizationUnits.AmperesPerMeter:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MagnetizationUnits.AmperesPerMeter:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Magnetization to string.
   * Note! the default format for Magnetization is AmperesPerMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Magnetization.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Magnetization.
   */
  toString(unit = MagnetizationUnits.AmperesPerMeter, fractionalDigits) {
    switch (unit) {
      case MagnetizationUnits.AmperesPerMeter:
        return super.truncateFractionDigits(this.AmperesPerMeter, fractionalDigits) + ` A/m`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Magnetization unit abbreviation.
   * Note! the default abbreviation for Magnetization is AmperesPerMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Magnetization.
   * @returns The abbreviation string of Magnetization.
   */
  getUnitAbbreviation(unitAbbreviation = MagnetizationUnits.AmperesPerMeter) {
    switch (unitAbbreviation) {
      case MagnetizationUnits.AmperesPerMeter:
        return `A/m`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Magnetization are equals to the current Magnetization.
   * @param magnetization The other Magnetization.
   * @returns True if the given Magnetization are equal to the current Magnetization.
   */
  equals(magnetization) {
    return super.internalEquals(this.value, magnetization.BaseValue);
  }
  /**
   * Compare the given Magnetization against the current Magnetization.
   * @param magnetization The other Magnetization.
   * @returns 0 if they are equal, -1 if the current Magnetization is less then other, 1 if the current Magnetization is greater then other.
   */
  compareTo(magnetization) {
    return super.internalCompareTo(this.value, magnetization.BaseValue);
  }
  /**
   * Add the given Magnetization with the current Magnetization.
   * @param magnetization The other Magnetization.
   * @returns A new Magnetization instance with the results.
   */
  add(magnetization) {
    return new Magnetization(super.internalAdd(this.value, magnetization.BaseValue));
  }
  /**
   * Subtract the given Magnetization with the current Magnetization.
   * @param magnetization The other Magnetization.
   * @returns A new Magnetization instance with the results.
   */
  subtract(magnetization) {
    return new Magnetization(super.internalSubtract(this.value, magnetization.BaseValue));
  }
  /**
   * Multiply the given Magnetization with the current Magnetization.
   * @param magnetization The other Magnetization.
   * @returns A new Magnetization instance with the results.
   */
  multiply(magnetization) {
    return new Magnetization(super.internalMultiply(this.value, magnetization.BaseValue));
  }
  /**
   * Divide the given Magnetization with the current Magnetization.
   * @param magnetization The other Magnetization.
   * @returns A new Magnetization instance with the results.
   */
  divide(magnetization) {
    return new Magnetization(super.internalDivide(this.value, magnetization.BaseValue));
  }
  /**
   * Modulo the given Magnetization with the current Magnetization.
   * @param magnetization The other Magnetization.
   * @returns A new Magnetization instance with the results.
   */
  modulo(magnetization) {
    return new Magnetization(super.internalModulo(this.value, magnetization.BaseValue));
  }
  /**
   * Pow the given Magnetization with the current Magnetization.
   * @param magnetization The other Magnetization.
   * @returns A new Magnetization instance with the results.
   */
  pow(magnetization) {
    return new Magnetization(super.internalPow(this.value, magnetization.BaseValue));
  }
}
exports.Magnetization = Magnetization;