"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AmountOfSubstance = exports.AmountOfSubstanceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** AmountOfSubstanceUnits enumeration */
var AmountOfSubstanceUnits;
(function (AmountOfSubstanceUnits) {
  /** */
  AmountOfSubstanceUnits["Moles"] = "Mole";
  /** */
  AmountOfSubstanceUnits["PoundMoles"] = "PoundMole";
  /** */
  AmountOfSubstanceUnits["Femtomoles"] = "Femtomole";
  /** */
  AmountOfSubstanceUnits["Picomoles"] = "Picomole";
  /** */
  AmountOfSubstanceUnits["Nanomoles"] = "Nanomole";
  /** */
  AmountOfSubstanceUnits["Micromoles"] = "Micromole";
  /** */
  AmountOfSubstanceUnits["Millimoles"] = "Millimole";
  /** */
  AmountOfSubstanceUnits["Centimoles"] = "Centimole";
  /** */
  AmountOfSubstanceUnits["Decimoles"] = "Decimole";
  /** */
  AmountOfSubstanceUnits["Kilomoles"] = "Kilomole";
  /** */
  AmountOfSubstanceUnits["Megamoles"] = "Megamole";
  /** */
  AmountOfSubstanceUnits["NanopoundMoles"] = "NanopoundMole";
  /** */
  AmountOfSubstanceUnits["MicropoundMoles"] = "MicropoundMole";
  /** */
  AmountOfSubstanceUnits["MillipoundMoles"] = "MillipoundMole";
  /** */
  AmountOfSubstanceUnits["CentipoundMoles"] = "CentipoundMole";
  /** */
  AmountOfSubstanceUnits["DecipoundMoles"] = "DecipoundMole";
  /** */
  AmountOfSubstanceUnits["KilopoundMoles"] = "KilopoundMole";
})(AmountOfSubstanceUnits = exports.AmountOfSubstanceUnits || (exports.AmountOfSubstanceUnits = {}));
/** Mole is the amount of substance containing Avagadro's Number (6.02 x 10 ^ 23) of real particles such as molecules,atoms, ions or radicals. */
class AmountOfSubstance extends base_unit_1.BaseUnit {
  /**
   * Create a new AmountOfSubstance.
   * @param value The value.
   * @param fromUnit The ‘AmountOfSubstance’ unit to create from.
   * The default unit is Moles
   */
  constructor(value, fromUnit = AmountOfSubstanceUnits.Moles) {
    super();
    this.molesLazy = null;
    this.poundmolesLazy = null;
    this.femtomolesLazy = null;
    this.picomolesLazy = null;
    this.nanomolesLazy = null;
    this.micromolesLazy = null;
    this.millimolesLazy = null;
    this.centimolesLazy = null;
    this.decimolesLazy = null;
    this.kilomolesLazy = null;
    this.megamolesLazy = null;
    this.nanopoundmolesLazy = null;
    this.micropoundmolesLazy = null;
    this.millipoundmolesLazy = null;
    this.centipoundmolesLazy = null;
    this.decipoundmolesLazy = null;
    this.kilopoundmolesLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of AmountOfSubstance is Moles.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return AmountOfSubstanceUnits.Moles;
  }
  /** */
  get Moles() {
    if (this.molesLazy !== null) {
      return this.molesLazy;
    }
    return this.molesLazy = this.convertFromBase(AmountOfSubstanceUnits.Moles);
  }
  /** */
  get PoundMoles() {
    if (this.poundmolesLazy !== null) {
      return this.poundmolesLazy;
    }
    return this.poundmolesLazy = this.convertFromBase(AmountOfSubstanceUnits.PoundMoles);
  }
  /** */
  get Femtomoles() {
    if (this.femtomolesLazy !== null) {
      return this.femtomolesLazy;
    }
    return this.femtomolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Femtomoles);
  }
  /** */
  get Picomoles() {
    if (this.picomolesLazy !== null) {
      return this.picomolesLazy;
    }
    return this.picomolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Picomoles);
  }
  /** */
  get Nanomoles() {
    if (this.nanomolesLazy !== null) {
      return this.nanomolesLazy;
    }
    return this.nanomolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Nanomoles);
  }
  /** */
  get Micromoles() {
    if (this.micromolesLazy !== null) {
      return this.micromolesLazy;
    }
    return this.micromolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Micromoles);
  }
  /** */
  get Millimoles() {
    if (this.millimolesLazy !== null) {
      return this.millimolesLazy;
    }
    return this.millimolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Millimoles);
  }
  /** */
  get Centimoles() {
    if (this.centimolesLazy !== null) {
      return this.centimolesLazy;
    }
    return this.centimolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Centimoles);
  }
  /** */
  get Decimoles() {
    if (this.decimolesLazy !== null) {
      return this.decimolesLazy;
    }
    return this.decimolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Decimoles);
  }
  /** */
  get Kilomoles() {
    if (this.kilomolesLazy !== null) {
      return this.kilomolesLazy;
    }
    return this.kilomolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Kilomoles);
  }
  /** */
  get Megamoles() {
    if (this.megamolesLazy !== null) {
      return this.megamolesLazy;
    }
    return this.megamolesLazy = this.convertFromBase(AmountOfSubstanceUnits.Megamoles);
  }
  /** */
  get NanopoundMoles() {
    if (this.nanopoundmolesLazy !== null) {
      return this.nanopoundmolesLazy;
    }
    return this.nanopoundmolesLazy = this.convertFromBase(AmountOfSubstanceUnits.NanopoundMoles);
  }
  /** */
  get MicropoundMoles() {
    if (this.micropoundmolesLazy !== null) {
      return this.micropoundmolesLazy;
    }
    return this.micropoundmolesLazy = this.convertFromBase(AmountOfSubstanceUnits.MicropoundMoles);
  }
  /** */
  get MillipoundMoles() {
    if (this.millipoundmolesLazy !== null) {
      return this.millipoundmolesLazy;
    }
    return this.millipoundmolesLazy = this.convertFromBase(AmountOfSubstanceUnits.MillipoundMoles);
  }
  /** */
  get CentipoundMoles() {
    if (this.centipoundmolesLazy !== null) {
      return this.centipoundmolesLazy;
    }
    return this.centipoundmolesLazy = this.convertFromBase(AmountOfSubstanceUnits.CentipoundMoles);
  }
  /** */
  get DecipoundMoles() {
    if (this.decipoundmolesLazy !== null) {
      return this.decipoundmolesLazy;
    }
    return this.decipoundmolesLazy = this.convertFromBase(AmountOfSubstanceUnits.DecipoundMoles);
  }
  /** */
  get KilopoundMoles() {
    if (this.kilopoundmolesLazy !== null) {
      return this.kilopoundmolesLazy;
    }
    return this.kilopoundmolesLazy = this.convertFromBase(AmountOfSubstanceUnits.KilopoundMoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Moles
   *
   * @param value The unit as Moles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromMoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Moles);
  }
  /**
   * Create a new AmountOfSubstance instance from a PoundMoles
   *
   * @param value The unit as PoundMoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromPoundMoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.PoundMoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Femtomoles
   *
   * @param value The unit as Femtomoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromFemtomoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Femtomoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Picomoles
   *
   * @param value The unit as Picomoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromPicomoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Picomoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Nanomoles
   *
   * @param value The unit as Nanomoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromNanomoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Nanomoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Micromoles
   *
   * @param value The unit as Micromoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromMicromoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Micromoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Millimoles
   *
   * @param value The unit as Millimoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromMillimoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Millimoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Centimoles
   *
   * @param value The unit as Centimoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromCentimoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Centimoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Decimoles
   *
   * @param value The unit as Decimoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromDecimoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Decimoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Kilomoles
   *
   * @param value The unit as Kilomoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromKilomoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Kilomoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a Megamoles
   *
   * @param value The unit as Megamoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromMegamoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.Megamoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a NanopoundMoles
   *
   * @param value The unit as NanopoundMoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromNanopoundMoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.NanopoundMoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a MicropoundMoles
   *
   * @param value The unit as MicropoundMoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromMicropoundMoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.MicropoundMoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a MillipoundMoles
   *
   * @param value The unit as MillipoundMoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromMillipoundMoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.MillipoundMoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a CentipoundMoles
   *
   * @param value The unit as CentipoundMoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromCentipoundMoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.CentipoundMoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a DecipoundMoles
   *
   * @param value The unit as DecipoundMoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromDecipoundMoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.DecipoundMoles);
  }
  /**
   * Create a new AmountOfSubstance instance from a KilopoundMoles
   *
   * @param value The unit as KilopoundMoles to create a new AmountOfSubstance from.
   * @returns The new AmountOfSubstance instance.
   */
  static FromKilopoundMoles(value) {
    return new AmountOfSubstance(value, AmountOfSubstanceUnits.KilopoundMoles);
  }
  /**
   * Gets the base unit enumeration associated with AmountOfSubstance
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return AmountOfSubstanceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return AmountOfSubstanceUnits.Moles;
  }
  /**
   * Create API DTO represent a AmountOfSubstance unit.
   * @param holdInUnit The specific AmountOfSubstance unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = AmountOfSubstanceUnits.Moles) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a AmountOfSubstance unit from an API DTO representation.
   * @param dtoAmountOfSubstance The AmountOfSubstance API DTO representation
   */
  static FromDto(dtoAmountOfSubstance) {
    return new AmountOfSubstance(dtoAmountOfSubstance.value, dtoAmountOfSubstance.unit);
  }
  /**
   * Convert AmountOfSubstance to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case AmountOfSubstanceUnits.Moles:
        return this.Moles;
      case AmountOfSubstanceUnits.PoundMoles:
        return this.PoundMoles;
      case AmountOfSubstanceUnits.Femtomoles:
        return this.Femtomoles;
      case AmountOfSubstanceUnits.Picomoles:
        return this.Picomoles;
      case AmountOfSubstanceUnits.Nanomoles:
        return this.Nanomoles;
      case AmountOfSubstanceUnits.Micromoles:
        return this.Micromoles;
      case AmountOfSubstanceUnits.Millimoles:
        return this.Millimoles;
      case AmountOfSubstanceUnits.Centimoles:
        return this.Centimoles;
      case AmountOfSubstanceUnits.Decimoles:
        return this.Decimoles;
      case AmountOfSubstanceUnits.Kilomoles:
        return this.Kilomoles;
      case AmountOfSubstanceUnits.Megamoles:
        return this.Megamoles;
      case AmountOfSubstanceUnits.NanopoundMoles:
        return this.NanopoundMoles;
      case AmountOfSubstanceUnits.MicropoundMoles:
        return this.MicropoundMoles;
      case AmountOfSubstanceUnits.MillipoundMoles:
        return this.MillipoundMoles;
      case AmountOfSubstanceUnits.CentipoundMoles:
        return this.CentipoundMoles;
      case AmountOfSubstanceUnits.DecipoundMoles:
        return this.DecipoundMoles;
      case AmountOfSubstanceUnits.KilopoundMoles:
        return this.KilopoundMoles;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case AmountOfSubstanceUnits.Moles:
        return this.value;
      case AmountOfSubstanceUnits.PoundMoles:
        return super.internalDivide(this.value, 453.59237);
      case AmountOfSubstanceUnits.Femtomoles:
        return super.internalDivide(this.value, 1e-15);
      case AmountOfSubstanceUnits.Picomoles:
        return super.internalDivide(this.value, 1e-12);
      case AmountOfSubstanceUnits.Nanomoles:
        return super.internalDivide(this.value, 1e-9);
      case AmountOfSubstanceUnits.Micromoles:
        return super.internalDivide(this.value, 0.000001);
      case AmountOfSubstanceUnits.Millimoles:
        return super.internalDivide(this.value, 0.001);
      case AmountOfSubstanceUnits.Centimoles:
        return super.internalDivide(this.value, 0.01);
      case AmountOfSubstanceUnits.Decimoles:
        return super.internalDivide(this.value, 0.1);
      case AmountOfSubstanceUnits.Kilomoles:
        return super.internalDivide(this.value, 1000);
      case AmountOfSubstanceUnits.Megamoles:
        return super.internalDivide(this.value, 1000000);
      case AmountOfSubstanceUnits.NanopoundMoles:
        {
          const v3 = super.internalDivide(this.value, 453.59237);
          return super.internalDivide(v3, 1e-9);
        }
      case AmountOfSubstanceUnits.MicropoundMoles:
        {
          const v3 = super.internalDivide(this.value, 453.59237);
          return super.internalDivide(v3, 0.000001);
        }
      case AmountOfSubstanceUnits.MillipoundMoles:
        {
          const v3 = super.internalDivide(this.value, 453.59237);
          return super.internalDivide(v3, 0.001);
        }
      case AmountOfSubstanceUnits.CentipoundMoles:
        {
          const v3 = super.internalDivide(this.value, 453.59237);
          return super.internalDivide(v3, 0.01);
        }
      case AmountOfSubstanceUnits.DecipoundMoles:
        {
          const v3 = super.internalDivide(this.value, 453.59237);
          return super.internalDivide(v3, 0.1);
        }
      case AmountOfSubstanceUnits.KilopoundMoles:
        {
          const v3 = super.internalDivide(this.value, 453.59237);
          return super.internalDivide(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case AmountOfSubstanceUnits.Moles:
        return this.value;
      case AmountOfSubstanceUnits.PoundMoles:
        return this.value / 453.59237;
      case AmountOfSubstanceUnits.Femtomoles:
        return this.value / 1e-15;
      case AmountOfSubstanceUnits.Picomoles:
        return this.value / 1e-12;
      case AmountOfSubstanceUnits.Nanomoles:
        return this.value / 1e-9;
      case AmountOfSubstanceUnits.Micromoles:
        return this.value / 0.000001;
      case AmountOfSubstanceUnits.Millimoles:
        return this.value / 0.001;
      case AmountOfSubstanceUnits.Centimoles:
        return this.value / 0.01;
      case AmountOfSubstanceUnits.Decimoles:
        return this.value / 0.1;
      case AmountOfSubstanceUnits.Kilomoles:
        return this.value / 1000;
      case AmountOfSubstanceUnits.Megamoles:
        return this.value / 1000000;
      case AmountOfSubstanceUnits.NanopoundMoles:
        return this.value / 453.59237 / 1e-9;
      case AmountOfSubstanceUnits.MicropoundMoles:
        return this.value / 453.59237 / 0.000001;
      case AmountOfSubstanceUnits.MillipoundMoles:
        return this.value / 453.59237 / 0.001;
      case AmountOfSubstanceUnits.CentipoundMoles:
        return this.value / 453.59237 / 0.01;
      case AmountOfSubstanceUnits.DecipoundMoles:
        return this.value / 453.59237 / 0.1;
      case AmountOfSubstanceUnits.KilopoundMoles:
        return this.value / 453.59237 / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case AmountOfSubstanceUnits.Moles:
        return value;
      case AmountOfSubstanceUnits.PoundMoles:
        return super.internalMultiply(value, 453.59237);
      case AmountOfSubstanceUnits.Femtomoles:
        return super.internalMultiply(value, 1e-15);
      case AmountOfSubstanceUnits.Picomoles:
        return super.internalMultiply(value, 1e-12);
      case AmountOfSubstanceUnits.Nanomoles:
        return super.internalMultiply(value, 1e-9);
      case AmountOfSubstanceUnits.Micromoles:
        return super.internalMultiply(value, 0.000001);
      case AmountOfSubstanceUnits.Millimoles:
        return super.internalMultiply(value, 0.001);
      case AmountOfSubstanceUnits.Centimoles:
        return super.internalMultiply(value, 0.01);
      case AmountOfSubstanceUnits.Decimoles:
        return super.internalMultiply(value, 0.1);
      case AmountOfSubstanceUnits.Kilomoles:
        return super.internalMultiply(value, 1000);
      case AmountOfSubstanceUnits.Megamoles:
        return super.internalMultiply(value, 1000000);
      case AmountOfSubstanceUnits.NanopoundMoles:
        {
          const v3 = super.internalMultiply(value, 453.59237);
          return super.internalMultiply(v3, 1e-9);
        }
      case AmountOfSubstanceUnits.MicropoundMoles:
        {
          const v3 = super.internalMultiply(value, 453.59237);
          return super.internalMultiply(v3, 0.000001);
        }
      case AmountOfSubstanceUnits.MillipoundMoles:
        {
          const v3 = super.internalMultiply(value, 453.59237);
          return super.internalMultiply(v3, 0.001);
        }
      case AmountOfSubstanceUnits.CentipoundMoles:
        {
          const v3 = super.internalMultiply(value, 453.59237);
          return super.internalMultiply(v3, 0.01);
        }
      case AmountOfSubstanceUnits.DecipoundMoles:
        {
          const v3 = super.internalMultiply(value, 453.59237);
          return super.internalMultiply(v3, 0.1);
        }
      case AmountOfSubstanceUnits.KilopoundMoles:
        {
          const v3 = super.internalMultiply(value, 453.59237);
          return super.internalMultiply(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case AmountOfSubstanceUnits.Moles:
        return value;
      case AmountOfSubstanceUnits.PoundMoles:
        return value * 453.59237;
      case AmountOfSubstanceUnits.Femtomoles:
        return value * 1e-15;
      case AmountOfSubstanceUnits.Picomoles:
        return value * 1e-12;
      case AmountOfSubstanceUnits.Nanomoles:
        return value * 1e-9;
      case AmountOfSubstanceUnits.Micromoles:
        return value * 0.000001;
      case AmountOfSubstanceUnits.Millimoles:
        return value * 0.001;
      case AmountOfSubstanceUnits.Centimoles:
        return value * 0.01;
      case AmountOfSubstanceUnits.Decimoles:
        return value * 0.1;
      case AmountOfSubstanceUnits.Kilomoles:
        return value * 1000;
      case AmountOfSubstanceUnits.Megamoles:
        return value * 1000000;
      case AmountOfSubstanceUnits.NanopoundMoles:
        return value * 453.59237 * 1e-9;
      case AmountOfSubstanceUnits.MicropoundMoles:
        return value * 453.59237 * 0.000001;
      case AmountOfSubstanceUnits.MillipoundMoles:
        return value * 453.59237 * 0.001;
      case AmountOfSubstanceUnits.CentipoundMoles:
        return value * 453.59237 * 0.01;
      case AmountOfSubstanceUnits.DecipoundMoles:
        return value * 453.59237 * 0.1;
      case AmountOfSubstanceUnits.KilopoundMoles:
        return value * 453.59237 * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the AmountOfSubstance to string.
   * Note! the default format for AmountOfSubstance is Moles.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the AmountOfSubstance.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the AmountOfSubstance.
   */
  toString(unit = AmountOfSubstanceUnits.Moles, fractionalDigits) {
    switch (unit) {
      case AmountOfSubstanceUnits.Moles:
        return super.truncateFractionDigits(this.Moles, fractionalDigits) + ` mol`;
      case AmountOfSubstanceUnits.PoundMoles:
        return super.truncateFractionDigits(this.PoundMoles, fractionalDigits) + ` lbmol`;
      case AmountOfSubstanceUnits.Femtomoles:
        return super.truncateFractionDigits(this.Femtomoles, fractionalDigits) + ` fmol`;
      case AmountOfSubstanceUnits.Picomoles:
        return super.truncateFractionDigits(this.Picomoles, fractionalDigits) + ` pmol`;
      case AmountOfSubstanceUnits.Nanomoles:
        return super.truncateFractionDigits(this.Nanomoles, fractionalDigits) + ` nmol`;
      case AmountOfSubstanceUnits.Micromoles:
        return super.truncateFractionDigits(this.Micromoles, fractionalDigits) + ` μmol`;
      case AmountOfSubstanceUnits.Millimoles:
        return super.truncateFractionDigits(this.Millimoles, fractionalDigits) + ` mmol`;
      case AmountOfSubstanceUnits.Centimoles:
        return super.truncateFractionDigits(this.Centimoles, fractionalDigits) + ` cmol`;
      case AmountOfSubstanceUnits.Decimoles:
        return super.truncateFractionDigits(this.Decimoles, fractionalDigits) + ` dmol`;
      case AmountOfSubstanceUnits.Kilomoles:
        return super.truncateFractionDigits(this.Kilomoles, fractionalDigits) + ` kmol`;
      case AmountOfSubstanceUnits.Megamoles:
        return super.truncateFractionDigits(this.Megamoles, fractionalDigits) + ` Mmol`;
      case AmountOfSubstanceUnits.NanopoundMoles:
        return super.truncateFractionDigits(this.NanopoundMoles, fractionalDigits) + ` nlbmol`;
      case AmountOfSubstanceUnits.MicropoundMoles:
        return super.truncateFractionDigits(this.MicropoundMoles, fractionalDigits) + ` μlbmol`;
      case AmountOfSubstanceUnits.MillipoundMoles:
        return super.truncateFractionDigits(this.MillipoundMoles, fractionalDigits) + ` mlbmol`;
      case AmountOfSubstanceUnits.CentipoundMoles:
        return super.truncateFractionDigits(this.CentipoundMoles, fractionalDigits) + ` clbmol`;
      case AmountOfSubstanceUnits.DecipoundMoles:
        return super.truncateFractionDigits(this.DecipoundMoles, fractionalDigits) + ` dlbmol`;
      case AmountOfSubstanceUnits.KilopoundMoles:
        return super.truncateFractionDigits(this.KilopoundMoles, fractionalDigits) + ` klbmol`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get AmountOfSubstance unit abbreviation.
   * Note! the default abbreviation for AmountOfSubstance is Moles.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the AmountOfSubstance.
   * @returns The abbreviation string of AmountOfSubstance.
   */
  getUnitAbbreviation(unitAbbreviation = AmountOfSubstanceUnits.Moles) {
    switch (unitAbbreviation) {
      case AmountOfSubstanceUnits.Moles:
        return `mol`;
      case AmountOfSubstanceUnits.PoundMoles:
        return `lbmol`;
      case AmountOfSubstanceUnits.Femtomoles:
        return `fmol`;
      case AmountOfSubstanceUnits.Picomoles:
        return `pmol`;
      case AmountOfSubstanceUnits.Nanomoles:
        return `nmol`;
      case AmountOfSubstanceUnits.Micromoles:
        return `μmol`;
      case AmountOfSubstanceUnits.Millimoles:
        return `mmol`;
      case AmountOfSubstanceUnits.Centimoles:
        return `cmol`;
      case AmountOfSubstanceUnits.Decimoles:
        return `dmol`;
      case AmountOfSubstanceUnits.Kilomoles:
        return `kmol`;
      case AmountOfSubstanceUnits.Megamoles:
        return `Mmol`;
      case AmountOfSubstanceUnits.NanopoundMoles:
        return `nlbmol`;
      case AmountOfSubstanceUnits.MicropoundMoles:
        return `μlbmol`;
      case AmountOfSubstanceUnits.MillipoundMoles:
        return `mlbmol`;
      case AmountOfSubstanceUnits.CentipoundMoles:
        return `clbmol`;
      case AmountOfSubstanceUnits.DecipoundMoles:
        return `dlbmol`;
      case AmountOfSubstanceUnits.KilopoundMoles:
        return `klbmol`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given AmountOfSubstance are equals to the current AmountOfSubstance.
   * @param amountOfSubstance The other AmountOfSubstance.
   * @returns True if the given AmountOfSubstance are equal to the current AmountOfSubstance.
   */
  equals(amountOfSubstance) {
    return super.internalEquals(this.value, amountOfSubstance.BaseValue);
  }
  /**
   * Compare the given AmountOfSubstance against the current AmountOfSubstance.
   * @param amountOfSubstance The other AmountOfSubstance.
   * @returns 0 if they are equal, -1 if the current AmountOfSubstance is less then other, 1 if the current AmountOfSubstance is greater then other.
   */
  compareTo(amountOfSubstance) {
    return super.internalCompareTo(this.value, amountOfSubstance.BaseValue);
  }
  /**
   * Add the given AmountOfSubstance with the current AmountOfSubstance.
   * @param amountOfSubstance The other AmountOfSubstance.
   * @returns A new AmountOfSubstance instance with the results.
   */
  add(amountOfSubstance) {
    return new AmountOfSubstance(super.internalAdd(this.value, amountOfSubstance.BaseValue));
  }
  /**
   * Subtract the given AmountOfSubstance with the current AmountOfSubstance.
   * @param amountOfSubstance The other AmountOfSubstance.
   * @returns A new AmountOfSubstance instance with the results.
   */
  subtract(amountOfSubstance) {
    return new AmountOfSubstance(super.internalSubtract(this.value, amountOfSubstance.BaseValue));
  }
  /**
   * Multiply the given AmountOfSubstance with the current AmountOfSubstance.
   * @param amountOfSubstance The other AmountOfSubstance.
   * @returns A new AmountOfSubstance instance with the results.
   */
  multiply(amountOfSubstance) {
    return new AmountOfSubstance(super.internalMultiply(this.value, amountOfSubstance.BaseValue));
  }
  /**
   * Divide the given AmountOfSubstance with the current AmountOfSubstance.
   * @param amountOfSubstance The other AmountOfSubstance.
   * @returns A new AmountOfSubstance instance with the results.
   */
  divide(amountOfSubstance) {
    return new AmountOfSubstance(super.internalDivide(this.value, amountOfSubstance.BaseValue));
  }
  /**
   * Modulo the given AmountOfSubstance with the current AmountOfSubstance.
   * @param amountOfSubstance The other AmountOfSubstance.
   * @returns A new AmountOfSubstance instance with the results.
   */
  modulo(amountOfSubstance) {
    return new AmountOfSubstance(super.internalModulo(this.value, amountOfSubstance.BaseValue));
  }
  /**
   * Pow the given AmountOfSubstance with the current AmountOfSubstance.
   * @param amountOfSubstance The other AmountOfSubstance.
   * @returns A new AmountOfSubstance instance with the results.
   */
  pow(amountOfSubstance) {
    return new AmountOfSubstance(super.internalPow(this.value, amountOfSubstance.BaseValue));
  }
}
exports.AmountOfSubstance = AmountOfSubstance;