"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AmplitudeRatio = exports.AmplitudeRatioUnits = void 0;
const base_unit_1 = require("../base-unit");
/** AmplitudeRatioUnits enumeration */
var AmplitudeRatioUnits;
(function (AmplitudeRatioUnits) {
  /** */
  AmplitudeRatioUnits["DecibelVolts"] = "DecibelVolt";
  /** */
  AmplitudeRatioUnits["DecibelMicrovolts"] = "DecibelMicrovolt";
  /** */
  AmplitudeRatioUnits["DecibelMillivolts"] = "DecibelMillivolt";
  /** */
  AmplitudeRatioUnits["DecibelsUnloaded"] = "DecibelUnloaded";
})(AmplitudeRatioUnits = exports.AmplitudeRatioUnits || (exports.AmplitudeRatioUnits = {}));
/** The strength of a signal expressed in decibels (dB) relative to one volt RMS. */
class AmplitudeRatio extends base_unit_1.BaseUnit {
  /**
   * Create a new AmplitudeRatio.
   * @param value The value.
   * @param fromUnit The ‘AmplitudeRatio’ unit to create from.
   * The default unit is DecibelVolts
   */
  constructor(value, fromUnit = AmplitudeRatioUnits.DecibelVolts) {
    super();
    this.decibelvoltsLazy = null;
    this.decibelmicrovoltsLazy = null;
    this.decibelmillivoltsLazy = null;
    this.decibelsunloadedLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of AmplitudeRatio is DecibelVolts.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return AmplitudeRatioUnits.DecibelVolts;
  }
  /** */
  get DecibelVolts() {
    if (this.decibelvoltsLazy !== null) {
      return this.decibelvoltsLazy;
    }
    return this.decibelvoltsLazy = this.convertFromBase(AmplitudeRatioUnits.DecibelVolts);
  }
  /** */
  get DecibelMicrovolts() {
    if (this.decibelmicrovoltsLazy !== null) {
      return this.decibelmicrovoltsLazy;
    }
    return this.decibelmicrovoltsLazy = this.convertFromBase(AmplitudeRatioUnits.DecibelMicrovolts);
  }
  /** */
  get DecibelMillivolts() {
    if (this.decibelmillivoltsLazy !== null) {
      return this.decibelmillivoltsLazy;
    }
    return this.decibelmillivoltsLazy = this.convertFromBase(AmplitudeRatioUnits.DecibelMillivolts);
  }
  /** */
  get DecibelsUnloaded() {
    if (this.decibelsunloadedLazy !== null) {
      return this.decibelsunloadedLazy;
    }
    return this.decibelsunloadedLazy = this.convertFromBase(AmplitudeRatioUnits.DecibelsUnloaded);
  }
  /**
   * Create a new AmplitudeRatio instance from a DecibelVolts
   *
   * @param value The unit as DecibelVolts to create a new AmplitudeRatio from.
   * @returns The new AmplitudeRatio instance.
   */
  static FromDecibelVolts(value) {
    return new AmplitudeRatio(value, AmplitudeRatioUnits.DecibelVolts);
  }
  /**
   * Create a new AmplitudeRatio instance from a DecibelMicrovolts
   *
   * @param value The unit as DecibelMicrovolts to create a new AmplitudeRatio from.
   * @returns The new AmplitudeRatio instance.
   */
  static FromDecibelMicrovolts(value) {
    return new AmplitudeRatio(value, AmplitudeRatioUnits.DecibelMicrovolts);
  }
  /**
   * Create a new AmplitudeRatio instance from a DecibelMillivolts
   *
   * @param value The unit as DecibelMillivolts to create a new AmplitudeRatio from.
   * @returns The new AmplitudeRatio instance.
   */
  static FromDecibelMillivolts(value) {
    return new AmplitudeRatio(value, AmplitudeRatioUnits.DecibelMillivolts);
  }
  /**
   * Create a new AmplitudeRatio instance from a DecibelsUnloaded
   *
   * @param value The unit as DecibelsUnloaded to create a new AmplitudeRatio from.
   * @returns The new AmplitudeRatio instance.
   */
  static FromDecibelsUnloaded(value) {
    return new AmplitudeRatio(value, AmplitudeRatioUnits.DecibelsUnloaded);
  }
  /**
   * Gets the base unit enumeration associated with AmplitudeRatio
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return AmplitudeRatioUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return AmplitudeRatioUnits.DecibelVolts;
  }
  /**
   * Create API DTO represent a AmplitudeRatio unit.
   * @param holdInUnit The specific AmplitudeRatio unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = AmplitudeRatioUnits.DecibelVolts) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a AmplitudeRatio unit from an API DTO representation.
   * @param dtoAmplitudeRatio The AmplitudeRatio API DTO representation
   */
  static FromDto(dtoAmplitudeRatio) {
    return new AmplitudeRatio(dtoAmplitudeRatio.value, dtoAmplitudeRatio.unit);
  }
  /**
   * Convert AmplitudeRatio to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case AmplitudeRatioUnits.DecibelVolts:
        return this.DecibelVolts;
      case AmplitudeRatioUnits.DecibelMicrovolts:
        return this.DecibelMicrovolts;
      case AmplitudeRatioUnits.DecibelMillivolts:
        return this.DecibelMillivolts;
      case AmplitudeRatioUnits.DecibelsUnloaded:
        return this.DecibelsUnloaded;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case AmplitudeRatioUnits.DecibelVolts:
        return this.value;
      case AmplitudeRatioUnits.DecibelMicrovolts:
        return super.internalAdd(this.value, 120);
      case AmplitudeRatioUnits.DecibelMillivolts:
        return super.internalAdd(this.value, 60);
      case AmplitudeRatioUnits.DecibelsUnloaded:
        return super.internalAdd(this.value, 2.218487499);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case AmplitudeRatioUnits.DecibelVolts:
        return this.value;
      case AmplitudeRatioUnits.DecibelMicrovolts:
        return this.value + 120;
      case AmplitudeRatioUnits.DecibelMillivolts:
        return this.value + 60;
      case AmplitudeRatioUnits.DecibelsUnloaded:
        return this.value + 2.218487499;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case AmplitudeRatioUnits.DecibelVolts:
        return value;
      case AmplitudeRatioUnits.DecibelMicrovolts:
        return super.internalSubtract(value, 120);
      case AmplitudeRatioUnits.DecibelMillivolts:
        return super.internalSubtract(value, 60);
      case AmplitudeRatioUnits.DecibelsUnloaded:
        return super.internalSubtract(value, 2.218487499);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case AmplitudeRatioUnits.DecibelVolts:
        return value;
      case AmplitudeRatioUnits.DecibelMicrovolts:
        return value - 120;
      case AmplitudeRatioUnits.DecibelMillivolts:
        return value - 60;
      case AmplitudeRatioUnits.DecibelsUnloaded:
        return value - 2.218487499;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the AmplitudeRatio to string.
   * Note! the default format for AmplitudeRatio is DecibelVolts.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the AmplitudeRatio.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the AmplitudeRatio.
   */
  toString(unit = AmplitudeRatioUnits.DecibelVolts, fractionalDigits) {
    switch (unit) {
      case AmplitudeRatioUnits.DecibelVolts:
        return super.truncateFractionDigits(this.DecibelVolts, fractionalDigits) + ` dBV`;
      case AmplitudeRatioUnits.DecibelMicrovolts:
        return super.truncateFractionDigits(this.DecibelMicrovolts, fractionalDigits) + ` dBµV`;
      case AmplitudeRatioUnits.DecibelMillivolts:
        return super.truncateFractionDigits(this.DecibelMillivolts, fractionalDigits) + ` dBmV`;
      case AmplitudeRatioUnits.DecibelsUnloaded:
        return super.truncateFractionDigits(this.DecibelsUnloaded, fractionalDigits) + ` dBu`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get AmplitudeRatio unit abbreviation.
   * Note! the default abbreviation for AmplitudeRatio is DecibelVolts.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the AmplitudeRatio.
   * @returns The abbreviation string of AmplitudeRatio.
   */
  getUnitAbbreviation(unitAbbreviation = AmplitudeRatioUnits.DecibelVolts) {
    switch (unitAbbreviation) {
      case AmplitudeRatioUnits.DecibelVolts:
        return `dBV`;
      case AmplitudeRatioUnits.DecibelMicrovolts:
        return `dBµV`;
      case AmplitudeRatioUnits.DecibelMillivolts:
        return `dBmV`;
      case AmplitudeRatioUnits.DecibelsUnloaded:
        return `dBu`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given AmplitudeRatio are equals to the current AmplitudeRatio.
   * @param amplitudeRatio The other AmplitudeRatio.
   * @returns True if the given AmplitudeRatio are equal to the current AmplitudeRatio.
   */
  equals(amplitudeRatio) {
    return super.internalEquals(this.value, amplitudeRatio.BaseValue);
  }
  /**
   * Compare the given AmplitudeRatio against the current AmplitudeRatio.
   * @param amplitudeRatio The other AmplitudeRatio.
   * @returns 0 if they are equal, -1 if the current AmplitudeRatio is less then other, 1 if the current AmplitudeRatio is greater then other.
   */
  compareTo(amplitudeRatio) {
    return super.internalCompareTo(this.value, amplitudeRatio.BaseValue);
  }
  /**
   * Add the given AmplitudeRatio with the current AmplitudeRatio.
   * @param amplitudeRatio The other AmplitudeRatio.
   * @returns A new AmplitudeRatio instance with the results.
   */
  add(amplitudeRatio) {
    return new AmplitudeRatio(super.internalAdd(this.value, amplitudeRatio.BaseValue));
  }
  /**
   * Subtract the given AmplitudeRatio with the current AmplitudeRatio.
   * @param amplitudeRatio The other AmplitudeRatio.
   * @returns A new AmplitudeRatio instance with the results.
   */
  subtract(amplitudeRatio) {
    return new AmplitudeRatio(super.internalSubtract(this.value, amplitudeRatio.BaseValue));
  }
  /**
   * Multiply the given AmplitudeRatio with the current AmplitudeRatio.
   * @param amplitudeRatio The other AmplitudeRatio.
   * @returns A new AmplitudeRatio instance with the results.
   */
  multiply(amplitudeRatio) {
    return new AmplitudeRatio(super.internalMultiply(this.value, amplitudeRatio.BaseValue));
  }
  /**
   * Divide the given AmplitudeRatio with the current AmplitudeRatio.
   * @param amplitudeRatio The other AmplitudeRatio.
   * @returns A new AmplitudeRatio instance with the results.
   */
  divide(amplitudeRatio) {
    return new AmplitudeRatio(super.internalDivide(this.value, amplitudeRatio.BaseValue));
  }
  /**
   * Modulo the given AmplitudeRatio with the current AmplitudeRatio.
   * @param amplitudeRatio The other AmplitudeRatio.
   * @returns A new AmplitudeRatio instance with the results.
   */
  modulo(amplitudeRatio) {
    return new AmplitudeRatio(super.internalModulo(this.value, amplitudeRatio.BaseValue));
  }
  /**
   * Pow the given AmplitudeRatio with the current AmplitudeRatio.
   * @param amplitudeRatio The other AmplitudeRatio.
   * @returns A new AmplitudeRatio instance with the results.
   */
  pow(amplitudeRatio) {
    return new AmplitudeRatio(super.internalPow(this.value, amplitudeRatio.BaseValue));
  }
}
exports.AmplitudeRatio = AmplitudeRatio;