"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MassFraction = exports.MassFractionUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MassFractionUnits enumeration */
var MassFractionUnits;
(function (MassFractionUnits) {
  /** */
  MassFractionUnits["DecimalFractions"] = "DecimalFraction";
  /** */
  MassFractionUnits["GramsPerGram"] = "GramPerGram";
  /** */
  MassFractionUnits["GramsPerKilogram"] = "GramPerKilogram";
  /** */
  MassFractionUnits["Percent"] = "Percent";
  /** */
  MassFractionUnits["PartsPerThousand"] = "PartPerThousand";
  /** */
  MassFractionUnits["PartsPerMillion"] = "PartPerMillion";
  /** */
  MassFractionUnits["PartsPerBillion"] = "PartPerBillion";
  /** */
  MassFractionUnits["PartsPerTrillion"] = "PartPerTrillion";
  /** */
  MassFractionUnits["NanogramsPerGram"] = "NanogramPerGram";
  /** */
  MassFractionUnits["MicrogramsPerGram"] = "MicrogramPerGram";
  /** */
  MassFractionUnits["MilligramsPerGram"] = "MilligramPerGram";
  /** */
  MassFractionUnits["CentigramsPerGram"] = "CentigramPerGram";
  /** */
  MassFractionUnits["DecigramsPerGram"] = "DecigramPerGram";
  /** */
  MassFractionUnits["DecagramsPerGram"] = "DecagramPerGram";
  /** */
  MassFractionUnits["HectogramsPerGram"] = "HectogramPerGram";
  /** */
  MassFractionUnits["KilogramsPerGram"] = "KilogramPerGram";
  /** */
  MassFractionUnits["NanogramsPerKilogram"] = "NanogramPerKilogram";
  /** */
  MassFractionUnits["MicrogramsPerKilogram"] = "MicrogramPerKilogram";
  /** */
  MassFractionUnits["MilligramsPerKilogram"] = "MilligramPerKilogram";
  /** */
  MassFractionUnits["CentigramsPerKilogram"] = "CentigramPerKilogram";
  /** */
  MassFractionUnits["DecigramsPerKilogram"] = "DecigramPerKilogram";
  /** */
  MassFractionUnits["DecagramsPerKilogram"] = "DecagramPerKilogram";
  /** */
  MassFractionUnits["HectogramsPerKilogram"] = "HectogramPerKilogram";
  /** */
  MassFractionUnits["KilogramsPerKilogram"] = "KilogramPerKilogram";
})(MassFractionUnits = exports.MassFractionUnits || (exports.MassFractionUnits = {}));
/** The mass fraction is defined as the mass of a constituent divided by the total mass of the mixture. */
class MassFraction extends base_unit_1.BaseUnit {
  /**
   * Create a new MassFraction.
   * @param value The value.
   * @param fromUnit The ‘MassFraction’ unit to create from.
   * The default unit is DecimalFractions
   */
  constructor(value, fromUnit = MassFractionUnits.DecimalFractions) {
    super();
    this.decimalfractionsLazy = null;
    this.gramspergramLazy = null;
    this.gramsperkilogramLazy = null;
    this.percentLazy = null;
    this.partsperthousandLazy = null;
    this.partspermillionLazy = null;
    this.partsperbillionLazy = null;
    this.partspertrillionLazy = null;
    this.nanogramspergramLazy = null;
    this.microgramspergramLazy = null;
    this.milligramspergramLazy = null;
    this.centigramspergramLazy = null;
    this.decigramspergramLazy = null;
    this.decagramspergramLazy = null;
    this.hectogramspergramLazy = null;
    this.kilogramspergramLazy = null;
    this.nanogramsperkilogramLazy = null;
    this.microgramsperkilogramLazy = null;
    this.milligramsperkilogramLazy = null;
    this.centigramsperkilogramLazy = null;
    this.decigramsperkilogramLazy = null;
    this.decagramsperkilogramLazy = null;
    this.hectogramsperkilogramLazy = null;
    this.kilogramsperkilogramLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of MassFraction is DecimalFractions.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MassFractionUnits.DecimalFractions;
  }
  /** */
  get DecimalFractions() {
    if (this.decimalfractionsLazy !== null) {
      return this.decimalfractionsLazy;
    }
    return this.decimalfractionsLazy = this.convertFromBase(MassFractionUnits.DecimalFractions);
  }
  /** */
  get GramsPerGram() {
    if (this.gramspergramLazy !== null) {
      return this.gramspergramLazy;
    }
    return this.gramspergramLazy = this.convertFromBase(MassFractionUnits.GramsPerGram);
  }
  /** */
  get GramsPerKilogram() {
    if (this.gramsperkilogramLazy !== null) {
      return this.gramsperkilogramLazy;
    }
    return this.gramsperkilogramLazy = this.convertFromBase(MassFractionUnits.GramsPerKilogram);
  }
  /** */
  get Percent() {
    if (this.percentLazy !== null) {
      return this.percentLazy;
    }
    return this.percentLazy = this.convertFromBase(MassFractionUnits.Percent);
  }
  /** */
  get PartsPerThousand() {
    if (this.partsperthousandLazy !== null) {
      return this.partsperthousandLazy;
    }
    return this.partsperthousandLazy = this.convertFromBase(MassFractionUnits.PartsPerThousand);
  }
  /** */
  get PartsPerMillion() {
    if (this.partspermillionLazy !== null) {
      return this.partspermillionLazy;
    }
    return this.partspermillionLazy = this.convertFromBase(MassFractionUnits.PartsPerMillion);
  }
  /** */
  get PartsPerBillion() {
    if (this.partsperbillionLazy !== null) {
      return this.partsperbillionLazy;
    }
    return this.partsperbillionLazy = this.convertFromBase(MassFractionUnits.PartsPerBillion);
  }
  /** */
  get PartsPerTrillion() {
    if (this.partspertrillionLazy !== null) {
      return this.partspertrillionLazy;
    }
    return this.partspertrillionLazy = this.convertFromBase(MassFractionUnits.PartsPerTrillion);
  }
  /** */
  get NanogramsPerGram() {
    if (this.nanogramspergramLazy !== null) {
      return this.nanogramspergramLazy;
    }
    return this.nanogramspergramLazy = this.convertFromBase(MassFractionUnits.NanogramsPerGram);
  }
  /** */
  get MicrogramsPerGram() {
    if (this.microgramspergramLazy !== null) {
      return this.microgramspergramLazy;
    }
    return this.microgramspergramLazy = this.convertFromBase(MassFractionUnits.MicrogramsPerGram);
  }
  /** */
  get MilligramsPerGram() {
    if (this.milligramspergramLazy !== null) {
      return this.milligramspergramLazy;
    }
    return this.milligramspergramLazy = this.convertFromBase(MassFractionUnits.MilligramsPerGram);
  }
  /** */
  get CentigramsPerGram() {
    if (this.centigramspergramLazy !== null) {
      return this.centigramspergramLazy;
    }
    return this.centigramspergramLazy = this.convertFromBase(MassFractionUnits.CentigramsPerGram);
  }
  /** */
  get DecigramsPerGram() {
    if (this.decigramspergramLazy !== null) {
      return this.decigramspergramLazy;
    }
    return this.decigramspergramLazy = this.convertFromBase(MassFractionUnits.DecigramsPerGram);
  }
  /** */
  get DecagramsPerGram() {
    if (this.decagramspergramLazy !== null) {
      return this.decagramspergramLazy;
    }
    return this.decagramspergramLazy = this.convertFromBase(MassFractionUnits.DecagramsPerGram);
  }
  /** */
  get HectogramsPerGram() {
    if (this.hectogramspergramLazy !== null) {
      return this.hectogramspergramLazy;
    }
    return this.hectogramspergramLazy = this.convertFromBase(MassFractionUnits.HectogramsPerGram);
  }
  /** */
  get KilogramsPerGram() {
    if (this.kilogramspergramLazy !== null) {
      return this.kilogramspergramLazy;
    }
    return this.kilogramspergramLazy = this.convertFromBase(MassFractionUnits.KilogramsPerGram);
  }
  /** */
  get NanogramsPerKilogram() {
    if (this.nanogramsperkilogramLazy !== null) {
      return this.nanogramsperkilogramLazy;
    }
    return this.nanogramsperkilogramLazy = this.convertFromBase(MassFractionUnits.NanogramsPerKilogram);
  }
  /** */
  get MicrogramsPerKilogram() {
    if (this.microgramsperkilogramLazy !== null) {
      return this.microgramsperkilogramLazy;
    }
    return this.microgramsperkilogramLazy = this.convertFromBase(MassFractionUnits.MicrogramsPerKilogram);
  }
  /** */
  get MilligramsPerKilogram() {
    if (this.milligramsperkilogramLazy !== null) {
      return this.milligramsperkilogramLazy;
    }
    return this.milligramsperkilogramLazy = this.convertFromBase(MassFractionUnits.MilligramsPerKilogram);
  }
  /** */
  get CentigramsPerKilogram() {
    if (this.centigramsperkilogramLazy !== null) {
      return this.centigramsperkilogramLazy;
    }
    return this.centigramsperkilogramLazy = this.convertFromBase(MassFractionUnits.CentigramsPerKilogram);
  }
  /** */
  get DecigramsPerKilogram() {
    if (this.decigramsperkilogramLazy !== null) {
      return this.decigramsperkilogramLazy;
    }
    return this.decigramsperkilogramLazy = this.convertFromBase(MassFractionUnits.DecigramsPerKilogram);
  }
  /** */
  get DecagramsPerKilogram() {
    if (this.decagramsperkilogramLazy !== null) {
      return this.decagramsperkilogramLazy;
    }
    return this.decagramsperkilogramLazy = this.convertFromBase(MassFractionUnits.DecagramsPerKilogram);
  }
  /** */
  get HectogramsPerKilogram() {
    if (this.hectogramsperkilogramLazy !== null) {
      return this.hectogramsperkilogramLazy;
    }
    return this.hectogramsperkilogramLazy = this.convertFromBase(MassFractionUnits.HectogramsPerKilogram);
  }
  /** */
  get KilogramsPerKilogram() {
    if (this.kilogramsperkilogramLazy !== null) {
      return this.kilogramsperkilogramLazy;
    }
    return this.kilogramsperkilogramLazy = this.convertFromBase(MassFractionUnits.KilogramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a DecimalFractions
   *
   * @param value The unit as DecimalFractions to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromDecimalFractions(value) {
    return new MassFraction(value, MassFractionUnits.DecimalFractions);
  }
  /**
   * Create a new MassFraction instance from a GramsPerGram
   *
   * @param value The unit as GramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromGramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.GramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a GramsPerKilogram
   *
   * @param value The unit as GramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromGramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.GramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a Percent
   *
   * @param value The unit as Percent to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromPercent(value) {
    return new MassFraction(value, MassFractionUnits.Percent);
  }
  /**
   * Create a new MassFraction instance from a PartsPerThousand
   *
   * @param value The unit as PartsPerThousand to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromPartsPerThousand(value) {
    return new MassFraction(value, MassFractionUnits.PartsPerThousand);
  }
  /**
   * Create a new MassFraction instance from a PartsPerMillion
   *
   * @param value The unit as PartsPerMillion to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromPartsPerMillion(value) {
    return new MassFraction(value, MassFractionUnits.PartsPerMillion);
  }
  /**
   * Create a new MassFraction instance from a PartsPerBillion
   *
   * @param value The unit as PartsPerBillion to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromPartsPerBillion(value) {
    return new MassFraction(value, MassFractionUnits.PartsPerBillion);
  }
  /**
   * Create a new MassFraction instance from a PartsPerTrillion
   *
   * @param value The unit as PartsPerTrillion to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromPartsPerTrillion(value) {
    return new MassFraction(value, MassFractionUnits.PartsPerTrillion);
  }
  /**
   * Create a new MassFraction instance from a NanogramsPerGram
   *
   * @param value The unit as NanogramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromNanogramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.NanogramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a MicrogramsPerGram
   *
   * @param value The unit as MicrogramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromMicrogramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.MicrogramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a MilligramsPerGram
   *
   * @param value The unit as MilligramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromMilligramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.MilligramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a CentigramsPerGram
   *
   * @param value The unit as CentigramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromCentigramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.CentigramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a DecigramsPerGram
   *
   * @param value The unit as DecigramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromDecigramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.DecigramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a DecagramsPerGram
   *
   * @param value The unit as DecagramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromDecagramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.DecagramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a HectogramsPerGram
   *
   * @param value The unit as HectogramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromHectogramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.HectogramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a KilogramsPerGram
   *
   * @param value The unit as KilogramsPerGram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromKilogramsPerGram(value) {
    return new MassFraction(value, MassFractionUnits.KilogramsPerGram);
  }
  /**
   * Create a new MassFraction instance from a NanogramsPerKilogram
   *
   * @param value The unit as NanogramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromNanogramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.NanogramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a MicrogramsPerKilogram
   *
   * @param value The unit as MicrogramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromMicrogramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.MicrogramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a MilligramsPerKilogram
   *
   * @param value The unit as MilligramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromMilligramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.MilligramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a CentigramsPerKilogram
   *
   * @param value The unit as CentigramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromCentigramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.CentigramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a DecigramsPerKilogram
   *
   * @param value The unit as DecigramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromDecigramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.DecigramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a DecagramsPerKilogram
   *
   * @param value The unit as DecagramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromDecagramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.DecagramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a HectogramsPerKilogram
   *
   * @param value The unit as HectogramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromHectogramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.HectogramsPerKilogram);
  }
  /**
   * Create a new MassFraction instance from a KilogramsPerKilogram
   *
   * @param value The unit as KilogramsPerKilogram to create a new MassFraction from.
   * @returns The new MassFraction instance.
   */
  static FromKilogramsPerKilogram(value) {
    return new MassFraction(value, MassFractionUnits.KilogramsPerKilogram);
  }
  /**
   * Gets the base unit enumeration associated with MassFraction
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MassFractionUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MassFractionUnits.DecimalFractions;
  }
  /**
   * Create API DTO represent a MassFraction unit.
   * @param holdInUnit The specific MassFraction unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MassFractionUnits.DecimalFractions) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a MassFraction unit from an API DTO representation.
   * @param dtoMassFraction The MassFraction API DTO representation
   */
  static FromDto(dtoMassFraction) {
    return new MassFraction(dtoMassFraction.value, dtoMassFraction.unit);
  }
  /**
   * Convert MassFraction to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MassFractionUnits.DecimalFractions:
        return this.DecimalFractions;
      case MassFractionUnits.GramsPerGram:
        return this.GramsPerGram;
      case MassFractionUnits.GramsPerKilogram:
        return this.GramsPerKilogram;
      case MassFractionUnits.Percent:
        return this.Percent;
      case MassFractionUnits.PartsPerThousand:
        return this.PartsPerThousand;
      case MassFractionUnits.PartsPerMillion:
        return this.PartsPerMillion;
      case MassFractionUnits.PartsPerBillion:
        return this.PartsPerBillion;
      case MassFractionUnits.PartsPerTrillion:
        return this.PartsPerTrillion;
      case MassFractionUnits.NanogramsPerGram:
        return this.NanogramsPerGram;
      case MassFractionUnits.MicrogramsPerGram:
        return this.MicrogramsPerGram;
      case MassFractionUnits.MilligramsPerGram:
        return this.MilligramsPerGram;
      case MassFractionUnits.CentigramsPerGram:
        return this.CentigramsPerGram;
      case MassFractionUnits.DecigramsPerGram:
        return this.DecigramsPerGram;
      case MassFractionUnits.DecagramsPerGram:
        return this.DecagramsPerGram;
      case MassFractionUnits.HectogramsPerGram:
        return this.HectogramsPerGram;
      case MassFractionUnits.KilogramsPerGram:
        return this.KilogramsPerGram;
      case MassFractionUnits.NanogramsPerKilogram:
        return this.NanogramsPerKilogram;
      case MassFractionUnits.MicrogramsPerKilogram:
        return this.MicrogramsPerKilogram;
      case MassFractionUnits.MilligramsPerKilogram:
        return this.MilligramsPerKilogram;
      case MassFractionUnits.CentigramsPerKilogram:
        return this.CentigramsPerKilogram;
      case MassFractionUnits.DecigramsPerKilogram:
        return this.DecigramsPerKilogram;
      case MassFractionUnits.DecagramsPerKilogram:
        return this.DecagramsPerKilogram;
      case MassFractionUnits.HectogramsPerKilogram:
        return this.HectogramsPerKilogram;
      case MassFractionUnits.KilogramsPerKilogram:
        return this.KilogramsPerKilogram;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MassFractionUnits.DecimalFractions:
        return this.value;
      case MassFractionUnits.GramsPerGram:
        return this.value;
      case MassFractionUnits.GramsPerKilogram:
        return super.internalMultiply(this.value, 1e3);
      case MassFractionUnits.Percent:
        return super.internalMultiply(this.value, 1e2);
      case MassFractionUnits.PartsPerThousand:
        return super.internalMultiply(this.value, 1e3);
      case MassFractionUnits.PartsPerMillion:
        return super.internalMultiply(this.value, 1e6);
      case MassFractionUnits.PartsPerBillion:
        return super.internalMultiply(this.value, 1e9);
      case MassFractionUnits.PartsPerTrillion:
        return super.internalMultiply(this.value, 1e12);
      case MassFractionUnits.NanogramsPerGram:
        return super.internalDivide(this.value, 1e-9);
      case MassFractionUnits.MicrogramsPerGram:
        return super.internalDivide(this.value, 0.000001);
      case MassFractionUnits.MilligramsPerGram:
        return super.internalDivide(this.value, 0.001);
      case MassFractionUnits.CentigramsPerGram:
        return super.internalDivide(this.value, 0.01);
      case MassFractionUnits.DecigramsPerGram:
        return super.internalDivide(this.value, 0.1);
      case MassFractionUnits.DecagramsPerGram:
        return super.internalDivide(this.value, 10);
      case MassFractionUnits.HectogramsPerGram:
        return super.internalDivide(this.value, 100);
      case MassFractionUnits.KilogramsPerGram:
        return super.internalDivide(this.value, 1000);
      case MassFractionUnits.NanogramsPerKilogram:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1e-9);
        }
      case MassFractionUnits.MicrogramsPerKilogram:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.000001);
        }
      case MassFractionUnits.MilligramsPerKilogram:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.001);
        }
      case MassFractionUnits.CentigramsPerKilogram:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.01);
        }
      case MassFractionUnits.DecigramsPerKilogram:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.1);
        }
      case MassFractionUnits.DecagramsPerKilogram:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 10);
        }
      case MassFractionUnits.HectogramsPerKilogram:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 100);
        }
      case MassFractionUnits.KilogramsPerKilogram:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MassFractionUnits.DecimalFractions:
        return this.value;
      case MassFractionUnits.GramsPerGram:
        return this.value;
      case MassFractionUnits.GramsPerKilogram:
        return this.value * 1e3;
      case MassFractionUnits.Percent:
        return this.value * 1e2;
      case MassFractionUnits.PartsPerThousand:
        return this.value * 1e3;
      case MassFractionUnits.PartsPerMillion:
        return this.value * 1e6;
      case MassFractionUnits.PartsPerBillion:
        return this.value * 1e9;
      case MassFractionUnits.PartsPerTrillion:
        return this.value * 1e12;
      case MassFractionUnits.NanogramsPerGram:
        return this.value / 1e-9;
      case MassFractionUnits.MicrogramsPerGram:
        return this.value / 0.000001;
      case MassFractionUnits.MilligramsPerGram:
        return this.value / 0.001;
      case MassFractionUnits.CentigramsPerGram:
        return this.value / 0.01;
      case MassFractionUnits.DecigramsPerGram:
        return this.value / 0.1;
      case MassFractionUnits.DecagramsPerGram:
        return this.value / 10;
      case MassFractionUnits.HectogramsPerGram:
        return this.value / 100;
      case MassFractionUnits.KilogramsPerGram:
        return this.value / 1000;
      case MassFractionUnits.NanogramsPerKilogram:
        return this.value * 1e3 / 1e-9;
      case MassFractionUnits.MicrogramsPerKilogram:
        return this.value * 1e3 / 0.000001;
      case MassFractionUnits.MilligramsPerKilogram:
        return this.value * 1e3 / 0.001;
      case MassFractionUnits.CentigramsPerKilogram:
        return this.value * 1e3 / 0.01;
      case MassFractionUnits.DecigramsPerKilogram:
        return this.value * 1e3 / 0.1;
      case MassFractionUnits.DecagramsPerKilogram:
        return this.value * 1e3 / 10;
      case MassFractionUnits.HectogramsPerKilogram:
        return this.value * 1e3 / 100;
      case MassFractionUnits.KilogramsPerKilogram:
        return this.value * 1e3 / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MassFractionUnits.DecimalFractions:
        return value;
      case MassFractionUnits.GramsPerGram:
        return value;
      case MassFractionUnits.GramsPerKilogram:
        return super.internalDivide(value, 1e3);
      case MassFractionUnits.Percent:
        return super.internalDivide(value, 1e2);
      case MassFractionUnits.PartsPerThousand:
        return super.internalDivide(value, 1e3);
      case MassFractionUnits.PartsPerMillion:
        return super.internalDivide(value, 1e6);
      case MassFractionUnits.PartsPerBillion:
        return super.internalDivide(value, 1e9);
      case MassFractionUnits.PartsPerTrillion:
        return super.internalDivide(value, 1e12);
      case MassFractionUnits.NanogramsPerGram:
        return super.internalMultiply(value, 1e-9);
      case MassFractionUnits.MicrogramsPerGram:
        return super.internalMultiply(value, 0.000001);
      case MassFractionUnits.MilligramsPerGram:
        return super.internalMultiply(value, 0.001);
      case MassFractionUnits.CentigramsPerGram:
        return super.internalMultiply(value, 0.01);
      case MassFractionUnits.DecigramsPerGram:
        return super.internalMultiply(value, 0.1);
      case MassFractionUnits.DecagramsPerGram:
        return super.internalMultiply(value, 10);
      case MassFractionUnits.HectogramsPerGram:
        return super.internalMultiply(value, 100);
      case MassFractionUnits.KilogramsPerGram:
        return super.internalMultiply(value, 1000);
      case MassFractionUnits.NanogramsPerKilogram:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1e-9);
        }
      case MassFractionUnits.MicrogramsPerKilogram:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.000001);
        }
      case MassFractionUnits.MilligramsPerKilogram:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.001);
        }
      case MassFractionUnits.CentigramsPerKilogram:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.01);
        }
      case MassFractionUnits.DecigramsPerKilogram:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.1);
        }
      case MassFractionUnits.DecagramsPerKilogram:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 10);
        }
      case MassFractionUnits.HectogramsPerKilogram:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 100);
        }
      case MassFractionUnits.KilogramsPerKilogram:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MassFractionUnits.DecimalFractions:
        return value;
      case MassFractionUnits.GramsPerGram:
        return value;
      case MassFractionUnits.GramsPerKilogram:
        return value / 1e3;
      case MassFractionUnits.Percent:
        return value / 1e2;
      case MassFractionUnits.PartsPerThousand:
        return value / 1e3;
      case MassFractionUnits.PartsPerMillion:
        return value / 1e6;
      case MassFractionUnits.PartsPerBillion:
        return value / 1e9;
      case MassFractionUnits.PartsPerTrillion:
        return value / 1e12;
      case MassFractionUnits.NanogramsPerGram:
        return value * 1e-9;
      case MassFractionUnits.MicrogramsPerGram:
        return value * 0.000001;
      case MassFractionUnits.MilligramsPerGram:
        return value * 0.001;
      case MassFractionUnits.CentigramsPerGram:
        return value * 0.01;
      case MassFractionUnits.DecigramsPerGram:
        return value * 0.1;
      case MassFractionUnits.DecagramsPerGram:
        return value * 10;
      case MassFractionUnits.HectogramsPerGram:
        return value * 100;
      case MassFractionUnits.KilogramsPerGram:
        return value * 1000;
      case MassFractionUnits.NanogramsPerKilogram:
        return value / 1e3 * 1e-9;
      case MassFractionUnits.MicrogramsPerKilogram:
        return value / 1e3 * 0.000001;
      case MassFractionUnits.MilligramsPerKilogram:
        return value / 1e3 * 0.001;
      case MassFractionUnits.CentigramsPerKilogram:
        return value / 1e3 * 0.01;
      case MassFractionUnits.DecigramsPerKilogram:
        return value / 1e3 * 0.1;
      case MassFractionUnits.DecagramsPerKilogram:
        return value / 1e3 * 10;
      case MassFractionUnits.HectogramsPerKilogram:
        return value / 1e3 * 100;
      case MassFractionUnits.KilogramsPerKilogram:
        return value / 1e3 * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the MassFraction to string.
   * Note! the default format for MassFraction is DecimalFractions.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the MassFraction.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the MassFraction.
   */
  toString(unit = MassFractionUnits.DecimalFractions, fractionalDigits) {
    switch (unit) {
      case MassFractionUnits.DecimalFractions:
        return super.truncateFractionDigits(this.DecimalFractions, fractionalDigits) + ` `;
      case MassFractionUnits.GramsPerGram:
        return super.truncateFractionDigits(this.GramsPerGram, fractionalDigits) + ` g/g`;
      case MassFractionUnits.GramsPerKilogram:
        return super.truncateFractionDigits(this.GramsPerKilogram, fractionalDigits) + ` g/kg`;
      case MassFractionUnits.Percent:
        return super.truncateFractionDigits(this.Percent, fractionalDigits) + ` %`;
      case MassFractionUnits.PartsPerThousand:
        return super.truncateFractionDigits(this.PartsPerThousand, fractionalDigits) + ` ‰`;
      case MassFractionUnits.PartsPerMillion:
        return super.truncateFractionDigits(this.PartsPerMillion, fractionalDigits) + ` ppm`;
      case MassFractionUnits.PartsPerBillion:
        return super.truncateFractionDigits(this.PartsPerBillion, fractionalDigits) + ` ppb`;
      case MassFractionUnits.PartsPerTrillion:
        return super.truncateFractionDigits(this.PartsPerTrillion, fractionalDigits) + ` ppt`;
      case MassFractionUnits.NanogramsPerGram:
        return super.truncateFractionDigits(this.NanogramsPerGram, fractionalDigits) + ` ng/g`;
      case MassFractionUnits.MicrogramsPerGram:
        return super.truncateFractionDigits(this.MicrogramsPerGram, fractionalDigits) + ` μg/g`;
      case MassFractionUnits.MilligramsPerGram:
        return super.truncateFractionDigits(this.MilligramsPerGram, fractionalDigits) + ` mg/g`;
      case MassFractionUnits.CentigramsPerGram:
        return super.truncateFractionDigits(this.CentigramsPerGram, fractionalDigits) + ` cg/g`;
      case MassFractionUnits.DecigramsPerGram:
        return super.truncateFractionDigits(this.DecigramsPerGram, fractionalDigits) + ` dg/g`;
      case MassFractionUnits.DecagramsPerGram:
        return super.truncateFractionDigits(this.DecagramsPerGram, fractionalDigits) + ` dag/g`;
      case MassFractionUnits.HectogramsPerGram:
        return super.truncateFractionDigits(this.HectogramsPerGram, fractionalDigits) + ` hg/g`;
      case MassFractionUnits.KilogramsPerGram:
        return super.truncateFractionDigits(this.KilogramsPerGram, fractionalDigits) + ` kg/g`;
      case MassFractionUnits.NanogramsPerKilogram:
        return super.truncateFractionDigits(this.NanogramsPerKilogram, fractionalDigits) + ` ng/kg`;
      case MassFractionUnits.MicrogramsPerKilogram:
        return super.truncateFractionDigits(this.MicrogramsPerKilogram, fractionalDigits) + ` μg/kg`;
      case MassFractionUnits.MilligramsPerKilogram:
        return super.truncateFractionDigits(this.MilligramsPerKilogram, fractionalDigits) + ` mg/kg`;
      case MassFractionUnits.CentigramsPerKilogram:
        return super.truncateFractionDigits(this.CentigramsPerKilogram, fractionalDigits) + ` cg/kg`;
      case MassFractionUnits.DecigramsPerKilogram:
        return super.truncateFractionDigits(this.DecigramsPerKilogram, fractionalDigits) + ` dg/kg`;
      case MassFractionUnits.DecagramsPerKilogram:
        return super.truncateFractionDigits(this.DecagramsPerKilogram, fractionalDigits) + ` dag/kg`;
      case MassFractionUnits.HectogramsPerKilogram:
        return super.truncateFractionDigits(this.HectogramsPerKilogram, fractionalDigits) + ` hg/kg`;
      case MassFractionUnits.KilogramsPerKilogram:
        return super.truncateFractionDigits(this.KilogramsPerKilogram, fractionalDigits) + ` kg/kg`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get MassFraction unit abbreviation.
   * Note! the default abbreviation for MassFraction is DecimalFractions.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the MassFraction.
   * @returns The abbreviation string of MassFraction.
   */
  getUnitAbbreviation(unitAbbreviation = MassFractionUnits.DecimalFractions) {
    switch (unitAbbreviation) {
      case MassFractionUnits.DecimalFractions:
        return ``;
      case MassFractionUnits.GramsPerGram:
        return `g/g`;
      case MassFractionUnits.GramsPerKilogram:
        return `g/kg`;
      case MassFractionUnits.Percent:
        return `%`;
      case MassFractionUnits.PartsPerThousand:
        return `‰`;
      case MassFractionUnits.PartsPerMillion:
        return `ppm`;
      case MassFractionUnits.PartsPerBillion:
        return `ppb`;
      case MassFractionUnits.PartsPerTrillion:
        return `ppt`;
      case MassFractionUnits.NanogramsPerGram:
        return `ng/g`;
      case MassFractionUnits.MicrogramsPerGram:
        return `μg/g`;
      case MassFractionUnits.MilligramsPerGram:
        return `mg/g`;
      case MassFractionUnits.CentigramsPerGram:
        return `cg/g`;
      case MassFractionUnits.DecigramsPerGram:
        return `dg/g`;
      case MassFractionUnits.DecagramsPerGram:
        return `dag/g`;
      case MassFractionUnits.HectogramsPerGram:
        return `hg/g`;
      case MassFractionUnits.KilogramsPerGram:
        return `kg/g`;
      case MassFractionUnits.NanogramsPerKilogram:
        return `ng/kg`;
      case MassFractionUnits.MicrogramsPerKilogram:
        return `μg/kg`;
      case MassFractionUnits.MilligramsPerKilogram:
        return `mg/kg`;
      case MassFractionUnits.CentigramsPerKilogram:
        return `cg/kg`;
      case MassFractionUnits.DecigramsPerKilogram:
        return `dg/kg`;
      case MassFractionUnits.DecagramsPerKilogram:
        return `dag/kg`;
      case MassFractionUnits.HectogramsPerKilogram:
        return `hg/kg`;
      case MassFractionUnits.KilogramsPerKilogram:
        return `kg/kg`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given MassFraction are equals to the current MassFraction.
   * @param massFraction The other MassFraction.
   * @returns True if the given MassFraction are equal to the current MassFraction.
   */
  equals(massFraction) {
    return super.internalEquals(this.value, massFraction.BaseValue);
  }
  /**
   * Compare the given MassFraction against the current MassFraction.
   * @param massFraction The other MassFraction.
   * @returns 0 if they are equal, -1 if the current MassFraction is less then other, 1 if the current MassFraction is greater then other.
   */
  compareTo(massFraction) {
    return super.internalCompareTo(this.value, massFraction.BaseValue);
  }
  /**
   * Add the given MassFraction with the current MassFraction.
   * @param massFraction The other MassFraction.
   * @returns A new MassFraction instance with the results.
   */
  add(massFraction) {
    return new MassFraction(super.internalAdd(this.value, massFraction.BaseValue));
  }
  /**
   * Subtract the given MassFraction with the current MassFraction.
   * @param massFraction The other MassFraction.
   * @returns A new MassFraction instance with the results.
   */
  subtract(massFraction) {
    return new MassFraction(super.internalSubtract(this.value, massFraction.BaseValue));
  }
  /**
   * Multiply the given MassFraction with the current MassFraction.
   * @param massFraction The other MassFraction.
   * @returns A new MassFraction instance with the results.
   */
  multiply(massFraction) {
    return new MassFraction(super.internalMultiply(this.value, massFraction.BaseValue));
  }
  /**
   * Divide the given MassFraction with the current MassFraction.
   * @param massFraction The other MassFraction.
   * @returns A new MassFraction instance with the results.
   */
  divide(massFraction) {
    return new MassFraction(super.internalDivide(this.value, massFraction.BaseValue));
  }
  /**
   * Modulo the given MassFraction with the current MassFraction.
   * @param massFraction The other MassFraction.
   * @returns A new MassFraction instance with the results.
   */
  modulo(massFraction) {
    return new MassFraction(super.internalModulo(this.value, massFraction.BaseValue));
  }
  /**
   * Pow the given MassFraction with the current MassFraction.
   * @param massFraction The other MassFraction.
   * @returns A new MassFraction instance with the results.
   */
  pow(massFraction) {
    return new MassFraction(super.internalPow(this.value, massFraction.BaseValue));
  }
}
exports.MassFraction = MassFraction;