"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RadiationExposure = exports.RadiationExposureUnits = void 0;
const base_unit_1 = require("../base-unit");
/** RadiationExposureUnits enumeration */
var RadiationExposureUnits;
(function (RadiationExposureUnits) {
  /** */
  RadiationExposureUnits["CoulombsPerKilogram"] = "CoulombPerKilogram";
  /** */
  RadiationExposureUnits["Roentgens"] = "Roentgen";
  /** */
  RadiationExposureUnits["PicocoulombsPerKilogram"] = "PicocoulombPerKilogram";
  /** */
  RadiationExposureUnits["NanocoulombsPerKilogram"] = "NanocoulombPerKilogram";
  /** */
  RadiationExposureUnits["MicrocoulombsPerKilogram"] = "MicrocoulombPerKilogram";
  /** */
  RadiationExposureUnits["MillicoulombsPerKilogram"] = "MillicoulombPerKilogram";
  /** */
  RadiationExposureUnits["Microroentgens"] = "Microroentgen";
  /** */
  RadiationExposureUnits["Milliroentgens"] = "Milliroentgen";
})(RadiationExposureUnits = exports.RadiationExposureUnits || (exports.RadiationExposureUnits = {}));
/** Radiation exposure is a measure of the ionization of air due to ionizing radiation from photons. */
class RadiationExposure extends base_unit_1.BaseUnit {
  /**
   * Create a new RadiationExposure.
   * @param value The value.
   * @param fromUnit The ‘RadiationExposure’ unit to create from.
   * The default unit is CoulombsPerKilogram
   */
  constructor(value, fromUnit = RadiationExposureUnits.CoulombsPerKilogram) {
    super();
    this.coulombsperkilogramLazy = null;
    this.roentgensLazy = null;
    this.picocoulombsperkilogramLazy = null;
    this.nanocoulombsperkilogramLazy = null;
    this.microcoulombsperkilogramLazy = null;
    this.millicoulombsperkilogramLazy = null;
    this.microroentgensLazy = null;
    this.milliroentgensLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of RadiationExposure is CoulombsPerKilogram.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return RadiationExposureUnits.CoulombsPerKilogram;
  }
  /** */
  get CoulombsPerKilogram() {
    if (this.coulombsperkilogramLazy !== null) {
      return this.coulombsperkilogramLazy;
    }
    return this.coulombsperkilogramLazy = this.convertFromBase(RadiationExposureUnits.CoulombsPerKilogram);
  }
  /** */
  get Roentgens() {
    if (this.roentgensLazy !== null) {
      return this.roentgensLazy;
    }
    return this.roentgensLazy = this.convertFromBase(RadiationExposureUnits.Roentgens);
  }
  /** */
  get PicocoulombsPerKilogram() {
    if (this.picocoulombsperkilogramLazy !== null) {
      return this.picocoulombsperkilogramLazy;
    }
    return this.picocoulombsperkilogramLazy = this.convertFromBase(RadiationExposureUnits.PicocoulombsPerKilogram);
  }
  /** */
  get NanocoulombsPerKilogram() {
    if (this.nanocoulombsperkilogramLazy !== null) {
      return this.nanocoulombsperkilogramLazy;
    }
    return this.nanocoulombsperkilogramLazy = this.convertFromBase(RadiationExposureUnits.NanocoulombsPerKilogram);
  }
  /** */
  get MicrocoulombsPerKilogram() {
    if (this.microcoulombsperkilogramLazy !== null) {
      return this.microcoulombsperkilogramLazy;
    }
    return this.microcoulombsperkilogramLazy = this.convertFromBase(RadiationExposureUnits.MicrocoulombsPerKilogram);
  }
  /** */
  get MillicoulombsPerKilogram() {
    if (this.millicoulombsperkilogramLazy !== null) {
      return this.millicoulombsperkilogramLazy;
    }
    return this.millicoulombsperkilogramLazy = this.convertFromBase(RadiationExposureUnits.MillicoulombsPerKilogram);
  }
  /** */
  get Microroentgens() {
    if (this.microroentgensLazy !== null) {
      return this.microroentgensLazy;
    }
    return this.microroentgensLazy = this.convertFromBase(RadiationExposureUnits.Microroentgens);
  }
  /** */
  get Milliroentgens() {
    if (this.milliroentgensLazy !== null) {
      return this.milliroentgensLazy;
    }
    return this.milliroentgensLazy = this.convertFromBase(RadiationExposureUnits.Milliroentgens);
  }
  /**
   * Create a new RadiationExposure instance from a CoulombsPerKilogram
   *
   * @param value The unit as CoulombsPerKilogram to create a new RadiationExposure from.
   * @returns The new RadiationExposure instance.
   */
  static FromCoulombsPerKilogram(value) {
    return new RadiationExposure(value, RadiationExposureUnits.CoulombsPerKilogram);
  }
  /**
   * Create a new RadiationExposure instance from a Roentgens
   *
   * @param value The unit as Roentgens to create a new RadiationExposure from.
   * @returns The new RadiationExposure instance.
   */
  static FromRoentgens(value) {
    return new RadiationExposure(value, RadiationExposureUnits.Roentgens);
  }
  /**
   * Create a new RadiationExposure instance from a PicocoulombsPerKilogram
   *
   * @param value The unit as PicocoulombsPerKilogram to create a new RadiationExposure from.
   * @returns The new RadiationExposure instance.
   */
  static FromPicocoulombsPerKilogram(value) {
    return new RadiationExposure(value, RadiationExposureUnits.PicocoulombsPerKilogram);
  }
  /**
   * Create a new RadiationExposure instance from a NanocoulombsPerKilogram
   *
   * @param value The unit as NanocoulombsPerKilogram to create a new RadiationExposure from.
   * @returns The new RadiationExposure instance.
   */
  static FromNanocoulombsPerKilogram(value) {
    return new RadiationExposure(value, RadiationExposureUnits.NanocoulombsPerKilogram);
  }
  /**
   * Create a new RadiationExposure instance from a MicrocoulombsPerKilogram
   *
   * @param value The unit as MicrocoulombsPerKilogram to create a new RadiationExposure from.
   * @returns The new RadiationExposure instance.
   */
  static FromMicrocoulombsPerKilogram(value) {
    return new RadiationExposure(value, RadiationExposureUnits.MicrocoulombsPerKilogram);
  }
  /**
   * Create a new RadiationExposure instance from a MillicoulombsPerKilogram
   *
   * @param value The unit as MillicoulombsPerKilogram to create a new RadiationExposure from.
   * @returns The new RadiationExposure instance.
   */
  static FromMillicoulombsPerKilogram(value) {
    return new RadiationExposure(value, RadiationExposureUnits.MillicoulombsPerKilogram);
  }
  /**
   * Create a new RadiationExposure instance from a Microroentgens
   *
   * @param value The unit as Microroentgens to create a new RadiationExposure from.
   * @returns The new RadiationExposure instance.
   */
  static FromMicroroentgens(value) {
    return new RadiationExposure(value, RadiationExposureUnits.Microroentgens);
  }
  /**
   * Create a new RadiationExposure instance from a Milliroentgens
   *
   * @param value The unit as Milliroentgens to create a new RadiationExposure from.
   * @returns The new RadiationExposure instance.
   */
  static FromMilliroentgens(value) {
    return new RadiationExposure(value, RadiationExposureUnits.Milliroentgens);
  }
  /**
   * Gets the base unit enumeration associated with RadiationExposure
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return RadiationExposureUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return RadiationExposureUnits.CoulombsPerKilogram;
  }
  /**
   * Create API DTO represent a RadiationExposure unit.
   * @param holdInUnit The specific RadiationExposure unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = RadiationExposureUnits.CoulombsPerKilogram) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a RadiationExposure unit from an API DTO representation.
   * @param dtoRadiationExposure The RadiationExposure API DTO representation
   */
  static FromDto(dtoRadiationExposure) {
    return new RadiationExposure(dtoRadiationExposure.value, dtoRadiationExposure.unit);
  }
  /**
   * Convert RadiationExposure to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case RadiationExposureUnits.CoulombsPerKilogram:
        return this.CoulombsPerKilogram;
      case RadiationExposureUnits.Roentgens:
        return this.Roentgens;
      case RadiationExposureUnits.PicocoulombsPerKilogram:
        return this.PicocoulombsPerKilogram;
      case RadiationExposureUnits.NanocoulombsPerKilogram:
        return this.NanocoulombsPerKilogram;
      case RadiationExposureUnits.MicrocoulombsPerKilogram:
        return this.MicrocoulombsPerKilogram;
      case RadiationExposureUnits.MillicoulombsPerKilogram:
        return this.MillicoulombsPerKilogram;
      case RadiationExposureUnits.Microroentgens:
        return this.Microroentgens;
      case RadiationExposureUnits.Milliroentgens:
        return this.Milliroentgens;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case RadiationExposureUnits.CoulombsPerKilogram:
        return this.value;
      case RadiationExposureUnits.Roentgens:
        return super.internalDivide(this.value, 2.58e-4);
      case RadiationExposureUnits.PicocoulombsPerKilogram:
        return super.internalDivide(this.value, 1e-12);
      case RadiationExposureUnits.NanocoulombsPerKilogram:
        return super.internalDivide(this.value, 1e-9);
      case RadiationExposureUnits.MicrocoulombsPerKilogram:
        return super.internalDivide(this.value, 0.000001);
      case RadiationExposureUnits.MillicoulombsPerKilogram:
        return super.internalDivide(this.value, 0.001);
      case RadiationExposureUnits.Microroentgens:
        {
          const v3 = super.internalDivide(this.value, 2.58e-4);
          return super.internalDivide(v3, 0.000001);
        }
      case RadiationExposureUnits.Milliroentgens:
        {
          const v3 = super.internalDivide(this.value, 2.58e-4);
          return super.internalDivide(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case RadiationExposureUnits.CoulombsPerKilogram:
        return this.value;
      case RadiationExposureUnits.Roentgens:
        return this.value / 2.58e-4;
      case RadiationExposureUnits.PicocoulombsPerKilogram:
        return this.value / 1e-12;
      case RadiationExposureUnits.NanocoulombsPerKilogram:
        return this.value / 1e-9;
      case RadiationExposureUnits.MicrocoulombsPerKilogram:
        return this.value / 0.000001;
      case RadiationExposureUnits.MillicoulombsPerKilogram:
        return this.value / 0.001;
      case RadiationExposureUnits.Microroentgens:
        return this.value / 2.58e-4 / 0.000001;
      case RadiationExposureUnits.Milliroentgens:
        return this.value / 2.58e-4 / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case RadiationExposureUnits.CoulombsPerKilogram:
        return value;
      case RadiationExposureUnits.Roentgens:
        return super.internalMultiply(value, 2.58e-4);
      case RadiationExposureUnits.PicocoulombsPerKilogram:
        return super.internalMultiply(value, 1e-12);
      case RadiationExposureUnits.NanocoulombsPerKilogram:
        return super.internalMultiply(value, 1e-9);
      case RadiationExposureUnits.MicrocoulombsPerKilogram:
        return super.internalMultiply(value, 0.000001);
      case RadiationExposureUnits.MillicoulombsPerKilogram:
        return super.internalMultiply(value, 0.001);
      case RadiationExposureUnits.Microroentgens:
        {
          const v3 = super.internalMultiply(value, 2.58e-4);
          return super.internalMultiply(v3, 0.000001);
        }
      case RadiationExposureUnits.Milliroentgens:
        {
          const v3 = super.internalMultiply(value, 2.58e-4);
          return super.internalMultiply(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case RadiationExposureUnits.CoulombsPerKilogram:
        return value;
      case RadiationExposureUnits.Roentgens:
        return value * 2.58e-4;
      case RadiationExposureUnits.PicocoulombsPerKilogram:
        return value * 1e-12;
      case RadiationExposureUnits.NanocoulombsPerKilogram:
        return value * 1e-9;
      case RadiationExposureUnits.MicrocoulombsPerKilogram:
        return value * 0.000001;
      case RadiationExposureUnits.MillicoulombsPerKilogram:
        return value * 0.001;
      case RadiationExposureUnits.Microroentgens:
        return value * 2.58e-4 * 0.000001;
      case RadiationExposureUnits.Milliroentgens:
        return value * 2.58e-4 * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the RadiationExposure to string.
   * Note! the default format for RadiationExposure is CoulombsPerKilogram.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the RadiationExposure.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the RadiationExposure.
   */
  toString(unit = RadiationExposureUnits.CoulombsPerKilogram, fractionalDigits) {
    switch (unit) {
      case RadiationExposureUnits.CoulombsPerKilogram:
        return super.truncateFractionDigits(this.CoulombsPerKilogram, fractionalDigits) + ` C/kg`;
      case RadiationExposureUnits.Roentgens:
        return super.truncateFractionDigits(this.Roentgens, fractionalDigits) + ` R`;
      case RadiationExposureUnits.PicocoulombsPerKilogram:
        return super.truncateFractionDigits(this.PicocoulombsPerKilogram, fractionalDigits) + ` pC/kg`;
      case RadiationExposureUnits.NanocoulombsPerKilogram:
        return super.truncateFractionDigits(this.NanocoulombsPerKilogram, fractionalDigits) + ` nC/kg`;
      case RadiationExposureUnits.MicrocoulombsPerKilogram:
        return super.truncateFractionDigits(this.MicrocoulombsPerKilogram, fractionalDigits) + ` μC/kg`;
      case RadiationExposureUnits.MillicoulombsPerKilogram:
        return super.truncateFractionDigits(this.MillicoulombsPerKilogram, fractionalDigits) + ` mC/kg`;
      case RadiationExposureUnits.Microroentgens:
        return super.truncateFractionDigits(this.Microroentgens, fractionalDigits) + ` μR`;
      case RadiationExposureUnits.Milliroentgens:
        return super.truncateFractionDigits(this.Milliroentgens, fractionalDigits) + ` mR`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get RadiationExposure unit abbreviation.
   * Note! the default abbreviation for RadiationExposure is CoulombsPerKilogram.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the RadiationExposure.
   * @returns The abbreviation string of RadiationExposure.
   */
  getUnitAbbreviation(unitAbbreviation = RadiationExposureUnits.CoulombsPerKilogram) {
    switch (unitAbbreviation) {
      case RadiationExposureUnits.CoulombsPerKilogram:
        return `C/kg`;
      case RadiationExposureUnits.Roentgens:
        return `R`;
      case RadiationExposureUnits.PicocoulombsPerKilogram:
        return `pC/kg`;
      case RadiationExposureUnits.NanocoulombsPerKilogram:
        return `nC/kg`;
      case RadiationExposureUnits.MicrocoulombsPerKilogram:
        return `μC/kg`;
      case RadiationExposureUnits.MillicoulombsPerKilogram:
        return `mC/kg`;
      case RadiationExposureUnits.Microroentgens:
        return `μR`;
      case RadiationExposureUnits.Milliroentgens:
        return `mR`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given RadiationExposure are equals to the current RadiationExposure.
   * @param radiationExposure The other RadiationExposure.
   * @returns True if the given RadiationExposure are equal to the current RadiationExposure.
   */
  equals(radiationExposure) {
    return super.internalEquals(this.value, radiationExposure.BaseValue);
  }
  /**
   * Compare the given RadiationExposure against the current RadiationExposure.
   * @param radiationExposure The other RadiationExposure.
   * @returns 0 if they are equal, -1 if the current RadiationExposure is less then other, 1 if the current RadiationExposure is greater then other.
   */
  compareTo(radiationExposure) {
    return super.internalCompareTo(this.value, radiationExposure.BaseValue);
  }
  /**
   * Add the given RadiationExposure with the current RadiationExposure.
   * @param radiationExposure The other RadiationExposure.
   * @returns A new RadiationExposure instance with the results.
   */
  add(radiationExposure) {
    return new RadiationExposure(super.internalAdd(this.value, radiationExposure.BaseValue));
  }
  /**
   * Subtract the given RadiationExposure with the current RadiationExposure.
   * @param radiationExposure The other RadiationExposure.
   * @returns A new RadiationExposure instance with the results.
   */
  subtract(radiationExposure) {
    return new RadiationExposure(super.internalSubtract(this.value, radiationExposure.BaseValue));
  }
  /**
   * Multiply the given RadiationExposure with the current RadiationExposure.
   * @param radiationExposure The other RadiationExposure.
   * @returns A new RadiationExposure instance with the results.
   */
  multiply(radiationExposure) {
    return new RadiationExposure(super.internalMultiply(this.value, radiationExposure.BaseValue));
  }
  /**
   * Divide the given RadiationExposure with the current RadiationExposure.
   * @param radiationExposure The other RadiationExposure.
   * @returns A new RadiationExposure instance with the results.
   */
  divide(radiationExposure) {
    return new RadiationExposure(super.internalDivide(this.value, radiationExposure.BaseValue));
  }
  /**
   * Modulo the given RadiationExposure with the current RadiationExposure.
   * @param radiationExposure The other RadiationExposure.
   * @returns A new RadiationExposure instance with the results.
   */
  modulo(radiationExposure) {
    return new RadiationExposure(super.internalModulo(this.value, radiationExposure.BaseValue));
  }
  /**
   * Pow the given RadiationExposure with the current RadiationExposure.
   * @param radiationExposure The other RadiationExposure.
   * @returns A new RadiationExposure instance with the results.
   */
  pow(radiationExposure) {
    return new RadiationExposure(super.internalPow(this.value, radiationExposure.BaseValue));
  }
}
exports.RadiationExposure = RadiationExposure;