export interface GraphMe {
    value: Group[];
}


export interface Group {
    id: string;
    displayName: string;

}

export class UserClaim {
    isSTiR_User: boolean = false;
    isSTiR_View: boolean = false;
    isSTiR_Admin: boolean = false;
    isUSER: boolean = false;
    isADMIN: boolean = false;
    version!: string;
    email!: string;
    givenName!: string;
    surName!: string;
    id!: string;
    UserId!: string;
}

