"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemperatureChangeRate = exports.TemperatureChangeRateUnits = void 0;
const base_unit_1 = require("../base-unit");
/** TemperatureChangeRateUnits enumeration */
var TemperatureChangeRateUnits;
(function (TemperatureChangeRateUnits) {
  /** */
  TemperatureChangeRateUnits["DegreesCelsiusPerSecond"] = "DegreeCelsiusPerSecond";
  /** */
  TemperatureChangeRateUnits["DegreesCelsiusPerMinute"] = "DegreeCelsiusPerMinute";
  /** */
  TemperatureChangeRateUnits["NanodegreesCelsiusPerSecond"] = "NanodegreeCelsiusPerSecond";
  /** */
  TemperatureChangeRateUnits["MicrodegreesCelsiusPerSecond"] = "MicrodegreeCelsiusPerSecond";
  /** */
  TemperatureChangeRateUnits["MillidegreesCelsiusPerSecond"] = "MillidegreeCelsiusPerSecond";
  /** */
  TemperatureChangeRateUnits["CentidegreesCelsiusPerSecond"] = "CentidegreeCelsiusPerSecond";
  /** */
  TemperatureChangeRateUnits["DecidegreesCelsiusPerSecond"] = "DecidegreeCelsiusPerSecond";
  /** */
  TemperatureChangeRateUnits["DecadegreesCelsiusPerSecond"] = "DecadegreeCelsiusPerSecond";
  /** */
  TemperatureChangeRateUnits["HectodegreesCelsiusPerSecond"] = "HectodegreeCelsiusPerSecond";
  /** */
  TemperatureChangeRateUnits["KilodegreesCelsiusPerSecond"] = "KilodegreeCelsiusPerSecond";
})(TemperatureChangeRateUnits = exports.TemperatureChangeRateUnits || (exports.TemperatureChangeRateUnits = {}));
/** Temperature change rate is the ratio of the temperature change to the time during which the change occurred (value of temperature changes per unit time). */
class TemperatureChangeRate extends base_unit_1.BaseUnit {
  /**
   * Create a new TemperatureChangeRate.
   * @param value The value.
   * @param fromUnit The ‘TemperatureChangeRate’ unit to create from.
   * The default unit is DegreesCelsiusPerSecond
   */
  constructor(value, fromUnit = TemperatureChangeRateUnits.DegreesCelsiusPerSecond) {
    super();
    this.degreescelsiuspersecondLazy = null;
    this.degreescelsiusperminuteLazy = null;
    this.nanodegreescelsiuspersecondLazy = null;
    this.microdegreescelsiuspersecondLazy = null;
    this.millidegreescelsiuspersecondLazy = null;
    this.centidegreescelsiuspersecondLazy = null;
    this.decidegreescelsiuspersecondLazy = null;
    this.decadegreescelsiuspersecondLazy = null;
    this.hectodegreescelsiuspersecondLazy = null;
    this.kilodegreescelsiuspersecondLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of TemperatureChangeRate is DegreesCelsiusPerSecond.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return TemperatureChangeRateUnits.DegreesCelsiusPerSecond;
  }
  /** */
  get DegreesCelsiusPerSecond() {
    if (this.degreescelsiuspersecondLazy !== null) {
      return this.degreescelsiuspersecondLazy;
    }
    return this.degreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.DegreesCelsiusPerSecond);
  }
  /** */
  get DegreesCelsiusPerMinute() {
    if (this.degreescelsiusperminuteLazy !== null) {
      return this.degreescelsiusperminuteLazy;
    }
    return this.degreescelsiusperminuteLazy = this.convertFromBase(TemperatureChangeRateUnits.DegreesCelsiusPerMinute);
  }
  /** */
  get NanodegreesCelsiusPerSecond() {
    if (this.nanodegreescelsiuspersecondLazy !== null) {
      return this.nanodegreescelsiuspersecondLazy;
    }
    return this.nanodegreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond);
  }
  /** */
  get MicrodegreesCelsiusPerSecond() {
    if (this.microdegreescelsiuspersecondLazy !== null) {
      return this.microdegreescelsiuspersecondLazy;
    }
    return this.microdegreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond);
  }
  /** */
  get MillidegreesCelsiusPerSecond() {
    if (this.millidegreescelsiuspersecondLazy !== null) {
      return this.millidegreescelsiuspersecondLazy;
    }
    return this.millidegreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond);
  }
  /** */
  get CentidegreesCelsiusPerSecond() {
    if (this.centidegreescelsiuspersecondLazy !== null) {
      return this.centidegreescelsiuspersecondLazy;
    }
    return this.centidegreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond);
  }
  /** */
  get DecidegreesCelsiusPerSecond() {
    if (this.decidegreescelsiuspersecondLazy !== null) {
      return this.decidegreescelsiuspersecondLazy;
    }
    return this.decidegreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond);
  }
  /** */
  get DecadegreesCelsiusPerSecond() {
    if (this.decadegreescelsiuspersecondLazy !== null) {
      return this.decadegreescelsiuspersecondLazy;
    }
    return this.decadegreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond);
  }
  /** */
  get HectodegreesCelsiusPerSecond() {
    if (this.hectodegreescelsiuspersecondLazy !== null) {
      return this.hectodegreescelsiuspersecondLazy;
    }
    return this.hectodegreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond);
  }
  /** */
  get KilodegreesCelsiusPerSecond() {
    if (this.kilodegreescelsiuspersecondLazy !== null) {
      return this.kilodegreescelsiuspersecondLazy;
    }
    return this.kilodegreescelsiuspersecondLazy = this.convertFromBase(TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a DegreesCelsiusPerSecond
   *
   * @param value The unit as DegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromDegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.DegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a DegreesCelsiusPerMinute
   *
   * @param value The unit as DegreesCelsiusPerMinute to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromDegreesCelsiusPerMinute(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.DegreesCelsiusPerMinute);
  }
  /**
   * Create a new TemperatureChangeRate instance from a NanodegreesCelsiusPerSecond
   *
   * @param value The unit as NanodegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromNanodegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a MicrodegreesCelsiusPerSecond
   *
   * @param value The unit as MicrodegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromMicrodegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a MillidegreesCelsiusPerSecond
   *
   * @param value The unit as MillidegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromMillidegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a CentidegreesCelsiusPerSecond
   *
   * @param value The unit as CentidegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromCentidegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a DecidegreesCelsiusPerSecond
   *
   * @param value The unit as DecidegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromDecidegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a DecadegreesCelsiusPerSecond
   *
   * @param value The unit as DecadegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromDecadegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a HectodegreesCelsiusPerSecond
   *
   * @param value The unit as HectodegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromHectodegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond);
  }
  /**
   * Create a new TemperatureChangeRate instance from a KilodegreesCelsiusPerSecond
   *
   * @param value The unit as KilodegreesCelsiusPerSecond to create a new TemperatureChangeRate from.
   * @returns The new TemperatureChangeRate instance.
   */
  static FromKilodegreesCelsiusPerSecond(value) {
    return new TemperatureChangeRate(value, TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond);
  }
  /**
   * Gets the base unit enumeration associated with TemperatureChangeRate
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return TemperatureChangeRateUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return TemperatureChangeRateUnits.DegreesCelsiusPerSecond;
  }
  /**
   * Create API DTO represent a TemperatureChangeRate unit.
   * @param holdInUnit The specific TemperatureChangeRate unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = TemperatureChangeRateUnits.DegreesCelsiusPerSecond) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a TemperatureChangeRate unit from an API DTO representation.
   * @param dtoTemperatureChangeRate The TemperatureChangeRate API DTO representation
   */
  static FromDto(dtoTemperatureChangeRate) {
    return new TemperatureChangeRate(dtoTemperatureChangeRate.value, dtoTemperatureChangeRate.unit);
  }
  /**
   * Convert TemperatureChangeRate to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case TemperatureChangeRateUnits.DegreesCelsiusPerSecond:
        return this.DegreesCelsiusPerSecond;
      case TemperatureChangeRateUnits.DegreesCelsiusPerMinute:
        return this.DegreesCelsiusPerMinute;
      case TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond:
        return this.NanodegreesCelsiusPerSecond;
      case TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond:
        return this.MicrodegreesCelsiusPerSecond;
      case TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond:
        return this.MillidegreesCelsiusPerSecond;
      case TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond:
        return this.CentidegreesCelsiusPerSecond;
      case TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond:
        return this.DecidegreesCelsiusPerSecond;
      case TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond:
        return this.DecadegreesCelsiusPerSecond;
      case TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond:
        return this.HectodegreesCelsiusPerSecond;
      case TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond:
        return this.KilodegreesCelsiusPerSecond;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case TemperatureChangeRateUnits.DegreesCelsiusPerSecond:
        return this.value;
      case TemperatureChangeRateUnits.DegreesCelsiusPerMinute:
        return super.internalMultiply(this.value, 60);
      case TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond:
        return super.internalDivide(this.value, 1e-9);
      case TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond:
        return super.internalDivide(this.value, 0.000001);
      case TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond:
        return super.internalDivide(this.value, 0.001);
      case TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond:
        return super.internalDivide(this.value, 0.01);
      case TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond:
        return super.internalDivide(this.value, 0.1);
      case TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond:
        return super.internalDivide(this.value, 10);
      case TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond:
        return super.internalDivide(this.value, 100);
      case TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond:
        return super.internalDivide(this.value, 1000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case TemperatureChangeRateUnits.DegreesCelsiusPerSecond:
        return this.value;
      case TemperatureChangeRateUnits.DegreesCelsiusPerMinute:
        return this.value * 60;
      case TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond:
        return this.value / 1e-9;
      case TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond:
        return this.value / 0.000001;
      case TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond:
        return this.value / 0.001;
      case TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond:
        return this.value / 0.01;
      case TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond:
        return this.value / 0.1;
      case TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond:
        return this.value / 10;
      case TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond:
        return this.value / 100;
      case TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond:
        return this.value / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case TemperatureChangeRateUnits.DegreesCelsiusPerSecond:
        return value;
      case TemperatureChangeRateUnits.DegreesCelsiusPerMinute:
        return super.internalDivide(value, 60);
      case TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond:
        return super.internalMultiply(value, 1e-9);
      case TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond:
        return super.internalMultiply(value, 0.000001);
      case TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond:
        return super.internalMultiply(value, 0.001);
      case TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond:
        return super.internalMultiply(value, 0.01);
      case TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond:
        return super.internalMultiply(value, 0.1);
      case TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond:
        return super.internalMultiply(value, 10);
      case TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond:
        return super.internalMultiply(value, 100);
      case TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond:
        return super.internalMultiply(value, 1000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case TemperatureChangeRateUnits.DegreesCelsiusPerSecond:
        return value;
      case TemperatureChangeRateUnits.DegreesCelsiusPerMinute:
        return value / 60;
      case TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond:
        return value * 1e-9;
      case TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond:
        return value * 0.000001;
      case TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond:
        return value * 0.001;
      case TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond:
        return value * 0.01;
      case TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond:
        return value * 0.1;
      case TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond:
        return value * 10;
      case TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond:
        return value * 100;
      case TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond:
        return value * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the TemperatureChangeRate to string.
   * Note! the default format for TemperatureChangeRate is DegreesCelsiusPerSecond.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the TemperatureChangeRate.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the TemperatureChangeRate.
   */
  toString(unit = TemperatureChangeRateUnits.DegreesCelsiusPerSecond, fractionalDigits) {
    switch (unit) {
      case TemperatureChangeRateUnits.DegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.DegreesCelsiusPerSecond, fractionalDigits) + ` °C/s`;
      case TemperatureChangeRateUnits.DegreesCelsiusPerMinute:
        return super.truncateFractionDigits(this.DegreesCelsiusPerMinute, fractionalDigits) + ` °C/min`;
      case TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.NanodegreesCelsiusPerSecond, fractionalDigits) + ` n°C/s`;
      case TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.MicrodegreesCelsiusPerSecond, fractionalDigits) + ` μ°C/s`;
      case TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.MillidegreesCelsiusPerSecond, fractionalDigits) + ` m°C/s`;
      case TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.CentidegreesCelsiusPerSecond, fractionalDigits) + ` c°C/s`;
      case TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.DecidegreesCelsiusPerSecond, fractionalDigits) + ` d°C/s`;
      case TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.DecadegreesCelsiusPerSecond, fractionalDigits) + ` da°C/s`;
      case TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.HectodegreesCelsiusPerSecond, fractionalDigits) + ` h°C/s`;
      case TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond:
        return super.truncateFractionDigits(this.KilodegreesCelsiusPerSecond, fractionalDigits) + ` k°C/s`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get TemperatureChangeRate unit abbreviation.
   * Note! the default abbreviation for TemperatureChangeRate is DegreesCelsiusPerSecond.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the TemperatureChangeRate.
   * @returns The abbreviation string of TemperatureChangeRate.
   */
  getUnitAbbreviation(unitAbbreviation = TemperatureChangeRateUnits.DegreesCelsiusPerSecond) {
    switch (unitAbbreviation) {
      case TemperatureChangeRateUnits.DegreesCelsiusPerSecond:
        return `°C/s`;
      case TemperatureChangeRateUnits.DegreesCelsiusPerMinute:
        return `°C/min`;
      case TemperatureChangeRateUnits.NanodegreesCelsiusPerSecond:
        return `n°C/s`;
      case TemperatureChangeRateUnits.MicrodegreesCelsiusPerSecond:
        return `μ°C/s`;
      case TemperatureChangeRateUnits.MillidegreesCelsiusPerSecond:
        return `m°C/s`;
      case TemperatureChangeRateUnits.CentidegreesCelsiusPerSecond:
        return `c°C/s`;
      case TemperatureChangeRateUnits.DecidegreesCelsiusPerSecond:
        return `d°C/s`;
      case TemperatureChangeRateUnits.DecadegreesCelsiusPerSecond:
        return `da°C/s`;
      case TemperatureChangeRateUnits.HectodegreesCelsiusPerSecond:
        return `h°C/s`;
      case TemperatureChangeRateUnits.KilodegreesCelsiusPerSecond:
        return `k°C/s`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given TemperatureChangeRate are equals to the current TemperatureChangeRate.
   * @param temperatureChangeRate The other TemperatureChangeRate.
   * @returns True if the given TemperatureChangeRate are equal to the current TemperatureChangeRate.
   */
  equals(temperatureChangeRate) {
    return super.internalEquals(this.value, temperatureChangeRate.BaseValue);
  }
  /**
   * Compare the given TemperatureChangeRate against the current TemperatureChangeRate.
   * @param temperatureChangeRate The other TemperatureChangeRate.
   * @returns 0 if they are equal, -1 if the current TemperatureChangeRate is less then other, 1 if the current TemperatureChangeRate is greater then other.
   */
  compareTo(temperatureChangeRate) {
    return super.internalCompareTo(this.value, temperatureChangeRate.BaseValue);
  }
  /**
   * Add the given TemperatureChangeRate with the current TemperatureChangeRate.
   * @param temperatureChangeRate The other TemperatureChangeRate.
   * @returns A new TemperatureChangeRate instance with the results.
   */
  add(temperatureChangeRate) {
    return new TemperatureChangeRate(super.internalAdd(this.value, temperatureChangeRate.BaseValue));
  }
  /**
   * Subtract the given TemperatureChangeRate with the current TemperatureChangeRate.
   * @param temperatureChangeRate The other TemperatureChangeRate.
   * @returns A new TemperatureChangeRate instance with the results.
   */
  subtract(temperatureChangeRate) {
    return new TemperatureChangeRate(super.internalSubtract(this.value, temperatureChangeRate.BaseValue));
  }
  /**
   * Multiply the given TemperatureChangeRate with the current TemperatureChangeRate.
   * @param temperatureChangeRate The other TemperatureChangeRate.
   * @returns A new TemperatureChangeRate instance with the results.
   */
  multiply(temperatureChangeRate) {
    return new TemperatureChangeRate(super.internalMultiply(this.value, temperatureChangeRate.BaseValue));
  }
  /**
   * Divide the given TemperatureChangeRate with the current TemperatureChangeRate.
   * @param temperatureChangeRate The other TemperatureChangeRate.
   * @returns A new TemperatureChangeRate instance with the results.
   */
  divide(temperatureChangeRate) {
    return new TemperatureChangeRate(super.internalDivide(this.value, temperatureChangeRate.BaseValue));
  }
  /**
   * Modulo the given TemperatureChangeRate with the current TemperatureChangeRate.
   * @param temperatureChangeRate The other TemperatureChangeRate.
   * @returns A new TemperatureChangeRate instance with the results.
   */
  modulo(temperatureChangeRate) {
    return new TemperatureChangeRate(super.internalModulo(this.value, temperatureChangeRate.BaseValue));
  }
  /**
   * Pow the given TemperatureChangeRate with the current TemperatureChangeRate.
   * @param temperatureChangeRate The other TemperatureChangeRate.
   * @returns A new TemperatureChangeRate instance with the results.
   */
  pow(temperatureChangeRate) {
    return new TemperatureChangeRate(super.internalPow(this.value, temperatureChangeRate.BaseValue));
  }
}
exports.TemperatureChangeRate = TemperatureChangeRate;