"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Pressure = exports.PressureUnits = void 0;
const base_unit_1 = require("../base-unit");
/** PressureUnits enumeration */
var PressureUnits;
(function (PressureUnits) {
  /** */
  PressureUnits["Pascals"] = "Pascal";
  /** */
  PressureUnits["Atmospheres"] = "Atmosphere";
  /** */
  PressureUnits["Bars"] = "Bar";
  /** */
  PressureUnits["KilogramsForcePerSquareMeter"] = "KilogramForcePerSquareMeter";
  /** */
  PressureUnits["KilogramsForcePerSquareCentimeter"] = "KilogramForcePerSquareCentimeter";
  /** */
  PressureUnits["KilogramsForcePerSquareMillimeter"] = "KilogramForcePerSquareMillimeter";
  /** */
  PressureUnits["NewtonsPerSquareMeter"] = "NewtonPerSquareMeter";
  /** */
  PressureUnits["NewtonsPerSquareCentimeter"] = "NewtonPerSquareCentimeter";
  /** */
  PressureUnits["NewtonsPerSquareMillimeter"] = "NewtonPerSquareMillimeter";
  /** */
  PressureUnits["TechnicalAtmospheres"] = "TechnicalAtmosphere";
  /** */
  PressureUnits["Torrs"] = "Torr";
  /** */
  PressureUnits["PoundsForcePerSquareInch"] = "PoundForcePerSquareInch";
  /** */
  PressureUnits["PoundsForcePerSquareMil"] = "PoundForcePerSquareMil";
  /** */
  PressureUnits["PoundsForcePerSquareFoot"] = "PoundForcePerSquareFoot";
  /** */
  PressureUnits["TonnesForcePerSquareMillimeter"] = "TonneForcePerSquareMillimeter";
  /** */
  PressureUnits["TonnesForcePerSquareMeter"] = "TonneForcePerSquareMeter";
  /** */
  PressureUnits["MetersOfHead"] = "MeterOfHead";
  /** */
  PressureUnits["TonnesForcePerSquareCentimeter"] = "TonneForcePerSquareCentimeter";
  /** */
  PressureUnits["FeetOfHead"] = "FootOfHead";
  /** */
  PressureUnits["MillimetersOfMercury"] = "MillimeterOfMercury";
  /** */
  PressureUnits["InchesOfMercury"] = "InchOfMercury";
  /** */
  PressureUnits["DynesPerSquareCentimeter"] = "DynePerSquareCentimeter";
  /** */
  PressureUnits["PoundsPerInchSecondSquared"] = "PoundPerInchSecondSquared";
  /** */
  PressureUnits["MetersOfWaterColumn"] = "MeterOfWaterColumn";
  /** */
  PressureUnits["InchesOfWaterColumn"] = "InchOfWaterColumn";
  /** */
  PressureUnits["MetersOfElevation"] = "MeterOfElevation";
  /** */
  PressureUnits["FeetOfElevation"] = "FootOfElevation";
  /** */
  PressureUnits["Micropascals"] = "Micropascal";
  /** */
  PressureUnits["Millipascals"] = "Millipascal";
  /** */
  PressureUnits["Decapascals"] = "Decapascal";
  /** */
  PressureUnits["Hectopascals"] = "Hectopascal";
  /** */
  PressureUnits["Kilopascals"] = "Kilopascal";
  /** */
  PressureUnits["Megapascals"] = "Megapascal";
  /** */
  PressureUnits["Gigapascals"] = "Gigapascal";
  /** */
  PressureUnits["Microbars"] = "Microbar";
  /** */
  PressureUnits["Millibars"] = "Millibar";
  /** */
  PressureUnits["Centibars"] = "Centibar";
  /** */
  PressureUnits["Decibars"] = "Decibar";
  /** */
  PressureUnits["Kilobars"] = "Kilobar";
  /** */
  PressureUnits["Megabars"] = "Megabar";
  /** */
  PressureUnits["KilonewtonsPerSquareMeter"] = "KilonewtonPerSquareMeter";
  /** */
  PressureUnits["MeganewtonsPerSquareMeter"] = "MeganewtonPerSquareMeter";
  /** */
  PressureUnits["KilonewtonsPerSquareCentimeter"] = "KilonewtonPerSquareCentimeter";
  /** */
  PressureUnits["KilonewtonsPerSquareMillimeter"] = "KilonewtonPerSquareMillimeter";
  /** */
  PressureUnits["KilopoundsForcePerSquareInch"] = "KilopoundForcePerSquareInch";
  /** */
  PressureUnits["KilopoundsForcePerSquareMil"] = "KilopoundForcePerSquareMil";
  /** */
  PressureUnits["KilopoundsForcePerSquareFoot"] = "KilopoundForcePerSquareFoot";
  /** */
  PressureUnits["MillimetersOfWaterColumn"] = "MillimeterOfWaterColumn";
  /** */
  PressureUnits["CentimetersOfWaterColumn"] = "CentimeterOfWaterColumn";
})(PressureUnits = exports.PressureUnits || (exports.PressureUnits = {}));
/** Pressure (symbol: P or p) is the ratio of force to the area over which that force is distributed. Pressure is force per unit area applied in a direction perpendicular to the surface of an object. Gauge pressure (also spelled gage pressure)[a] is the pressure relative to the local atmospheric or ambient pressure. Pressure is measured in any unit of force divided by any unit of area. The SI unit of pressure is the newton per square metre, which is called the pascal (Pa) after the seventeenth-century philosopher and scientist Blaise Pascal. A pressure of 1 Pa is small; it approximately equals the pressure exerted by a dollar bill resting flat on a table. Everyday pressures are often stated in kilopascals (1 kPa = 1000 Pa). */
class Pressure extends base_unit_1.BaseUnit {
  /**
   * Create a new Pressure.
   * @param value The value.
   * @param fromUnit The ‘Pressure’ unit to create from.
   * The default unit is Pascals
   */
  constructor(value, fromUnit = PressureUnits.Pascals) {
    super();
    this.pascalsLazy = null;
    this.atmospheresLazy = null;
    this.barsLazy = null;
    this.kilogramsforcepersquaremeterLazy = null;
    this.kilogramsforcepersquarecentimeterLazy = null;
    this.kilogramsforcepersquaremillimeterLazy = null;
    this.newtonspersquaremeterLazy = null;
    this.newtonspersquarecentimeterLazy = null;
    this.newtonspersquaremillimeterLazy = null;
    this.technicalatmospheresLazy = null;
    this.torrsLazy = null;
    this.poundsforcepersquareinchLazy = null;
    this.poundsforcepersquaremilLazy = null;
    this.poundsforcepersquarefootLazy = null;
    this.tonnesforcepersquaremillimeterLazy = null;
    this.tonnesforcepersquaremeterLazy = null;
    this.metersofheadLazy = null;
    this.tonnesforcepersquarecentimeterLazy = null;
    this.feetofheadLazy = null;
    this.millimetersofmercuryLazy = null;
    this.inchesofmercuryLazy = null;
    this.dynespersquarecentimeterLazy = null;
    this.poundsperinchsecondsquaredLazy = null;
    this.metersofwatercolumnLazy = null;
    this.inchesofwatercolumnLazy = null;
    this.metersofelevationLazy = null;
    this.feetofelevationLazy = null;
    this.micropascalsLazy = null;
    this.millipascalsLazy = null;
    this.decapascalsLazy = null;
    this.hectopascalsLazy = null;
    this.kilopascalsLazy = null;
    this.megapascalsLazy = null;
    this.gigapascalsLazy = null;
    this.microbarsLazy = null;
    this.millibarsLazy = null;
    this.centibarsLazy = null;
    this.decibarsLazy = null;
    this.kilobarsLazy = null;
    this.megabarsLazy = null;
    this.kilonewtonspersquaremeterLazy = null;
    this.meganewtonspersquaremeterLazy = null;
    this.kilonewtonspersquarecentimeterLazy = null;
    this.kilonewtonspersquaremillimeterLazy = null;
    this.kilopoundsforcepersquareinchLazy = null;
    this.kilopoundsforcepersquaremilLazy = null;
    this.kilopoundsforcepersquarefootLazy = null;
    this.millimetersofwatercolumnLazy = null;
    this.centimetersofwatercolumnLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Pressure is Pascals.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return PressureUnits.Pascals;
  }
  /** */
  get Pascals() {
    if (this.pascalsLazy !== null) {
      return this.pascalsLazy;
    }
    return this.pascalsLazy = this.convertFromBase(PressureUnits.Pascals);
  }
  /** */
  get Atmospheres() {
    if (this.atmospheresLazy !== null) {
      return this.atmospheresLazy;
    }
    return this.atmospheresLazy = this.convertFromBase(PressureUnits.Atmospheres);
  }
  /** */
  get Bars() {
    if (this.barsLazy !== null) {
      return this.barsLazy;
    }
    return this.barsLazy = this.convertFromBase(PressureUnits.Bars);
  }
  /** */
  get KilogramsForcePerSquareMeter() {
    if (this.kilogramsforcepersquaremeterLazy !== null) {
      return this.kilogramsforcepersquaremeterLazy;
    }
    return this.kilogramsforcepersquaremeterLazy = this.convertFromBase(PressureUnits.KilogramsForcePerSquareMeter);
  }
  /** */
  get KilogramsForcePerSquareCentimeter() {
    if (this.kilogramsforcepersquarecentimeterLazy !== null) {
      return this.kilogramsforcepersquarecentimeterLazy;
    }
    return this.kilogramsforcepersquarecentimeterLazy = this.convertFromBase(PressureUnits.KilogramsForcePerSquareCentimeter);
  }
  /** */
  get KilogramsForcePerSquareMillimeter() {
    if (this.kilogramsforcepersquaremillimeterLazy !== null) {
      return this.kilogramsforcepersquaremillimeterLazy;
    }
    return this.kilogramsforcepersquaremillimeterLazy = this.convertFromBase(PressureUnits.KilogramsForcePerSquareMillimeter);
  }
  /** */
  get NewtonsPerSquareMeter() {
    if (this.newtonspersquaremeterLazy !== null) {
      return this.newtonspersquaremeterLazy;
    }
    return this.newtonspersquaremeterLazy = this.convertFromBase(PressureUnits.NewtonsPerSquareMeter);
  }
  /** */
  get NewtonsPerSquareCentimeter() {
    if (this.newtonspersquarecentimeterLazy !== null) {
      return this.newtonspersquarecentimeterLazy;
    }
    return this.newtonspersquarecentimeterLazy = this.convertFromBase(PressureUnits.NewtonsPerSquareCentimeter);
  }
  /** */
  get NewtonsPerSquareMillimeter() {
    if (this.newtonspersquaremillimeterLazy !== null) {
      return this.newtonspersquaremillimeterLazy;
    }
    return this.newtonspersquaremillimeterLazy = this.convertFromBase(PressureUnits.NewtonsPerSquareMillimeter);
  }
  /** */
  get TechnicalAtmospheres() {
    if (this.technicalatmospheresLazy !== null) {
      return this.technicalatmospheresLazy;
    }
    return this.technicalatmospheresLazy = this.convertFromBase(PressureUnits.TechnicalAtmospheres);
  }
  /** */
  get Torrs() {
    if (this.torrsLazy !== null) {
      return this.torrsLazy;
    }
    return this.torrsLazy = this.convertFromBase(PressureUnits.Torrs);
  }
  /** */
  get PoundsForcePerSquareInch() {
    if (this.poundsforcepersquareinchLazy !== null) {
      return this.poundsforcepersquareinchLazy;
    }
    return this.poundsforcepersquareinchLazy = this.convertFromBase(PressureUnits.PoundsForcePerSquareInch);
  }
  /** */
  get PoundsForcePerSquareMil() {
    if (this.poundsforcepersquaremilLazy !== null) {
      return this.poundsforcepersquaremilLazy;
    }
    return this.poundsforcepersquaremilLazy = this.convertFromBase(PressureUnits.PoundsForcePerSquareMil);
  }
  /** */
  get PoundsForcePerSquareFoot() {
    if (this.poundsforcepersquarefootLazy !== null) {
      return this.poundsforcepersquarefootLazy;
    }
    return this.poundsforcepersquarefootLazy = this.convertFromBase(PressureUnits.PoundsForcePerSquareFoot);
  }
  /** */
  get TonnesForcePerSquareMillimeter() {
    if (this.tonnesforcepersquaremillimeterLazy !== null) {
      return this.tonnesforcepersquaremillimeterLazy;
    }
    return this.tonnesforcepersquaremillimeterLazy = this.convertFromBase(PressureUnits.TonnesForcePerSquareMillimeter);
  }
  /** */
  get TonnesForcePerSquareMeter() {
    if (this.tonnesforcepersquaremeterLazy !== null) {
      return this.tonnesforcepersquaremeterLazy;
    }
    return this.tonnesforcepersquaremeterLazy = this.convertFromBase(PressureUnits.TonnesForcePerSquareMeter);
  }
  /** */
  get MetersOfHead() {
    if (this.metersofheadLazy !== null) {
      return this.metersofheadLazy;
    }
    return this.metersofheadLazy = this.convertFromBase(PressureUnits.MetersOfHead);
  }
  /** */
  get TonnesForcePerSquareCentimeter() {
    if (this.tonnesforcepersquarecentimeterLazy !== null) {
      return this.tonnesforcepersquarecentimeterLazy;
    }
    return this.tonnesforcepersquarecentimeterLazy = this.convertFromBase(PressureUnits.TonnesForcePerSquareCentimeter);
  }
  /** */
  get FeetOfHead() {
    if (this.feetofheadLazy !== null) {
      return this.feetofheadLazy;
    }
    return this.feetofheadLazy = this.convertFromBase(PressureUnits.FeetOfHead);
  }
  /** */
  get MillimetersOfMercury() {
    if (this.millimetersofmercuryLazy !== null) {
      return this.millimetersofmercuryLazy;
    }
    return this.millimetersofmercuryLazy = this.convertFromBase(PressureUnits.MillimetersOfMercury);
  }
  /** */
  get InchesOfMercury() {
    if (this.inchesofmercuryLazy !== null) {
      return this.inchesofmercuryLazy;
    }
    return this.inchesofmercuryLazy = this.convertFromBase(PressureUnits.InchesOfMercury);
  }
  /** */
  get DynesPerSquareCentimeter() {
    if (this.dynespersquarecentimeterLazy !== null) {
      return this.dynespersquarecentimeterLazy;
    }
    return this.dynespersquarecentimeterLazy = this.convertFromBase(PressureUnits.DynesPerSquareCentimeter);
  }
  /** */
  get PoundsPerInchSecondSquared() {
    if (this.poundsperinchsecondsquaredLazy !== null) {
      return this.poundsperinchsecondsquaredLazy;
    }
    return this.poundsperinchsecondsquaredLazy = this.convertFromBase(PressureUnits.PoundsPerInchSecondSquared);
  }
  /** */
  get MetersOfWaterColumn() {
    if (this.metersofwatercolumnLazy !== null) {
      return this.metersofwatercolumnLazy;
    }
    return this.metersofwatercolumnLazy = this.convertFromBase(PressureUnits.MetersOfWaterColumn);
  }
  /** */
  get InchesOfWaterColumn() {
    if (this.inchesofwatercolumnLazy !== null) {
      return this.inchesofwatercolumnLazy;
    }
    return this.inchesofwatercolumnLazy = this.convertFromBase(PressureUnits.InchesOfWaterColumn);
  }
  /** */
  get MetersOfElevation() {
    if (this.metersofelevationLazy !== null) {
      return this.metersofelevationLazy;
    }
    return this.metersofelevationLazy = this.convertFromBase(PressureUnits.MetersOfElevation);
  }
  /** */
  get FeetOfElevation() {
    if (this.feetofelevationLazy !== null) {
      return this.feetofelevationLazy;
    }
    return this.feetofelevationLazy = this.convertFromBase(PressureUnits.FeetOfElevation);
  }
  /** */
  get Micropascals() {
    if (this.micropascalsLazy !== null) {
      return this.micropascalsLazy;
    }
    return this.micropascalsLazy = this.convertFromBase(PressureUnits.Micropascals);
  }
  /** */
  get Millipascals() {
    if (this.millipascalsLazy !== null) {
      return this.millipascalsLazy;
    }
    return this.millipascalsLazy = this.convertFromBase(PressureUnits.Millipascals);
  }
  /** */
  get Decapascals() {
    if (this.decapascalsLazy !== null) {
      return this.decapascalsLazy;
    }
    return this.decapascalsLazy = this.convertFromBase(PressureUnits.Decapascals);
  }
  /** */
  get Hectopascals() {
    if (this.hectopascalsLazy !== null) {
      return this.hectopascalsLazy;
    }
    return this.hectopascalsLazy = this.convertFromBase(PressureUnits.Hectopascals);
  }
  /** */
  get Kilopascals() {
    if (this.kilopascalsLazy !== null) {
      return this.kilopascalsLazy;
    }
    return this.kilopascalsLazy = this.convertFromBase(PressureUnits.Kilopascals);
  }
  /** */
  get Megapascals() {
    if (this.megapascalsLazy !== null) {
      return this.megapascalsLazy;
    }
    return this.megapascalsLazy = this.convertFromBase(PressureUnits.Megapascals);
  }
  /** */
  get Gigapascals() {
    if (this.gigapascalsLazy !== null) {
      return this.gigapascalsLazy;
    }
    return this.gigapascalsLazy = this.convertFromBase(PressureUnits.Gigapascals);
  }
  /** */
  get Microbars() {
    if (this.microbarsLazy !== null) {
      return this.microbarsLazy;
    }
    return this.microbarsLazy = this.convertFromBase(PressureUnits.Microbars);
  }
  /** */
  get Millibars() {
    if (this.millibarsLazy !== null) {
      return this.millibarsLazy;
    }
    return this.millibarsLazy = this.convertFromBase(PressureUnits.Millibars);
  }
  /** */
  get Centibars() {
    if (this.centibarsLazy !== null) {
      return this.centibarsLazy;
    }
    return this.centibarsLazy = this.convertFromBase(PressureUnits.Centibars);
  }
  /** */
  get Decibars() {
    if (this.decibarsLazy !== null) {
      return this.decibarsLazy;
    }
    return this.decibarsLazy = this.convertFromBase(PressureUnits.Decibars);
  }
  /** */
  get Kilobars() {
    if (this.kilobarsLazy !== null) {
      return this.kilobarsLazy;
    }
    return this.kilobarsLazy = this.convertFromBase(PressureUnits.Kilobars);
  }
  /** */
  get Megabars() {
    if (this.megabarsLazy !== null) {
      return this.megabarsLazy;
    }
    return this.megabarsLazy = this.convertFromBase(PressureUnits.Megabars);
  }
  /** */
  get KilonewtonsPerSquareMeter() {
    if (this.kilonewtonspersquaremeterLazy !== null) {
      return this.kilonewtonspersquaremeterLazy;
    }
    return this.kilonewtonspersquaremeterLazy = this.convertFromBase(PressureUnits.KilonewtonsPerSquareMeter);
  }
  /** */
  get MeganewtonsPerSquareMeter() {
    if (this.meganewtonspersquaremeterLazy !== null) {
      return this.meganewtonspersquaremeterLazy;
    }
    return this.meganewtonspersquaremeterLazy = this.convertFromBase(PressureUnits.MeganewtonsPerSquareMeter);
  }
  /** */
  get KilonewtonsPerSquareCentimeter() {
    if (this.kilonewtonspersquarecentimeterLazy !== null) {
      return this.kilonewtonspersquarecentimeterLazy;
    }
    return this.kilonewtonspersquarecentimeterLazy = this.convertFromBase(PressureUnits.KilonewtonsPerSquareCentimeter);
  }
  /** */
  get KilonewtonsPerSquareMillimeter() {
    if (this.kilonewtonspersquaremillimeterLazy !== null) {
      return this.kilonewtonspersquaremillimeterLazy;
    }
    return this.kilonewtonspersquaremillimeterLazy = this.convertFromBase(PressureUnits.KilonewtonsPerSquareMillimeter);
  }
  /** */
  get KilopoundsForcePerSquareInch() {
    if (this.kilopoundsforcepersquareinchLazy !== null) {
      return this.kilopoundsforcepersquareinchLazy;
    }
    return this.kilopoundsforcepersquareinchLazy = this.convertFromBase(PressureUnits.KilopoundsForcePerSquareInch);
  }
  /** */
  get KilopoundsForcePerSquareMil() {
    if (this.kilopoundsforcepersquaremilLazy !== null) {
      return this.kilopoundsforcepersquaremilLazy;
    }
    return this.kilopoundsforcepersquaremilLazy = this.convertFromBase(PressureUnits.KilopoundsForcePerSquareMil);
  }
  /** */
  get KilopoundsForcePerSquareFoot() {
    if (this.kilopoundsforcepersquarefootLazy !== null) {
      return this.kilopoundsforcepersquarefootLazy;
    }
    return this.kilopoundsforcepersquarefootLazy = this.convertFromBase(PressureUnits.KilopoundsForcePerSquareFoot);
  }
  /** */
  get MillimetersOfWaterColumn() {
    if (this.millimetersofwatercolumnLazy !== null) {
      return this.millimetersofwatercolumnLazy;
    }
    return this.millimetersofwatercolumnLazy = this.convertFromBase(PressureUnits.MillimetersOfWaterColumn);
  }
  /** */
  get CentimetersOfWaterColumn() {
    if (this.centimetersofwatercolumnLazy !== null) {
      return this.centimetersofwatercolumnLazy;
    }
    return this.centimetersofwatercolumnLazy = this.convertFromBase(PressureUnits.CentimetersOfWaterColumn);
  }
  /**
   * Create a new Pressure instance from a Pascals
   *
   * @param value The unit as Pascals to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromPascals(value) {
    return new Pressure(value, PressureUnits.Pascals);
  }
  /**
   * Create a new Pressure instance from a Atmospheres
   *
   * @param value The unit as Atmospheres to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromAtmospheres(value) {
    return new Pressure(value, PressureUnits.Atmospheres);
  }
  /**
   * Create a new Pressure instance from a Bars
   *
   * @param value The unit as Bars to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromBars(value) {
    return new Pressure(value, PressureUnits.Bars);
  }
  /**
   * Create a new Pressure instance from a KilogramsForcePerSquareMeter
   *
   * @param value The unit as KilogramsForcePerSquareMeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilogramsForcePerSquareMeter(value) {
    return new Pressure(value, PressureUnits.KilogramsForcePerSquareMeter);
  }
  /**
   * Create a new Pressure instance from a KilogramsForcePerSquareCentimeter
   *
   * @param value The unit as KilogramsForcePerSquareCentimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilogramsForcePerSquareCentimeter(value) {
    return new Pressure(value, PressureUnits.KilogramsForcePerSquareCentimeter);
  }
  /**
   * Create a new Pressure instance from a KilogramsForcePerSquareMillimeter
   *
   * @param value The unit as KilogramsForcePerSquareMillimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilogramsForcePerSquareMillimeter(value) {
    return new Pressure(value, PressureUnits.KilogramsForcePerSquareMillimeter);
  }
  /**
   * Create a new Pressure instance from a NewtonsPerSquareMeter
   *
   * @param value The unit as NewtonsPerSquareMeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromNewtonsPerSquareMeter(value) {
    return new Pressure(value, PressureUnits.NewtonsPerSquareMeter);
  }
  /**
   * Create a new Pressure instance from a NewtonsPerSquareCentimeter
   *
   * @param value The unit as NewtonsPerSquareCentimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromNewtonsPerSquareCentimeter(value) {
    return new Pressure(value, PressureUnits.NewtonsPerSquareCentimeter);
  }
  /**
   * Create a new Pressure instance from a NewtonsPerSquareMillimeter
   *
   * @param value The unit as NewtonsPerSquareMillimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromNewtonsPerSquareMillimeter(value) {
    return new Pressure(value, PressureUnits.NewtonsPerSquareMillimeter);
  }
  /**
   * Create a new Pressure instance from a TechnicalAtmospheres
   *
   * @param value The unit as TechnicalAtmospheres to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromTechnicalAtmospheres(value) {
    return new Pressure(value, PressureUnits.TechnicalAtmospheres);
  }
  /**
   * Create a new Pressure instance from a Torrs
   *
   * @param value The unit as Torrs to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromTorrs(value) {
    return new Pressure(value, PressureUnits.Torrs);
  }
  /**
   * Create a new Pressure instance from a PoundsForcePerSquareInch
   *
   * @param value The unit as PoundsForcePerSquareInch to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromPoundsForcePerSquareInch(value) {
    return new Pressure(value, PressureUnits.PoundsForcePerSquareInch);
  }
  /**
   * Create a new Pressure instance from a PoundsForcePerSquareMil
   *
   * @param value The unit as PoundsForcePerSquareMil to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromPoundsForcePerSquareMil(value) {
    return new Pressure(value, PressureUnits.PoundsForcePerSquareMil);
  }
  /**
   * Create a new Pressure instance from a PoundsForcePerSquareFoot
   *
   * @param value The unit as PoundsForcePerSquareFoot to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromPoundsForcePerSquareFoot(value) {
    return new Pressure(value, PressureUnits.PoundsForcePerSquareFoot);
  }
  /**
   * Create a new Pressure instance from a TonnesForcePerSquareMillimeter
   *
   * @param value The unit as TonnesForcePerSquareMillimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromTonnesForcePerSquareMillimeter(value) {
    return new Pressure(value, PressureUnits.TonnesForcePerSquareMillimeter);
  }
  /**
   * Create a new Pressure instance from a TonnesForcePerSquareMeter
   *
   * @param value The unit as TonnesForcePerSquareMeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromTonnesForcePerSquareMeter(value) {
    return new Pressure(value, PressureUnits.TonnesForcePerSquareMeter);
  }
  /**
   * Create a new Pressure instance from a MetersOfHead
   *
   * @param value The unit as MetersOfHead to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMetersOfHead(value) {
    return new Pressure(value, PressureUnits.MetersOfHead);
  }
  /**
   * Create a new Pressure instance from a TonnesForcePerSquareCentimeter
   *
   * @param value The unit as TonnesForcePerSquareCentimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromTonnesForcePerSquareCentimeter(value) {
    return new Pressure(value, PressureUnits.TonnesForcePerSquareCentimeter);
  }
  /**
   * Create a new Pressure instance from a FeetOfHead
   *
   * @param value The unit as FeetOfHead to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromFeetOfHead(value) {
    return new Pressure(value, PressureUnits.FeetOfHead);
  }
  /**
   * Create a new Pressure instance from a MillimetersOfMercury
   *
   * @param value The unit as MillimetersOfMercury to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMillimetersOfMercury(value) {
    return new Pressure(value, PressureUnits.MillimetersOfMercury);
  }
  /**
   * Create a new Pressure instance from a InchesOfMercury
   *
   * @param value The unit as InchesOfMercury to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromInchesOfMercury(value) {
    return new Pressure(value, PressureUnits.InchesOfMercury);
  }
  /**
   * Create a new Pressure instance from a DynesPerSquareCentimeter
   *
   * @param value The unit as DynesPerSquareCentimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromDynesPerSquareCentimeter(value) {
    return new Pressure(value, PressureUnits.DynesPerSquareCentimeter);
  }
  /**
   * Create a new Pressure instance from a PoundsPerInchSecondSquared
   *
   * @param value The unit as PoundsPerInchSecondSquared to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromPoundsPerInchSecondSquared(value) {
    return new Pressure(value, PressureUnits.PoundsPerInchSecondSquared);
  }
  /**
   * Create a new Pressure instance from a MetersOfWaterColumn
   *
   * @param value The unit as MetersOfWaterColumn to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMetersOfWaterColumn(value) {
    return new Pressure(value, PressureUnits.MetersOfWaterColumn);
  }
  /**
   * Create a new Pressure instance from a InchesOfWaterColumn
   *
   * @param value The unit as InchesOfWaterColumn to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromInchesOfWaterColumn(value) {
    return new Pressure(value, PressureUnits.InchesOfWaterColumn);
  }
  /**
   * Create a new Pressure instance from a MetersOfElevation
   *
   * @param value The unit as MetersOfElevation to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMetersOfElevation(value) {
    return new Pressure(value, PressureUnits.MetersOfElevation);
  }
  /**
   * Create a new Pressure instance from a FeetOfElevation
   *
   * @param value The unit as FeetOfElevation to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromFeetOfElevation(value) {
    return new Pressure(value, PressureUnits.FeetOfElevation);
  }
  /**
   * Create a new Pressure instance from a Micropascals
   *
   * @param value The unit as Micropascals to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMicropascals(value) {
    return new Pressure(value, PressureUnits.Micropascals);
  }
  /**
   * Create a new Pressure instance from a Millipascals
   *
   * @param value The unit as Millipascals to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMillipascals(value) {
    return new Pressure(value, PressureUnits.Millipascals);
  }
  /**
   * Create a new Pressure instance from a Decapascals
   *
   * @param value The unit as Decapascals to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromDecapascals(value) {
    return new Pressure(value, PressureUnits.Decapascals);
  }
  /**
   * Create a new Pressure instance from a Hectopascals
   *
   * @param value The unit as Hectopascals to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromHectopascals(value) {
    return new Pressure(value, PressureUnits.Hectopascals);
  }
  /**
   * Create a new Pressure instance from a Kilopascals
   *
   * @param value The unit as Kilopascals to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilopascals(value) {
    return new Pressure(value, PressureUnits.Kilopascals);
  }
  /**
   * Create a new Pressure instance from a Megapascals
   *
   * @param value The unit as Megapascals to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMegapascals(value) {
    return new Pressure(value, PressureUnits.Megapascals);
  }
  /**
   * Create a new Pressure instance from a Gigapascals
   *
   * @param value The unit as Gigapascals to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromGigapascals(value) {
    return new Pressure(value, PressureUnits.Gigapascals);
  }
  /**
   * Create a new Pressure instance from a Microbars
   *
   * @param value The unit as Microbars to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMicrobars(value) {
    return new Pressure(value, PressureUnits.Microbars);
  }
  /**
   * Create a new Pressure instance from a Millibars
   *
   * @param value The unit as Millibars to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMillibars(value) {
    return new Pressure(value, PressureUnits.Millibars);
  }
  /**
   * Create a new Pressure instance from a Centibars
   *
   * @param value The unit as Centibars to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromCentibars(value) {
    return new Pressure(value, PressureUnits.Centibars);
  }
  /**
   * Create a new Pressure instance from a Decibars
   *
   * @param value The unit as Decibars to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromDecibars(value) {
    return new Pressure(value, PressureUnits.Decibars);
  }
  /**
   * Create a new Pressure instance from a Kilobars
   *
   * @param value The unit as Kilobars to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilobars(value) {
    return new Pressure(value, PressureUnits.Kilobars);
  }
  /**
   * Create a new Pressure instance from a Megabars
   *
   * @param value The unit as Megabars to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMegabars(value) {
    return new Pressure(value, PressureUnits.Megabars);
  }
  /**
   * Create a new Pressure instance from a KilonewtonsPerSquareMeter
   *
   * @param value The unit as KilonewtonsPerSquareMeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilonewtonsPerSquareMeter(value) {
    return new Pressure(value, PressureUnits.KilonewtonsPerSquareMeter);
  }
  /**
   * Create a new Pressure instance from a MeganewtonsPerSquareMeter
   *
   * @param value The unit as MeganewtonsPerSquareMeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMeganewtonsPerSquareMeter(value) {
    return new Pressure(value, PressureUnits.MeganewtonsPerSquareMeter);
  }
  /**
   * Create a new Pressure instance from a KilonewtonsPerSquareCentimeter
   *
   * @param value The unit as KilonewtonsPerSquareCentimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilonewtonsPerSquareCentimeter(value) {
    return new Pressure(value, PressureUnits.KilonewtonsPerSquareCentimeter);
  }
  /**
   * Create a new Pressure instance from a KilonewtonsPerSquareMillimeter
   *
   * @param value The unit as KilonewtonsPerSquareMillimeter to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilonewtonsPerSquareMillimeter(value) {
    return new Pressure(value, PressureUnits.KilonewtonsPerSquareMillimeter);
  }
  /**
   * Create a new Pressure instance from a KilopoundsForcePerSquareInch
   *
   * @param value The unit as KilopoundsForcePerSquareInch to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilopoundsForcePerSquareInch(value) {
    return new Pressure(value, PressureUnits.KilopoundsForcePerSquareInch);
  }
  /**
   * Create a new Pressure instance from a KilopoundsForcePerSquareMil
   *
   * @param value The unit as KilopoundsForcePerSquareMil to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilopoundsForcePerSquareMil(value) {
    return new Pressure(value, PressureUnits.KilopoundsForcePerSquareMil);
  }
  /**
   * Create a new Pressure instance from a KilopoundsForcePerSquareFoot
   *
   * @param value The unit as KilopoundsForcePerSquareFoot to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromKilopoundsForcePerSquareFoot(value) {
    return new Pressure(value, PressureUnits.KilopoundsForcePerSquareFoot);
  }
  /**
   * Create a new Pressure instance from a MillimetersOfWaterColumn
   *
   * @param value The unit as MillimetersOfWaterColumn to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromMillimetersOfWaterColumn(value) {
    return new Pressure(value, PressureUnits.MillimetersOfWaterColumn);
  }
  /**
   * Create a new Pressure instance from a CentimetersOfWaterColumn
   *
   * @param value The unit as CentimetersOfWaterColumn to create a new Pressure from.
   * @returns The new Pressure instance.
   */
  static FromCentimetersOfWaterColumn(value) {
    return new Pressure(value, PressureUnits.CentimetersOfWaterColumn);
  }
  /**
   * Gets the base unit enumeration associated with Pressure
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return PressureUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return PressureUnits.Pascals;
  }
  /**
   * Create API DTO represent a Pressure unit.
   * @param holdInUnit The specific Pressure unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = PressureUnits.Pascals) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Pressure unit from an API DTO representation.
   * @param dtoPressure The Pressure API DTO representation
   */
  static FromDto(dtoPressure) {
    return new Pressure(dtoPressure.value, dtoPressure.unit);
  }
  /**
   * Convert Pressure to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case PressureUnits.Pascals:
        return this.Pascals;
      case PressureUnits.Atmospheres:
        return this.Atmospheres;
      case PressureUnits.Bars:
        return this.Bars;
      case PressureUnits.KilogramsForcePerSquareMeter:
        return this.KilogramsForcePerSquareMeter;
      case PressureUnits.KilogramsForcePerSquareCentimeter:
        return this.KilogramsForcePerSquareCentimeter;
      case PressureUnits.KilogramsForcePerSquareMillimeter:
        return this.KilogramsForcePerSquareMillimeter;
      case PressureUnits.NewtonsPerSquareMeter:
        return this.NewtonsPerSquareMeter;
      case PressureUnits.NewtonsPerSquareCentimeter:
        return this.NewtonsPerSquareCentimeter;
      case PressureUnits.NewtonsPerSquareMillimeter:
        return this.NewtonsPerSquareMillimeter;
      case PressureUnits.TechnicalAtmospheres:
        return this.TechnicalAtmospheres;
      case PressureUnits.Torrs:
        return this.Torrs;
      case PressureUnits.PoundsForcePerSquareInch:
        return this.PoundsForcePerSquareInch;
      case PressureUnits.PoundsForcePerSquareMil:
        return this.PoundsForcePerSquareMil;
      case PressureUnits.PoundsForcePerSquareFoot:
        return this.PoundsForcePerSquareFoot;
      case PressureUnits.TonnesForcePerSquareMillimeter:
        return this.TonnesForcePerSquareMillimeter;
      case PressureUnits.TonnesForcePerSquareMeter:
        return this.TonnesForcePerSquareMeter;
      case PressureUnits.MetersOfHead:
        return this.MetersOfHead;
      case PressureUnits.TonnesForcePerSquareCentimeter:
        return this.TonnesForcePerSquareCentimeter;
      case PressureUnits.FeetOfHead:
        return this.FeetOfHead;
      case PressureUnits.MillimetersOfMercury:
        return this.MillimetersOfMercury;
      case PressureUnits.InchesOfMercury:
        return this.InchesOfMercury;
      case PressureUnits.DynesPerSquareCentimeter:
        return this.DynesPerSquareCentimeter;
      case PressureUnits.PoundsPerInchSecondSquared:
        return this.PoundsPerInchSecondSquared;
      case PressureUnits.MetersOfWaterColumn:
        return this.MetersOfWaterColumn;
      case PressureUnits.InchesOfWaterColumn:
        return this.InchesOfWaterColumn;
      case PressureUnits.MetersOfElevation:
        return this.MetersOfElevation;
      case PressureUnits.FeetOfElevation:
        return this.FeetOfElevation;
      case PressureUnits.Micropascals:
        return this.Micropascals;
      case PressureUnits.Millipascals:
        return this.Millipascals;
      case PressureUnits.Decapascals:
        return this.Decapascals;
      case PressureUnits.Hectopascals:
        return this.Hectopascals;
      case PressureUnits.Kilopascals:
        return this.Kilopascals;
      case PressureUnits.Megapascals:
        return this.Megapascals;
      case PressureUnits.Gigapascals:
        return this.Gigapascals;
      case PressureUnits.Microbars:
        return this.Microbars;
      case PressureUnits.Millibars:
        return this.Millibars;
      case PressureUnits.Centibars:
        return this.Centibars;
      case PressureUnits.Decibars:
        return this.Decibars;
      case PressureUnits.Kilobars:
        return this.Kilobars;
      case PressureUnits.Megabars:
        return this.Megabars;
      case PressureUnits.KilonewtonsPerSquareMeter:
        return this.KilonewtonsPerSquareMeter;
      case PressureUnits.MeganewtonsPerSquareMeter:
        return this.MeganewtonsPerSquareMeter;
      case PressureUnits.KilonewtonsPerSquareCentimeter:
        return this.KilonewtonsPerSquareCentimeter;
      case PressureUnits.KilonewtonsPerSquareMillimeter:
        return this.KilonewtonsPerSquareMillimeter;
      case PressureUnits.KilopoundsForcePerSquareInch:
        return this.KilopoundsForcePerSquareInch;
      case PressureUnits.KilopoundsForcePerSquareMil:
        return this.KilopoundsForcePerSquareMil;
      case PressureUnits.KilopoundsForcePerSquareFoot:
        return this.KilopoundsForcePerSquareFoot;
      case PressureUnits.MillimetersOfWaterColumn:
        return this.MillimetersOfWaterColumn;
      case PressureUnits.CentimetersOfWaterColumn:
        return this.CentimetersOfWaterColumn;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case PressureUnits.Pascals:
        return this.value;
      case PressureUnits.Atmospheres:
        {
          const v4 = super.internalMultiply(1.01325, 1e5);
          return super.internalDivide(this.value, v4);
        }
      case PressureUnits.Bars:
        return super.internalDivide(this.value, 1e5);
      case PressureUnits.KilogramsForcePerSquareMeter:
        return super.internalMultiply(this.value, 0.101971619222242);
      case PressureUnits.KilogramsForcePerSquareCentimeter:
        return super.internalDivide(this.value, 9.80665e4);
      case PressureUnits.KilogramsForcePerSquareMillimeter:
        return super.internalDivide(this.value, 9.80665e6);
      case PressureUnits.NewtonsPerSquareMeter:
        return this.value;
      case PressureUnits.NewtonsPerSquareCentimeter:
        return super.internalDivide(this.value, 1e4);
      case PressureUnits.NewtonsPerSquareMillimeter:
        return super.internalDivide(this.value, 1e6);
      case PressureUnits.TechnicalAtmospheres:
        {
          const v4 = super.internalMultiply(9.80680592331, 1e4);
          return super.internalDivide(this.value, v4);
        }
      case PressureUnits.Torrs:
        {
          const v4 = super.internalMultiply(1.3332266752, 1e2);
          return super.internalDivide(this.value, v4);
        }
      case PressureUnits.PoundsForcePerSquareInch:
        return super.internalDivide(this.value, 6.894757293168361e3);
      case PressureUnits.PoundsForcePerSquareMil:
        return super.internalDivide(this.value, 6.894757293168361e9);
      case PressureUnits.PoundsForcePerSquareFoot:
        return super.internalDivide(this.value, 4.788025898033584e1);
      case PressureUnits.TonnesForcePerSquareMillimeter:
        return super.internalDivide(this.value, 9.80665e9);
      case PressureUnits.TonnesForcePerSquareMeter:
        return super.internalDivide(this.value, 9.80665e3);
      case PressureUnits.MetersOfHead:
        return super.internalMultiply(this.value, 0.0001019977334);
      case PressureUnits.TonnesForcePerSquareCentimeter:
        return super.internalDivide(this.value, 9.80665e7);
      case PressureUnits.FeetOfHead:
        return super.internalMultiply(this.value, 0.000334552565551);
      case PressureUnits.MillimetersOfMercury:
        return super.internalMultiply(this.value, 7.50061561302643e-3);
      case PressureUnits.InchesOfMercury:
        return super.internalMultiply(this.value, 2.95299830714159e-4);
      case PressureUnits.DynesPerSquareCentimeter:
        return super.internalDivide(this.value, 1.0e-1);
      case PressureUnits.PoundsPerInchSecondSquared:
        return super.internalDivide(this.value, 1.785796732283465e1);
      case PressureUnits.MetersOfWaterColumn:
        return super.internalDivide(this.value, 9.806650000000272e3);
      case PressureUnits.InchesOfWaterColumn:
        return super.internalDivide(this.value, 249.08890833333);
      case PressureUnits.MetersOfElevation:
        {
          const v4 = super.internalDivide(this.value, 101325.0);
          const v6 = super.internalPow(v4, 0.190284);
          const v7 = super.internalSubtract(1.0, v6);
          return super.internalMultiply(v7, 44307.69396);
        }
      case PressureUnits.FeetOfElevation:
        {
          const v4 = super.internalDivide(this.value, 101325.0);
          const v6 = super.internalPow(v4, 0.190284);
          const v7 = super.internalSubtract(1.0, v6);
          return super.internalMultiply(v7, 145366.45);
        }
      case PressureUnits.Micropascals:
        return super.internalDivide(this.value, 0.000001);
      case PressureUnits.Millipascals:
        return super.internalDivide(this.value, 0.001);
      case PressureUnits.Decapascals:
        return super.internalDivide(this.value, 10);
      case PressureUnits.Hectopascals:
        return super.internalDivide(this.value, 100);
      case PressureUnits.Kilopascals:
        return super.internalDivide(this.value, 1000);
      case PressureUnits.Megapascals:
        return super.internalDivide(this.value, 1000000);
      case PressureUnits.Gigapascals:
        return super.internalDivide(this.value, 1000000000);
      case PressureUnits.Microbars:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          return super.internalDivide(v3, 0.000001);
        }
      case PressureUnits.Millibars:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          return super.internalDivide(v3, 0.001);
        }
      case PressureUnits.Centibars:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          return super.internalDivide(v3, 0.01);
        }
      case PressureUnits.Decibars:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          return super.internalDivide(v3, 0.1);
        }
      case PressureUnits.Kilobars:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          return super.internalDivide(v3, 1000);
        }
      case PressureUnits.Megabars:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          return super.internalDivide(v3, 1000000);
        }
      case PressureUnits.KilonewtonsPerSquareMeter:
        return super.internalDivide(this.value, 1000);
      case PressureUnits.MeganewtonsPerSquareMeter:
        return super.internalDivide(this.value, 1000000);
      case PressureUnits.KilonewtonsPerSquareCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e4);
          return super.internalDivide(v3, 1000);
        }
      case PressureUnits.KilonewtonsPerSquareMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e6);
          return super.internalDivide(v3, 1000);
        }
      case PressureUnits.KilopoundsForcePerSquareInch:
        {
          const v3 = super.internalDivide(this.value, 6.894757293168361e3);
          return super.internalDivide(v3, 1000);
        }
      case PressureUnits.KilopoundsForcePerSquareMil:
        {
          const v3 = super.internalDivide(this.value, 6.894757293168361e9);
          return super.internalDivide(v3, 1000);
        }
      case PressureUnits.KilopoundsForcePerSquareFoot:
        {
          const v3 = super.internalDivide(this.value, 4.788025898033584e1);
          return super.internalDivide(v3, 1000);
        }
      case PressureUnits.MillimetersOfWaterColumn:
        {
          const v3 = super.internalDivide(this.value, 9.806650000000272e3);
          return super.internalDivide(v3, 0.001);
        }
      case PressureUnits.CentimetersOfWaterColumn:
        {
          const v3 = super.internalDivide(this.value, 9.806650000000272e3);
          return super.internalDivide(v3, 0.01);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case PressureUnits.Pascals:
        return this.value;
      case PressureUnits.Atmospheres:
        return this.value / (1.01325 * 1e5);
      case PressureUnits.Bars:
        return this.value / 1e5;
      case PressureUnits.KilogramsForcePerSquareMeter:
        return this.value * 0.101971619222242;
      case PressureUnits.KilogramsForcePerSquareCentimeter:
        return this.value / 9.80665e4;
      case PressureUnits.KilogramsForcePerSquareMillimeter:
        return this.value / 9.80665e6;
      case PressureUnits.NewtonsPerSquareMeter:
        return this.value;
      case PressureUnits.NewtonsPerSquareCentimeter:
        return this.value / 1e4;
      case PressureUnits.NewtonsPerSquareMillimeter:
        return this.value / 1e6;
      case PressureUnits.TechnicalAtmospheres:
        return this.value / (9.80680592331 * 1e4);
      case PressureUnits.Torrs:
        return this.value / (1.3332266752 * 1e2);
      case PressureUnits.PoundsForcePerSquareInch:
        return this.value / 6.894757293168361e3;
      case PressureUnits.PoundsForcePerSquareMil:
        return this.value / 6.894757293168361e9;
      case PressureUnits.PoundsForcePerSquareFoot:
        return this.value / 4.788025898033584e1;
      case PressureUnits.TonnesForcePerSquareMillimeter:
        return this.value / 9.80665e9;
      case PressureUnits.TonnesForcePerSquareMeter:
        return this.value / 9.80665e3;
      case PressureUnits.MetersOfHead:
        return this.value * 0.0001019977334;
      case PressureUnits.TonnesForcePerSquareCentimeter:
        return this.value / 9.80665e7;
      case PressureUnits.FeetOfHead:
        return this.value * 0.000334552565551;
      case PressureUnits.MillimetersOfMercury:
        return this.value * 7.50061561302643e-3;
      case PressureUnits.InchesOfMercury:
        return this.value * 2.95299830714159e-4;
      case PressureUnits.DynesPerSquareCentimeter:
        return this.value / 1.0e-1;
      case PressureUnits.PoundsPerInchSecondSquared:
        return this.value / 1.785796732283465e1;
      case PressureUnits.MetersOfWaterColumn:
        return this.value / 9.806650000000272e3;
      case PressureUnits.InchesOfWaterColumn:
        return this.value / 249.08890833333;
      case PressureUnits.MetersOfElevation:
        return (1.0 - Math.pow(this.value / 101325.0, 0.190284)) * 44307.69396;
      case PressureUnits.FeetOfElevation:
        return (1.0 - Math.pow(this.value / 101325.0, 0.190284)) * 145366.45;
      case PressureUnits.Micropascals:
        return this.value / 0.000001;
      case PressureUnits.Millipascals:
        return this.value / 0.001;
      case PressureUnits.Decapascals:
        return this.value / 10;
      case PressureUnits.Hectopascals:
        return this.value / 100;
      case PressureUnits.Kilopascals:
        return this.value / 1000;
      case PressureUnits.Megapascals:
        return this.value / 1000000;
      case PressureUnits.Gigapascals:
        return this.value / 1000000000;
      case PressureUnits.Microbars:
        return this.value / 1e5 / 0.000001;
      case PressureUnits.Millibars:
        return this.value / 1e5 / 0.001;
      case PressureUnits.Centibars:
        return this.value / 1e5 / 0.01;
      case PressureUnits.Decibars:
        return this.value / 1e5 / 0.1;
      case PressureUnits.Kilobars:
        return this.value / 1e5 / 1000;
      case PressureUnits.Megabars:
        return this.value / 1e5 / 1000000;
      case PressureUnits.KilonewtonsPerSquareMeter:
        return this.value / 1000;
      case PressureUnits.MeganewtonsPerSquareMeter:
        return this.value / 1000000;
      case PressureUnits.KilonewtonsPerSquareCentimeter:
        return this.value / 1e4 / 1000;
      case PressureUnits.KilonewtonsPerSquareMillimeter:
        return this.value / 1e6 / 1000;
      case PressureUnits.KilopoundsForcePerSquareInch:
        return this.value / 6.894757293168361e3 / 1000;
      case PressureUnits.KilopoundsForcePerSquareMil:
        return this.value / 6.894757293168361e9 / 1000;
      case PressureUnits.KilopoundsForcePerSquareFoot:
        return this.value / 4.788025898033584e1 / 1000;
      case PressureUnits.MillimetersOfWaterColumn:
        return this.value / 9.806650000000272e3 / 0.001;
      case PressureUnits.CentimetersOfWaterColumn:
        return this.value / 9.806650000000272e3 / 0.01;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case PressureUnits.Pascals:
        return value;
      case PressureUnits.Atmospheres:
        {
          const v3 = super.internalMultiply(value, 1.01325);
          return super.internalMultiply(v3, 1e5);
        }
      case PressureUnits.Bars:
        return super.internalMultiply(value, 1e5);
      case PressureUnits.KilogramsForcePerSquareMeter:
        return super.internalMultiply(value, 9.80665019960652);
      case PressureUnits.KilogramsForcePerSquareCentimeter:
        return super.internalMultiply(value, 9.80665e4);
      case PressureUnits.KilogramsForcePerSquareMillimeter:
        return super.internalMultiply(value, 9.80665e6);
      case PressureUnits.NewtonsPerSquareMeter:
        return value;
      case PressureUnits.NewtonsPerSquareCentimeter:
        return super.internalMultiply(value, 1e4);
      case PressureUnits.NewtonsPerSquareMillimeter:
        return super.internalMultiply(value, 1e6);
      case PressureUnits.TechnicalAtmospheres:
        {
          const v3 = super.internalMultiply(value, 9.80680592331);
          return super.internalMultiply(v3, 1e4);
        }
      case PressureUnits.Torrs:
        {
          const v3 = super.internalMultiply(value, 1.3332266752);
          return super.internalMultiply(v3, 1e2);
        }
      case PressureUnits.PoundsForcePerSquareInch:
        return super.internalMultiply(value, 6.894757293168361e3);
      case PressureUnits.PoundsForcePerSquareMil:
        return super.internalMultiply(value, 6.894757293168361e9);
      case PressureUnits.PoundsForcePerSquareFoot:
        return super.internalMultiply(value, 4.788025898033584e1);
      case PressureUnits.TonnesForcePerSquareMillimeter:
        return super.internalMultiply(value, 9.80665e9);
      case PressureUnits.TonnesForcePerSquareMeter:
        return super.internalMultiply(value, 9.80665e3);
      case PressureUnits.MetersOfHead:
        return super.internalMultiply(value, 9804.139432);
      case PressureUnits.TonnesForcePerSquareCentimeter:
        return super.internalMultiply(value, 9.80665e7);
      case PressureUnits.FeetOfHead:
        return super.internalMultiply(value, 2989.0669);
      case PressureUnits.MillimetersOfMercury:
        return super.internalDivide(value, 7.50061561302643e-3);
      case PressureUnits.InchesOfMercury:
        return super.internalDivide(value, 2.95299830714159e-4);
      case PressureUnits.DynesPerSquareCentimeter:
        return super.internalMultiply(value, 1.0e-1);
      case PressureUnits.PoundsPerInchSecondSquared:
        return super.internalMultiply(value, 1.785796732283465e1);
      case PressureUnits.MetersOfWaterColumn:
        return super.internalMultiply(value, 9.806650000000272e3);
      case PressureUnits.InchesOfWaterColumn:
        return super.internalMultiply(value, 249.08890833333);
      case PressureUnits.MetersOfElevation:
        {
          const v4 = super.internalDivide(value, 44307.69396);
          const v5 = super.internalSubtract(1.0, v4);
          const v7 = super.internalPow(v5, 5.2553026003237266401799415610351);
          return super.internalMultiply(v7, 101325.0);
        }
      case PressureUnits.FeetOfElevation:
        {
          const v4 = super.internalDivide(value, 145366.45);
          const v5 = super.internalSubtract(1.0, v4);
          const v7 = super.internalPow(v5, 5.2553026003237266401799415610351);
          return super.internalMultiply(v7, 101325.0);
        }
      case PressureUnits.Micropascals:
        return super.internalMultiply(value, 0.000001);
      case PressureUnits.Millipascals:
        return super.internalMultiply(value, 0.001);
      case PressureUnits.Decapascals:
        return super.internalMultiply(value, 10);
      case PressureUnits.Hectopascals:
        return super.internalMultiply(value, 100);
      case PressureUnits.Kilopascals:
        return super.internalMultiply(value, 1000);
      case PressureUnits.Megapascals:
        return super.internalMultiply(value, 1000000);
      case PressureUnits.Gigapascals:
        return super.internalMultiply(value, 1000000000);
      case PressureUnits.Microbars:
        {
          const v3 = super.internalMultiply(value, 1e5);
          return super.internalMultiply(v3, 0.000001);
        }
      case PressureUnits.Millibars:
        {
          const v3 = super.internalMultiply(value, 1e5);
          return super.internalMultiply(v3, 0.001);
        }
      case PressureUnits.Centibars:
        {
          const v3 = super.internalMultiply(value, 1e5);
          return super.internalMultiply(v3, 0.01);
        }
      case PressureUnits.Decibars:
        {
          const v3 = super.internalMultiply(value, 1e5);
          return super.internalMultiply(v3, 0.1);
        }
      case PressureUnits.Kilobars:
        {
          const v3 = super.internalMultiply(value, 1e5);
          return super.internalMultiply(v3, 1000);
        }
      case PressureUnits.Megabars:
        {
          const v3 = super.internalMultiply(value, 1e5);
          return super.internalMultiply(v3, 1000000);
        }
      case PressureUnits.KilonewtonsPerSquareMeter:
        return super.internalMultiply(value, 1000);
      case PressureUnits.MeganewtonsPerSquareMeter:
        return super.internalMultiply(value, 1000000);
      case PressureUnits.KilonewtonsPerSquareCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e4);
          return super.internalMultiply(v3, 1000);
        }
      case PressureUnits.KilonewtonsPerSquareMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e6);
          return super.internalMultiply(v3, 1000);
        }
      case PressureUnits.KilopoundsForcePerSquareInch:
        {
          const v3 = super.internalMultiply(value, 6.894757293168361e3);
          return super.internalMultiply(v3, 1000);
        }
      case PressureUnits.KilopoundsForcePerSquareMil:
        {
          const v3 = super.internalMultiply(value, 6.894757293168361e9);
          return super.internalMultiply(v3, 1000);
        }
      case PressureUnits.KilopoundsForcePerSquareFoot:
        {
          const v3 = super.internalMultiply(value, 4.788025898033584e1);
          return super.internalMultiply(v3, 1000);
        }
      case PressureUnits.MillimetersOfWaterColumn:
        {
          const v3 = super.internalMultiply(value, 9.806650000000272e3);
          return super.internalMultiply(v3, 0.001);
        }
      case PressureUnits.CentimetersOfWaterColumn:
        {
          const v3 = super.internalMultiply(value, 9.806650000000272e3);
          return super.internalMultiply(v3, 0.01);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case PressureUnits.Pascals:
        return value;
      case PressureUnits.Atmospheres:
        return value * 1.01325 * 1e5;
      case PressureUnits.Bars:
        return value * 1e5;
      case PressureUnits.KilogramsForcePerSquareMeter:
        return value * 9.80665019960652;
      case PressureUnits.KilogramsForcePerSquareCentimeter:
        return value * 9.80665e4;
      case PressureUnits.KilogramsForcePerSquareMillimeter:
        return value * 9.80665e6;
      case PressureUnits.NewtonsPerSquareMeter:
        return value;
      case PressureUnits.NewtonsPerSquareCentimeter:
        return value * 1e4;
      case PressureUnits.NewtonsPerSquareMillimeter:
        return value * 1e6;
      case PressureUnits.TechnicalAtmospheres:
        return value * 9.80680592331 * 1e4;
      case PressureUnits.Torrs:
        return value * 1.3332266752 * 1e2;
      case PressureUnits.PoundsForcePerSquareInch:
        return value * 6.894757293168361e3;
      case PressureUnits.PoundsForcePerSquareMil:
        return value * 6.894757293168361e9;
      case PressureUnits.PoundsForcePerSquareFoot:
        return value * 4.788025898033584e1;
      case PressureUnits.TonnesForcePerSquareMillimeter:
        return value * 9.80665e9;
      case PressureUnits.TonnesForcePerSquareMeter:
        return value * 9.80665e3;
      case PressureUnits.MetersOfHead:
        return value * 9804.139432;
      case PressureUnits.TonnesForcePerSquareCentimeter:
        return value * 9.80665e7;
      case PressureUnits.FeetOfHead:
        return value * 2989.0669;
      case PressureUnits.MillimetersOfMercury:
        return value / 7.50061561302643e-3;
      case PressureUnits.InchesOfMercury:
        return value / 2.95299830714159e-4;
      case PressureUnits.DynesPerSquareCentimeter:
        return value * 1.0e-1;
      case PressureUnits.PoundsPerInchSecondSquared:
        return value * 1.785796732283465e1;
      case PressureUnits.MetersOfWaterColumn:
        return value * 9.806650000000272e3;
      case PressureUnits.InchesOfWaterColumn:
        return value * 249.08890833333;
      case PressureUnits.MetersOfElevation:
        return Math.pow(1.0 - value / 44307.69396, 5.2553026003237266401799415610351) * 101325.0;
      case PressureUnits.FeetOfElevation:
        return Math.pow(1.0 - value / 145366.45, 5.2553026003237266401799415610351) * 101325.0;
      case PressureUnits.Micropascals:
        return value * 0.000001;
      case PressureUnits.Millipascals:
        return value * 0.001;
      case PressureUnits.Decapascals:
        return value * 10;
      case PressureUnits.Hectopascals:
        return value * 100;
      case PressureUnits.Kilopascals:
        return value * 1000;
      case PressureUnits.Megapascals:
        return value * 1000000;
      case PressureUnits.Gigapascals:
        return value * 1000000000;
      case PressureUnits.Microbars:
        return value * 1e5 * 0.000001;
      case PressureUnits.Millibars:
        return value * 1e5 * 0.001;
      case PressureUnits.Centibars:
        return value * 1e5 * 0.01;
      case PressureUnits.Decibars:
        return value * 1e5 * 0.1;
      case PressureUnits.Kilobars:
        return value * 1e5 * 1000;
      case PressureUnits.Megabars:
        return value * 1e5 * 1000000;
      case PressureUnits.KilonewtonsPerSquareMeter:
        return value * 1000;
      case PressureUnits.MeganewtonsPerSquareMeter:
        return value * 1000000;
      case PressureUnits.KilonewtonsPerSquareCentimeter:
        return value * 1e4 * 1000;
      case PressureUnits.KilonewtonsPerSquareMillimeter:
        return value * 1e6 * 1000;
      case PressureUnits.KilopoundsForcePerSquareInch:
        return value * 6.894757293168361e3 * 1000;
      case PressureUnits.KilopoundsForcePerSquareMil:
        return value * 6.894757293168361e9 * 1000;
      case PressureUnits.KilopoundsForcePerSquareFoot:
        return value * 4.788025898033584e1 * 1000;
      case PressureUnits.MillimetersOfWaterColumn:
        return value * 9.806650000000272e3 * 0.001;
      case PressureUnits.CentimetersOfWaterColumn:
        return value * 9.806650000000272e3 * 0.01;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Pressure to string.
   * Note! the default format for Pressure is Pascals.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Pressure.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Pressure.
   */
  toString(unit = PressureUnits.Pascals, fractionalDigits) {
    switch (unit) {
      case PressureUnits.Pascals:
        return super.truncateFractionDigits(this.Pascals, fractionalDigits) + ` Pa`;
      case PressureUnits.Atmospheres:
        return super.truncateFractionDigits(this.Atmospheres, fractionalDigits) + ` atm`;
      case PressureUnits.Bars:
        return super.truncateFractionDigits(this.Bars, fractionalDigits) + ` bar`;
      case PressureUnits.KilogramsForcePerSquareMeter:
        return super.truncateFractionDigits(this.KilogramsForcePerSquareMeter, fractionalDigits) + ` kgf/m²`;
      case PressureUnits.KilogramsForcePerSquareCentimeter:
        return super.truncateFractionDigits(this.KilogramsForcePerSquareCentimeter, fractionalDigits) + ` kgf/cm²`;
      case PressureUnits.KilogramsForcePerSquareMillimeter:
        return super.truncateFractionDigits(this.KilogramsForcePerSquareMillimeter, fractionalDigits) + ` kgf/mm²`;
      case PressureUnits.NewtonsPerSquareMeter:
        return super.truncateFractionDigits(this.NewtonsPerSquareMeter, fractionalDigits) + ` N/m²`;
      case PressureUnits.NewtonsPerSquareCentimeter:
        return super.truncateFractionDigits(this.NewtonsPerSquareCentimeter, fractionalDigits) + ` N/cm²`;
      case PressureUnits.NewtonsPerSquareMillimeter:
        return super.truncateFractionDigits(this.NewtonsPerSquareMillimeter, fractionalDigits) + ` N/mm²`;
      case PressureUnits.TechnicalAtmospheres:
        return super.truncateFractionDigits(this.TechnicalAtmospheres, fractionalDigits) + ` at`;
      case PressureUnits.Torrs:
        return super.truncateFractionDigits(this.Torrs, fractionalDigits) + ` torr`;
      case PressureUnits.PoundsForcePerSquareInch:
        return super.truncateFractionDigits(this.PoundsForcePerSquareInch, fractionalDigits) + ` psi`;
      case PressureUnits.PoundsForcePerSquareMil:
        return super.truncateFractionDigits(this.PoundsForcePerSquareMil, fractionalDigits) + ` lb/mil²`;
      case PressureUnits.PoundsForcePerSquareFoot:
        return super.truncateFractionDigits(this.PoundsForcePerSquareFoot, fractionalDigits) + ` lb/ft²`;
      case PressureUnits.TonnesForcePerSquareMillimeter:
        return super.truncateFractionDigits(this.TonnesForcePerSquareMillimeter, fractionalDigits) + ` tf/mm²`;
      case PressureUnits.TonnesForcePerSquareMeter:
        return super.truncateFractionDigits(this.TonnesForcePerSquareMeter, fractionalDigits) + ` tf/m²`;
      case PressureUnits.MetersOfHead:
        return super.truncateFractionDigits(this.MetersOfHead, fractionalDigits) + ` m of head`;
      case PressureUnits.TonnesForcePerSquareCentimeter:
        return super.truncateFractionDigits(this.TonnesForcePerSquareCentimeter, fractionalDigits) + ` tf/cm²`;
      case PressureUnits.FeetOfHead:
        return super.truncateFractionDigits(this.FeetOfHead, fractionalDigits) + ` ft of head`;
      case PressureUnits.MillimetersOfMercury:
        return super.truncateFractionDigits(this.MillimetersOfMercury, fractionalDigits) + ` mmHg`;
      case PressureUnits.InchesOfMercury:
        return super.truncateFractionDigits(this.InchesOfMercury, fractionalDigits) + ` inHg`;
      case PressureUnits.DynesPerSquareCentimeter:
        return super.truncateFractionDigits(this.DynesPerSquareCentimeter, fractionalDigits) + ` dyn/cm²`;
      case PressureUnits.PoundsPerInchSecondSquared:
        return super.truncateFractionDigits(this.PoundsPerInchSecondSquared, fractionalDigits) + ` lbm/(in·s²)`;
      case PressureUnits.MetersOfWaterColumn:
        return super.truncateFractionDigits(this.MetersOfWaterColumn, fractionalDigits) + ` mH₂O`;
      case PressureUnits.InchesOfWaterColumn:
        return super.truncateFractionDigits(this.InchesOfWaterColumn, fractionalDigits) + ` inH2O`;
      case PressureUnits.MetersOfElevation:
        return super.truncateFractionDigits(this.MetersOfElevation, fractionalDigits) + ` m of elevation`;
      case PressureUnits.FeetOfElevation:
        return super.truncateFractionDigits(this.FeetOfElevation, fractionalDigits) + ` ft of elevation`;
      case PressureUnits.Micropascals:
        return super.truncateFractionDigits(this.Micropascals, fractionalDigits) + ` μPa`;
      case PressureUnits.Millipascals:
        return super.truncateFractionDigits(this.Millipascals, fractionalDigits) + ` mPa`;
      case PressureUnits.Decapascals:
        return super.truncateFractionDigits(this.Decapascals, fractionalDigits) + ` daPa`;
      case PressureUnits.Hectopascals:
        return super.truncateFractionDigits(this.Hectopascals, fractionalDigits) + ` hPa`;
      case PressureUnits.Kilopascals:
        return super.truncateFractionDigits(this.Kilopascals, fractionalDigits) + ` kPa`;
      case PressureUnits.Megapascals:
        return super.truncateFractionDigits(this.Megapascals, fractionalDigits) + ` MPa`;
      case PressureUnits.Gigapascals:
        return super.truncateFractionDigits(this.Gigapascals, fractionalDigits) + ` GPa`;
      case PressureUnits.Microbars:
        return super.truncateFractionDigits(this.Microbars, fractionalDigits) + ` μbar`;
      case PressureUnits.Millibars:
        return super.truncateFractionDigits(this.Millibars, fractionalDigits) + ` mbar`;
      case PressureUnits.Centibars:
        return super.truncateFractionDigits(this.Centibars, fractionalDigits) + ` cbar`;
      case PressureUnits.Decibars:
        return super.truncateFractionDigits(this.Decibars, fractionalDigits) + ` dbar`;
      case PressureUnits.Kilobars:
        return super.truncateFractionDigits(this.Kilobars, fractionalDigits) + ` kbar`;
      case PressureUnits.Megabars:
        return super.truncateFractionDigits(this.Megabars, fractionalDigits) + ` Mbar`;
      case PressureUnits.KilonewtonsPerSquareMeter:
        return super.truncateFractionDigits(this.KilonewtonsPerSquareMeter, fractionalDigits) + ` kN/m²`;
      case PressureUnits.MeganewtonsPerSquareMeter:
        return super.truncateFractionDigits(this.MeganewtonsPerSquareMeter, fractionalDigits) + ` MN/m²`;
      case PressureUnits.KilonewtonsPerSquareCentimeter:
        return super.truncateFractionDigits(this.KilonewtonsPerSquareCentimeter, fractionalDigits) + ` kN/cm²`;
      case PressureUnits.KilonewtonsPerSquareMillimeter:
        return super.truncateFractionDigits(this.KilonewtonsPerSquareMillimeter, fractionalDigits) + ` kN/mm²`;
      case PressureUnits.KilopoundsForcePerSquareInch:
        return super.truncateFractionDigits(this.KilopoundsForcePerSquareInch, fractionalDigits) + ` kpsi`;
      case PressureUnits.KilopoundsForcePerSquareMil:
        return super.truncateFractionDigits(this.KilopoundsForcePerSquareMil, fractionalDigits) + ` klb/mil²`;
      case PressureUnits.KilopoundsForcePerSquareFoot:
        return super.truncateFractionDigits(this.KilopoundsForcePerSquareFoot, fractionalDigits) + ` klb/ft²`;
      case PressureUnits.MillimetersOfWaterColumn:
        return super.truncateFractionDigits(this.MillimetersOfWaterColumn, fractionalDigits) + ` mmH₂O`;
      case PressureUnits.CentimetersOfWaterColumn:
        return super.truncateFractionDigits(this.CentimetersOfWaterColumn, fractionalDigits) + ` cmH₂O`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Pressure unit abbreviation.
   * Note! the default abbreviation for Pressure is Pascals.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Pressure.
   * @returns The abbreviation string of Pressure.
   */
  getUnitAbbreviation(unitAbbreviation = PressureUnits.Pascals) {
    switch (unitAbbreviation) {
      case PressureUnits.Pascals:
        return `Pa`;
      case PressureUnits.Atmospheres:
        return `atm`;
      case PressureUnits.Bars:
        return `bar`;
      case PressureUnits.KilogramsForcePerSquareMeter:
        return `kgf/m²`;
      case PressureUnits.KilogramsForcePerSquareCentimeter:
        return `kgf/cm²`;
      case PressureUnits.KilogramsForcePerSquareMillimeter:
        return `kgf/mm²`;
      case PressureUnits.NewtonsPerSquareMeter:
        return `N/m²`;
      case PressureUnits.NewtonsPerSquareCentimeter:
        return `N/cm²`;
      case PressureUnits.NewtonsPerSquareMillimeter:
        return `N/mm²`;
      case PressureUnits.TechnicalAtmospheres:
        return `at`;
      case PressureUnits.Torrs:
        return `torr`;
      case PressureUnits.PoundsForcePerSquareInch:
        return `psi`;
      case PressureUnits.PoundsForcePerSquareMil:
        return `lb/mil²`;
      case PressureUnits.PoundsForcePerSquareFoot:
        return `lb/ft²`;
      case PressureUnits.TonnesForcePerSquareMillimeter:
        return `tf/mm²`;
      case PressureUnits.TonnesForcePerSquareMeter:
        return `tf/m²`;
      case PressureUnits.MetersOfHead:
        return `m of head`;
      case PressureUnits.TonnesForcePerSquareCentimeter:
        return `tf/cm²`;
      case PressureUnits.FeetOfHead:
        return `ft of head`;
      case PressureUnits.MillimetersOfMercury:
        return `mmHg`;
      case PressureUnits.InchesOfMercury:
        return `inHg`;
      case PressureUnits.DynesPerSquareCentimeter:
        return `dyn/cm²`;
      case PressureUnits.PoundsPerInchSecondSquared:
        return `lbm/(in·s²)`;
      case PressureUnits.MetersOfWaterColumn:
        return `mH₂O`;
      case PressureUnits.InchesOfWaterColumn:
        return `inH2O`;
      case PressureUnits.MetersOfElevation:
        return `m of elevation`;
      case PressureUnits.FeetOfElevation:
        return `ft of elevation`;
      case PressureUnits.Micropascals:
        return `μPa`;
      case PressureUnits.Millipascals:
        return `mPa`;
      case PressureUnits.Decapascals:
        return `daPa`;
      case PressureUnits.Hectopascals:
        return `hPa`;
      case PressureUnits.Kilopascals:
        return `kPa`;
      case PressureUnits.Megapascals:
        return `MPa`;
      case PressureUnits.Gigapascals:
        return `GPa`;
      case PressureUnits.Microbars:
        return `μbar`;
      case PressureUnits.Millibars:
        return `mbar`;
      case PressureUnits.Centibars:
        return `cbar`;
      case PressureUnits.Decibars:
        return `dbar`;
      case PressureUnits.Kilobars:
        return `kbar`;
      case PressureUnits.Megabars:
        return `Mbar`;
      case PressureUnits.KilonewtonsPerSquareMeter:
        return `kN/m²`;
      case PressureUnits.MeganewtonsPerSquareMeter:
        return `MN/m²`;
      case PressureUnits.KilonewtonsPerSquareCentimeter:
        return `kN/cm²`;
      case PressureUnits.KilonewtonsPerSquareMillimeter:
        return `kN/mm²`;
      case PressureUnits.KilopoundsForcePerSquareInch:
        return `kpsi`;
      case PressureUnits.KilopoundsForcePerSquareMil:
        return `klb/mil²`;
      case PressureUnits.KilopoundsForcePerSquareFoot:
        return `klb/ft²`;
      case PressureUnits.MillimetersOfWaterColumn:
        return `mmH₂O`;
      case PressureUnits.CentimetersOfWaterColumn:
        return `cmH₂O`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Pressure are equals to the current Pressure.
   * @param pressure The other Pressure.
   * @returns True if the given Pressure are equal to the current Pressure.
   */
  equals(pressure) {
    return super.internalEquals(this.value, pressure.BaseValue);
  }
  /**
   * Compare the given Pressure against the current Pressure.
   * @param pressure The other Pressure.
   * @returns 0 if they are equal, -1 if the current Pressure is less then other, 1 if the current Pressure is greater then other.
   */
  compareTo(pressure) {
    return super.internalCompareTo(this.value, pressure.BaseValue);
  }
  /**
   * Add the given Pressure with the current Pressure.
   * @param pressure The other Pressure.
   * @returns A new Pressure instance with the results.
   */
  add(pressure) {
    return new Pressure(super.internalAdd(this.value, pressure.BaseValue));
  }
  /**
   * Subtract the given Pressure with the current Pressure.
   * @param pressure The other Pressure.
   * @returns A new Pressure instance with the results.
   */
  subtract(pressure) {
    return new Pressure(super.internalSubtract(this.value, pressure.BaseValue));
  }
  /**
   * Multiply the given Pressure with the current Pressure.
   * @param pressure The other Pressure.
   * @returns A new Pressure instance with the results.
   */
  multiply(pressure) {
    return new Pressure(super.internalMultiply(this.value, pressure.BaseValue));
  }
  /**
   * Divide the given Pressure with the current Pressure.
   * @param pressure The other Pressure.
   * @returns A new Pressure instance with the results.
   */
  divide(pressure) {
    return new Pressure(super.internalDivide(this.value, pressure.BaseValue));
  }
  /**
   * Modulo the given Pressure with the current Pressure.
   * @param pressure The other Pressure.
   * @returns A new Pressure instance with the results.
   */
  modulo(pressure) {
    return new Pressure(super.internalModulo(this.value, pressure.BaseValue));
  }
  /**
   * Pow the given Pressure with the current Pressure.
   * @param pressure The other Pressure.
   * @returns A new Pressure instance with the results.
   */
  pow(pressure) {
    return new Pressure(super.internalPow(this.value, pressure.BaseValue));
  }
}
exports.Pressure = Pressure;