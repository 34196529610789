"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MolarMass = exports.MolarMassUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MolarMassUnits enumeration */
var MolarMassUnits;
(function (MolarMassUnits) {
  /** */
  MolarMassUnits["GramsPerMole"] = "GramPerMole";
  /** */
  MolarMassUnits["KilogramsPerKilomole"] = "KilogramPerKilomole";
  /** */
  MolarMassUnits["PoundsPerMole"] = "PoundPerMole";
  /** */
  MolarMassUnits["NanogramsPerMole"] = "NanogramPerMole";
  /** */
  MolarMassUnits["MicrogramsPerMole"] = "MicrogramPerMole";
  /** */
  MolarMassUnits["MilligramsPerMole"] = "MilligramPerMole";
  /** */
  MolarMassUnits["CentigramsPerMole"] = "CentigramPerMole";
  /** */
  MolarMassUnits["DecigramsPerMole"] = "DecigramPerMole";
  /** */
  MolarMassUnits["DecagramsPerMole"] = "DecagramPerMole";
  /** */
  MolarMassUnits["HectogramsPerMole"] = "HectogramPerMole";
  /** */
  MolarMassUnits["KilogramsPerMole"] = "KilogramPerMole";
  /** */
  MolarMassUnits["KilopoundsPerMole"] = "KilopoundPerMole";
  /** */
  MolarMassUnits["MegapoundsPerMole"] = "MegapoundPerMole";
})(MolarMassUnits = exports.MolarMassUnits || (exports.MolarMassUnits = {}));
/** In chemistry, the molar mass M is a physical property defined as the mass of a given substance (chemical element or chemical compound) divided by the amount of substance. */
class MolarMass extends base_unit_1.BaseUnit {
  /**
   * Create a new MolarMass.
   * @param value The value.
   * @param fromUnit The ‘MolarMass’ unit to create from.
   * The default unit is KilogramsPerMole
   */
  constructor(value, fromUnit = MolarMassUnits.KilogramsPerMole) {
    super();
    this.gramspermoleLazy = null;
    this.kilogramsperkilomoleLazy = null;
    this.poundspermoleLazy = null;
    this.nanogramspermoleLazy = null;
    this.microgramspermoleLazy = null;
    this.milligramspermoleLazy = null;
    this.centigramspermoleLazy = null;
    this.decigramspermoleLazy = null;
    this.decagramspermoleLazy = null;
    this.hectogramspermoleLazy = null;
    this.kilogramspermoleLazy = null;
    this.kilopoundspermoleLazy = null;
    this.megapoundspermoleLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of MolarMass is KilogramsPerMole.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MolarMassUnits.KilogramsPerMole;
  }
  /** */
  get GramsPerMole() {
    if (this.gramspermoleLazy !== null) {
      return this.gramspermoleLazy;
    }
    return this.gramspermoleLazy = this.convertFromBase(MolarMassUnits.GramsPerMole);
  }
  /** */
  get KilogramsPerKilomole() {
    if (this.kilogramsperkilomoleLazy !== null) {
      return this.kilogramsperkilomoleLazy;
    }
    return this.kilogramsperkilomoleLazy = this.convertFromBase(MolarMassUnits.KilogramsPerKilomole);
  }
  /** */
  get PoundsPerMole() {
    if (this.poundspermoleLazy !== null) {
      return this.poundspermoleLazy;
    }
    return this.poundspermoleLazy = this.convertFromBase(MolarMassUnits.PoundsPerMole);
  }
  /** */
  get NanogramsPerMole() {
    if (this.nanogramspermoleLazy !== null) {
      return this.nanogramspermoleLazy;
    }
    return this.nanogramspermoleLazy = this.convertFromBase(MolarMassUnits.NanogramsPerMole);
  }
  /** */
  get MicrogramsPerMole() {
    if (this.microgramspermoleLazy !== null) {
      return this.microgramspermoleLazy;
    }
    return this.microgramspermoleLazy = this.convertFromBase(MolarMassUnits.MicrogramsPerMole);
  }
  /** */
  get MilligramsPerMole() {
    if (this.milligramspermoleLazy !== null) {
      return this.milligramspermoleLazy;
    }
    return this.milligramspermoleLazy = this.convertFromBase(MolarMassUnits.MilligramsPerMole);
  }
  /** */
  get CentigramsPerMole() {
    if (this.centigramspermoleLazy !== null) {
      return this.centigramspermoleLazy;
    }
    return this.centigramspermoleLazy = this.convertFromBase(MolarMassUnits.CentigramsPerMole);
  }
  /** */
  get DecigramsPerMole() {
    if (this.decigramspermoleLazy !== null) {
      return this.decigramspermoleLazy;
    }
    return this.decigramspermoleLazy = this.convertFromBase(MolarMassUnits.DecigramsPerMole);
  }
  /** */
  get DecagramsPerMole() {
    if (this.decagramspermoleLazy !== null) {
      return this.decagramspermoleLazy;
    }
    return this.decagramspermoleLazy = this.convertFromBase(MolarMassUnits.DecagramsPerMole);
  }
  /** */
  get HectogramsPerMole() {
    if (this.hectogramspermoleLazy !== null) {
      return this.hectogramspermoleLazy;
    }
    return this.hectogramspermoleLazy = this.convertFromBase(MolarMassUnits.HectogramsPerMole);
  }
  /** */
  get KilogramsPerMole() {
    if (this.kilogramspermoleLazy !== null) {
      return this.kilogramspermoleLazy;
    }
    return this.kilogramspermoleLazy = this.convertFromBase(MolarMassUnits.KilogramsPerMole);
  }
  /** */
  get KilopoundsPerMole() {
    if (this.kilopoundspermoleLazy !== null) {
      return this.kilopoundspermoleLazy;
    }
    return this.kilopoundspermoleLazy = this.convertFromBase(MolarMassUnits.KilopoundsPerMole);
  }
  /** */
  get MegapoundsPerMole() {
    if (this.megapoundspermoleLazy !== null) {
      return this.megapoundspermoleLazy;
    }
    return this.megapoundspermoleLazy = this.convertFromBase(MolarMassUnits.MegapoundsPerMole);
  }
  /**
   * Create a new MolarMass instance from a GramsPerMole
   *
   * @param value The unit as GramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromGramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.GramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a KilogramsPerKilomole
   *
   * @param value The unit as KilogramsPerKilomole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromKilogramsPerKilomole(value) {
    return new MolarMass(value, MolarMassUnits.KilogramsPerKilomole);
  }
  /**
   * Create a new MolarMass instance from a PoundsPerMole
   *
   * @param value The unit as PoundsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromPoundsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.PoundsPerMole);
  }
  /**
   * Create a new MolarMass instance from a NanogramsPerMole
   *
   * @param value The unit as NanogramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromNanogramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.NanogramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a MicrogramsPerMole
   *
   * @param value The unit as MicrogramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromMicrogramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.MicrogramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a MilligramsPerMole
   *
   * @param value The unit as MilligramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromMilligramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.MilligramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a CentigramsPerMole
   *
   * @param value The unit as CentigramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromCentigramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.CentigramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a DecigramsPerMole
   *
   * @param value The unit as DecigramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromDecigramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.DecigramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a DecagramsPerMole
   *
   * @param value The unit as DecagramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromDecagramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.DecagramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a HectogramsPerMole
   *
   * @param value The unit as HectogramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromHectogramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.HectogramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a KilogramsPerMole
   *
   * @param value The unit as KilogramsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromKilogramsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.KilogramsPerMole);
  }
  /**
   * Create a new MolarMass instance from a KilopoundsPerMole
   *
   * @param value The unit as KilopoundsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromKilopoundsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.KilopoundsPerMole);
  }
  /**
   * Create a new MolarMass instance from a MegapoundsPerMole
   *
   * @param value The unit as MegapoundsPerMole to create a new MolarMass from.
   * @returns The new MolarMass instance.
   */
  static FromMegapoundsPerMole(value) {
    return new MolarMass(value, MolarMassUnits.MegapoundsPerMole);
  }
  /**
   * Gets the base unit enumeration associated with MolarMass
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MolarMassUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MolarMassUnits.KilogramsPerMole;
  }
  /**
   * Create API DTO represent a MolarMass unit.
   * @param holdInUnit The specific MolarMass unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MolarMassUnits.KilogramsPerMole) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a MolarMass unit from an API DTO representation.
   * @param dtoMolarMass The MolarMass API DTO representation
   */
  static FromDto(dtoMolarMass) {
    return new MolarMass(dtoMolarMass.value, dtoMolarMass.unit);
  }
  /**
   * Convert MolarMass to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MolarMassUnits.GramsPerMole:
        return this.GramsPerMole;
      case MolarMassUnits.KilogramsPerKilomole:
        return this.KilogramsPerKilomole;
      case MolarMassUnits.PoundsPerMole:
        return this.PoundsPerMole;
      case MolarMassUnits.NanogramsPerMole:
        return this.NanogramsPerMole;
      case MolarMassUnits.MicrogramsPerMole:
        return this.MicrogramsPerMole;
      case MolarMassUnits.MilligramsPerMole:
        return this.MilligramsPerMole;
      case MolarMassUnits.CentigramsPerMole:
        return this.CentigramsPerMole;
      case MolarMassUnits.DecigramsPerMole:
        return this.DecigramsPerMole;
      case MolarMassUnits.DecagramsPerMole:
        return this.DecagramsPerMole;
      case MolarMassUnits.HectogramsPerMole:
        return this.HectogramsPerMole;
      case MolarMassUnits.KilogramsPerMole:
        return this.KilogramsPerMole;
      case MolarMassUnits.KilopoundsPerMole:
        return this.KilopoundsPerMole;
      case MolarMassUnits.MegapoundsPerMole:
        return this.MegapoundsPerMole;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MolarMassUnits.GramsPerMole:
        return super.internalMultiply(this.value, 1e3);
      case MolarMassUnits.KilogramsPerKilomole:
        return super.internalMultiply(this.value, 1e3);
      case MolarMassUnits.PoundsPerMole:
        return super.internalDivide(this.value, 0.45359237);
      case MolarMassUnits.NanogramsPerMole:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1e-9);
        }
      case MolarMassUnits.MicrogramsPerMole:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.000001);
        }
      case MolarMassUnits.MilligramsPerMole:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.001);
        }
      case MolarMassUnits.CentigramsPerMole:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.01);
        }
      case MolarMassUnits.DecigramsPerMole:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.1);
        }
      case MolarMassUnits.DecagramsPerMole:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 10);
        }
      case MolarMassUnits.HectogramsPerMole:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 100);
        }
      case MolarMassUnits.KilogramsPerMole:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1000);
        }
      case MolarMassUnits.KilopoundsPerMole:
        {
          const v3 = super.internalDivide(this.value, 0.45359237);
          return super.internalDivide(v3, 1000);
        }
      case MolarMassUnits.MegapoundsPerMole:
        {
          const v3 = super.internalDivide(this.value, 0.45359237);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MolarMassUnits.GramsPerMole:
        return this.value * 1e3;
      case MolarMassUnits.KilogramsPerKilomole:
        return this.value * 1e3;
      case MolarMassUnits.PoundsPerMole:
        return this.value / 0.45359237;
      case MolarMassUnits.NanogramsPerMole:
        return this.value * 1e3 / 1e-9;
      case MolarMassUnits.MicrogramsPerMole:
        return this.value * 1e3 / 0.000001;
      case MolarMassUnits.MilligramsPerMole:
        return this.value * 1e3 / 0.001;
      case MolarMassUnits.CentigramsPerMole:
        return this.value * 1e3 / 0.01;
      case MolarMassUnits.DecigramsPerMole:
        return this.value * 1e3 / 0.1;
      case MolarMassUnits.DecagramsPerMole:
        return this.value * 1e3 / 10;
      case MolarMassUnits.HectogramsPerMole:
        return this.value * 1e3 / 100;
      case MolarMassUnits.KilogramsPerMole:
        return this.value * 1e3 / 1000;
      case MolarMassUnits.KilopoundsPerMole:
        return this.value / 0.45359237 / 1000;
      case MolarMassUnits.MegapoundsPerMole:
        return this.value / 0.45359237 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MolarMassUnits.GramsPerMole:
        return super.internalDivide(value, 1e3);
      case MolarMassUnits.KilogramsPerKilomole:
        return super.internalDivide(value, 1e3);
      case MolarMassUnits.PoundsPerMole:
        return super.internalMultiply(value, 0.45359237);
      case MolarMassUnits.NanogramsPerMole:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1e-9);
        }
      case MolarMassUnits.MicrogramsPerMole:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.000001);
        }
      case MolarMassUnits.MilligramsPerMole:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.001);
        }
      case MolarMassUnits.CentigramsPerMole:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.01);
        }
      case MolarMassUnits.DecigramsPerMole:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.1);
        }
      case MolarMassUnits.DecagramsPerMole:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 10);
        }
      case MolarMassUnits.HectogramsPerMole:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 100);
        }
      case MolarMassUnits.KilogramsPerMole:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1000);
        }
      case MolarMassUnits.KilopoundsPerMole:
        {
          const v3 = super.internalMultiply(value, 0.45359237);
          return super.internalMultiply(v3, 1000);
        }
      case MolarMassUnits.MegapoundsPerMole:
        {
          const v3 = super.internalMultiply(value, 0.45359237);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MolarMassUnits.GramsPerMole:
        return value / 1e3;
      case MolarMassUnits.KilogramsPerKilomole:
        return value / 1e3;
      case MolarMassUnits.PoundsPerMole:
        return value * 0.45359237;
      case MolarMassUnits.NanogramsPerMole:
        return value / 1e3 * 1e-9;
      case MolarMassUnits.MicrogramsPerMole:
        return value / 1e3 * 0.000001;
      case MolarMassUnits.MilligramsPerMole:
        return value / 1e3 * 0.001;
      case MolarMassUnits.CentigramsPerMole:
        return value / 1e3 * 0.01;
      case MolarMassUnits.DecigramsPerMole:
        return value / 1e3 * 0.1;
      case MolarMassUnits.DecagramsPerMole:
        return value / 1e3 * 10;
      case MolarMassUnits.HectogramsPerMole:
        return value / 1e3 * 100;
      case MolarMassUnits.KilogramsPerMole:
        return value / 1e3 * 1000;
      case MolarMassUnits.KilopoundsPerMole:
        return value * 0.45359237 * 1000;
      case MolarMassUnits.MegapoundsPerMole:
        return value * 0.45359237 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the MolarMass to string.
   * Note! the default format for MolarMass is KilogramsPerMole.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the MolarMass.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the MolarMass.
   */
  toString(unit = MolarMassUnits.KilogramsPerMole, fractionalDigits) {
    switch (unit) {
      case MolarMassUnits.GramsPerMole:
        return super.truncateFractionDigits(this.GramsPerMole, fractionalDigits) + ` g/mol`;
      case MolarMassUnits.KilogramsPerKilomole:
        return super.truncateFractionDigits(this.KilogramsPerKilomole, fractionalDigits) + ` kg/kmol`;
      case MolarMassUnits.PoundsPerMole:
        return super.truncateFractionDigits(this.PoundsPerMole, fractionalDigits) + ` lb/mol`;
      case MolarMassUnits.NanogramsPerMole:
        return super.truncateFractionDigits(this.NanogramsPerMole, fractionalDigits) + ` ng/mol`;
      case MolarMassUnits.MicrogramsPerMole:
        return super.truncateFractionDigits(this.MicrogramsPerMole, fractionalDigits) + ` μg/mol`;
      case MolarMassUnits.MilligramsPerMole:
        return super.truncateFractionDigits(this.MilligramsPerMole, fractionalDigits) + ` mg/mol`;
      case MolarMassUnits.CentigramsPerMole:
        return super.truncateFractionDigits(this.CentigramsPerMole, fractionalDigits) + ` cg/mol`;
      case MolarMassUnits.DecigramsPerMole:
        return super.truncateFractionDigits(this.DecigramsPerMole, fractionalDigits) + ` dg/mol`;
      case MolarMassUnits.DecagramsPerMole:
        return super.truncateFractionDigits(this.DecagramsPerMole, fractionalDigits) + ` dag/mol`;
      case MolarMassUnits.HectogramsPerMole:
        return super.truncateFractionDigits(this.HectogramsPerMole, fractionalDigits) + ` hg/mol`;
      case MolarMassUnits.KilogramsPerMole:
        return super.truncateFractionDigits(this.KilogramsPerMole, fractionalDigits) + ` kg/mol`;
      case MolarMassUnits.KilopoundsPerMole:
        return super.truncateFractionDigits(this.KilopoundsPerMole, fractionalDigits) + ` klb/mol`;
      case MolarMassUnits.MegapoundsPerMole:
        return super.truncateFractionDigits(this.MegapoundsPerMole, fractionalDigits) + ` Mlb/mol`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get MolarMass unit abbreviation.
   * Note! the default abbreviation for MolarMass is KilogramsPerMole.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the MolarMass.
   * @returns The abbreviation string of MolarMass.
   */
  getUnitAbbreviation(unitAbbreviation = MolarMassUnits.KilogramsPerMole) {
    switch (unitAbbreviation) {
      case MolarMassUnits.GramsPerMole:
        return `g/mol`;
      case MolarMassUnits.KilogramsPerKilomole:
        return `kg/kmol`;
      case MolarMassUnits.PoundsPerMole:
        return `lb/mol`;
      case MolarMassUnits.NanogramsPerMole:
        return `ng/mol`;
      case MolarMassUnits.MicrogramsPerMole:
        return `μg/mol`;
      case MolarMassUnits.MilligramsPerMole:
        return `mg/mol`;
      case MolarMassUnits.CentigramsPerMole:
        return `cg/mol`;
      case MolarMassUnits.DecigramsPerMole:
        return `dg/mol`;
      case MolarMassUnits.DecagramsPerMole:
        return `dag/mol`;
      case MolarMassUnits.HectogramsPerMole:
        return `hg/mol`;
      case MolarMassUnits.KilogramsPerMole:
        return `kg/mol`;
      case MolarMassUnits.KilopoundsPerMole:
        return `klb/mol`;
      case MolarMassUnits.MegapoundsPerMole:
        return `Mlb/mol`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given MolarMass are equals to the current MolarMass.
   * @param molarMass The other MolarMass.
   * @returns True if the given MolarMass are equal to the current MolarMass.
   */
  equals(molarMass) {
    return super.internalEquals(this.value, molarMass.BaseValue);
  }
  /**
   * Compare the given MolarMass against the current MolarMass.
   * @param molarMass The other MolarMass.
   * @returns 0 if they are equal, -1 if the current MolarMass is less then other, 1 if the current MolarMass is greater then other.
   */
  compareTo(molarMass) {
    return super.internalCompareTo(this.value, molarMass.BaseValue);
  }
  /**
   * Add the given MolarMass with the current MolarMass.
   * @param molarMass The other MolarMass.
   * @returns A new MolarMass instance with the results.
   */
  add(molarMass) {
    return new MolarMass(super.internalAdd(this.value, molarMass.BaseValue));
  }
  /**
   * Subtract the given MolarMass with the current MolarMass.
   * @param molarMass The other MolarMass.
   * @returns A new MolarMass instance with the results.
   */
  subtract(molarMass) {
    return new MolarMass(super.internalSubtract(this.value, molarMass.BaseValue));
  }
  /**
   * Multiply the given MolarMass with the current MolarMass.
   * @param molarMass The other MolarMass.
   * @returns A new MolarMass instance with the results.
   */
  multiply(molarMass) {
    return new MolarMass(super.internalMultiply(this.value, molarMass.BaseValue));
  }
  /**
   * Divide the given MolarMass with the current MolarMass.
   * @param molarMass The other MolarMass.
   * @returns A new MolarMass instance with the results.
   */
  divide(molarMass) {
    return new MolarMass(super.internalDivide(this.value, molarMass.BaseValue));
  }
  /**
   * Modulo the given MolarMass with the current MolarMass.
   * @param molarMass The other MolarMass.
   * @returns A new MolarMass instance with the results.
   */
  modulo(molarMass) {
    return new MolarMass(super.internalModulo(this.value, molarMass.BaseValue));
  }
  /**
   * Pow the given MolarMass with the current MolarMass.
   * @param molarMass The other MolarMass.
   * @returns A new MolarMass instance with the results.
   */
  pow(molarMass) {
    return new MolarMass(super.internalPow(this.value, molarMass.BaseValue));
  }
}
exports.MolarMass = MolarMass;