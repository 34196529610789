import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CustomMessageService } from './customMessage.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private httpService: HttpClient, private customMessageService: CustomMessageService) {

    }

    public getVersion(): Observable<any> {
        return this.httpService.get(`version.json?v=${new Date().getTime()}`);
    }

    public getUserInfo(): any {
        let userInfourl = "https://graph.microsoft.com/v1.0/me?$select=onPremisesSamAccountName,displayName,mail,country";
        return this.httpService.get(userInfourl);
    }

    responseHandler(response: any, showSuccesMessage: boolean): any {
        if (response.success && showSuccesMessage) {
            this.customMessageService.showMessage({ 'severity': 'success', summary: 'Success', detail: response.message });
        }
        else if (!response.success) {
            this.customMessageService.showMessage({ 'severity': 'error', summary: 'Failed', detail: response.message });
        }
        return response;
    }

    errorHandler(httpErrorResponse: HttpErrorResponse) {
        let errorTitle = httpErrorResponse.error.errors ? JSON.stringify(httpErrorResponse.error.title) : httpErrorResponse.statusText;
        let errorMessage = httpErrorResponse.error.errors ? JSON.stringify(httpErrorResponse.error.errors) : httpErrorResponse.message;
        this.customMessageService.showMessage({ 'severity': 'error', summary: errorTitle, detail: errorMessage });
        return throwError(() => new Error('The Error'));
    }
}