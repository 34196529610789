"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpecificWeight = exports.SpecificWeightUnits = void 0;
const base_unit_1 = require("../base-unit");
/** SpecificWeightUnits enumeration */
var SpecificWeightUnits;
(function (SpecificWeightUnits) {
  /** */
  SpecificWeightUnits["NewtonsPerCubicMillimeter"] = "NewtonPerCubicMillimeter";
  /** */
  SpecificWeightUnits["NewtonsPerCubicCentimeter"] = "NewtonPerCubicCentimeter";
  /** */
  SpecificWeightUnits["NewtonsPerCubicMeter"] = "NewtonPerCubicMeter";
  /** */
  SpecificWeightUnits["KilogramsForcePerCubicMillimeter"] = "KilogramForcePerCubicMillimeter";
  /** */
  SpecificWeightUnits["KilogramsForcePerCubicCentimeter"] = "KilogramForcePerCubicCentimeter";
  /** */
  SpecificWeightUnits["KilogramsForcePerCubicMeter"] = "KilogramForcePerCubicMeter";
  /** */
  SpecificWeightUnits["PoundsForcePerCubicInch"] = "PoundForcePerCubicInch";
  /** */
  SpecificWeightUnits["PoundsForcePerCubicFoot"] = "PoundForcePerCubicFoot";
  /** */
  SpecificWeightUnits["TonnesForcePerCubicMillimeter"] = "TonneForcePerCubicMillimeter";
  /** */
  SpecificWeightUnits["TonnesForcePerCubicCentimeter"] = "TonneForcePerCubicCentimeter";
  /** */
  SpecificWeightUnits["TonnesForcePerCubicMeter"] = "TonneForcePerCubicMeter";
  /** */
  SpecificWeightUnits["KilonewtonsPerCubicMillimeter"] = "KilonewtonPerCubicMillimeter";
  /** */
  SpecificWeightUnits["KilonewtonsPerCubicCentimeter"] = "KilonewtonPerCubicCentimeter";
  /** */
  SpecificWeightUnits["KilonewtonsPerCubicMeter"] = "KilonewtonPerCubicMeter";
  /** */
  SpecificWeightUnits["MeganewtonsPerCubicMeter"] = "MeganewtonPerCubicMeter";
  /** */
  SpecificWeightUnits["KilopoundsForcePerCubicInch"] = "KilopoundForcePerCubicInch";
  /** */
  SpecificWeightUnits["KilopoundsForcePerCubicFoot"] = "KilopoundForcePerCubicFoot";
})(SpecificWeightUnits = exports.SpecificWeightUnits || (exports.SpecificWeightUnits = {}));
/** The SpecificWeight, or more precisely, the volumetric weight density, of a substance is its weight per unit volume. */
class SpecificWeight extends base_unit_1.BaseUnit {
  /**
   * Create a new SpecificWeight.
   * @param value The value.
   * @param fromUnit The ‘SpecificWeight’ unit to create from.
   * The default unit is NewtonsPerCubicMeter
   */
  constructor(value, fromUnit = SpecificWeightUnits.NewtonsPerCubicMeter) {
    super();
    this.newtonspercubicmillimeterLazy = null;
    this.newtonspercubiccentimeterLazy = null;
    this.newtonspercubicmeterLazy = null;
    this.kilogramsforcepercubicmillimeterLazy = null;
    this.kilogramsforcepercubiccentimeterLazy = null;
    this.kilogramsforcepercubicmeterLazy = null;
    this.poundsforcepercubicinchLazy = null;
    this.poundsforcepercubicfootLazy = null;
    this.tonnesforcepercubicmillimeterLazy = null;
    this.tonnesforcepercubiccentimeterLazy = null;
    this.tonnesforcepercubicmeterLazy = null;
    this.kilonewtonspercubicmillimeterLazy = null;
    this.kilonewtonspercubiccentimeterLazy = null;
    this.kilonewtonspercubicmeterLazy = null;
    this.meganewtonspercubicmeterLazy = null;
    this.kilopoundsforcepercubicinchLazy = null;
    this.kilopoundsforcepercubicfootLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of SpecificWeight is NewtonsPerCubicMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return SpecificWeightUnits.NewtonsPerCubicMeter;
  }
  /** */
  get NewtonsPerCubicMillimeter() {
    if (this.newtonspercubicmillimeterLazy !== null) {
      return this.newtonspercubicmillimeterLazy;
    }
    return this.newtonspercubicmillimeterLazy = this.convertFromBase(SpecificWeightUnits.NewtonsPerCubicMillimeter);
  }
  /** */
  get NewtonsPerCubicCentimeter() {
    if (this.newtonspercubiccentimeterLazy !== null) {
      return this.newtonspercubiccentimeterLazy;
    }
    return this.newtonspercubiccentimeterLazy = this.convertFromBase(SpecificWeightUnits.NewtonsPerCubicCentimeter);
  }
  /** */
  get NewtonsPerCubicMeter() {
    if (this.newtonspercubicmeterLazy !== null) {
      return this.newtonspercubicmeterLazy;
    }
    return this.newtonspercubicmeterLazy = this.convertFromBase(SpecificWeightUnits.NewtonsPerCubicMeter);
  }
  /** */
  get KilogramsForcePerCubicMillimeter() {
    if (this.kilogramsforcepercubicmillimeterLazy !== null) {
      return this.kilogramsforcepercubicmillimeterLazy;
    }
    return this.kilogramsforcepercubicmillimeterLazy = this.convertFromBase(SpecificWeightUnits.KilogramsForcePerCubicMillimeter);
  }
  /** */
  get KilogramsForcePerCubicCentimeter() {
    if (this.kilogramsforcepercubiccentimeterLazy !== null) {
      return this.kilogramsforcepercubiccentimeterLazy;
    }
    return this.kilogramsforcepercubiccentimeterLazy = this.convertFromBase(SpecificWeightUnits.KilogramsForcePerCubicCentimeter);
  }
  /** */
  get KilogramsForcePerCubicMeter() {
    if (this.kilogramsforcepercubicmeterLazy !== null) {
      return this.kilogramsforcepercubicmeterLazy;
    }
    return this.kilogramsforcepercubicmeterLazy = this.convertFromBase(SpecificWeightUnits.KilogramsForcePerCubicMeter);
  }
  /** */
  get PoundsForcePerCubicInch() {
    if (this.poundsforcepercubicinchLazy !== null) {
      return this.poundsforcepercubicinchLazy;
    }
    return this.poundsforcepercubicinchLazy = this.convertFromBase(SpecificWeightUnits.PoundsForcePerCubicInch);
  }
  /** */
  get PoundsForcePerCubicFoot() {
    if (this.poundsforcepercubicfootLazy !== null) {
      return this.poundsforcepercubicfootLazy;
    }
    return this.poundsforcepercubicfootLazy = this.convertFromBase(SpecificWeightUnits.PoundsForcePerCubicFoot);
  }
  /** */
  get TonnesForcePerCubicMillimeter() {
    if (this.tonnesforcepercubicmillimeterLazy !== null) {
      return this.tonnesforcepercubicmillimeterLazy;
    }
    return this.tonnesforcepercubicmillimeterLazy = this.convertFromBase(SpecificWeightUnits.TonnesForcePerCubicMillimeter);
  }
  /** */
  get TonnesForcePerCubicCentimeter() {
    if (this.tonnesforcepercubiccentimeterLazy !== null) {
      return this.tonnesforcepercubiccentimeterLazy;
    }
    return this.tonnesforcepercubiccentimeterLazy = this.convertFromBase(SpecificWeightUnits.TonnesForcePerCubicCentimeter);
  }
  /** */
  get TonnesForcePerCubicMeter() {
    if (this.tonnesforcepercubicmeterLazy !== null) {
      return this.tonnesforcepercubicmeterLazy;
    }
    return this.tonnesforcepercubicmeterLazy = this.convertFromBase(SpecificWeightUnits.TonnesForcePerCubicMeter);
  }
  /** */
  get KilonewtonsPerCubicMillimeter() {
    if (this.kilonewtonspercubicmillimeterLazy !== null) {
      return this.kilonewtonspercubicmillimeterLazy;
    }
    return this.kilonewtonspercubicmillimeterLazy = this.convertFromBase(SpecificWeightUnits.KilonewtonsPerCubicMillimeter);
  }
  /** */
  get KilonewtonsPerCubicCentimeter() {
    if (this.kilonewtonspercubiccentimeterLazy !== null) {
      return this.kilonewtonspercubiccentimeterLazy;
    }
    return this.kilonewtonspercubiccentimeterLazy = this.convertFromBase(SpecificWeightUnits.KilonewtonsPerCubicCentimeter);
  }
  /** */
  get KilonewtonsPerCubicMeter() {
    if (this.kilonewtonspercubicmeterLazy !== null) {
      return this.kilonewtonspercubicmeterLazy;
    }
    return this.kilonewtonspercubicmeterLazy = this.convertFromBase(SpecificWeightUnits.KilonewtonsPerCubicMeter);
  }
  /** */
  get MeganewtonsPerCubicMeter() {
    if (this.meganewtonspercubicmeterLazy !== null) {
      return this.meganewtonspercubicmeterLazy;
    }
    return this.meganewtonspercubicmeterLazy = this.convertFromBase(SpecificWeightUnits.MeganewtonsPerCubicMeter);
  }
  /** */
  get KilopoundsForcePerCubicInch() {
    if (this.kilopoundsforcepercubicinchLazy !== null) {
      return this.kilopoundsforcepercubicinchLazy;
    }
    return this.kilopoundsforcepercubicinchLazy = this.convertFromBase(SpecificWeightUnits.KilopoundsForcePerCubicInch);
  }
  /** */
  get KilopoundsForcePerCubicFoot() {
    if (this.kilopoundsforcepercubicfootLazy !== null) {
      return this.kilopoundsforcepercubicfootLazy;
    }
    return this.kilopoundsforcepercubicfootLazy = this.convertFromBase(SpecificWeightUnits.KilopoundsForcePerCubicFoot);
  }
  /**
   * Create a new SpecificWeight instance from a NewtonsPerCubicMillimeter
   *
   * @param value The unit as NewtonsPerCubicMillimeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromNewtonsPerCubicMillimeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.NewtonsPerCubicMillimeter);
  }
  /**
   * Create a new SpecificWeight instance from a NewtonsPerCubicCentimeter
   *
   * @param value The unit as NewtonsPerCubicCentimeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromNewtonsPerCubicCentimeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.NewtonsPerCubicCentimeter);
  }
  /**
   * Create a new SpecificWeight instance from a NewtonsPerCubicMeter
   *
   * @param value The unit as NewtonsPerCubicMeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromNewtonsPerCubicMeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.NewtonsPerCubicMeter);
  }
  /**
   * Create a new SpecificWeight instance from a KilogramsForcePerCubicMillimeter
   *
   * @param value The unit as KilogramsForcePerCubicMillimeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromKilogramsForcePerCubicMillimeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.KilogramsForcePerCubicMillimeter);
  }
  /**
   * Create a new SpecificWeight instance from a KilogramsForcePerCubicCentimeter
   *
   * @param value The unit as KilogramsForcePerCubicCentimeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromKilogramsForcePerCubicCentimeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.KilogramsForcePerCubicCentimeter);
  }
  /**
   * Create a new SpecificWeight instance from a KilogramsForcePerCubicMeter
   *
   * @param value The unit as KilogramsForcePerCubicMeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromKilogramsForcePerCubicMeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.KilogramsForcePerCubicMeter);
  }
  /**
   * Create a new SpecificWeight instance from a PoundsForcePerCubicInch
   *
   * @param value The unit as PoundsForcePerCubicInch to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromPoundsForcePerCubicInch(value) {
    return new SpecificWeight(value, SpecificWeightUnits.PoundsForcePerCubicInch);
  }
  /**
   * Create a new SpecificWeight instance from a PoundsForcePerCubicFoot
   *
   * @param value The unit as PoundsForcePerCubicFoot to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromPoundsForcePerCubicFoot(value) {
    return new SpecificWeight(value, SpecificWeightUnits.PoundsForcePerCubicFoot);
  }
  /**
   * Create a new SpecificWeight instance from a TonnesForcePerCubicMillimeter
   *
   * @param value The unit as TonnesForcePerCubicMillimeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromTonnesForcePerCubicMillimeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.TonnesForcePerCubicMillimeter);
  }
  /**
   * Create a new SpecificWeight instance from a TonnesForcePerCubicCentimeter
   *
   * @param value The unit as TonnesForcePerCubicCentimeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromTonnesForcePerCubicCentimeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.TonnesForcePerCubicCentimeter);
  }
  /**
   * Create a new SpecificWeight instance from a TonnesForcePerCubicMeter
   *
   * @param value The unit as TonnesForcePerCubicMeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromTonnesForcePerCubicMeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.TonnesForcePerCubicMeter);
  }
  /**
   * Create a new SpecificWeight instance from a KilonewtonsPerCubicMillimeter
   *
   * @param value The unit as KilonewtonsPerCubicMillimeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromKilonewtonsPerCubicMillimeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.KilonewtonsPerCubicMillimeter);
  }
  /**
   * Create a new SpecificWeight instance from a KilonewtonsPerCubicCentimeter
   *
   * @param value The unit as KilonewtonsPerCubicCentimeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromKilonewtonsPerCubicCentimeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.KilonewtonsPerCubicCentimeter);
  }
  /**
   * Create a new SpecificWeight instance from a KilonewtonsPerCubicMeter
   *
   * @param value The unit as KilonewtonsPerCubicMeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromKilonewtonsPerCubicMeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.KilonewtonsPerCubicMeter);
  }
  /**
   * Create a new SpecificWeight instance from a MeganewtonsPerCubicMeter
   *
   * @param value The unit as MeganewtonsPerCubicMeter to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromMeganewtonsPerCubicMeter(value) {
    return new SpecificWeight(value, SpecificWeightUnits.MeganewtonsPerCubicMeter);
  }
  /**
   * Create a new SpecificWeight instance from a KilopoundsForcePerCubicInch
   *
   * @param value The unit as KilopoundsForcePerCubicInch to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromKilopoundsForcePerCubicInch(value) {
    return new SpecificWeight(value, SpecificWeightUnits.KilopoundsForcePerCubicInch);
  }
  /**
   * Create a new SpecificWeight instance from a KilopoundsForcePerCubicFoot
   *
   * @param value The unit as KilopoundsForcePerCubicFoot to create a new SpecificWeight from.
   * @returns The new SpecificWeight instance.
   */
  static FromKilopoundsForcePerCubicFoot(value) {
    return new SpecificWeight(value, SpecificWeightUnits.KilopoundsForcePerCubicFoot);
  }
  /**
   * Gets the base unit enumeration associated with SpecificWeight
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return SpecificWeightUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return SpecificWeightUnits.NewtonsPerCubicMeter;
  }
  /**
   * Create API DTO represent a SpecificWeight unit.
   * @param holdInUnit The specific SpecificWeight unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = SpecificWeightUnits.NewtonsPerCubicMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a SpecificWeight unit from an API DTO representation.
   * @param dtoSpecificWeight The SpecificWeight API DTO representation
   */
  static FromDto(dtoSpecificWeight) {
    return new SpecificWeight(dtoSpecificWeight.value, dtoSpecificWeight.unit);
  }
  /**
   * Convert SpecificWeight to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case SpecificWeightUnits.NewtonsPerCubicMillimeter:
        return this.NewtonsPerCubicMillimeter;
      case SpecificWeightUnits.NewtonsPerCubicCentimeter:
        return this.NewtonsPerCubicCentimeter;
      case SpecificWeightUnits.NewtonsPerCubicMeter:
        return this.NewtonsPerCubicMeter;
      case SpecificWeightUnits.KilogramsForcePerCubicMillimeter:
        return this.KilogramsForcePerCubicMillimeter;
      case SpecificWeightUnits.KilogramsForcePerCubicCentimeter:
        return this.KilogramsForcePerCubicCentimeter;
      case SpecificWeightUnits.KilogramsForcePerCubicMeter:
        return this.KilogramsForcePerCubicMeter;
      case SpecificWeightUnits.PoundsForcePerCubicInch:
        return this.PoundsForcePerCubicInch;
      case SpecificWeightUnits.PoundsForcePerCubicFoot:
        return this.PoundsForcePerCubicFoot;
      case SpecificWeightUnits.TonnesForcePerCubicMillimeter:
        return this.TonnesForcePerCubicMillimeter;
      case SpecificWeightUnits.TonnesForcePerCubicCentimeter:
        return this.TonnesForcePerCubicCentimeter;
      case SpecificWeightUnits.TonnesForcePerCubicMeter:
        return this.TonnesForcePerCubicMeter;
      case SpecificWeightUnits.KilonewtonsPerCubicMillimeter:
        return this.KilonewtonsPerCubicMillimeter;
      case SpecificWeightUnits.KilonewtonsPerCubicCentimeter:
        return this.KilonewtonsPerCubicCentimeter;
      case SpecificWeightUnits.KilonewtonsPerCubicMeter:
        return this.KilonewtonsPerCubicMeter;
      case SpecificWeightUnits.MeganewtonsPerCubicMeter:
        return this.MeganewtonsPerCubicMeter;
      case SpecificWeightUnits.KilopoundsForcePerCubicInch:
        return this.KilopoundsForcePerCubicInch;
      case SpecificWeightUnits.KilopoundsForcePerCubicFoot:
        return this.KilopoundsForcePerCubicFoot;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case SpecificWeightUnits.NewtonsPerCubicMillimeter:
        return super.internalMultiply(this.value, 0.000000001);
      case SpecificWeightUnits.NewtonsPerCubicCentimeter:
        return super.internalMultiply(this.value, 0.000001);
      case SpecificWeightUnits.NewtonsPerCubicMeter:
        return this.value;
      case SpecificWeightUnits.KilogramsForcePerCubicMillimeter:
        return super.internalDivide(this.value, 9.80665e9);
      case SpecificWeightUnits.KilogramsForcePerCubicCentimeter:
        return super.internalDivide(this.value, 9.80665e6);
      case SpecificWeightUnits.KilogramsForcePerCubicMeter:
        return super.internalDivide(this.value, 9.80665);
      case SpecificWeightUnits.PoundsForcePerCubicInch:
        return super.internalDivide(this.value, 2.714471375263134e5);
      case SpecificWeightUnits.PoundsForcePerCubicFoot:
        return super.internalDivide(this.value, 1.570874638462462e2);
      case SpecificWeightUnits.TonnesForcePerCubicMillimeter:
        return super.internalDivide(this.value, 9.80665e12);
      case SpecificWeightUnits.TonnesForcePerCubicCentimeter:
        return super.internalDivide(this.value, 9.80665e9);
      case SpecificWeightUnits.TonnesForcePerCubicMeter:
        return super.internalDivide(this.value, 9.80665e3);
      case SpecificWeightUnits.KilonewtonsPerCubicMillimeter:
        {
          const v3 = super.internalMultiply(this.value, 0.000000001);
          return super.internalDivide(v3, 1000);
        }
      case SpecificWeightUnits.KilonewtonsPerCubicCentimeter:
        {
          const v3 = super.internalMultiply(this.value, 0.000001);
          return super.internalDivide(v3, 1000);
        }
      case SpecificWeightUnits.KilonewtonsPerCubicMeter:
        return super.internalDivide(this.value, 1000);
      case SpecificWeightUnits.MeganewtonsPerCubicMeter:
        return super.internalDivide(this.value, 1000000);
      case SpecificWeightUnits.KilopoundsForcePerCubicInch:
        {
          const v3 = super.internalDivide(this.value, 2.714471375263134e5);
          return super.internalDivide(v3, 1000);
        }
      case SpecificWeightUnits.KilopoundsForcePerCubicFoot:
        {
          const v3 = super.internalDivide(this.value, 1.570874638462462e2);
          return super.internalDivide(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case SpecificWeightUnits.NewtonsPerCubicMillimeter:
        return this.value * 0.000000001;
      case SpecificWeightUnits.NewtonsPerCubicCentimeter:
        return this.value * 0.000001;
      case SpecificWeightUnits.NewtonsPerCubicMeter:
        return this.value;
      case SpecificWeightUnits.KilogramsForcePerCubicMillimeter:
        return this.value / 9.80665e9;
      case SpecificWeightUnits.KilogramsForcePerCubicCentimeter:
        return this.value / 9.80665e6;
      case SpecificWeightUnits.KilogramsForcePerCubicMeter:
        return this.value / 9.80665;
      case SpecificWeightUnits.PoundsForcePerCubicInch:
        return this.value / 2.714471375263134e5;
      case SpecificWeightUnits.PoundsForcePerCubicFoot:
        return this.value / 1.570874638462462e2;
      case SpecificWeightUnits.TonnesForcePerCubicMillimeter:
        return this.value / 9.80665e12;
      case SpecificWeightUnits.TonnesForcePerCubicCentimeter:
        return this.value / 9.80665e9;
      case SpecificWeightUnits.TonnesForcePerCubicMeter:
        return this.value / 9.80665e3;
      case SpecificWeightUnits.KilonewtonsPerCubicMillimeter:
        return this.value * 0.000000001 / 1000;
      case SpecificWeightUnits.KilonewtonsPerCubicCentimeter:
        return this.value * 0.000001 / 1000;
      case SpecificWeightUnits.KilonewtonsPerCubicMeter:
        return this.value / 1000;
      case SpecificWeightUnits.MeganewtonsPerCubicMeter:
        return this.value / 1000000;
      case SpecificWeightUnits.KilopoundsForcePerCubicInch:
        return this.value / 2.714471375263134e5 / 1000;
      case SpecificWeightUnits.KilopoundsForcePerCubicFoot:
        return this.value / 1.570874638462462e2 / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case SpecificWeightUnits.NewtonsPerCubicMillimeter:
        return super.internalMultiply(value, 1000000000);
      case SpecificWeightUnits.NewtonsPerCubicCentimeter:
        return super.internalMultiply(value, 1000000);
      case SpecificWeightUnits.NewtonsPerCubicMeter:
        return value;
      case SpecificWeightUnits.KilogramsForcePerCubicMillimeter:
        return super.internalMultiply(value, 9.80665e9);
      case SpecificWeightUnits.KilogramsForcePerCubicCentimeter:
        return super.internalMultiply(value, 9.80665e6);
      case SpecificWeightUnits.KilogramsForcePerCubicMeter:
        return super.internalMultiply(value, 9.80665);
      case SpecificWeightUnits.PoundsForcePerCubicInch:
        return super.internalMultiply(value, 2.714471375263134e5);
      case SpecificWeightUnits.PoundsForcePerCubicFoot:
        return super.internalMultiply(value, 1.570874638462462e2);
      case SpecificWeightUnits.TonnesForcePerCubicMillimeter:
        return super.internalMultiply(value, 9.80665e12);
      case SpecificWeightUnits.TonnesForcePerCubicCentimeter:
        return super.internalMultiply(value, 9.80665e9);
      case SpecificWeightUnits.TonnesForcePerCubicMeter:
        return super.internalMultiply(value, 9.80665e3);
      case SpecificWeightUnits.KilonewtonsPerCubicMillimeter:
        {
          const v3 = super.internalMultiply(value, 1000000000);
          return super.internalMultiply(v3, 1000);
        }
      case SpecificWeightUnits.KilonewtonsPerCubicCentimeter:
        {
          const v3 = super.internalMultiply(value, 1000000);
          return super.internalMultiply(v3, 1000);
        }
      case SpecificWeightUnits.KilonewtonsPerCubicMeter:
        return super.internalMultiply(value, 1000);
      case SpecificWeightUnits.MeganewtonsPerCubicMeter:
        return super.internalMultiply(value, 1000000);
      case SpecificWeightUnits.KilopoundsForcePerCubicInch:
        {
          const v3 = super.internalMultiply(value, 2.714471375263134e5);
          return super.internalMultiply(v3, 1000);
        }
      case SpecificWeightUnits.KilopoundsForcePerCubicFoot:
        {
          const v3 = super.internalMultiply(value, 1.570874638462462e2);
          return super.internalMultiply(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case SpecificWeightUnits.NewtonsPerCubicMillimeter:
        return value * 1000000000;
      case SpecificWeightUnits.NewtonsPerCubicCentimeter:
        return value * 1000000;
      case SpecificWeightUnits.NewtonsPerCubicMeter:
        return value;
      case SpecificWeightUnits.KilogramsForcePerCubicMillimeter:
        return value * 9.80665e9;
      case SpecificWeightUnits.KilogramsForcePerCubicCentimeter:
        return value * 9.80665e6;
      case SpecificWeightUnits.KilogramsForcePerCubicMeter:
        return value * 9.80665;
      case SpecificWeightUnits.PoundsForcePerCubicInch:
        return value * 2.714471375263134e5;
      case SpecificWeightUnits.PoundsForcePerCubicFoot:
        return value * 1.570874638462462e2;
      case SpecificWeightUnits.TonnesForcePerCubicMillimeter:
        return value * 9.80665e12;
      case SpecificWeightUnits.TonnesForcePerCubicCentimeter:
        return value * 9.80665e9;
      case SpecificWeightUnits.TonnesForcePerCubicMeter:
        return value * 9.80665e3;
      case SpecificWeightUnits.KilonewtonsPerCubicMillimeter:
        return value * 1000000000 * 1000;
      case SpecificWeightUnits.KilonewtonsPerCubicCentimeter:
        return value * 1000000 * 1000;
      case SpecificWeightUnits.KilonewtonsPerCubicMeter:
        return value * 1000;
      case SpecificWeightUnits.MeganewtonsPerCubicMeter:
        return value * 1000000;
      case SpecificWeightUnits.KilopoundsForcePerCubicInch:
        return value * 2.714471375263134e5 * 1000;
      case SpecificWeightUnits.KilopoundsForcePerCubicFoot:
        return value * 1.570874638462462e2 * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the SpecificWeight to string.
   * Note! the default format for SpecificWeight is NewtonsPerCubicMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the SpecificWeight.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the SpecificWeight.
   */
  toString(unit = SpecificWeightUnits.NewtonsPerCubicMeter, fractionalDigits) {
    switch (unit) {
      case SpecificWeightUnits.NewtonsPerCubicMillimeter:
        return super.truncateFractionDigits(this.NewtonsPerCubicMillimeter, fractionalDigits) + ` N/mm³`;
      case SpecificWeightUnits.NewtonsPerCubicCentimeter:
        return super.truncateFractionDigits(this.NewtonsPerCubicCentimeter, fractionalDigits) + ` N/cm³`;
      case SpecificWeightUnits.NewtonsPerCubicMeter:
        return super.truncateFractionDigits(this.NewtonsPerCubicMeter, fractionalDigits) + ` N/m³`;
      case SpecificWeightUnits.KilogramsForcePerCubicMillimeter:
        return super.truncateFractionDigits(this.KilogramsForcePerCubicMillimeter, fractionalDigits) + ` kgf/mm³`;
      case SpecificWeightUnits.KilogramsForcePerCubicCentimeter:
        return super.truncateFractionDigits(this.KilogramsForcePerCubicCentimeter, fractionalDigits) + ` kgf/cm³`;
      case SpecificWeightUnits.KilogramsForcePerCubicMeter:
        return super.truncateFractionDigits(this.KilogramsForcePerCubicMeter, fractionalDigits) + ` kgf/m³`;
      case SpecificWeightUnits.PoundsForcePerCubicInch:
        return super.truncateFractionDigits(this.PoundsForcePerCubicInch, fractionalDigits) + ` lbf/in³`;
      case SpecificWeightUnits.PoundsForcePerCubicFoot:
        return super.truncateFractionDigits(this.PoundsForcePerCubicFoot, fractionalDigits) + ` lbf/ft³`;
      case SpecificWeightUnits.TonnesForcePerCubicMillimeter:
        return super.truncateFractionDigits(this.TonnesForcePerCubicMillimeter, fractionalDigits) + ` tf/mm³`;
      case SpecificWeightUnits.TonnesForcePerCubicCentimeter:
        return super.truncateFractionDigits(this.TonnesForcePerCubicCentimeter, fractionalDigits) + ` tf/cm³`;
      case SpecificWeightUnits.TonnesForcePerCubicMeter:
        return super.truncateFractionDigits(this.TonnesForcePerCubicMeter, fractionalDigits) + ` tf/m³`;
      case SpecificWeightUnits.KilonewtonsPerCubicMillimeter:
        return super.truncateFractionDigits(this.KilonewtonsPerCubicMillimeter, fractionalDigits) + ` kN/mm³`;
      case SpecificWeightUnits.KilonewtonsPerCubicCentimeter:
        return super.truncateFractionDigits(this.KilonewtonsPerCubicCentimeter, fractionalDigits) + ` kN/cm³`;
      case SpecificWeightUnits.KilonewtonsPerCubicMeter:
        return super.truncateFractionDigits(this.KilonewtonsPerCubicMeter, fractionalDigits) + ` kN/m³`;
      case SpecificWeightUnits.MeganewtonsPerCubicMeter:
        return super.truncateFractionDigits(this.MeganewtonsPerCubicMeter, fractionalDigits) + ` MN/m³`;
      case SpecificWeightUnits.KilopoundsForcePerCubicInch:
        return super.truncateFractionDigits(this.KilopoundsForcePerCubicInch, fractionalDigits) + ` klbf/in³`;
      case SpecificWeightUnits.KilopoundsForcePerCubicFoot:
        return super.truncateFractionDigits(this.KilopoundsForcePerCubicFoot, fractionalDigits) + ` klbf/ft³`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get SpecificWeight unit abbreviation.
   * Note! the default abbreviation for SpecificWeight is NewtonsPerCubicMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the SpecificWeight.
   * @returns The abbreviation string of SpecificWeight.
   */
  getUnitAbbreviation(unitAbbreviation = SpecificWeightUnits.NewtonsPerCubicMeter) {
    switch (unitAbbreviation) {
      case SpecificWeightUnits.NewtonsPerCubicMillimeter:
        return `N/mm³`;
      case SpecificWeightUnits.NewtonsPerCubicCentimeter:
        return `N/cm³`;
      case SpecificWeightUnits.NewtonsPerCubicMeter:
        return `N/m³`;
      case SpecificWeightUnits.KilogramsForcePerCubicMillimeter:
        return `kgf/mm³`;
      case SpecificWeightUnits.KilogramsForcePerCubicCentimeter:
        return `kgf/cm³`;
      case SpecificWeightUnits.KilogramsForcePerCubicMeter:
        return `kgf/m³`;
      case SpecificWeightUnits.PoundsForcePerCubicInch:
        return `lbf/in³`;
      case SpecificWeightUnits.PoundsForcePerCubicFoot:
        return `lbf/ft³`;
      case SpecificWeightUnits.TonnesForcePerCubicMillimeter:
        return `tf/mm³`;
      case SpecificWeightUnits.TonnesForcePerCubicCentimeter:
        return `tf/cm³`;
      case SpecificWeightUnits.TonnesForcePerCubicMeter:
        return `tf/m³`;
      case SpecificWeightUnits.KilonewtonsPerCubicMillimeter:
        return `kN/mm³`;
      case SpecificWeightUnits.KilonewtonsPerCubicCentimeter:
        return `kN/cm³`;
      case SpecificWeightUnits.KilonewtonsPerCubicMeter:
        return `kN/m³`;
      case SpecificWeightUnits.MeganewtonsPerCubicMeter:
        return `MN/m³`;
      case SpecificWeightUnits.KilopoundsForcePerCubicInch:
        return `klbf/in³`;
      case SpecificWeightUnits.KilopoundsForcePerCubicFoot:
        return `klbf/ft³`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given SpecificWeight are equals to the current SpecificWeight.
   * @param specificWeight The other SpecificWeight.
   * @returns True if the given SpecificWeight are equal to the current SpecificWeight.
   */
  equals(specificWeight) {
    return super.internalEquals(this.value, specificWeight.BaseValue);
  }
  /**
   * Compare the given SpecificWeight against the current SpecificWeight.
   * @param specificWeight The other SpecificWeight.
   * @returns 0 if they are equal, -1 if the current SpecificWeight is less then other, 1 if the current SpecificWeight is greater then other.
   */
  compareTo(specificWeight) {
    return super.internalCompareTo(this.value, specificWeight.BaseValue);
  }
  /**
   * Add the given SpecificWeight with the current SpecificWeight.
   * @param specificWeight The other SpecificWeight.
   * @returns A new SpecificWeight instance with the results.
   */
  add(specificWeight) {
    return new SpecificWeight(super.internalAdd(this.value, specificWeight.BaseValue));
  }
  /**
   * Subtract the given SpecificWeight with the current SpecificWeight.
   * @param specificWeight The other SpecificWeight.
   * @returns A new SpecificWeight instance with the results.
   */
  subtract(specificWeight) {
    return new SpecificWeight(super.internalSubtract(this.value, specificWeight.BaseValue));
  }
  /**
   * Multiply the given SpecificWeight with the current SpecificWeight.
   * @param specificWeight The other SpecificWeight.
   * @returns A new SpecificWeight instance with the results.
   */
  multiply(specificWeight) {
    return new SpecificWeight(super.internalMultiply(this.value, specificWeight.BaseValue));
  }
  /**
   * Divide the given SpecificWeight with the current SpecificWeight.
   * @param specificWeight The other SpecificWeight.
   * @returns A new SpecificWeight instance with the results.
   */
  divide(specificWeight) {
    return new SpecificWeight(super.internalDivide(this.value, specificWeight.BaseValue));
  }
  /**
   * Modulo the given SpecificWeight with the current SpecificWeight.
   * @param specificWeight The other SpecificWeight.
   * @returns A new SpecificWeight instance with the results.
   */
  modulo(specificWeight) {
    return new SpecificWeight(super.internalModulo(this.value, specificWeight.BaseValue));
  }
  /**
   * Pow the given SpecificWeight with the current SpecificWeight.
   * @param specificWeight The other SpecificWeight.
   * @returns A new SpecificWeight instance with the results.
   */
  pow(specificWeight) {
    return new SpecificWeight(super.internalPow(this.value, specificWeight.BaseValue));
  }
}
exports.SpecificWeight = SpecificWeight;