"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Permittivity = exports.PermittivityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** PermittivityUnits enumeration */
var PermittivityUnits;
(function (PermittivityUnits) {
  /** */
  PermittivityUnits["FaradsPerMeter"] = "FaradPerMeter";
})(PermittivityUnits = exports.PermittivityUnits || (exports.PermittivityUnits = {}));
/** In electromagnetism, permittivity is the measure of resistance that is encountered when forming an electric field in a particular medium. */
class Permittivity extends base_unit_1.BaseUnit {
  /**
   * Create a new Permittivity.
   * @param value The value.
   * @param fromUnit The ‘Permittivity’ unit to create from.
   * The default unit is FaradsPerMeter
   */
  constructor(value, fromUnit = PermittivityUnits.FaradsPerMeter) {
    super();
    this.faradspermeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Permittivity is FaradsPerMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return PermittivityUnits.FaradsPerMeter;
  }
  /** */
  get FaradsPerMeter() {
    if (this.faradspermeterLazy !== null) {
      return this.faradspermeterLazy;
    }
    return this.faradspermeterLazy = this.convertFromBase(PermittivityUnits.FaradsPerMeter);
  }
  /**
   * Create a new Permittivity instance from a FaradsPerMeter
   *
   * @param value The unit as FaradsPerMeter to create a new Permittivity from.
   * @returns The new Permittivity instance.
   */
  static FromFaradsPerMeter(value) {
    return new Permittivity(value, PermittivityUnits.FaradsPerMeter);
  }
  /**
   * Gets the base unit enumeration associated with Permittivity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return PermittivityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return PermittivityUnits.FaradsPerMeter;
  }
  /**
   * Create API DTO represent a Permittivity unit.
   * @param holdInUnit The specific Permittivity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = PermittivityUnits.FaradsPerMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Permittivity unit from an API DTO representation.
   * @param dtoPermittivity The Permittivity API DTO representation
   */
  static FromDto(dtoPermittivity) {
    return new Permittivity(dtoPermittivity.value, dtoPermittivity.unit);
  }
  /**
   * Convert Permittivity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case PermittivityUnits.FaradsPerMeter:
        return this.FaradsPerMeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case PermittivityUnits.FaradsPerMeter:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case PermittivityUnits.FaradsPerMeter:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case PermittivityUnits.FaradsPerMeter:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case PermittivityUnits.FaradsPerMeter:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Permittivity to string.
   * Note! the default format for Permittivity is FaradsPerMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Permittivity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Permittivity.
   */
  toString(unit = PermittivityUnits.FaradsPerMeter, fractionalDigits) {
    switch (unit) {
      case PermittivityUnits.FaradsPerMeter:
        return super.truncateFractionDigits(this.FaradsPerMeter, fractionalDigits) + ` F/m`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Permittivity unit abbreviation.
   * Note! the default abbreviation for Permittivity is FaradsPerMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Permittivity.
   * @returns The abbreviation string of Permittivity.
   */
  getUnitAbbreviation(unitAbbreviation = PermittivityUnits.FaradsPerMeter) {
    switch (unitAbbreviation) {
      case PermittivityUnits.FaradsPerMeter:
        return `F/m`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Permittivity are equals to the current Permittivity.
   * @param permittivity The other Permittivity.
   * @returns True if the given Permittivity are equal to the current Permittivity.
   */
  equals(permittivity) {
    return super.internalEquals(this.value, permittivity.BaseValue);
  }
  /**
   * Compare the given Permittivity against the current Permittivity.
   * @param permittivity The other Permittivity.
   * @returns 0 if they are equal, -1 if the current Permittivity is less then other, 1 if the current Permittivity is greater then other.
   */
  compareTo(permittivity) {
    return super.internalCompareTo(this.value, permittivity.BaseValue);
  }
  /**
   * Add the given Permittivity with the current Permittivity.
   * @param permittivity The other Permittivity.
   * @returns A new Permittivity instance with the results.
   */
  add(permittivity) {
    return new Permittivity(super.internalAdd(this.value, permittivity.BaseValue));
  }
  /**
   * Subtract the given Permittivity with the current Permittivity.
   * @param permittivity The other Permittivity.
   * @returns A new Permittivity instance with the results.
   */
  subtract(permittivity) {
    return new Permittivity(super.internalSubtract(this.value, permittivity.BaseValue));
  }
  /**
   * Multiply the given Permittivity with the current Permittivity.
   * @param permittivity The other Permittivity.
   * @returns A new Permittivity instance with the results.
   */
  multiply(permittivity) {
    return new Permittivity(super.internalMultiply(this.value, permittivity.BaseValue));
  }
  /**
   * Divide the given Permittivity with the current Permittivity.
   * @param permittivity The other Permittivity.
   * @returns A new Permittivity instance with the results.
   */
  divide(permittivity) {
    return new Permittivity(super.internalDivide(this.value, permittivity.BaseValue));
  }
  /**
   * Modulo the given Permittivity with the current Permittivity.
   * @param permittivity The other Permittivity.
   * @returns A new Permittivity instance with the results.
   */
  modulo(permittivity) {
    return new Permittivity(super.internalModulo(this.value, permittivity.BaseValue));
  }
  /**
   * Pow the given Permittivity with the current Permittivity.
   * @param permittivity The other Permittivity.
   * @returns A new Permittivity instance with the results.
   */
  pow(permittivity) {
    return new Permittivity(super.internalPow(this.value, permittivity.BaseValue));
  }
}
exports.Permittivity = Permittivity;