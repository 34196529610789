"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Illuminance = exports.IlluminanceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** IlluminanceUnits enumeration */
var IlluminanceUnits;
(function (IlluminanceUnits) {
  /** */
  IlluminanceUnits["Lux"] = "Lux";
  /** */
  IlluminanceUnits["Millilux"] = "Millilux";
  /** */
  IlluminanceUnits["Kilolux"] = "Kilolux";
  /** */
  IlluminanceUnits["Megalux"] = "Megalux";
})(IlluminanceUnits = exports.IlluminanceUnits || (exports.IlluminanceUnits = {}));
/** In photometry, illuminance is the total luminous flux incident on a surface, per unit area. */
class Illuminance extends base_unit_1.BaseUnit {
  /**
   * Create a new Illuminance.
   * @param value The value.
   * @param fromUnit The ‘Illuminance’ unit to create from.
   * The default unit is Lux
   */
  constructor(value, fromUnit = IlluminanceUnits.Lux) {
    super();
    this.luxLazy = null;
    this.milliluxLazy = null;
    this.kiloluxLazy = null;
    this.megaluxLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Illuminance is Lux.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return IlluminanceUnits.Lux;
  }
  /** */
  get Lux() {
    if (this.luxLazy !== null) {
      return this.luxLazy;
    }
    return this.luxLazy = this.convertFromBase(IlluminanceUnits.Lux);
  }
  /** */
  get Millilux() {
    if (this.milliluxLazy !== null) {
      return this.milliluxLazy;
    }
    return this.milliluxLazy = this.convertFromBase(IlluminanceUnits.Millilux);
  }
  /** */
  get Kilolux() {
    if (this.kiloluxLazy !== null) {
      return this.kiloluxLazy;
    }
    return this.kiloluxLazy = this.convertFromBase(IlluminanceUnits.Kilolux);
  }
  /** */
  get Megalux() {
    if (this.megaluxLazy !== null) {
      return this.megaluxLazy;
    }
    return this.megaluxLazy = this.convertFromBase(IlluminanceUnits.Megalux);
  }
  /**
   * Create a new Illuminance instance from a Lux
   *
   * @param value The unit as Lux to create a new Illuminance from.
   * @returns The new Illuminance instance.
   */
  static FromLux(value) {
    return new Illuminance(value, IlluminanceUnits.Lux);
  }
  /**
   * Create a new Illuminance instance from a Millilux
   *
   * @param value The unit as Millilux to create a new Illuminance from.
   * @returns The new Illuminance instance.
   */
  static FromMillilux(value) {
    return new Illuminance(value, IlluminanceUnits.Millilux);
  }
  /**
   * Create a new Illuminance instance from a Kilolux
   *
   * @param value The unit as Kilolux to create a new Illuminance from.
   * @returns The new Illuminance instance.
   */
  static FromKilolux(value) {
    return new Illuminance(value, IlluminanceUnits.Kilolux);
  }
  /**
   * Create a new Illuminance instance from a Megalux
   *
   * @param value The unit as Megalux to create a new Illuminance from.
   * @returns The new Illuminance instance.
   */
  static FromMegalux(value) {
    return new Illuminance(value, IlluminanceUnits.Megalux);
  }
  /**
   * Gets the base unit enumeration associated with Illuminance
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return IlluminanceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return IlluminanceUnits.Lux;
  }
  /**
   * Create API DTO represent a Illuminance unit.
   * @param holdInUnit The specific Illuminance unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = IlluminanceUnits.Lux) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Illuminance unit from an API DTO representation.
   * @param dtoIlluminance The Illuminance API DTO representation
   */
  static FromDto(dtoIlluminance) {
    return new Illuminance(dtoIlluminance.value, dtoIlluminance.unit);
  }
  /**
   * Convert Illuminance to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case IlluminanceUnits.Lux:
        return this.Lux;
      case IlluminanceUnits.Millilux:
        return this.Millilux;
      case IlluminanceUnits.Kilolux:
        return this.Kilolux;
      case IlluminanceUnits.Megalux:
        return this.Megalux;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case IlluminanceUnits.Lux:
        return this.value;
      case IlluminanceUnits.Millilux:
        return super.internalDivide(this.value, 0.001);
      case IlluminanceUnits.Kilolux:
        return super.internalDivide(this.value, 1000);
      case IlluminanceUnits.Megalux:
        return super.internalDivide(this.value, 1000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case IlluminanceUnits.Lux:
        return this.value;
      case IlluminanceUnits.Millilux:
        return this.value / 0.001;
      case IlluminanceUnits.Kilolux:
        return this.value / 1000;
      case IlluminanceUnits.Megalux:
        return this.value / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case IlluminanceUnits.Lux:
        return value;
      case IlluminanceUnits.Millilux:
        return super.internalMultiply(value, 0.001);
      case IlluminanceUnits.Kilolux:
        return super.internalMultiply(value, 1000);
      case IlluminanceUnits.Megalux:
        return super.internalMultiply(value, 1000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case IlluminanceUnits.Lux:
        return value;
      case IlluminanceUnits.Millilux:
        return value * 0.001;
      case IlluminanceUnits.Kilolux:
        return value * 1000;
      case IlluminanceUnits.Megalux:
        return value * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Illuminance to string.
   * Note! the default format for Illuminance is Lux.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Illuminance.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Illuminance.
   */
  toString(unit = IlluminanceUnits.Lux, fractionalDigits) {
    switch (unit) {
      case IlluminanceUnits.Lux:
        return super.truncateFractionDigits(this.Lux, fractionalDigits) + ` lx`;
      case IlluminanceUnits.Millilux:
        return super.truncateFractionDigits(this.Millilux, fractionalDigits) + ` mlx`;
      case IlluminanceUnits.Kilolux:
        return super.truncateFractionDigits(this.Kilolux, fractionalDigits) + ` klx`;
      case IlluminanceUnits.Megalux:
        return super.truncateFractionDigits(this.Megalux, fractionalDigits) + ` Mlx`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Illuminance unit abbreviation.
   * Note! the default abbreviation for Illuminance is Lux.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Illuminance.
   * @returns The abbreviation string of Illuminance.
   */
  getUnitAbbreviation(unitAbbreviation = IlluminanceUnits.Lux) {
    switch (unitAbbreviation) {
      case IlluminanceUnits.Lux:
        return `lx`;
      case IlluminanceUnits.Millilux:
        return `mlx`;
      case IlluminanceUnits.Kilolux:
        return `klx`;
      case IlluminanceUnits.Megalux:
        return `Mlx`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Illuminance are equals to the current Illuminance.
   * @param illuminance The other Illuminance.
   * @returns True if the given Illuminance are equal to the current Illuminance.
   */
  equals(illuminance) {
    return super.internalEquals(this.value, illuminance.BaseValue);
  }
  /**
   * Compare the given Illuminance against the current Illuminance.
   * @param illuminance The other Illuminance.
   * @returns 0 if they are equal, -1 if the current Illuminance is less then other, 1 if the current Illuminance is greater then other.
   */
  compareTo(illuminance) {
    return super.internalCompareTo(this.value, illuminance.BaseValue);
  }
  /**
   * Add the given Illuminance with the current Illuminance.
   * @param illuminance The other Illuminance.
   * @returns A new Illuminance instance with the results.
   */
  add(illuminance) {
    return new Illuminance(super.internalAdd(this.value, illuminance.BaseValue));
  }
  /**
   * Subtract the given Illuminance with the current Illuminance.
   * @param illuminance The other Illuminance.
   * @returns A new Illuminance instance with the results.
   */
  subtract(illuminance) {
    return new Illuminance(super.internalSubtract(this.value, illuminance.BaseValue));
  }
  /**
   * Multiply the given Illuminance with the current Illuminance.
   * @param illuminance The other Illuminance.
   * @returns A new Illuminance instance with the results.
   */
  multiply(illuminance) {
    return new Illuminance(super.internalMultiply(this.value, illuminance.BaseValue));
  }
  /**
   * Divide the given Illuminance with the current Illuminance.
   * @param illuminance The other Illuminance.
   * @returns A new Illuminance instance with the results.
   */
  divide(illuminance) {
    return new Illuminance(super.internalDivide(this.value, illuminance.BaseValue));
  }
  /**
   * Modulo the given Illuminance with the current Illuminance.
   * @param illuminance The other Illuminance.
   * @returns A new Illuminance instance with the results.
   */
  modulo(illuminance) {
    return new Illuminance(super.internalModulo(this.value, illuminance.BaseValue));
  }
  /**
   * Pow the given Illuminance with the current Illuminance.
   * @param illuminance The other Illuminance.
   * @returns A new Illuminance instance with the results.
   */
  pow(illuminance) {
    return new Illuminance(super.internalPow(this.value, illuminance.BaseValue));
  }
}
exports.Illuminance = Illuminance;