export class AppConstants {
  public static get STiR_View(): string { return "343bfa87-d763-4ea0-abe5-b452efd9029e"; }
  public static get STiR_Admin(): string { return "efacfbaa-6930-42a5-a97b-45b7ad08db68"; }
  public static get STiR_User(): string { return "a2fab7a0-9cfb-4671-bedb-381be6ce400c"; }
  public static get TOKEN(): string { return "msal.idtoken"; }
  public static get AUTHORIZATION(): string { return "Authorization"; }
  public static get BEARER(): string { return "Bearer "; }
  public static get NAVHOME(): string { return './home'; }
  public static get NAVAUTHORIZATION(): string { return './authorization'; }
  public static get UIUSERROLE(): string { return "uiUserRole "; }
  public static get DATAFIELD(): string { return 'dataField'; }
  public static get SORTORDER(): string { return 'sortOrder'; }
  public static get DESC(): string { return 'desc'; }
  public static get ASC(): string { return 'asc'; }
  public static get FILEEXT(): string { return '.xlsx'; }
  public static get CONTENTTYPE(): string { return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
  public static get URL(): string { return "/"; }
  public static get Nav_Home(): string { return "/home"; }
  public static get DataTypes(): any[] { return [{ name: DataType.Stage }, { name: DataType.MetaData }]; }
  public static get MetaDataTypes(): any[] { return ["All", "GE", "SI"]; }
  public static get ImportFileHeaders(): string[] { return [ImportFileHeader.geId, ImportFileHeader.geName, ImportFileHeader.program, ImportFileHeader.year, ImportFileHeader.stage, ImportFileHeader.metaData, ImportFileHeader.customMetaData, ImportFileHeader.programComment]; }

  public static get USERPROPERTYFILTER(): string {
    return "$select=id,givenName,surname,displayName,mail,jobTitle,businessPhones,mobilePhone,displayName,streetAddress,city,postalCode,state,country,department,officeLocation,onPremisesSamAccountName,companyName"
  }

  public static get Loading(): string {
    return "Loading...";
  }

  public static get Saving(): string {
    return "Saving...";
  }

  public static get ErrorMessage(): string {
    return "Error, Please try again";
  }

  public static get Error(): string {
    return "Error"
  }

  public static get Warning(): string {
    return "Warning"
  }
  public static get Success(): string {
    return "Success"
  }
  public static get login_failed(): string {
    return "login-failed";
  }

  public static get STiR_USER(): string {
    return "STiR_USER";
  }
  public static get STiR_ADMIN(): string {
    return "STiR_ADMIN";
  }

  public static get STiR_Admin_Role(): string {
    return "STiR.Admin";
  }

  public static get STiR_User_Role(): string {
    return "STiR.User";
  }

  public static get Manage_Standards(): string {
    return "Manage Standards";
  }

  public static get Add_Update_Recipe(): string {
    return "Add or Update Recipe";
  }

  public static get Manage_Basic_code(): string {
    return "Manage Basic Code";
  }

  public static get Recipe_Details_Popup(): string {
    return "Recipe Details Popuo";
  }

  public static get Empty_Guid(): string {
    return "00000000-0000-0000-0000-000000000000"
  }

  public static get Ingredients(): string {
    return "Ingredients";
  }

  public static get PRISMSeedTreatment(): string {
    return "PRISMSeedTreatment";
  }

  public static get FindRecipe(): string {
    return "FindRecipe";
  }
  public static get Facts(): string {
    return "Facts";
  }

  public static get CountryLabelAs(): string {
    return "Country Label As";
  }

  public static get UnitedStates(): string {
    return "United States";
  }

  public static get UnitedKingdom(): string {
    return "United Kingdom";
  }

  public static get Active(): string {
    return "Active";
  }

  public static get Crop(): string {
    return "Crop";
  }

  public static get Recipe(): string {
    return "Recipe";
  }

  public static get Country(): string {
    return "Country";
  }

  public static get LabelAs(): string {
    return "LabelAs";
  }

  public static get FactsId(): string {
    return "FactsId";
  }

  public static get All(): string {
    return "All";
  }

  public static get ViewRecipeDetails(): string {
    return "View recipe details";
  }


  public static get ViewIngredientLabelDetails(): string {
    return "View Country Label As details for ingredient";
  }

  public static get Status(): string {
    return "Status";
  }
  //type
  public static get R3BatchCharacteristics(): string {
    return "R3 Batch Characteristics";
  }
  public static get RecipeType(): string {
    return "RecipeType";
  }
  public static get TreatingOrganization(): string {
    return "TreatingOrganization";
  }
  public static get IngredientType(): string {
    return "TreatmentType";
    //return "IngredientType";
  }

  public static get Formulant(): string {
    return "Formulant";
  }
  public static get Coformulant(): string {
    return "Coformulant";
  }

  public static get Research(): string {
    return "Research";
  }

  public static get GSO(): string {
    return "GSO";
  }

  public static get Experimental(): string {
    return "Experimental";
  }

  public static get Competitive(): string {
    return "Competitive";
  }

}

export class DataType {
  static Stage: string = "Stage";
  static MetaData: string = "Meta Data";
}

export class ImportFileHeader {
  static geId: string = "GE ID";
  static geName: string = "GE Name";
  static program: string = "Program";
  static year: string = "Year";
  static stage: string = "Stage";
  static metaData: string = "Meta Data";
  static customMetaData: string = "Custom Meta Data";
  static programComment: string = "Program Comment";
}

export class Metric {
  static Lbs: string = "Lbs";
  static Kg: string = "Kg";
}

export class Volume {
  static mL: string = "mL";
}