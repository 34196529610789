"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SolidAngle = exports.SolidAngleUnits = void 0;
const base_unit_1 = require("../base-unit");
/** SolidAngleUnits enumeration */
var SolidAngleUnits;
(function (SolidAngleUnits) {
  /** */
  SolidAngleUnits["Steradians"] = "Steradian";
})(SolidAngleUnits = exports.SolidAngleUnits || (exports.SolidAngleUnits = {}));
/** In geometry, a solid angle is the two-dimensional angle in three-dimensional space that an object subtends at a point. */
class SolidAngle extends base_unit_1.BaseUnit {
  /**
   * Create a new SolidAngle.
   * @param value The value.
   * @param fromUnit The ‘SolidAngle’ unit to create from.
   * The default unit is Steradians
   */
  constructor(value, fromUnit = SolidAngleUnits.Steradians) {
    super();
    this.steradiansLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of SolidAngle is Steradians.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return SolidAngleUnits.Steradians;
  }
  /** */
  get Steradians() {
    if (this.steradiansLazy !== null) {
      return this.steradiansLazy;
    }
    return this.steradiansLazy = this.convertFromBase(SolidAngleUnits.Steradians);
  }
  /**
   * Create a new SolidAngle instance from a Steradians
   *
   * @param value The unit as Steradians to create a new SolidAngle from.
   * @returns The new SolidAngle instance.
   */
  static FromSteradians(value) {
    return new SolidAngle(value, SolidAngleUnits.Steradians);
  }
  /**
   * Gets the base unit enumeration associated with SolidAngle
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return SolidAngleUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return SolidAngleUnits.Steradians;
  }
  /**
   * Create API DTO represent a SolidAngle unit.
   * @param holdInUnit The specific SolidAngle unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = SolidAngleUnits.Steradians) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a SolidAngle unit from an API DTO representation.
   * @param dtoSolidAngle The SolidAngle API DTO representation
   */
  static FromDto(dtoSolidAngle) {
    return new SolidAngle(dtoSolidAngle.value, dtoSolidAngle.unit);
  }
  /**
   * Convert SolidAngle to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case SolidAngleUnits.Steradians:
        return this.Steradians;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case SolidAngleUnits.Steradians:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case SolidAngleUnits.Steradians:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case SolidAngleUnits.Steradians:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case SolidAngleUnits.Steradians:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the SolidAngle to string.
   * Note! the default format for SolidAngle is Steradians.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the SolidAngle.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the SolidAngle.
   */
  toString(unit = SolidAngleUnits.Steradians, fractionalDigits) {
    switch (unit) {
      case SolidAngleUnits.Steradians:
        return super.truncateFractionDigits(this.Steradians, fractionalDigits) + ` sr`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get SolidAngle unit abbreviation.
   * Note! the default abbreviation for SolidAngle is Steradians.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the SolidAngle.
   * @returns The abbreviation string of SolidAngle.
   */
  getUnitAbbreviation(unitAbbreviation = SolidAngleUnits.Steradians) {
    switch (unitAbbreviation) {
      case SolidAngleUnits.Steradians:
        return `sr`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given SolidAngle are equals to the current SolidAngle.
   * @param solidAngle The other SolidAngle.
   * @returns True if the given SolidAngle are equal to the current SolidAngle.
   */
  equals(solidAngle) {
    return super.internalEquals(this.value, solidAngle.BaseValue);
  }
  /**
   * Compare the given SolidAngle against the current SolidAngle.
   * @param solidAngle The other SolidAngle.
   * @returns 0 if they are equal, -1 if the current SolidAngle is less then other, 1 if the current SolidAngle is greater then other.
   */
  compareTo(solidAngle) {
    return super.internalCompareTo(this.value, solidAngle.BaseValue);
  }
  /**
   * Add the given SolidAngle with the current SolidAngle.
   * @param solidAngle The other SolidAngle.
   * @returns A new SolidAngle instance with the results.
   */
  add(solidAngle) {
    return new SolidAngle(super.internalAdd(this.value, solidAngle.BaseValue));
  }
  /**
   * Subtract the given SolidAngle with the current SolidAngle.
   * @param solidAngle The other SolidAngle.
   * @returns A new SolidAngle instance with the results.
   */
  subtract(solidAngle) {
    return new SolidAngle(super.internalSubtract(this.value, solidAngle.BaseValue));
  }
  /**
   * Multiply the given SolidAngle with the current SolidAngle.
   * @param solidAngle The other SolidAngle.
   * @returns A new SolidAngle instance with the results.
   */
  multiply(solidAngle) {
    return new SolidAngle(super.internalMultiply(this.value, solidAngle.BaseValue));
  }
  /**
   * Divide the given SolidAngle with the current SolidAngle.
   * @param solidAngle The other SolidAngle.
   * @returns A new SolidAngle instance with the results.
   */
  divide(solidAngle) {
    return new SolidAngle(super.internalDivide(this.value, solidAngle.BaseValue));
  }
  /**
   * Modulo the given SolidAngle with the current SolidAngle.
   * @param solidAngle The other SolidAngle.
   * @returns A new SolidAngle instance with the results.
   */
  modulo(solidAngle) {
    return new SolidAngle(super.internalModulo(this.value, solidAngle.BaseValue));
  }
  /**
   * Pow the given SolidAngle with the current SolidAngle.
   * @param solidAngle The other SolidAngle.
   * @returns A new SolidAngle instance with the results.
   */
  pow(solidAngle) {
    return new SolidAngle(super.internalPow(this.value, solidAngle.BaseValue));
  }
}
exports.SolidAngle = SolidAngle;