"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BrakeSpecificFuelConsumption = exports.BrakeSpecificFuelConsumptionUnits = void 0;
const base_unit_1 = require("../base-unit");
/** BrakeSpecificFuelConsumptionUnits enumeration */
var BrakeSpecificFuelConsumptionUnits;
(function (BrakeSpecificFuelConsumptionUnits) {
  /** */
  BrakeSpecificFuelConsumptionUnits["GramsPerKiloWattHour"] = "GramPerKiloWattHour";
  /** */
  BrakeSpecificFuelConsumptionUnits["KilogramsPerJoule"] = "KilogramPerJoule";
  /** The pound per horse power hour uses mechanical horse power and the imperial pound */
  BrakeSpecificFuelConsumptionUnits["PoundsPerMechanicalHorsepowerHour"] = "PoundPerMechanicalHorsepowerHour";
})(BrakeSpecificFuelConsumptionUnits = exports.BrakeSpecificFuelConsumptionUnits || (exports.BrakeSpecificFuelConsumptionUnits = {}));
/** Brake specific fuel consumption (BSFC) is a measure of the fuel efficiency of any prime mover that burns fuel and produces rotational, or shaft, power. It is typically used for comparing the efficiency of internal combustion engines with a shaft output. */
class BrakeSpecificFuelConsumption extends base_unit_1.BaseUnit {
  /**
   * Create a new BrakeSpecificFuelConsumption.
   * @param value The value.
   * @param fromUnit The ‘BrakeSpecificFuelConsumption’ unit to create from.
   * The default unit is KilogramsPerJoule
   */
  constructor(value, fromUnit = BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule) {
    super();
    this.gramsperkilowatthourLazy = null;
    this.kilogramsperjouleLazy = null;
    this.poundspermechanicalhorsepowerhourLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of BrakeSpecificFuelConsumption is KilogramsPerJoule.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule;
  }
  /** */
  get GramsPerKiloWattHour() {
    if (this.gramsperkilowatthourLazy !== null) {
      return this.gramsperkilowatthourLazy;
    }
    return this.gramsperkilowatthourLazy = this.convertFromBase(BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour);
  }
  /** */
  get KilogramsPerJoule() {
    if (this.kilogramsperjouleLazy !== null) {
      return this.kilogramsperjouleLazy;
    }
    return this.kilogramsperjouleLazy = this.convertFromBase(BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule);
  }
  /** The pound per horse power hour uses mechanical horse power and the imperial pound */
  get PoundsPerMechanicalHorsepowerHour() {
    if (this.poundspermechanicalhorsepowerhourLazy !== null) {
      return this.poundspermechanicalhorsepowerhourLazy;
    }
    return this.poundspermechanicalhorsepowerhourLazy = this.convertFromBase(BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour);
  }
  /**
   * Create a new BrakeSpecificFuelConsumption instance from a GramsPerKiloWattHour
   *
   * @param value The unit as GramsPerKiloWattHour to create a new BrakeSpecificFuelConsumption from.
   * @returns The new BrakeSpecificFuelConsumption instance.
   */
  static FromGramsPerKiloWattHour(value) {
    return new BrakeSpecificFuelConsumption(value, BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour);
  }
  /**
   * Create a new BrakeSpecificFuelConsumption instance from a KilogramsPerJoule
   *
   * @param value The unit as KilogramsPerJoule to create a new BrakeSpecificFuelConsumption from.
   * @returns The new BrakeSpecificFuelConsumption instance.
   */
  static FromKilogramsPerJoule(value) {
    return new BrakeSpecificFuelConsumption(value, BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule);
  }
  /**
   * Create a new BrakeSpecificFuelConsumption instance from a PoundsPerMechanicalHorsepowerHour
   * The pound per horse power hour uses mechanical horse power and the imperial pound
   * @param value The unit as PoundsPerMechanicalHorsepowerHour to create a new BrakeSpecificFuelConsumption from.
   * @returns The new BrakeSpecificFuelConsumption instance.
   */
  static FromPoundsPerMechanicalHorsepowerHour(value) {
    return new BrakeSpecificFuelConsumption(value, BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour);
  }
  /**
   * Gets the base unit enumeration associated with BrakeSpecificFuelConsumption
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return BrakeSpecificFuelConsumptionUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule;
  }
  /**
   * Create API DTO represent a BrakeSpecificFuelConsumption unit.
   * @param holdInUnit The specific BrakeSpecificFuelConsumption unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a BrakeSpecificFuelConsumption unit from an API DTO representation.
   * @param dtoBrakeSpecificFuelConsumption The BrakeSpecificFuelConsumption API DTO representation
   */
  static FromDto(dtoBrakeSpecificFuelConsumption) {
    return new BrakeSpecificFuelConsumption(dtoBrakeSpecificFuelConsumption.value, dtoBrakeSpecificFuelConsumption.unit);
  }
  /**
   * Convert BrakeSpecificFuelConsumption to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour:
        return this.GramsPerKiloWattHour;
      case BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule:
        return this.KilogramsPerJoule;
      case BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour:
        return this.PoundsPerMechanicalHorsepowerHour;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour:
        return super.internalMultiply(this.value, 3.6e9);
      case BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule:
        return this.value;
      case BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour:
        return super.internalDivide(this.value, 1.689659410672e-7);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour:
        return this.value * 3.6e9;
      case BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule:
        return this.value;
      case BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour:
        return this.value / 1.689659410672e-7;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour:
        return super.internalDivide(value, 3.6e9);
      case BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule:
        return value;
      case BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour:
        return super.internalMultiply(value, 1.689659410672e-7);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour:
        return value / 3.6e9;
      case BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule:
        return value;
      case BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour:
        return value * 1.689659410672e-7;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the BrakeSpecificFuelConsumption to string.
   * Note! the default format for BrakeSpecificFuelConsumption is KilogramsPerJoule.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the BrakeSpecificFuelConsumption.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the BrakeSpecificFuelConsumption.
   */
  toString(unit = BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule, fractionalDigits) {
    switch (unit) {
      case BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour:
        return super.truncateFractionDigits(this.GramsPerKiloWattHour, fractionalDigits) + ` g/kWh`;
      case BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule:
        return super.truncateFractionDigits(this.KilogramsPerJoule, fractionalDigits) + ` kg/J`;
      case BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour:
        return super.truncateFractionDigits(this.PoundsPerMechanicalHorsepowerHour, fractionalDigits) + ` lb/hph`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get BrakeSpecificFuelConsumption unit abbreviation.
   * Note! the default abbreviation for BrakeSpecificFuelConsumption is KilogramsPerJoule.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the BrakeSpecificFuelConsumption.
   * @returns The abbreviation string of BrakeSpecificFuelConsumption.
   */
  getUnitAbbreviation(unitAbbreviation = BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule) {
    switch (unitAbbreviation) {
      case BrakeSpecificFuelConsumptionUnits.GramsPerKiloWattHour:
        return `g/kWh`;
      case BrakeSpecificFuelConsumptionUnits.KilogramsPerJoule:
        return `kg/J`;
      case BrakeSpecificFuelConsumptionUnits.PoundsPerMechanicalHorsepowerHour:
        return `lb/hph`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given BrakeSpecificFuelConsumption are equals to the current BrakeSpecificFuelConsumption.
   * @param brakeSpecificFuelConsumption The other BrakeSpecificFuelConsumption.
   * @returns True if the given BrakeSpecificFuelConsumption are equal to the current BrakeSpecificFuelConsumption.
   */
  equals(brakeSpecificFuelConsumption) {
    return super.internalEquals(this.value, brakeSpecificFuelConsumption.BaseValue);
  }
  /**
   * Compare the given BrakeSpecificFuelConsumption against the current BrakeSpecificFuelConsumption.
   * @param brakeSpecificFuelConsumption The other BrakeSpecificFuelConsumption.
   * @returns 0 if they are equal, -1 if the current BrakeSpecificFuelConsumption is less then other, 1 if the current BrakeSpecificFuelConsumption is greater then other.
   */
  compareTo(brakeSpecificFuelConsumption) {
    return super.internalCompareTo(this.value, brakeSpecificFuelConsumption.BaseValue);
  }
  /**
   * Add the given BrakeSpecificFuelConsumption with the current BrakeSpecificFuelConsumption.
   * @param brakeSpecificFuelConsumption The other BrakeSpecificFuelConsumption.
   * @returns A new BrakeSpecificFuelConsumption instance with the results.
   */
  add(brakeSpecificFuelConsumption) {
    return new BrakeSpecificFuelConsumption(super.internalAdd(this.value, brakeSpecificFuelConsumption.BaseValue));
  }
  /**
   * Subtract the given BrakeSpecificFuelConsumption with the current BrakeSpecificFuelConsumption.
   * @param brakeSpecificFuelConsumption The other BrakeSpecificFuelConsumption.
   * @returns A new BrakeSpecificFuelConsumption instance with the results.
   */
  subtract(brakeSpecificFuelConsumption) {
    return new BrakeSpecificFuelConsumption(super.internalSubtract(this.value, brakeSpecificFuelConsumption.BaseValue));
  }
  /**
   * Multiply the given BrakeSpecificFuelConsumption with the current BrakeSpecificFuelConsumption.
   * @param brakeSpecificFuelConsumption The other BrakeSpecificFuelConsumption.
   * @returns A new BrakeSpecificFuelConsumption instance with the results.
   */
  multiply(brakeSpecificFuelConsumption) {
    return new BrakeSpecificFuelConsumption(super.internalMultiply(this.value, brakeSpecificFuelConsumption.BaseValue));
  }
  /**
   * Divide the given BrakeSpecificFuelConsumption with the current BrakeSpecificFuelConsumption.
   * @param brakeSpecificFuelConsumption The other BrakeSpecificFuelConsumption.
   * @returns A new BrakeSpecificFuelConsumption instance with the results.
   */
  divide(brakeSpecificFuelConsumption) {
    return new BrakeSpecificFuelConsumption(super.internalDivide(this.value, brakeSpecificFuelConsumption.BaseValue));
  }
  /**
   * Modulo the given BrakeSpecificFuelConsumption with the current BrakeSpecificFuelConsumption.
   * @param brakeSpecificFuelConsumption The other BrakeSpecificFuelConsumption.
   * @returns A new BrakeSpecificFuelConsumption instance with the results.
   */
  modulo(brakeSpecificFuelConsumption) {
    return new BrakeSpecificFuelConsumption(super.internalModulo(this.value, brakeSpecificFuelConsumption.BaseValue));
  }
  /**
   * Pow the given BrakeSpecificFuelConsumption with the current BrakeSpecificFuelConsumption.
   * @param brakeSpecificFuelConsumption The other BrakeSpecificFuelConsumption.
   * @returns A new BrakeSpecificFuelConsumption instance with the results.
   */
  pow(brakeSpecificFuelConsumption) {
    return new BrakeSpecificFuelConsumption(super.internalPow(this.value, brakeSpecificFuelConsumption.BaseValue));
  }
}
exports.BrakeSpecificFuelConsumption = BrakeSpecificFuelConsumption;