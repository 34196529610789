"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Irradiation = exports.IrradiationUnits = void 0;
const base_unit_1 = require("../base-unit");
/** IrradiationUnits enumeration */
var IrradiationUnits;
(function (IrradiationUnits) {
  /** */
  IrradiationUnits["JoulesPerSquareMeter"] = "JoulePerSquareMeter";
  /** */
  IrradiationUnits["JoulesPerSquareCentimeter"] = "JoulePerSquareCentimeter";
  /** */
  IrradiationUnits["JoulesPerSquareMillimeter"] = "JoulePerSquareMillimeter";
  /** */
  IrradiationUnits["WattHoursPerSquareMeter"] = "WattHourPerSquareMeter";
  /** */
  IrradiationUnits["BtusPerSquareFoot"] = "BtuPerSquareFoot";
  /** */
  IrradiationUnits["KilojoulesPerSquareMeter"] = "KilojoulePerSquareMeter";
  /** */
  IrradiationUnits["MillijoulesPerSquareCentimeter"] = "MillijoulePerSquareCentimeter";
  /** */
  IrradiationUnits["KilowattHoursPerSquareMeter"] = "KilowattHourPerSquareMeter";
  /** */
  IrradiationUnits["KilobtusPerSquareFoot"] = "KilobtuPerSquareFoot";
})(IrradiationUnits = exports.IrradiationUnits || (exports.IrradiationUnits = {}));
/** Irradiation is the process by which an object is exposed to radiation. The exposure can originate from various sources, including natural sources. */
class Irradiation extends base_unit_1.BaseUnit {
  /**
   * Create a new Irradiation.
   * @param value The value.
   * @param fromUnit The ‘Irradiation’ unit to create from.
   * The default unit is JoulesPerSquareMeter
   */
  constructor(value, fromUnit = IrradiationUnits.JoulesPerSquareMeter) {
    super();
    this.joulespersquaremeterLazy = null;
    this.joulespersquarecentimeterLazy = null;
    this.joulespersquaremillimeterLazy = null;
    this.watthourspersquaremeterLazy = null;
    this.btuspersquarefootLazy = null;
    this.kilojoulespersquaremeterLazy = null;
    this.millijoulespersquarecentimeterLazy = null;
    this.kilowatthourspersquaremeterLazy = null;
    this.kilobtuspersquarefootLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Irradiation is JoulesPerSquareMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return IrradiationUnits.JoulesPerSquareMeter;
  }
  /** */
  get JoulesPerSquareMeter() {
    if (this.joulespersquaremeterLazy !== null) {
      return this.joulespersquaremeterLazy;
    }
    return this.joulespersquaremeterLazy = this.convertFromBase(IrradiationUnits.JoulesPerSquareMeter);
  }
  /** */
  get JoulesPerSquareCentimeter() {
    if (this.joulespersquarecentimeterLazy !== null) {
      return this.joulespersquarecentimeterLazy;
    }
    return this.joulespersquarecentimeterLazy = this.convertFromBase(IrradiationUnits.JoulesPerSquareCentimeter);
  }
  /** */
  get JoulesPerSquareMillimeter() {
    if (this.joulespersquaremillimeterLazy !== null) {
      return this.joulespersquaremillimeterLazy;
    }
    return this.joulespersquaremillimeterLazy = this.convertFromBase(IrradiationUnits.JoulesPerSquareMillimeter);
  }
  /** */
  get WattHoursPerSquareMeter() {
    if (this.watthourspersquaremeterLazy !== null) {
      return this.watthourspersquaremeterLazy;
    }
    return this.watthourspersquaremeterLazy = this.convertFromBase(IrradiationUnits.WattHoursPerSquareMeter);
  }
  /** */
  get BtusPerSquareFoot() {
    if (this.btuspersquarefootLazy !== null) {
      return this.btuspersquarefootLazy;
    }
    return this.btuspersquarefootLazy = this.convertFromBase(IrradiationUnits.BtusPerSquareFoot);
  }
  /** */
  get KilojoulesPerSquareMeter() {
    if (this.kilojoulespersquaremeterLazy !== null) {
      return this.kilojoulespersquaremeterLazy;
    }
    return this.kilojoulespersquaremeterLazy = this.convertFromBase(IrradiationUnits.KilojoulesPerSquareMeter);
  }
  /** */
  get MillijoulesPerSquareCentimeter() {
    if (this.millijoulespersquarecentimeterLazy !== null) {
      return this.millijoulespersquarecentimeterLazy;
    }
    return this.millijoulespersquarecentimeterLazy = this.convertFromBase(IrradiationUnits.MillijoulesPerSquareCentimeter);
  }
  /** */
  get KilowattHoursPerSquareMeter() {
    if (this.kilowatthourspersquaremeterLazy !== null) {
      return this.kilowatthourspersquaremeterLazy;
    }
    return this.kilowatthourspersquaremeterLazy = this.convertFromBase(IrradiationUnits.KilowattHoursPerSquareMeter);
  }
  /** */
  get KilobtusPerSquareFoot() {
    if (this.kilobtuspersquarefootLazy !== null) {
      return this.kilobtuspersquarefootLazy;
    }
    return this.kilobtuspersquarefootLazy = this.convertFromBase(IrradiationUnits.KilobtusPerSquareFoot);
  }
  /**
   * Create a new Irradiation instance from a JoulesPerSquareMeter
   *
   * @param value The unit as JoulesPerSquareMeter to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromJoulesPerSquareMeter(value) {
    return new Irradiation(value, IrradiationUnits.JoulesPerSquareMeter);
  }
  /**
   * Create a new Irradiation instance from a JoulesPerSquareCentimeter
   *
   * @param value The unit as JoulesPerSquareCentimeter to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromJoulesPerSquareCentimeter(value) {
    return new Irradiation(value, IrradiationUnits.JoulesPerSquareCentimeter);
  }
  /**
   * Create a new Irradiation instance from a JoulesPerSquareMillimeter
   *
   * @param value The unit as JoulesPerSquareMillimeter to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromJoulesPerSquareMillimeter(value) {
    return new Irradiation(value, IrradiationUnits.JoulesPerSquareMillimeter);
  }
  /**
   * Create a new Irradiation instance from a WattHoursPerSquareMeter
   *
   * @param value The unit as WattHoursPerSquareMeter to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromWattHoursPerSquareMeter(value) {
    return new Irradiation(value, IrradiationUnits.WattHoursPerSquareMeter);
  }
  /**
   * Create a new Irradiation instance from a BtusPerSquareFoot
   *
   * @param value The unit as BtusPerSquareFoot to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromBtusPerSquareFoot(value) {
    return new Irradiation(value, IrradiationUnits.BtusPerSquareFoot);
  }
  /**
   * Create a new Irradiation instance from a KilojoulesPerSquareMeter
   *
   * @param value The unit as KilojoulesPerSquareMeter to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromKilojoulesPerSquareMeter(value) {
    return new Irradiation(value, IrradiationUnits.KilojoulesPerSquareMeter);
  }
  /**
   * Create a new Irradiation instance from a MillijoulesPerSquareCentimeter
   *
   * @param value The unit as MillijoulesPerSquareCentimeter to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromMillijoulesPerSquareCentimeter(value) {
    return new Irradiation(value, IrradiationUnits.MillijoulesPerSquareCentimeter);
  }
  /**
   * Create a new Irradiation instance from a KilowattHoursPerSquareMeter
   *
   * @param value The unit as KilowattHoursPerSquareMeter to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromKilowattHoursPerSquareMeter(value) {
    return new Irradiation(value, IrradiationUnits.KilowattHoursPerSquareMeter);
  }
  /**
   * Create a new Irradiation instance from a KilobtusPerSquareFoot
   *
   * @param value The unit as KilobtusPerSquareFoot to create a new Irradiation from.
   * @returns The new Irradiation instance.
   */
  static FromKilobtusPerSquareFoot(value) {
    return new Irradiation(value, IrradiationUnits.KilobtusPerSquareFoot);
  }
  /**
   * Gets the base unit enumeration associated with Irradiation
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return IrradiationUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return IrradiationUnits.JoulesPerSquareMeter;
  }
  /**
   * Create API DTO represent a Irradiation unit.
   * @param holdInUnit The specific Irradiation unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = IrradiationUnits.JoulesPerSquareMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Irradiation unit from an API DTO representation.
   * @param dtoIrradiation The Irradiation API DTO representation
   */
  static FromDto(dtoIrradiation) {
    return new Irradiation(dtoIrradiation.value, dtoIrradiation.unit);
  }
  /**
   * Convert Irradiation to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case IrradiationUnits.JoulesPerSquareMeter:
        return this.JoulesPerSquareMeter;
      case IrradiationUnits.JoulesPerSquareCentimeter:
        return this.JoulesPerSquareCentimeter;
      case IrradiationUnits.JoulesPerSquareMillimeter:
        return this.JoulesPerSquareMillimeter;
      case IrradiationUnits.WattHoursPerSquareMeter:
        return this.WattHoursPerSquareMeter;
      case IrradiationUnits.BtusPerSquareFoot:
        return this.BtusPerSquareFoot;
      case IrradiationUnits.KilojoulesPerSquareMeter:
        return this.KilojoulesPerSquareMeter;
      case IrradiationUnits.MillijoulesPerSquareCentimeter:
        return this.MillijoulesPerSquareCentimeter;
      case IrradiationUnits.KilowattHoursPerSquareMeter:
        return this.KilowattHoursPerSquareMeter;
      case IrradiationUnits.KilobtusPerSquareFoot:
        return this.KilobtusPerSquareFoot;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case IrradiationUnits.JoulesPerSquareMeter:
        return this.value;
      case IrradiationUnits.JoulesPerSquareCentimeter:
        return super.internalDivide(this.value, 1e4);
      case IrradiationUnits.JoulesPerSquareMillimeter:
        return super.internalDivide(this.value, 1e6);
      case IrradiationUnits.WattHoursPerSquareMeter:
        return super.internalDivide(this.value, 3600);
      case IrradiationUnits.BtusPerSquareFoot:
        {
          const v4 = super.internalDivide(52752792631, 4645152);
          return super.internalDivide(this.value, v4);
        }
      case IrradiationUnits.KilojoulesPerSquareMeter:
        return super.internalDivide(this.value, 1000);
      case IrradiationUnits.MillijoulesPerSquareCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e4);
          return super.internalDivide(v3, 0.001);
        }
      case IrradiationUnits.KilowattHoursPerSquareMeter:
        {
          const v3 = super.internalDivide(this.value, 3600);
          return super.internalDivide(v3, 1000);
        }
      case IrradiationUnits.KilobtusPerSquareFoot:
        {
          const v4 = super.internalDivide(52752792631, 4645152);
          const v5 = super.internalDivide(this.value, v4);
          return super.internalDivide(v5, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case IrradiationUnits.JoulesPerSquareMeter:
        return this.value;
      case IrradiationUnits.JoulesPerSquareCentimeter:
        return this.value / 1e4;
      case IrradiationUnits.JoulesPerSquareMillimeter:
        return this.value / 1e6;
      case IrradiationUnits.WattHoursPerSquareMeter:
        return this.value / 3600;
      case IrradiationUnits.BtusPerSquareFoot:
        return this.value / (52752792631 / 4645152);
      case IrradiationUnits.KilojoulesPerSquareMeter:
        return this.value / 1000;
      case IrradiationUnits.MillijoulesPerSquareCentimeter:
        return this.value / 1e4 / 0.001;
      case IrradiationUnits.KilowattHoursPerSquareMeter:
        return this.value / 3600 / 1000;
      case IrradiationUnits.KilobtusPerSquareFoot:
        return this.value / (52752792631 / 4645152) / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case IrradiationUnits.JoulesPerSquareMeter:
        return value;
      case IrradiationUnits.JoulesPerSquareCentimeter:
        return super.internalMultiply(value, 1e4);
      case IrradiationUnits.JoulesPerSquareMillimeter:
        return super.internalMultiply(value, 1e6);
      case IrradiationUnits.WattHoursPerSquareMeter:
        return super.internalMultiply(value, 3600);
      case IrradiationUnits.BtusPerSquareFoot:
        {
          const v4 = super.internalDivide(52752792631, 4645152);
          return super.internalMultiply(value, v4);
        }
      case IrradiationUnits.KilojoulesPerSquareMeter:
        return super.internalMultiply(value, 1000);
      case IrradiationUnits.MillijoulesPerSquareCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e4);
          return super.internalMultiply(v3, 0.001);
        }
      case IrradiationUnits.KilowattHoursPerSquareMeter:
        {
          const v3 = super.internalMultiply(value, 3600);
          return super.internalMultiply(v3, 1000);
        }
      case IrradiationUnits.KilobtusPerSquareFoot:
        {
          const v4 = super.internalDivide(52752792631, 4645152);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case IrradiationUnits.JoulesPerSquareMeter:
        return value;
      case IrradiationUnits.JoulesPerSquareCentimeter:
        return value * 1e4;
      case IrradiationUnits.JoulesPerSquareMillimeter:
        return value * 1e6;
      case IrradiationUnits.WattHoursPerSquareMeter:
        return value * 3600;
      case IrradiationUnits.BtusPerSquareFoot:
        return value * (52752792631 / 4645152);
      case IrradiationUnits.KilojoulesPerSquareMeter:
        return value * 1000;
      case IrradiationUnits.MillijoulesPerSquareCentimeter:
        return value * 1e4 * 0.001;
      case IrradiationUnits.KilowattHoursPerSquareMeter:
        return value * 3600 * 1000;
      case IrradiationUnits.KilobtusPerSquareFoot:
        return value * (52752792631 / 4645152) * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Irradiation to string.
   * Note! the default format for Irradiation is JoulesPerSquareMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Irradiation.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Irradiation.
   */
  toString(unit = IrradiationUnits.JoulesPerSquareMeter, fractionalDigits) {
    switch (unit) {
      case IrradiationUnits.JoulesPerSquareMeter:
        return super.truncateFractionDigits(this.JoulesPerSquareMeter, fractionalDigits) + ` J/m²`;
      case IrradiationUnits.JoulesPerSquareCentimeter:
        return super.truncateFractionDigits(this.JoulesPerSquareCentimeter, fractionalDigits) + ` J/cm²`;
      case IrradiationUnits.JoulesPerSquareMillimeter:
        return super.truncateFractionDigits(this.JoulesPerSquareMillimeter, fractionalDigits) + ` J/mm²`;
      case IrradiationUnits.WattHoursPerSquareMeter:
        return super.truncateFractionDigits(this.WattHoursPerSquareMeter, fractionalDigits) + ` Wh/m²`;
      case IrradiationUnits.BtusPerSquareFoot:
        return super.truncateFractionDigits(this.BtusPerSquareFoot, fractionalDigits) + ` Btu/ft²`;
      case IrradiationUnits.KilojoulesPerSquareMeter:
        return super.truncateFractionDigits(this.KilojoulesPerSquareMeter, fractionalDigits) + ` kJ/m²`;
      case IrradiationUnits.MillijoulesPerSquareCentimeter:
        return super.truncateFractionDigits(this.MillijoulesPerSquareCentimeter, fractionalDigits) + ` mJ/cm²`;
      case IrradiationUnits.KilowattHoursPerSquareMeter:
        return super.truncateFractionDigits(this.KilowattHoursPerSquareMeter, fractionalDigits) + ` kWh/m²`;
      case IrradiationUnits.KilobtusPerSquareFoot:
        return super.truncateFractionDigits(this.KilobtusPerSquareFoot, fractionalDigits) + ` kBtu/ft²`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Irradiation unit abbreviation.
   * Note! the default abbreviation for Irradiation is JoulesPerSquareMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Irradiation.
   * @returns The abbreviation string of Irradiation.
   */
  getUnitAbbreviation(unitAbbreviation = IrradiationUnits.JoulesPerSquareMeter) {
    switch (unitAbbreviation) {
      case IrradiationUnits.JoulesPerSquareMeter:
        return `J/m²`;
      case IrradiationUnits.JoulesPerSquareCentimeter:
        return `J/cm²`;
      case IrradiationUnits.JoulesPerSquareMillimeter:
        return `J/mm²`;
      case IrradiationUnits.WattHoursPerSquareMeter:
        return `Wh/m²`;
      case IrradiationUnits.BtusPerSquareFoot:
        return `Btu/ft²`;
      case IrradiationUnits.KilojoulesPerSquareMeter:
        return `kJ/m²`;
      case IrradiationUnits.MillijoulesPerSquareCentimeter:
        return `mJ/cm²`;
      case IrradiationUnits.KilowattHoursPerSquareMeter:
        return `kWh/m²`;
      case IrradiationUnits.KilobtusPerSquareFoot:
        return `kBtu/ft²`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Irradiation are equals to the current Irradiation.
   * @param irradiation The other Irradiation.
   * @returns True if the given Irradiation are equal to the current Irradiation.
   */
  equals(irradiation) {
    return super.internalEquals(this.value, irradiation.BaseValue);
  }
  /**
   * Compare the given Irradiation against the current Irradiation.
   * @param irradiation The other Irradiation.
   * @returns 0 if they are equal, -1 if the current Irradiation is less then other, 1 if the current Irradiation is greater then other.
   */
  compareTo(irradiation) {
    return super.internalCompareTo(this.value, irradiation.BaseValue);
  }
  /**
   * Add the given Irradiation with the current Irradiation.
   * @param irradiation The other Irradiation.
   * @returns A new Irradiation instance with the results.
   */
  add(irradiation) {
    return new Irradiation(super.internalAdd(this.value, irradiation.BaseValue));
  }
  /**
   * Subtract the given Irradiation with the current Irradiation.
   * @param irradiation The other Irradiation.
   * @returns A new Irradiation instance with the results.
   */
  subtract(irradiation) {
    return new Irradiation(super.internalSubtract(this.value, irradiation.BaseValue));
  }
  /**
   * Multiply the given Irradiation with the current Irradiation.
   * @param irradiation The other Irradiation.
   * @returns A new Irradiation instance with the results.
   */
  multiply(irradiation) {
    return new Irradiation(super.internalMultiply(this.value, irradiation.BaseValue));
  }
  /**
   * Divide the given Irradiation with the current Irradiation.
   * @param irradiation The other Irradiation.
   * @returns A new Irradiation instance with the results.
   */
  divide(irradiation) {
    return new Irradiation(super.internalDivide(this.value, irradiation.BaseValue));
  }
  /**
   * Modulo the given Irradiation with the current Irradiation.
   * @param irradiation The other Irradiation.
   * @returns A new Irradiation instance with the results.
   */
  modulo(irradiation) {
    return new Irradiation(super.internalModulo(this.value, irradiation.BaseValue));
  }
  /**
   * Pow the given Irradiation with the current Irradiation.
   * @param irradiation The other Irradiation.
   * @returns A new Irradiation instance with the results.
   */
  pow(irradiation) {
    return new Irradiation(super.internalPow(this.value, irradiation.BaseValue));
  }
}
exports.Irradiation = Irradiation;