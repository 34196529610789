"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThermalResistance = exports.ThermalResistanceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ThermalResistanceUnits enumeration */
var ThermalResistanceUnits;
(function (ThermalResistanceUnits) {
  /** */
  ThermalResistanceUnits["SquareMeterKelvinsPerKilowatt"] = "SquareMeterKelvinPerKilowatt";
  /** */
  ThermalResistanceUnits["SquareMeterKelvinsPerWatt"] = "SquareMeterKelvinPerWatt";
  /** */
  ThermalResistanceUnits["SquareMeterDegreesCelsiusPerWatt"] = "SquareMeterDegreeCelsiusPerWatt";
  /** */
  ThermalResistanceUnits["SquareCentimeterKelvinsPerWatt"] = "SquareCentimeterKelvinPerWatt";
  /** */
  ThermalResistanceUnits["SquareCentimeterHourDegreesCelsiusPerKilocalorie"] = "SquareCentimeterHourDegreeCelsiusPerKilocalorie";
  /** */
  ThermalResistanceUnits["HourSquareFeetDegreesFahrenheitPerBtu"] = "HourSquareFeetDegreeFahrenheitPerBtu";
})(ThermalResistanceUnits = exports.ThermalResistanceUnits || (exports.ThermalResistanceUnits = {}));
/** Heat Transfer Coefficient or Thermal conductivity - indicates a materials ability to conduct heat. */
class ThermalResistance extends base_unit_1.BaseUnit {
  /**
   * Create a new ThermalResistance.
   * @param value The value.
   * @param fromUnit The ‘ThermalResistance’ unit to create from.
   * The default unit is SquareMeterKelvinsPerKilowatt
   */
  constructor(value, fromUnit = ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt) {
    super();
    this.squaremeterkelvinsperkilowattLazy = null;
    this.squaremeterkelvinsperwattLazy = null;
    this.squaremeterdegreescelsiusperwattLazy = null;
    this.squarecentimeterkelvinsperwattLazy = null;
    this.squarecentimeterhourdegreescelsiusperkilocalorieLazy = null;
    this.hoursquarefeetdegreesfahrenheitperbtuLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ThermalResistance is SquareMeterKelvinsPerKilowatt.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt;
  }
  /** */
  get SquareMeterKelvinsPerKilowatt() {
    if (this.squaremeterkelvinsperkilowattLazy !== null) {
      return this.squaremeterkelvinsperkilowattLazy;
    }
    return this.squaremeterkelvinsperkilowattLazy = this.convertFromBase(ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt);
  }
  /** */
  get SquareMeterKelvinsPerWatt() {
    if (this.squaremeterkelvinsperwattLazy !== null) {
      return this.squaremeterkelvinsperwattLazy;
    }
    return this.squaremeterkelvinsperwattLazy = this.convertFromBase(ThermalResistanceUnits.SquareMeterKelvinsPerWatt);
  }
  /** */
  get SquareMeterDegreesCelsiusPerWatt() {
    if (this.squaremeterdegreescelsiusperwattLazy !== null) {
      return this.squaremeterdegreescelsiusperwattLazy;
    }
    return this.squaremeterdegreescelsiusperwattLazy = this.convertFromBase(ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt);
  }
  /** */
  get SquareCentimeterKelvinsPerWatt() {
    if (this.squarecentimeterkelvinsperwattLazy !== null) {
      return this.squarecentimeterkelvinsperwattLazy;
    }
    return this.squarecentimeterkelvinsperwattLazy = this.convertFromBase(ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt);
  }
  /** */
  get SquareCentimeterHourDegreesCelsiusPerKilocalorie() {
    if (this.squarecentimeterhourdegreescelsiusperkilocalorieLazy !== null) {
      return this.squarecentimeterhourdegreescelsiusperkilocalorieLazy;
    }
    return this.squarecentimeterhourdegreescelsiusperkilocalorieLazy = this.convertFromBase(ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie);
  }
  /** */
  get HourSquareFeetDegreesFahrenheitPerBtu() {
    if (this.hoursquarefeetdegreesfahrenheitperbtuLazy !== null) {
      return this.hoursquarefeetdegreesfahrenheitperbtuLazy;
    }
    return this.hoursquarefeetdegreesfahrenheitperbtuLazy = this.convertFromBase(ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu);
  }
  /**
   * Create a new ThermalResistance instance from a SquareMeterKelvinsPerKilowatt
   *
   * @param value The unit as SquareMeterKelvinsPerKilowatt to create a new ThermalResistance from.
   * @returns The new ThermalResistance instance.
   */
  static FromSquareMeterKelvinsPerKilowatt(value) {
    return new ThermalResistance(value, ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt);
  }
  /**
   * Create a new ThermalResistance instance from a SquareMeterKelvinsPerWatt
   *
   * @param value The unit as SquareMeterKelvinsPerWatt to create a new ThermalResistance from.
   * @returns The new ThermalResistance instance.
   */
  static FromSquareMeterKelvinsPerWatt(value) {
    return new ThermalResistance(value, ThermalResistanceUnits.SquareMeterKelvinsPerWatt);
  }
  /**
   * Create a new ThermalResistance instance from a SquareMeterDegreesCelsiusPerWatt
   *
   * @param value The unit as SquareMeterDegreesCelsiusPerWatt to create a new ThermalResistance from.
   * @returns The new ThermalResistance instance.
   */
  static FromSquareMeterDegreesCelsiusPerWatt(value) {
    return new ThermalResistance(value, ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt);
  }
  /**
   * Create a new ThermalResistance instance from a SquareCentimeterKelvinsPerWatt
   *
   * @param value The unit as SquareCentimeterKelvinsPerWatt to create a new ThermalResistance from.
   * @returns The new ThermalResistance instance.
   */
  static FromSquareCentimeterKelvinsPerWatt(value) {
    return new ThermalResistance(value, ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt);
  }
  /**
   * Create a new ThermalResistance instance from a SquareCentimeterHourDegreesCelsiusPerKilocalorie
   *
   * @param value The unit as SquareCentimeterHourDegreesCelsiusPerKilocalorie to create a new ThermalResistance from.
   * @returns The new ThermalResistance instance.
   */
  static FromSquareCentimeterHourDegreesCelsiusPerKilocalorie(value) {
    return new ThermalResistance(value, ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie);
  }
  /**
   * Create a new ThermalResistance instance from a HourSquareFeetDegreesFahrenheitPerBtu
   *
   * @param value The unit as HourSquareFeetDegreesFahrenheitPerBtu to create a new ThermalResistance from.
   * @returns The new ThermalResistance instance.
   */
  static FromHourSquareFeetDegreesFahrenheitPerBtu(value) {
    return new ThermalResistance(value, ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu);
  }
  /**
   * Gets the base unit enumeration associated with ThermalResistance
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ThermalResistanceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt;
  }
  /**
   * Create API DTO represent a ThermalResistance unit.
   * @param holdInUnit The specific ThermalResistance unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ThermalResistance unit from an API DTO representation.
   * @param dtoThermalResistance The ThermalResistance API DTO representation
   */
  static FromDto(dtoThermalResistance) {
    return new ThermalResistance(dtoThermalResistance.value, dtoThermalResistance.unit);
  }
  /**
   * Convert ThermalResistance to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt:
        return this.SquareMeterKelvinsPerKilowatt;
      case ThermalResistanceUnits.SquareMeterKelvinsPerWatt:
        return this.SquareMeterKelvinsPerWatt;
      case ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt:
        return this.SquareMeterDegreesCelsiusPerWatt;
      case ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt:
        return this.SquareCentimeterKelvinsPerWatt;
      case ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie:
        return this.SquareCentimeterHourDegreesCelsiusPerKilocalorie;
      case ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu:
        return this.HourSquareFeetDegreesFahrenheitPerBtu;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt:
        return this.value;
      case ThermalResistanceUnits.SquareMeterKelvinsPerWatt:
        return super.internalDivide(this.value, 1000);
      case ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt:
        return super.internalDivide(this.value, 1000.0);
      case ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt:
        return super.internalDivide(this.value, 0.1);
      case ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie:
        return super.internalDivide(this.value, 0.0859779507590433);
      case ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu:
        return super.internalDivide(this.value, 176.1121482159839);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt:
        return this.value;
      case ThermalResistanceUnits.SquareMeterKelvinsPerWatt:
        return this.value / 1000;
      case ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt:
        return this.value / 1000.0;
      case ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt:
        return this.value / 0.1;
      case ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie:
        return this.value / 0.0859779507590433;
      case ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu:
        return this.value / 176.1121482159839;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt:
        return value;
      case ThermalResistanceUnits.SquareMeterKelvinsPerWatt:
        return super.internalMultiply(value, 1000);
      case ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt:
        return super.internalMultiply(value, 1000.0);
      case ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt:
        return super.internalMultiply(value, 0.1);
      case ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie:
        return super.internalMultiply(value, 0.0859779507590433);
      case ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu:
        return super.internalMultiply(value, 176.1121482159839);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt:
        return value;
      case ThermalResistanceUnits.SquareMeterKelvinsPerWatt:
        return value * 1000;
      case ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt:
        return value * 1000.0;
      case ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt:
        return value * 0.1;
      case ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie:
        return value * 0.0859779507590433;
      case ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu:
        return value * 176.1121482159839;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ThermalResistance to string.
   * Note! the default format for ThermalResistance is SquareMeterKelvinsPerKilowatt.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ThermalResistance.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ThermalResistance.
   */
  toString(unit = ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt, fractionalDigits) {
    switch (unit) {
      case ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt:
        return super.truncateFractionDigits(this.SquareMeterKelvinsPerKilowatt, fractionalDigits) + ` m²K/kW`;
      case ThermalResistanceUnits.SquareMeterKelvinsPerWatt:
        return super.truncateFractionDigits(this.SquareMeterKelvinsPerWatt, fractionalDigits) + ` m²K/W`;
      case ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt:
        return super.truncateFractionDigits(this.SquareMeterDegreesCelsiusPerWatt, fractionalDigits) + ` m²°C/W`;
      case ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt:
        return super.truncateFractionDigits(this.SquareCentimeterKelvinsPerWatt, fractionalDigits) + ` cm²K/W`;
      case ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie:
        return super.truncateFractionDigits(this.SquareCentimeterHourDegreesCelsiusPerKilocalorie, fractionalDigits) + ` cm²Hr°C/kcal`;
      case ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu:
        return super.truncateFractionDigits(this.HourSquareFeetDegreesFahrenheitPerBtu, fractionalDigits) + ` Hrft²°F/Btu`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ThermalResistance unit abbreviation.
   * Note! the default abbreviation for ThermalResistance is SquareMeterKelvinsPerKilowatt.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ThermalResistance.
   * @returns The abbreviation string of ThermalResistance.
   */
  getUnitAbbreviation(unitAbbreviation = ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt) {
    switch (unitAbbreviation) {
      case ThermalResistanceUnits.SquareMeterKelvinsPerKilowatt:
        return `m²K/kW`;
      case ThermalResistanceUnits.SquareMeterKelvinsPerWatt:
        return `m²K/W`;
      case ThermalResistanceUnits.SquareMeterDegreesCelsiusPerWatt:
        return `m²°C/W`;
      case ThermalResistanceUnits.SquareCentimeterKelvinsPerWatt:
        return `cm²K/W`;
      case ThermalResistanceUnits.SquareCentimeterHourDegreesCelsiusPerKilocalorie:
        return `cm²Hr°C/kcal`;
      case ThermalResistanceUnits.HourSquareFeetDegreesFahrenheitPerBtu:
        return `Hrft²°F/Btu`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ThermalResistance are equals to the current ThermalResistance.
   * @param thermalResistance The other ThermalResistance.
   * @returns True if the given ThermalResistance are equal to the current ThermalResistance.
   */
  equals(thermalResistance) {
    return super.internalEquals(this.value, thermalResistance.BaseValue);
  }
  /**
   * Compare the given ThermalResistance against the current ThermalResistance.
   * @param thermalResistance The other ThermalResistance.
   * @returns 0 if they are equal, -1 if the current ThermalResistance is less then other, 1 if the current ThermalResistance is greater then other.
   */
  compareTo(thermalResistance) {
    return super.internalCompareTo(this.value, thermalResistance.BaseValue);
  }
  /**
   * Add the given ThermalResistance with the current ThermalResistance.
   * @param thermalResistance The other ThermalResistance.
   * @returns A new ThermalResistance instance with the results.
   */
  add(thermalResistance) {
    return new ThermalResistance(super.internalAdd(this.value, thermalResistance.BaseValue));
  }
  /**
   * Subtract the given ThermalResistance with the current ThermalResistance.
   * @param thermalResistance The other ThermalResistance.
   * @returns A new ThermalResistance instance with the results.
   */
  subtract(thermalResistance) {
    return new ThermalResistance(super.internalSubtract(this.value, thermalResistance.BaseValue));
  }
  /**
   * Multiply the given ThermalResistance with the current ThermalResistance.
   * @param thermalResistance The other ThermalResistance.
   * @returns A new ThermalResistance instance with the results.
   */
  multiply(thermalResistance) {
    return new ThermalResistance(super.internalMultiply(this.value, thermalResistance.BaseValue));
  }
  /**
   * Divide the given ThermalResistance with the current ThermalResistance.
   * @param thermalResistance The other ThermalResistance.
   * @returns A new ThermalResistance instance with the results.
   */
  divide(thermalResistance) {
    return new ThermalResistance(super.internalDivide(this.value, thermalResistance.BaseValue));
  }
  /**
   * Modulo the given ThermalResistance with the current ThermalResistance.
   * @param thermalResistance The other ThermalResistance.
   * @returns A new ThermalResistance instance with the results.
   */
  modulo(thermalResistance) {
    return new ThermalResistance(super.internalModulo(this.value, thermalResistance.BaseValue));
  }
  /**
   * Pow the given ThermalResistance with the current ThermalResistance.
   * @param thermalResistance The other ThermalResistance.
   * @returns A new ThermalResistance instance with the results.
   */
  pow(thermalResistance) {
    return new ThermalResistance(super.internalPow(this.value, thermalResistance.BaseValue));
  }
}
exports.ThermalResistance = ThermalResistance;