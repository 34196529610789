"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LuminousFlux = exports.LuminousFluxUnits = void 0;
const base_unit_1 = require("../base-unit");
/** LuminousFluxUnits enumeration */
var LuminousFluxUnits;
(function (LuminousFluxUnits) {
  /** */
  LuminousFluxUnits["Lumens"] = "Lumen";
})(LuminousFluxUnits = exports.LuminousFluxUnits || (exports.LuminousFluxUnits = {}));
/** In photometry, luminous flux or luminous power is the measure of the perceived power of light. */
class LuminousFlux extends base_unit_1.BaseUnit {
  /**
   * Create a new LuminousFlux.
   * @param value The value.
   * @param fromUnit The ‘LuminousFlux’ unit to create from.
   * The default unit is Lumens
   */
  constructor(value, fromUnit = LuminousFluxUnits.Lumens) {
    super();
    this.lumensLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of LuminousFlux is Lumens.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return LuminousFluxUnits.Lumens;
  }
  /** */
  get Lumens() {
    if (this.lumensLazy !== null) {
      return this.lumensLazy;
    }
    return this.lumensLazy = this.convertFromBase(LuminousFluxUnits.Lumens);
  }
  /**
   * Create a new LuminousFlux instance from a Lumens
   *
   * @param value The unit as Lumens to create a new LuminousFlux from.
   * @returns The new LuminousFlux instance.
   */
  static FromLumens(value) {
    return new LuminousFlux(value, LuminousFluxUnits.Lumens);
  }
  /**
   * Gets the base unit enumeration associated with LuminousFlux
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return LuminousFluxUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return LuminousFluxUnits.Lumens;
  }
  /**
   * Create API DTO represent a LuminousFlux unit.
   * @param holdInUnit The specific LuminousFlux unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = LuminousFluxUnits.Lumens) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a LuminousFlux unit from an API DTO representation.
   * @param dtoLuminousFlux The LuminousFlux API DTO representation
   */
  static FromDto(dtoLuminousFlux) {
    return new LuminousFlux(dtoLuminousFlux.value, dtoLuminousFlux.unit);
  }
  /**
   * Convert LuminousFlux to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case LuminousFluxUnits.Lumens:
        return this.Lumens;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case LuminousFluxUnits.Lumens:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case LuminousFluxUnits.Lumens:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case LuminousFluxUnits.Lumens:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case LuminousFluxUnits.Lumens:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the LuminousFlux to string.
   * Note! the default format for LuminousFlux is Lumens.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the LuminousFlux.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the LuminousFlux.
   */
  toString(unit = LuminousFluxUnits.Lumens, fractionalDigits) {
    switch (unit) {
      case LuminousFluxUnits.Lumens:
        return super.truncateFractionDigits(this.Lumens, fractionalDigits) + ` lm`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get LuminousFlux unit abbreviation.
   * Note! the default abbreviation for LuminousFlux is Lumens.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the LuminousFlux.
   * @returns The abbreviation string of LuminousFlux.
   */
  getUnitAbbreviation(unitAbbreviation = LuminousFluxUnits.Lumens) {
    switch (unitAbbreviation) {
      case LuminousFluxUnits.Lumens:
        return `lm`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given LuminousFlux are equals to the current LuminousFlux.
   * @param luminousFlux The other LuminousFlux.
   * @returns True if the given LuminousFlux are equal to the current LuminousFlux.
   */
  equals(luminousFlux) {
    return super.internalEquals(this.value, luminousFlux.BaseValue);
  }
  /**
   * Compare the given LuminousFlux against the current LuminousFlux.
   * @param luminousFlux The other LuminousFlux.
   * @returns 0 if they are equal, -1 if the current LuminousFlux is less then other, 1 if the current LuminousFlux is greater then other.
   */
  compareTo(luminousFlux) {
    return super.internalCompareTo(this.value, luminousFlux.BaseValue);
  }
  /**
   * Add the given LuminousFlux with the current LuminousFlux.
   * @param luminousFlux The other LuminousFlux.
   * @returns A new LuminousFlux instance with the results.
   */
  add(luminousFlux) {
    return new LuminousFlux(super.internalAdd(this.value, luminousFlux.BaseValue));
  }
  /**
   * Subtract the given LuminousFlux with the current LuminousFlux.
   * @param luminousFlux The other LuminousFlux.
   * @returns A new LuminousFlux instance with the results.
   */
  subtract(luminousFlux) {
    return new LuminousFlux(super.internalSubtract(this.value, luminousFlux.BaseValue));
  }
  /**
   * Multiply the given LuminousFlux with the current LuminousFlux.
   * @param luminousFlux The other LuminousFlux.
   * @returns A new LuminousFlux instance with the results.
   */
  multiply(luminousFlux) {
    return new LuminousFlux(super.internalMultiply(this.value, luminousFlux.BaseValue));
  }
  /**
   * Divide the given LuminousFlux with the current LuminousFlux.
   * @param luminousFlux The other LuminousFlux.
   * @returns A new LuminousFlux instance with the results.
   */
  divide(luminousFlux) {
    return new LuminousFlux(super.internalDivide(this.value, luminousFlux.BaseValue));
  }
  /**
   * Modulo the given LuminousFlux with the current LuminousFlux.
   * @param luminousFlux The other LuminousFlux.
   * @returns A new LuminousFlux instance with the results.
   */
  modulo(luminousFlux) {
    return new LuminousFlux(super.internalModulo(this.value, luminousFlux.BaseValue));
  }
  /**
   * Pow the given LuminousFlux with the current LuminousFlux.
   * @param luminousFlux The other LuminousFlux.
   * @returns A new LuminousFlux instance with the results.
   */
  pow(luminousFlux) {
    return new LuminousFlux(super.internalPow(this.value, luminousFlux.BaseValue));
  }
}
exports.LuminousFlux = LuminousFlux;