import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { lastValueFrom, throwError, zip } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { map, finalize, catchError } from "rxjs/operators";
import { ApplicationSettings } from "../models/applicationSettings";
import { AppConstants } from "../shared/constants";
import { LoadingService } from "./loading.service";
import { SettingService } from "./setting.service";


@Injectable({
    providedIn: "root",
})
export class BaseService {
    constructor(private http: HttpClient, private loader: LoadingService, @Inject(SettingService) private config: ApplicationSettings) { }


    public funtionGet(url: string, msg: string = `Saving...`, isShowLoader = true): Observable<any> {
        if (isShowLoader) {
            this.loader.setMessage(msg);
        }

        const webApiUrl = `${this.config.infrastructure.functionUrl}${url}`;
        return this.http.get<any>(webApiUrl).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            catchError(this.handleError),
            finalize(() => {
                if (isShowLoader) {
                    this.loader.clearMessage();
                }
            })
        );
    }

    public Post(url: string, data: any, msg: string = `Saving...`, isShowLoader = true): Observable<any> {
        if (isShowLoader) {
            this.loader.setMessage(msg);
        }

        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.post<any>(webApiUrl, data).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            catchError(this.handleError),
            finalize(() => {
                if (isShowLoader) {
                    this.loader.clearMessage();
                }
            })
        );
    }
    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

    public Put(url: string, data: any, msg: string = `Saving...`, isShowLoader = true): Observable<any> {
        if (isShowLoader) {
            this.loader.setMessage(msg);
        }

        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.put<any>(webApiUrl, data).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            catchError(this.handleError),
            finalize(() => {
                if (isShowLoader) {
                    this.loader.clearMessage();
                }
            })
        );
    }


    public BlobPost(url: string, data: any, msg: string = `Saving...`, isShowLoader = true): Observable<any> {
        if (isShowLoader) {
            this.loader.setMessage(msg);
        }
        const options = {
            responseType: 'blob' as 'json'
        };
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.post<any>(webApiUrl, data, options).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            catchError(this.handleError),
            finalize(() => {
                if (isShowLoader) {
                    this.loader.clearMessage();
                }
            })
        );
    }

    public BlobGet(url: string, msg: string = `Saving...`, isShowLoader = true): Observable<any> {
        if (isShowLoader) {
            this.loader.setMessage(msg);
        }
        const options = {
            responseType: 'blob' as 'json'
        };
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.get<any>(webApiUrl, options).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            catchError(this.handleError),
            finalize(() => {
                if (isShowLoader) {
                    this.loader.clearMessage();
                }
            })
        );
    }

    public Get(url: string, msg: string = `Loading...`): Observable<any> {
        this.loader.setMessage(msg);
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.get<any>(webApiUrl).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            finalize(() => {
                this.loader.clearMessage();
            })
        );
    }
    public NextLink(url: string, msg: string = `Loading...`): Observable<any> {
        //this.loader.setMessage(msg);
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'ConsistencyLevel': 'eventual'
        }
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return this.http.get<any>(url, requestOptions).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            finalize(() => {
                //this.loader.clearMessage();
            })
        );
    }

    public GetWithoutLoading(url: string): Observable<any> {
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.get(webApiUrl, { responseType: 'text' }).pipe(
            map(
                (result) => result,
                (error: any) => error
            )
        );
    }

    public GetBlob(url: string, data: any, msg: string = `Loading...`): Observable<any> {
        this.loader.setMessage(msg);
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.post(webApiUrl, data, { responseType: "blob" }).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            finalize(() => {
                this.loader.clearMessage();
            })
        );
    }

    public GetByID(url: string, key: any, msg: string = `Loading...`): Observable<any> {
        this.loader.setMessage('Loading...');
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;

        let params = new HttpParams().set("key", key)
        return this.http.get<any>(webApiUrl, { params: params })
            .pipe(
                map(result => result.value === undefined ? result : result.value,
                    (error: any) => error
                ),
                finalize(() => {
                    this.loader.clearMessage();
                })
            )
    }

    public Delete(url: string, key: any): Observable<any> {
        this.loader.setMessage(`Deleting...`);
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.post<any>(webApiUrl, key).pipe(
            map(result => result.value === undefined ? result : result.value,
                (error: any) => error
            ),
            finalize(() => { this.loader.clearMessage(); })
        )
    }

    public getUsers(filter: string): Observable<any> {
        this.loader.setMessage("Loading...");
        const graphApiUrl = `${this.config.graphApi + filter}&${AppConstants.USERPROPERTYFILTER}`;
        return this.http.get(graphApiUrl).pipe(finalize(() => {
            this.loader.clearMessage();
        }));
    }

    public getGroups(filter: string): Observable<any> {
        this.loader.setMessage("Loading...");
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'ConsistencyLevel': 'eventual'
        }
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        const graphApiUrl = `${this.config.graphApi + filter}`;
        return this.http.get(graphApiUrl, requestOptions).pipe(finalize(() => {
            this.loader.clearMessage();
        }));
    }

    public async awaitPost(url: string, data: any, msg: string = `Saving...`): Promise<any> {
        this.loader.setMessage(msg);
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        const source$ = this.http.post<any>(webApiUrl, data);
        try {
            const result = await lastValueFrom(source$);
            this.loader.clearMessage();
            return result;
        } catch (error) {
            this.loader.clearMessage();
        }
    }

    public async awaitGet(url: string, msg: string = `Saving...`): Promise<any> {
        this.loader.setMessage(msg);
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        const result = await this.http.get<any>(webApiUrl).toPromise();
        this.loader.clearMessage();
        return result;
    }

    public DeleteById(url: string, msg: string = `Loading...`): Observable<any> {
        this.loader.setMessage(msg);
        const webApiUrl = `${this.config.infrastructure.baseApiUrl}/${url}`;
        return this.http.delete<any>(webApiUrl).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            finalize(() => {
                this.loader.clearMessage();
            })
        );
    }

    public ParallelGetAPIs(url: string[], msg: string = `Loading...`): Observable<any> {
        this.loader.setMessage(msg);
        let getUrls: any[] = [];
        url.forEach(x => {
            getUrls.push(this.http.get<any>(`${this.config.infrastructure.baseApiUrl}/${x}`))
        });
        return forkJoin(getUrls).pipe(
            map(
                (result) => result,
                (error: any) => error
            ),
            finalize(() => {
                this.loader.clearMessage();
            })
        );
    }

    getUserId(): Observable<any> {
        const graphApiUrl = `${this.config.graphApi}/?$select=id,givenName,surname,displayName,onPremisesSamAccountName`;
        return this.http.get(graphApiUrl);
    }

    public ParallelPostAPIS(apiData: any[], msg: string = `Loading...`): Observable<any> {
        this.loader.setMessage(msg);
        let postUrls: any[] = [];
        apiData.forEach((res: any) => {
            postUrls.push(this.http.post<any>(`${this.config.infrastructure.baseApiUrl}/${res.url}`, res.body))
        });
        return zip(postUrls).pipe(
            finalize(() => {
                this.loader.clearMessage();
            })
        );
    }

}