"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PressureChangeRate = exports.PressureChangeRateUnits = void 0;
const base_unit_1 = require("../base-unit");
/** PressureChangeRateUnits enumeration */
var PressureChangeRateUnits;
(function (PressureChangeRateUnits) {
  /** */
  PressureChangeRateUnits["PascalsPerSecond"] = "PascalPerSecond";
  /** */
  PressureChangeRateUnits["PascalsPerMinute"] = "PascalPerMinute";
  /** */
  PressureChangeRateUnits["MillimetersOfMercuryPerSecond"] = "MillimeterOfMercuryPerSecond";
  /** */
  PressureChangeRateUnits["AtmospheresPerSecond"] = "AtmospherePerSecond";
  /** */
  PressureChangeRateUnits["PoundsForcePerSquareInchPerSecond"] = "PoundForcePerSquareInchPerSecond";
  /** */
  PressureChangeRateUnits["PoundsForcePerSquareInchPerMinute"] = "PoundForcePerSquareInchPerMinute";
  /** */
  PressureChangeRateUnits["BarsPerSecond"] = "BarPerSecond";
  /** */
  PressureChangeRateUnits["BarsPerMinute"] = "BarPerMinute";
  /** */
  PressureChangeRateUnits["KilopascalsPerSecond"] = "KilopascalPerSecond";
  /** */
  PressureChangeRateUnits["MegapascalsPerSecond"] = "MegapascalPerSecond";
  /** */
  PressureChangeRateUnits["KilopascalsPerMinute"] = "KilopascalPerMinute";
  /** */
  PressureChangeRateUnits["MegapascalsPerMinute"] = "MegapascalPerMinute";
  /** */
  PressureChangeRateUnits["KilopoundsForcePerSquareInchPerSecond"] = "KilopoundForcePerSquareInchPerSecond";
  /** */
  PressureChangeRateUnits["MegapoundsForcePerSquareInchPerSecond"] = "MegapoundForcePerSquareInchPerSecond";
  /** */
  PressureChangeRateUnits["KilopoundsForcePerSquareInchPerMinute"] = "KilopoundForcePerSquareInchPerMinute";
  /** */
  PressureChangeRateUnits["MegapoundsForcePerSquareInchPerMinute"] = "MegapoundForcePerSquareInchPerMinute";
  /** */
  PressureChangeRateUnits["MillibarsPerSecond"] = "MillibarPerSecond";
  /** */
  PressureChangeRateUnits["MillibarsPerMinute"] = "MillibarPerMinute";
})(PressureChangeRateUnits = exports.PressureChangeRateUnits || (exports.PressureChangeRateUnits = {}));
/** Pressure change rate is the ratio of the pressure change to the time during which the change occurred (value of pressure changes per unit time). */
class PressureChangeRate extends base_unit_1.BaseUnit {
  /**
   * Create a new PressureChangeRate.
   * @param value The value.
   * @param fromUnit The ‘PressureChangeRate’ unit to create from.
   * The default unit is PascalsPerSecond
   */
  constructor(value, fromUnit = PressureChangeRateUnits.PascalsPerSecond) {
    super();
    this.pascalspersecondLazy = null;
    this.pascalsperminuteLazy = null;
    this.millimetersofmercurypersecondLazy = null;
    this.atmospherespersecondLazy = null;
    this.poundsforcepersquareinchpersecondLazy = null;
    this.poundsforcepersquareinchperminuteLazy = null;
    this.barspersecondLazy = null;
    this.barsperminuteLazy = null;
    this.kilopascalspersecondLazy = null;
    this.megapascalspersecondLazy = null;
    this.kilopascalsperminuteLazy = null;
    this.megapascalsperminuteLazy = null;
    this.kilopoundsforcepersquareinchpersecondLazy = null;
    this.megapoundsforcepersquareinchpersecondLazy = null;
    this.kilopoundsforcepersquareinchperminuteLazy = null;
    this.megapoundsforcepersquareinchperminuteLazy = null;
    this.millibarspersecondLazy = null;
    this.millibarsperminuteLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of PressureChangeRate is PascalsPerSecond.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return PressureChangeRateUnits.PascalsPerSecond;
  }
  /** */
  get PascalsPerSecond() {
    if (this.pascalspersecondLazy !== null) {
      return this.pascalspersecondLazy;
    }
    return this.pascalspersecondLazy = this.convertFromBase(PressureChangeRateUnits.PascalsPerSecond);
  }
  /** */
  get PascalsPerMinute() {
    if (this.pascalsperminuteLazy !== null) {
      return this.pascalsperminuteLazy;
    }
    return this.pascalsperminuteLazy = this.convertFromBase(PressureChangeRateUnits.PascalsPerMinute);
  }
  /** */
  get MillimetersOfMercuryPerSecond() {
    if (this.millimetersofmercurypersecondLazy !== null) {
      return this.millimetersofmercurypersecondLazy;
    }
    return this.millimetersofmercurypersecondLazy = this.convertFromBase(PressureChangeRateUnits.MillimetersOfMercuryPerSecond);
  }
  /** */
  get AtmospheresPerSecond() {
    if (this.atmospherespersecondLazy !== null) {
      return this.atmospherespersecondLazy;
    }
    return this.atmospherespersecondLazy = this.convertFromBase(PressureChangeRateUnits.AtmospheresPerSecond);
  }
  /** */
  get PoundsForcePerSquareInchPerSecond() {
    if (this.poundsforcepersquareinchpersecondLazy !== null) {
      return this.poundsforcepersquareinchpersecondLazy;
    }
    return this.poundsforcepersquareinchpersecondLazy = this.convertFromBase(PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond);
  }
  /** */
  get PoundsForcePerSquareInchPerMinute() {
    if (this.poundsforcepersquareinchperminuteLazy !== null) {
      return this.poundsforcepersquareinchperminuteLazy;
    }
    return this.poundsforcepersquareinchperminuteLazy = this.convertFromBase(PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute);
  }
  /** */
  get BarsPerSecond() {
    if (this.barspersecondLazy !== null) {
      return this.barspersecondLazy;
    }
    return this.barspersecondLazy = this.convertFromBase(PressureChangeRateUnits.BarsPerSecond);
  }
  /** */
  get BarsPerMinute() {
    if (this.barsperminuteLazy !== null) {
      return this.barsperminuteLazy;
    }
    return this.barsperminuteLazy = this.convertFromBase(PressureChangeRateUnits.BarsPerMinute);
  }
  /** */
  get KilopascalsPerSecond() {
    if (this.kilopascalspersecondLazy !== null) {
      return this.kilopascalspersecondLazy;
    }
    return this.kilopascalspersecondLazy = this.convertFromBase(PressureChangeRateUnits.KilopascalsPerSecond);
  }
  /** */
  get MegapascalsPerSecond() {
    if (this.megapascalspersecondLazy !== null) {
      return this.megapascalspersecondLazy;
    }
    return this.megapascalspersecondLazy = this.convertFromBase(PressureChangeRateUnits.MegapascalsPerSecond);
  }
  /** */
  get KilopascalsPerMinute() {
    if (this.kilopascalsperminuteLazy !== null) {
      return this.kilopascalsperminuteLazy;
    }
    return this.kilopascalsperminuteLazy = this.convertFromBase(PressureChangeRateUnits.KilopascalsPerMinute);
  }
  /** */
  get MegapascalsPerMinute() {
    if (this.megapascalsperminuteLazy !== null) {
      return this.megapascalsperminuteLazy;
    }
    return this.megapascalsperminuteLazy = this.convertFromBase(PressureChangeRateUnits.MegapascalsPerMinute);
  }
  /** */
  get KilopoundsForcePerSquareInchPerSecond() {
    if (this.kilopoundsforcepersquareinchpersecondLazy !== null) {
      return this.kilopoundsforcepersquareinchpersecondLazy;
    }
    return this.kilopoundsforcepersquareinchpersecondLazy = this.convertFromBase(PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond);
  }
  /** */
  get MegapoundsForcePerSquareInchPerSecond() {
    if (this.megapoundsforcepersquareinchpersecondLazy !== null) {
      return this.megapoundsforcepersquareinchpersecondLazy;
    }
    return this.megapoundsforcepersquareinchpersecondLazy = this.convertFromBase(PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond);
  }
  /** */
  get KilopoundsForcePerSquareInchPerMinute() {
    if (this.kilopoundsforcepersquareinchperminuteLazy !== null) {
      return this.kilopoundsforcepersquareinchperminuteLazy;
    }
    return this.kilopoundsforcepersquareinchperminuteLazy = this.convertFromBase(PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute);
  }
  /** */
  get MegapoundsForcePerSquareInchPerMinute() {
    if (this.megapoundsforcepersquareinchperminuteLazy !== null) {
      return this.megapoundsforcepersquareinchperminuteLazy;
    }
    return this.megapoundsforcepersquareinchperminuteLazy = this.convertFromBase(PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute);
  }
  /** */
  get MillibarsPerSecond() {
    if (this.millibarspersecondLazy !== null) {
      return this.millibarspersecondLazy;
    }
    return this.millibarspersecondLazy = this.convertFromBase(PressureChangeRateUnits.MillibarsPerSecond);
  }
  /** */
  get MillibarsPerMinute() {
    if (this.millibarsperminuteLazy !== null) {
      return this.millibarsperminuteLazy;
    }
    return this.millibarsperminuteLazy = this.convertFromBase(PressureChangeRateUnits.MillibarsPerMinute);
  }
  /**
   * Create a new PressureChangeRate instance from a PascalsPerSecond
   *
   * @param value The unit as PascalsPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromPascalsPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.PascalsPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a PascalsPerMinute
   *
   * @param value The unit as PascalsPerMinute to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromPascalsPerMinute(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.PascalsPerMinute);
  }
  /**
   * Create a new PressureChangeRate instance from a MillimetersOfMercuryPerSecond
   *
   * @param value The unit as MillimetersOfMercuryPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromMillimetersOfMercuryPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.MillimetersOfMercuryPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a AtmospheresPerSecond
   *
   * @param value The unit as AtmospheresPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromAtmospheresPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.AtmospheresPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a PoundsForcePerSquareInchPerSecond
   *
   * @param value The unit as PoundsForcePerSquareInchPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromPoundsForcePerSquareInchPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a PoundsForcePerSquareInchPerMinute
   *
   * @param value The unit as PoundsForcePerSquareInchPerMinute to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromPoundsForcePerSquareInchPerMinute(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute);
  }
  /**
   * Create a new PressureChangeRate instance from a BarsPerSecond
   *
   * @param value The unit as BarsPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromBarsPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.BarsPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a BarsPerMinute
   *
   * @param value The unit as BarsPerMinute to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromBarsPerMinute(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.BarsPerMinute);
  }
  /**
   * Create a new PressureChangeRate instance from a KilopascalsPerSecond
   *
   * @param value The unit as KilopascalsPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromKilopascalsPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.KilopascalsPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a MegapascalsPerSecond
   *
   * @param value The unit as MegapascalsPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromMegapascalsPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.MegapascalsPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a KilopascalsPerMinute
   *
   * @param value The unit as KilopascalsPerMinute to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromKilopascalsPerMinute(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.KilopascalsPerMinute);
  }
  /**
   * Create a new PressureChangeRate instance from a MegapascalsPerMinute
   *
   * @param value The unit as MegapascalsPerMinute to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromMegapascalsPerMinute(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.MegapascalsPerMinute);
  }
  /**
   * Create a new PressureChangeRate instance from a KilopoundsForcePerSquareInchPerSecond
   *
   * @param value The unit as KilopoundsForcePerSquareInchPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromKilopoundsForcePerSquareInchPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a MegapoundsForcePerSquareInchPerSecond
   *
   * @param value The unit as MegapoundsForcePerSquareInchPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromMegapoundsForcePerSquareInchPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a KilopoundsForcePerSquareInchPerMinute
   *
   * @param value The unit as KilopoundsForcePerSquareInchPerMinute to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromKilopoundsForcePerSquareInchPerMinute(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute);
  }
  /**
   * Create a new PressureChangeRate instance from a MegapoundsForcePerSquareInchPerMinute
   *
   * @param value The unit as MegapoundsForcePerSquareInchPerMinute to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromMegapoundsForcePerSquareInchPerMinute(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute);
  }
  /**
   * Create a new PressureChangeRate instance from a MillibarsPerSecond
   *
   * @param value The unit as MillibarsPerSecond to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromMillibarsPerSecond(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.MillibarsPerSecond);
  }
  /**
   * Create a new PressureChangeRate instance from a MillibarsPerMinute
   *
   * @param value The unit as MillibarsPerMinute to create a new PressureChangeRate from.
   * @returns The new PressureChangeRate instance.
   */
  static FromMillibarsPerMinute(value) {
    return new PressureChangeRate(value, PressureChangeRateUnits.MillibarsPerMinute);
  }
  /**
   * Gets the base unit enumeration associated with PressureChangeRate
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return PressureChangeRateUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return PressureChangeRateUnits.PascalsPerSecond;
  }
  /**
   * Create API DTO represent a PressureChangeRate unit.
   * @param holdInUnit The specific PressureChangeRate unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = PressureChangeRateUnits.PascalsPerSecond) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a PressureChangeRate unit from an API DTO representation.
   * @param dtoPressureChangeRate The PressureChangeRate API DTO representation
   */
  static FromDto(dtoPressureChangeRate) {
    return new PressureChangeRate(dtoPressureChangeRate.value, dtoPressureChangeRate.unit);
  }
  /**
   * Convert PressureChangeRate to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case PressureChangeRateUnits.PascalsPerSecond:
        return this.PascalsPerSecond;
      case PressureChangeRateUnits.PascalsPerMinute:
        return this.PascalsPerMinute;
      case PressureChangeRateUnits.MillimetersOfMercuryPerSecond:
        return this.MillimetersOfMercuryPerSecond;
      case PressureChangeRateUnits.AtmospheresPerSecond:
        return this.AtmospheresPerSecond;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond:
        return this.PoundsForcePerSquareInchPerSecond;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute:
        return this.PoundsForcePerSquareInchPerMinute;
      case PressureChangeRateUnits.BarsPerSecond:
        return this.BarsPerSecond;
      case PressureChangeRateUnits.BarsPerMinute:
        return this.BarsPerMinute;
      case PressureChangeRateUnits.KilopascalsPerSecond:
        return this.KilopascalsPerSecond;
      case PressureChangeRateUnits.MegapascalsPerSecond:
        return this.MegapascalsPerSecond;
      case PressureChangeRateUnits.KilopascalsPerMinute:
        return this.KilopascalsPerMinute;
      case PressureChangeRateUnits.MegapascalsPerMinute:
        return this.MegapascalsPerMinute;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond:
        return this.KilopoundsForcePerSquareInchPerSecond;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond:
        return this.MegapoundsForcePerSquareInchPerSecond;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute:
        return this.KilopoundsForcePerSquareInchPerMinute;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute:
        return this.MegapoundsForcePerSquareInchPerMinute;
      case PressureChangeRateUnits.MillibarsPerSecond:
        return this.MillibarsPerSecond;
      case PressureChangeRateUnits.MillibarsPerMinute:
        return this.MillibarsPerMinute;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case PressureChangeRateUnits.PascalsPerSecond:
        return this.value;
      case PressureChangeRateUnits.PascalsPerMinute:
        return super.internalMultiply(this.value, 60);
      case PressureChangeRateUnits.MillimetersOfMercuryPerSecond:
        return super.internalDivide(this.value, 133.322);
      case PressureChangeRateUnits.AtmospheresPerSecond:
        {
          const v4 = super.internalMultiply(1.01325, 1e5);
          return super.internalDivide(this.value, v4);
        }
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond:
        return super.internalDivide(this.value, 6.894757293168361e3);
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute:
        {
          const v3 = super.internalDivide(this.value, 6.894757293168361e3);
          return super.internalMultiply(v3, 60);
        }
      case PressureChangeRateUnits.BarsPerSecond:
        return super.internalDivide(this.value, 1e5);
      case PressureChangeRateUnits.BarsPerMinute:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          return super.internalMultiply(v3, 60);
        }
      case PressureChangeRateUnits.KilopascalsPerSecond:
        return super.internalDivide(this.value, 1000);
      case PressureChangeRateUnits.MegapascalsPerSecond:
        return super.internalDivide(this.value, 1000000);
      case PressureChangeRateUnits.KilopascalsPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60);
          return super.internalDivide(v3, 1000);
        }
      case PressureChangeRateUnits.MegapascalsPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60);
          return super.internalDivide(v3, 1000000);
        }
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond:
        {
          const v3 = super.internalDivide(this.value, 6.894757293168361e3);
          return super.internalDivide(v3, 1000);
        }
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond:
        {
          const v3 = super.internalDivide(this.value, 6.894757293168361e3);
          return super.internalDivide(v3, 1000000);
        }
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute:
        {
          const v3 = super.internalDivide(this.value, 6.894757293168361e3);
          const v5 = super.internalMultiply(v3, 60);
          return super.internalDivide(v5, 1000);
        }
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute:
        {
          const v3 = super.internalDivide(this.value, 6.894757293168361e3);
          const v5 = super.internalMultiply(v3, 60);
          return super.internalDivide(v5, 1000000);
        }
      case PressureChangeRateUnits.MillibarsPerSecond:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          return super.internalDivide(v3, 0.001);
        }
      case PressureChangeRateUnits.MillibarsPerMinute:
        {
          const v3 = super.internalDivide(this.value, 1e5);
          const v5 = super.internalMultiply(v3, 60);
          return super.internalDivide(v5, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case PressureChangeRateUnits.PascalsPerSecond:
        return this.value;
      case PressureChangeRateUnits.PascalsPerMinute:
        return this.value * 60;
      case PressureChangeRateUnits.MillimetersOfMercuryPerSecond:
        return this.value / 133.322;
      case PressureChangeRateUnits.AtmospheresPerSecond:
        return this.value / (1.01325 * 1e5);
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond:
        return this.value / 6.894757293168361e3;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute:
        return this.value / 6.894757293168361e3 * 60;
      case PressureChangeRateUnits.BarsPerSecond:
        return this.value / 1e5;
      case PressureChangeRateUnits.BarsPerMinute:
        return this.value / 1e5 * 60;
      case PressureChangeRateUnits.KilopascalsPerSecond:
        return this.value / 1000;
      case PressureChangeRateUnits.MegapascalsPerSecond:
        return this.value / 1000000;
      case PressureChangeRateUnits.KilopascalsPerMinute:
        return this.value * 60 / 1000;
      case PressureChangeRateUnits.MegapascalsPerMinute:
        return this.value * 60 / 1000000;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond:
        return this.value / 6.894757293168361e3 / 1000;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond:
        return this.value / 6.894757293168361e3 / 1000000;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute:
        return this.value / 6.894757293168361e3 * 60 / 1000;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute:
        return this.value / 6.894757293168361e3 * 60 / 1000000;
      case PressureChangeRateUnits.MillibarsPerSecond:
        return this.value / 1e5 / 0.001;
      case PressureChangeRateUnits.MillibarsPerMinute:
        return this.value / 1e5 * 60 / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case PressureChangeRateUnits.PascalsPerSecond:
        return value;
      case PressureChangeRateUnits.PascalsPerMinute:
        return super.internalDivide(value, 60);
      case PressureChangeRateUnits.MillimetersOfMercuryPerSecond:
        return super.internalMultiply(value, 133.322);
      case PressureChangeRateUnits.AtmospheresPerSecond:
        {
          const v3 = super.internalMultiply(value, 1.01325);
          return super.internalMultiply(v3, 1e5);
        }
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond:
        return super.internalMultiply(value, 6.894757293168361e3);
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute:
        {
          const v4 = super.internalDivide(6.894757293168361e3, 60);
          return super.internalMultiply(value, v4);
        }
      case PressureChangeRateUnits.BarsPerSecond:
        return super.internalMultiply(value, 1e5);
      case PressureChangeRateUnits.BarsPerMinute:
        {
          const v4 = super.internalDivide(1e5, 60);
          return super.internalMultiply(value, v4);
        }
      case PressureChangeRateUnits.KilopascalsPerSecond:
        return super.internalMultiply(value, 1000);
      case PressureChangeRateUnits.MegapascalsPerSecond:
        return super.internalMultiply(value, 1000000);
      case PressureChangeRateUnits.KilopascalsPerMinute:
        {
          const v3 = super.internalDivide(value, 60);
          return super.internalMultiply(v3, 1000);
        }
      case PressureChangeRateUnits.MegapascalsPerMinute:
        {
          const v3 = super.internalDivide(value, 60);
          return super.internalMultiply(v3, 1000000);
        }
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond:
        {
          const v3 = super.internalMultiply(value, 6.894757293168361e3);
          return super.internalMultiply(v3, 1000);
        }
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond:
        {
          const v3 = super.internalMultiply(value, 6.894757293168361e3);
          return super.internalMultiply(v3, 1000000);
        }
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute:
        {
          const v4 = super.internalDivide(6.894757293168361e3, 60);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 1000);
        }
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute:
        {
          const v4 = super.internalDivide(6.894757293168361e3, 60);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 1000000);
        }
      case PressureChangeRateUnits.MillibarsPerSecond:
        {
          const v3 = super.internalMultiply(value, 1e5);
          return super.internalMultiply(v3, 0.001);
        }
      case PressureChangeRateUnits.MillibarsPerMinute:
        {
          const v4 = super.internalDivide(1e5, 60);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case PressureChangeRateUnits.PascalsPerSecond:
        return value;
      case PressureChangeRateUnits.PascalsPerMinute:
        return value / 60;
      case PressureChangeRateUnits.MillimetersOfMercuryPerSecond:
        return value * 133.322;
      case PressureChangeRateUnits.AtmospheresPerSecond:
        return value * 1.01325 * 1e5;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond:
        return value * 6.894757293168361e3;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute:
        return value * 6.894757293168361e3 / 60;
      case PressureChangeRateUnits.BarsPerSecond:
        return value * 1e5;
      case PressureChangeRateUnits.BarsPerMinute:
        return value * 1e5 / 60;
      case PressureChangeRateUnits.KilopascalsPerSecond:
        return value * 1000;
      case PressureChangeRateUnits.MegapascalsPerSecond:
        return value * 1000000;
      case PressureChangeRateUnits.KilopascalsPerMinute:
        return value / 60 * 1000;
      case PressureChangeRateUnits.MegapascalsPerMinute:
        return value / 60 * 1000000;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond:
        return value * 6.894757293168361e3 * 1000;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond:
        return value * 6.894757293168361e3 * 1000000;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute:
        return value * 6.894757293168361e3 / 60 * 1000;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute:
        return value * 6.894757293168361e3 / 60 * 1000000;
      case PressureChangeRateUnits.MillibarsPerSecond:
        return value * 1e5 * 0.001;
      case PressureChangeRateUnits.MillibarsPerMinute:
        return value * 1e5 / 60 * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the PressureChangeRate to string.
   * Note! the default format for PressureChangeRate is PascalsPerSecond.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the PressureChangeRate.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the PressureChangeRate.
   */
  toString(unit = PressureChangeRateUnits.PascalsPerSecond, fractionalDigits) {
    switch (unit) {
      case PressureChangeRateUnits.PascalsPerSecond:
        return super.truncateFractionDigits(this.PascalsPerSecond, fractionalDigits) + ` Pa/s`;
      case PressureChangeRateUnits.PascalsPerMinute:
        return super.truncateFractionDigits(this.PascalsPerMinute, fractionalDigits) + ` Pa/min`;
      case PressureChangeRateUnits.MillimetersOfMercuryPerSecond:
        return super.truncateFractionDigits(this.MillimetersOfMercuryPerSecond, fractionalDigits) + ` mmHg/s`;
      case PressureChangeRateUnits.AtmospheresPerSecond:
        return super.truncateFractionDigits(this.AtmospheresPerSecond, fractionalDigits) + ` atm/s`;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond:
        return super.truncateFractionDigits(this.PoundsForcePerSquareInchPerSecond, fractionalDigits) + ` psi/s`;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute:
        return super.truncateFractionDigits(this.PoundsForcePerSquareInchPerMinute, fractionalDigits) + ` psi/min`;
      case PressureChangeRateUnits.BarsPerSecond:
        return super.truncateFractionDigits(this.BarsPerSecond, fractionalDigits) + ` bar/s`;
      case PressureChangeRateUnits.BarsPerMinute:
        return super.truncateFractionDigits(this.BarsPerMinute, fractionalDigits) + ` bar/min`;
      case PressureChangeRateUnits.KilopascalsPerSecond:
        return super.truncateFractionDigits(this.KilopascalsPerSecond, fractionalDigits) + ` kPa/s`;
      case PressureChangeRateUnits.MegapascalsPerSecond:
        return super.truncateFractionDigits(this.MegapascalsPerSecond, fractionalDigits) + ` MPa/s`;
      case PressureChangeRateUnits.KilopascalsPerMinute:
        return super.truncateFractionDigits(this.KilopascalsPerMinute, fractionalDigits) + ` kPa/min`;
      case PressureChangeRateUnits.MegapascalsPerMinute:
        return super.truncateFractionDigits(this.MegapascalsPerMinute, fractionalDigits) + ` MPa/min`;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond:
        return super.truncateFractionDigits(this.KilopoundsForcePerSquareInchPerSecond, fractionalDigits) + ` kpsi/s`;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond:
        return super.truncateFractionDigits(this.MegapoundsForcePerSquareInchPerSecond, fractionalDigits) + ` Mpsi/s`;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute:
        return super.truncateFractionDigits(this.KilopoundsForcePerSquareInchPerMinute, fractionalDigits) + ` kpsi/min`;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute:
        return super.truncateFractionDigits(this.MegapoundsForcePerSquareInchPerMinute, fractionalDigits) + ` Mpsi/min`;
      case PressureChangeRateUnits.MillibarsPerSecond:
        return super.truncateFractionDigits(this.MillibarsPerSecond, fractionalDigits) + ` mbar/s`;
      case PressureChangeRateUnits.MillibarsPerMinute:
        return super.truncateFractionDigits(this.MillibarsPerMinute, fractionalDigits) + ` mbar/min`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get PressureChangeRate unit abbreviation.
   * Note! the default abbreviation for PressureChangeRate is PascalsPerSecond.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the PressureChangeRate.
   * @returns The abbreviation string of PressureChangeRate.
   */
  getUnitAbbreviation(unitAbbreviation = PressureChangeRateUnits.PascalsPerSecond) {
    switch (unitAbbreviation) {
      case PressureChangeRateUnits.PascalsPerSecond:
        return `Pa/s`;
      case PressureChangeRateUnits.PascalsPerMinute:
        return `Pa/min`;
      case PressureChangeRateUnits.MillimetersOfMercuryPerSecond:
        return `mmHg/s`;
      case PressureChangeRateUnits.AtmospheresPerSecond:
        return `atm/s`;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerSecond:
        return `psi/s`;
      case PressureChangeRateUnits.PoundsForcePerSquareInchPerMinute:
        return `psi/min`;
      case PressureChangeRateUnits.BarsPerSecond:
        return `bar/s`;
      case PressureChangeRateUnits.BarsPerMinute:
        return `bar/min`;
      case PressureChangeRateUnits.KilopascalsPerSecond:
        return `kPa/s`;
      case PressureChangeRateUnits.MegapascalsPerSecond:
        return `MPa/s`;
      case PressureChangeRateUnits.KilopascalsPerMinute:
        return `kPa/min`;
      case PressureChangeRateUnits.MegapascalsPerMinute:
        return `MPa/min`;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerSecond:
        return `kpsi/s`;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerSecond:
        return `Mpsi/s`;
      case PressureChangeRateUnits.KilopoundsForcePerSquareInchPerMinute:
        return `kpsi/min`;
      case PressureChangeRateUnits.MegapoundsForcePerSquareInchPerMinute:
        return `Mpsi/min`;
      case PressureChangeRateUnits.MillibarsPerSecond:
        return `mbar/s`;
      case PressureChangeRateUnits.MillibarsPerMinute:
        return `mbar/min`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given PressureChangeRate are equals to the current PressureChangeRate.
   * @param pressureChangeRate The other PressureChangeRate.
   * @returns True if the given PressureChangeRate are equal to the current PressureChangeRate.
   */
  equals(pressureChangeRate) {
    return super.internalEquals(this.value, pressureChangeRate.BaseValue);
  }
  /**
   * Compare the given PressureChangeRate against the current PressureChangeRate.
   * @param pressureChangeRate The other PressureChangeRate.
   * @returns 0 if they are equal, -1 if the current PressureChangeRate is less then other, 1 if the current PressureChangeRate is greater then other.
   */
  compareTo(pressureChangeRate) {
    return super.internalCompareTo(this.value, pressureChangeRate.BaseValue);
  }
  /**
   * Add the given PressureChangeRate with the current PressureChangeRate.
   * @param pressureChangeRate The other PressureChangeRate.
   * @returns A new PressureChangeRate instance with the results.
   */
  add(pressureChangeRate) {
    return new PressureChangeRate(super.internalAdd(this.value, pressureChangeRate.BaseValue));
  }
  /**
   * Subtract the given PressureChangeRate with the current PressureChangeRate.
   * @param pressureChangeRate The other PressureChangeRate.
   * @returns A new PressureChangeRate instance with the results.
   */
  subtract(pressureChangeRate) {
    return new PressureChangeRate(super.internalSubtract(this.value, pressureChangeRate.BaseValue));
  }
  /**
   * Multiply the given PressureChangeRate with the current PressureChangeRate.
   * @param pressureChangeRate The other PressureChangeRate.
   * @returns A new PressureChangeRate instance with the results.
   */
  multiply(pressureChangeRate) {
    return new PressureChangeRate(super.internalMultiply(this.value, pressureChangeRate.BaseValue));
  }
  /**
   * Divide the given PressureChangeRate with the current PressureChangeRate.
   * @param pressureChangeRate The other PressureChangeRate.
   * @returns A new PressureChangeRate instance with the results.
   */
  divide(pressureChangeRate) {
    return new PressureChangeRate(super.internalDivide(this.value, pressureChangeRate.BaseValue));
  }
  /**
   * Modulo the given PressureChangeRate with the current PressureChangeRate.
   * @param pressureChangeRate The other PressureChangeRate.
   * @returns A new PressureChangeRate instance with the results.
   */
  modulo(pressureChangeRate) {
    return new PressureChangeRate(super.internalModulo(this.value, pressureChangeRate.BaseValue));
  }
  /**
   * Pow the given PressureChangeRate with the current PressureChangeRate.
   * @param pressureChangeRate The other PressureChangeRate.
   * @returns A new PressureChangeRate instance with the results.
   */
  pow(pressureChangeRate) {
    return new PressureChangeRate(super.internalPow(this.value, pressureChangeRate.BaseValue));
  }
}
exports.PressureChangeRate = PressureChangeRate;