import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AppConstants } from '../../constants';
import { SharedService } from '../../../services';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrl: './custom-button.component.scss'
})
export class CustomButtonComponent implements ICellRendererAngularComp {
  btnName: string = '0';
  isDisabled: boolean = false;
  pdfTooltip: string = "Recipe PDF"
  params: any;
  isAdmin: boolean;
  title: string = 'Edit Recipe';
  viewRecipe: string = '/assets/icons/Edit.png';

  constructor(private sharedSvc: SharedService) {

  }

  ngOnInit() {
    this.isAdmin = this.sharedSvc.hasAdmin();
    this.title = this.isAdmin ? 'Edit Recipe' : 'View Recipe';
    this.viewRecipe = this.isAdmin ? '/assets/icons/Edit.png' : '/assets/icons/View.png';
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.data.treatingOrganization && this.params.data.treatingOrganization === AppConstants.Competitive) {
      this.isDisabled = true;
      this.pdfTooltip = "Recipe PDF view is not avaliable for competitive recipe"
    }
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  public editRecipe() {
    this.params.context.componentParent.editRecipe(this.params.data.recipeId);
  }

  public viewHistory() {
    this.params.context.componentParent.auditHistory(this.params.data.recipeId);
  }

  public viewPDF() {
    this.params.context.componentParent.viewRecipeDetailsModal(this.params.data.recipeId);
  }


}
