"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricAdmittance = exports.ElectricAdmittanceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricAdmittanceUnits enumeration */
var ElectricAdmittanceUnits;
(function (ElectricAdmittanceUnits) {
  /** */
  ElectricAdmittanceUnits["Siemens"] = "Siemens";
  /** */
  ElectricAdmittanceUnits["Nanosiemens"] = "Nanosiemens";
  /** */
  ElectricAdmittanceUnits["Microsiemens"] = "Microsiemens";
  /** */
  ElectricAdmittanceUnits["Millisiemens"] = "Millisiemens";
})(ElectricAdmittanceUnits = exports.ElectricAdmittanceUnits || (exports.ElectricAdmittanceUnits = {}));
/** Electric admittance is a measure of how easily a circuit or device will allow a current to flow. It is defined as the inverse of impedance. The SI unit of admittance is the siemens (symbol S). */
class ElectricAdmittance extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricAdmittance.
   * @param value The value.
   * @param fromUnit The ‘ElectricAdmittance’ unit to create from.
   * The default unit is Siemens
   */
  constructor(value, fromUnit = ElectricAdmittanceUnits.Siemens) {
    super();
    this.siemensLazy = null;
    this.nanosiemensLazy = null;
    this.microsiemensLazy = null;
    this.millisiemensLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricAdmittance is Siemens.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricAdmittanceUnits.Siemens;
  }
  /** */
  get Siemens() {
    if (this.siemensLazy !== null) {
      return this.siemensLazy;
    }
    return this.siemensLazy = this.convertFromBase(ElectricAdmittanceUnits.Siemens);
  }
  /** */
  get Nanosiemens() {
    if (this.nanosiemensLazy !== null) {
      return this.nanosiemensLazy;
    }
    return this.nanosiemensLazy = this.convertFromBase(ElectricAdmittanceUnits.Nanosiemens);
  }
  /** */
  get Microsiemens() {
    if (this.microsiemensLazy !== null) {
      return this.microsiemensLazy;
    }
    return this.microsiemensLazy = this.convertFromBase(ElectricAdmittanceUnits.Microsiemens);
  }
  /** */
  get Millisiemens() {
    if (this.millisiemensLazy !== null) {
      return this.millisiemensLazy;
    }
    return this.millisiemensLazy = this.convertFromBase(ElectricAdmittanceUnits.Millisiemens);
  }
  /**
   * Create a new ElectricAdmittance instance from a Siemens
   *
   * @param value The unit as Siemens to create a new ElectricAdmittance from.
   * @returns The new ElectricAdmittance instance.
   */
  static FromSiemens(value) {
    return new ElectricAdmittance(value, ElectricAdmittanceUnits.Siemens);
  }
  /**
   * Create a new ElectricAdmittance instance from a Nanosiemens
   *
   * @param value The unit as Nanosiemens to create a new ElectricAdmittance from.
   * @returns The new ElectricAdmittance instance.
   */
  static FromNanosiemens(value) {
    return new ElectricAdmittance(value, ElectricAdmittanceUnits.Nanosiemens);
  }
  /**
   * Create a new ElectricAdmittance instance from a Microsiemens
   *
   * @param value The unit as Microsiemens to create a new ElectricAdmittance from.
   * @returns The new ElectricAdmittance instance.
   */
  static FromMicrosiemens(value) {
    return new ElectricAdmittance(value, ElectricAdmittanceUnits.Microsiemens);
  }
  /**
   * Create a new ElectricAdmittance instance from a Millisiemens
   *
   * @param value The unit as Millisiemens to create a new ElectricAdmittance from.
   * @returns The new ElectricAdmittance instance.
   */
  static FromMillisiemens(value) {
    return new ElectricAdmittance(value, ElectricAdmittanceUnits.Millisiemens);
  }
  /**
   * Gets the base unit enumeration associated with ElectricAdmittance
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricAdmittanceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricAdmittanceUnits.Siemens;
  }
  /**
   * Create API DTO represent a ElectricAdmittance unit.
   * @param holdInUnit The specific ElectricAdmittance unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricAdmittanceUnits.Siemens) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricAdmittance unit from an API DTO representation.
   * @param dtoElectricAdmittance The ElectricAdmittance API DTO representation
   */
  static FromDto(dtoElectricAdmittance) {
    return new ElectricAdmittance(dtoElectricAdmittance.value, dtoElectricAdmittance.unit);
  }
  /**
   * Convert ElectricAdmittance to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricAdmittanceUnits.Siemens:
        return this.Siemens;
      case ElectricAdmittanceUnits.Nanosiemens:
        return this.Nanosiemens;
      case ElectricAdmittanceUnits.Microsiemens:
        return this.Microsiemens;
      case ElectricAdmittanceUnits.Millisiemens:
        return this.Millisiemens;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricAdmittanceUnits.Siemens:
        return this.value;
      case ElectricAdmittanceUnits.Nanosiemens:
        return super.internalDivide(this.value, 1e-9);
      case ElectricAdmittanceUnits.Microsiemens:
        return super.internalDivide(this.value, 0.000001);
      case ElectricAdmittanceUnits.Millisiemens:
        return super.internalDivide(this.value, 0.001);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricAdmittanceUnits.Siemens:
        return this.value;
      case ElectricAdmittanceUnits.Nanosiemens:
        return this.value / 1e-9;
      case ElectricAdmittanceUnits.Microsiemens:
        return this.value / 0.000001;
      case ElectricAdmittanceUnits.Millisiemens:
        return this.value / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricAdmittanceUnits.Siemens:
        return value;
      case ElectricAdmittanceUnits.Nanosiemens:
        return super.internalMultiply(value, 1e-9);
      case ElectricAdmittanceUnits.Microsiemens:
        return super.internalMultiply(value, 0.000001);
      case ElectricAdmittanceUnits.Millisiemens:
        return super.internalMultiply(value, 0.001);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricAdmittanceUnits.Siemens:
        return value;
      case ElectricAdmittanceUnits.Nanosiemens:
        return value * 1e-9;
      case ElectricAdmittanceUnits.Microsiemens:
        return value * 0.000001;
      case ElectricAdmittanceUnits.Millisiemens:
        return value * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricAdmittance to string.
   * Note! the default format for ElectricAdmittance is Siemens.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricAdmittance.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricAdmittance.
   */
  toString(unit = ElectricAdmittanceUnits.Siemens, fractionalDigits) {
    switch (unit) {
      case ElectricAdmittanceUnits.Siemens:
        return super.truncateFractionDigits(this.Siemens, fractionalDigits) + ` S`;
      case ElectricAdmittanceUnits.Nanosiemens:
        return super.truncateFractionDigits(this.Nanosiemens, fractionalDigits) + ` nS`;
      case ElectricAdmittanceUnits.Microsiemens:
        return super.truncateFractionDigits(this.Microsiemens, fractionalDigits) + ` μS`;
      case ElectricAdmittanceUnits.Millisiemens:
        return super.truncateFractionDigits(this.Millisiemens, fractionalDigits) + ` mS`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricAdmittance unit abbreviation.
   * Note! the default abbreviation for ElectricAdmittance is Siemens.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricAdmittance.
   * @returns The abbreviation string of ElectricAdmittance.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricAdmittanceUnits.Siemens) {
    switch (unitAbbreviation) {
      case ElectricAdmittanceUnits.Siemens:
        return `S`;
      case ElectricAdmittanceUnits.Nanosiemens:
        return `nS`;
      case ElectricAdmittanceUnits.Microsiemens:
        return `μS`;
      case ElectricAdmittanceUnits.Millisiemens:
        return `mS`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricAdmittance are equals to the current ElectricAdmittance.
   * @param electricAdmittance The other ElectricAdmittance.
   * @returns True if the given ElectricAdmittance are equal to the current ElectricAdmittance.
   */
  equals(electricAdmittance) {
    return super.internalEquals(this.value, electricAdmittance.BaseValue);
  }
  /**
   * Compare the given ElectricAdmittance against the current ElectricAdmittance.
   * @param electricAdmittance The other ElectricAdmittance.
   * @returns 0 if they are equal, -1 if the current ElectricAdmittance is less then other, 1 if the current ElectricAdmittance is greater then other.
   */
  compareTo(electricAdmittance) {
    return super.internalCompareTo(this.value, electricAdmittance.BaseValue);
  }
  /**
   * Add the given ElectricAdmittance with the current ElectricAdmittance.
   * @param electricAdmittance The other ElectricAdmittance.
   * @returns A new ElectricAdmittance instance with the results.
   */
  add(electricAdmittance) {
    return new ElectricAdmittance(super.internalAdd(this.value, electricAdmittance.BaseValue));
  }
  /**
   * Subtract the given ElectricAdmittance with the current ElectricAdmittance.
   * @param electricAdmittance The other ElectricAdmittance.
   * @returns A new ElectricAdmittance instance with the results.
   */
  subtract(electricAdmittance) {
    return new ElectricAdmittance(super.internalSubtract(this.value, electricAdmittance.BaseValue));
  }
  /**
   * Multiply the given ElectricAdmittance with the current ElectricAdmittance.
   * @param electricAdmittance The other ElectricAdmittance.
   * @returns A new ElectricAdmittance instance with the results.
   */
  multiply(electricAdmittance) {
    return new ElectricAdmittance(super.internalMultiply(this.value, electricAdmittance.BaseValue));
  }
  /**
   * Divide the given ElectricAdmittance with the current ElectricAdmittance.
   * @param electricAdmittance The other ElectricAdmittance.
   * @returns A new ElectricAdmittance instance with the results.
   */
  divide(electricAdmittance) {
    return new ElectricAdmittance(super.internalDivide(this.value, electricAdmittance.BaseValue));
  }
  /**
   * Modulo the given ElectricAdmittance with the current ElectricAdmittance.
   * @param electricAdmittance The other ElectricAdmittance.
   * @returns A new ElectricAdmittance instance with the results.
   */
  modulo(electricAdmittance) {
    return new ElectricAdmittance(super.internalModulo(this.value, electricAdmittance.BaseValue));
  }
  /**
   * Pow the given ElectricAdmittance with the current ElectricAdmittance.
   * @param electricAdmittance The other ElectricAdmittance.
   * @returns A new ElectricAdmittance instance with the results.
   */
  pow(electricAdmittance) {
    return new ElectricAdmittance(super.internalPow(this.value, electricAdmittance.BaseValue));
  }
}
exports.ElectricAdmittance = ElectricAdmittance;