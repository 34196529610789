"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Duration = exports.DurationUnits = void 0;
const base_unit_1 = require("../base-unit");
/** DurationUnits enumeration */
var DurationUnits;
(function (DurationUnits) {
  /** */
  DurationUnits["Years365"] = "Year365";
  /** */
  DurationUnits["Months30"] = "Month30";
  /** */
  DurationUnits["Weeks"] = "Week";
  /** */
  DurationUnits["Days"] = "Day";
  /** */
  DurationUnits["Hours"] = "Hour";
  /** */
  DurationUnits["Minutes"] = "Minute";
  /** */
  DurationUnits["Seconds"] = "Second";
  /** */
  DurationUnits["JulianYears"] = "JulianYear";
  /** */
  DurationUnits["Sols"] = "Sol";
  /** */
  DurationUnits["Nanoseconds"] = "Nanosecond";
  /** */
  DurationUnits["Microseconds"] = "Microsecond";
  /** */
  DurationUnits["Milliseconds"] = "Millisecond";
})(DurationUnits = exports.DurationUnits || (exports.DurationUnits = {}));
/** Time is a dimension in which events can be ordered from the past through the present into the future, and also the measure of durations of events and the intervals between them. */
class Duration extends base_unit_1.BaseUnit {
  /**
   * Create a new Duration.
   * @param value The value.
   * @param fromUnit The ‘Duration’ unit to create from.
   * The default unit is Seconds
   */
  constructor(value, fromUnit = DurationUnits.Seconds) {
    super();
    this.years365Lazy = null;
    this.months30Lazy = null;
    this.weeksLazy = null;
    this.daysLazy = null;
    this.hoursLazy = null;
    this.minutesLazy = null;
    this.secondsLazy = null;
    this.julianyearsLazy = null;
    this.solsLazy = null;
    this.nanosecondsLazy = null;
    this.microsecondsLazy = null;
    this.millisecondsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Duration is Seconds.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return DurationUnits.Seconds;
  }
  /** */
  get Years365() {
    if (this.years365Lazy !== null) {
      return this.years365Lazy;
    }
    return this.years365Lazy = this.convertFromBase(DurationUnits.Years365);
  }
  /** */
  get Months30() {
    if (this.months30Lazy !== null) {
      return this.months30Lazy;
    }
    return this.months30Lazy = this.convertFromBase(DurationUnits.Months30);
  }
  /** */
  get Weeks() {
    if (this.weeksLazy !== null) {
      return this.weeksLazy;
    }
    return this.weeksLazy = this.convertFromBase(DurationUnits.Weeks);
  }
  /** */
  get Days() {
    if (this.daysLazy !== null) {
      return this.daysLazy;
    }
    return this.daysLazy = this.convertFromBase(DurationUnits.Days);
  }
  /** */
  get Hours() {
    if (this.hoursLazy !== null) {
      return this.hoursLazy;
    }
    return this.hoursLazy = this.convertFromBase(DurationUnits.Hours);
  }
  /** */
  get Minutes() {
    if (this.minutesLazy !== null) {
      return this.minutesLazy;
    }
    return this.minutesLazy = this.convertFromBase(DurationUnits.Minutes);
  }
  /** */
  get Seconds() {
    if (this.secondsLazy !== null) {
      return this.secondsLazy;
    }
    return this.secondsLazy = this.convertFromBase(DurationUnits.Seconds);
  }
  /** */
  get JulianYears() {
    if (this.julianyearsLazy !== null) {
      return this.julianyearsLazy;
    }
    return this.julianyearsLazy = this.convertFromBase(DurationUnits.JulianYears);
  }
  /** */
  get Sols() {
    if (this.solsLazy !== null) {
      return this.solsLazy;
    }
    return this.solsLazy = this.convertFromBase(DurationUnits.Sols);
  }
  /** */
  get Nanoseconds() {
    if (this.nanosecondsLazy !== null) {
      return this.nanosecondsLazy;
    }
    return this.nanosecondsLazy = this.convertFromBase(DurationUnits.Nanoseconds);
  }
  /** */
  get Microseconds() {
    if (this.microsecondsLazy !== null) {
      return this.microsecondsLazy;
    }
    return this.microsecondsLazy = this.convertFromBase(DurationUnits.Microseconds);
  }
  /** */
  get Milliseconds() {
    if (this.millisecondsLazy !== null) {
      return this.millisecondsLazy;
    }
    return this.millisecondsLazy = this.convertFromBase(DurationUnits.Milliseconds);
  }
  /**
   * Create a new Duration instance from a Years365
   *
   * @param value The unit as Years365 to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromYears365(value) {
    return new Duration(value, DurationUnits.Years365);
  }
  /**
   * Create a new Duration instance from a Months30
   *
   * @param value The unit as Months30 to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromMonths30(value) {
    return new Duration(value, DurationUnits.Months30);
  }
  /**
   * Create a new Duration instance from a Weeks
   *
   * @param value The unit as Weeks to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromWeeks(value) {
    return new Duration(value, DurationUnits.Weeks);
  }
  /**
   * Create a new Duration instance from a Days
   *
   * @param value The unit as Days to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromDays(value) {
    return new Duration(value, DurationUnits.Days);
  }
  /**
   * Create a new Duration instance from a Hours
   *
   * @param value The unit as Hours to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromHours(value) {
    return new Duration(value, DurationUnits.Hours);
  }
  /**
   * Create a new Duration instance from a Minutes
   *
   * @param value The unit as Minutes to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromMinutes(value) {
    return new Duration(value, DurationUnits.Minutes);
  }
  /**
   * Create a new Duration instance from a Seconds
   *
   * @param value The unit as Seconds to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromSeconds(value) {
    return new Duration(value, DurationUnits.Seconds);
  }
  /**
   * Create a new Duration instance from a JulianYears
   *
   * @param value The unit as JulianYears to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromJulianYears(value) {
    return new Duration(value, DurationUnits.JulianYears);
  }
  /**
   * Create a new Duration instance from a Sols
   *
   * @param value The unit as Sols to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromSols(value) {
    return new Duration(value, DurationUnits.Sols);
  }
  /**
   * Create a new Duration instance from a Nanoseconds
   *
   * @param value The unit as Nanoseconds to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromNanoseconds(value) {
    return new Duration(value, DurationUnits.Nanoseconds);
  }
  /**
   * Create a new Duration instance from a Microseconds
   *
   * @param value The unit as Microseconds to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromMicroseconds(value) {
    return new Duration(value, DurationUnits.Microseconds);
  }
  /**
   * Create a new Duration instance from a Milliseconds
   *
   * @param value The unit as Milliseconds to create a new Duration from.
   * @returns The new Duration instance.
   */
  static FromMilliseconds(value) {
    return new Duration(value, DurationUnits.Milliseconds);
  }
  /**
   * Gets the base unit enumeration associated with Duration
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return DurationUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return DurationUnits.Seconds;
  }
  /**
   * Create API DTO represent a Duration unit.
   * @param holdInUnit The specific Duration unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = DurationUnits.Seconds) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Duration unit from an API DTO representation.
   * @param dtoDuration The Duration API DTO representation
   */
  static FromDto(dtoDuration) {
    return new Duration(dtoDuration.value, dtoDuration.unit);
  }
  /**
   * Convert Duration to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case DurationUnits.Years365:
        return this.Years365;
      case DurationUnits.Months30:
        return this.Months30;
      case DurationUnits.Weeks:
        return this.Weeks;
      case DurationUnits.Days:
        return this.Days;
      case DurationUnits.Hours:
        return this.Hours;
      case DurationUnits.Minutes:
        return this.Minutes;
      case DurationUnits.Seconds:
        return this.Seconds;
      case DurationUnits.JulianYears:
        return this.JulianYears;
      case DurationUnits.Sols:
        return this.Sols;
      case DurationUnits.Nanoseconds:
        return this.Nanoseconds;
      case DurationUnits.Microseconds:
        return this.Microseconds;
      case DurationUnits.Milliseconds:
        return this.Milliseconds;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case DurationUnits.Years365:
        {
          const v4 = super.internalMultiply(365, 24);
          const v6 = super.internalMultiply(v4, 3600);
          return super.internalDivide(this.value, v6);
        }
      case DurationUnits.Months30:
        {
          const v4 = super.internalMultiply(30, 24);
          const v6 = super.internalMultiply(v4, 3600);
          return super.internalDivide(this.value, v6);
        }
      case DurationUnits.Weeks:
        {
          const v4 = super.internalMultiply(7, 24);
          const v6 = super.internalMultiply(v4, 3600);
          return super.internalDivide(this.value, v6);
        }
      case DurationUnits.Days:
        {
          const v4 = super.internalMultiply(24, 3600);
          return super.internalDivide(this.value, v4);
        }
      case DurationUnits.Hours:
        return super.internalDivide(this.value, 3600);
      case DurationUnits.Minutes:
        return super.internalDivide(this.value, 60);
      case DurationUnits.Seconds:
        return this.value;
      case DurationUnits.JulianYears:
        {
          const v4 = super.internalMultiply(365.25, 24);
          const v6 = super.internalMultiply(v4, 3600);
          return super.internalDivide(this.value, v6);
        }
      case DurationUnits.Sols:
        return super.internalDivide(this.value, 88775.244);
      case DurationUnits.Nanoseconds:
        return super.internalDivide(this.value, 1e-9);
      case DurationUnits.Microseconds:
        return super.internalDivide(this.value, 0.000001);
      case DurationUnits.Milliseconds:
        return super.internalDivide(this.value, 0.001);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case DurationUnits.Years365:
        return this.value / (365 * 24 * 3600);
      case DurationUnits.Months30:
        return this.value / (30 * 24 * 3600);
      case DurationUnits.Weeks:
        return this.value / (7 * 24 * 3600);
      case DurationUnits.Days:
        return this.value / (24 * 3600);
      case DurationUnits.Hours:
        return this.value / 3600;
      case DurationUnits.Minutes:
        return this.value / 60;
      case DurationUnits.Seconds:
        return this.value;
      case DurationUnits.JulianYears:
        return this.value / (365.25 * 24 * 3600);
      case DurationUnits.Sols:
        return this.value / 88775.244;
      case DurationUnits.Nanoseconds:
        return this.value / 1e-9;
      case DurationUnits.Microseconds:
        return this.value / 0.000001;
      case DurationUnits.Milliseconds:
        return this.value / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case DurationUnits.Years365:
        {
          const v3 = super.internalMultiply(value, 365);
          const v5 = super.internalMultiply(v3, 24);
          return super.internalMultiply(v5, 3600);
        }
      case DurationUnits.Months30:
        {
          const v3 = super.internalMultiply(value, 30);
          const v5 = super.internalMultiply(v3, 24);
          return super.internalMultiply(v5, 3600);
        }
      case DurationUnits.Weeks:
        {
          const v3 = super.internalMultiply(value, 7);
          const v5 = super.internalMultiply(v3, 24);
          return super.internalMultiply(v5, 3600);
        }
      case DurationUnits.Days:
        {
          const v3 = super.internalMultiply(value, 24);
          return super.internalMultiply(v3, 3600);
        }
      case DurationUnits.Hours:
        return super.internalMultiply(value, 3600);
      case DurationUnits.Minutes:
        return super.internalMultiply(value, 60);
      case DurationUnits.Seconds:
        return value;
      case DurationUnits.JulianYears:
        {
          const v3 = super.internalMultiply(value, 365.25);
          const v5 = super.internalMultiply(v3, 24);
          return super.internalMultiply(v5, 3600);
        }
      case DurationUnits.Sols:
        return super.internalMultiply(value, 88775.244);
      case DurationUnits.Nanoseconds:
        return super.internalMultiply(value, 1e-9);
      case DurationUnits.Microseconds:
        return super.internalMultiply(value, 0.000001);
      case DurationUnits.Milliseconds:
        return super.internalMultiply(value, 0.001);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case DurationUnits.Years365:
        return value * 365 * 24 * 3600;
      case DurationUnits.Months30:
        return value * 30 * 24 * 3600;
      case DurationUnits.Weeks:
        return value * 7 * 24 * 3600;
      case DurationUnits.Days:
        return value * 24 * 3600;
      case DurationUnits.Hours:
        return value * 3600;
      case DurationUnits.Minutes:
        return value * 60;
      case DurationUnits.Seconds:
        return value;
      case DurationUnits.JulianYears:
        return value * 365.25 * 24 * 3600;
      case DurationUnits.Sols:
        return value * 88775.244;
      case DurationUnits.Nanoseconds:
        return value * 1e-9;
      case DurationUnits.Microseconds:
        return value * 0.000001;
      case DurationUnits.Milliseconds:
        return value * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Duration to string.
   * Note! the default format for Duration is Seconds.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Duration.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Duration.
   */
  toString(unit = DurationUnits.Seconds, fractionalDigits) {
    switch (unit) {
      case DurationUnits.Years365:
        return super.truncateFractionDigits(this.Years365, fractionalDigits) + ` yr`;
      case DurationUnits.Months30:
        return super.truncateFractionDigits(this.Months30, fractionalDigits) + ` mo`;
      case DurationUnits.Weeks:
        return super.truncateFractionDigits(this.Weeks, fractionalDigits) + ` wk`;
      case DurationUnits.Days:
        return super.truncateFractionDigits(this.Days, fractionalDigits) + ` d`;
      case DurationUnits.Hours:
        return super.truncateFractionDigits(this.Hours, fractionalDigits) + ` h`;
      case DurationUnits.Minutes:
        return super.truncateFractionDigits(this.Minutes, fractionalDigits) + ` m`;
      case DurationUnits.Seconds:
        return super.truncateFractionDigits(this.Seconds, fractionalDigits) + ` s`;
      case DurationUnits.JulianYears:
        return super.truncateFractionDigits(this.JulianYears, fractionalDigits) + ` jyr`;
      case DurationUnits.Sols:
        return super.truncateFractionDigits(this.Sols, fractionalDigits) + ` sol`;
      case DurationUnits.Nanoseconds:
        return super.truncateFractionDigits(this.Nanoseconds, fractionalDigits) + ` ns`;
      case DurationUnits.Microseconds:
        return super.truncateFractionDigits(this.Microseconds, fractionalDigits) + ` μs`;
      case DurationUnits.Milliseconds:
        return super.truncateFractionDigits(this.Milliseconds, fractionalDigits) + ` ms`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Duration unit abbreviation.
   * Note! the default abbreviation for Duration is Seconds.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Duration.
   * @returns The abbreviation string of Duration.
   */
  getUnitAbbreviation(unitAbbreviation = DurationUnits.Seconds) {
    switch (unitAbbreviation) {
      case DurationUnits.Years365:
        return `yr`;
      case DurationUnits.Months30:
        return `mo`;
      case DurationUnits.Weeks:
        return `wk`;
      case DurationUnits.Days:
        return `d`;
      case DurationUnits.Hours:
        return `h`;
      case DurationUnits.Minutes:
        return `m`;
      case DurationUnits.Seconds:
        return `s`;
      case DurationUnits.JulianYears:
        return `jyr`;
      case DurationUnits.Sols:
        return `sol`;
      case DurationUnits.Nanoseconds:
        return `ns`;
      case DurationUnits.Microseconds:
        return `μs`;
      case DurationUnits.Milliseconds:
        return `ms`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Duration are equals to the current Duration.
   * @param duration The other Duration.
   * @returns True if the given Duration are equal to the current Duration.
   */
  equals(duration) {
    return super.internalEquals(this.value, duration.BaseValue);
  }
  /**
   * Compare the given Duration against the current Duration.
   * @param duration The other Duration.
   * @returns 0 if they are equal, -1 if the current Duration is less then other, 1 if the current Duration is greater then other.
   */
  compareTo(duration) {
    return super.internalCompareTo(this.value, duration.BaseValue);
  }
  /**
   * Add the given Duration with the current Duration.
   * @param duration The other Duration.
   * @returns A new Duration instance with the results.
   */
  add(duration) {
    return new Duration(super.internalAdd(this.value, duration.BaseValue));
  }
  /**
   * Subtract the given Duration with the current Duration.
   * @param duration The other Duration.
   * @returns A new Duration instance with the results.
   */
  subtract(duration) {
    return new Duration(super.internalSubtract(this.value, duration.BaseValue));
  }
  /**
   * Multiply the given Duration with the current Duration.
   * @param duration The other Duration.
   * @returns A new Duration instance with the results.
   */
  multiply(duration) {
    return new Duration(super.internalMultiply(this.value, duration.BaseValue));
  }
  /**
   * Divide the given Duration with the current Duration.
   * @param duration The other Duration.
   * @returns A new Duration instance with the results.
   */
  divide(duration) {
    return new Duration(super.internalDivide(this.value, duration.BaseValue));
  }
  /**
   * Modulo the given Duration with the current Duration.
   * @param duration The other Duration.
   * @returns A new Duration instance with the results.
   */
  modulo(duration) {
    return new Duration(super.internalModulo(this.value, duration.BaseValue));
  }
  /**
   * Pow the given Duration with the current Duration.
   * @param duration The other Duration.
   * @returns A new Duration instance with the results.
   */
  pow(duration) {
    return new Duration(super.internalPow(this.value, duration.BaseValue));
  }
}
exports.Duration = Duration;