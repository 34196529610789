"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Frequency = exports.FrequencyUnits = void 0;
const base_unit_1 = require("../base-unit");
/** FrequencyUnits enumeration */
var FrequencyUnits;
(function (FrequencyUnits) {
  /** */
  FrequencyUnits["Hertz"] = "Hertz";
  /** */
  FrequencyUnits["RadiansPerSecond"] = "RadianPerSecond";
  /** */
  FrequencyUnits["CyclesPerMinute"] = "CyclePerMinute";
  /** */
  FrequencyUnits["CyclesPerHour"] = "CyclePerHour";
  /** */
  FrequencyUnits["BeatsPerMinute"] = "BeatPerMinute";
  /** */
  FrequencyUnits["PerSecond"] = "PerSecond";
  /** */
  FrequencyUnits["BUnits"] = "BUnit";
  /** */
  FrequencyUnits["Microhertz"] = "Microhertz";
  /** */
  FrequencyUnits["Millihertz"] = "Millihertz";
  /** */
  FrequencyUnits["Kilohertz"] = "Kilohertz";
  /** */
  FrequencyUnits["Megahertz"] = "Megahertz";
  /** */
  FrequencyUnits["Gigahertz"] = "Gigahertz";
  /** */
  FrequencyUnits["Terahertz"] = "Terahertz";
})(FrequencyUnits = exports.FrequencyUnits || (exports.FrequencyUnits = {}));
/** The number of occurrences of a repeating event per unit time. */
class Frequency extends base_unit_1.BaseUnit {
  /**
   * Create a new Frequency.
   * @param value The value.
   * @param fromUnit The ‘Frequency’ unit to create from.
   * The default unit is Hertz
   */
  constructor(value, fromUnit = FrequencyUnits.Hertz) {
    super();
    this.hertzLazy = null;
    this.radianspersecondLazy = null;
    this.cyclesperminuteLazy = null;
    this.cyclesperhourLazy = null;
    this.beatsperminuteLazy = null;
    this.persecondLazy = null;
    this.bunitsLazy = null;
    this.microhertzLazy = null;
    this.millihertzLazy = null;
    this.kilohertzLazy = null;
    this.megahertzLazy = null;
    this.gigahertzLazy = null;
    this.terahertzLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Frequency is Hertz.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return FrequencyUnits.Hertz;
  }
  /** */
  get Hertz() {
    if (this.hertzLazy !== null) {
      return this.hertzLazy;
    }
    return this.hertzLazy = this.convertFromBase(FrequencyUnits.Hertz);
  }
  /** */
  get RadiansPerSecond() {
    if (this.radianspersecondLazy !== null) {
      return this.radianspersecondLazy;
    }
    return this.radianspersecondLazy = this.convertFromBase(FrequencyUnits.RadiansPerSecond);
  }
  /** */
  get CyclesPerMinute() {
    if (this.cyclesperminuteLazy !== null) {
      return this.cyclesperminuteLazy;
    }
    return this.cyclesperminuteLazy = this.convertFromBase(FrequencyUnits.CyclesPerMinute);
  }
  /** */
  get CyclesPerHour() {
    if (this.cyclesperhourLazy !== null) {
      return this.cyclesperhourLazy;
    }
    return this.cyclesperhourLazy = this.convertFromBase(FrequencyUnits.CyclesPerHour);
  }
  /** */
  get BeatsPerMinute() {
    if (this.beatsperminuteLazy !== null) {
      return this.beatsperminuteLazy;
    }
    return this.beatsperminuteLazy = this.convertFromBase(FrequencyUnits.BeatsPerMinute);
  }
  /** */
  get PerSecond() {
    if (this.persecondLazy !== null) {
      return this.persecondLazy;
    }
    return this.persecondLazy = this.convertFromBase(FrequencyUnits.PerSecond);
  }
  /** */
  get BUnits() {
    if (this.bunitsLazy !== null) {
      return this.bunitsLazy;
    }
    return this.bunitsLazy = this.convertFromBase(FrequencyUnits.BUnits);
  }
  /** */
  get Microhertz() {
    if (this.microhertzLazy !== null) {
      return this.microhertzLazy;
    }
    return this.microhertzLazy = this.convertFromBase(FrequencyUnits.Microhertz);
  }
  /** */
  get Millihertz() {
    if (this.millihertzLazy !== null) {
      return this.millihertzLazy;
    }
    return this.millihertzLazy = this.convertFromBase(FrequencyUnits.Millihertz);
  }
  /** */
  get Kilohertz() {
    if (this.kilohertzLazy !== null) {
      return this.kilohertzLazy;
    }
    return this.kilohertzLazy = this.convertFromBase(FrequencyUnits.Kilohertz);
  }
  /** */
  get Megahertz() {
    if (this.megahertzLazy !== null) {
      return this.megahertzLazy;
    }
    return this.megahertzLazy = this.convertFromBase(FrequencyUnits.Megahertz);
  }
  /** */
  get Gigahertz() {
    if (this.gigahertzLazy !== null) {
      return this.gigahertzLazy;
    }
    return this.gigahertzLazy = this.convertFromBase(FrequencyUnits.Gigahertz);
  }
  /** */
  get Terahertz() {
    if (this.terahertzLazy !== null) {
      return this.terahertzLazy;
    }
    return this.terahertzLazy = this.convertFromBase(FrequencyUnits.Terahertz);
  }
  /**
   * Create a new Frequency instance from a Hertz
   *
   * @param value The unit as Hertz to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromHertz(value) {
    return new Frequency(value, FrequencyUnits.Hertz);
  }
  /**
   * Create a new Frequency instance from a RadiansPerSecond
   *
   * @param value The unit as RadiansPerSecond to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromRadiansPerSecond(value) {
    return new Frequency(value, FrequencyUnits.RadiansPerSecond);
  }
  /**
   * Create a new Frequency instance from a CyclesPerMinute
   *
   * @param value The unit as CyclesPerMinute to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromCyclesPerMinute(value) {
    return new Frequency(value, FrequencyUnits.CyclesPerMinute);
  }
  /**
   * Create a new Frequency instance from a CyclesPerHour
   *
   * @param value The unit as CyclesPerHour to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromCyclesPerHour(value) {
    return new Frequency(value, FrequencyUnits.CyclesPerHour);
  }
  /**
   * Create a new Frequency instance from a BeatsPerMinute
   *
   * @param value The unit as BeatsPerMinute to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromBeatsPerMinute(value) {
    return new Frequency(value, FrequencyUnits.BeatsPerMinute);
  }
  /**
   * Create a new Frequency instance from a PerSecond
   *
   * @param value The unit as PerSecond to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromPerSecond(value) {
    return new Frequency(value, FrequencyUnits.PerSecond);
  }
  /**
   * Create a new Frequency instance from a BUnits
   *
   * @param value The unit as BUnits to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromBUnits(value) {
    return new Frequency(value, FrequencyUnits.BUnits);
  }
  /**
   * Create a new Frequency instance from a Microhertz
   *
   * @param value The unit as Microhertz to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromMicrohertz(value) {
    return new Frequency(value, FrequencyUnits.Microhertz);
  }
  /**
   * Create a new Frequency instance from a Millihertz
   *
   * @param value The unit as Millihertz to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromMillihertz(value) {
    return new Frequency(value, FrequencyUnits.Millihertz);
  }
  /**
   * Create a new Frequency instance from a Kilohertz
   *
   * @param value The unit as Kilohertz to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromKilohertz(value) {
    return new Frequency(value, FrequencyUnits.Kilohertz);
  }
  /**
   * Create a new Frequency instance from a Megahertz
   *
   * @param value The unit as Megahertz to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromMegahertz(value) {
    return new Frequency(value, FrequencyUnits.Megahertz);
  }
  /**
   * Create a new Frequency instance from a Gigahertz
   *
   * @param value The unit as Gigahertz to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromGigahertz(value) {
    return new Frequency(value, FrequencyUnits.Gigahertz);
  }
  /**
   * Create a new Frequency instance from a Terahertz
   *
   * @param value The unit as Terahertz to create a new Frequency from.
   * @returns The new Frequency instance.
   */
  static FromTerahertz(value) {
    return new Frequency(value, FrequencyUnits.Terahertz);
  }
  /**
   * Gets the base unit enumeration associated with Frequency
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return FrequencyUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return FrequencyUnits.Hertz;
  }
  /**
   * Create API DTO represent a Frequency unit.
   * @param holdInUnit The specific Frequency unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = FrequencyUnits.Hertz) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Frequency unit from an API DTO representation.
   * @param dtoFrequency The Frequency API DTO representation
   */
  static FromDto(dtoFrequency) {
    return new Frequency(dtoFrequency.value, dtoFrequency.unit);
  }
  /**
   * Convert Frequency to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case FrequencyUnits.Hertz:
        return this.Hertz;
      case FrequencyUnits.RadiansPerSecond:
        return this.RadiansPerSecond;
      case FrequencyUnits.CyclesPerMinute:
        return this.CyclesPerMinute;
      case FrequencyUnits.CyclesPerHour:
        return this.CyclesPerHour;
      case FrequencyUnits.BeatsPerMinute:
        return this.BeatsPerMinute;
      case FrequencyUnits.PerSecond:
        return this.PerSecond;
      case FrequencyUnits.BUnits:
        return this.BUnits;
      case FrequencyUnits.Microhertz:
        return this.Microhertz;
      case FrequencyUnits.Millihertz:
        return this.Millihertz;
      case FrequencyUnits.Kilohertz:
        return this.Kilohertz;
      case FrequencyUnits.Megahertz:
        return this.Megahertz;
      case FrequencyUnits.Gigahertz:
        return this.Gigahertz;
      case FrequencyUnits.Terahertz:
        return this.Terahertz;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case FrequencyUnits.Hertz:
        return this.value;
      case FrequencyUnits.RadiansPerSecond:
        return super.internalMultiply(this.value, 6.2831853072);
      case FrequencyUnits.CyclesPerMinute:
        return super.internalMultiply(this.value, 60);
      case FrequencyUnits.CyclesPerHour:
        return super.internalMultiply(this.value, 3600);
      case FrequencyUnits.BeatsPerMinute:
        return super.internalMultiply(this.value, 60);
      case FrequencyUnits.PerSecond:
        return this.value;
      case FrequencyUnits.BUnits:
        {
          const v3 = super.internalMultiply(this.value, this.value);
          return super.internalMultiply(v3, 1e-3);
        }
      case FrequencyUnits.Microhertz:
        return super.internalDivide(this.value, 0.000001);
      case FrequencyUnits.Millihertz:
        return super.internalDivide(this.value, 0.001);
      case FrequencyUnits.Kilohertz:
        return super.internalDivide(this.value, 1000);
      case FrequencyUnits.Megahertz:
        return super.internalDivide(this.value, 1000000);
      case FrequencyUnits.Gigahertz:
        return super.internalDivide(this.value, 1000000000);
      case FrequencyUnits.Terahertz:
        return super.internalDivide(this.value, 1000000000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case FrequencyUnits.Hertz:
        return this.value;
      case FrequencyUnits.RadiansPerSecond:
        return this.value * 6.2831853072;
      case FrequencyUnits.CyclesPerMinute:
        return this.value * 60;
      case FrequencyUnits.CyclesPerHour:
        return this.value * 3600;
      case FrequencyUnits.BeatsPerMinute:
        return this.value * 60;
      case FrequencyUnits.PerSecond:
        return this.value;
      case FrequencyUnits.BUnits:
        return this.value * this.value * 1e-3;
      case FrequencyUnits.Microhertz:
        return this.value / 0.000001;
      case FrequencyUnits.Millihertz:
        return this.value / 0.001;
      case FrequencyUnits.Kilohertz:
        return this.value / 1000;
      case FrequencyUnits.Megahertz:
        return this.value / 1000000;
      case FrequencyUnits.Gigahertz:
        return this.value / 1000000000;
      case FrequencyUnits.Terahertz:
        return this.value / 1000000000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case FrequencyUnits.Hertz:
        return value;
      case FrequencyUnits.RadiansPerSecond:
        return super.internalDivide(value, 6.2831853072);
      case FrequencyUnits.CyclesPerMinute:
        return super.internalDivide(value, 60);
      case FrequencyUnits.CyclesPerHour:
        return super.internalDivide(value, 3600);
      case FrequencyUnits.BeatsPerMinute:
        return super.internalDivide(value, 60);
      case FrequencyUnits.PerSecond:
        return value;
      case FrequencyUnits.BUnits:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalSqrt(v3);
        }
      case FrequencyUnits.Microhertz:
        return super.internalMultiply(value, 0.000001);
      case FrequencyUnits.Millihertz:
        return super.internalMultiply(value, 0.001);
      case FrequencyUnits.Kilohertz:
        return super.internalMultiply(value, 1000);
      case FrequencyUnits.Megahertz:
        return super.internalMultiply(value, 1000000);
      case FrequencyUnits.Gigahertz:
        return super.internalMultiply(value, 1000000000);
      case FrequencyUnits.Terahertz:
        return super.internalMultiply(value, 1000000000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case FrequencyUnits.Hertz:
        return value;
      case FrequencyUnits.RadiansPerSecond:
        return value / 6.2831853072;
      case FrequencyUnits.CyclesPerMinute:
        return value / 60;
      case FrequencyUnits.CyclesPerHour:
        return value / 3600;
      case FrequencyUnits.BeatsPerMinute:
        return value / 60;
      case FrequencyUnits.PerSecond:
        return value;
      case FrequencyUnits.BUnits:
        return Math.sqrt(value * 1e3);
      case FrequencyUnits.Microhertz:
        return value * 0.000001;
      case FrequencyUnits.Millihertz:
        return value * 0.001;
      case FrequencyUnits.Kilohertz:
        return value * 1000;
      case FrequencyUnits.Megahertz:
        return value * 1000000;
      case FrequencyUnits.Gigahertz:
        return value * 1000000000;
      case FrequencyUnits.Terahertz:
        return value * 1000000000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Frequency to string.
   * Note! the default format for Frequency is Hertz.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Frequency.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Frequency.
   */
  toString(unit = FrequencyUnits.Hertz, fractionalDigits) {
    switch (unit) {
      case FrequencyUnits.Hertz:
        return super.truncateFractionDigits(this.Hertz, fractionalDigits) + ` Hz`;
      case FrequencyUnits.RadiansPerSecond:
        return super.truncateFractionDigits(this.RadiansPerSecond, fractionalDigits) + ` rad/s`;
      case FrequencyUnits.CyclesPerMinute:
        return super.truncateFractionDigits(this.CyclesPerMinute, fractionalDigits) + ` cpm`;
      case FrequencyUnits.CyclesPerHour:
        return super.truncateFractionDigits(this.CyclesPerHour, fractionalDigits) + ` cph`;
      case FrequencyUnits.BeatsPerMinute:
        return super.truncateFractionDigits(this.BeatsPerMinute, fractionalDigits) + ` bpm`;
      case FrequencyUnits.PerSecond:
        return super.truncateFractionDigits(this.PerSecond, fractionalDigits) + ` s⁻¹`;
      case FrequencyUnits.BUnits:
        return super.truncateFractionDigits(this.BUnits, fractionalDigits) + ` B Units`;
      case FrequencyUnits.Microhertz:
        return super.truncateFractionDigits(this.Microhertz, fractionalDigits) + ` μHz`;
      case FrequencyUnits.Millihertz:
        return super.truncateFractionDigits(this.Millihertz, fractionalDigits) + ` mHz`;
      case FrequencyUnits.Kilohertz:
        return super.truncateFractionDigits(this.Kilohertz, fractionalDigits) + ` kHz`;
      case FrequencyUnits.Megahertz:
        return super.truncateFractionDigits(this.Megahertz, fractionalDigits) + ` MHz`;
      case FrequencyUnits.Gigahertz:
        return super.truncateFractionDigits(this.Gigahertz, fractionalDigits) + ` GHz`;
      case FrequencyUnits.Terahertz:
        return super.truncateFractionDigits(this.Terahertz, fractionalDigits) + ` THz`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Frequency unit abbreviation.
   * Note! the default abbreviation for Frequency is Hertz.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Frequency.
   * @returns The abbreviation string of Frequency.
   */
  getUnitAbbreviation(unitAbbreviation = FrequencyUnits.Hertz) {
    switch (unitAbbreviation) {
      case FrequencyUnits.Hertz:
        return `Hz`;
      case FrequencyUnits.RadiansPerSecond:
        return `rad/s`;
      case FrequencyUnits.CyclesPerMinute:
        return `cpm`;
      case FrequencyUnits.CyclesPerHour:
        return `cph`;
      case FrequencyUnits.BeatsPerMinute:
        return `bpm`;
      case FrequencyUnits.PerSecond:
        return `s⁻¹`;
      case FrequencyUnits.BUnits:
        return `B Units`;
      case FrequencyUnits.Microhertz:
        return `μHz`;
      case FrequencyUnits.Millihertz:
        return `mHz`;
      case FrequencyUnits.Kilohertz:
        return `kHz`;
      case FrequencyUnits.Megahertz:
        return `MHz`;
      case FrequencyUnits.Gigahertz:
        return `GHz`;
      case FrequencyUnits.Terahertz:
        return `THz`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Frequency are equals to the current Frequency.
   * @param frequency The other Frequency.
   * @returns True if the given Frequency are equal to the current Frequency.
   */
  equals(frequency) {
    return super.internalEquals(this.value, frequency.BaseValue);
  }
  /**
   * Compare the given Frequency against the current Frequency.
   * @param frequency The other Frequency.
   * @returns 0 if they are equal, -1 if the current Frequency is less then other, 1 if the current Frequency is greater then other.
   */
  compareTo(frequency) {
    return super.internalCompareTo(this.value, frequency.BaseValue);
  }
  /**
   * Add the given Frequency with the current Frequency.
   * @param frequency The other Frequency.
   * @returns A new Frequency instance with the results.
   */
  add(frequency) {
    return new Frequency(super.internalAdd(this.value, frequency.BaseValue));
  }
  /**
   * Subtract the given Frequency with the current Frequency.
   * @param frequency The other Frequency.
   * @returns A new Frequency instance with the results.
   */
  subtract(frequency) {
    return new Frequency(super.internalSubtract(this.value, frequency.BaseValue));
  }
  /**
   * Multiply the given Frequency with the current Frequency.
   * @param frequency The other Frequency.
   * @returns A new Frequency instance with the results.
   */
  multiply(frequency) {
    return new Frequency(super.internalMultiply(this.value, frequency.BaseValue));
  }
  /**
   * Divide the given Frequency with the current Frequency.
   * @param frequency The other Frequency.
   * @returns A new Frequency instance with the results.
   */
  divide(frequency) {
    return new Frequency(super.internalDivide(this.value, frequency.BaseValue));
  }
  /**
   * Modulo the given Frequency with the current Frequency.
   * @param frequency The other Frequency.
   * @returns A new Frequency instance with the results.
   */
  modulo(frequency) {
    return new Frequency(super.internalModulo(this.value, frequency.BaseValue));
  }
  /**
   * Pow the given Frequency with the current Frequency.
   * @param frequency The other Frequency.
   * @returns A new Frequency instance with the results.
   */
  pow(frequency) {
    return new Frequency(super.internalPow(this.value, frequency.BaseValue));
  }
}
exports.Frequency = Frequency;