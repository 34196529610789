"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeatFlux = exports.HeatFluxUnits = void 0;
const base_unit_1 = require("../base-unit");
/** HeatFluxUnits enumeration */
var HeatFluxUnits;
(function (HeatFluxUnits) {
  /** */
  HeatFluxUnits["WattsPerSquareMeter"] = "WattPerSquareMeter";
  /** */
  HeatFluxUnits["WattsPerSquareInch"] = "WattPerSquareInch";
  /** */
  HeatFluxUnits["WattsPerSquareFoot"] = "WattPerSquareFoot";
  /** */
  HeatFluxUnits["BtusPerSecondSquareInch"] = "BtuPerSecondSquareInch";
  /** */
  HeatFluxUnits["BtusPerSecondSquareFoot"] = "BtuPerSecondSquareFoot";
  /** */
  HeatFluxUnits["BtusPerMinuteSquareFoot"] = "BtuPerMinuteSquareFoot";
  /** */
  HeatFluxUnits["BtusPerHourSquareFoot"] = "BtuPerHourSquareFoot";
  /** */
  HeatFluxUnits["CaloriesPerSecondSquareCentimeter"] = "CaloriePerSecondSquareCentimeter";
  /** */
  HeatFluxUnits["KilocaloriesPerHourSquareMeter"] = "KilocaloriePerHourSquareMeter";
  /** */
  HeatFluxUnits["PoundsForcePerFootSecond"] = "PoundForcePerFootSecond";
  /** */
  HeatFluxUnits["PoundsPerSecondCubed"] = "PoundPerSecondCubed";
  /** */
  HeatFluxUnits["NanowattsPerSquareMeter"] = "NanowattPerSquareMeter";
  /** */
  HeatFluxUnits["MicrowattsPerSquareMeter"] = "MicrowattPerSquareMeter";
  /** */
  HeatFluxUnits["MilliwattsPerSquareMeter"] = "MilliwattPerSquareMeter";
  /** */
  HeatFluxUnits["CentiwattsPerSquareMeter"] = "CentiwattPerSquareMeter";
  /** */
  HeatFluxUnits["DeciwattsPerSquareMeter"] = "DeciwattPerSquareMeter";
  /** */
  HeatFluxUnits["KilowattsPerSquareMeter"] = "KilowattPerSquareMeter";
  /** */
  HeatFluxUnits["KilocaloriesPerSecondSquareCentimeter"] = "KilocaloriePerSecondSquareCentimeter";
})(HeatFluxUnits = exports.HeatFluxUnits || (exports.HeatFluxUnits = {}));
/** Heat flux is the flow of energy per unit of area per unit of time */
class HeatFlux extends base_unit_1.BaseUnit {
  /**
   * Create a new HeatFlux.
   * @param value The value.
   * @param fromUnit The ‘HeatFlux’ unit to create from.
   * The default unit is WattsPerSquareMeter
   */
  constructor(value, fromUnit = HeatFluxUnits.WattsPerSquareMeter) {
    super();
    this.wattspersquaremeterLazy = null;
    this.wattspersquareinchLazy = null;
    this.wattspersquarefootLazy = null;
    this.btuspersecondsquareinchLazy = null;
    this.btuspersecondsquarefootLazy = null;
    this.btusperminutesquarefootLazy = null;
    this.btusperhoursquarefootLazy = null;
    this.caloriespersecondsquarecentimeterLazy = null;
    this.kilocaloriesperhoursquaremeterLazy = null;
    this.poundsforceperfootsecondLazy = null;
    this.poundspersecondcubedLazy = null;
    this.nanowattspersquaremeterLazy = null;
    this.microwattspersquaremeterLazy = null;
    this.milliwattspersquaremeterLazy = null;
    this.centiwattspersquaremeterLazy = null;
    this.deciwattspersquaremeterLazy = null;
    this.kilowattspersquaremeterLazy = null;
    this.kilocaloriespersecondsquarecentimeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of HeatFlux is WattsPerSquareMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return HeatFluxUnits.WattsPerSquareMeter;
  }
  /** */
  get WattsPerSquareMeter() {
    if (this.wattspersquaremeterLazy !== null) {
      return this.wattspersquaremeterLazy;
    }
    return this.wattspersquaremeterLazy = this.convertFromBase(HeatFluxUnits.WattsPerSquareMeter);
  }
  /** */
  get WattsPerSquareInch() {
    if (this.wattspersquareinchLazy !== null) {
      return this.wattspersquareinchLazy;
    }
    return this.wattspersquareinchLazy = this.convertFromBase(HeatFluxUnits.WattsPerSquareInch);
  }
  /** */
  get WattsPerSquareFoot() {
    if (this.wattspersquarefootLazy !== null) {
      return this.wattspersquarefootLazy;
    }
    return this.wattspersquarefootLazy = this.convertFromBase(HeatFluxUnits.WattsPerSquareFoot);
  }
  /** */
  get BtusPerSecondSquareInch() {
    if (this.btuspersecondsquareinchLazy !== null) {
      return this.btuspersecondsquareinchLazy;
    }
    return this.btuspersecondsquareinchLazy = this.convertFromBase(HeatFluxUnits.BtusPerSecondSquareInch);
  }
  /** */
  get BtusPerSecondSquareFoot() {
    if (this.btuspersecondsquarefootLazy !== null) {
      return this.btuspersecondsquarefootLazy;
    }
    return this.btuspersecondsquarefootLazy = this.convertFromBase(HeatFluxUnits.BtusPerSecondSquareFoot);
  }
  /** */
  get BtusPerMinuteSquareFoot() {
    if (this.btusperminutesquarefootLazy !== null) {
      return this.btusperminutesquarefootLazy;
    }
    return this.btusperminutesquarefootLazy = this.convertFromBase(HeatFluxUnits.BtusPerMinuteSquareFoot);
  }
  /** */
  get BtusPerHourSquareFoot() {
    if (this.btusperhoursquarefootLazy !== null) {
      return this.btusperhoursquarefootLazy;
    }
    return this.btusperhoursquarefootLazy = this.convertFromBase(HeatFluxUnits.BtusPerHourSquareFoot);
  }
  /** */
  get CaloriesPerSecondSquareCentimeter() {
    if (this.caloriespersecondsquarecentimeterLazy !== null) {
      return this.caloriespersecondsquarecentimeterLazy;
    }
    return this.caloriespersecondsquarecentimeterLazy = this.convertFromBase(HeatFluxUnits.CaloriesPerSecondSquareCentimeter);
  }
  /** */
  get KilocaloriesPerHourSquareMeter() {
    if (this.kilocaloriesperhoursquaremeterLazy !== null) {
      return this.kilocaloriesperhoursquaremeterLazy;
    }
    return this.kilocaloriesperhoursquaremeterLazy = this.convertFromBase(HeatFluxUnits.KilocaloriesPerHourSquareMeter);
  }
  /** */
  get PoundsForcePerFootSecond() {
    if (this.poundsforceperfootsecondLazy !== null) {
      return this.poundsforceperfootsecondLazy;
    }
    return this.poundsforceperfootsecondLazy = this.convertFromBase(HeatFluxUnits.PoundsForcePerFootSecond);
  }
  /** */
  get PoundsPerSecondCubed() {
    if (this.poundspersecondcubedLazy !== null) {
      return this.poundspersecondcubedLazy;
    }
    return this.poundspersecondcubedLazy = this.convertFromBase(HeatFluxUnits.PoundsPerSecondCubed);
  }
  /** */
  get NanowattsPerSquareMeter() {
    if (this.nanowattspersquaremeterLazy !== null) {
      return this.nanowattspersquaremeterLazy;
    }
    return this.nanowattspersquaremeterLazy = this.convertFromBase(HeatFluxUnits.NanowattsPerSquareMeter);
  }
  /** */
  get MicrowattsPerSquareMeter() {
    if (this.microwattspersquaremeterLazy !== null) {
      return this.microwattspersquaremeterLazy;
    }
    return this.microwattspersquaremeterLazy = this.convertFromBase(HeatFluxUnits.MicrowattsPerSquareMeter);
  }
  /** */
  get MilliwattsPerSquareMeter() {
    if (this.milliwattspersquaremeterLazy !== null) {
      return this.milliwattspersquaremeterLazy;
    }
    return this.milliwattspersquaremeterLazy = this.convertFromBase(HeatFluxUnits.MilliwattsPerSquareMeter);
  }
  /** */
  get CentiwattsPerSquareMeter() {
    if (this.centiwattspersquaremeterLazy !== null) {
      return this.centiwattspersquaremeterLazy;
    }
    return this.centiwattspersquaremeterLazy = this.convertFromBase(HeatFluxUnits.CentiwattsPerSquareMeter);
  }
  /** */
  get DeciwattsPerSquareMeter() {
    if (this.deciwattspersquaremeterLazy !== null) {
      return this.deciwattspersquaremeterLazy;
    }
    return this.deciwattspersquaremeterLazy = this.convertFromBase(HeatFluxUnits.DeciwattsPerSquareMeter);
  }
  /** */
  get KilowattsPerSquareMeter() {
    if (this.kilowattspersquaremeterLazy !== null) {
      return this.kilowattspersquaremeterLazy;
    }
    return this.kilowattspersquaremeterLazy = this.convertFromBase(HeatFluxUnits.KilowattsPerSquareMeter);
  }
  /** */
  get KilocaloriesPerSecondSquareCentimeter() {
    if (this.kilocaloriespersecondsquarecentimeterLazy !== null) {
      return this.kilocaloriespersecondsquarecentimeterLazy;
    }
    return this.kilocaloriespersecondsquarecentimeterLazy = this.convertFromBase(HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter);
  }
  /**
   * Create a new HeatFlux instance from a WattsPerSquareMeter
   *
   * @param value The unit as WattsPerSquareMeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromWattsPerSquareMeter(value) {
    return new HeatFlux(value, HeatFluxUnits.WattsPerSquareMeter);
  }
  /**
   * Create a new HeatFlux instance from a WattsPerSquareInch
   *
   * @param value The unit as WattsPerSquareInch to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromWattsPerSquareInch(value) {
    return new HeatFlux(value, HeatFluxUnits.WattsPerSquareInch);
  }
  /**
   * Create a new HeatFlux instance from a WattsPerSquareFoot
   *
   * @param value The unit as WattsPerSquareFoot to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromWattsPerSquareFoot(value) {
    return new HeatFlux(value, HeatFluxUnits.WattsPerSquareFoot);
  }
  /**
   * Create a new HeatFlux instance from a BtusPerSecondSquareInch
   *
   * @param value The unit as BtusPerSecondSquareInch to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromBtusPerSecondSquareInch(value) {
    return new HeatFlux(value, HeatFluxUnits.BtusPerSecondSquareInch);
  }
  /**
   * Create a new HeatFlux instance from a BtusPerSecondSquareFoot
   *
   * @param value The unit as BtusPerSecondSquareFoot to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromBtusPerSecondSquareFoot(value) {
    return new HeatFlux(value, HeatFluxUnits.BtusPerSecondSquareFoot);
  }
  /**
   * Create a new HeatFlux instance from a BtusPerMinuteSquareFoot
   *
   * @param value The unit as BtusPerMinuteSquareFoot to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromBtusPerMinuteSquareFoot(value) {
    return new HeatFlux(value, HeatFluxUnits.BtusPerMinuteSquareFoot);
  }
  /**
   * Create a new HeatFlux instance from a BtusPerHourSquareFoot
   *
   * @param value The unit as BtusPerHourSquareFoot to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromBtusPerHourSquareFoot(value) {
    return new HeatFlux(value, HeatFluxUnits.BtusPerHourSquareFoot);
  }
  /**
   * Create a new HeatFlux instance from a CaloriesPerSecondSquareCentimeter
   *
   * @param value The unit as CaloriesPerSecondSquareCentimeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromCaloriesPerSecondSquareCentimeter(value) {
    return new HeatFlux(value, HeatFluxUnits.CaloriesPerSecondSquareCentimeter);
  }
  /**
   * Create a new HeatFlux instance from a KilocaloriesPerHourSquareMeter
   *
   * @param value The unit as KilocaloriesPerHourSquareMeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromKilocaloriesPerHourSquareMeter(value) {
    return new HeatFlux(value, HeatFluxUnits.KilocaloriesPerHourSquareMeter);
  }
  /**
   * Create a new HeatFlux instance from a PoundsForcePerFootSecond
   *
   * @param value The unit as PoundsForcePerFootSecond to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromPoundsForcePerFootSecond(value) {
    return new HeatFlux(value, HeatFluxUnits.PoundsForcePerFootSecond);
  }
  /**
   * Create a new HeatFlux instance from a PoundsPerSecondCubed
   *
   * @param value The unit as PoundsPerSecondCubed to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromPoundsPerSecondCubed(value) {
    return new HeatFlux(value, HeatFluxUnits.PoundsPerSecondCubed);
  }
  /**
   * Create a new HeatFlux instance from a NanowattsPerSquareMeter
   *
   * @param value The unit as NanowattsPerSquareMeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromNanowattsPerSquareMeter(value) {
    return new HeatFlux(value, HeatFluxUnits.NanowattsPerSquareMeter);
  }
  /**
   * Create a new HeatFlux instance from a MicrowattsPerSquareMeter
   *
   * @param value The unit as MicrowattsPerSquareMeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromMicrowattsPerSquareMeter(value) {
    return new HeatFlux(value, HeatFluxUnits.MicrowattsPerSquareMeter);
  }
  /**
   * Create a new HeatFlux instance from a MilliwattsPerSquareMeter
   *
   * @param value The unit as MilliwattsPerSquareMeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromMilliwattsPerSquareMeter(value) {
    return new HeatFlux(value, HeatFluxUnits.MilliwattsPerSquareMeter);
  }
  /**
   * Create a new HeatFlux instance from a CentiwattsPerSquareMeter
   *
   * @param value The unit as CentiwattsPerSquareMeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromCentiwattsPerSquareMeter(value) {
    return new HeatFlux(value, HeatFluxUnits.CentiwattsPerSquareMeter);
  }
  /**
   * Create a new HeatFlux instance from a DeciwattsPerSquareMeter
   *
   * @param value The unit as DeciwattsPerSquareMeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromDeciwattsPerSquareMeter(value) {
    return new HeatFlux(value, HeatFluxUnits.DeciwattsPerSquareMeter);
  }
  /**
   * Create a new HeatFlux instance from a KilowattsPerSquareMeter
   *
   * @param value The unit as KilowattsPerSquareMeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromKilowattsPerSquareMeter(value) {
    return new HeatFlux(value, HeatFluxUnits.KilowattsPerSquareMeter);
  }
  /**
   * Create a new HeatFlux instance from a KilocaloriesPerSecondSquareCentimeter
   *
   * @param value The unit as KilocaloriesPerSecondSquareCentimeter to create a new HeatFlux from.
   * @returns The new HeatFlux instance.
   */
  static FromKilocaloriesPerSecondSquareCentimeter(value) {
    return new HeatFlux(value, HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter);
  }
  /**
   * Gets the base unit enumeration associated with HeatFlux
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return HeatFluxUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return HeatFluxUnits.WattsPerSquareMeter;
  }
  /**
   * Create API DTO represent a HeatFlux unit.
   * @param holdInUnit The specific HeatFlux unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = HeatFluxUnits.WattsPerSquareMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a HeatFlux unit from an API DTO representation.
   * @param dtoHeatFlux The HeatFlux API DTO representation
   */
  static FromDto(dtoHeatFlux) {
    return new HeatFlux(dtoHeatFlux.value, dtoHeatFlux.unit);
  }
  /**
   * Convert HeatFlux to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case HeatFluxUnits.WattsPerSquareMeter:
        return this.WattsPerSquareMeter;
      case HeatFluxUnits.WattsPerSquareInch:
        return this.WattsPerSquareInch;
      case HeatFluxUnits.WattsPerSquareFoot:
        return this.WattsPerSquareFoot;
      case HeatFluxUnits.BtusPerSecondSquareInch:
        return this.BtusPerSecondSquareInch;
      case HeatFluxUnits.BtusPerSecondSquareFoot:
        return this.BtusPerSecondSquareFoot;
      case HeatFluxUnits.BtusPerMinuteSquareFoot:
        return this.BtusPerMinuteSquareFoot;
      case HeatFluxUnits.BtusPerHourSquareFoot:
        return this.BtusPerHourSquareFoot;
      case HeatFluxUnits.CaloriesPerSecondSquareCentimeter:
        return this.CaloriesPerSecondSquareCentimeter;
      case HeatFluxUnits.KilocaloriesPerHourSquareMeter:
        return this.KilocaloriesPerHourSquareMeter;
      case HeatFluxUnits.PoundsForcePerFootSecond:
        return this.PoundsForcePerFootSecond;
      case HeatFluxUnits.PoundsPerSecondCubed:
        return this.PoundsPerSecondCubed;
      case HeatFluxUnits.NanowattsPerSquareMeter:
        return this.NanowattsPerSquareMeter;
      case HeatFluxUnits.MicrowattsPerSquareMeter:
        return this.MicrowattsPerSquareMeter;
      case HeatFluxUnits.MilliwattsPerSquareMeter:
        return this.MilliwattsPerSquareMeter;
      case HeatFluxUnits.CentiwattsPerSquareMeter:
        return this.CentiwattsPerSquareMeter;
      case HeatFluxUnits.DeciwattsPerSquareMeter:
        return this.DeciwattsPerSquareMeter;
      case HeatFluxUnits.KilowattsPerSquareMeter:
        return this.KilowattsPerSquareMeter;
      case HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter:
        return this.KilocaloriesPerSecondSquareCentimeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case HeatFluxUnits.WattsPerSquareMeter:
        return this.value;
      case HeatFluxUnits.WattsPerSquareInch:
        return super.internalDivide(this.value, 1.5500031e3);
      case HeatFluxUnits.WattsPerSquareFoot:
        return super.internalDivide(this.value, 1.07639e1);
      case HeatFluxUnits.BtusPerSecondSquareInch:
        return super.internalDivide(this.value, 1.63533984e6);
      case HeatFluxUnits.BtusPerSecondSquareFoot:
        return super.internalDivide(this.value, 1.13565267e4);
      case HeatFluxUnits.BtusPerMinuteSquareFoot:
        return super.internalDivide(this.value, 1.89275445e2);
      case HeatFluxUnits.BtusPerHourSquareFoot:
        return super.internalDivide(this.value, 3.15459075);
      case HeatFluxUnits.CaloriesPerSecondSquareCentimeter:
        return super.internalDivide(this.value, 4.1868e4);
      case HeatFluxUnits.KilocaloriesPerHourSquareMeter:
        return super.internalDivide(this.value, 1.163);
      case HeatFluxUnits.PoundsForcePerFootSecond:
        return super.internalDivide(this.value, 1.459390293720636e1);
      case HeatFluxUnits.PoundsPerSecondCubed:
        return super.internalDivide(this.value, 4.5359237e-1);
      case HeatFluxUnits.NanowattsPerSquareMeter:
        return super.internalDivide(this.value, 1e-9);
      case HeatFluxUnits.MicrowattsPerSquareMeter:
        return super.internalDivide(this.value, 0.000001);
      case HeatFluxUnits.MilliwattsPerSquareMeter:
        return super.internalDivide(this.value, 0.001);
      case HeatFluxUnits.CentiwattsPerSquareMeter:
        return super.internalDivide(this.value, 0.01);
      case HeatFluxUnits.DeciwattsPerSquareMeter:
        return super.internalDivide(this.value, 0.1);
      case HeatFluxUnits.KilowattsPerSquareMeter:
        return super.internalDivide(this.value, 1000);
      case HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter:
        {
          const v3 = super.internalDivide(this.value, 4.1868e4);
          return super.internalDivide(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case HeatFluxUnits.WattsPerSquareMeter:
        return this.value;
      case HeatFluxUnits.WattsPerSquareInch:
        return this.value / 1.5500031e3;
      case HeatFluxUnits.WattsPerSquareFoot:
        return this.value / 1.07639e1;
      case HeatFluxUnits.BtusPerSecondSquareInch:
        return this.value / 1.63533984e6;
      case HeatFluxUnits.BtusPerSecondSquareFoot:
        return this.value / 1.13565267e4;
      case HeatFluxUnits.BtusPerMinuteSquareFoot:
        return this.value / 1.89275445e2;
      case HeatFluxUnits.BtusPerHourSquareFoot:
        return this.value / 3.15459075;
      case HeatFluxUnits.CaloriesPerSecondSquareCentimeter:
        return this.value / 4.1868e4;
      case HeatFluxUnits.KilocaloriesPerHourSquareMeter:
        return this.value / 1.163;
      case HeatFluxUnits.PoundsForcePerFootSecond:
        return this.value / 1.459390293720636e1;
      case HeatFluxUnits.PoundsPerSecondCubed:
        return this.value / 4.5359237e-1;
      case HeatFluxUnits.NanowattsPerSquareMeter:
        return this.value / 1e-9;
      case HeatFluxUnits.MicrowattsPerSquareMeter:
        return this.value / 0.000001;
      case HeatFluxUnits.MilliwattsPerSquareMeter:
        return this.value / 0.001;
      case HeatFluxUnits.CentiwattsPerSquareMeter:
        return this.value / 0.01;
      case HeatFluxUnits.DeciwattsPerSquareMeter:
        return this.value / 0.1;
      case HeatFluxUnits.KilowattsPerSquareMeter:
        return this.value / 1000;
      case HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter:
        return this.value / 4.1868e4 / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case HeatFluxUnits.WattsPerSquareMeter:
        return value;
      case HeatFluxUnits.WattsPerSquareInch:
        return super.internalMultiply(value, 1.5500031e3);
      case HeatFluxUnits.WattsPerSquareFoot:
        return super.internalMultiply(value, 1.07639e1);
      case HeatFluxUnits.BtusPerSecondSquareInch:
        return super.internalMultiply(value, 1.63533984e6);
      case HeatFluxUnits.BtusPerSecondSquareFoot:
        return super.internalMultiply(value, 1.13565267e4);
      case HeatFluxUnits.BtusPerMinuteSquareFoot:
        return super.internalMultiply(value, 1.89275445e2);
      case HeatFluxUnits.BtusPerHourSquareFoot:
        return super.internalMultiply(value, 3.15459075);
      case HeatFluxUnits.CaloriesPerSecondSquareCentimeter:
        return super.internalMultiply(value, 4.1868e4);
      case HeatFluxUnits.KilocaloriesPerHourSquareMeter:
        return super.internalMultiply(value, 1.163);
      case HeatFluxUnits.PoundsForcePerFootSecond:
        return super.internalMultiply(value, 1.459390293720636e1);
      case HeatFluxUnits.PoundsPerSecondCubed:
        return super.internalMultiply(value, 4.5359237e-1);
      case HeatFluxUnits.NanowattsPerSquareMeter:
        return super.internalMultiply(value, 1e-9);
      case HeatFluxUnits.MicrowattsPerSquareMeter:
        return super.internalMultiply(value, 0.000001);
      case HeatFluxUnits.MilliwattsPerSquareMeter:
        return super.internalMultiply(value, 0.001);
      case HeatFluxUnits.CentiwattsPerSquareMeter:
        return super.internalMultiply(value, 0.01);
      case HeatFluxUnits.DeciwattsPerSquareMeter:
        return super.internalMultiply(value, 0.1);
      case HeatFluxUnits.KilowattsPerSquareMeter:
        return super.internalMultiply(value, 1000);
      case HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter:
        {
          const v3 = super.internalMultiply(value, 4.1868e4);
          return super.internalMultiply(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case HeatFluxUnits.WattsPerSquareMeter:
        return value;
      case HeatFluxUnits.WattsPerSquareInch:
        return value * 1.5500031e3;
      case HeatFluxUnits.WattsPerSquareFoot:
        return value * 1.07639e1;
      case HeatFluxUnits.BtusPerSecondSquareInch:
        return value * 1.63533984e6;
      case HeatFluxUnits.BtusPerSecondSquareFoot:
        return value * 1.13565267e4;
      case HeatFluxUnits.BtusPerMinuteSquareFoot:
        return value * 1.89275445e2;
      case HeatFluxUnits.BtusPerHourSquareFoot:
        return value * 3.15459075;
      case HeatFluxUnits.CaloriesPerSecondSquareCentimeter:
        return value * 4.1868e4;
      case HeatFluxUnits.KilocaloriesPerHourSquareMeter:
        return value * 1.163;
      case HeatFluxUnits.PoundsForcePerFootSecond:
        return value * 1.459390293720636e1;
      case HeatFluxUnits.PoundsPerSecondCubed:
        return value * 4.5359237e-1;
      case HeatFluxUnits.NanowattsPerSquareMeter:
        return value * 1e-9;
      case HeatFluxUnits.MicrowattsPerSquareMeter:
        return value * 0.000001;
      case HeatFluxUnits.MilliwattsPerSquareMeter:
        return value * 0.001;
      case HeatFluxUnits.CentiwattsPerSquareMeter:
        return value * 0.01;
      case HeatFluxUnits.DeciwattsPerSquareMeter:
        return value * 0.1;
      case HeatFluxUnits.KilowattsPerSquareMeter:
        return value * 1000;
      case HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter:
        return value * 4.1868e4 * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the HeatFlux to string.
   * Note! the default format for HeatFlux is WattsPerSquareMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the HeatFlux.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the HeatFlux.
   */
  toString(unit = HeatFluxUnits.WattsPerSquareMeter, fractionalDigits) {
    switch (unit) {
      case HeatFluxUnits.WattsPerSquareMeter:
        return super.truncateFractionDigits(this.WattsPerSquareMeter, fractionalDigits) + ` W/m²`;
      case HeatFluxUnits.WattsPerSquareInch:
        return super.truncateFractionDigits(this.WattsPerSquareInch, fractionalDigits) + ` W/in²`;
      case HeatFluxUnits.WattsPerSquareFoot:
        return super.truncateFractionDigits(this.WattsPerSquareFoot, fractionalDigits) + ` W/ft²`;
      case HeatFluxUnits.BtusPerSecondSquareInch:
        return super.truncateFractionDigits(this.BtusPerSecondSquareInch, fractionalDigits) + ` BTU/s·in²`;
      case HeatFluxUnits.BtusPerSecondSquareFoot:
        return super.truncateFractionDigits(this.BtusPerSecondSquareFoot, fractionalDigits) + ` BTU/s·ft²`;
      case HeatFluxUnits.BtusPerMinuteSquareFoot:
        return super.truncateFractionDigits(this.BtusPerMinuteSquareFoot, fractionalDigits) + ` BTU/min·ft²`;
      case HeatFluxUnits.BtusPerHourSquareFoot:
        return super.truncateFractionDigits(this.BtusPerHourSquareFoot, fractionalDigits) + ` BTU/h·ft²`;
      case HeatFluxUnits.CaloriesPerSecondSquareCentimeter:
        return super.truncateFractionDigits(this.CaloriesPerSecondSquareCentimeter, fractionalDigits) + ` cal/s·cm²`;
      case HeatFluxUnits.KilocaloriesPerHourSquareMeter:
        return super.truncateFractionDigits(this.KilocaloriesPerHourSquareMeter, fractionalDigits) + ` kcal/h·m²`;
      case HeatFluxUnits.PoundsForcePerFootSecond:
        return super.truncateFractionDigits(this.PoundsForcePerFootSecond, fractionalDigits) + ` lbf/(ft·s)`;
      case HeatFluxUnits.PoundsPerSecondCubed:
        return super.truncateFractionDigits(this.PoundsPerSecondCubed, fractionalDigits) + ` lb/s³`;
      case HeatFluxUnits.NanowattsPerSquareMeter:
        return super.truncateFractionDigits(this.NanowattsPerSquareMeter, fractionalDigits) + ` nW/m²`;
      case HeatFluxUnits.MicrowattsPerSquareMeter:
        return super.truncateFractionDigits(this.MicrowattsPerSquareMeter, fractionalDigits) + ` μW/m²`;
      case HeatFluxUnits.MilliwattsPerSquareMeter:
        return super.truncateFractionDigits(this.MilliwattsPerSquareMeter, fractionalDigits) + ` mW/m²`;
      case HeatFluxUnits.CentiwattsPerSquareMeter:
        return super.truncateFractionDigits(this.CentiwattsPerSquareMeter, fractionalDigits) + ` cW/m²`;
      case HeatFluxUnits.DeciwattsPerSquareMeter:
        return super.truncateFractionDigits(this.DeciwattsPerSquareMeter, fractionalDigits) + ` dW/m²`;
      case HeatFluxUnits.KilowattsPerSquareMeter:
        return super.truncateFractionDigits(this.KilowattsPerSquareMeter, fractionalDigits) + ` kW/m²`;
      case HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter:
        return super.truncateFractionDigits(this.KilocaloriesPerSecondSquareCentimeter, fractionalDigits) + ` kcal/s·cm²`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get HeatFlux unit abbreviation.
   * Note! the default abbreviation for HeatFlux is WattsPerSquareMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the HeatFlux.
   * @returns The abbreviation string of HeatFlux.
   */
  getUnitAbbreviation(unitAbbreviation = HeatFluxUnits.WattsPerSquareMeter) {
    switch (unitAbbreviation) {
      case HeatFluxUnits.WattsPerSquareMeter:
        return `W/m²`;
      case HeatFluxUnits.WattsPerSquareInch:
        return `W/in²`;
      case HeatFluxUnits.WattsPerSquareFoot:
        return `W/ft²`;
      case HeatFluxUnits.BtusPerSecondSquareInch:
        return `BTU/s·in²`;
      case HeatFluxUnits.BtusPerSecondSquareFoot:
        return `BTU/s·ft²`;
      case HeatFluxUnits.BtusPerMinuteSquareFoot:
        return `BTU/min·ft²`;
      case HeatFluxUnits.BtusPerHourSquareFoot:
        return `BTU/h·ft²`;
      case HeatFluxUnits.CaloriesPerSecondSquareCentimeter:
        return `cal/s·cm²`;
      case HeatFluxUnits.KilocaloriesPerHourSquareMeter:
        return `kcal/h·m²`;
      case HeatFluxUnits.PoundsForcePerFootSecond:
        return `lbf/(ft·s)`;
      case HeatFluxUnits.PoundsPerSecondCubed:
        return `lb/s³`;
      case HeatFluxUnits.NanowattsPerSquareMeter:
        return `nW/m²`;
      case HeatFluxUnits.MicrowattsPerSquareMeter:
        return `μW/m²`;
      case HeatFluxUnits.MilliwattsPerSquareMeter:
        return `mW/m²`;
      case HeatFluxUnits.CentiwattsPerSquareMeter:
        return `cW/m²`;
      case HeatFluxUnits.DeciwattsPerSquareMeter:
        return `dW/m²`;
      case HeatFluxUnits.KilowattsPerSquareMeter:
        return `kW/m²`;
      case HeatFluxUnits.KilocaloriesPerSecondSquareCentimeter:
        return `kcal/s·cm²`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given HeatFlux are equals to the current HeatFlux.
   * @param heatFlux The other HeatFlux.
   * @returns True if the given HeatFlux are equal to the current HeatFlux.
   */
  equals(heatFlux) {
    return super.internalEquals(this.value, heatFlux.BaseValue);
  }
  /**
   * Compare the given HeatFlux against the current HeatFlux.
   * @param heatFlux The other HeatFlux.
   * @returns 0 if they are equal, -1 if the current HeatFlux is less then other, 1 if the current HeatFlux is greater then other.
   */
  compareTo(heatFlux) {
    return super.internalCompareTo(this.value, heatFlux.BaseValue);
  }
  /**
   * Add the given HeatFlux with the current HeatFlux.
   * @param heatFlux The other HeatFlux.
   * @returns A new HeatFlux instance with the results.
   */
  add(heatFlux) {
    return new HeatFlux(super.internalAdd(this.value, heatFlux.BaseValue));
  }
  /**
   * Subtract the given HeatFlux with the current HeatFlux.
   * @param heatFlux The other HeatFlux.
   * @returns A new HeatFlux instance with the results.
   */
  subtract(heatFlux) {
    return new HeatFlux(super.internalSubtract(this.value, heatFlux.BaseValue));
  }
  /**
   * Multiply the given HeatFlux with the current HeatFlux.
   * @param heatFlux The other HeatFlux.
   * @returns A new HeatFlux instance with the results.
   */
  multiply(heatFlux) {
    return new HeatFlux(super.internalMultiply(this.value, heatFlux.BaseValue));
  }
  /**
   * Divide the given HeatFlux with the current HeatFlux.
   * @param heatFlux The other HeatFlux.
   * @returns A new HeatFlux instance with the results.
   */
  divide(heatFlux) {
    return new HeatFlux(super.internalDivide(this.value, heatFlux.BaseValue));
  }
  /**
   * Modulo the given HeatFlux with the current HeatFlux.
   * @param heatFlux The other HeatFlux.
   * @returns A new HeatFlux instance with the results.
   */
  modulo(heatFlux) {
    return new HeatFlux(super.internalModulo(this.value, heatFlux.BaseValue));
  }
  /**
   * Pow the given HeatFlux with the current HeatFlux.
   * @param heatFlux The other HeatFlux.
   * @returns A new HeatFlux instance with the results.
   */
  pow(heatFlux) {
    return new HeatFlux(super.internalPow(this.value, heatFlux.BaseValue));
  }
}
exports.HeatFlux = HeatFlux;