import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { RecipeInfoPO, StandardsPO, STiRResultListPO, STiRResultPO } from "../models";
import { ManageStandardMasterPO } from "../models/manage-standardpo";
import { AppConstants } from "../shared/constants";

@Injectable({
    providedIn: "root",
})
export class ManageStandardService {

    constructor(private baseService: BaseService) { }

    GetMasterDataForManageStandards(): Observable<STiRResultPO<ManageStandardMasterPO>> {
        let url = "Standard/GetMasterDataForManageStandards";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    GetStandards(): Observable<STiRResultListPO<StandardsPO>> {
        let url = "Standard/GetStandards";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    SubmitStandard(data: StandardsPO[]): Observable<STiRResultListPO<StandardsPO>> {
        let url = `Standard/SubmitStandard`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }
}