"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VolumeFlowPerArea = exports.VolumeFlowPerAreaUnits = void 0;
const base_unit_1 = require("../base-unit");
/** VolumeFlowPerAreaUnits enumeration */
var VolumeFlowPerAreaUnits;
(function (VolumeFlowPerAreaUnits) {
  /** */
  VolumeFlowPerAreaUnits["CubicMetersPerSecondPerSquareMeter"] = "CubicMeterPerSecondPerSquareMeter";
  /** */
  VolumeFlowPerAreaUnits["CubicFeetPerMinutePerSquareFoot"] = "CubicFootPerMinutePerSquareFoot";
})(VolumeFlowPerAreaUnits = exports.VolumeFlowPerAreaUnits || (exports.VolumeFlowPerAreaUnits = {}));
/** The volumetric flow rate per area is the volume of fluid which passes through a given unit surface area per unit time. */
class VolumeFlowPerArea extends base_unit_1.BaseUnit {
  /**
   * Create a new VolumeFlowPerArea.
   * @param value The value.
   * @param fromUnit The ‘VolumeFlowPerArea’ unit to create from.
   * The default unit is CubicMetersPerSecondPerSquareMeter
   */
  constructor(value, fromUnit = VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter) {
    super();
    this.cubicmeterspersecondpersquaremeterLazy = null;
    this.cubicfeetperminutepersquarefootLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of VolumeFlowPerArea is CubicMetersPerSecondPerSquareMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter;
  }
  /** */
  get CubicMetersPerSecondPerSquareMeter() {
    if (this.cubicmeterspersecondpersquaremeterLazy !== null) {
      return this.cubicmeterspersecondpersquaremeterLazy;
    }
    return this.cubicmeterspersecondpersquaremeterLazy = this.convertFromBase(VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter);
  }
  /** */
  get CubicFeetPerMinutePerSquareFoot() {
    if (this.cubicfeetperminutepersquarefootLazy !== null) {
      return this.cubicfeetperminutepersquarefootLazy;
    }
    return this.cubicfeetperminutepersquarefootLazy = this.convertFromBase(VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot);
  }
  /**
   * Create a new VolumeFlowPerArea instance from a CubicMetersPerSecondPerSquareMeter
   *
   * @param value The unit as CubicMetersPerSecondPerSquareMeter to create a new VolumeFlowPerArea from.
   * @returns The new VolumeFlowPerArea instance.
   */
  static FromCubicMetersPerSecondPerSquareMeter(value) {
    return new VolumeFlowPerArea(value, VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter);
  }
  /**
   * Create a new VolumeFlowPerArea instance from a CubicFeetPerMinutePerSquareFoot
   *
   * @param value The unit as CubicFeetPerMinutePerSquareFoot to create a new VolumeFlowPerArea from.
   * @returns The new VolumeFlowPerArea instance.
   */
  static FromCubicFeetPerMinutePerSquareFoot(value) {
    return new VolumeFlowPerArea(value, VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot);
  }
  /**
   * Gets the base unit enumeration associated with VolumeFlowPerArea
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return VolumeFlowPerAreaUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter;
  }
  /**
   * Create API DTO represent a VolumeFlowPerArea unit.
   * @param holdInUnit The specific VolumeFlowPerArea unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a VolumeFlowPerArea unit from an API DTO representation.
   * @param dtoVolumeFlowPerArea The VolumeFlowPerArea API DTO representation
   */
  static FromDto(dtoVolumeFlowPerArea) {
    return new VolumeFlowPerArea(dtoVolumeFlowPerArea.value, dtoVolumeFlowPerArea.unit);
  }
  /**
   * Convert VolumeFlowPerArea to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter:
        return this.CubicMetersPerSecondPerSquareMeter;
      case VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot:
        return this.CubicFeetPerMinutePerSquareFoot;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter:
        return this.value;
      case VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot:
        return super.internalMultiply(this.value, 196.850394);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter:
        return this.value;
      case VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot:
        return this.value * 196.850394;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter:
        return value;
      case VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot:
        return super.internalDivide(value, 196.850394);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter:
        return value;
      case VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot:
        return value / 196.850394;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the VolumeFlowPerArea to string.
   * Note! the default format for VolumeFlowPerArea is CubicMetersPerSecondPerSquareMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the VolumeFlowPerArea.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the VolumeFlowPerArea.
   */
  toString(unit = VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter, fractionalDigits) {
    switch (unit) {
      case VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter:
        return super.truncateFractionDigits(this.CubicMetersPerSecondPerSquareMeter, fractionalDigits) + ` m³/(s·m²)`;
      case VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot:
        return super.truncateFractionDigits(this.CubicFeetPerMinutePerSquareFoot, fractionalDigits) + ` CFM/ft²`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get VolumeFlowPerArea unit abbreviation.
   * Note! the default abbreviation for VolumeFlowPerArea is CubicMetersPerSecondPerSquareMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the VolumeFlowPerArea.
   * @returns The abbreviation string of VolumeFlowPerArea.
   */
  getUnitAbbreviation(unitAbbreviation = VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter) {
    switch (unitAbbreviation) {
      case VolumeFlowPerAreaUnits.CubicMetersPerSecondPerSquareMeter:
        return `m³/(s·m²)`;
      case VolumeFlowPerAreaUnits.CubicFeetPerMinutePerSquareFoot:
        return `CFM/ft²`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given VolumeFlowPerArea are equals to the current VolumeFlowPerArea.
   * @param volumeFlowPerArea The other VolumeFlowPerArea.
   * @returns True if the given VolumeFlowPerArea are equal to the current VolumeFlowPerArea.
   */
  equals(volumeFlowPerArea) {
    return super.internalEquals(this.value, volumeFlowPerArea.BaseValue);
  }
  /**
   * Compare the given VolumeFlowPerArea against the current VolumeFlowPerArea.
   * @param volumeFlowPerArea The other VolumeFlowPerArea.
   * @returns 0 if they are equal, -1 if the current VolumeFlowPerArea is less then other, 1 if the current VolumeFlowPerArea is greater then other.
   */
  compareTo(volumeFlowPerArea) {
    return super.internalCompareTo(this.value, volumeFlowPerArea.BaseValue);
  }
  /**
   * Add the given VolumeFlowPerArea with the current VolumeFlowPerArea.
   * @param volumeFlowPerArea The other VolumeFlowPerArea.
   * @returns A new VolumeFlowPerArea instance with the results.
   */
  add(volumeFlowPerArea) {
    return new VolumeFlowPerArea(super.internalAdd(this.value, volumeFlowPerArea.BaseValue));
  }
  /**
   * Subtract the given VolumeFlowPerArea with the current VolumeFlowPerArea.
   * @param volumeFlowPerArea The other VolumeFlowPerArea.
   * @returns A new VolumeFlowPerArea instance with the results.
   */
  subtract(volumeFlowPerArea) {
    return new VolumeFlowPerArea(super.internalSubtract(this.value, volumeFlowPerArea.BaseValue));
  }
  /**
   * Multiply the given VolumeFlowPerArea with the current VolumeFlowPerArea.
   * @param volumeFlowPerArea The other VolumeFlowPerArea.
   * @returns A new VolumeFlowPerArea instance with the results.
   */
  multiply(volumeFlowPerArea) {
    return new VolumeFlowPerArea(super.internalMultiply(this.value, volumeFlowPerArea.BaseValue));
  }
  /**
   * Divide the given VolumeFlowPerArea with the current VolumeFlowPerArea.
   * @param volumeFlowPerArea The other VolumeFlowPerArea.
   * @returns A new VolumeFlowPerArea instance with the results.
   */
  divide(volumeFlowPerArea) {
    return new VolumeFlowPerArea(super.internalDivide(this.value, volumeFlowPerArea.BaseValue));
  }
  /**
   * Modulo the given VolumeFlowPerArea with the current VolumeFlowPerArea.
   * @param volumeFlowPerArea The other VolumeFlowPerArea.
   * @returns A new VolumeFlowPerArea instance with the results.
   */
  modulo(volumeFlowPerArea) {
    return new VolumeFlowPerArea(super.internalModulo(this.value, volumeFlowPerArea.BaseValue));
  }
  /**
   * Pow the given VolumeFlowPerArea with the current VolumeFlowPerArea.
   * @param volumeFlowPerArea The other VolumeFlowPerArea.
   * @returns A new VolumeFlowPerArea instance with the results.
   */
  pow(volumeFlowPerArea) {
    return new VolumeFlowPerArea(super.internalPow(this.value, volumeFlowPerArea.BaseValue));
  }
}
exports.VolumeFlowPerArea = VolumeFlowPerArea;