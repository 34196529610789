import { NgModule } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { OrderListModule } from 'primeng/orderlist';

@NgModule({
    exports: [
        ButtonModule,
        TooltipModule,
        ConfirmDialogModule,
        DynamicDialogModule,
        DropdownModule,
        RadioButtonModule,
        InputNumberModule,
        InputTextModule,
        DialogModule,
        MultiSelectModule,
        InputSwitchModule,
        CheckboxModule,
        InputTextareaModule,
        TabViewModule,
        OrderListModule
    ],
    providers: [
        ConfirmationService,
        DialogService
    ]
})
export class PrimeNGModule { }