"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MagneticField = exports.MagneticFieldUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MagneticFieldUnits enumeration */
var MagneticFieldUnits;
(function (MagneticFieldUnits) {
  /** */
  MagneticFieldUnits["Teslas"] = "Tesla";
  /** */
  MagneticFieldUnits["Gausses"] = "Gauss";
  /** */
  MagneticFieldUnits["Nanoteslas"] = "Nanotesla";
  /** */
  MagneticFieldUnits["Microteslas"] = "Microtesla";
  /** */
  MagneticFieldUnits["Milliteslas"] = "Millitesla";
  /** */
  MagneticFieldUnits["Milligausses"] = "Milligauss";
})(MagneticFieldUnits = exports.MagneticFieldUnits || (exports.MagneticFieldUnits = {}));
/** A magnetic field is a force field that is created by moving electric charges (electric currents) and magnetic dipoles, and exerts a force on other nearby moving charges and magnetic dipoles. */
class MagneticField extends base_unit_1.BaseUnit {
  /**
   * Create a new MagneticField.
   * @param value The value.
   * @param fromUnit The ‘MagneticField’ unit to create from.
   * The default unit is Teslas
   */
  constructor(value, fromUnit = MagneticFieldUnits.Teslas) {
    super();
    this.teslasLazy = null;
    this.gaussesLazy = null;
    this.nanoteslasLazy = null;
    this.microteslasLazy = null;
    this.milliteslasLazy = null;
    this.milligaussesLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of MagneticField is Teslas.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MagneticFieldUnits.Teslas;
  }
  /** */
  get Teslas() {
    if (this.teslasLazy !== null) {
      return this.teslasLazy;
    }
    return this.teslasLazy = this.convertFromBase(MagneticFieldUnits.Teslas);
  }
  /** */
  get Gausses() {
    if (this.gaussesLazy !== null) {
      return this.gaussesLazy;
    }
    return this.gaussesLazy = this.convertFromBase(MagneticFieldUnits.Gausses);
  }
  /** */
  get Nanoteslas() {
    if (this.nanoteslasLazy !== null) {
      return this.nanoteslasLazy;
    }
    return this.nanoteslasLazy = this.convertFromBase(MagneticFieldUnits.Nanoteslas);
  }
  /** */
  get Microteslas() {
    if (this.microteslasLazy !== null) {
      return this.microteslasLazy;
    }
    return this.microteslasLazy = this.convertFromBase(MagneticFieldUnits.Microteslas);
  }
  /** */
  get Milliteslas() {
    if (this.milliteslasLazy !== null) {
      return this.milliteslasLazy;
    }
    return this.milliteslasLazy = this.convertFromBase(MagneticFieldUnits.Milliteslas);
  }
  /** */
  get Milligausses() {
    if (this.milligaussesLazy !== null) {
      return this.milligaussesLazy;
    }
    return this.milligaussesLazy = this.convertFromBase(MagneticFieldUnits.Milligausses);
  }
  /**
   * Create a new MagneticField instance from a Teslas
   *
   * @param value The unit as Teslas to create a new MagneticField from.
   * @returns The new MagneticField instance.
   */
  static FromTeslas(value) {
    return new MagneticField(value, MagneticFieldUnits.Teslas);
  }
  /**
   * Create a new MagneticField instance from a Gausses
   *
   * @param value The unit as Gausses to create a new MagneticField from.
   * @returns The new MagneticField instance.
   */
  static FromGausses(value) {
    return new MagneticField(value, MagneticFieldUnits.Gausses);
  }
  /**
   * Create a new MagneticField instance from a Nanoteslas
   *
   * @param value The unit as Nanoteslas to create a new MagneticField from.
   * @returns The new MagneticField instance.
   */
  static FromNanoteslas(value) {
    return new MagneticField(value, MagneticFieldUnits.Nanoteslas);
  }
  /**
   * Create a new MagneticField instance from a Microteslas
   *
   * @param value The unit as Microteslas to create a new MagneticField from.
   * @returns The new MagneticField instance.
   */
  static FromMicroteslas(value) {
    return new MagneticField(value, MagneticFieldUnits.Microteslas);
  }
  /**
   * Create a new MagneticField instance from a Milliteslas
   *
   * @param value The unit as Milliteslas to create a new MagneticField from.
   * @returns The new MagneticField instance.
   */
  static FromMilliteslas(value) {
    return new MagneticField(value, MagneticFieldUnits.Milliteslas);
  }
  /**
   * Create a new MagneticField instance from a Milligausses
   *
   * @param value The unit as Milligausses to create a new MagneticField from.
   * @returns The new MagneticField instance.
   */
  static FromMilligausses(value) {
    return new MagneticField(value, MagneticFieldUnits.Milligausses);
  }
  /**
   * Gets the base unit enumeration associated with MagneticField
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MagneticFieldUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MagneticFieldUnits.Teslas;
  }
  /**
   * Create API DTO represent a MagneticField unit.
   * @param holdInUnit The specific MagneticField unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MagneticFieldUnits.Teslas) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a MagneticField unit from an API DTO representation.
   * @param dtoMagneticField The MagneticField API DTO representation
   */
  static FromDto(dtoMagneticField) {
    return new MagneticField(dtoMagneticField.value, dtoMagneticField.unit);
  }
  /**
   * Convert MagneticField to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MagneticFieldUnits.Teslas:
        return this.Teslas;
      case MagneticFieldUnits.Gausses:
        return this.Gausses;
      case MagneticFieldUnits.Nanoteslas:
        return this.Nanoteslas;
      case MagneticFieldUnits.Microteslas:
        return this.Microteslas;
      case MagneticFieldUnits.Milliteslas:
        return this.Milliteslas;
      case MagneticFieldUnits.Milligausses:
        return this.Milligausses;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MagneticFieldUnits.Teslas:
        return this.value;
      case MagneticFieldUnits.Gausses:
        return super.internalMultiply(this.value, 1e4);
      case MagneticFieldUnits.Nanoteslas:
        return super.internalDivide(this.value, 1e-9);
      case MagneticFieldUnits.Microteslas:
        return super.internalDivide(this.value, 0.000001);
      case MagneticFieldUnits.Milliteslas:
        return super.internalDivide(this.value, 0.001);
      case MagneticFieldUnits.Milligausses:
        {
          const v3 = super.internalMultiply(this.value, 1e4);
          return super.internalDivide(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MagneticFieldUnits.Teslas:
        return this.value;
      case MagneticFieldUnits.Gausses:
        return this.value * 1e4;
      case MagneticFieldUnits.Nanoteslas:
        return this.value / 1e-9;
      case MagneticFieldUnits.Microteslas:
        return this.value / 0.000001;
      case MagneticFieldUnits.Milliteslas:
        return this.value / 0.001;
      case MagneticFieldUnits.Milligausses:
        return this.value * 1e4 / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MagneticFieldUnits.Teslas:
        return value;
      case MagneticFieldUnits.Gausses:
        return super.internalDivide(value, 1e4);
      case MagneticFieldUnits.Nanoteslas:
        return super.internalMultiply(value, 1e-9);
      case MagneticFieldUnits.Microteslas:
        return super.internalMultiply(value, 0.000001);
      case MagneticFieldUnits.Milliteslas:
        return super.internalMultiply(value, 0.001);
      case MagneticFieldUnits.Milligausses:
        {
          const v3 = super.internalDivide(value, 1e4);
          return super.internalMultiply(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MagneticFieldUnits.Teslas:
        return value;
      case MagneticFieldUnits.Gausses:
        return value / 1e4;
      case MagneticFieldUnits.Nanoteslas:
        return value * 1e-9;
      case MagneticFieldUnits.Microteslas:
        return value * 0.000001;
      case MagneticFieldUnits.Milliteslas:
        return value * 0.001;
      case MagneticFieldUnits.Milligausses:
        return value / 1e4 * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the MagneticField to string.
   * Note! the default format for MagneticField is Teslas.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the MagneticField.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the MagneticField.
   */
  toString(unit = MagneticFieldUnits.Teslas, fractionalDigits) {
    switch (unit) {
      case MagneticFieldUnits.Teslas:
        return super.truncateFractionDigits(this.Teslas, fractionalDigits) + ` T`;
      case MagneticFieldUnits.Gausses:
        return super.truncateFractionDigits(this.Gausses, fractionalDigits) + ` G`;
      case MagneticFieldUnits.Nanoteslas:
        return super.truncateFractionDigits(this.Nanoteslas, fractionalDigits) + ` nT`;
      case MagneticFieldUnits.Microteslas:
        return super.truncateFractionDigits(this.Microteslas, fractionalDigits) + ` μT`;
      case MagneticFieldUnits.Milliteslas:
        return super.truncateFractionDigits(this.Milliteslas, fractionalDigits) + ` mT`;
      case MagneticFieldUnits.Milligausses:
        return super.truncateFractionDigits(this.Milligausses, fractionalDigits) + ` mG`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get MagneticField unit abbreviation.
   * Note! the default abbreviation for MagneticField is Teslas.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the MagneticField.
   * @returns The abbreviation string of MagneticField.
   */
  getUnitAbbreviation(unitAbbreviation = MagneticFieldUnits.Teslas) {
    switch (unitAbbreviation) {
      case MagneticFieldUnits.Teslas:
        return `T`;
      case MagneticFieldUnits.Gausses:
        return `G`;
      case MagneticFieldUnits.Nanoteslas:
        return `nT`;
      case MagneticFieldUnits.Microteslas:
        return `μT`;
      case MagneticFieldUnits.Milliteslas:
        return `mT`;
      case MagneticFieldUnits.Milligausses:
        return `mG`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given MagneticField are equals to the current MagneticField.
   * @param magneticField The other MagneticField.
   * @returns True if the given MagneticField are equal to the current MagneticField.
   */
  equals(magneticField) {
    return super.internalEquals(this.value, magneticField.BaseValue);
  }
  /**
   * Compare the given MagneticField against the current MagneticField.
   * @param magneticField The other MagneticField.
   * @returns 0 if they are equal, -1 if the current MagneticField is less then other, 1 if the current MagneticField is greater then other.
   */
  compareTo(magneticField) {
    return super.internalCompareTo(this.value, magneticField.BaseValue);
  }
  /**
   * Add the given MagneticField with the current MagneticField.
   * @param magneticField The other MagneticField.
   * @returns A new MagneticField instance with the results.
   */
  add(magneticField) {
    return new MagneticField(super.internalAdd(this.value, magneticField.BaseValue));
  }
  /**
   * Subtract the given MagneticField with the current MagneticField.
   * @param magneticField The other MagneticField.
   * @returns A new MagneticField instance with the results.
   */
  subtract(magneticField) {
    return new MagneticField(super.internalSubtract(this.value, magneticField.BaseValue));
  }
  /**
   * Multiply the given MagneticField with the current MagneticField.
   * @param magneticField The other MagneticField.
   * @returns A new MagneticField instance with the results.
   */
  multiply(magneticField) {
    return new MagneticField(super.internalMultiply(this.value, magneticField.BaseValue));
  }
  /**
   * Divide the given MagneticField with the current MagneticField.
   * @param magneticField The other MagneticField.
   * @returns A new MagneticField instance with the results.
   */
  divide(magneticField) {
    return new MagneticField(super.internalDivide(this.value, magneticField.BaseValue));
  }
  /**
   * Modulo the given MagneticField with the current MagneticField.
   * @param magneticField The other MagneticField.
   * @returns A new MagneticField instance with the results.
   */
  modulo(magneticField) {
    return new MagneticField(super.internalModulo(this.value, magneticField.BaseValue));
  }
  /**
   * Pow the given MagneticField with the current MagneticField.
   * @param magneticField The other MagneticField.
   * @returns A new MagneticField instance with the results.
   */
  pow(magneticField) {
    return new MagneticField(super.internalPow(this.value, magneticField.BaseValue));
  }
}
exports.MagneticField = MagneticField;