"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AbsorbedDoseOfIonizingRadiation = exports.AbsorbedDoseOfIonizingRadiationUnits = void 0;
const base_unit_1 = require("../base-unit");
/** AbsorbedDoseOfIonizingRadiationUnits enumeration */
var AbsorbedDoseOfIonizingRadiationUnits;
(function (AbsorbedDoseOfIonizingRadiationUnits) {
  /** The gray is the unit of ionizing radiation dose in the SI, defined as the absorption of one joule of radiation energy per kilogram of matter. */
  AbsorbedDoseOfIonizingRadiationUnits["Grays"] = "Gray";
  /** The rad is a unit of absorbed radiation dose, defined as 1 rad = 0.01 Gy = 0.01 J/kg. */
  AbsorbedDoseOfIonizingRadiationUnits["Rads"] = "Rad";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Femtograys"] = "Femtogray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Picograys"] = "Picogray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Nanograys"] = "Nanogray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Micrograys"] = "Microgray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Milligrays"] = "Milligray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Centigrays"] = "Centigray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Kilograys"] = "Kilogray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Megagrays"] = "Megagray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Gigagrays"] = "Gigagray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Teragrays"] = "Teragray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Petagrays"] = "Petagray";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Millirads"] = "Millirad";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Kilorads"] = "Kilorad";
  /** */
  AbsorbedDoseOfIonizingRadiationUnits["Megarads"] = "Megarad";
})(AbsorbedDoseOfIonizingRadiationUnits = exports.AbsorbedDoseOfIonizingRadiationUnits || (exports.AbsorbedDoseOfIonizingRadiationUnits = {}));
/** Absorbed dose is a dose quantity which is the measure of the energy deposited in matter by ionizing radiation per unit mass. */
class AbsorbedDoseOfIonizingRadiation extends base_unit_1.BaseUnit {
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation.
   * @param value The value.
   * @param fromUnit The ‘AbsorbedDoseOfIonizingRadiation’ unit to create from.
   * The default unit is Grays
   */
  constructor(value, fromUnit = AbsorbedDoseOfIonizingRadiationUnits.Grays) {
    super();
    this.graysLazy = null;
    this.radsLazy = null;
    this.femtograysLazy = null;
    this.picograysLazy = null;
    this.nanograysLazy = null;
    this.micrograysLazy = null;
    this.milligraysLazy = null;
    this.centigraysLazy = null;
    this.kilograysLazy = null;
    this.megagraysLazy = null;
    this.gigagraysLazy = null;
    this.teragraysLazy = null;
    this.petagraysLazy = null;
    this.milliradsLazy = null;
    this.kiloradsLazy = null;
    this.megaradsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of AbsorbedDoseOfIonizingRadiation is Grays.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return AbsorbedDoseOfIonizingRadiationUnits.Grays;
  }
  /** The gray is the unit of ionizing radiation dose in the SI, defined as the absorption of one joule of radiation energy per kilogram of matter. */
  get Grays() {
    if (this.graysLazy !== null) {
      return this.graysLazy;
    }
    return this.graysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Grays);
  }
  /** The rad is a unit of absorbed radiation dose, defined as 1 rad = 0.01 Gy = 0.01 J/kg. */
  get Rads() {
    if (this.radsLazy !== null) {
      return this.radsLazy;
    }
    return this.radsLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Rads);
  }
  /** */
  get Femtograys() {
    if (this.femtograysLazy !== null) {
      return this.femtograysLazy;
    }
    return this.femtograysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Femtograys);
  }
  /** */
  get Picograys() {
    if (this.picograysLazy !== null) {
      return this.picograysLazy;
    }
    return this.picograysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Picograys);
  }
  /** */
  get Nanograys() {
    if (this.nanograysLazy !== null) {
      return this.nanograysLazy;
    }
    return this.nanograysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Nanograys);
  }
  /** */
  get Micrograys() {
    if (this.micrograysLazy !== null) {
      return this.micrograysLazy;
    }
    return this.micrograysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Micrograys);
  }
  /** */
  get Milligrays() {
    if (this.milligraysLazy !== null) {
      return this.milligraysLazy;
    }
    return this.milligraysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Milligrays);
  }
  /** */
  get Centigrays() {
    if (this.centigraysLazy !== null) {
      return this.centigraysLazy;
    }
    return this.centigraysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Centigrays);
  }
  /** */
  get Kilograys() {
    if (this.kilograysLazy !== null) {
      return this.kilograysLazy;
    }
    return this.kilograysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Kilograys);
  }
  /** */
  get Megagrays() {
    if (this.megagraysLazy !== null) {
      return this.megagraysLazy;
    }
    return this.megagraysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Megagrays);
  }
  /** */
  get Gigagrays() {
    if (this.gigagraysLazy !== null) {
      return this.gigagraysLazy;
    }
    return this.gigagraysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Gigagrays);
  }
  /** */
  get Teragrays() {
    if (this.teragraysLazy !== null) {
      return this.teragraysLazy;
    }
    return this.teragraysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Teragrays);
  }
  /** */
  get Petagrays() {
    if (this.petagraysLazy !== null) {
      return this.petagraysLazy;
    }
    return this.petagraysLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Petagrays);
  }
  /** */
  get Millirads() {
    if (this.milliradsLazy !== null) {
      return this.milliradsLazy;
    }
    return this.milliradsLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Millirads);
  }
  /** */
  get Kilorads() {
    if (this.kiloradsLazy !== null) {
      return this.kiloradsLazy;
    }
    return this.kiloradsLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Kilorads);
  }
  /** */
  get Megarads() {
    if (this.megaradsLazy !== null) {
      return this.megaradsLazy;
    }
    return this.megaradsLazy = this.convertFromBase(AbsorbedDoseOfIonizingRadiationUnits.Megarads);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Grays
   * The gray is the unit of ionizing radiation dose in the SI, defined as the absorption of one joule of radiation energy per kilogram of matter.
   * @param value The unit as Grays to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromGrays(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Grays);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Rads
   * The rad is a unit of absorbed radiation dose, defined as 1 rad = 0.01 Gy = 0.01 J/kg.
   * @param value The unit as Rads to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromRads(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Rads);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Femtograys
   *
   * @param value The unit as Femtograys to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromFemtograys(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Femtograys);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Picograys
   *
   * @param value The unit as Picograys to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromPicograys(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Picograys);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Nanograys
   *
   * @param value The unit as Nanograys to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromNanograys(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Nanograys);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Micrograys
   *
   * @param value The unit as Micrograys to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromMicrograys(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Micrograys);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Milligrays
   *
   * @param value The unit as Milligrays to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromMilligrays(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Milligrays);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Centigrays
   *
   * @param value The unit as Centigrays to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromCentigrays(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Centigrays);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Kilograys
   *
   * @param value The unit as Kilograys to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromKilograys(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Kilograys);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Megagrays
   *
   * @param value The unit as Megagrays to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromMegagrays(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Megagrays);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Gigagrays
   *
   * @param value The unit as Gigagrays to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromGigagrays(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Gigagrays);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Teragrays
   *
   * @param value The unit as Teragrays to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromTeragrays(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Teragrays);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Petagrays
   *
   * @param value The unit as Petagrays to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromPetagrays(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Petagrays);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Millirads
   *
   * @param value The unit as Millirads to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromMillirads(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Millirads);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Kilorads
   *
   * @param value The unit as Kilorads to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromKilorads(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Kilorads);
  }
  /**
   * Create a new AbsorbedDoseOfIonizingRadiation instance from a Megarads
   *
   * @param value The unit as Megarads to create a new AbsorbedDoseOfIonizingRadiation from.
   * @returns The new AbsorbedDoseOfIonizingRadiation instance.
   */
  static FromMegarads(value) {
    return new AbsorbedDoseOfIonizingRadiation(value, AbsorbedDoseOfIonizingRadiationUnits.Megarads);
  }
  /**
   * Gets the base unit enumeration associated with AbsorbedDoseOfIonizingRadiation
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return AbsorbedDoseOfIonizingRadiationUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return AbsorbedDoseOfIonizingRadiationUnits.Grays;
  }
  /**
   * Create API DTO represent a AbsorbedDoseOfIonizingRadiation unit.
   * @param holdInUnit The specific AbsorbedDoseOfIonizingRadiation unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = AbsorbedDoseOfIonizingRadiationUnits.Grays) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a AbsorbedDoseOfIonizingRadiation unit from an API DTO representation.
   * @param dtoAbsorbedDoseOfIonizingRadiation The AbsorbedDoseOfIonizingRadiation API DTO representation
   */
  static FromDto(dtoAbsorbedDoseOfIonizingRadiation) {
    return new AbsorbedDoseOfIonizingRadiation(dtoAbsorbedDoseOfIonizingRadiation.value, dtoAbsorbedDoseOfIonizingRadiation.unit);
  }
  /**
   * Convert AbsorbedDoseOfIonizingRadiation to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case AbsorbedDoseOfIonizingRadiationUnits.Grays:
        return this.Grays;
      case AbsorbedDoseOfIonizingRadiationUnits.Rads:
        return this.Rads;
      case AbsorbedDoseOfIonizingRadiationUnits.Femtograys:
        return this.Femtograys;
      case AbsorbedDoseOfIonizingRadiationUnits.Picograys:
        return this.Picograys;
      case AbsorbedDoseOfIonizingRadiationUnits.Nanograys:
        return this.Nanograys;
      case AbsorbedDoseOfIonizingRadiationUnits.Micrograys:
        return this.Micrograys;
      case AbsorbedDoseOfIonizingRadiationUnits.Milligrays:
        return this.Milligrays;
      case AbsorbedDoseOfIonizingRadiationUnits.Centigrays:
        return this.Centigrays;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilograys:
        return this.Kilograys;
      case AbsorbedDoseOfIonizingRadiationUnits.Megagrays:
        return this.Megagrays;
      case AbsorbedDoseOfIonizingRadiationUnits.Gigagrays:
        return this.Gigagrays;
      case AbsorbedDoseOfIonizingRadiationUnits.Teragrays:
        return this.Teragrays;
      case AbsorbedDoseOfIonizingRadiationUnits.Petagrays:
        return this.Petagrays;
      case AbsorbedDoseOfIonizingRadiationUnits.Millirads:
        return this.Millirads;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilorads:
        return this.Kilorads;
      case AbsorbedDoseOfIonizingRadiationUnits.Megarads:
        return this.Megarads;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case AbsorbedDoseOfIonizingRadiationUnits.Grays:
        return this.value;
      case AbsorbedDoseOfIonizingRadiationUnits.Rads:
        return super.internalMultiply(this.value, 100);
      case AbsorbedDoseOfIonizingRadiationUnits.Femtograys:
        return super.internalDivide(this.value, 1e-15);
      case AbsorbedDoseOfIonizingRadiationUnits.Picograys:
        return super.internalDivide(this.value, 1e-12);
      case AbsorbedDoseOfIonizingRadiationUnits.Nanograys:
        return super.internalDivide(this.value, 1e-9);
      case AbsorbedDoseOfIonizingRadiationUnits.Micrograys:
        return super.internalDivide(this.value, 0.000001);
      case AbsorbedDoseOfIonizingRadiationUnits.Milligrays:
        return super.internalDivide(this.value, 0.001);
      case AbsorbedDoseOfIonizingRadiationUnits.Centigrays:
        return super.internalDivide(this.value, 0.01);
      case AbsorbedDoseOfIonizingRadiationUnits.Kilograys:
        return super.internalDivide(this.value, 1000);
      case AbsorbedDoseOfIonizingRadiationUnits.Megagrays:
        return super.internalDivide(this.value, 1000000);
      case AbsorbedDoseOfIonizingRadiationUnits.Gigagrays:
        return super.internalDivide(this.value, 1000000000);
      case AbsorbedDoseOfIonizingRadiationUnits.Teragrays:
        return super.internalDivide(this.value, 1000000000000);
      case AbsorbedDoseOfIonizingRadiationUnits.Petagrays:
        return super.internalDivide(this.value, 1000000000000000);
      case AbsorbedDoseOfIonizingRadiationUnits.Millirads:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 0.001);
        }
      case AbsorbedDoseOfIonizingRadiationUnits.Kilorads:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 1000);
        }
      case AbsorbedDoseOfIonizingRadiationUnits.Megarads:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case AbsorbedDoseOfIonizingRadiationUnits.Grays:
        return this.value;
      case AbsorbedDoseOfIonizingRadiationUnits.Rads:
        return this.value * 100;
      case AbsorbedDoseOfIonizingRadiationUnits.Femtograys:
        return this.value / 1e-15;
      case AbsorbedDoseOfIonizingRadiationUnits.Picograys:
        return this.value / 1e-12;
      case AbsorbedDoseOfIonizingRadiationUnits.Nanograys:
        return this.value / 1e-9;
      case AbsorbedDoseOfIonizingRadiationUnits.Micrograys:
        return this.value / 0.000001;
      case AbsorbedDoseOfIonizingRadiationUnits.Milligrays:
        return this.value / 0.001;
      case AbsorbedDoseOfIonizingRadiationUnits.Centigrays:
        return this.value / 0.01;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilograys:
        return this.value / 1000;
      case AbsorbedDoseOfIonizingRadiationUnits.Megagrays:
        return this.value / 1000000;
      case AbsorbedDoseOfIonizingRadiationUnits.Gigagrays:
        return this.value / 1000000000;
      case AbsorbedDoseOfIonizingRadiationUnits.Teragrays:
        return this.value / 1000000000000;
      case AbsorbedDoseOfIonizingRadiationUnits.Petagrays:
        return this.value / 1000000000000000;
      case AbsorbedDoseOfIonizingRadiationUnits.Millirads:
        return this.value * 100 / 0.001;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilorads:
        return this.value * 100 / 1000;
      case AbsorbedDoseOfIonizingRadiationUnits.Megarads:
        return this.value * 100 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case AbsorbedDoseOfIonizingRadiationUnits.Grays:
        return value;
      case AbsorbedDoseOfIonizingRadiationUnits.Rads:
        return super.internalDivide(value, 100);
      case AbsorbedDoseOfIonizingRadiationUnits.Femtograys:
        return super.internalMultiply(value, 1e-15);
      case AbsorbedDoseOfIonizingRadiationUnits.Picograys:
        return super.internalMultiply(value, 1e-12);
      case AbsorbedDoseOfIonizingRadiationUnits.Nanograys:
        return super.internalMultiply(value, 1e-9);
      case AbsorbedDoseOfIonizingRadiationUnits.Micrograys:
        return super.internalMultiply(value, 0.000001);
      case AbsorbedDoseOfIonizingRadiationUnits.Milligrays:
        return super.internalMultiply(value, 0.001);
      case AbsorbedDoseOfIonizingRadiationUnits.Centigrays:
        return super.internalMultiply(value, 0.01);
      case AbsorbedDoseOfIonizingRadiationUnits.Kilograys:
        return super.internalMultiply(value, 1000);
      case AbsorbedDoseOfIonizingRadiationUnits.Megagrays:
        return super.internalMultiply(value, 1000000);
      case AbsorbedDoseOfIonizingRadiationUnits.Gigagrays:
        return super.internalMultiply(value, 1000000000);
      case AbsorbedDoseOfIonizingRadiationUnits.Teragrays:
        return super.internalMultiply(value, 1000000000000);
      case AbsorbedDoseOfIonizingRadiationUnits.Petagrays:
        return super.internalMultiply(value, 1000000000000000);
      case AbsorbedDoseOfIonizingRadiationUnits.Millirads:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 0.001);
        }
      case AbsorbedDoseOfIonizingRadiationUnits.Kilorads:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 1000);
        }
      case AbsorbedDoseOfIonizingRadiationUnits.Megarads:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case AbsorbedDoseOfIonizingRadiationUnits.Grays:
        return value;
      case AbsorbedDoseOfIonizingRadiationUnits.Rads:
        return value / 100;
      case AbsorbedDoseOfIonizingRadiationUnits.Femtograys:
        return value * 1e-15;
      case AbsorbedDoseOfIonizingRadiationUnits.Picograys:
        return value * 1e-12;
      case AbsorbedDoseOfIonizingRadiationUnits.Nanograys:
        return value * 1e-9;
      case AbsorbedDoseOfIonizingRadiationUnits.Micrograys:
        return value * 0.000001;
      case AbsorbedDoseOfIonizingRadiationUnits.Milligrays:
        return value * 0.001;
      case AbsorbedDoseOfIonizingRadiationUnits.Centigrays:
        return value * 0.01;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilograys:
        return value * 1000;
      case AbsorbedDoseOfIonizingRadiationUnits.Megagrays:
        return value * 1000000;
      case AbsorbedDoseOfIonizingRadiationUnits.Gigagrays:
        return value * 1000000000;
      case AbsorbedDoseOfIonizingRadiationUnits.Teragrays:
        return value * 1000000000000;
      case AbsorbedDoseOfIonizingRadiationUnits.Petagrays:
        return value * 1000000000000000;
      case AbsorbedDoseOfIonizingRadiationUnits.Millirads:
        return value / 100 * 0.001;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilorads:
        return value / 100 * 1000;
      case AbsorbedDoseOfIonizingRadiationUnits.Megarads:
        return value / 100 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the AbsorbedDoseOfIonizingRadiation to string.
   * Note! the default format for AbsorbedDoseOfIonizingRadiation is Grays.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the AbsorbedDoseOfIonizingRadiation.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the AbsorbedDoseOfIonizingRadiation.
   */
  toString(unit = AbsorbedDoseOfIonizingRadiationUnits.Grays, fractionalDigits) {
    switch (unit) {
      case AbsorbedDoseOfIonizingRadiationUnits.Grays:
        return super.truncateFractionDigits(this.Grays, fractionalDigits) + ` Gy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Rads:
        return super.truncateFractionDigits(this.Rads, fractionalDigits) + ` rad`;
      case AbsorbedDoseOfIonizingRadiationUnits.Femtograys:
        return super.truncateFractionDigits(this.Femtograys, fractionalDigits) + ` fGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Picograys:
        return super.truncateFractionDigits(this.Picograys, fractionalDigits) + ` pGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Nanograys:
        return super.truncateFractionDigits(this.Nanograys, fractionalDigits) + ` nGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Micrograys:
        return super.truncateFractionDigits(this.Micrograys, fractionalDigits) + ` μGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Milligrays:
        return super.truncateFractionDigits(this.Milligrays, fractionalDigits) + ` mGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Centigrays:
        return super.truncateFractionDigits(this.Centigrays, fractionalDigits) + ` cGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilograys:
        return super.truncateFractionDigits(this.Kilograys, fractionalDigits) + ` kGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Megagrays:
        return super.truncateFractionDigits(this.Megagrays, fractionalDigits) + ` MGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Gigagrays:
        return super.truncateFractionDigits(this.Gigagrays, fractionalDigits) + ` GGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Teragrays:
        return super.truncateFractionDigits(this.Teragrays, fractionalDigits) + ` TGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Petagrays:
        return super.truncateFractionDigits(this.Petagrays, fractionalDigits) + ` PGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Millirads:
        return super.truncateFractionDigits(this.Millirads, fractionalDigits) + ` mrad`;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilorads:
        return super.truncateFractionDigits(this.Kilorads, fractionalDigits) + ` krad`;
      case AbsorbedDoseOfIonizingRadiationUnits.Megarads:
        return super.truncateFractionDigits(this.Megarads, fractionalDigits) + ` Mrad`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get AbsorbedDoseOfIonizingRadiation unit abbreviation.
   * Note! the default abbreviation for AbsorbedDoseOfIonizingRadiation is Grays.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the AbsorbedDoseOfIonizingRadiation.
   * @returns The abbreviation string of AbsorbedDoseOfIonizingRadiation.
   */
  getUnitAbbreviation(unitAbbreviation = AbsorbedDoseOfIonizingRadiationUnits.Grays) {
    switch (unitAbbreviation) {
      case AbsorbedDoseOfIonizingRadiationUnits.Grays:
        return `Gy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Rads:
        return `rad`;
      case AbsorbedDoseOfIonizingRadiationUnits.Femtograys:
        return `fGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Picograys:
        return `pGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Nanograys:
        return `nGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Micrograys:
        return `μGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Milligrays:
        return `mGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Centigrays:
        return `cGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilograys:
        return `kGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Megagrays:
        return `MGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Gigagrays:
        return `GGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Teragrays:
        return `TGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Petagrays:
        return `PGy`;
      case AbsorbedDoseOfIonizingRadiationUnits.Millirads:
        return `mrad`;
      case AbsorbedDoseOfIonizingRadiationUnits.Kilorads:
        return `krad`;
      case AbsorbedDoseOfIonizingRadiationUnits.Megarads:
        return `Mrad`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given AbsorbedDoseOfIonizingRadiation are equals to the current AbsorbedDoseOfIonizingRadiation.
   * @param absorbedDoseOfIonizingRadiation The other AbsorbedDoseOfIonizingRadiation.
   * @returns True if the given AbsorbedDoseOfIonizingRadiation are equal to the current AbsorbedDoseOfIonizingRadiation.
   */
  equals(absorbedDoseOfIonizingRadiation) {
    return super.internalEquals(this.value, absorbedDoseOfIonizingRadiation.BaseValue);
  }
  /**
   * Compare the given AbsorbedDoseOfIonizingRadiation against the current AbsorbedDoseOfIonizingRadiation.
   * @param absorbedDoseOfIonizingRadiation The other AbsorbedDoseOfIonizingRadiation.
   * @returns 0 if they are equal, -1 if the current AbsorbedDoseOfIonizingRadiation is less then other, 1 if the current AbsorbedDoseOfIonizingRadiation is greater then other.
   */
  compareTo(absorbedDoseOfIonizingRadiation) {
    return super.internalCompareTo(this.value, absorbedDoseOfIonizingRadiation.BaseValue);
  }
  /**
   * Add the given AbsorbedDoseOfIonizingRadiation with the current AbsorbedDoseOfIonizingRadiation.
   * @param absorbedDoseOfIonizingRadiation The other AbsorbedDoseOfIonizingRadiation.
   * @returns A new AbsorbedDoseOfIonizingRadiation instance with the results.
   */
  add(absorbedDoseOfIonizingRadiation) {
    return new AbsorbedDoseOfIonizingRadiation(super.internalAdd(this.value, absorbedDoseOfIonizingRadiation.BaseValue));
  }
  /**
   * Subtract the given AbsorbedDoseOfIonizingRadiation with the current AbsorbedDoseOfIonizingRadiation.
   * @param absorbedDoseOfIonizingRadiation The other AbsorbedDoseOfIonizingRadiation.
   * @returns A new AbsorbedDoseOfIonizingRadiation instance with the results.
   */
  subtract(absorbedDoseOfIonizingRadiation) {
    return new AbsorbedDoseOfIonizingRadiation(super.internalSubtract(this.value, absorbedDoseOfIonizingRadiation.BaseValue));
  }
  /**
   * Multiply the given AbsorbedDoseOfIonizingRadiation with the current AbsorbedDoseOfIonizingRadiation.
   * @param absorbedDoseOfIonizingRadiation The other AbsorbedDoseOfIonizingRadiation.
   * @returns A new AbsorbedDoseOfIonizingRadiation instance with the results.
   */
  multiply(absorbedDoseOfIonizingRadiation) {
    return new AbsorbedDoseOfIonizingRadiation(super.internalMultiply(this.value, absorbedDoseOfIonizingRadiation.BaseValue));
  }
  /**
   * Divide the given AbsorbedDoseOfIonizingRadiation with the current AbsorbedDoseOfIonizingRadiation.
   * @param absorbedDoseOfIonizingRadiation The other AbsorbedDoseOfIonizingRadiation.
   * @returns A new AbsorbedDoseOfIonizingRadiation instance with the results.
   */
  divide(absorbedDoseOfIonizingRadiation) {
    return new AbsorbedDoseOfIonizingRadiation(super.internalDivide(this.value, absorbedDoseOfIonizingRadiation.BaseValue));
  }
  /**
   * Modulo the given AbsorbedDoseOfIonizingRadiation with the current AbsorbedDoseOfIonizingRadiation.
   * @param absorbedDoseOfIonizingRadiation The other AbsorbedDoseOfIonizingRadiation.
   * @returns A new AbsorbedDoseOfIonizingRadiation instance with the results.
   */
  modulo(absorbedDoseOfIonizingRadiation) {
    return new AbsorbedDoseOfIonizingRadiation(super.internalModulo(this.value, absorbedDoseOfIonizingRadiation.BaseValue));
  }
  /**
   * Pow the given AbsorbedDoseOfIonizingRadiation with the current AbsorbedDoseOfIonizingRadiation.
   * @param absorbedDoseOfIonizingRadiation The other AbsorbedDoseOfIonizingRadiation.
   * @returns A new AbsorbedDoseOfIonizingRadiation instance with the results.
   */
  pow(absorbedDoseOfIonizingRadiation) {
    return new AbsorbedDoseOfIonizingRadiation(super.internalPow(this.value, absorbedDoseOfIonizingRadiation.BaseValue));
  }
}
exports.AbsorbedDoseOfIonizingRadiation = AbsorbedDoseOfIonizingRadiation;