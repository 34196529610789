import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { CountryLabelPO, PrismSeedTreatment, STiRResultListPO, STiRResult,STiRResultPO } from "../models";
import { AppConstants } from "../shared/constants";

@Injectable({
    providedIn: "root",
})
export class PrismSeedTreatmentService {

    constructor(private baseService: BaseService) { }

    FetchPrismSeedTreatment(): Observable<STiRResultListPO<PrismSeedTreatment>> {
        let url = "Admin/GetPrismSeedTreatment";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    SavePrismSeedTreatment(data: PrismSeedTreatment): Observable<STiRResultPO<PrismSeedTreatment>> {
        let url = `Admin/SubmitPrismSeedTreatment`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    updatePrismSeedTreatment(data: PrismSeedTreatment[]): Observable<STiRResultListPO<PrismSeedTreatment>> {
        let url = `Admin/updatePrismSeedTreatment`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    SaveCountryLabelPrismSeedTreatment(data: CountryLabelPO[]): Observable<STiRResultListPO<CountryLabelPO>> {
        let url = "Admin/SubmitCountryLabelPrismSeedTreatment";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }

    removePrismSeedTreatment(data: PrismSeedTreatment[]): Observable<STiRResult> {
        let url = `Admin/DeletePrismSeedTreatment`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    removeCountryLabelPrismSeedTreatment(data: string[]): Observable<STiRResult> {
        let url = `Admin/DeleteCountryLabelPrismSeedTreatment`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }
}