"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
__exportStar(require("./gen-units/absorbeddoseofionizingradiation.g"), exports);
__exportStar(require("./gen-units/acceleration.g"), exports);
__exportStar(require("./gen-units/amountofsubstance.g"), exports);
__exportStar(require("./gen-units/amplituderatio.g"), exports);
__exportStar(require("./gen-units/angle.g"), exports);
__exportStar(require("./gen-units/apparentenergy.g"), exports);
__exportStar(require("./gen-units/apparentpower.g"), exports);
__exportStar(require("./gen-units/area.g"), exports);
__exportStar(require("./gen-units/areadensity.g"), exports);
__exportStar(require("./gen-units/areamomentofinertia.g"), exports);
__exportStar(require("./gen-units/bitrate.g"), exports);
__exportStar(require("./gen-units/brakespecificfuelconsumption.g"), exports);
__exportStar(require("./gen-units/capacitance.g"), exports);
__exportStar(require("./gen-units/coefficientofthermalexpansion.g"), exports);
__exportStar(require("./gen-units/compressibility.g"), exports);
__exportStar(require("./gen-units/density.g"), exports);
__exportStar(require("./gen-units/duration.g"), exports);
__exportStar(require("./gen-units/dynamicviscosity.g"), exports);
__exportStar(require("./gen-units/electricadmittance.g"), exports);
__exportStar(require("./gen-units/electriccharge.g"), exports);
__exportStar(require("./gen-units/electricchargedensity.g"), exports);
__exportStar(require("./gen-units/electricconductance.g"), exports);
__exportStar(require("./gen-units/electricconductivity.g"), exports);
__exportStar(require("./gen-units/electriccurrent.g"), exports);
__exportStar(require("./gen-units/electriccurrentdensity.g"), exports);
__exportStar(require("./gen-units/electriccurrentgradient.g"), exports);
__exportStar(require("./gen-units/electricfield.g"), exports);
__exportStar(require("./gen-units/electricinductance.g"), exports);
__exportStar(require("./gen-units/electricpotential.g"), exports);
__exportStar(require("./gen-units/electricpotentialac.g"), exports);
__exportStar(require("./gen-units/electricpotentialchangerate.g"), exports);
__exportStar(require("./gen-units/electricpotentialdc.g"), exports);
__exportStar(require("./gen-units/electricresistance.g"), exports);
__exportStar(require("./gen-units/electricresistivity.g"), exports);
__exportStar(require("./gen-units/electricsurfacechargedensity.g"), exports);
__exportStar(require("./gen-units/energy.g"), exports);
__exportStar(require("./gen-units/energydensity.g"), exports);
__exportStar(require("./gen-units/entropy.g"), exports);
__exportStar(require("./gen-units/force.g"), exports);
__exportStar(require("./gen-units/forcechangerate.g"), exports);
__exportStar(require("./gen-units/forceperlength.g"), exports);
__exportStar(require("./gen-units/frequency.g"), exports);
__exportStar(require("./gen-units/fuelefficiency.g"), exports);
__exportStar(require("./gen-units/heatflux.g"), exports);
__exportStar(require("./gen-units/heattransfercoefficient.g"), exports);
__exportStar(require("./gen-units/illuminance.g"), exports);
__exportStar(require("./gen-units/impulse.g"), exports);
__exportStar(require("./gen-units/information.g"), exports);
__exportStar(require("./gen-units/irradiance.g"), exports);
__exportStar(require("./gen-units/irradiation.g"), exports);
__exportStar(require("./gen-units/jerk.g"), exports);
__exportStar(require("./gen-units/kinematicviscosity.g"), exports);
__exportStar(require("./gen-units/leakrate.g"), exports);
__exportStar(require("./gen-units/length.g"), exports);
__exportStar(require("./gen-units/level.g"), exports);
__exportStar(require("./gen-units/lineardensity.g"), exports);
__exportStar(require("./gen-units/linearpowerdensity.g"), exports);
__exportStar(require("./gen-units/luminance.g"), exports);
__exportStar(require("./gen-units/luminosity.g"), exports);
__exportStar(require("./gen-units/luminousflux.g"), exports);
__exportStar(require("./gen-units/luminousintensity.g"), exports);
__exportStar(require("./gen-units/magneticfield.g"), exports);
__exportStar(require("./gen-units/magneticflux.g"), exports);
__exportStar(require("./gen-units/magnetization.g"), exports);
__exportStar(require("./gen-units/mass.g"), exports);
__exportStar(require("./gen-units/massconcentration.g"), exports);
__exportStar(require("./gen-units/massflow.g"), exports);
__exportStar(require("./gen-units/massflux.g"), exports);
__exportStar(require("./gen-units/massfraction.g"), exports);
__exportStar(require("./gen-units/massmomentofinertia.g"), exports);
__exportStar(require("./gen-units/molality.g"), exports);
__exportStar(require("./gen-units/molarenergy.g"), exports);
__exportStar(require("./gen-units/molarentropy.g"), exports);
__exportStar(require("./gen-units/molarflow.g"), exports);
__exportStar(require("./gen-units/molarmass.g"), exports);
__exportStar(require("./gen-units/molarity.g"), exports);
__exportStar(require("./gen-units/permeability.g"), exports);
__exportStar(require("./gen-units/permittivity.g"), exports);
__exportStar(require("./gen-units/porousmediumpermeability.g"), exports);
__exportStar(require("./gen-units/power.g"), exports);
__exportStar(require("./gen-units/powerdensity.g"), exports);
__exportStar(require("./gen-units/powerratio.g"), exports);
__exportStar(require("./gen-units/pressure.g"), exports);
__exportStar(require("./gen-units/pressurechangerate.g"), exports);
__exportStar(require("./gen-units/radiationequivalentdose.g"), exports);
__exportStar(require("./gen-units/radiationexposure.g"), exports);
__exportStar(require("./gen-units/radioactivity.g"), exports);
__exportStar(require("./gen-units/ratio.g"), exports);
__exportStar(require("./gen-units/ratiochangerate.g"), exports);
__exportStar(require("./gen-units/reactiveenergy.g"), exports);
__exportStar(require("./gen-units/reactivepower.g"), exports);
__exportStar(require("./gen-units/reciprocalarea.g"), exports);
__exportStar(require("./gen-units/reciprocallength.g"), exports);
__exportStar(require("./gen-units/relativehumidity.g"), exports);
__exportStar(require("./gen-units/rotationalacceleration.g"), exports);
__exportStar(require("./gen-units/rotationalspeed.g"), exports);
__exportStar(require("./gen-units/rotationalstiffness.g"), exports);
__exportStar(require("./gen-units/rotationalstiffnessperlength.g"), exports);
__exportStar(require("./gen-units/scalar.g"), exports);
__exportStar(require("./gen-units/solidangle.g"), exports);
__exportStar(require("./gen-units/specificenergy.g"), exports);
__exportStar(require("./gen-units/specificentropy.g"), exports);
__exportStar(require("./gen-units/specificfuelconsumption.g"), exports);
__exportStar(require("./gen-units/specificvolume.g"), exports);
__exportStar(require("./gen-units/specificweight.g"), exports);
__exportStar(require("./gen-units/speed.g"), exports);
__exportStar(require("./gen-units/standardvolumeflow.g"), exports);
__exportStar(require("./gen-units/temperature.g"), exports);
__exportStar(require("./gen-units/temperaturechangerate.g"), exports);
__exportStar(require("./gen-units/temperaturedelta.g"), exports);
__exportStar(require("./gen-units/temperaturegradient.g"), exports);
__exportStar(require("./gen-units/thermalconductivity.g"), exports);
__exportStar(require("./gen-units/thermalresistance.g"), exports);
__exportStar(require("./gen-units/torque.g"), exports);
__exportStar(require("./gen-units/torqueperlength.g"), exports);
__exportStar(require("./gen-units/turbidity.g"), exports);
__exportStar(require("./gen-units/vitamina.g"), exports);
__exportStar(require("./gen-units/volume.g"), exports);
__exportStar(require("./gen-units/volumeconcentration.g"), exports);
__exportStar(require("./gen-units/volumeflow.g"), exports);
__exportStar(require("./gen-units/volumeflowperarea.g"), exports);
__exportStar(require("./gen-units/volumeperlength.g"), exports);
__exportStar(require("./gen-units/volumetricheatcapacity.g"), exports);
__exportStar(require("./gen-units/warpingmomentofinertia.g"), exports);
var base_unit_1 = require("./base-unit");
Object.defineProperty(exports, "ArithmeticOperation", {
  enumerable: true,
  get: function () {
    return base_unit_1.ArithmeticOperation;
  }
});
Object.defineProperty(exports, "CompareOperation", {
  enumerable: true,
  get: function () {
    return base_unit_1.CompareOperation;
  }
});
Object.defineProperty(exports, "setOperatorOverride", {
  enumerable: true,
  get: function () {
    return base_unit_1.setOperatorOverride;
  }
});
Object.defineProperty(exports, "unsetOperatorOverride", {
  enumerable: true,
  get: function () {
    return base_unit_1.unsetOperatorOverride;
  }
});
Object.defineProperty(exports, "unsetAllOperatorOverrides", {
  enumerable: true,
  get: function () {
    return base_unit_1.unsetAllOperatorOverrides;
  }
});
Object.defineProperty(exports, "areAnyOperatorsOverridden", {
  enumerable: true,
  get: function () {
    return base_unit_1.areAnyOperatorsOverridden;
  }
});