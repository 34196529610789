"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VitaminA = exports.VitaminAUnits = void 0;
const base_unit_1 = require("../base-unit");
/** VitaminAUnits enumeration */
var VitaminAUnits;
(function (VitaminAUnits) {
  /** */
  VitaminAUnits["InternationalUnits"] = "InternationalUnit";
})(VitaminAUnits = exports.VitaminAUnits || (exports.VitaminAUnits = {}));
/** Vitamin A: 1 IU is the biological equivalent of 0.3 µg retinol, or of 0.6 µg beta-carotene. */
class VitaminA extends base_unit_1.BaseUnit {
  /**
   * Create a new VitaminA.
   * @param value The value.
   * @param fromUnit The ‘VitaminA’ unit to create from.
   * The default unit is InternationalUnits
   */
  constructor(value, fromUnit = VitaminAUnits.InternationalUnits) {
    super();
    this.internationalunitsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of VitaminA is InternationalUnits.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return VitaminAUnits.InternationalUnits;
  }
  /** */
  get InternationalUnits() {
    if (this.internationalunitsLazy !== null) {
      return this.internationalunitsLazy;
    }
    return this.internationalunitsLazy = this.convertFromBase(VitaminAUnits.InternationalUnits);
  }
  /**
   * Create a new VitaminA instance from a InternationalUnits
   *
   * @param value The unit as InternationalUnits to create a new VitaminA from.
   * @returns The new VitaminA instance.
   */
  static FromInternationalUnits(value) {
    return new VitaminA(value, VitaminAUnits.InternationalUnits);
  }
  /**
   * Gets the base unit enumeration associated with VitaminA
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return VitaminAUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return VitaminAUnits.InternationalUnits;
  }
  /**
   * Create API DTO represent a VitaminA unit.
   * @param holdInUnit The specific VitaminA unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = VitaminAUnits.InternationalUnits) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a VitaminA unit from an API DTO representation.
   * @param dtoVitaminA The VitaminA API DTO representation
   */
  static FromDto(dtoVitaminA) {
    return new VitaminA(dtoVitaminA.value, dtoVitaminA.unit);
  }
  /**
   * Convert VitaminA to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case VitaminAUnits.InternationalUnits:
        return this.InternationalUnits;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case VitaminAUnits.InternationalUnits:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case VitaminAUnits.InternationalUnits:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case VitaminAUnits.InternationalUnits:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case VitaminAUnits.InternationalUnits:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the VitaminA to string.
   * Note! the default format for VitaminA is InternationalUnits.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the VitaminA.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the VitaminA.
   */
  toString(unit = VitaminAUnits.InternationalUnits, fractionalDigits) {
    switch (unit) {
      case VitaminAUnits.InternationalUnits:
        return super.truncateFractionDigits(this.InternationalUnits, fractionalDigits) + ` IU`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get VitaminA unit abbreviation.
   * Note! the default abbreviation for VitaminA is InternationalUnits.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the VitaminA.
   * @returns The abbreviation string of VitaminA.
   */
  getUnitAbbreviation(unitAbbreviation = VitaminAUnits.InternationalUnits) {
    switch (unitAbbreviation) {
      case VitaminAUnits.InternationalUnits:
        return `IU`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given VitaminA are equals to the current VitaminA.
   * @param vitaminA The other VitaminA.
   * @returns True if the given VitaminA are equal to the current VitaminA.
   */
  equals(vitaminA) {
    return super.internalEquals(this.value, vitaminA.BaseValue);
  }
  /**
   * Compare the given VitaminA against the current VitaminA.
   * @param vitaminA The other VitaminA.
   * @returns 0 if they are equal, -1 if the current VitaminA is less then other, 1 if the current VitaminA is greater then other.
   */
  compareTo(vitaminA) {
    return super.internalCompareTo(this.value, vitaminA.BaseValue);
  }
  /**
   * Add the given VitaminA with the current VitaminA.
   * @param vitaminA The other VitaminA.
   * @returns A new VitaminA instance with the results.
   */
  add(vitaminA) {
    return new VitaminA(super.internalAdd(this.value, vitaminA.BaseValue));
  }
  /**
   * Subtract the given VitaminA with the current VitaminA.
   * @param vitaminA The other VitaminA.
   * @returns A new VitaminA instance with the results.
   */
  subtract(vitaminA) {
    return new VitaminA(super.internalSubtract(this.value, vitaminA.BaseValue));
  }
  /**
   * Multiply the given VitaminA with the current VitaminA.
   * @param vitaminA The other VitaminA.
   * @returns A new VitaminA instance with the results.
   */
  multiply(vitaminA) {
    return new VitaminA(super.internalMultiply(this.value, vitaminA.BaseValue));
  }
  /**
   * Divide the given VitaminA with the current VitaminA.
   * @param vitaminA The other VitaminA.
   * @returns A new VitaminA instance with the results.
   */
  divide(vitaminA) {
    return new VitaminA(super.internalDivide(this.value, vitaminA.BaseValue));
  }
  /**
   * Modulo the given VitaminA with the current VitaminA.
   * @param vitaminA The other VitaminA.
   * @returns A new VitaminA instance with the results.
   */
  modulo(vitaminA) {
    return new VitaminA(super.internalModulo(this.value, vitaminA.BaseValue));
  }
  /**
   * Pow the given VitaminA with the current VitaminA.
   * @param vitaminA The other VitaminA.
   * @returns A new VitaminA instance with the results.
   */
  pow(vitaminA) {
    return new VitaminA(super.internalPow(this.value, vitaminA.BaseValue));
  }
}
exports.VitaminA = VitaminA;