"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricField = exports.ElectricFieldUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricFieldUnits enumeration */
var ElectricFieldUnits;
(function (ElectricFieldUnits) {
  /** */
  ElectricFieldUnits["VoltsPerMeter"] = "VoltPerMeter";
})(ElectricFieldUnits = exports.ElectricFieldUnits || (exports.ElectricFieldUnits = {}));
/** An electric field is a force field that surrounds electric charges that attracts or repels other electric charges. */
class ElectricField extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricField.
   * @param value The value.
   * @param fromUnit The ‘ElectricField’ unit to create from.
   * The default unit is VoltsPerMeter
   */
  constructor(value, fromUnit = ElectricFieldUnits.VoltsPerMeter) {
    super();
    this.voltspermeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricField is VoltsPerMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricFieldUnits.VoltsPerMeter;
  }
  /** */
  get VoltsPerMeter() {
    if (this.voltspermeterLazy !== null) {
      return this.voltspermeterLazy;
    }
    return this.voltspermeterLazy = this.convertFromBase(ElectricFieldUnits.VoltsPerMeter);
  }
  /**
   * Create a new ElectricField instance from a VoltsPerMeter
   *
   * @param value The unit as VoltsPerMeter to create a new ElectricField from.
   * @returns The new ElectricField instance.
   */
  static FromVoltsPerMeter(value) {
    return new ElectricField(value, ElectricFieldUnits.VoltsPerMeter);
  }
  /**
   * Gets the base unit enumeration associated with ElectricField
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricFieldUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricFieldUnits.VoltsPerMeter;
  }
  /**
   * Create API DTO represent a ElectricField unit.
   * @param holdInUnit The specific ElectricField unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricFieldUnits.VoltsPerMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricField unit from an API DTO representation.
   * @param dtoElectricField The ElectricField API DTO representation
   */
  static FromDto(dtoElectricField) {
    return new ElectricField(dtoElectricField.value, dtoElectricField.unit);
  }
  /**
   * Convert ElectricField to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricFieldUnits.VoltsPerMeter:
        return this.VoltsPerMeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricFieldUnits.VoltsPerMeter:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricFieldUnits.VoltsPerMeter:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricFieldUnits.VoltsPerMeter:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricFieldUnits.VoltsPerMeter:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricField to string.
   * Note! the default format for ElectricField is VoltsPerMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricField.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricField.
   */
  toString(unit = ElectricFieldUnits.VoltsPerMeter, fractionalDigits) {
    switch (unit) {
      case ElectricFieldUnits.VoltsPerMeter:
        return super.truncateFractionDigits(this.VoltsPerMeter, fractionalDigits) + ` V/m`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricField unit abbreviation.
   * Note! the default abbreviation for ElectricField is VoltsPerMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricField.
   * @returns The abbreviation string of ElectricField.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricFieldUnits.VoltsPerMeter) {
    switch (unitAbbreviation) {
      case ElectricFieldUnits.VoltsPerMeter:
        return `V/m`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricField are equals to the current ElectricField.
   * @param electricField The other ElectricField.
   * @returns True if the given ElectricField are equal to the current ElectricField.
   */
  equals(electricField) {
    return super.internalEquals(this.value, electricField.BaseValue);
  }
  /**
   * Compare the given ElectricField against the current ElectricField.
   * @param electricField The other ElectricField.
   * @returns 0 if they are equal, -1 if the current ElectricField is less then other, 1 if the current ElectricField is greater then other.
   */
  compareTo(electricField) {
    return super.internalCompareTo(this.value, electricField.BaseValue);
  }
  /**
   * Add the given ElectricField with the current ElectricField.
   * @param electricField The other ElectricField.
   * @returns A new ElectricField instance with the results.
   */
  add(electricField) {
    return new ElectricField(super.internalAdd(this.value, electricField.BaseValue));
  }
  /**
   * Subtract the given ElectricField with the current ElectricField.
   * @param electricField The other ElectricField.
   * @returns A new ElectricField instance with the results.
   */
  subtract(electricField) {
    return new ElectricField(super.internalSubtract(this.value, electricField.BaseValue));
  }
  /**
   * Multiply the given ElectricField with the current ElectricField.
   * @param electricField The other ElectricField.
   * @returns A new ElectricField instance with the results.
   */
  multiply(electricField) {
    return new ElectricField(super.internalMultiply(this.value, electricField.BaseValue));
  }
  /**
   * Divide the given ElectricField with the current ElectricField.
   * @param electricField The other ElectricField.
   * @returns A new ElectricField instance with the results.
   */
  divide(electricField) {
    return new ElectricField(super.internalDivide(this.value, electricField.BaseValue));
  }
  /**
   * Modulo the given ElectricField with the current ElectricField.
   * @param electricField The other ElectricField.
   * @returns A new ElectricField instance with the results.
   */
  modulo(electricField) {
    return new ElectricField(super.internalModulo(this.value, electricField.BaseValue));
  }
  /**
   * Pow the given ElectricField with the current ElectricField.
   * @param electricField The other ElectricField.
   * @returns A new ElectricField instance with the results.
   */
  pow(electricField) {
    return new ElectricField(super.internalPow(this.value, electricField.BaseValue));
  }
}
exports.ElectricField = ElectricField;