"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Turbidity = exports.TurbidityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** TurbidityUnits enumeration */
var TurbidityUnits;
(function (TurbidityUnits) {
  /** */
  TurbidityUnits["NTU"] = "NTU";
})(TurbidityUnits = exports.TurbidityUnits || (exports.TurbidityUnits = {}));
/** Turbidity is the cloudiness or haziness of a fluid caused by large numbers of individual particles that are generally invisible to the naked eye, similar to smoke in air. The measurement of turbidity is a key test of water quality. */
class Turbidity extends base_unit_1.BaseUnit {
  /**
   * Create a new Turbidity.
   * @param value The value.
   * @param fromUnit The ‘Turbidity’ unit to create from.
   * The default unit is NTU
   */
  constructor(value, fromUnit = TurbidityUnits.NTU) {
    super();
    this.ntuLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Turbidity is NTU.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return TurbidityUnits.NTU;
  }
  /** */
  get NTU() {
    if (this.ntuLazy !== null) {
      return this.ntuLazy;
    }
    return this.ntuLazy = this.convertFromBase(TurbidityUnits.NTU);
  }
  /**
   * Create a new Turbidity instance from a NTU
   *
   * @param value The unit as NTU to create a new Turbidity from.
   * @returns The new Turbidity instance.
   */
  static FromNTU(value) {
    return new Turbidity(value, TurbidityUnits.NTU);
  }
  /**
   * Gets the base unit enumeration associated with Turbidity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return TurbidityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return TurbidityUnits.NTU;
  }
  /**
   * Create API DTO represent a Turbidity unit.
   * @param holdInUnit The specific Turbidity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = TurbidityUnits.NTU) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Turbidity unit from an API DTO representation.
   * @param dtoTurbidity The Turbidity API DTO representation
   */
  static FromDto(dtoTurbidity) {
    return new Turbidity(dtoTurbidity.value, dtoTurbidity.unit);
  }
  /**
   * Convert Turbidity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case TurbidityUnits.NTU:
        return this.NTU;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case TurbidityUnits.NTU:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case TurbidityUnits.NTU:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case TurbidityUnits.NTU:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case TurbidityUnits.NTU:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Turbidity to string.
   * Note! the default format for Turbidity is NTU.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Turbidity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Turbidity.
   */
  toString(unit = TurbidityUnits.NTU, fractionalDigits) {
    switch (unit) {
      case TurbidityUnits.NTU:
        return super.truncateFractionDigits(this.NTU, fractionalDigits) + ` NTU`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Turbidity unit abbreviation.
   * Note! the default abbreviation for Turbidity is NTU.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Turbidity.
   * @returns The abbreviation string of Turbidity.
   */
  getUnitAbbreviation(unitAbbreviation = TurbidityUnits.NTU) {
    switch (unitAbbreviation) {
      case TurbidityUnits.NTU:
        return `NTU`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Turbidity are equals to the current Turbidity.
   * @param turbidity The other Turbidity.
   * @returns True if the given Turbidity are equal to the current Turbidity.
   */
  equals(turbidity) {
    return super.internalEquals(this.value, turbidity.BaseValue);
  }
  /**
   * Compare the given Turbidity against the current Turbidity.
   * @param turbidity The other Turbidity.
   * @returns 0 if they are equal, -1 if the current Turbidity is less then other, 1 if the current Turbidity is greater then other.
   */
  compareTo(turbidity) {
    return super.internalCompareTo(this.value, turbidity.BaseValue);
  }
  /**
   * Add the given Turbidity with the current Turbidity.
   * @param turbidity The other Turbidity.
   * @returns A new Turbidity instance with the results.
   */
  add(turbidity) {
    return new Turbidity(super.internalAdd(this.value, turbidity.BaseValue));
  }
  /**
   * Subtract the given Turbidity with the current Turbidity.
   * @param turbidity The other Turbidity.
   * @returns A new Turbidity instance with the results.
   */
  subtract(turbidity) {
    return new Turbidity(super.internalSubtract(this.value, turbidity.BaseValue));
  }
  /**
   * Multiply the given Turbidity with the current Turbidity.
   * @param turbidity The other Turbidity.
   * @returns A new Turbidity instance with the results.
   */
  multiply(turbidity) {
    return new Turbidity(super.internalMultiply(this.value, turbidity.BaseValue));
  }
  /**
   * Divide the given Turbidity with the current Turbidity.
   * @param turbidity The other Turbidity.
   * @returns A new Turbidity instance with the results.
   */
  divide(turbidity) {
    return new Turbidity(super.internalDivide(this.value, turbidity.BaseValue));
  }
  /**
   * Modulo the given Turbidity with the current Turbidity.
   * @param turbidity The other Turbidity.
   * @returns A new Turbidity instance with the results.
   */
  modulo(turbidity) {
    return new Turbidity(super.internalModulo(this.value, turbidity.BaseValue));
  }
  /**
   * Pow the given Turbidity with the current Turbidity.
   * @param turbidity The other Turbidity.
   * @returns A new Turbidity instance with the results.
   */
  pow(turbidity) {
    return new Turbidity(super.internalPow(this.value, turbidity.BaseValue));
  }
}
exports.Turbidity = Turbidity;