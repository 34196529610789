import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy, OnChanges {
  private loaderSubscription: Subscription = new Subscription();

  @Input() public imageSrc: any;
  @Input() public debounceTime = 300;
  public message  = '';

  constructor(private loader: LoadingService) { }

  ngOnInit(): void {

      this.loader.setMessageDelay(this.debounceTime);

      this.loaderSubscription = this.loader
          .onMessageUpdate()
          .subscribe(message => {
              this.message = message;
          });
  }

  ngOnDestroy(): void {
      if (this.loaderSubscription) {
          this.loaderSubscription.unsubscribe();
          this.loaderSubscription.remove;
      }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['debounceTime']) {
          this.loader.setMessageDelay(changes['debounceTime'].currentValue);
      }
  }
}