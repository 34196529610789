import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private count: number = 0;
    private messageSubject = new ReplaySubject<any>(1);
    private messageDelay = 100;

    private messageTimer: any;

    public clearMessage() {
        this.count--;
        if (this.count == 0) {
            this.clearMessageTimerIfSet();
            this.messageSubject.next(null);
        }
    }

    public setMessage(message: string) {
        this.clearMessageTimerIfSet();
        this.messageTimer = setTimeout(() => {
            this.messageSubject.next(message);
        }, this.messageDelay);
        this.count++;
    }

    public onMessageUpdate(): Observable<string> {
        return this.messageSubject.asObservable();
    }

    public setMessageDelay(delay: number) {
        this.messageDelay = delay;
    }

    private clearMessageTimerIfSet() {
        if (this.messageTimer) {
            clearTimeout(this.messageTimer);
            this.messageTimer = null;
        }
    }
}
