"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VolumetricHeatCapacity = exports.VolumetricHeatCapacityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** VolumetricHeatCapacityUnits enumeration */
var VolumetricHeatCapacityUnits;
(function (VolumetricHeatCapacityUnits) {
  /** */
  VolumetricHeatCapacityUnits["JoulesPerCubicMeterKelvin"] = "JoulePerCubicMeterKelvin";
  /** */
  VolumetricHeatCapacityUnits["JoulesPerCubicMeterDegreeCelsius"] = "JoulePerCubicMeterDegreeCelsius";
  /** */
  VolumetricHeatCapacityUnits["CaloriesPerCubicCentimeterDegreeCelsius"] = "CaloriePerCubicCentimeterDegreeCelsius";
  /** */
  VolumetricHeatCapacityUnits["BtusPerCubicFootDegreeFahrenheit"] = "BtuPerCubicFootDegreeFahrenheit";
  /** */
  VolumetricHeatCapacityUnits["KilojoulesPerCubicMeterKelvin"] = "KilojoulePerCubicMeterKelvin";
  /** */
  VolumetricHeatCapacityUnits["MegajoulesPerCubicMeterKelvin"] = "MegajoulePerCubicMeterKelvin";
  /** */
  VolumetricHeatCapacityUnits["KilojoulesPerCubicMeterDegreeCelsius"] = "KilojoulePerCubicMeterDegreeCelsius";
  /** */
  VolumetricHeatCapacityUnits["MegajoulesPerCubicMeterDegreeCelsius"] = "MegajoulePerCubicMeterDegreeCelsius";
  /** */
  VolumetricHeatCapacityUnits["KilocaloriesPerCubicCentimeterDegreeCelsius"] = "KilocaloriePerCubicCentimeterDegreeCelsius";
})(VolumetricHeatCapacityUnits = exports.VolumetricHeatCapacityUnits || (exports.VolumetricHeatCapacityUnits = {}));
/** The volumetric heat capacity is the amount of energy that must be added, in the form of heat, to one unit of volume of the material in order to cause an increase of one unit in its temperature. */
class VolumetricHeatCapacity extends base_unit_1.BaseUnit {
  /**
   * Create a new VolumetricHeatCapacity.
   * @param value The value.
   * @param fromUnit The ‘VolumetricHeatCapacity’ unit to create from.
   * The default unit is JoulesPerCubicMeterKelvin
   */
  constructor(value, fromUnit = VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin) {
    super();
    this.joulespercubicmeterkelvinLazy = null;
    this.joulespercubicmeterdegreecelsiusLazy = null;
    this.caloriespercubiccentimeterdegreecelsiusLazy = null;
    this.btuspercubicfootdegreefahrenheitLazy = null;
    this.kilojoulespercubicmeterkelvinLazy = null;
    this.megajoulespercubicmeterkelvinLazy = null;
    this.kilojoulespercubicmeterdegreecelsiusLazy = null;
    this.megajoulespercubicmeterdegreecelsiusLazy = null;
    this.kilocaloriespercubiccentimeterdegreecelsiusLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of VolumetricHeatCapacity is JoulesPerCubicMeterKelvin.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin;
  }
  /** */
  get JoulesPerCubicMeterKelvin() {
    if (this.joulespercubicmeterkelvinLazy !== null) {
      return this.joulespercubicmeterkelvinLazy;
    }
    return this.joulespercubicmeterkelvinLazy = this.convertFromBase(VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin);
  }
  /** */
  get JoulesPerCubicMeterDegreeCelsius() {
    if (this.joulespercubicmeterdegreecelsiusLazy !== null) {
      return this.joulespercubicmeterdegreecelsiusLazy;
    }
    return this.joulespercubicmeterdegreecelsiusLazy = this.convertFromBase(VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius);
  }
  /** */
  get CaloriesPerCubicCentimeterDegreeCelsius() {
    if (this.caloriespercubiccentimeterdegreecelsiusLazy !== null) {
      return this.caloriespercubiccentimeterdegreecelsiusLazy;
    }
    return this.caloriespercubiccentimeterdegreecelsiusLazy = this.convertFromBase(VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius);
  }
  /** */
  get BtusPerCubicFootDegreeFahrenheit() {
    if (this.btuspercubicfootdegreefahrenheitLazy !== null) {
      return this.btuspercubicfootdegreefahrenheitLazy;
    }
    return this.btuspercubicfootdegreefahrenheitLazy = this.convertFromBase(VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit);
  }
  /** */
  get KilojoulesPerCubicMeterKelvin() {
    if (this.kilojoulespercubicmeterkelvinLazy !== null) {
      return this.kilojoulespercubicmeterkelvinLazy;
    }
    return this.kilojoulespercubicmeterkelvinLazy = this.convertFromBase(VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin);
  }
  /** */
  get MegajoulesPerCubicMeterKelvin() {
    if (this.megajoulespercubicmeterkelvinLazy !== null) {
      return this.megajoulespercubicmeterkelvinLazy;
    }
    return this.megajoulespercubicmeterkelvinLazy = this.convertFromBase(VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin);
  }
  /** */
  get KilojoulesPerCubicMeterDegreeCelsius() {
    if (this.kilojoulespercubicmeterdegreecelsiusLazy !== null) {
      return this.kilojoulespercubicmeterdegreecelsiusLazy;
    }
    return this.kilojoulespercubicmeterdegreecelsiusLazy = this.convertFromBase(VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius);
  }
  /** */
  get MegajoulesPerCubicMeterDegreeCelsius() {
    if (this.megajoulespercubicmeterdegreecelsiusLazy !== null) {
      return this.megajoulespercubicmeterdegreecelsiusLazy;
    }
    return this.megajoulespercubicmeterdegreecelsiusLazy = this.convertFromBase(VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius);
  }
  /** */
  get KilocaloriesPerCubicCentimeterDegreeCelsius() {
    if (this.kilocaloriespercubiccentimeterdegreecelsiusLazy !== null) {
      return this.kilocaloriespercubiccentimeterdegreecelsiusLazy;
    }
    return this.kilocaloriespercubiccentimeterdegreecelsiusLazy = this.convertFromBase(VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a JoulesPerCubicMeterKelvin
   *
   * @param value The unit as JoulesPerCubicMeterKelvin to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromJoulesPerCubicMeterKelvin(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a JoulesPerCubicMeterDegreeCelsius
   *
   * @param value The unit as JoulesPerCubicMeterDegreeCelsius to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromJoulesPerCubicMeterDegreeCelsius(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a CaloriesPerCubicCentimeterDegreeCelsius
   *
   * @param value The unit as CaloriesPerCubicCentimeterDegreeCelsius to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromCaloriesPerCubicCentimeterDegreeCelsius(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a BtusPerCubicFootDegreeFahrenheit
   *
   * @param value The unit as BtusPerCubicFootDegreeFahrenheit to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromBtusPerCubicFootDegreeFahrenheit(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a KilojoulesPerCubicMeterKelvin
   *
   * @param value The unit as KilojoulesPerCubicMeterKelvin to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromKilojoulesPerCubicMeterKelvin(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a MegajoulesPerCubicMeterKelvin
   *
   * @param value The unit as MegajoulesPerCubicMeterKelvin to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromMegajoulesPerCubicMeterKelvin(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a KilojoulesPerCubicMeterDegreeCelsius
   *
   * @param value The unit as KilojoulesPerCubicMeterDegreeCelsius to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromKilojoulesPerCubicMeterDegreeCelsius(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a MegajoulesPerCubicMeterDegreeCelsius
   *
   * @param value The unit as MegajoulesPerCubicMeterDegreeCelsius to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromMegajoulesPerCubicMeterDegreeCelsius(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius);
  }
  /**
   * Create a new VolumetricHeatCapacity instance from a KilocaloriesPerCubicCentimeterDegreeCelsius
   *
   * @param value The unit as KilocaloriesPerCubicCentimeterDegreeCelsius to create a new VolumetricHeatCapacity from.
   * @returns The new VolumetricHeatCapacity instance.
   */
  static FromKilocaloriesPerCubicCentimeterDegreeCelsius(value) {
    return new VolumetricHeatCapacity(value, VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius);
  }
  /**
   * Gets the base unit enumeration associated with VolumetricHeatCapacity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return VolumetricHeatCapacityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin;
  }
  /**
   * Create API DTO represent a VolumetricHeatCapacity unit.
   * @param holdInUnit The specific VolumetricHeatCapacity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a VolumetricHeatCapacity unit from an API DTO representation.
   * @param dtoVolumetricHeatCapacity The VolumetricHeatCapacity API DTO representation
   */
  static FromDto(dtoVolumetricHeatCapacity) {
    return new VolumetricHeatCapacity(dtoVolumetricHeatCapacity.value, dtoVolumetricHeatCapacity.unit);
  }
  /**
   * Convert VolumetricHeatCapacity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin:
        return this.JoulesPerCubicMeterKelvin;
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius:
        return this.JoulesPerCubicMeterDegreeCelsius;
      case VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius:
        return this.CaloriesPerCubicCentimeterDegreeCelsius;
      case VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit:
        return this.BtusPerCubicFootDegreeFahrenheit;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin:
        return this.KilojoulesPerCubicMeterKelvin;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin:
        return this.MegajoulesPerCubicMeterKelvin;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius:
        return this.KilojoulesPerCubicMeterDegreeCelsius;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius:
        return this.MegajoulesPerCubicMeterDegreeCelsius;
      case VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius:
        return this.KilocaloriesPerCubicCentimeterDegreeCelsius;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin:
        return this.value;
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius:
        return this.value;
      case VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius:
        return super.internalMultiply(this.value, 2.388459e-7);
      case VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit:
        return super.internalMultiply(this.value, 1.4910660e-5);
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin:
        return super.internalDivide(this.value, 1000);
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin:
        return super.internalDivide(this.value, 1000000);
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius:
        return super.internalDivide(this.value, 1000);
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius:
        return super.internalDivide(this.value, 1000000);
      case VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius:
        {
          const v3 = super.internalMultiply(this.value, 2.388459e-7);
          return super.internalDivide(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin:
        return this.value;
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius:
        return this.value;
      case VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius:
        return this.value * 2.388459e-7;
      case VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit:
        return this.value * 1.4910660e-5;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin:
        return this.value / 1000;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin:
        return this.value / 1000000;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius:
        return this.value / 1000;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius:
        return this.value / 1000000;
      case VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius:
        return this.value * 2.388459e-7 / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin:
        return value;
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius:
        return value;
      case VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius:
        return super.internalDivide(value, 2.388459e-7);
      case VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit:
        return super.internalDivide(value, 1.4910660e-5);
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin:
        return super.internalMultiply(value, 1000);
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin:
        return super.internalMultiply(value, 1000000);
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius:
        return super.internalMultiply(value, 1000);
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius:
        return super.internalMultiply(value, 1000000);
      case VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius:
        {
          const v3 = super.internalDivide(value, 2.388459e-7);
          return super.internalMultiply(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin:
        return value;
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius:
        return value;
      case VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius:
        return value / 2.388459e-7;
      case VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit:
        return value / 1.4910660e-5;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin:
        return value * 1000;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin:
        return value * 1000000;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius:
        return value * 1000;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius:
        return value * 1000000;
      case VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius:
        return value / 2.388459e-7 * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the VolumetricHeatCapacity to string.
   * Note! the default format for VolumetricHeatCapacity is JoulesPerCubicMeterKelvin.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the VolumetricHeatCapacity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the VolumetricHeatCapacity.
   */
  toString(unit = VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin, fractionalDigits) {
    switch (unit) {
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin:
        return super.truncateFractionDigits(this.JoulesPerCubicMeterKelvin, fractionalDigits) + ` J/m³·K`;
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius:
        return super.truncateFractionDigits(this.JoulesPerCubicMeterDegreeCelsius, fractionalDigits) + ` J/m³·°C`;
      case VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius:
        return super.truncateFractionDigits(this.CaloriesPerCubicCentimeterDegreeCelsius, fractionalDigits) + ` cal/cm³·°C`;
      case VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit:
        return super.truncateFractionDigits(this.BtusPerCubicFootDegreeFahrenheit, fractionalDigits) + ` BTU/ft³·°F`;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin:
        return super.truncateFractionDigits(this.KilojoulesPerCubicMeterKelvin, fractionalDigits) + ` kJ/m³·K`;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin:
        return super.truncateFractionDigits(this.MegajoulesPerCubicMeterKelvin, fractionalDigits) + ` MJ/m³·K`;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius:
        return super.truncateFractionDigits(this.KilojoulesPerCubicMeterDegreeCelsius, fractionalDigits) + ` kJ/m³·°C`;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius:
        return super.truncateFractionDigits(this.MegajoulesPerCubicMeterDegreeCelsius, fractionalDigits) + ` MJ/m³·°C`;
      case VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius:
        return super.truncateFractionDigits(this.KilocaloriesPerCubicCentimeterDegreeCelsius, fractionalDigits) + ` kcal/cm³·°C`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get VolumetricHeatCapacity unit abbreviation.
   * Note! the default abbreviation for VolumetricHeatCapacity is JoulesPerCubicMeterKelvin.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the VolumetricHeatCapacity.
   * @returns The abbreviation string of VolumetricHeatCapacity.
   */
  getUnitAbbreviation(unitAbbreviation = VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin) {
    switch (unitAbbreviation) {
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterKelvin:
        return `J/m³·K`;
      case VolumetricHeatCapacityUnits.JoulesPerCubicMeterDegreeCelsius:
        return `J/m³·°C`;
      case VolumetricHeatCapacityUnits.CaloriesPerCubicCentimeterDegreeCelsius:
        return `cal/cm³·°C`;
      case VolumetricHeatCapacityUnits.BtusPerCubicFootDegreeFahrenheit:
        return `BTU/ft³·°F`;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterKelvin:
        return `kJ/m³·K`;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterKelvin:
        return `MJ/m³·K`;
      case VolumetricHeatCapacityUnits.KilojoulesPerCubicMeterDegreeCelsius:
        return `kJ/m³·°C`;
      case VolumetricHeatCapacityUnits.MegajoulesPerCubicMeterDegreeCelsius:
        return `MJ/m³·°C`;
      case VolumetricHeatCapacityUnits.KilocaloriesPerCubicCentimeterDegreeCelsius:
        return `kcal/cm³·°C`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given VolumetricHeatCapacity are equals to the current VolumetricHeatCapacity.
   * @param volumetricHeatCapacity The other VolumetricHeatCapacity.
   * @returns True if the given VolumetricHeatCapacity are equal to the current VolumetricHeatCapacity.
   */
  equals(volumetricHeatCapacity) {
    return super.internalEquals(this.value, volumetricHeatCapacity.BaseValue);
  }
  /**
   * Compare the given VolumetricHeatCapacity against the current VolumetricHeatCapacity.
   * @param volumetricHeatCapacity The other VolumetricHeatCapacity.
   * @returns 0 if they are equal, -1 if the current VolumetricHeatCapacity is less then other, 1 if the current VolumetricHeatCapacity is greater then other.
   */
  compareTo(volumetricHeatCapacity) {
    return super.internalCompareTo(this.value, volumetricHeatCapacity.BaseValue);
  }
  /**
   * Add the given VolumetricHeatCapacity with the current VolumetricHeatCapacity.
   * @param volumetricHeatCapacity The other VolumetricHeatCapacity.
   * @returns A new VolumetricHeatCapacity instance with the results.
   */
  add(volumetricHeatCapacity) {
    return new VolumetricHeatCapacity(super.internalAdd(this.value, volumetricHeatCapacity.BaseValue));
  }
  /**
   * Subtract the given VolumetricHeatCapacity with the current VolumetricHeatCapacity.
   * @param volumetricHeatCapacity The other VolumetricHeatCapacity.
   * @returns A new VolumetricHeatCapacity instance with the results.
   */
  subtract(volumetricHeatCapacity) {
    return new VolumetricHeatCapacity(super.internalSubtract(this.value, volumetricHeatCapacity.BaseValue));
  }
  /**
   * Multiply the given VolumetricHeatCapacity with the current VolumetricHeatCapacity.
   * @param volumetricHeatCapacity The other VolumetricHeatCapacity.
   * @returns A new VolumetricHeatCapacity instance with the results.
   */
  multiply(volumetricHeatCapacity) {
    return new VolumetricHeatCapacity(super.internalMultiply(this.value, volumetricHeatCapacity.BaseValue));
  }
  /**
   * Divide the given VolumetricHeatCapacity with the current VolumetricHeatCapacity.
   * @param volumetricHeatCapacity The other VolumetricHeatCapacity.
   * @returns A new VolumetricHeatCapacity instance with the results.
   */
  divide(volumetricHeatCapacity) {
    return new VolumetricHeatCapacity(super.internalDivide(this.value, volumetricHeatCapacity.BaseValue));
  }
  /**
   * Modulo the given VolumetricHeatCapacity with the current VolumetricHeatCapacity.
   * @param volumetricHeatCapacity The other VolumetricHeatCapacity.
   * @returns A new VolumetricHeatCapacity instance with the results.
   */
  modulo(volumetricHeatCapacity) {
    return new VolumetricHeatCapacity(super.internalModulo(this.value, volumetricHeatCapacity.BaseValue));
  }
  /**
   * Pow the given VolumetricHeatCapacity with the current VolumetricHeatCapacity.
   * @param volumetricHeatCapacity The other VolumetricHeatCapacity.
   * @returns A new VolumetricHeatCapacity instance with the results.
   */
  pow(volumetricHeatCapacity) {
    return new VolumetricHeatCapacity(super.internalPow(this.value, volumetricHeatCapacity.BaseValue));
  }
}
exports.VolumetricHeatCapacity = VolumetricHeatCapacity;