"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpecificFuelConsumption = exports.SpecificFuelConsumptionUnits = void 0;
const base_unit_1 = require("../base-unit");
/** SpecificFuelConsumptionUnits enumeration */
var SpecificFuelConsumptionUnits;
(function (SpecificFuelConsumptionUnits) {
  /** */
  SpecificFuelConsumptionUnits["PoundsMassPerPoundForceHour"] = "PoundMassPerPoundForceHour";
  /** */
  SpecificFuelConsumptionUnits["KilogramsPerKilogramForceHour"] = "KilogramPerKilogramForceHour";
  /** */
  SpecificFuelConsumptionUnits["GramsPerKiloNewtonSecond"] = "GramPerKiloNewtonSecond";
  /** */
  SpecificFuelConsumptionUnits["KilogramsPerKiloNewtonSecond"] = "KilogramPerKiloNewtonSecond";
})(SpecificFuelConsumptionUnits = exports.SpecificFuelConsumptionUnits || (exports.SpecificFuelConsumptionUnits = {}));
/** SFC is the fuel efficiency of an engine design with respect to thrust output */
class SpecificFuelConsumption extends base_unit_1.BaseUnit {
  /**
   * Create a new SpecificFuelConsumption.
   * @param value The value.
   * @param fromUnit The ‘SpecificFuelConsumption’ unit to create from.
   * The default unit is GramsPerKiloNewtonSecond
   */
  constructor(value, fromUnit = SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond) {
    super();
    this.poundsmassperpoundforcehourLazy = null;
    this.kilogramsperkilogramforcehourLazy = null;
    this.gramsperkilonewtonsecondLazy = null;
    this.kilogramsperkilonewtonsecondLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of SpecificFuelConsumption is GramsPerKiloNewtonSecond.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond;
  }
  /** */
  get PoundsMassPerPoundForceHour() {
    if (this.poundsmassperpoundforcehourLazy !== null) {
      return this.poundsmassperpoundforcehourLazy;
    }
    return this.poundsmassperpoundforcehourLazy = this.convertFromBase(SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour);
  }
  /** */
  get KilogramsPerKilogramForceHour() {
    if (this.kilogramsperkilogramforcehourLazy !== null) {
      return this.kilogramsperkilogramforcehourLazy;
    }
    return this.kilogramsperkilogramforcehourLazy = this.convertFromBase(SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour);
  }
  /** */
  get GramsPerKiloNewtonSecond() {
    if (this.gramsperkilonewtonsecondLazy !== null) {
      return this.gramsperkilonewtonsecondLazy;
    }
    return this.gramsperkilonewtonsecondLazy = this.convertFromBase(SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond);
  }
  /** */
  get KilogramsPerKiloNewtonSecond() {
    if (this.kilogramsperkilonewtonsecondLazy !== null) {
      return this.kilogramsperkilonewtonsecondLazy;
    }
    return this.kilogramsperkilonewtonsecondLazy = this.convertFromBase(SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond);
  }
  /**
   * Create a new SpecificFuelConsumption instance from a PoundsMassPerPoundForceHour
   *
   * @param value The unit as PoundsMassPerPoundForceHour to create a new SpecificFuelConsumption from.
   * @returns The new SpecificFuelConsumption instance.
   */
  static FromPoundsMassPerPoundForceHour(value) {
    return new SpecificFuelConsumption(value, SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour);
  }
  /**
   * Create a new SpecificFuelConsumption instance from a KilogramsPerKilogramForceHour
   *
   * @param value The unit as KilogramsPerKilogramForceHour to create a new SpecificFuelConsumption from.
   * @returns The new SpecificFuelConsumption instance.
   */
  static FromKilogramsPerKilogramForceHour(value) {
    return new SpecificFuelConsumption(value, SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour);
  }
  /**
   * Create a new SpecificFuelConsumption instance from a GramsPerKiloNewtonSecond
   *
   * @param value The unit as GramsPerKiloNewtonSecond to create a new SpecificFuelConsumption from.
   * @returns The new SpecificFuelConsumption instance.
   */
  static FromGramsPerKiloNewtonSecond(value) {
    return new SpecificFuelConsumption(value, SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond);
  }
  /**
   * Create a new SpecificFuelConsumption instance from a KilogramsPerKiloNewtonSecond
   *
   * @param value The unit as KilogramsPerKiloNewtonSecond to create a new SpecificFuelConsumption from.
   * @returns The new SpecificFuelConsumption instance.
   */
  static FromKilogramsPerKiloNewtonSecond(value) {
    return new SpecificFuelConsumption(value, SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond);
  }
  /**
   * Gets the base unit enumeration associated with SpecificFuelConsumption
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return SpecificFuelConsumptionUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond;
  }
  /**
   * Create API DTO represent a SpecificFuelConsumption unit.
   * @param holdInUnit The specific SpecificFuelConsumption unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a SpecificFuelConsumption unit from an API DTO representation.
   * @param dtoSpecificFuelConsumption The SpecificFuelConsumption API DTO representation
   */
  static FromDto(dtoSpecificFuelConsumption) {
    return new SpecificFuelConsumption(dtoSpecificFuelConsumption.value, dtoSpecificFuelConsumption.unit);
  }
  /**
   * Convert SpecificFuelConsumption to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour:
        return this.PoundsMassPerPoundForceHour;
      case SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour:
        return this.KilogramsPerKilogramForceHour;
      case SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond:
        return this.GramsPerKiloNewtonSecond;
      case SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond:
        return this.KilogramsPerKiloNewtonSecond;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour:
        return super.internalDivide(this.value, 28.33);
      case SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour:
        return super.internalDivide(this.value, 28.33);
      case SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond:
        return this.value;
      case SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond:
        return super.internalDivide(this.value, 1000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour:
        return this.value / 28.33;
      case SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour:
        return this.value / 28.33;
      case SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond:
        return this.value;
      case SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond:
        return this.value / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour:
        return super.internalMultiply(value, 28.33);
      case SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour:
        return super.internalMultiply(value, 28.33);
      case SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond:
        return value;
      case SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond:
        return super.internalMultiply(value, 1000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour:
        return value * 28.33;
      case SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour:
        return value * 28.33;
      case SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond:
        return value;
      case SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond:
        return value * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the SpecificFuelConsumption to string.
   * Note! the default format for SpecificFuelConsumption is GramsPerKiloNewtonSecond.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the SpecificFuelConsumption.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the SpecificFuelConsumption.
   */
  toString(unit = SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond, fractionalDigits) {
    switch (unit) {
      case SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour:
        return super.truncateFractionDigits(this.PoundsMassPerPoundForceHour, fractionalDigits) + ` lb/(lbf·h)`;
      case SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour:
        return super.truncateFractionDigits(this.KilogramsPerKilogramForceHour, fractionalDigits) + ` kg/(kgf�h)`;
      case SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond:
        return super.truncateFractionDigits(this.GramsPerKiloNewtonSecond, fractionalDigits) + ` g/(kN�s)`;
      case SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond:
        return super.truncateFractionDigits(this.KilogramsPerKiloNewtonSecond, fractionalDigits) + ` kg/(kN�s)`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get SpecificFuelConsumption unit abbreviation.
   * Note! the default abbreviation for SpecificFuelConsumption is GramsPerKiloNewtonSecond.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the SpecificFuelConsumption.
   * @returns The abbreviation string of SpecificFuelConsumption.
   */
  getUnitAbbreviation(unitAbbreviation = SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond) {
    switch (unitAbbreviation) {
      case SpecificFuelConsumptionUnits.PoundsMassPerPoundForceHour:
        return `lb/(lbf·h)`;
      case SpecificFuelConsumptionUnits.KilogramsPerKilogramForceHour:
        return `kg/(kgf�h)`;
      case SpecificFuelConsumptionUnits.GramsPerKiloNewtonSecond:
        return `g/(kN�s)`;
      case SpecificFuelConsumptionUnits.KilogramsPerKiloNewtonSecond:
        return `kg/(kN�s)`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given SpecificFuelConsumption are equals to the current SpecificFuelConsumption.
   * @param specificFuelConsumption The other SpecificFuelConsumption.
   * @returns True if the given SpecificFuelConsumption are equal to the current SpecificFuelConsumption.
   */
  equals(specificFuelConsumption) {
    return super.internalEquals(this.value, specificFuelConsumption.BaseValue);
  }
  /**
   * Compare the given SpecificFuelConsumption against the current SpecificFuelConsumption.
   * @param specificFuelConsumption The other SpecificFuelConsumption.
   * @returns 0 if they are equal, -1 if the current SpecificFuelConsumption is less then other, 1 if the current SpecificFuelConsumption is greater then other.
   */
  compareTo(specificFuelConsumption) {
    return super.internalCompareTo(this.value, specificFuelConsumption.BaseValue);
  }
  /**
   * Add the given SpecificFuelConsumption with the current SpecificFuelConsumption.
   * @param specificFuelConsumption The other SpecificFuelConsumption.
   * @returns A new SpecificFuelConsumption instance with the results.
   */
  add(specificFuelConsumption) {
    return new SpecificFuelConsumption(super.internalAdd(this.value, specificFuelConsumption.BaseValue));
  }
  /**
   * Subtract the given SpecificFuelConsumption with the current SpecificFuelConsumption.
   * @param specificFuelConsumption The other SpecificFuelConsumption.
   * @returns A new SpecificFuelConsumption instance with the results.
   */
  subtract(specificFuelConsumption) {
    return new SpecificFuelConsumption(super.internalSubtract(this.value, specificFuelConsumption.BaseValue));
  }
  /**
   * Multiply the given SpecificFuelConsumption with the current SpecificFuelConsumption.
   * @param specificFuelConsumption The other SpecificFuelConsumption.
   * @returns A new SpecificFuelConsumption instance with the results.
   */
  multiply(specificFuelConsumption) {
    return new SpecificFuelConsumption(super.internalMultiply(this.value, specificFuelConsumption.BaseValue));
  }
  /**
   * Divide the given SpecificFuelConsumption with the current SpecificFuelConsumption.
   * @param specificFuelConsumption The other SpecificFuelConsumption.
   * @returns A new SpecificFuelConsumption instance with the results.
   */
  divide(specificFuelConsumption) {
    return new SpecificFuelConsumption(super.internalDivide(this.value, specificFuelConsumption.BaseValue));
  }
  /**
   * Modulo the given SpecificFuelConsumption with the current SpecificFuelConsumption.
   * @param specificFuelConsumption The other SpecificFuelConsumption.
   * @returns A new SpecificFuelConsumption instance with the results.
   */
  modulo(specificFuelConsumption) {
    return new SpecificFuelConsumption(super.internalModulo(this.value, specificFuelConsumption.BaseValue));
  }
  /**
   * Pow the given SpecificFuelConsumption with the current SpecificFuelConsumption.
   * @param specificFuelConsumption The other SpecificFuelConsumption.
   * @returns A new SpecificFuelConsumption instance with the results.
   */
  pow(specificFuelConsumption) {
    return new SpecificFuelConsumption(super.internalPow(this.value, specificFuelConsumption.BaseValue));
  }
}
exports.SpecificFuelConsumption = SpecificFuelConsumption;