"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricConductance = exports.ElectricConductanceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricConductanceUnits enumeration */
var ElectricConductanceUnits;
(function (ElectricConductanceUnits) {
  /** */
  ElectricConductanceUnits["Siemens"] = "Siemens";
  /** */
  ElectricConductanceUnits["Nanosiemens"] = "Nanosiemens";
  /** */
  ElectricConductanceUnits["Microsiemens"] = "Microsiemens";
  /** */
  ElectricConductanceUnits["Millisiemens"] = "Millisiemens";
  /** */
  ElectricConductanceUnits["Kilosiemens"] = "Kilosiemens";
})(ElectricConductanceUnits = exports.ElectricConductanceUnits || (exports.ElectricConductanceUnits = {}));
/** The electrical conductance of an electrical conductor is a measure of the easeness to pass an electric current through that conductor. */
class ElectricConductance extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricConductance.
   * @param value The value.
   * @param fromUnit The ‘ElectricConductance’ unit to create from.
   * The default unit is Siemens
   */
  constructor(value, fromUnit = ElectricConductanceUnits.Siemens) {
    super();
    this.siemensLazy = null;
    this.nanosiemensLazy = null;
    this.microsiemensLazy = null;
    this.millisiemensLazy = null;
    this.kilosiemensLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricConductance is Siemens.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricConductanceUnits.Siemens;
  }
  /** */
  get Siemens() {
    if (this.siemensLazy !== null) {
      return this.siemensLazy;
    }
    return this.siemensLazy = this.convertFromBase(ElectricConductanceUnits.Siemens);
  }
  /** */
  get Nanosiemens() {
    if (this.nanosiemensLazy !== null) {
      return this.nanosiemensLazy;
    }
    return this.nanosiemensLazy = this.convertFromBase(ElectricConductanceUnits.Nanosiemens);
  }
  /** */
  get Microsiemens() {
    if (this.microsiemensLazy !== null) {
      return this.microsiemensLazy;
    }
    return this.microsiemensLazy = this.convertFromBase(ElectricConductanceUnits.Microsiemens);
  }
  /** */
  get Millisiemens() {
    if (this.millisiemensLazy !== null) {
      return this.millisiemensLazy;
    }
    return this.millisiemensLazy = this.convertFromBase(ElectricConductanceUnits.Millisiemens);
  }
  /** */
  get Kilosiemens() {
    if (this.kilosiemensLazy !== null) {
      return this.kilosiemensLazy;
    }
    return this.kilosiemensLazy = this.convertFromBase(ElectricConductanceUnits.Kilosiemens);
  }
  /**
   * Create a new ElectricConductance instance from a Siemens
   *
   * @param value The unit as Siemens to create a new ElectricConductance from.
   * @returns The new ElectricConductance instance.
   */
  static FromSiemens(value) {
    return new ElectricConductance(value, ElectricConductanceUnits.Siemens);
  }
  /**
   * Create a new ElectricConductance instance from a Nanosiemens
   *
   * @param value The unit as Nanosiemens to create a new ElectricConductance from.
   * @returns The new ElectricConductance instance.
   */
  static FromNanosiemens(value) {
    return new ElectricConductance(value, ElectricConductanceUnits.Nanosiemens);
  }
  /**
   * Create a new ElectricConductance instance from a Microsiemens
   *
   * @param value The unit as Microsiemens to create a new ElectricConductance from.
   * @returns The new ElectricConductance instance.
   */
  static FromMicrosiemens(value) {
    return new ElectricConductance(value, ElectricConductanceUnits.Microsiemens);
  }
  /**
   * Create a new ElectricConductance instance from a Millisiemens
   *
   * @param value The unit as Millisiemens to create a new ElectricConductance from.
   * @returns The new ElectricConductance instance.
   */
  static FromMillisiemens(value) {
    return new ElectricConductance(value, ElectricConductanceUnits.Millisiemens);
  }
  /**
   * Create a new ElectricConductance instance from a Kilosiemens
   *
   * @param value The unit as Kilosiemens to create a new ElectricConductance from.
   * @returns The new ElectricConductance instance.
   */
  static FromKilosiemens(value) {
    return new ElectricConductance(value, ElectricConductanceUnits.Kilosiemens);
  }
  /**
   * Gets the base unit enumeration associated with ElectricConductance
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricConductanceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricConductanceUnits.Siemens;
  }
  /**
   * Create API DTO represent a ElectricConductance unit.
   * @param holdInUnit The specific ElectricConductance unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricConductanceUnits.Siemens) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricConductance unit from an API DTO representation.
   * @param dtoElectricConductance The ElectricConductance API DTO representation
   */
  static FromDto(dtoElectricConductance) {
    return new ElectricConductance(dtoElectricConductance.value, dtoElectricConductance.unit);
  }
  /**
   * Convert ElectricConductance to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricConductanceUnits.Siemens:
        return this.Siemens;
      case ElectricConductanceUnits.Nanosiemens:
        return this.Nanosiemens;
      case ElectricConductanceUnits.Microsiemens:
        return this.Microsiemens;
      case ElectricConductanceUnits.Millisiemens:
        return this.Millisiemens;
      case ElectricConductanceUnits.Kilosiemens:
        return this.Kilosiemens;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricConductanceUnits.Siemens:
        return this.value;
      case ElectricConductanceUnits.Nanosiemens:
        return super.internalDivide(this.value, 1e-9);
      case ElectricConductanceUnits.Microsiemens:
        return super.internalDivide(this.value, 0.000001);
      case ElectricConductanceUnits.Millisiemens:
        return super.internalDivide(this.value, 0.001);
      case ElectricConductanceUnits.Kilosiemens:
        return super.internalDivide(this.value, 1000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricConductanceUnits.Siemens:
        return this.value;
      case ElectricConductanceUnits.Nanosiemens:
        return this.value / 1e-9;
      case ElectricConductanceUnits.Microsiemens:
        return this.value / 0.000001;
      case ElectricConductanceUnits.Millisiemens:
        return this.value / 0.001;
      case ElectricConductanceUnits.Kilosiemens:
        return this.value / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricConductanceUnits.Siemens:
        return value;
      case ElectricConductanceUnits.Nanosiemens:
        return super.internalMultiply(value, 1e-9);
      case ElectricConductanceUnits.Microsiemens:
        return super.internalMultiply(value, 0.000001);
      case ElectricConductanceUnits.Millisiemens:
        return super.internalMultiply(value, 0.001);
      case ElectricConductanceUnits.Kilosiemens:
        return super.internalMultiply(value, 1000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricConductanceUnits.Siemens:
        return value;
      case ElectricConductanceUnits.Nanosiemens:
        return value * 1e-9;
      case ElectricConductanceUnits.Microsiemens:
        return value * 0.000001;
      case ElectricConductanceUnits.Millisiemens:
        return value * 0.001;
      case ElectricConductanceUnits.Kilosiemens:
        return value * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricConductance to string.
   * Note! the default format for ElectricConductance is Siemens.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricConductance.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricConductance.
   */
  toString(unit = ElectricConductanceUnits.Siemens, fractionalDigits) {
    switch (unit) {
      case ElectricConductanceUnits.Siemens:
        return super.truncateFractionDigits(this.Siemens, fractionalDigits) + ` S`;
      case ElectricConductanceUnits.Nanosiemens:
        return super.truncateFractionDigits(this.Nanosiemens, fractionalDigits) + ` nS`;
      case ElectricConductanceUnits.Microsiemens:
        return super.truncateFractionDigits(this.Microsiemens, fractionalDigits) + ` μS`;
      case ElectricConductanceUnits.Millisiemens:
        return super.truncateFractionDigits(this.Millisiemens, fractionalDigits) + ` mS`;
      case ElectricConductanceUnits.Kilosiemens:
        return super.truncateFractionDigits(this.Kilosiemens, fractionalDigits) + ` kS`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricConductance unit abbreviation.
   * Note! the default abbreviation for ElectricConductance is Siemens.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricConductance.
   * @returns The abbreviation string of ElectricConductance.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricConductanceUnits.Siemens) {
    switch (unitAbbreviation) {
      case ElectricConductanceUnits.Siemens:
        return `S`;
      case ElectricConductanceUnits.Nanosiemens:
        return `nS`;
      case ElectricConductanceUnits.Microsiemens:
        return `μS`;
      case ElectricConductanceUnits.Millisiemens:
        return `mS`;
      case ElectricConductanceUnits.Kilosiemens:
        return `kS`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricConductance are equals to the current ElectricConductance.
   * @param electricConductance The other ElectricConductance.
   * @returns True if the given ElectricConductance are equal to the current ElectricConductance.
   */
  equals(electricConductance) {
    return super.internalEquals(this.value, electricConductance.BaseValue);
  }
  /**
   * Compare the given ElectricConductance against the current ElectricConductance.
   * @param electricConductance The other ElectricConductance.
   * @returns 0 if they are equal, -1 if the current ElectricConductance is less then other, 1 if the current ElectricConductance is greater then other.
   */
  compareTo(electricConductance) {
    return super.internalCompareTo(this.value, electricConductance.BaseValue);
  }
  /**
   * Add the given ElectricConductance with the current ElectricConductance.
   * @param electricConductance The other ElectricConductance.
   * @returns A new ElectricConductance instance with the results.
   */
  add(electricConductance) {
    return new ElectricConductance(super.internalAdd(this.value, electricConductance.BaseValue));
  }
  /**
   * Subtract the given ElectricConductance with the current ElectricConductance.
   * @param electricConductance The other ElectricConductance.
   * @returns A new ElectricConductance instance with the results.
   */
  subtract(electricConductance) {
    return new ElectricConductance(super.internalSubtract(this.value, electricConductance.BaseValue));
  }
  /**
   * Multiply the given ElectricConductance with the current ElectricConductance.
   * @param electricConductance The other ElectricConductance.
   * @returns A new ElectricConductance instance with the results.
   */
  multiply(electricConductance) {
    return new ElectricConductance(super.internalMultiply(this.value, electricConductance.BaseValue));
  }
  /**
   * Divide the given ElectricConductance with the current ElectricConductance.
   * @param electricConductance The other ElectricConductance.
   * @returns A new ElectricConductance instance with the results.
   */
  divide(electricConductance) {
    return new ElectricConductance(super.internalDivide(this.value, electricConductance.BaseValue));
  }
  /**
   * Modulo the given ElectricConductance with the current ElectricConductance.
   * @param electricConductance The other ElectricConductance.
   * @returns A new ElectricConductance instance with the results.
   */
  modulo(electricConductance) {
    return new ElectricConductance(super.internalModulo(this.value, electricConductance.BaseValue));
  }
  /**
   * Pow the given ElectricConductance with the current ElectricConductance.
   * @param electricConductance The other ElectricConductance.
   * @returns A new ElectricConductance instance with the results.
   */
  pow(electricConductance) {
    return new ElectricConductance(super.internalPow(this.value, electricConductance.BaseValue));
  }
}
exports.ElectricConductance = ElectricConductance;