"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FuelEfficiency = exports.FuelEfficiencyUnits = void 0;
const base_unit_1 = require("../base-unit");
/** FuelEfficiencyUnits enumeration */
var FuelEfficiencyUnits;
(function (FuelEfficiencyUnits) {
  /** */
  FuelEfficiencyUnits["LitersPer100Kilometers"] = "LiterPer100Kilometers";
  /** */
  FuelEfficiencyUnits["MilesPerUsGallon"] = "MilePerUsGallon";
  /** */
  FuelEfficiencyUnits["MilesPerUkGallon"] = "MilePerUkGallon";
  /** */
  FuelEfficiencyUnits["KilometersPerLiters"] = "KilometerPerLiter";
})(FuelEfficiencyUnits = exports.FuelEfficiencyUnits || (exports.FuelEfficiencyUnits = {}));
/** Fuel efficiency is a form of thermal efficiency, meaning the ratio from effort to result of a process that converts chemical potential energy contained in a carrier (fuel) into kinetic energy or work. Fuel economy is stated as "fuel consumption" in liters per 100 kilometers (L/100 km). In countries using non-metric system, fuel economy is expressed in miles per gallon (mpg) (imperial galon or US galon). */
class FuelEfficiency extends base_unit_1.BaseUnit {
  /**
   * Create a new FuelEfficiency.
   * @param value The value.
   * @param fromUnit The ‘FuelEfficiency’ unit to create from.
   * The default unit is LitersPer100Kilometers
   */
  constructor(value, fromUnit = FuelEfficiencyUnits.LitersPer100Kilometers) {
    super();
    this.litersper100kilometersLazy = null;
    this.milesperusgallonLazy = null;
    this.milesperukgallonLazy = null;
    this.kilometersperlitersLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of FuelEfficiency is LitersPer100Kilometers.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return FuelEfficiencyUnits.LitersPer100Kilometers;
  }
  /** */
  get LitersPer100Kilometers() {
    if (this.litersper100kilometersLazy !== null) {
      return this.litersper100kilometersLazy;
    }
    return this.litersper100kilometersLazy = this.convertFromBase(FuelEfficiencyUnits.LitersPer100Kilometers);
  }
  /** */
  get MilesPerUsGallon() {
    if (this.milesperusgallonLazy !== null) {
      return this.milesperusgallonLazy;
    }
    return this.milesperusgallonLazy = this.convertFromBase(FuelEfficiencyUnits.MilesPerUsGallon);
  }
  /** */
  get MilesPerUkGallon() {
    if (this.milesperukgallonLazy !== null) {
      return this.milesperukgallonLazy;
    }
    return this.milesperukgallonLazy = this.convertFromBase(FuelEfficiencyUnits.MilesPerUkGallon);
  }
  /** */
  get KilometersPerLiters() {
    if (this.kilometersperlitersLazy !== null) {
      return this.kilometersperlitersLazy;
    }
    return this.kilometersperlitersLazy = this.convertFromBase(FuelEfficiencyUnits.KilometersPerLiters);
  }
  /**
   * Create a new FuelEfficiency instance from a LitersPer100Kilometers
   *
   * @param value The unit as LitersPer100Kilometers to create a new FuelEfficiency from.
   * @returns The new FuelEfficiency instance.
   */
  static FromLitersPer100Kilometers(value) {
    return new FuelEfficiency(value, FuelEfficiencyUnits.LitersPer100Kilometers);
  }
  /**
   * Create a new FuelEfficiency instance from a MilesPerUsGallon
   *
   * @param value The unit as MilesPerUsGallon to create a new FuelEfficiency from.
   * @returns The new FuelEfficiency instance.
   */
  static FromMilesPerUsGallon(value) {
    return new FuelEfficiency(value, FuelEfficiencyUnits.MilesPerUsGallon);
  }
  /**
   * Create a new FuelEfficiency instance from a MilesPerUkGallon
   *
   * @param value The unit as MilesPerUkGallon to create a new FuelEfficiency from.
   * @returns The new FuelEfficiency instance.
   */
  static FromMilesPerUkGallon(value) {
    return new FuelEfficiency(value, FuelEfficiencyUnits.MilesPerUkGallon);
  }
  /**
   * Create a new FuelEfficiency instance from a KilometersPerLiters
   *
   * @param value The unit as KilometersPerLiters to create a new FuelEfficiency from.
   * @returns The new FuelEfficiency instance.
   */
  static FromKilometersPerLiters(value) {
    return new FuelEfficiency(value, FuelEfficiencyUnits.KilometersPerLiters);
  }
  /**
   * Gets the base unit enumeration associated with FuelEfficiency
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return FuelEfficiencyUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return FuelEfficiencyUnits.LitersPer100Kilometers;
  }
  /**
   * Create API DTO represent a FuelEfficiency unit.
   * @param holdInUnit The specific FuelEfficiency unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = FuelEfficiencyUnits.LitersPer100Kilometers) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a FuelEfficiency unit from an API DTO representation.
   * @param dtoFuelEfficiency The FuelEfficiency API DTO representation
   */
  static FromDto(dtoFuelEfficiency) {
    return new FuelEfficiency(dtoFuelEfficiency.value, dtoFuelEfficiency.unit);
  }
  /**
   * Convert FuelEfficiency to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case FuelEfficiencyUnits.LitersPer100Kilometers:
        return this.LitersPer100Kilometers;
      case FuelEfficiencyUnits.MilesPerUsGallon:
        return this.MilesPerUsGallon;
      case FuelEfficiencyUnits.MilesPerUkGallon:
        return this.MilesPerUkGallon;
      case FuelEfficiencyUnits.KilometersPerLiters:
        return this.KilometersPerLiters;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case FuelEfficiencyUnits.LitersPer100Kilometers:
        return this.value;
      case FuelEfficiencyUnits.MilesPerUsGallon:
        {
          const v3 = super.internalMultiply(100, 3.785411784);
          const v6 = super.internalMultiply(1.609344, this.value);
          return super.internalDivide(v3, v6);
        }
      case FuelEfficiencyUnits.MilesPerUkGallon:
        {
          const v3 = super.internalMultiply(100, 4.54609188);
          const v6 = super.internalMultiply(1.609344, this.value);
          return super.internalDivide(v3, v6);
        }
      case FuelEfficiencyUnits.KilometersPerLiters:
        return super.internalDivide(100, this.value);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case FuelEfficiencyUnits.LitersPer100Kilometers:
        return this.value;
      case FuelEfficiencyUnits.MilesPerUsGallon:
        return 100 * 3.785411784 / (1.609344 * this.value);
      case FuelEfficiencyUnits.MilesPerUkGallon:
        return 100 * 4.54609188 / (1.609344 * this.value);
      case FuelEfficiencyUnits.KilometersPerLiters:
        return 100 / this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case FuelEfficiencyUnits.LitersPer100Kilometers:
        return value;
      case FuelEfficiencyUnits.MilesPerUsGallon:
        {
          const v3 = super.internalMultiply(100, 3.785411784);
          const v6 = super.internalMultiply(1.609344, value);
          return super.internalDivide(v3, v6);
        }
      case FuelEfficiencyUnits.MilesPerUkGallon:
        {
          const v3 = super.internalMultiply(100, 4.54609188);
          const v6 = super.internalMultiply(1.609344, value);
          return super.internalDivide(v3, v6);
        }
      case FuelEfficiencyUnits.KilometersPerLiters:
        return super.internalDivide(100, value);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case FuelEfficiencyUnits.LitersPer100Kilometers:
        return value;
      case FuelEfficiencyUnits.MilesPerUsGallon:
        return 100 * 3.785411784 / (1.609344 * value);
      case FuelEfficiencyUnits.MilesPerUkGallon:
        return 100 * 4.54609188 / (1.609344 * value);
      case FuelEfficiencyUnits.KilometersPerLiters:
        return 100 / value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the FuelEfficiency to string.
   * Note! the default format for FuelEfficiency is LitersPer100Kilometers.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the FuelEfficiency.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the FuelEfficiency.
   */
  toString(unit = FuelEfficiencyUnits.LitersPer100Kilometers, fractionalDigits) {
    switch (unit) {
      case FuelEfficiencyUnits.LitersPer100Kilometers:
        return super.truncateFractionDigits(this.LitersPer100Kilometers, fractionalDigits) + ` L/100km`;
      case FuelEfficiencyUnits.MilesPerUsGallon:
        return super.truncateFractionDigits(this.MilesPerUsGallon, fractionalDigits) + ` mpg (U.S.)`;
      case FuelEfficiencyUnits.MilesPerUkGallon:
        return super.truncateFractionDigits(this.MilesPerUkGallon, fractionalDigits) + ` mpg (imp.)`;
      case FuelEfficiencyUnits.KilometersPerLiters:
        return super.truncateFractionDigits(this.KilometersPerLiters, fractionalDigits) + ` km/L`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get FuelEfficiency unit abbreviation.
   * Note! the default abbreviation for FuelEfficiency is LitersPer100Kilometers.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the FuelEfficiency.
   * @returns The abbreviation string of FuelEfficiency.
   */
  getUnitAbbreviation(unitAbbreviation = FuelEfficiencyUnits.LitersPer100Kilometers) {
    switch (unitAbbreviation) {
      case FuelEfficiencyUnits.LitersPer100Kilometers:
        return `L/100km`;
      case FuelEfficiencyUnits.MilesPerUsGallon:
        return `mpg (U.S.)`;
      case FuelEfficiencyUnits.MilesPerUkGallon:
        return `mpg (imp.)`;
      case FuelEfficiencyUnits.KilometersPerLiters:
        return `km/L`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given FuelEfficiency are equals to the current FuelEfficiency.
   * @param fuelEfficiency The other FuelEfficiency.
   * @returns True if the given FuelEfficiency are equal to the current FuelEfficiency.
   */
  equals(fuelEfficiency) {
    return super.internalEquals(this.value, fuelEfficiency.BaseValue);
  }
  /**
   * Compare the given FuelEfficiency against the current FuelEfficiency.
   * @param fuelEfficiency The other FuelEfficiency.
   * @returns 0 if they are equal, -1 if the current FuelEfficiency is less then other, 1 if the current FuelEfficiency is greater then other.
   */
  compareTo(fuelEfficiency) {
    return super.internalCompareTo(this.value, fuelEfficiency.BaseValue);
  }
  /**
   * Add the given FuelEfficiency with the current FuelEfficiency.
   * @param fuelEfficiency The other FuelEfficiency.
   * @returns A new FuelEfficiency instance with the results.
   */
  add(fuelEfficiency) {
    return new FuelEfficiency(super.internalAdd(this.value, fuelEfficiency.BaseValue));
  }
  /**
   * Subtract the given FuelEfficiency with the current FuelEfficiency.
   * @param fuelEfficiency The other FuelEfficiency.
   * @returns A new FuelEfficiency instance with the results.
   */
  subtract(fuelEfficiency) {
    return new FuelEfficiency(super.internalSubtract(this.value, fuelEfficiency.BaseValue));
  }
  /**
   * Multiply the given FuelEfficiency with the current FuelEfficiency.
   * @param fuelEfficiency The other FuelEfficiency.
   * @returns A new FuelEfficiency instance with the results.
   */
  multiply(fuelEfficiency) {
    return new FuelEfficiency(super.internalMultiply(this.value, fuelEfficiency.BaseValue));
  }
  /**
   * Divide the given FuelEfficiency with the current FuelEfficiency.
   * @param fuelEfficiency The other FuelEfficiency.
   * @returns A new FuelEfficiency instance with the results.
   */
  divide(fuelEfficiency) {
    return new FuelEfficiency(super.internalDivide(this.value, fuelEfficiency.BaseValue));
  }
  /**
   * Modulo the given FuelEfficiency with the current FuelEfficiency.
   * @param fuelEfficiency The other FuelEfficiency.
   * @returns A new FuelEfficiency instance with the results.
   */
  modulo(fuelEfficiency) {
    return new FuelEfficiency(super.internalModulo(this.value, fuelEfficiency.BaseValue));
  }
  /**
   * Pow the given FuelEfficiency with the current FuelEfficiency.
   * @param fuelEfficiency The other FuelEfficiency.
   * @returns A new FuelEfficiency instance with the results.
   */
  pow(fuelEfficiency) {
    return new FuelEfficiency(super.internalPow(this.value, fuelEfficiency.BaseValue));
  }
}
exports.FuelEfficiency = FuelEfficiency;