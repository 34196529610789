"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricInductance = exports.ElectricInductanceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricInductanceUnits enumeration */
var ElectricInductanceUnits;
(function (ElectricInductanceUnits) {
  /** */
  ElectricInductanceUnits["Henries"] = "Henry";
  /** */
  ElectricInductanceUnits["Picohenries"] = "Picohenry";
  /** */
  ElectricInductanceUnits["Nanohenries"] = "Nanohenry";
  /** */
  ElectricInductanceUnits["Microhenries"] = "Microhenry";
  /** */
  ElectricInductanceUnits["Millihenries"] = "Millihenry";
})(ElectricInductanceUnits = exports.ElectricInductanceUnits || (exports.ElectricInductanceUnits = {}));
/** Inductance is a property of an electrical conductor which opposes a change in current. */
class ElectricInductance extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricInductance.
   * @param value The value.
   * @param fromUnit The ‘ElectricInductance’ unit to create from.
   * The default unit is Henries
   */
  constructor(value, fromUnit = ElectricInductanceUnits.Henries) {
    super();
    this.henriesLazy = null;
    this.picohenriesLazy = null;
    this.nanohenriesLazy = null;
    this.microhenriesLazy = null;
    this.millihenriesLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricInductance is Henries.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricInductanceUnits.Henries;
  }
  /** */
  get Henries() {
    if (this.henriesLazy !== null) {
      return this.henriesLazy;
    }
    return this.henriesLazy = this.convertFromBase(ElectricInductanceUnits.Henries);
  }
  /** */
  get Picohenries() {
    if (this.picohenriesLazy !== null) {
      return this.picohenriesLazy;
    }
    return this.picohenriesLazy = this.convertFromBase(ElectricInductanceUnits.Picohenries);
  }
  /** */
  get Nanohenries() {
    if (this.nanohenriesLazy !== null) {
      return this.nanohenriesLazy;
    }
    return this.nanohenriesLazy = this.convertFromBase(ElectricInductanceUnits.Nanohenries);
  }
  /** */
  get Microhenries() {
    if (this.microhenriesLazy !== null) {
      return this.microhenriesLazy;
    }
    return this.microhenriesLazy = this.convertFromBase(ElectricInductanceUnits.Microhenries);
  }
  /** */
  get Millihenries() {
    if (this.millihenriesLazy !== null) {
      return this.millihenriesLazy;
    }
    return this.millihenriesLazy = this.convertFromBase(ElectricInductanceUnits.Millihenries);
  }
  /**
   * Create a new ElectricInductance instance from a Henries
   *
   * @param value The unit as Henries to create a new ElectricInductance from.
   * @returns The new ElectricInductance instance.
   */
  static FromHenries(value) {
    return new ElectricInductance(value, ElectricInductanceUnits.Henries);
  }
  /**
   * Create a new ElectricInductance instance from a Picohenries
   *
   * @param value The unit as Picohenries to create a new ElectricInductance from.
   * @returns The new ElectricInductance instance.
   */
  static FromPicohenries(value) {
    return new ElectricInductance(value, ElectricInductanceUnits.Picohenries);
  }
  /**
   * Create a new ElectricInductance instance from a Nanohenries
   *
   * @param value The unit as Nanohenries to create a new ElectricInductance from.
   * @returns The new ElectricInductance instance.
   */
  static FromNanohenries(value) {
    return new ElectricInductance(value, ElectricInductanceUnits.Nanohenries);
  }
  /**
   * Create a new ElectricInductance instance from a Microhenries
   *
   * @param value The unit as Microhenries to create a new ElectricInductance from.
   * @returns The new ElectricInductance instance.
   */
  static FromMicrohenries(value) {
    return new ElectricInductance(value, ElectricInductanceUnits.Microhenries);
  }
  /**
   * Create a new ElectricInductance instance from a Millihenries
   *
   * @param value The unit as Millihenries to create a new ElectricInductance from.
   * @returns The new ElectricInductance instance.
   */
  static FromMillihenries(value) {
    return new ElectricInductance(value, ElectricInductanceUnits.Millihenries);
  }
  /**
   * Gets the base unit enumeration associated with ElectricInductance
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricInductanceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricInductanceUnits.Henries;
  }
  /**
   * Create API DTO represent a ElectricInductance unit.
   * @param holdInUnit The specific ElectricInductance unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricInductanceUnits.Henries) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricInductance unit from an API DTO representation.
   * @param dtoElectricInductance The ElectricInductance API DTO representation
   */
  static FromDto(dtoElectricInductance) {
    return new ElectricInductance(dtoElectricInductance.value, dtoElectricInductance.unit);
  }
  /**
   * Convert ElectricInductance to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricInductanceUnits.Henries:
        return this.Henries;
      case ElectricInductanceUnits.Picohenries:
        return this.Picohenries;
      case ElectricInductanceUnits.Nanohenries:
        return this.Nanohenries;
      case ElectricInductanceUnits.Microhenries:
        return this.Microhenries;
      case ElectricInductanceUnits.Millihenries:
        return this.Millihenries;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricInductanceUnits.Henries:
        return this.value;
      case ElectricInductanceUnits.Picohenries:
        return super.internalDivide(this.value, 1e-12);
      case ElectricInductanceUnits.Nanohenries:
        return super.internalDivide(this.value, 1e-9);
      case ElectricInductanceUnits.Microhenries:
        return super.internalDivide(this.value, 0.000001);
      case ElectricInductanceUnits.Millihenries:
        return super.internalDivide(this.value, 0.001);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricInductanceUnits.Henries:
        return this.value;
      case ElectricInductanceUnits.Picohenries:
        return this.value / 1e-12;
      case ElectricInductanceUnits.Nanohenries:
        return this.value / 1e-9;
      case ElectricInductanceUnits.Microhenries:
        return this.value / 0.000001;
      case ElectricInductanceUnits.Millihenries:
        return this.value / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricInductanceUnits.Henries:
        return value;
      case ElectricInductanceUnits.Picohenries:
        return super.internalMultiply(value, 1e-12);
      case ElectricInductanceUnits.Nanohenries:
        return super.internalMultiply(value, 1e-9);
      case ElectricInductanceUnits.Microhenries:
        return super.internalMultiply(value, 0.000001);
      case ElectricInductanceUnits.Millihenries:
        return super.internalMultiply(value, 0.001);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricInductanceUnits.Henries:
        return value;
      case ElectricInductanceUnits.Picohenries:
        return value * 1e-12;
      case ElectricInductanceUnits.Nanohenries:
        return value * 1e-9;
      case ElectricInductanceUnits.Microhenries:
        return value * 0.000001;
      case ElectricInductanceUnits.Millihenries:
        return value * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricInductance to string.
   * Note! the default format for ElectricInductance is Henries.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricInductance.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricInductance.
   */
  toString(unit = ElectricInductanceUnits.Henries, fractionalDigits) {
    switch (unit) {
      case ElectricInductanceUnits.Henries:
        return super.truncateFractionDigits(this.Henries, fractionalDigits) + ` H`;
      case ElectricInductanceUnits.Picohenries:
        return super.truncateFractionDigits(this.Picohenries, fractionalDigits) + ` pH`;
      case ElectricInductanceUnits.Nanohenries:
        return super.truncateFractionDigits(this.Nanohenries, fractionalDigits) + ` nH`;
      case ElectricInductanceUnits.Microhenries:
        return super.truncateFractionDigits(this.Microhenries, fractionalDigits) + ` μH`;
      case ElectricInductanceUnits.Millihenries:
        return super.truncateFractionDigits(this.Millihenries, fractionalDigits) + ` mH`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricInductance unit abbreviation.
   * Note! the default abbreviation for ElectricInductance is Henries.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricInductance.
   * @returns The abbreviation string of ElectricInductance.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricInductanceUnits.Henries) {
    switch (unitAbbreviation) {
      case ElectricInductanceUnits.Henries:
        return `H`;
      case ElectricInductanceUnits.Picohenries:
        return `pH`;
      case ElectricInductanceUnits.Nanohenries:
        return `nH`;
      case ElectricInductanceUnits.Microhenries:
        return `μH`;
      case ElectricInductanceUnits.Millihenries:
        return `mH`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricInductance are equals to the current ElectricInductance.
   * @param electricInductance The other ElectricInductance.
   * @returns True if the given ElectricInductance are equal to the current ElectricInductance.
   */
  equals(electricInductance) {
    return super.internalEquals(this.value, electricInductance.BaseValue);
  }
  /**
   * Compare the given ElectricInductance against the current ElectricInductance.
   * @param electricInductance The other ElectricInductance.
   * @returns 0 if they are equal, -1 if the current ElectricInductance is less then other, 1 if the current ElectricInductance is greater then other.
   */
  compareTo(electricInductance) {
    return super.internalCompareTo(this.value, electricInductance.BaseValue);
  }
  /**
   * Add the given ElectricInductance with the current ElectricInductance.
   * @param electricInductance The other ElectricInductance.
   * @returns A new ElectricInductance instance with the results.
   */
  add(electricInductance) {
    return new ElectricInductance(super.internalAdd(this.value, electricInductance.BaseValue));
  }
  /**
   * Subtract the given ElectricInductance with the current ElectricInductance.
   * @param electricInductance The other ElectricInductance.
   * @returns A new ElectricInductance instance with the results.
   */
  subtract(electricInductance) {
    return new ElectricInductance(super.internalSubtract(this.value, electricInductance.BaseValue));
  }
  /**
   * Multiply the given ElectricInductance with the current ElectricInductance.
   * @param electricInductance The other ElectricInductance.
   * @returns A new ElectricInductance instance with the results.
   */
  multiply(electricInductance) {
    return new ElectricInductance(super.internalMultiply(this.value, electricInductance.BaseValue));
  }
  /**
   * Divide the given ElectricInductance with the current ElectricInductance.
   * @param electricInductance The other ElectricInductance.
   * @returns A new ElectricInductance instance with the results.
   */
  divide(electricInductance) {
    return new ElectricInductance(super.internalDivide(this.value, electricInductance.BaseValue));
  }
  /**
   * Modulo the given ElectricInductance with the current ElectricInductance.
   * @param electricInductance The other ElectricInductance.
   * @returns A new ElectricInductance instance with the results.
   */
  modulo(electricInductance) {
    return new ElectricInductance(super.internalModulo(this.value, electricInductance.BaseValue));
  }
  /**
   * Pow the given ElectricInductance with the current ElectricInductance.
   * @param electricInductance The other ElectricInductance.
   * @returns A new ElectricInductance instance with the results.
   */
  pow(electricInductance) {
    return new ElectricInductance(super.internalPow(this.value, electricInductance.BaseValue));
  }
}
exports.ElectricInductance = ElectricInductance;