import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { CountryLabelPO, IngredientsPO, STiRResultListPO, STiRResult } from "../models";
import { AppConstants } from "../shared/constants";

@Injectable({
    providedIn: "root",
})
export class IngredientsService {

    constructor(private baseService: BaseService) { }

    FetchIngredient(): Observable<STiRResultListPO<IngredientsPO>> {
        let url = "Ingredient/GetIngredients";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    SaveIngredients(data: IngredientsPO[]): Observable<STiRResultListPO<IngredientsPO>> {
        let url = `Ingredient/SubmitIngredients`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    updateIngredients(data: IngredientsPO[]): Observable<STiRResultListPO<IngredientsPO>> {
        let url = `Ingredient/updateIngredients`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    SaveCountryLabelIngredients(data: CountryLabelPO[]): Observable<STiRResultListPO<CountryLabelPO>> {
        let url = "Ingredient/SubmitCountryLabelIngredients";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }

    removeIngredients(data: IngredientsPO[]): Observable<STiRResult> {
        let url = `Ingredient/DeleteIngredients`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    removeCountryLabelIngredients(data: string[]): Observable<STiRResult> {
        let url = `Ingredient/DeleteCountryLabelIngredients`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }
}