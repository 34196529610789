"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ForcePerLength = exports.ForcePerLengthUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ForcePerLengthUnits enumeration */
var ForcePerLengthUnits;
(function (ForcePerLengthUnits) {
  /** */
  ForcePerLengthUnits["NewtonsPerMeter"] = "NewtonPerMeter";
  /** */
  ForcePerLengthUnits["NewtonsPerCentimeter"] = "NewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["NewtonsPerMillimeter"] = "NewtonPerMillimeter";
  /** */
  ForcePerLengthUnits["KilogramsForcePerMeter"] = "KilogramForcePerMeter";
  /** */
  ForcePerLengthUnits["KilogramsForcePerCentimeter"] = "KilogramForcePerCentimeter";
  /** */
  ForcePerLengthUnits["KilogramsForcePerMillimeter"] = "KilogramForcePerMillimeter";
  /** */
  ForcePerLengthUnits["TonnesForcePerMeter"] = "TonneForcePerMeter";
  /** */
  ForcePerLengthUnits["TonnesForcePerCentimeter"] = "TonneForcePerCentimeter";
  /** */
  ForcePerLengthUnits["TonnesForcePerMillimeter"] = "TonneForcePerMillimeter";
  /** */
  ForcePerLengthUnits["PoundsForcePerFoot"] = "PoundForcePerFoot";
  /** */
  ForcePerLengthUnits["PoundsForcePerInch"] = "PoundForcePerInch";
  /** */
  ForcePerLengthUnits["PoundsForcePerYard"] = "PoundForcePerYard";
  /** */
  ForcePerLengthUnits["KilopoundsForcePerFoot"] = "KilopoundForcePerFoot";
  /** */
  ForcePerLengthUnits["KilopoundsForcePerInch"] = "KilopoundForcePerInch";
  /** */
  ForcePerLengthUnits["NanonewtonsPerMeter"] = "NanonewtonPerMeter";
  /** */
  ForcePerLengthUnits["MicronewtonsPerMeter"] = "MicronewtonPerMeter";
  /** */
  ForcePerLengthUnits["MillinewtonsPerMeter"] = "MillinewtonPerMeter";
  /** */
  ForcePerLengthUnits["CentinewtonsPerMeter"] = "CentinewtonPerMeter";
  /** */
  ForcePerLengthUnits["DecinewtonsPerMeter"] = "DecinewtonPerMeter";
  /** */
  ForcePerLengthUnits["DecanewtonsPerMeter"] = "DecanewtonPerMeter";
  /** */
  ForcePerLengthUnits["KilonewtonsPerMeter"] = "KilonewtonPerMeter";
  /** */
  ForcePerLengthUnits["MeganewtonsPerMeter"] = "MeganewtonPerMeter";
  /** */
  ForcePerLengthUnits["NanonewtonsPerCentimeter"] = "NanonewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["MicronewtonsPerCentimeter"] = "MicronewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["MillinewtonsPerCentimeter"] = "MillinewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["CentinewtonsPerCentimeter"] = "CentinewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["DecinewtonsPerCentimeter"] = "DecinewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["DecanewtonsPerCentimeter"] = "DecanewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["KilonewtonsPerCentimeter"] = "KilonewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["MeganewtonsPerCentimeter"] = "MeganewtonPerCentimeter";
  /** */
  ForcePerLengthUnits["NanonewtonsPerMillimeter"] = "NanonewtonPerMillimeter";
  /** */
  ForcePerLengthUnits["MicronewtonsPerMillimeter"] = "MicronewtonPerMillimeter";
  /** */
  ForcePerLengthUnits["MillinewtonsPerMillimeter"] = "MillinewtonPerMillimeter";
  /** */
  ForcePerLengthUnits["CentinewtonsPerMillimeter"] = "CentinewtonPerMillimeter";
  /** */
  ForcePerLengthUnits["DecinewtonsPerMillimeter"] = "DecinewtonPerMillimeter";
  /** */
  ForcePerLengthUnits["DecanewtonsPerMillimeter"] = "DecanewtonPerMillimeter";
  /** */
  ForcePerLengthUnits["KilonewtonsPerMillimeter"] = "KilonewtonPerMillimeter";
  /** */
  ForcePerLengthUnits["MeganewtonsPerMillimeter"] = "MeganewtonPerMillimeter";
})(ForcePerLengthUnits = exports.ForcePerLengthUnits || (exports.ForcePerLengthUnits = {}));
/** The magnitude of force per unit length. */
class ForcePerLength extends base_unit_1.BaseUnit {
  /**
   * Create a new ForcePerLength.
   * @param value The value.
   * @param fromUnit The ‘ForcePerLength’ unit to create from.
   * The default unit is NewtonsPerMeter
   */
  constructor(value, fromUnit = ForcePerLengthUnits.NewtonsPerMeter) {
    super();
    this.newtonspermeterLazy = null;
    this.newtonspercentimeterLazy = null;
    this.newtonspermillimeterLazy = null;
    this.kilogramsforcepermeterLazy = null;
    this.kilogramsforcepercentimeterLazy = null;
    this.kilogramsforcepermillimeterLazy = null;
    this.tonnesforcepermeterLazy = null;
    this.tonnesforcepercentimeterLazy = null;
    this.tonnesforcepermillimeterLazy = null;
    this.poundsforceperfootLazy = null;
    this.poundsforceperinchLazy = null;
    this.poundsforceperyardLazy = null;
    this.kilopoundsforceperfootLazy = null;
    this.kilopoundsforceperinchLazy = null;
    this.nanonewtonspermeterLazy = null;
    this.micronewtonspermeterLazy = null;
    this.millinewtonspermeterLazy = null;
    this.centinewtonspermeterLazy = null;
    this.decinewtonspermeterLazy = null;
    this.decanewtonspermeterLazy = null;
    this.kilonewtonspermeterLazy = null;
    this.meganewtonspermeterLazy = null;
    this.nanonewtonspercentimeterLazy = null;
    this.micronewtonspercentimeterLazy = null;
    this.millinewtonspercentimeterLazy = null;
    this.centinewtonspercentimeterLazy = null;
    this.decinewtonspercentimeterLazy = null;
    this.decanewtonspercentimeterLazy = null;
    this.kilonewtonspercentimeterLazy = null;
    this.meganewtonspercentimeterLazy = null;
    this.nanonewtonspermillimeterLazy = null;
    this.micronewtonspermillimeterLazy = null;
    this.millinewtonspermillimeterLazy = null;
    this.centinewtonspermillimeterLazy = null;
    this.decinewtonspermillimeterLazy = null;
    this.decanewtonspermillimeterLazy = null;
    this.kilonewtonspermillimeterLazy = null;
    this.meganewtonspermillimeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ForcePerLength is NewtonsPerMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ForcePerLengthUnits.NewtonsPerMeter;
  }
  /** */
  get NewtonsPerMeter() {
    if (this.newtonspermeterLazy !== null) {
      return this.newtonspermeterLazy;
    }
    return this.newtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.NewtonsPerMeter);
  }
  /** */
  get NewtonsPerCentimeter() {
    if (this.newtonspercentimeterLazy !== null) {
      return this.newtonspercentimeterLazy;
    }
    return this.newtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.NewtonsPerCentimeter);
  }
  /** */
  get NewtonsPerMillimeter() {
    if (this.newtonspermillimeterLazy !== null) {
      return this.newtonspermillimeterLazy;
    }
    return this.newtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.NewtonsPerMillimeter);
  }
  /** */
  get KilogramsForcePerMeter() {
    if (this.kilogramsforcepermeterLazy !== null) {
      return this.kilogramsforcepermeterLazy;
    }
    return this.kilogramsforcepermeterLazy = this.convertFromBase(ForcePerLengthUnits.KilogramsForcePerMeter);
  }
  /** */
  get KilogramsForcePerCentimeter() {
    if (this.kilogramsforcepercentimeterLazy !== null) {
      return this.kilogramsforcepercentimeterLazy;
    }
    return this.kilogramsforcepercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.KilogramsForcePerCentimeter);
  }
  /** */
  get KilogramsForcePerMillimeter() {
    if (this.kilogramsforcepermillimeterLazy !== null) {
      return this.kilogramsforcepermillimeterLazy;
    }
    return this.kilogramsforcepermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.KilogramsForcePerMillimeter);
  }
  /** */
  get TonnesForcePerMeter() {
    if (this.tonnesforcepermeterLazy !== null) {
      return this.tonnesforcepermeterLazy;
    }
    return this.tonnesforcepermeterLazy = this.convertFromBase(ForcePerLengthUnits.TonnesForcePerMeter);
  }
  /** */
  get TonnesForcePerCentimeter() {
    if (this.tonnesforcepercentimeterLazy !== null) {
      return this.tonnesforcepercentimeterLazy;
    }
    return this.tonnesforcepercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.TonnesForcePerCentimeter);
  }
  /** */
  get TonnesForcePerMillimeter() {
    if (this.tonnesforcepermillimeterLazy !== null) {
      return this.tonnesforcepermillimeterLazy;
    }
    return this.tonnesforcepermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.TonnesForcePerMillimeter);
  }
  /** */
  get PoundsForcePerFoot() {
    if (this.poundsforceperfootLazy !== null) {
      return this.poundsforceperfootLazy;
    }
    return this.poundsforceperfootLazy = this.convertFromBase(ForcePerLengthUnits.PoundsForcePerFoot);
  }
  /** */
  get PoundsForcePerInch() {
    if (this.poundsforceperinchLazy !== null) {
      return this.poundsforceperinchLazy;
    }
    return this.poundsforceperinchLazy = this.convertFromBase(ForcePerLengthUnits.PoundsForcePerInch);
  }
  /** */
  get PoundsForcePerYard() {
    if (this.poundsforceperyardLazy !== null) {
      return this.poundsforceperyardLazy;
    }
    return this.poundsforceperyardLazy = this.convertFromBase(ForcePerLengthUnits.PoundsForcePerYard);
  }
  /** */
  get KilopoundsForcePerFoot() {
    if (this.kilopoundsforceperfootLazy !== null) {
      return this.kilopoundsforceperfootLazy;
    }
    return this.kilopoundsforceperfootLazy = this.convertFromBase(ForcePerLengthUnits.KilopoundsForcePerFoot);
  }
  /** */
  get KilopoundsForcePerInch() {
    if (this.kilopoundsforceperinchLazy !== null) {
      return this.kilopoundsforceperinchLazy;
    }
    return this.kilopoundsforceperinchLazy = this.convertFromBase(ForcePerLengthUnits.KilopoundsForcePerInch);
  }
  /** */
  get NanonewtonsPerMeter() {
    if (this.nanonewtonspermeterLazy !== null) {
      return this.nanonewtonspermeterLazy;
    }
    return this.nanonewtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.NanonewtonsPerMeter);
  }
  /** */
  get MicronewtonsPerMeter() {
    if (this.micronewtonspermeterLazy !== null) {
      return this.micronewtonspermeterLazy;
    }
    return this.micronewtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.MicronewtonsPerMeter);
  }
  /** */
  get MillinewtonsPerMeter() {
    if (this.millinewtonspermeterLazy !== null) {
      return this.millinewtonspermeterLazy;
    }
    return this.millinewtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.MillinewtonsPerMeter);
  }
  /** */
  get CentinewtonsPerMeter() {
    if (this.centinewtonspermeterLazy !== null) {
      return this.centinewtonspermeterLazy;
    }
    return this.centinewtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.CentinewtonsPerMeter);
  }
  /** */
  get DecinewtonsPerMeter() {
    if (this.decinewtonspermeterLazy !== null) {
      return this.decinewtonspermeterLazy;
    }
    return this.decinewtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.DecinewtonsPerMeter);
  }
  /** */
  get DecanewtonsPerMeter() {
    if (this.decanewtonspermeterLazy !== null) {
      return this.decanewtonspermeterLazy;
    }
    return this.decanewtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.DecanewtonsPerMeter);
  }
  /** */
  get KilonewtonsPerMeter() {
    if (this.kilonewtonspermeterLazy !== null) {
      return this.kilonewtonspermeterLazy;
    }
    return this.kilonewtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.KilonewtonsPerMeter);
  }
  /** */
  get MeganewtonsPerMeter() {
    if (this.meganewtonspermeterLazy !== null) {
      return this.meganewtonspermeterLazy;
    }
    return this.meganewtonspermeterLazy = this.convertFromBase(ForcePerLengthUnits.MeganewtonsPerMeter);
  }
  /** */
  get NanonewtonsPerCentimeter() {
    if (this.nanonewtonspercentimeterLazy !== null) {
      return this.nanonewtonspercentimeterLazy;
    }
    return this.nanonewtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.NanonewtonsPerCentimeter);
  }
  /** */
  get MicronewtonsPerCentimeter() {
    if (this.micronewtonspercentimeterLazy !== null) {
      return this.micronewtonspercentimeterLazy;
    }
    return this.micronewtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.MicronewtonsPerCentimeter);
  }
  /** */
  get MillinewtonsPerCentimeter() {
    if (this.millinewtonspercentimeterLazy !== null) {
      return this.millinewtonspercentimeterLazy;
    }
    return this.millinewtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.MillinewtonsPerCentimeter);
  }
  /** */
  get CentinewtonsPerCentimeter() {
    if (this.centinewtonspercentimeterLazy !== null) {
      return this.centinewtonspercentimeterLazy;
    }
    return this.centinewtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.CentinewtonsPerCentimeter);
  }
  /** */
  get DecinewtonsPerCentimeter() {
    if (this.decinewtonspercentimeterLazy !== null) {
      return this.decinewtonspercentimeterLazy;
    }
    return this.decinewtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.DecinewtonsPerCentimeter);
  }
  /** */
  get DecanewtonsPerCentimeter() {
    if (this.decanewtonspercentimeterLazy !== null) {
      return this.decanewtonspercentimeterLazy;
    }
    return this.decanewtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.DecanewtonsPerCentimeter);
  }
  /** */
  get KilonewtonsPerCentimeter() {
    if (this.kilonewtonspercentimeterLazy !== null) {
      return this.kilonewtonspercentimeterLazy;
    }
    return this.kilonewtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.KilonewtonsPerCentimeter);
  }
  /** */
  get MeganewtonsPerCentimeter() {
    if (this.meganewtonspercentimeterLazy !== null) {
      return this.meganewtonspercentimeterLazy;
    }
    return this.meganewtonspercentimeterLazy = this.convertFromBase(ForcePerLengthUnits.MeganewtonsPerCentimeter);
  }
  /** */
  get NanonewtonsPerMillimeter() {
    if (this.nanonewtonspermillimeterLazy !== null) {
      return this.nanonewtonspermillimeterLazy;
    }
    return this.nanonewtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.NanonewtonsPerMillimeter);
  }
  /** */
  get MicronewtonsPerMillimeter() {
    if (this.micronewtonspermillimeterLazy !== null) {
      return this.micronewtonspermillimeterLazy;
    }
    return this.micronewtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.MicronewtonsPerMillimeter);
  }
  /** */
  get MillinewtonsPerMillimeter() {
    if (this.millinewtonspermillimeterLazy !== null) {
      return this.millinewtonspermillimeterLazy;
    }
    return this.millinewtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.MillinewtonsPerMillimeter);
  }
  /** */
  get CentinewtonsPerMillimeter() {
    if (this.centinewtonspermillimeterLazy !== null) {
      return this.centinewtonspermillimeterLazy;
    }
    return this.centinewtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.CentinewtonsPerMillimeter);
  }
  /** */
  get DecinewtonsPerMillimeter() {
    if (this.decinewtonspermillimeterLazy !== null) {
      return this.decinewtonspermillimeterLazy;
    }
    return this.decinewtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.DecinewtonsPerMillimeter);
  }
  /** */
  get DecanewtonsPerMillimeter() {
    if (this.decanewtonspermillimeterLazy !== null) {
      return this.decanewtonspermillimeterLazy;
    }
    return this.decanewtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.DecanewtonsPerMillimeter);
  }
  /** */
  get KilonewtonsPerMillimeter() {
    if (this.kilonewtonspermillimeterLazy !== null) {
      return this.kilonewtonspermillimeterLazy;
    }
    return this.kilonewtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.KilonewtonsPerMillimeter);
  }
  /** */
  get MeganewtonsPerMillimeter() {
    if (this.meganewtonspermillimeterLazy !== null) {
      return this.meganewtonspermillimeterLazy;
    }
    return this.meganewtonspermillimeterLazy = this.convertFromBase(ForcePerLengthUnits.MeganewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a NewtonsPerMeter
   *
   * @param value The unit as NewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromNewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.NewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a NewtonsPerCentimeter
   *
   * @param value The unit as NewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromNewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.NewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a NewtonsPerMillimeter
   *
   * @param value The unit as NewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromNewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.NewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a KilogramsForcePerMeter
   *
   * @param value The unit as KilogramsForcePerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromKilogramsForcePerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.KilogramsForcePerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a KilogramsForcePerCentimeter
   *
   * @param value The unit as KilogramsForcePerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromKilogramsForcePerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.KilogramsForcePerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a KilogramsForcePerMillimeter
   *
   * @param value The unit as KilogramsForcePerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromKilogramsForcePerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.KilogramsForcePerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a TonnesForcePerMeter
   *
   * @param value The unit as TonnesForcePerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromTonnesForcePerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.TonnesForcePerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a TonnesForcePerCentimeter
   *
   * @param value The unit as TonnesForcePerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromTonnesForcePerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.TonnesForcePerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a TonnesForcePerMillimeter
   *
   * @param value The unit as TonnesForcePerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromTonnesForcePerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.TonnesForcePerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a PoundsForcePerFoot
   *
   * @param value The unit as PoundsForcePerFoot to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromPoundsForcePerFoot(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.PoundsForcePerFoot);
  }
  /**
   * Create a new ForcePerLength instance from a PoundsForcePerInch
   *
   * @param value The unit as PoundsForcePerInch to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromPoundsForcePerInch(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.PoundsForcePerInch);
  }
  /**
   * Create a new ForcePerLength instance from a PoundsForcePerYard
   *
   * @param value The unit as PoundsForcePerYard to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromPoundsForcePerYard(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.PoundsForcePerYard);
  }
  /**
   * Create a new ForcePerLength instance from a KilopoundsForcePerFoot
   *
   * @param value The unit as KilopoundsForcePerFoot to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromKilopoundsForcePerFoot(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.KilopoundsForcePerFoot);
  }
  /**
   * Create a new ForcePerLength instance from a KilopoundsForcePerInch
   *
   * @param value The unit as KilopoundsForcePerInch to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromKilopoundsForcePerInch(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.KilopoundsForcePerInch);
  }
  /**
   * Create a new ForcePerLength instance from a NanonewtonsPerMeter
   *
   * @param value The unit as NanonewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromNanonewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.NanonewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a MicronewtonsPerMeter
   *
   * @param value The unit as MicronewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMicronewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MicronewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a MillinewtonsPerMeter
   *
   * @param value The unit as MillinewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMillinewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MillinewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a CentinewtonsPerMeter
   *
   * @param value The unit as CentinewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromCentinewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.CentinewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a DecinewtonsPerMeter
   *
   * @param value The unit as DecinewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromDecinewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.DecinewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a DecanewtonsPerMeter
   *
   * @param value The unit as DecanewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromDecanewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.DecanewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a KilonewtonsPerMeter
   *
   * @param value The unit as KilonewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromKilonewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.KilonewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a MeganewtonsPerMeter
   *
   * @param value The unit as MeganewtonsPerMeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMeganewtonsPerMeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MeganewtonsPerMeter);
  }
  /**
   * Create a new ForcePerLength instance from a NanonewtonsPerCentimeter
   *
   * @param value The unit as NanonewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromNanonewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.NanonewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a MicronewtonsPerCentimeter
   *
   * @param value The unit as MicronewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMicronewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MicronewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a MillinewtonsPerCentimeter
   *
   * @param value The unit as MillinewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMillinewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MillinewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a CentinewtonsPerCentimeter
   *
   * @param value The unit as CentinewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromCentinewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.CentinewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a DecinewtonsPerCentimeter
   *
   * @param value The unit as DecinewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromDecinewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.DecinewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a DecanewtonsPerCentimeter
   *
   * @param value The unit as DecanewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromDecanewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.DecanewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a KilonewtonsPerCentimeter
   *
   * @param value The unit as KilonewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromKilonewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.KilonewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a MeganewtonsPerCentimeter
   *
   * @param value The unit as MeganewtonsPerCentimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMeganewtonsPerCentimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MeganewtonsPerCentimeter);
  }
  /**
   * Create a new ForcePerLength instance from a NanonewtonsPerMillimeter
   *
   * @param value The unit as NanonewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromNanonewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.NanonewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a MicronewtonsPerMillimeter
   *
   * @param value The unit as MicronewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMicronewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MicronewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a MillinewtonsPerMillimeter
   *
   * @param value The unit as MillinewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMillinewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MillinewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a CentinewtonsPerMillimeter
   *
   * @param value The unit as CentinewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromCentinewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.CentinewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a DecinewtonsPerMillimeter
   *
   * @param value The unit as DecinewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromDecinewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.DecinewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a DecanewtonsPerMillimeter
   *
   * @param value The unit as DecanewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromDecanewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.DecanewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a KilonewtonsPerMillimeter
   *
   * @param value The unit as KilonewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromKilonewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.KilonewtonsPerMillimeter);
  }
  /**
   * Create a new ForcePerLength instance from a MeganewtonsPerMillimeter
   *
   * @param value The unit as MeganewtonsPerMillimeter to create a new ForcePerLength from.
   * @returns The new ForcePerLength instance.
   */
  static FromMeganewtonsPerMillimeter(value) {
    return new ForcePerLength(value, ForcePerLengthUnits.MeganewtonsPerMillimeter);
  }
  /**
   * Gets the base unit enumeration associated with ForcePerLength
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ForcePerLengthUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ForcePerLengthUnits.NewtonsPerMeter;
  }
  /**
   * Create API DTO represent a ForcePerLength unit.
   * @param holdInUnit The specific ForcePerLength unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ForcePerLengthUnits.NewtonsPerMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ForcePerLength unit from an API DTO representation.
   * @param dtoForcePerLength The ForcePerLength API DTO representation
   */
  static FromDto(dtoForcePerLength) {
    return new ForcePerLength(dtoForcePerLength.value, dtoForcePerLength.unit);
  }
  /**
   * Convert ForcePerLength to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ForcePerLengthUnits.NewtonsPerMeter:
        return this.NewtonsPerMeter;
      case ForcePerLengthUnits.NewtonsPerCentimeter:
        return this.NewtonsPerCentimeter;
      case ForcePerLengthUnits.NewtonsPerMillimeter:
        return this.NewtonsPerMillimeter;
      case ForcePerLengthUnits.KilogramsForcePerMeter:
        return this.KilogramsForcePerMeter;
      case ForcePerLengthUnits.KilogramsForcePerCentimeter:
        return this.KilogramsForcePerCentimeter;
      case ForcePerLengthUnits.KilogramsForcePerMillimeter:
        return this.KilogramsForcePerMillimeter;
      case ForcePerLengthUnits.TonnesForcePerMeter:
        return this.TonnesForcePerMeter;
      case ForcePerLengthUnits.TonnesForcePerCentimeter:
        return this.TonnesForcePerCentimeter;
      case ForcePerLengthUnits.TonnesForcePerMillimeter:
        return this.TonnesForcePerMillimeter;
      case ForcePerLengthUnits.PoundsForcePerFoot:
        return this.PoundsForcePerFoot;
      case ForcePerLengthUnits.PoundsForcePerInch:
        return this.PoundsForcePerInch;
      case ForcePerLengthUnits.PoundsForcePerYard:
        return this.PoundsForcePerYard;
      case ForcePerLengthUnits.KilopoundsForcePerFoot:
        return this.KilopoundsForcePerFoot;
      case ForcePerLengthUnits.KilopoundsForcePerInch:
        return this.KilopoundsForcePerInch;
      case ForcePerLengthUnits.NanonewtonsPerMeter:
        return this.NanonewtonsPerMeter;
      case ForcePerLengthUnits.MicronewtonsPerMeter:
        return this.MicronewtonsPerMeter;
      case ForcePerLengthUnits.MillinewtonsPerMeter:
        return this.MillinewtonsPerMeter;
      case ForcePerLengthUnits.CentinewtonsPerMeter:
        return this.CentinewtonsPerMeter;
      case ForcePerLengthUnits.DecinewtonsPerMeter:
        return this.DecinewtonsPerMeter;
      case ForcePerLengthUnits.DecanewtonsPerMeter:
        return this.DecanewtonsPerMeter;
      case ForcePerLengthUnits.KilonewtonsPerMeter:
        return this.KilonewtonsPerMeter;
      case ForcePerLengthUnits.MeganewtonsPerMeter:
        return this.MeganewtonsPerMeter;
      case ForcePerLengthUnits.NanonewtonsPerCentimeter:
        return this.NanonewtonsPerCentimeter;
      case ForcePerLengthUnits.MicronewtonsPerCentimeter:
        return this.MicronewtonsPerCentimeter;
      case ForcePerLengthUnits.MillinewtonsPerCentimeter:
        return this.MillinewtonsPerCentimeter;
      case ForcePerLengthUnits.CentinewtonsPerCentimeter:
        return this.CentinewtonsPerCentimeter;
      case ForcePerLengthUnits.DecinewtonsPerCentimeter:
        return this.DecinewtonsPerCentimeter;
      case ForcePerLengthUnits.DecanewtonsPerCentimeter:
        return this.DecanewtonsPerCentimeter;
      case ForcePerLengthUnits.KilonewtonsPerCentimeter:
        return this.KilonewtonsPerCentimeter;
      case ForcePerLengthUnits.MeganewtonsPerCentimeter:
        return this.MeganewtonsPerCentimeter;
      case ForcePerLengthUnits.NanonewtonsPerMillimeter:
        return this.NanonewtonsPerMillimeter;
      case ForcePerLengthUnits.MicronewtonsPerMillimeter:
        return this.MicronewtonsPerMillimeter;
      case ForcePerLengthUnits.MillinewtonsPerMillimeter:
        return this.MillinewtonsPerMillimeter;
      case ForcePerLengthUnits.CentinewtonsPerMillimeter:
        return this.CentinewtonsPerMillimeter;
      case ForcePerLengthUnits.DecinewtonsPerMillimeter:
        return this.DecinewtonsPerMillimeter;
      case ForcePerLengthUnits.DecanewtonsPerMillimeter:
        return this.DecanewtonsPerMillimeter;
      case ForcePerLengthUnits.KilonewtonsPerMillimeter:
        return this.KilonewtonsPerMillimeter;
      case ForcePerLengthUnits.MeganewtonsPerMillimeter:
        return this.MeganewtonsPerMillimeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ForcePerLengthUnits.NewtonsPerMeter:
        return this.value;
      case ForcePerLengthUnits.NewtonsPerCentimeter:
        return super.internalDivide(this.value, 1e2);
      case ForcePerLengthUnits.NewtonsPerMillimeter:
        return super.internalDivide(this.value, 1e3);
      case ForcePerLengthUnits.KilogramsForcePerMeter:
        return super.internalDivide(this.value, 9.80665002864);
      case ForcePerLengthUnits.KilogramsForcePerCentimeter:
        return super.internalDivide(this.value, 980.665002864);
      case ForcePerLengthUnits.KilogramsForcePerMillimeter:
        return super.internalDivide(this.value, 9.80665002864e3);
      case ForcePerLengthUnits.TonnesForcePerMeter:
        return super.internalDivide(this.value, 9.80665002864e3);
      case ForcePerLengthUnits.TonnesForcePerCentimeter:
        return super.internalDivide(this.value, 9.80665002864e5);
      case ForcePerLengthUnits.TonnesForcePerMillimeter:
        return super.internalDivide(this.value, 9.80665002864e6);
      case ForcePerLengthUnits.PoundsForcePerFoot:
        return super.internalDivide(this.value, 14.59390292);
      case ForcePerLengthUnits.PoundsForcePerInch:
        return super.internalDivide(this.value, 1.75126835e2);
      case ForcePerLengthUnits.PoundsForcePerYard:
        return super.internalDivide(this.value, 4.864634307);
      case ForcePerLengthUnits.KilopoundsForcePerFoot:
        return super.internalDivide(this.value, 14593.90292);
      case ForcePerLengthUnits.KilopoundsForcePerInch:
        return super.internalDivide(this.value, 1.75126835e5);
      case ForcePerLengthUnits.NanonewtonsPerMeter:
        return super.internalDivide(this.value, 1e-9);
      case ForcePerLengthUnits.MicronewtonsPerMeter:
        return super.internalDivide(this.value, 0.000001);
      case ForcePerLengthUnits.MillinewtonsPerMeter:
        return super.internalDivide(this.value, 0.001);
      case ForcePerLengthUnits.CentinewtonsPerMeter:
        return super.internalDivide(this.value, 0.01);
      case ForcePerLengthUnits.DecinewtonsPerMeter:
        return super.internalDivide(this.value, 0.1);
      case ForcePerLengthUnits.DecanewtonsPerMeter:
        return super.internalDivide(this.value, 10);
      case ForcePerLengthUnits.KilonewtonsPerMeter:
        return super.internalDivide(this.value, 1000);
      case ForcePerLengthUnits.MeganewtonsPerMeter:
        return super.internalDivide(this.value, 1000000);
      case ForcePerLengthUnits.NanonewtonsPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 1e-9);
        }
      case ForcePerLengthUnits.MicronewtonsPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 0.000001);
        }
      case ForcePerLengthUnits.MillinewtonsPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 0.001);
        }
      case ForcePerLengthUnits.CentinewtonsPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 0.01);
        }
      case ForcePerLengthUnits.DecinewtonsPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 0.1);
        }
      case ForcePerLengthUnits.DecanewtonsPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 10);
        }
      case ForcePerLengthUnits.KilonewtonsPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 1000);
        }
      case ForcePerLengthUnits.MeganewtonsPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 1000000);
        }
      case ForcePerLengthUnits.NanonewtonsPerMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 1e-9);
        }
      case ForcePerLengthUnits.MicronewtonsPerMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 0.000001);
        }
      case ForcePerLengthUnits.MillinewtonsPerMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 0.001);
        }
      case ForcePerLengthUnits.CentinewtonsPerMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 0.01);
        }
      case ForcePerLengthUnits.DecinewtonsPerMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 0.1);
        }
      case ForcePerLengthUnits.DecanewtonsPerMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 10);
        }
      case ForcePerLengthUnits.KilonewtonsPerMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 1000);
        }
      case ForcePerLengthUnits.MeganewtonsPerMillimeter:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ForcePerLengthUnits.NewtonsPerMeter:
        return this.value;
      case ForcePerLengthUnits.NewtonsPerCentimeter:
        return this.value / 1e2;
      case ForcePerLengthUnits.NewtonsPerMillimeter:
        return this.value / 1e3;
      case ForcePerLengthUnits.KilogramsForcePerMeter:
        return this.value / 9.80665002864;
      case ForcePerLengthUnits.KilogramsForcePerCentimeter:
        return this.value / 980.665002864;
      case ForcePerLengthUnits.KilogramsForcePerMillimeter:
        return this.value / 9.80665002864e3;
      case ForcePerLengthUnits.TonnesForcePerMeter:
        return this.value / 9.80665002864e3;
      case ForcePerLengthUnits.TonnesForcePerCentimeter:
        return this.value / 9.80665002864e5;
      case ForcePerLengthUnits.TonnesForcePerMillimeter:
        return this.value / 9.80665002864e6;
      case ForcePerLengthUnits.PoundsForcePerFoot:
        return this.value / 14.59390292;
      case ForcePerLengthUnits.PoundsForcePerInch:
        return this.value / 1.75126835e2;
      case ForcePerLengthUnits.PoundsForcePerYard:
        return this.value / 4.864634307;
      case ForcePerLengthUnits.KilopoundsForcePerFoot:
        return this.value / 14593.90292;
      case ForcePerLengthUnits.KilopoundsForcePerInch:
        return this.value / 1.75126835e5;
      case ForcePerLengthUnits.NanonewtonsPerMeter:
        return this.value / 1e-9;
      case ForcePerLengthUnits.MicronewtonsPerMeter:
        return this.value / 0.000001;
      case ForcePerLengthUnits.MillinewtonsPerMeter:
        return this.value / 0.001;
      case ForcePerLengthUnits.CentinewtonsPerMeter:
        return this.value / 0.01;
      case ForcePerLengthUnits.DecinewtonsPerMeter:
        return this.value / 0.1;
      case ForcePerLengthUnits.DecanewtonsPerMeter:
        return this.value / 10;
      case ForcePerLengthUnits.KilonewtonsPerMeter:
        return this.value / 1000;
      case ForcePerLengthUnits.MeganewtonsPerMeter:
        return this.value / 1000000;
      case ForcePerLengthUnits.NanonewtonsPerCentimeter:
        return this.value / 1e2 / 1e-9;
      case ForcePerLengthUnits.MicronewtonsPerCentimeter:
        return this.value / 1e2 / 0.000001;
      case ForcePerLengthUnits.MillinewtonsPerCentimeter:
        return this.value / 1e2 / 0.001;
      case ForcePerLengthUnits.CentinewtonsPerCentimeter:
        return this.value / 1e2 / 0.01;
      case ForcePerLengthUnits.DecinewtonsPerCentimeter:
        return this.value / 1e2 / 0.1;
      case ForcePerLengthUnits.DecanewtonsPerCentimeter:
        return this.value / 1e2 / 10;
      case ForcePerLengthUnits.KilonewtonsPerCentimeter:
        return this.value / 1e2 / 1000;
      case ForcePerLengthUnits.MeganewtonsPerCentimeter:
        return this.value / 1e2 / 1000000;
      case ForcePerLengthUnits.NanonewtonsPerMillimeter:
        return this.value / 1e3 / 1e-9;
      case ForcePerLengthUnits.MicronewtonsPerMillimeter:
        return this.value / 1e3 / 0.000001;
      case ForcePerLengthUnits.MillinewtonsPerMillimeter:
        return this.value / 1e3 / 0.001;
      case ForcePerLengthUnits.CentinewtonsPerMillimeter:
        return this.value / 1e3 / 0.01;
      case ForcePerLengthUnits.DecinewtonsPerMillimeter:
        return this.value / 1e3 / 0.1;
      case ForcePerLengthUnits.DecanewtonsPerMillimeter:
        return this.value / 1e3 / 10;
      case ForcePerLengthUnits.KilonewtonsPerMillimeter:
        return this.value / 1e3 / 1000;
      case ForcePerLengthUnits.MeganewtonsPerMillimeter:
        return this.value / 1e3 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ForcePerLengthUnits.NewtonsPerMeter:
        return value;
      case ForcePerLengthUnits.NewtonsPerCentimeter:
        return super.internalMultiply(value, 1e2);
      case ForcePerLengthUnits.NewtonsPerMillimeter:
        return super.internalMultiply(value, 1e3);
      case ForcePerLengthUnits.KilogramsForcePerMeter:
        return super.internalMultiply(value, 9.80665002864);
      case ForcePerLengthUnits.KilogramsForcePerCentimeter:
        return super.internalMultiply(value, 980.665002864);
      case ForcePerLengthUnits.KilogramsForcePerMillimeter:
        return super.internalMultiply(value, 9.80665002864e3);
      case ForcePerLengthUnits.TonnesForcePerMeter:
        return super.internalMultiply(value, 9.80665002864e3);
      case ForcePerLengthUnits.TonnesForcePerCentimeter:
        return super.internalMultiply(value, 9.80665002864e5);
      case ForcePerLengthUnits.TonnesForcePerMillimeter:
        return super.internalMultiply(value, 9.80665002864e6);
      case ForcePerLengthUnits.PoundsForcePerFoot:
        return super.internalMultiply(value, 14.59390292);
      case ForcePerLengthUnits.PoundsForcePerInch:
        return super.internalMultiply(value, 1.75126835e2);
      case ForcePerLengthUnits.PoundsForcePerYard:
        return super.internalMultiply(value, 4.864634307);
      case ForcePerLengthUnits.KilopoundsForcePerFoot:
        return super.internalMultiply(value, 14593.90292);
      case ForcePerLengthUnits.KilopoundsForcePerInch:
        return super.internalMultiply(value, 1.75126835e5);
      case ForcePerLengthUnits.NanonewtonsPerMeter:
        return super.internalMultiply(value, 1e-9);
      case ForcePerLengthUnits.MicronewtonsPerMeter:
        return super.internalMultiply(value, 0.000001);
      case ForcePerLengthUnits.MillinewtonsPerMeter:
        return super.internalMultiply(value, 0.001);
      case ForcePerLengthUnits.CentinewtonsPerMeter:
        return super.internalMultiply(value, 0.01);
      case ForcePerLengthUnits.DecinewtonsPerMeter:
        return super.internalMultiply(value, 0.1);
      case ForcePerLengthUnits.DecanewtonsPerMeter:
        return super.internalMultiply(value, 10);
      case ForcePerLengthUnits.KilonewtonsPerMeter:
        return super.internalMultiply(value, 1000);
      case ForcePerLengthUnits.MeganewtonsPerMeter:
        return super.internalMultiply(value, 1000000);
      case ForcePerLengthUnits.NanonewtonsPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 1e-9);
        }
      case ForcePerLengthUnits.MicronewtonsPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 0.000001);
        }
      case ForcePerLengthUnits.MillinewtonsPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 0.001);
        }
      case ForcePerLengthUnits.CentinewtonsPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 0.01);
        }
      case ForcePerLengthUnits.DecinewtonsPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 0.1);
        }
      case ForcePerLengthUnits.DecanewtonsPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 10);
        }
      case ForcePerLengthUnits.KilonewtonsPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 1000);
        }
      case ForcePerLengthUnits.MeganewtonsPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 1000000);
        }
      case ForcePerLengthUnits.NanonewtonsPerMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 1e-9);
        }
      case ForcePerLengthUnits.MicronewtonsPerMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 0.000001);
        }
      case ForcePerLengthUnits.MillinewtonsPerMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 0.001);
        }
      case ForcePerLengthUnits.CentinewtonsPerMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 0.01);
        }
      case ForcePerLengthUnits.DecinewtonsPerMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 0.1);
        }
      case ForcePerLengthUnits.DecanewtonsPerMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 10);
        }
      case ForcePerLengthUnits.KilonewtonsPerMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 1000);
        }
      case ForcePerLengthUnits.MeganewtonsPerMillimeter:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ForcePerLengthUnits.NewtonsPerMeter:
        return value;
      case ForcePerLengthUnits.NewtonsPerCentimeter:
        return value * 1e2;
      case ForcePerLengthUnits.NewtonsPerMillimeter:
        return value * 1e3;
      case ForcePerLengthUnits.KilogramsForcePerMeter:
        return value * 9.80665002864;
      case ForcePerLengthUnits.KilogramsForcePerCentimeter:
        return value * 980.665002864;
      case ForcePerLengthUnits.KilogramsForcePerMillimeter:
        return value * 9.80665002864e3;
      case ForcePerLengthUnits.TonnesForcePerMeter:
        return value * 9.80665002864e3;
      case ForcePerLengthUnits.TonnesForcePerCentimeter:
        return value * 9.80665002864e5;
      case ForcePerLengthUnits.TonnesForcePerMillimeter:
        return value * 9.80665002864e6;
      case ForcePerLengthUnits.PoundsForcePerFoot:
        return value * 14.59390292;
      case ForcePerLengthUnits.PoundsForcePerInch:
        return value * 1.75126835e2;
      case ForcePerLengthUnits.PoundsForcePerYard:
        return value * 4.864634307;
      case ForcePerLengthUnits.KilopoundsForcePerFoot:
        return value * 14593.90292;
      case ForcePerLengthUnits.KilopoundsForcePerInch:
        return value * 1.75126835e5;
      case ForcePerLengthUnits.NanonewtonsPerMeter:
        return value * 1e-9;
      case ForcePerLengthUnits.MicronewtonsPerMeter:
        return value * 0.000001;
      case ForcePerLengthUnits.MillinewtonsPerMeter:
        return value * 0.001;
      case ForcePerLengthUnits.CentinewtonsPerMeter:
        return value * 0.01;
      case ForcePerLengthUnits.DecinewtonsPerMeter:
        return value * 0.1;
      case ForcePerLengthUnits.DecanewtonsPerMeter:
        return value * 10;
      case ForcePerLengthUnits.KilonewtonsPerMeter:
        return value * 1000;
      case ForcePerLengthUnits.MeganewtonsPerMeter:
        return value * 1000000;
      case ForcePerLengthUnits.NanonewtonsPerCentimeter:
        return value * 1e2 * 1e-9;
      case ForcePerLengthUnits.MicronewtonsPerCentimeter:
        return value * 1e2 * 0.000001;
      case ForcePerLengthUnits.MillinewtonsPerCentimeter:
        return value * 1e2 * 0.001;
      case ForcePerLengthUnits.CentinewtonsPerCentimeter:
        return value * 1e2 * 0.01;
      case ForcePerLengthUnits.DecinewtonsPerCentimeter:
        return value * 1e2 * 0.1;
      case ForcePerLengthUnits.DecanewtonsPerCentimeter:
        return value * 1e2 * 10;
      case ForcePerLengthUnits.KilonewtonsPerCentimeter:
        return value * 1e2 * 1000;
      case ForcePerLengthUnits.MeganewtonsPerCentimeter:
        return value * 1e2 * 1000000;
      case ForcePerLengthUnits.NanonewtonsPerMillimeter:
        return value * 1e3 * 1e-9;
      case ForcePerLengthUnits.MicronewtonsPerMillimeter:
        return value * 1e3 * 0.000001;
      case ForcePerLengthUnits.MillinewtonsPerMillimeter:
        return value * 1e3 * 0.001;
      case ForcePerLengthUnits.CentinewtonsPerMillimeter:
        return value * 1e3 * 0.01;
      case ForcePerLengthUnits.DecinewtonsPerMillimeter:
        return value * 1e3 * 0.1;
      case ForcePerLengthUnits.DecanewtonsPerMillimeter:
        return value * 1e3 * 10;
      case ForcePerLengthUnits.KilonewtonsPerMillimeter:
        return value * 1e3 * 1000;
      case ForcePerLengthUnits.MeganewtonsPerMillimeter:
        return value * 1e3 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ForcePerLength to string.
   * Note! the default format for ForcePerLength is NewtonsPerMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ForcePerLength.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ForcePerLength.
   */
  toString(unit = ForcePerLengthUnits.NewtonsPerMeter, fractionalDigits) {
    switch (unit) {
      case ForcePerLengthUnits.NewtonsPerMeter:
        return super.truncateFractionDigits(this.NewtonsPerMeter, fractionalDigits) + ` N/m`;
      case ForcePerLengthUnits.NewtonsPerCentimeter:
        return super.truncateFractionDigits(this.NewtonsPerCentimeter, fractionalDigits) + ` N/cm`;
      case ForcePerLengthUnits.NewtonsPerMillimeter:
        return super.truncateFractionDigits(this.NewtonsPerMillimeter, fractionalDigits) + ` N/mm`;
      case ForcePerLengthUnits.KilogramsForcePerMeter:
        return super.truncateFractionDigits(this.KilogramsForcePerMeter, fractionalDigits) + ` kgf/m`;
      case ForcePerLengthUnits.KilogramsForcePerCentimeter:
        return super.truncateFractionDigits(this.KilogramsForcePerCentimeter, fractionalDigits) + ` kgf/cm`;
      case ForcePerLengthUnits.KilogramsForcePerMillimeter:
        return super.truncateFractionDigits(this.KilogramsForcePerMillimeter, fractionalDigits) + ` kgf/mm`;
      case ForcePerLengthUnits.TonnesForcePerMeter:
        return super.truncateFractionDigits(this.TonnesForcePerMeter, fractionalDigits) + ` tf/m`;
      case ForcePerLengthUnits.TonnesForcePerCentimeter:
        return super.truncateFractionDigits(this.TonnesForcePerCentimeter, fractionalDigits) + ` tf/cm`;
      case ForcePerLengthUnits.TonnesForcePerMillimeter:
        return super.truncateFractionDigits(this.TonnesForcePerMillimeter, fractionalDigits) + ` tf/mm`;
      case ForcePerLengthUnits.PoundsForcePerFoot:
        return super.truncateFractionDigits(this.PoundsForcePerFoot, fractionalDigits) + ` lbf/ft`;
      case ForcePerLengthUnits.PoundsForcePerInch:
        return super.truncateFractionDigits(this.PoundsForcePerInch, fractionalDigits) + ` lbf/in`;
      case ForcePerLengthUnits.PoundsForcePerYard:
        return super.truncateFractionDigits(this.PoundsForcePerYard, fractionalDigits) + ` lbf/yd`;
      case ForcePerLengthUnits.KilopoundsForcePerFoot:
        return super.truncateFractionDigits(this.KilopoundsForcePerFoot, fractionalDigits) + ` kipf/ft`;
      case ForcePerLengthUnits.KilopoundsForcePerInch:
        return super.truncateFractionDigits(this.KilopoundsForcePerInch, fractionalDigits) + ` kipf/in`;
      case ForcePerLengthUnits.NanonewtonsPerMeter:
        return super.truncateFractionDigits(this.NanonewtonsPerMeter, fractionalDigits) + ` nN/m`;
      case ForcePerLengthUnits.MicronewtonsPerMeter:
        return super.truncateFractionDigits(this.MicronewtonsPerMeter, fractionalDigits) + ` μN/m`;
      case ForcePerLengthUnits.MillinewtonsPerMeter:
        return super.truncateFractionDigits(this.MillinewtonsPerMeter, fractionalDigits) + ` mN/m`;
      case ForcePerLengthUnits.CentinewtonsPerMeter:
        return super.truncateFractionDigits(this.CentinewtonsPerMeter, fractionalDigits) + ` cN/m`;
      case ForcePerLengthUnits.DecinewtonsPerMeter:
        return super.truncateFractionDigits(this.DecinewtonsPerMeter, fractionalDigits) + ` dN/m`;
      case ForcePerLengthUnits.DecanewtonsPerMeter:
        return super.truncateFractionDigits(this.DecanewtonsPerMeter, fractionalDigits) + ` daN/m`;
      case ForcePerLengthUnits.KilonewtonsPerMeter:
        return super.truncateFractionDigits(this.KilonewtonsPerMeter, fractionalDigits) + ` kN/m`;
      case ForcePerLengthUnits.MeganewtonsPerMeter:
        return super.truncateFractionDigits(this.MeganewtonsPerMeter, fractionalDigits) + ` MN/m`;
      case ForcePerLengthUnits.NanonewtonsPerCentimeter:
        return super.truncateFractionDigits(this.NanonewtonsPerCentimeter, fractionalDigits) + ` nN/cm`;
      case ForcePerLengthUnits.MicronewtonsPerCentimeter:
        return super.truncateFractionDigits(this.MicronewtonsPerCentimeter, fractionalDigits) + ` μN/cm`;
      case ForcePerLengthUnits.MillinewtonsPerCentimeter:
        return super.truncateFractionDigits(this.MillinewtonsPerCentimeter, fractionalDigits) + ` mN/cm`;
      case ForcePerLengthUnits.CentinewtonsPerCentimeter:
        return super.truncateFractionDigits(this.CentinewtonsPerCentimeter, fractionalDigits) + ` cN/cm`;
      case ForcePerLengthUnits.DecinewtonsPerCentimeter:
        return super.truncateFractionDigits(this.DecinewtonsPerCentimeter, fractionalDigits) + ` dN/cm`;
      case ForcePerLengthUnits.DecanewtonsPerCentimeter:
        return super.truncateFractionDigits(this.DecanewtonsPerCentimeter, fractionalDigits) + ` daN/cm`;
      case ForcePerLengthUnits.KilonewtonsPerCentimeter:
        return super.truncateFractionDigits(this.KilonewtonsPerCentimeter, fractionalDigits) + ` kN/cm`;
      case ForcePerLengthUnits.MeganewtonsPerCentimeter:
        return super.truncateFractionDigits(this.MeganewtonsPerCentimeter, fractionalDigits) + ` MN/cm`;
      case ForcePerLengthUnits.NanonewtonsPerMillimeter:
        return super.truncateFractionDigits(this.NanonewtonsPerMillimeter, fractionalDigits) + ` nN/mm`;
      case ForcePerLengthUnits.MicronewtonsPerMillimeter:
        return super.truncateFractionDigits(this.MicronewtonsPerMillimeter, fractionalDigits) + ` μN/mm`;
      case ForcePerLengthUnits.MillinewtonsPerMillimeter:
        return super.truncateFractionDigits(this.MillinewtonsPerMillimeter, fractionalDigits) + ` mN/mm`;
      case ForcePerLengthUnits.CentinewtonsPerMillimeter:
        return super.truncateFractionDigits(this.CentinewtonsPerMillimeter, fractionalDigits) + ` cN/mm`;
      case ForcePerLengthUnits.DecinewtonsPerMillimeter:
        return super.truncateFractionDigits(this.DecinewtonsPerMillimeter, fractionalDigits) + ` dN/mm`;
      case ForcePerLengthUnits.DecanewtonsPerMillimeter:
        return super.truncateFractionDigits(this.DecanewtonsPerMillimeter, fractionalDigits) + ` daN/mm`;
      case ForcePerLengthUnits.KilonewtonsPerMillimeter:
        return super.truncateFractionDigits(this.KilonewtonsPerMillimeter, fractionalDigits) + ` kN/mm`;
      case ForcePerLengthUnits.MeganewtonsPerMillimeter:
        return super.truncateFractionDigits(this.MeganewtonsPerMillimeter, fractionalDigits) + ` MN/mm`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ForcePerLength unit abbreviation.
   * Note! the default abbreviation for ForcePerLength is NewtonsPerMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ForcePerLength.
   * @returns The abbreviation string of ForcePerLength.
   */
  getUnitAbbreviation(unitAbbreviation = ForcePerLengthUnits.NewtonsPerMeter) {
    switch (unitAbbreviation) {
      case ForcePerLengthUnits.NewtonsPerMeter:
        return `N/m`;
      case ForcePerLengthUnits.NewtonsPerCentimeter:
        return `N/cm`;
      case ForcePerLengthUnits.NewtonsPerMillimeter:
        return `N/mm`;
      case ForcePerLengthUnits.KilogramsForcePerMeter:
        return `kgf/m`;
      case ForcePerLengthUnits.KilogramsForcePerCentimeter:
        return `kgf/cm`;
      case ForcePerLengthUnits.KilogramsForcePerMillimeter:
        return `kgf/mm`;
      case ForcePerLengthUnits.TonnesForcePerMeter:
        return `tf/m`;
      case ForcePerLengthUnits.TonnesForcePerCentimeter:
        return `tf/cm`;
      case ForcePerLengthUnits.TonnesForcePerMillimeter:
        return `tf/mm`;
      case ForcePerLengthUnits.PoundsForcePerFoot:
        return `lbf/ft`;
      case ForcePerLengthUnits.PoundsForcePerInch:
        return `lbf/in`;
      case ForcePerLengthUnits.PoundsForcePerYard:
        return `lbf/yd`;
      case ForcePerLengthUnits.KilopoundsForcePerFoot:
        return `kipf/ft`;
      case ForcePerLengthUnits.KilopoundsForcePerInch:
        return `kipf/in`;
      case ForcePerLengthUnits.NanonewtonsPerMeter:
        return `nN/m`;
      case ForcePerLengthUnits.MicronewtonsPerMeter:
        return `μN/m`;
      case ForcePerLengthUnits.MillinewtonsPerMeter:
        return `mN/m`;
      case ForcePerLengthUnits.CentinewtonsPerMeter:
        return `cN/m`;
      case ForcePerLengthUnits.DecinewtonsPerMeter:
        return `dN/m`;
      case ForcePerLengthUnits.DecanewtonsPerMeter:
        return `daN/m`;
      case ForcePerLengthUnits.KilonewtonsPerMeter:
        return `kN/m`;
      case ForcePerLengthUnits.MeganewtonsPerMeter:
        return `MN/m`;
      case ForcePerLengthUnits.NanonewtonsPerCentimeter:
        return `nN/cm`;
      case ForcePerLengthUnits.MicronewtonsPerCentimeter:
        return `μN/cm`;
      case ForcePerLengthUnits.MillinewtonsPerCentimeter:
        return `mN/cm`;
      case ForcePerLengthUnits.CentinewtonsPerCentimeter:
        return `cN/cm`;
      case ForcePerLengthUnits.DecinewtonsPerCentimeter:
        return `dN/cm`;
      case ForcePerLengthUnits.DecanewtonsPerCentimeter:
        return `daN/cm`;
      case ForcePerLengthUnits.KilonewtonsPerCentimeter:
        return `kN/cm`;
      case ForcePerLengthUnits.MeganewtonsPerCentimeter:
        return `MN/cm`;
      case ForcePerLengthUnits.NanonewtonsPerMillimeter:
        return `nN/mm`;
      case ForcePerLengthUnits.MicronewtonsPerMillimeter:
        return `μN/mm`;
      case ForcePerLengthUnits.MillinewtonsPerMillimeter:
        return `mN/mm`;
      case ForcePerLengthUnits.CentinewtonsPerMillimeter:
        return `cN/mm`;
      case ForcePerLengthUnits.DecinewtonsPerMillimeter:
        return `dN/mm`;
      case ForcePerLengthUnits.DecanewtonsPerMillimeter:
        return `daN/mm`;
      case ForcePerLengthUnits.KilonewtonsPerMillimeter:
        return `kN/mm`;
      case ForcePerLengthUnits.MeganewtonsPerMillimeter:
        return `MN/mm`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ForcePerLength are equals to the current ForcePerLength.
   * @param forcePerLength The other ForcePerLength.
   * @returns True if the given ForcePerLength are equal to the current ForcePerLength.
   */
  equals(forcePerLength) {
    return super.internalEquals(this.value, forcePerLength.BaseValue);
  }
  /**
   * Compare the given ForcePerLength against the current ForcePerLength.
   * @param forcePerLength The other ForcePerLength.
   * @returns 0 if they are equal, -1 if the current ForcePerLength is less then other, 1 if the current ForcePerLength is greater then other.
   */
  compareTo(forcePerLength) {
    return super.internalCompareTo(this.value, forcePerLength.BaseValue);
  }
  /**
   * Add the given ForcePerLength with the current ForcePerLength.
   * @param forcePerLength The other ForcePerLength.
   * @returns A new ForcePerLength instance with the results.
   */
  add(forcePerLength) {
    return new ForcePerLength(super.internalAdd(this.value, forcePerLength.BaseValue));
  }
  /**
   * Subtract the given ForcePerLength with the current ForcePerLength.
   * @param forcePerLength The other ForcePerLength.
   * @returns A new ForcePerLength instance with the results.
   */
  subtract(forcePerLength) {
    return new ForcePerLength(super.internalSubtract(this.value, forcePerLength.BaseValue));
  }
  /**
   * Multiply the given ForcePerLength with the current ForcePerLength.
   * @param forcePerLength The other ForcePerLength.
   * @returns A new ForcePerLength instance with the results.
   */
  multiply(forcePerLength) {
    return new ForcePerLength(super.internalMultiply(this.value, forcePerLength.BaseValue));
  }
  /**
   * Divide the given ForcePerLength with the current ForcePerLength.
   * @param forcePerLength The other ForcePerLength.
   * @returns A new ForcePerLength instance with the results.
   */
  divide(forcePerLength) {
    return new ForcePerLength(super.internalDivide(this.value, forcePerLength.BaseValue));
  }
  /**
   * Modulo the given ForcePerLength with the current ForcePerLength.
   * @param forcePerLength The other ForcePerLength.
   * @returns A new ForcePerLength instance with the results.
   */
  modulo(forcePerLength) {
    return new ForcePerLength(super.internalModulo(this.value, forcePerLength.BaseValue));
  }
  /**
   * Pow the given ForcePerLength with the current ForcePerLength.
   * @param forcePerLength The other ForcePerLength.
   * @returns A new ForcePerLength instance with the results.
   */
  pow(forcePerLength) {
    return new ForcePerLength(super.internalPow(this.value, forcePerLength.BaseValue));
  }
}
exports.ForcePerLength = ForcePerLength;