import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { FactsPO, STiRResultListPO } from "../models";
import { AppConstants } from "../shared/constants";

@Injectable({
    providedIn: "root",
})
export class FormulationService {

    constructor(private baseService: BaseService) { }


    fetchCompositionData(name: string): Observable<FactsPO[]> {
        let url = "Formulation/GetCompositionData";
        return this.baseService.GetByID(url, name, AppConstants.Loading);
    }

    fetchFactsDetails(data: any): Observable<FactsPO[]> {
        let url = "Formulation/GetFactsDetails";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }

    fetchCoformulants(data: any): Observable<FactsPO[]> {
        let url = "Coformulant/GetCoformulants";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }
}