"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Molarity = exports.MolarityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MolarityUnits enumeration */
var MolarityUnits;
(function (MolarityUnits) {
  /** */
  MolarityUnits["MolesPerCubicMeter"] = "MolePerCubicMeter";
  /** */
  MolarityUnits["MolesPerLiter"] = "MolePerLiter";
  /** */
  MolarityUnits["PoundMolesPerCubicFoot"] = "PoundMolePerCubicFoot";
  /** */
  MolarityUnits["KilomolesPerCubicMeter"] = "KilomolePerCubicMeter";
  /** */
  MolarityUnits["FemtomolesPerLiter"] = "FemtomolePerLiter";
  /** */
  MolarityUnits["PicomolesPerLiter"] = "PicomolePerLiter";
  /** */
  MolarityUnits["NanomolesPerLiter"] = "NanomolePerLiter";
  /** */
  MolarityUnits["MicromolesPerLiter"] = "MicromolePerLiter";
  /** */
  MolarityUnits["MillimolesPerLiter"] = "MillimolePerLiter";
  /** */
  MolarityUnits["CentimolesPerLiter"] = "CentimolePerLiter";
  /** */
  MolarityUnits["DecimolesPerLiter"] = "DecimolePerLiter";
})(MolarityUnits = exports.MolarityUnits || (exports.MolarityUnits = {}));
/** Molar concentration, also called molarity, amount concentration or substance concentration, is a measure of the concentration of a solute in a solution, or of any chemical species, in terms of amount of substance in a given volume. */
class Molarity extends base_unit_1.BaseUnit {
  /**
   * Create a new Molarity.
   * @param value The value.
   * @param fromUnit The ‘Molarity’ unit to create from.
   * The default unit is MolesPerCubicMeter
   */
  constructor(value, fromUnit = MolarityUnits.MolesPerCubicMeter) {
    super();
    this.molespercubicmeterLazy = null;
    this.molesperliterLazy = null;
    this.poundmolespercubicfootLazy = null;
    this.kilomolespercubicmeterLazy = null;
    this.femtomolesperliterLazy = null;
    this.picomolesperliterLazy = null;
    this.nanomolesperliterLazy = null;
    this.micromolesperliterLazy = null;
    this.millimolesperliterLazy = null;
    this.centimolesperliterLazy = null;
    this.decimolesperliterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Molarity is MolesPerCubicMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MolarityUnits.MolesPerCubicMeter;
  }
  /** */
  get MolesPerCubicMeter() {
    if (this.molespercubicmeterLazy !== null) {
      return this.molespercubicmeterLazy;
    }
    return this.molespercubicmeterLazy = this.convertFromBase(MolarityUnits.MolesPerCubicMeter);
  }
  /** */
  get MolesPerLiter() {
    if (this.molesperliterLazy !== null) {
      return this.molesperliterLazy;
    }
    return this.molesperliterLazy = this.convertFromBase(MolarityUnits.MolesPerLiter);
  }
  /** */
  get PoundMolesPerCubicFoot() {
    if (this.poundmolespercubicfootLazy !== null) {
      return this.poundmolespercubicfootLazy;
    }
    return this.poundmolespercubicfootLazy = this.convertFromBase(MolarityUnits.PoundMolesPerCubicFoot);
  }
  /** */
  get KilomolesPerCubicMeter() {
    if (this.kilomolespercubicmeterLazy !== null) {
      return this.kilomolespercubicmeterLazy;
    }
    return this.kilomolespercubicmeterLazy = this.convertFromBase(MolarityUnits.KilomolesPerCubicMeter);
  }
  /** */
  get FemtomolesPerLiter() {
    if (this.femtomolesperliterLazy !== null) {
      return this.femtomolesperliterLazy;
    }
    return this.femtomolesperliterLazy = this.convertFromBase(MolarityUnits.FemtomolesPerLiter);
  }
  /** */
  get PicomolesPerLiter() {
    if (this.picomolesperliterLazy !== null) {
      return this.picomolesperliterLazy;
    }
    return this.picomolesperliterLazy = this.convertFromBase(MolarityUnits.PicomolesPerLiter);
  }
  /** */
  get NanomolesPerLiter() {
    if (this.nanomolesperliterLazy !== null) {
      return this.nanomolesperliterLazy;
    }
    return this.nanomolesperliterLazy = this.convertFromBase(MolarityUnits.NanomolesPerLiter);
  }
  /** */
  get MicromolesPerLiter() {
    if (this.micromolesperliterLazy !== null) {
      return this.micromolesperliterLazy;
    }
    return this.micromolesperliterLazy = this.convertFromBase(MolarityUnits.MicromolesPerLiter);
  }
  /** */
  get MillimolesPerLiter() {
    if (this.millimolesperliterLazy !== null) {
      return this.millimolesperliterLazy;
    }
    return this.millimolesperliterLazy = this.convertFromBase(MolarityUnits.MillimolesPerLiter);
  }
  /** */
  get CentimolesPerLiter() {
    if (this.centimolesperliterLazy !== null) {
      return this.centimolesperliterLazy;
    }
    return this.centimolesperliterLazy = this.convertFromBase(MolarityUnits.CentimolesPerLiter);
  }
  /** */
  get DecimolesPerLiter() {
    if (this.decimolesperliterLazy !== null) {
      return this.decimolesperliterLazy;
    }
    return this.decimolesperliterLazy = this.convertFromBase(MolarityUnits.DecimolesPerLiter);
  }
  /**
   * Create a new Molarity instance from a MolesPerCubicMeter
   *
   * @param value The unit as MolesPerCubicMeter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromMolesPerCubicMeter(value) {
    return new Molarity(value, MolarityUnits.MolesPerCubicMeter);
  }
  /**
   * Create a new Molarity instance from a MolesPerLiter
   *
   * @param value The unit as MolesPerLiter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromMolesPerLiter(value) {
    return new Molarity(value, MolarityUnits.MolesPerLiter);
  }
  /**
   * Create a new Molarity instance from a PoundMolesPerCubicFoot
   *
   * @param value The unit as PoundMolesPerCubicFoot to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromPoundMolesPerCubicFoot(value) {
    return new Molarity(value, MolarityUnits.PoundMolesPerCubicFoot);
  }
  /**
   * Create a new Molarity instance from a KilomolesPerCubicMeter
   *
   * @param value The unit as KilomolesPerCubicMeter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromKilomolesPerCubicMeter(value) {
    return new Molarity(value, MolarityUnits.KilomolesPerCubicMeter);
  }
  /**
   * Create a new Molarity instance from a FemtomolesPerLiter
   *
   * @param value The unit as FemtomolesPerLiter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromFemtomolesPerLiter(value) {
    return new Molarity(value, MolarityUnits.FemtomolesPerLiter);
  }
  /**
   * Create a new Molarity instance from a PicomolesPerLiter
   *
   * @param value The unit as PicomolesPerLiter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromPicomolesPerLiter(value) {
    return new Molarity(value, MolarityUnits.PicomolesPerLiter);
  }
  /**
   * Create a new Molarity instance from a NanomolesPerLiter
   *
   * @param value The unit as NanomolesPerLiter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromNanomolesPerLiter(value) {
    return new Molarity(value, MolarityUnits.NanomolesPerLiter);
  }
  /**
   * Create a new Molarity instance from a MicromolesPerLiter
   *
   * @param value The unit as MicromolesPerLiter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromMicromolesPerLiter(value) {
    return new Molarity(value, MolarityUnits.MicromolesPerLiter);
  }
  /**
   * Create a new Molarity instance from a MillimolesPerLiter
   *
   * @param value The unit as MillimolesPerLiter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromMillimolesPerLiter(value) {
    return new Molarity(value, MolarityUnits.MillimolesPerLiter);
  }
  /**
   * Create a new Molarity instance from a CentimolesPerLiter
   *
   * @param value The unit as CentimolesPerLiter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromCentimolesPerLiter(value) {
    return new Molarity(value, MolarityUnits.CentimolesPerLiter);
  }
  /**
   * Create a new Molarity instance from a DecimolesPerLiter
   *
   * @param value The unit as DecimolesPerLiter to create a new Molarity from.
   * @returns The new Molarity instance.
   */
  static FromDecimolesPerLiter(value) {
    return new Molarity(value, MolarityUnits.DecimolesPerLiter);
  }
  /**
   * Gets the base unit enumeration associated with Molarity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MolarityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MolarityUnits.MolesPerCubicMeter;
  }
  /**
   * Create API DTO represent a Molarity unit.
   * @param holdInUnit The specific Molarity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MolarityUnits.MolesPerCubicMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Molarity unit from an API DTO representation.
   * @param dtoMolarity The Molarity API DTO representation
   */
  static FromDto(dtoMolarity) {
    return new Molarity(dtoMolarity.value, dtoMolarity.unit);
  }
  /**
   * Convert Molarity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MolarityUnits.MolesPerCubicMeter:
        return this.MolesPerCubicMeter;
      case MolarityUnits.MolesPerLiter:
        return this.MolesPerLiter;
      case MolarityUnits.PoundMolesPerCubicFoot:
        return this.PoundMolesPerCubicFoot;
      case MolarityUnits.KilomolesPerCubicMeter:
        return this.KilomolesPerCubicMeter;
      case MolarityUnits.FemtomolesPerLiter:
        return this.FemtomolesPerLiter;
      case MolarityUnits.PicomolesPerLiter:
        return this.PicomolesPerLiter;
      case MolarityUnits.NanomolesPerLiter:
        return this.NanomolesPerLiter;
      case MolarityUnits.MicromolesPerLiter:
        return this.MicromolesPerLiter;
      case MolarityUnits.MillimolesPerLiter:
        return this.MillimolesPerLiter;
      case MolarityUnits.CentimolesPerLiter:
        return this.CentimolesPerLiter;
      case MolarityUnits.DecimolesPerLiter:
        return this.DecimolesPerLiter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MolarityUnits.MolesPerCubicMeter:
        return this.value;
      case MolarityUnits.MolesPerLiter:
        return super.internalMultiply(this.value, 1e-3);
      case MolarityUnits.PoundMolesPerCubicFoot:
        return super.internalMultiply(this.value, 6.2427960576144611956325455827221e-5);
      case MolarityUnits.KilomolesPerCubicMeter:
        return super.internalDivide(this.value, 1000);
      case MolarityUnits.FemtomolesPerLiter:
        {
          const v3 = super.internalMultiply(this.value, 1e-3);
          return super.internalDivide(v3, 1e-15);
        }
      case MolarityUnits.PicomolesPerLiter:
        {
          const v3 = super.internalMultiply(this.value, 1e-3);
          return super.internalDivide(v3, 1e-12);
        }
      case MolarityUnits.NanomolesPerLiter:
        {
          const v3 = super.internalMultiply(this.value, 1e-3);
          return super.internalDivide(v3, 1e-9);
        }
      case MolarityUnits.MicromolesPerLiter:
        {
          const v3 = super.internalMultiply(this.value, 1e-3);
          return super.internalDivide(v3, 0.000001);
        }
      case MolarityUnits.MillimolesPerLiter:
        {
          const v3 = super.internalMultiply(this.value, 1e-3);
          return super.internalDivide(v3, 0.001);
        }
      case MolarityUnits.CentimolesPerLiter:
        {
          const v3 = super.internalMultiply(this.value, 1e-3);
          return super.internalDivide(v3, 0.01);
        }
      case MolarityUnits.DecimolesPerLiter:
        {
          const v3 = super.internalMultiply(this.value, 1e-3);
          return super.internalDivide(v3, 0.1);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MolarityUnits.MolesPerCubicMeter:
        return this.value;
      case MolarityUnits.MolesPerLiter:
        return this.value * 1e-3;
      case MolarityUnits.PoundMolesPerCubicFoot:
        return this.value * 6.2427960576144611956325455827221e-5;
      case MolarityUnits.KilomolesPerCubicMeter:
        return this.value / 1000;
      case MolarityUnits.FemtomolesPerLiter:
        return this.value * 1e-3 / 1e-15;
      case MolarityUnits.PicomolesPerLiter:
        return this.value * 1e-3 / 1e-12;
      case MolarityUnits.NanomolesPerLiter:
        return this.value * 1e-3 / 1e-9;
      case MolarityUnits.MicromolesPerLiter:
        return this.value * 1e-3 / 0.000001;
      case MolarityUnits.MillimolesPerLiter:
        return this.value * 1e-3 / 0.001;
      case MolarityUnits.CentimolesPerLiter:
        return this.value * 1e-3 / 0.01;
      case MolarityUnits.DecimolesPerLiter:
        return this.value * 1e-3 / 0.1;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MolarityUnits.MolesPerCubicMeter:
        return value;
      case MolarityUnits.MolesPerLiter:
        return super.internalDivide(value, 1e-3);
      case MolarityUnits.PoundMolesPerCubicFoot:
        return super.internalDivide(value, 6.2427960576144611956325455827221e-5);
      case MolarityUnits.KilomolesPerCubicMeter:
        return super.internalMultiply(value, 1000);
      case MolarityUnits.FemtomolesPerLiter:
        {
          const v3 = super.internalDivide(value, 1e-3);
          return super.internalMultiply(v3, 1e-15);
        }
      case MolarityUnits.PicomolesPerLiter:
        {
          const v3 = super.internalDivide(value, 1e-3);
          return super.internalMultiply(v3, 1e-12);
        }
      case MolarityUnits.NanomolesPerLiter:
        {
          const v3 = super.internalDivide(value, 1e-3);
          return super.internalMultiply(v3, 1e-9);
        }
      case MolarityUnits.MicromolesPerLiter:
        {
          const v3 = super.internalDivide(value, 1e-3);
          return super.internalMultiply(v3, 0.000001);
        }
      case MolarityUnits.MillimolesPerLiter:
        {
          const v3 = super.internalDivide(value, 1e-3);
          return super.internalMultiply(v3, 0.001);
        }
      case MolarityUnits.CentimolesPerLiter:
        {
          const v3 = super.internalDivide(value, 1e-3);
          return super.internalMultiply(v3, 0.01);
        }
      case MolarityUnits.DecimolesPerLiter:
        {
          const v3 = super.internalDivide(value, 1e-3);
          return super.internalMultiply(v3, 0.1);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MolarityUnits.MolesPerCubicMeter:
        return value;
      case MolarityUnits.MolesPerLiter:
        return value / 1e-3;
      case MolarityUnits.PoundMolesPerCubicFoot:
        return value / 6.2427960576144611956325455827221e-5;
      case MolarityUnits.KilomolesPerCubicMeter:
        return value * 1000;
      case MolarityUnits.FemtomolesPerLiter:
        return value / 1e-3 * 1e-15;
      case MolarityUnits.PicomolesPerLiter:
        return value / 1e-3 * 1e-12;
      case MolarityUnits.NanomolesPerLiter:
        return value / 1e-3 * 1e-9;
      case MolarityUnits.MicromolesPerLiter:
        return value / 1e-3 * 0.000001;
      case MolarityUnits.MillimolesPerLiter:
        return value / 1e-3 * 0.001;
      case MolarityUnits.CentimolesPerLiter:
        return value / 1e-3 * 0.01;
      case MolarityUnits.DecimolesPerLiter:
        return value / 1e-3 * 0.1;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Molarity to string.
   * Note! the default format for Molarity is MolesPerCubicMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Molarity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Molarity.
   */
  toString(unit = MolarityUnits.MolesPerCubicMeter, fractionalDigits) {
    switch (unit) {
      case MolarityUnits.MolesPerCubicMeter:
        return super.truncateFractionDigits(this.MolesPerCubicMeter, fractionalDigits) + ` mol/m³`;
      case MolarityUnits.MolesPerLiter:
        return super.truncateFractionDigits(this.MolesPerLiter, fractionalDigits) + ` mol/L`;
      case MolarityUnits.PoundMolesPerCubicFoot:
        return super.truncateFractionDigits(this.PoundMolesPerCubicFoot, fractionalDigits) + ` lbmol/ft³`;
      case MolarityUnits.KilomolesPerCubicMeter:
        return super.truncateFractionDigits(this.KilomolesPerCubicMeter, fractionalDigits) + ` kmol/m³`;
      case MolarityUnits.FemtomolesPerLiter:
        return super.truncateFractionDigits(this.FemtomolesPerLiter, fractionalDigits) + ` fmol/L`;
      case MolarityUnits.PicomolesPerLiter:
        return super.truncateFractionDigits(this.PicomolesPerLiter, fractionalDigits) + ` pmol/L`;
      case MolarityUnits.NanomolesPerLiter:
        return super.truncateFractionDigits(this.NanomolesPerLiter, fractionalDigits) + ` nmol/L`;
      case MolarityUnits.MicromolesPerLiter:
        return super.truncateFractionDigits(this.MicromolesPerLiter, fractionalDigits) + ` μmol/L`;
      case MolarityUnits.MillimolesPerLiter:
        return super.truncateFractionDigits(this.MillimolesPerLiter, fractionalDigits) + ` mmol/L`;
      case MolarityUnits.CentimolesPerLiter:
        return super.truncateFractionDigits(this.CentimolesPerLiter, fractionalDigits) + ` cmol/L`;
      case MolarityUnits.DecimolesPerLiter:
        return super.truncateFractionDigits(this.DecimolesPerLiter, fractionalDigits) + ` dmol/L`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Molarity unit abbreviation.
   * Note! the default abbreviation for Molarity is MolesPerCubicMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Molarity.
   * @returns The abbreviation string of Molarity.
   */
  getUnitAbbreviation(unitAbbreviation = MolarityUnits.MolesPerCubicMeter) {
    switch (unitAbbreviation) {
      case MolarityUnits.MolesPerCubicMeter:
        return `mol/m³`;
      case MolarityUnits.MolesPerLiter:
        return `mol/L`;
      case MolarityUnits.PoundMolesPerCubicFoot:
        return `lbmol/ft³`;
      case MolarityUnits.KilomolesPerCubicMeter:
        return `kmol/m³`;
      case MolarityUnits.FemtomolesPerLiter:
        return `fmol/L`;
      case MolarityUnits.PicomolesPerLiter:
        return `pmol/L`;
      case MolarityUnits.NanomolesPerLiter:
        return `nmol/L`;
      case MolarityUnits.MicromolesPerLiter:
        return `μmol/L`;
      case MolarityUnits.MillimolesPerLiter:
        return `mmol/L`;
      case MolarityUnits.CentimolesPerLiter:
        return `cmol/L`;
      case MolarityUnits.DecimolesPerLiter:
        return `dmol/L`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Molarity are equals to the current Molarity.
   * @param molarity The other Molarity.
   * @returns True if the given Molarity are equal to the current Molarity.
   */
  equals(molarity) {
    return super.internalEquals(this.value, molarity.BaseValue);
  }
  /**
   * Compare the given Molarity against the current Molarity.
   * @param molarity The other Molarity.
   * @returns 0 if they are equal, -1 if the current Molarity is less then other, 1 if the current Molarity is greater then other.
   */
  compareTo(molarity) {
    return super.internalCompareTo(this.value, molarity.BaseValue);
  }
  /**
   * Add the given Molarity with the current Molarity.
   * @param molarity The other Molarity.
   * @returns A new Molarity instance with the results.
   */
  add(molarity) {
    return new Molarity(super.internalAdd(this.value, molarity.BaseValue));
  }
  /**
   * Subtract the given Molarity with the current Molarity.
   * @param molarity The other Molarity.
   * @returns A new Molarity instance with the results.
   */
  subtract(molarity) {
    return new Molarity(super.internalSubtract(this.value, molarity.BaseValue));
  }
  /**
   * Multiply the given Molarity with the current Molarity.
   * @param molarity The other Molarity.
   * @returns A new Molarity instance with the results.
   */
  multiply(molarity) {
    return new Molarity(super.internalMultiply(this.value, molarity.BaseValue));
  }
  /**
   * Divide the given Molarity with the current Molarity.
   * @param molarity The other Molarity.
   * @returns A new Molarity instance with the results.
   */
  divide(molarity) {
    return new Molarity(super.internalDivide(this.value, molarity.BaseValue));
  }
  /**
   * Modulo the given Molarity with the current Molarity.
   * @param molarity The other Molarity.
   * @returns A new Molarity instance with the results.
   */
  modulo(molarity) {
    return new Molarity(super.internalModulo(this.value, molarity.BaseValue));
  }
  /**
   * Pow the given Molarity with the current Molarity.
   * @param molarity The other Molarity.
   * @returns A new Molarity instance with the results.
   */
  pow(molarity) {
    return new Molarity(super.internalPow(this.value, molarity.BaseValue));
  }
}
exports.Molarity = Molarity;