"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseUnit = exports.areAnyOperatorsOverridden = exports.unsetAllOperatorOverrides = exports.unsetOperatorOverride = exports.setOperatorOverride = exports.CompareOperation = exports.ArithmeticOperation = void 0;
var ArithmeticOperation;
(function (ArithmeticOperation) {
  /** An plus arithmetic operation (JS default "+") */
  ArithmeticOperation["Add"] = "Add";
  /** An subtract arithmetic operation (JS default "-") */
  ArithmeticOperation["Subtract"] = "Subtract";
  /** An multiply arithmetic operation (JS default "/") */
  ArithmeticOperation["Multiply"] = "Multiply";
  /** An divide arithmetic operation (JS default "*") */
  ArithmeticOperation["Divide"] = "Divide";
  /** An modulo arithmetic operation (JS default "%") */
  ArithmeticOperation["Modulo"] = "Modulo";
  /** An power arithmetic operation (JS default "**") */
  ArithmeticOperation["Pow"] = "Pow";
  /** A Square root operation (JS Default "Math.sqrt") */
  ArithmeticOperation["Sqrt"] = "Sqrt";
})(ArithmeticOperation = exports.ArithmeticOperation || (exports.ArithmeticOperation = {}));
var CompareOperation;
(function (CompareOperation) {
  CompareOperation["Equals"] = "Equals";
  CompareOperation["CompareTo"] = "CompareTo";
})(CompareOperation = exports.CompareOperation || (exports.CompareOperation = {}));
let operatorOverrides = {};
let numberOfOverwrittenOperators = 0;
/**
 * Set arithmetic formula to be used while calling this operation on two units (e.g. Length + Length)
 * Instead of the JS default operation (+, -, * etc.)
 * @param arithmeticOperation The formula's operation
 * @param replacementFunction The formula to used.
 */
/**
 * Sets an arithmetic operator to use the given function.
 * @description Certain use-cases require the use of high precision mathematics beyond what's defined in the ECMA specification.
 * This function allows overriding of operators to to facilitate usage of specialized mathematic libraries.
 * @example
 * ```
 * import NP from 'number-precision'
 *
 * setOperatorOverride(ArithmeticOperation.Add, (a, b) => NP.plus(a, b))
 * ```
 *
 * @export
 * @template TOperator
 * @param {TOperator} arithmeticOperation
 * @param {(OperatorOverrides[TOperator] | undefined)} replacementFunction
 */
function setOperatorOverride(arithmeticOperation, replacementFunction) {
  operatorOverrides[arithmeticOperation] = replacementFunction;
  numberOfOverwrittenOperators = Object.values(operatorOverrides).filter(value => !!value).length;
}
exports.setOperatorOverride = setOperatorOverride;
/**
 * Removes the given operator override (i.e., returns operator behavior to default JavaScript implementation)
 *
 * @export
 * @template TOperator The operator to unset
 * @param {TOperator} arithmeticOperation The operator to unset
 */
function unsetOperatorOverride(arithmeticOperation) {
  if (operatorOverrides[arithmeticOperation]) {
    numberOfOverwrittenOperators--;
    operatorOverrides[arithmeticOperation] = undefined;
  }
}
exports.unsetOperatorOverride = unsetOperatorOverride;
/**
 * Removes all operator overrides (i.e., return all operator behaviors to default JavaScript implementation)
 *
 * @export
 */
function unsetAllOperatorOverrides() {
  operatorOverrides = {};
  numberOfOverwrittenOperators = 0;
}
exports.unsetAllOperatorOverrides = unsetAllOperatorOverrides;
/**
 * Gets a boolean indicating whether any operators are currently overridden
 *
 * @export
 * @return {boolean}
 */
function areAnyOperatorsOverridden() {
  return numberOfOverwrittenOperators > 0;
}
exports.areAnyOperatorsOverridden = areAnyOperatorsOverridden;
class BaseUnit {
  /**
   * Truncates a number to a specified number of fractional digits.
   * @param num - The number to truncate.
   * @param fractionalDigits - The number of fractional digits to keep.
   * @returns The truncated number.
   */
  truncateFractionDigits(num, fractionalDigits) {
    if (typeof fractionalDigits !== "number") {
      return num;
    }
    // Convert the number to a string with the desired precision
    const numString = num.toFixed(fractionalDigits);
    // Parse the string back to a number
    const truncatedNum = parseFloat(numString);
    return truncatedNum;
  }
  internalEquals(valueA, valueB) {
    var _a, _b;
    return (_b = (_a = operatorOverrides.Equals) === null || _a === void 0 ? void 0 : _a.call(operatorOverrides, valueA, valueB)) !== null && _b !== void 0 ? _b : valueA === valueB;
  }
  internalCompareTo(valueA, valueB) {
    if (operatorOverrides.CompareTo) {
      return operatorOverrides.CompareTo(valueA, valueB);
    }
    if (valueA > valueB) return 1;
    if (valueA < valueB) return -1;
    return 0;
  }
  internalAdd(valueA, valueB) {
    var _a, _b;
    return (_b = (_a = operatorOverrides.Add) === null || _a === void 0 ? void 0 : _a.call(operatorOverrides, valueA, valueB)) !== null && _b !== void 0 ? _b : valueA + valueB;
  }
  internalSubtract(valueA, valueB) {
    var _a, _b;
    return (_b = (_a = operatorOverrides.Subtract) === null || _a === void 0 ? void 0 : _a.call(operatorOverrides, valueA, valueB)) !== null && _b !== void 0 ? _b : valueA - valueB;
  }
  internalMultiply(valueA, valueB) {
    var _a, _b;
    return (_b = (_a = operatorOverrides.Multiply) === null || _a === void 0 ? void 0 : _a.call(operatorOverrides, valueA, valueB)) !== null && _b !== void 0 ? _b : valueA * valueB;
  }
  internalDivide(valueA, valueB) {
    var _a, _b;
    return (_b = (_a = operatorOverrides.Divide) === null || _a === void 0 ? void 0 : _a.call(operatorOverrides, valueA, valueB)) !== null && _b !== void 0 ? _b : valueA / valueB;
  }
  internalModulo(valueA, valueB) {
    var _a, _b;
    return (_b = (_a = operatorOverrides.Modulo) === null || _a === void 0 ? void 0 : _a.call(operatorOverrides, valueA, valueB)) !== null && _b !== void 0 ? _b : valueA % valueB;
  }
  internalPow(valueA, valueB) {
    var _a, _b;
    return (_b = (_a = operatorOverrides.Pow) === null || _a === void 0 ? void 0 : _a.call(operatorOverrides, valueA, valueB)) !== null && _b !== void 0 ? _b : valueA ** valueB;
  }
  internalSqrt(value) {
    var _a, _b;
    return (_b = (_a = operatorOverrides.Sqrt) === null || _a === void 0 ? void 0 : _a.call(operatorOverrides, value)) !== null && _b !== void 0 ? _b : Math.sqrt(value);
  }
}
exports.BaseUnit = BaseUnit;