"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Molality = exports.MolalityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MolalityUnits enumeration */
var MolalityUnits;
(function (MolalityUnits) {
  /** */
  MolalityUnits["MolesPerKilogram"] = "MolePerKilogram";
  /** */
  MolalityUnits["MolesPerGram"] = "MolePerGram";
  /** */
  MolalityUnits["MillimolesPerKilogram"] = "MillimolePerKilogram";
})(MolalityUnits = exports.MolalityUnits || (exports.MolalityUnits = {}));
/** Molality is a measure of the amount of solute in a solution relative to a given mass of solvent. */
class Molality extends base_unit_1.BaseUnit {
  /**
   * Create a new Molality.
   * @param value The value.
   * @param fromUnit The ‘Molality’ unit to create from.
   * The default unit is MolesPerKilogram
   */
  constructor(value, fromUnit = MolalityUnits.MolesPerKilogram) {
    super();
    this.molesperkilogramLazy = null;
    this.molespergramLazy = null;
    this.millimolesperkilogramLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Molality is MolesPerKilogram.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MolalityUnits.MolesPerKilogram;
  }
  /** */
  get MolesPerKilogram() {
    if (this.molesperkilogramLazy !== null) {
      return this.molesperkilogramLazy;
    }
    return this.molesperkilogramLazy = this.convertFromBase(MolalityUnits.MolesPerKilogram);
  }
  /** */
  get MolesPerGram() {
    if (this.molespergramLazy !== null) {
      return this.molespergramLazy;
    }
    return this.molespergramLazy = this.convertFromBase(MolalityUnits.MolesPerGram);
  }
  /** */
  get MillimolesPerKilogram() {
    if (this.millimolesperkilogramLazy !== null) {
      return this.millimolesperkilogramLazy;
    }
    return this.millimolesperkilogramLazy = this.convertFromBase(MolalityUnits.MillimolesPerKilogram);
  }
  /**
   * Create a new Molality instance from a MolesPerKilogram
   *
   * @param value The unit as MolesPerKilogram to create a new Molality from.
   * @returns The new Molality instance.
   */
  static FromMolesPerKilogram(value) {
    return new Molality(value, MolalityUnits.MolesPerKilogram);
  }
  /**
   * Create a new Molality instance from a MolesPerGram
   *
   * @param value The unit as MolesPerGram to create a new Molality from.
   * @returns The new Molality instance.
   */
  static FromMolesPerGram(value) {
    return new Molality(value, MolalityUnits.MolesPerGram);
  }
  /**
   * Create a new Molality instance from a MillimolesPerKilogram
   *
   * @param value The unit as MillimolesPerKilogram to create a new Molality from.
   * @returns The new Molality instance.
   */
  static FromMillimolesPerKilogram(value) {
    return new Molality(value, MolalityUnits.MillimolesPerKilogram);
  }
  /**
   * Gets the base unit enumeration associated with Molality
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MolalityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MolalityUnits.MolesPerKilogram;
  }
  /**
   * Create API DTO represent a Molality unit.
   * @param holdInUnit The specific Molality unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MolalityUnits.MolesPerKilogram) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Molality unit from an API DTO representation.
   * @param dtoMolality The Molality API DTO representation
   */
  static FromDto(dtoMolality) {
    return new Molality(dtoMolality.value, dtoMolality.unit);
  }
  /**
   * Convert Molality to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MolalityUnits.MolesPerKilogram:
        return this.MolesPerKilogram;
      case MolalityUnits.MolesPerGram:
        return this.MolesPerGram;
      case MolalityUnits.MillimolesPerKilogram:
        return this.MillimolesPerKilogram;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MolalityUnits.MolesPerKilogram:
        return this.value;
      case MolalityUnits.MolesPerGram:
        return super.internalMultiply(this.value, 1e-3);
      case MolalityUnits.MillimolesPerKilogram:
        return super.internalDivide(this.value, 0.001);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MolalityUnits.MolesPerKilogram:
        return this.value;
      case MolalityUnits.MolesPerGram:
        return this.value * 1e-3;
      case MolalityUnits.MillimolesPerKilogram:
        return this.value / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MolalityUnits.MolesPerKilogram:
        return value;
      case MolalityUnits.MolesPerGram:
        return super.internalDivide(value, 1e-3);
      case MolalityUnits.MillimolesPerKilogram:
        return super.internalMultiply(value, 0.001);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MolalityUnits.MolesPerKilogram:
        return value;
      case MolalityUnits.MolesPerGram:
        return value / 1e-3;
      case MolalityUnits.MillimolesPerKilogram:
        return value * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Molality to string.
   * Note! the default format for Molality is MolesPerKilogram.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Molality.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Molality.
   */
  toString(unit = MolalityUnits.MolesPerKilogram, fractionalDigits) {
    switch (unit) {
      case MolalityUnits.MolesPerKilogram:
        return super.truncateFractionDigits(this.MolesPerKilogram, fractionalDigits) + ` mol/kg`;
      case MolalityUnits.MolesPerGram:
        return super.truncateFractionDigits(this.MolesPerGram, fractionalDigits) + ` mol/g`;
      case MolalityUnits.MillimolesPerKilogram:
        return super.truncateFractionDigits(this.MillimolesPerKilogram, fractionalDigits) + ` mmol/kg`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Molality unit abbreviation.
   * Note! the default abbreviation for Molality is MolesPerKilogram.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Molality.
   * @returns The abbreviation string of Molality.
   */
  getUnitAbbreviation(unitAbbreviation = MolalityUnits.MolesPerKilogram) {
    switch (unitAbbreviation) {
      case MolalityUnits.MolesPerKilogram:
        return `mol/kg`;
      case MolalityUnits.MolesPerGram:
        return `mol/g`;
      case MolalityUnits.MillimolesPerKilogram:
        return `mmol/kg`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Molality are equals to the current Molality.
   * @param molality The other Molality.
   * @returns True if the given Molality are equal to the current Molality.
   */
  equals(molality) {
    return super.internalEquals(this.value, molality.BaseValue);
  }
  /**
   * Compare the given Molality against the current Molality.
   * @param molality The other Molality.
   * @returns 0 if they are equal, -1 if the current Molality is less then other, 1 if the current Molality is greater then other.
   */
  compareTo(molality) {
    return super.internalCompareTo(this.value, molality.BaseValue);
  }
  /**
   * Add the given Molality with the current Molality.
   * @param molality The other Molality.
   * @returns A new Molality instance with the results.
   */
  add(molality) {
    return new Molality(super.internalAdd(this.value, molality.BaseValue));
  }
  /**
   * Subtract the given Molality with the current Molality.
   * @param molality The other Molality.
   * @returns A new Molality instance with the results.
   */
  subtract(molality) {
    return new Molality(super.internalSubtract(this.value, molality.BaseValue));
  }
  /**
   * Multiply the given Molality with the current Molality.
   * @param molality The other Molality.
   * @returns A new Molality instance with the results.
   */
  multiply(molality) {
    return new Molality(super.internalMultiply(this.value, molality.BaseValue));
  }
  /**
   * Divide the given Molality with the current Molality.
   * @param molality The other Molality.
   * @returns A new Molality instance with the results.
   */
  divide(molality) {
    return new Molality(super.internalDivide(this.value, molality.BaseValue));
  }
  /**
   * Modulo the given Molality with the current Molality.
   * @param molality The other Molality.
   * @returns A new Molality instance with the results.
   */
  modulo(molality) {
    return new Molality(super.internalModulo(this.value, molality.BaseValue));
  }
  /**
   * Pow the given Molality with the current Molality.
   * @param molality The other Molality.
   * @returns A new Molality instance with the results.
   */
  pow(molality) {
    return new Molality(super.internalPow(this.value, molality.BaseValue));
  }
}
exports.Molality = Molality;