"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Force = exports.ForceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ForceUnits enumeration */
var ForceUnits;
(function (ForceUnits) {
  /** One dyne is equal to 10 micronewtons, 10e−5 N or to 10 nsn (nanosthenes) in the old metre–tonne–second system of units. */
  ForceUnits["Dyne"] = "Dyn";
  /** The kilogram-force, or kilopond, is equal to the magnitude of the force exerted on one kilogram of mass in a 9.80665 m/s2 gravitational field (standard gravity). Therefore, one kilogram-force is by definition equal to 9.80665 N. */
  ForceUnits["KilogramsForce"] = "KilogramForce";
  /** The tonne-force, metric ton-force, megagram-force, and megapond (Mp) are each 1000 kilograms-force. */
  ForceUnits["TonnesForce"] = "TonneForce";
  /** The newton (symbol: N) is the unit of force in the International System of Units (SI). It is defined as 1 kg⋅m/s2, the force which gives a mass of 1 kilogram an acceleration of 1 metre per second per second. */
  ForceUnits["Newtons"] = "Newton";
  /** The kilogram-force, or kilopond, is equal to the magnitude of the force exerted on one kilogram of mass in a 9.80665 m/s2 gravitational field (standard gravity). Therefore, one kilogram-force is by definition equal to 9.80665 N. */
  ForceUnits["KiloPonds"] = "KiloPond";
  /** The poundal is defined as the force necessary to accelerate 1 pound-mass at 1 foot per second per second. 1 pdl = 0.138254954376 N exactly. */
  ForceUnits["Poundals"] = "Poundal";
  /** The standard values of acceleration of the standard gravitational field (gn) and the international avoirdupois pound (lb) result in a pound-force equal to 4.4482216152605 N. */
  ForceUnits["PoundsForce"] = "PoundForce";
  /** An ounce-force is 1⁄16 of a pound-force, or about 0.2780139 newtons. */
  ForceUnits["OunceForce"] = "OunceForce";
  /** The short ton-force is a unit of force equal to 2,000 pounds-force (907.18474 kgf), that is most commonly used in the United States – known there simply as the ton or US ton. */
  ForceUnits["ShortTonsForce"] = "ShortTonForce";
  /** */
  ForceUnits["Micronewtons"] = "Micronewton";
  /** */
  ForceUnits["Millinewtons"] = "Millinewton";
  /** */
  ForceUnits["Decanewtons"] = "Decanewton";
  /** */
  ForceUnits["Kilonewtons"] = "Kilonewton";
  /** */
  ForceUnits["Meganewtons"] = "Meganewton";
  /** */
  ForceUnits["KilopoundsForce"] = "KilopoundForce";
})(ForceUnits = exports.ForceUnits || (exports.ForceUnits = {}));
/** In physics, a force is any influence that causes an object to undergo a certain change, either concerning its movement, direction, or geometrical construction. In other words, a force can cause an object with mass to change its velocity (which includes to begin moving from a state of rest), i.e., to accelerate, or a flexible object to deform, or both. Force can also be described by intuitive concepts such as a push or a pull. A force has both magnitude and direction, making it a vector quantity. It is measured in the SI unit of newtons and represented by the symbol F. */
class Force extends base_unit_1.BaseUnit {
  /**
   * Create a new Force.
   * @param value The value.
   * @param fromUnit The ‘Force’ unit to create from.
   * The default unit is Newtons
   */
  constructor(value, fromUnit = ForceUnits.Newtons) {
    super();
    this.dyneLazy = null;
    this.kilogramsforceLazy = null;
    this.tonnesforceLazy = null;
    this.newtonsLazy = null;
    this.kilopondsLazy = null;
    this.poundalsLazy = null;
    this.poundsforceLazy = null;
    this.ounceforceLazy = null;
    this.shorttonsforceLazy = null;
    this.micronewtonsLazy = null;
    this.millinewtonsLazy = null;
    this.decanewtonsLazy = null;
    this.kilonewtonsLazy = null;
    this.meganewtonsLazy = null;
    this.kilopoundsforceLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Force is Newtons.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ForceUnits.Newtons;
  }
  /** One dyne is equal to 10 micronewtons, 10e−5 N or to 10 nsn (nanosthenes) in the old metre–tonne–second system of units. */
  get Dyne() {
    if (this.dyneLazy !== null) {
      return this.dyneLazy;
    }
    return this.dyneLazy = this.convertFromBase(ForceUnits.Dyne);
  }
  /** The kilogram-force, or kilopond, is equal to the magnitude of the force exerted on one kilogram of mass in a 9.80665 m/s2 gravitational field (standard gravity). Therefore, one kilogram-force is by definition equal to 9.80665 N. */
  get KilogramsForce() {
    if (this.kilogramsforceLazy !== null) {
      return this.kilogramsforceLazy;
    }
    return this.kilogramsforceLazy = this.convertFromBase(ForceUnits.KilogramsForce);
  }
  /** The tonne-force, metric ton-force, megagram-force, and megapond (Mp) are each 1000 kilograms-force. */
  get TonnesForce() {
    if (this.tonnesforceLazy !== null) {
      return this.tonnesforceLazy;
    }
    return this.tonnesforceLazy = this.convertFromBase(ForceUnits.TonnesForce);
  }
  /** The newton (symbol: N) is the unit of force in the International System of Units (SI). It is defined as 1 kg⋅m/s2, the force which gives a mass of 1 kilogram an acceleration of 1 metre per second per second. */
  get Newtons() {
    if (this.newtonsLazy !== null) {
      return this.newtonsLazy;
    }
    return this.newtonsLazy = this.convertFromBase(ForceUnits.Newtons);
  }
  /** The kilogram-force, or kilopond, is equal to the magnitude of the force exerted on one kilogram of mass in a 9.80665 m/s2 gravitational field (standard gravity). Therefore, one kilogram-force is by definition equal to 9.80665 N. */
  get KiloPonds() {
    if (this.kilopondsLazy !== null) {
      return this.kilopondsLazy;
    }
    return this.kilopondsLazy = this.convertFromBase(ForceUnits.KiloPonds);
  }
  /** The poundal is defined as the force necessary to accelerate 1 pound-mass at 1 foot per second per second. 1 pdl = 0.138254954376 N exactly. */
  get Poundals() {
    if (this.poundalsLazy !== null) {
      return this.poundalsLazy;
    }
    return this.poundalsLazy = this.convertFromBase(ForceUnits.Poundals);
  }
  /** The standard values of acceleration of the standard gravitational field (gn) and the international avoirdupois pound (lb) result in a pound-force equal to 4.4482216152605 N. */
  get PoundsForce() {
    if (this.poundsforceLazy !== null) {
      return this.poundsforceLazy;
    }
    return this.poundsforceLazy = this.convertFromBase(ForceUnits.PoundsForce);
  }
  /** An ounce-force is 1⁄16 of a pound-force, or about 0.2780139 newtons. */
  get OunceForce() {
    if (this.ounceforceLazy !== null) {
      return this.ounceforceLazy;
    }
    return this.ounceforceLazy = this.convertFromBase(ForceUnits.OunceForce);
  }
  /** The short ton-force is a unit of force equal to 2,000 pounds-force (907.18474 kgf), that is most commonly used in the United States – known there simply as the ton or US ton. */
  get ShortTonsForce() {
    if (this.shorttonsforceLazy !== null) {
      return this.shorttonsforceLazy;
    }
    return this.shorttonsforceLazy = this.convertFromBase(ForceUnits.ShortTonsForce);
  }
  /** */
  get Micronewtons() {
    if (this.micronewtonsLazy !== null) {
      return this.micronewtonsLazy;
    }
    return this.micronewtonsLazy = this.convertFromBase(ForceUnits.Micronewtons);
  }
  /** */
  get Millinewtons() {
    if (this.millinewtonsLazy !== null) {
      return this.millinewtonsLazy;
    }
    return this.millinewtonsLazy = this.convertFromBase(ForceUnits.Millinewtons);
  }
  /** */
  get Decanewtons() {
    if (this.decanewtonsLazy !== null) {
      return this.decanewtonsLazy;
    }
    return this.decanewtonsLazy = this.convertFromBase(ForceUnits.Decanewtons);
  }
  /** */
  get Kilonewtons() {
    if (this.kilonewtonsLazy !== null) {
      return this.kilonewtonsLazy;
    }
    return this.kilonewtonsLazy = this.convertFromBase(ForceUnits.Kilonewtons);
  }
  /** */
  get Meganewtons() {
    if (this.meganewtonsLazy !== null) {
      return this.meganewtonsLazy;
    }
    return this.meganewtonsLazy = this.convertFromBase(ForceUnits.Meganewtons);
  }
  /** */
  get KilopoundsForce() {
    if (this.kilopoundsforceLazy !== null) {
      return this.kilopoundsforceLazy;
    }
    return this.kilopoundsforceLazy = this.convertFromBase(ForceUnits.KilopoundsForce);
  }
  /**
   * Create a new Force instance from a Dyne
   * One dyne is equal to 10 micronewtons, 10e−5 N or to 10 nsn (nanosthenes) in the old metre–tonne–second system of units.
   * @param value The unit as Dyne to create a new Force from.
   * @returns The new Force instance.
   */
  static FromDyne(value) {
    return new Force(value, ForceUnits.Dyne);
  }
  /**
   * Create a new Force instance from a KilogramsForce
   * The kilogram-force, or kilopond, is equal to the magnitude of the force exerted on one kilogram of mass in a 9.80665 m/s2 gravitational field (standard gravity). Therefore, one kilogram-force is by definition equal to 9.80665 N.
   * @param value The unit as KilogramsForce to create a new Force from.
   * @returns The new Force instance.
   */
  static FromKilogramsForce(value) {
    return new Force(value, ForceUnits.KilogramsForce);
  }
  /**
   * Create a new Force instance from a TonnesForce
   * The tonne-force, metric ton-force, megagram-force, and megapond (Mp) are each 1000 kilograms-force.
   * @param value The unit as TonnesForce to create a new Force from.
   * @returns The new Force instance.
   */
  static FromTonnesForce(value) {
    return new Force(value, ForceUnits.TonnesForce);
  }
  /**
   * Create a new Force instance from a Newtons
   * The newton (symbol: N) is the unit of force in the International System of Units (SI). It is defined as 1 kg⋅m/s2, the force which gives a mass of 1 kilogram an acceleration of 1 metre per second per second.
   * @param value The unit as Newtons to create a new Force from.
   * @returns The new Force instance.
   */
  static FromNewtons(value) {
    return new Force(value, ForceUnits.Newtons);
  }
  /**
   * Create a new Force instance from a KiloPonds
   * The kilogram-force, or kilopond, is equal to the magnitude of the force exerted on one kilogram of mass in a 9.80665 m/s2 gravitational field (standard gravity). Therefore, one kilogram-force is by definition equal to 9.80665 N.
   * @param value The unit as KiloPonds to create a new Force from.
   * @returns The new Force instance.
   */
  static FromKiloPonds(value) {
    return new Force(value, ForceUnits.KiloPonds);
  }
  /**
   * Create a new Force instance from a Poundals
   * The poundal is defined as the force necessary to accelerate 1 pound-mass at 1 foot per second per second. 1 pdl = 0.138254954376 N exactly.
   * @param value The unit as Poundals to create a new Force from.
   * @returns The new Force instance.
   */
  static FromPoundals(value) {
    return new Force(value, ForceUnits.Poundals);
  }
  /**
   * Create a new Force instance from a PoundsForce
   * The standard values of acceleration of the standard gravitational field (gn) and the international avoirdupois pound (lb) result in a pound-force equal to 4.4482216152605 N.
   * @param value The unit as PoundsForce to create a new Force from.
   * @returns The new Force instance.
   */
  static FromPoundsForce(value) {
    return new Force(value, ForceUnits.PoundsForce);
  }
  /**
   * Create a new Force instance from a OunceForce
   * An ounce-force is 1⁄16 of a pound-force, or about 0.2780139 newtons.
   * @param value The unit as OunceForce to create a new Force from.
   * @returns The new Force instance.
   */
  static FromOunceForce(value) {
    return new Force(value, ForceUnits.OunceForce);
  }
  /**
   * Create a new Force instance from a ShortTonsForce
   * The short ton-force is a unit of force equal to 2,000 pounds-force (907.18474 kgf), that is most commonly used in the United States – known there simply as the ton or US ton.
   * @param value The unit as ShortTonsForce to create a new Force from.
   * @returns The new Force instance.
   */
  static FromShortTonsForce(value) {
    return new Force(value, ForceUnits.ShortTonsForce);
  }
  /**
   * Create a new Force instance from a Micronewtons
   *
   * @param value The unit as Micronewtons to create a new Force from.
   * @returns The new Force instance.
   */
  static FromMicronewtons(value) {
    return new Force(value, ForceUnits.Micronewtons);
  }
  /**
   * Create a new Force instance from a Millinewtons
   *
   * @param value The unit as Millinewtons to create a new Force from.
   * @returns The new Force instance.
   */
  static FromMillinewtons(value) {
    return new Force(value, ForceUnits.Millinewtons);
  }
  /**
   * Create a new Force instance from a Decanewtons
   *
   * @param value The unit as Decanewtons to create a new Force from.
   * @returns The new Force instance.
   */
  static FromDecanewtons(value) {
    return new Force(value, ForceUnits.Decanewtons);
  }
  /**
   * Create a new Force instance from a Kilonewtons
   *
   * @param value The unit as Kilonewtons to create a new Force from.
   * @returns The new Force instance.
   */
  static FromKilonewtons(value) {
    return new Force(value, ForceUnits.Kilonewtons);
  }
  /**
   * Create a new Force instance from a Meganewtons
   *
   * @param value The unit as Meganewtons to create a new Force from.
   * @returns The new Force instance.
   */
  static FromMeganewtons(value) {
    return new Force(value, ForceUnits.Meganewtons);
  }
  /**
   * Create a new Force instance from a KilopoundsForce
   *
   * @param value The unit as KilopoundsForce to create a new Force from.
   * @returns The new Force instance.
   */
  static FromKilopoundsForce(value) {
    return new Force(value, ForceUnits.KilopoundsForce);
  }
  /**
   * Gets the base unit enumeration associated with Force
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ForceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ForceUnits.Newtons;
  }
  /**
   * Create API DTO represent a Force unit.
   * @param holdInUnit The specific Force unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ForceUnits.Newtons) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Force unit from an API DTO representation.
   * @param dtoForce The Force API DTO representation
   */
  static FromDto(dtoForce) {
    return new Force(dtoForce.value, dtoForce.unit);
  }
  /**
   * Convert Force to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ForceUnits.Dyne:
        return this.Dyne;
      case ForceUnits.KilogramsForce:
        return this.KilogramsForce;
      case ForceUnits.TonnesForce:
        return this.TonnesForce;
      case ForceUnits.Newtons:
        return this.Newtons;
      case ForceUnits.KiloPonds:
        return this.KiloPonds;
      case ForceUnits.Poundals:
        return this.Poundals;
      case ForceUnits.PoundsForce:
        return this.PoundsForce;
      case ForceUnits.OunceForce:
        return this.OunceForce;
      case ForceUnits.ShortTonsForce:
        return this.ShortTonsForce;
      case ForceUnits.Micronewtons:
        return this.Micronewtons;
      case ForceUnits.Millinewtons:
        return this.Millinewtons;
      case ForceUnits.Decanewtons:
        return this.Decanewtons;
      case ForceUnits.Kilonewtons:
        return this.Kilonewtons;
      case ForceUnits.Meganewtons:
        return this.Meganewtons;
      case ForceUnits.KilopoundsForce:
        return this.KilopoundsForce;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ForceUnits.Dyne:
        return super.internalMultiply(this.value, 1e5);
      case ForceUnits.KilogramsForce:
        return super.internalDivide(this.value, 9.80665);
      case ForceUnits.TonnesForce:
        {
          const v4 = super.internalMultiply(9.80665, 1000);
          return super.internalDivide(this.value, v4);
        }
      case ForceUnits.Newtons:
        return this.value;
      case ForceUnits.KiloPonds:
        return super.internalDivide(this.value, 9.80665);
      case ForceUnits.Poundals:
        return super.internalDivide(this.value, 0.138254954376);
      case ForceUnits.PoundsForce:
        return super.internalDivide(this.value, 4.4482216152605);
      case ForceUnits.OunceForce:
        {
          const v4 = super.internalDivide(4.4482216152605, 16);
          return super.internalDivide(this.value, v4);
        }
      case ForceUnits.ShortTonsForce:
        {
          const v4 = super.internalMultiply(4.4482216152605, 2000);
          return super.internalDivide(this.value, v4);
        }
      case ForceUnits.Micronewtons:
        return super.internalDivide(this.value, 0.000001);
      case ForceUnits.Millinewtons:
        return super.internalDivide(this.value, 0.001);
      case ForceUnits.Decanewtons:
        return super.internalDivide(this.value, 10);
      case ForceUnits.Kilonewtons:
        return super.internalDivide(this.value, 1000);
      case ForceUnits.Meganewtons:
        return super.internalDivide(this.value, 1000000);
      case ForceUnits.KilopoundsForce:
        {
          const v3 = super.internalDivide(this.value, 4.4482216152605);
          return super.internalDivide(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ForceUnits.Dyne:
        return this.value * 1e5;
      case ForceUnits.KilogramsForce:
        return this.value / 9.80665;
      case ForceUnits.TonnesForce:
        return this.value / (9.80665 * 1000);
      case ForceUnits.Newtons:
        return this.value;
      case ForceUnits.KiloPonds:
        return this.value / 9.80665;
      case ForceUnits.Poundals:
        return this.value / 0.138254954376;
      case ForceUnits.PoundsForce:
        return this.value / 4.4482216152605;
      case ForceUnits.OunceForce:
        return this.value / (4.4482216152605 / 16);
      case ForceUnits.ShortTonsForce:
        return this.value / (4.4482216152605 * 2000);
      case ForceUnits.Micronewtons:
        return this.value / 0.000001;
      case ForceUnits.Millinewtons:
        return this.value / 0.001;
      case ForceUnits.Decanewtons:
        return this.value / 10;
      case ForceUnits.Kilonewtons:
        return this.value / 1000;
      case ForceUnits.Meganewtons:
        return this.value / 1000000;
      case ForceUnits.KilopoundsForce:
        return this.value / 4.4482216152605 / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ForceUnits.Dyne:
        return super.internalDivide(value, 1e5);
      case ForceUnits.KilogramsForce:
        return super.internalMultiply(value, 9.80665);
      case ForceUnits.TonnesForce:
        {
          const v4 = super.internalMultiply(9.80665, 1000);
          return super.internalMultiply(value, v4);
        }
      case ForceUnits.Newtons:
        return value;
      case ForceUnits.KiloPonds:
        return super.internalMultiply(value, 9.80665);
      case ForceUnits.Poundals:
        return super.internalMultiply(value, 0.138254954376);
      case ForceUnits.PoundsForce:
        return super.internalMultiply(value, 4.4482216152605);
      case ForceUnits.OunceForce:
        {
          const v4 = super.internalDivide(4.4482216152605, 16);
          return super.internalMultiply(value, v4);
        }
      case ForceUnits.ShortTonsForce:
        {
          const v4 = super.internalMultiply(4.4482216152605, 2000);
          return super.internalMultiply(value, v4);
        }
      case ForceUnits.Micronewtons:
        return super.internalMultiply(value, 0.000001);
      case ForceUnits.Millinewtons:
        return super.internalMultiply(value, 0.001);
      case ForceUnits.Decanewtons:
        return super.internalMultiply(value, 10);
      case ForceUnits.Kilonewtons:
        return super.internalMultiply(value, 1000);
      case ForceUnits.Meganewtons:
        return super.internalMultiply(value, 1000000);
      case ForceUnits.KilopoundsForce:
        {
          const v3 = super.internalMultiply(value, 4.4482216152605);
          return super.internalMultiply(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ForceUnits.Dyne:
        return value / 1e5;
      case ForceUnits.KilogramsForce:
        return value * 9.80665;
      case ForceUnits.TonnesForce:
        return value * (9.80665 * 1000);
      case ForceUnits.Newtons:
        return value;
      case ForceUnits.KiloPonds:
        return value * 9.80665;
      case ForceUnits.Poundals:
        return value * 0.138254954376;
      case ForceUnits.PoundsForce:
        return value * 4.4482216152605;
      case ForceUnits.OunceForce:
        return value * (4.4482216152605 / 16);
      case ForceUnits.ShortTonsForce:
        return value * (4.4482216152605 * 2000);
      case ForceUnits.Micronewtons:
        return value * 0.000001;
      case ForceUnits.Millinewtons:
        return value * 0.001;
      case ForceUnits.Decanewtons:
        return value * 10;
      case ForceUnits.Kilonewtons:
        return value * 1000;
      case ForceUnits.Meganewtons:
        return value * 1000000;
      case ForceUnits.KilopoundsForce:
        return value * 4.4482216152605 * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Force to string.
   * Note! the default format for Force is Newtons.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Force.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Force.
   */
  toString(unit = ForceUnits.Newtons, fractionalDigits) {
    switch (unit) {
      case ForceUnits.Dyne:
        return super.truncateFractionDigits(this.Dyne, fractionalDigits) + ` dyn`;
      case ForceUnits.KilogramsForce:
        return super.truncateFractionDigits(this.KilogramsForce, fractionalDigits) + ` kgf`;
      case ForceUnits.TonnesForce:
        return super.truncateFractionDigits(this.TonnesForce, fractionalDigits) + ` tf`;
      case ForceUnits.Newtons:
        return super.truncateFractionDigits(this.Newtons, fractionalDigits) + ` N`;
      case ForceUnits.KiloPonds:
        return super.truncateFractionDigits(this.KiloPonds, fractionalDigits) + ` kp`;
      case ForceUnits.Poundals:
        return super.truncateFractionDigits(this.Poundals, fractionalDigits) + ` pdl`;
      case ForceUnits.PoundsForce:
        return super.truncateFractionDigits(this.PoundsForce, fractionalDigits) + ` lbf`;
      case ForceUnits.OunceForce:
        return super.truncateFractionDigits(this.OunceForce, fractionalDigits) + ` ozf`;
      case ForceUnits.ShortTonsForce:
        return super.truncateFractionDigits(this.ShortTonsForce, fractionalDigits) + ` tf (short)`;
      case ForceUnits.Micronewtons:
        return super.truncateFractionDigits(this.Micronewtons, fractionalDigits) + ` μN`;
      case ForceUnits.Millinewtons:
        return super.truncateFractionDigits(this.Millinewtons, fractionalDigits) + ` mN`;
      case ForceUnits.Decanewtons:
        return super.truncateFractionDigits(this.Decanewtons, fractionalDigits) + ` daN`;
      case ForceUnits.Kilonewtons:
        return super.truncateFractionDigits(this.Kilonewtons, fractionalDigits) + ` kN`;
      case ForceUnits.Meganewtons:
        return super.truncateFractionDigits(this.Meganewtons, fractionalDigits) + ` MN`;
      case ForceUnits.KilopoundsForce:
        return super.truncateFractionDigits(this.KilopoundsForce, fractionalDigits) + ` klbf`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Force unit abbreviation.
   * Note! the default abbreviation for Force is Newtons.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Force.
   * @returns The abbreviation string of Force.
   */
  getUnitAbbreviation(unitAbbreviation = ForceUnits.Newtons) {
    switch (unitAbbreviation) {
      case ForceUnits.Dyne:
        return `dyn`;
      case ForceUnits.KilogramsForce:
        return `kgf`;
      case ForceUnits.TonnesForce:
        return `tf`;
      case ForceUnits.Newtons:
        return `N`;
      case ForceUnits.KiloPonds:
        return `kp`;
      case ForceUnits.Poundals:
        return `pdl`;
      case ForceUnits.PoundsForce:
        return `lbf`;
      case ForceUnits.OunceForce:
        return `ozf`;
      case ForceUnits.ShortTonsForce:
        return `tf (short)`;
      case ForceUnits.Micronewtons:
        return `μN`;
      case ForceUnits.Millinewtons:
        return `mN`;
      case ForceUnits.Decanewtons:
        return `daN`;
      case ForceUnits.Kilonewtons:
        return `kN`;
      case ForceUnits.Meganewtons:
        return `MN`;
      case ForceUnits.KilopoundsForce:
        return `klbf`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Force are equals to the current Force.
   * @param force The other Force.
   * @returns True if the given Force are equal to the current Force.
   */
  equals(force) {
    return super.internalEquals(this.value, force.BaseValue);
  }
  /**
   * Compare the given Force against the current Force.
   * @param force The other Force.
   * @returns 0 if they are equal, -1 if the current Force is less then other, 1 if the current Force is greater then other.
   */
  compareTo(force) {
    return super.internalCompareTo(this.value, force.BaseValue);
  }
  /**
   * Add the given Force with the current Force.
   * @param force The other Force.
   * @returns A new Force instance with the results.
   */
  add(force) {
    return new Force(super.internalAdd(this.value, force.BaseValue));
  }
  /**
   * Subtract the given Force with the current Force.
   * @param force The other Force.
   * @returns A new Force instance with the results.
   */
  subtract(force) {
    return new Force(super.internalSubtract(this.value, force.BaseValue));
  }
  /**
   * Multiply the given Force with the current Force.
   * @param force The other Force.
   * @returns A new Force instance with the results.
   */
  multiply(force) {
    return new Force(super.internalMultiply(this.value, force.BaseValue));
  }
  /**
   * Divide the given Force with the current Force.
   * @param force The other Force.
   * @returns A new Force instance with the results.
   */
  divide(force) {
    return new Force(super.internalDivide(this.value, force.BaseValue));
  }
  /**
   * Modulo the given Force with the current Force.
   * @param force The other Force.
   * @returns A new Force instance with the results.
   */
  modulo(force) {
    return new Force(super.internalModulo(this.value, force.BaseValue));
  }
  /**
   * Pow the given Force with the current Force.
   * @param force The other Force.
   * @returns A new Force instance with the results.
   */
  pow(force) {
    return new Force(super.internalPow(this.value, force.BaseValue));
  }
}
exports.Force = Force;