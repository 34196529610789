import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Home } from '../models/home';
import { AppConstants } from '../shared/constants';
import { BaseService } from '../services/base.service';
import { map, mergeMap } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { SettingService } from '../services/setting.service';
import { ApplicationSettings } from '../models/applicationSettings';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    homeScreenData: Home = {
        id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        box1: '',
        box2: '',
        box3: '',
        box4: '',
        userId: '',
        actionBy: '',
        actionOn: new Date()
    };
    constructor(private http: HttpClient, private baseService: BaseService, @Inject(SettingService) private config: ApplicationSettings) { }


    fetchHomeById(userId: string): Observable<any> {
        if (userId === '' || userId === undefined) {
            return this.getUserDetails().pipe(
                mergeMap((res: any) => {
                    let userID = res.onPremisesSamAccountName;
                    let url = 'Home/GetHomeById';
                    return this.baseService.GetByID(url, userID, AppConstants.Loading).pipe(
                        map((resHome: any) => {
                            this.homeScreenData = { ...resHome };
                            return resHome;
                        }, (error: any) => error))
                }
                ));
        }
        else {
            return of({ ...this.homeScreenData });
        }
    }

    getUserDetails(): Observable<any> {
        const graphApiUrl = `${this.config.graphApi}/?$select=id,givenName,surname,displayName,mail,userPrincipalName,jobTitle,businessPhones,mobilePhone,displayName,streetAddress,city,postalCode,state,country,department,officeLocation,companyName,onPremisesSamAccountName,extensionAttribute2`;
        return this.http.get(graphApiUrl);
    }

    saveHome(data: Home) {
        let url = 'Home/SubmitHome';
        return this.baseService.Post(url, data).pipe(
            map((result: any) => result, (error: any) => error))
    }
}
