"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricSurfaceChargeDensity = exports.ElectricSurfaceChargeDensityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricSurfaceChargeDensityUnits enumeration */
var ElectricSurfaceChargeDensityUnits;
(function (ElectricSurfaceChargeDensityUnits) {
  /** */
  ElectricSurfaceChargeDensityUnits["CoulombsPerSquareMeter"] = "CoulombPerSquareMeter";
  /** */
  ElectricSurfaceChargeDensityUnits["CoulombsPerSquareCentimeter"] = "CoulombPerSquareCentimeter";
  /** */
  ElectricSurfaceChargeDensityUnits["CoulombsPerSquareInch"] = "CoulombPerSquareInch";
})(ElectricSurfaceChargeDensityUnits = exports.ElectricSurfaceChargeDensityUnits || (exports.ElectricSurfaceChargeDensityUnits = {}));
/** In electromagnetism, surface charge density is a measure of the amount of electric charge per surface area. */
class ElectricSurfaceChargeDensity extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricSurfaceChargeDensity.
   * @param value The value.
   * @param fromUnit The ‘ElectricSurfaceChargeDensity’ unit to create from.
   * The default unit is CoulombsPerSquareMeter
   */
  constructor(value, fromUnit = ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter) {
    super();
    this.coulombspersquaremeterLazy = null;
    this.coulombspersquarecentimeterLazy = null;
    this.coulombspersquareinchLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricSurfaceChargeDensity is CoulombsPerSquareMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter;
  }
  /** */
  get CoulombsPerSquareMeter() {
    if (this.coulombspersquaremeterLazy !== null) {
      return this.coulombspersquaremeterLazy;
    }
    return this.coulombspersquaremeterLazy = this.convertFromBase(ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter);
  }
  /** */
  get CoulombsPerSquareCentimeter() {
    if (this.coulombspersquarecentimeterLazy !== null) {
      return this.coulombspersquarecentimeterLazy;
    }
    return this.coulombspersquarecentimeterLazy = this.convertFromBase(ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter);
  }
  /** */
  get CoulombsPerSquareInch() {
    if (this.coulombspersquareinchLazy !== null) {
      return this.coulombspersquareinchLazy;
    }
    return this.coulombspersquareinchLazy = this.convertFromBase(ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch);
  }
  /**
   * Create a new ElectricSurfaceChargeDensity instance from a CoulombsPerSquareMeter
   *
   * @param value The unit as CoulombsPerSquareMeter to create a new ElectricSurfaceChargeDensity from.
   * @returns The new ElectricSurfaceChargeDensity instance.
   */
  static FromCoulombsPerSquareMeter(value) {
    return new ElectricSurfaceChargeDensity(value, ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter);
  }
  /**
   * Create a new ElectricSurfaceChargeDensity instance from a CoulombsPerSquareCentimeter
   *
   * @param value The unit as CoulombsPerSquareCentimeter to create a new ElectricSurfaceChargeDensity from.
   * @returns The new ElectricSurfaceChargeDensity instance.
   */
  static FromCoulombsPerSquareCentimeter(value) {
    return new ElectricSurfaceChargeDensity(value, ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter);
  }
  /**
   * Create a new ElectricSurfaceChargeDensity instance from a CoulombsPerSquareInch
   *
   * @param value The unit as CoulombsPerSquareInch to create a new ElectricSurfaceChargeDensity from.
   * @returns The new ElectricSurfaceChargeDensity instance.
   */
  static FromCoulombsPerSquareInch(value) {
    return new ElectricSurfaceChargeDensity(value, ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch);
  }
  /**
   * Gets the base unit enumeration associated with ElectricSurfaceChargeDensity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricSurfaceChargeDensityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter;
  }
  /**
   * Create API DTO represent a ElectricSurfaceChargeDensity unit.
   * @param holdInUnit The specific ElectricSurfaceChargeDensity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricSurfaceChargeDensity unit from an API DTO representation.
   * @param dtoElectricSurfaceChargeDensity The ElectricSurfaceChargeDensity API DTO representation
   */
  static FromDto(dtoElectricSurfaceChargeDensity) {
    return new ElectricSurfaceChargeDensity(dtoElectricSurfaceChargeDensity.value, dtoElectricSurfaceChargeDensity.unit);
  }
  /**
   * Convert ElectricSurfaceChargeDensity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter:
        return this.CoulombsPerSquareMeter;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter:
        return this.CoulombsPerSquareCentimeter;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch:
        return this.CoulombsPerSquareInch;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter:
        return this.value;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter:
        return super.internalDivide(this.value, 1.0e4);
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch:
        return super.internalDivide(this.value, 1.5500031000062000e3);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter:
        return this.value;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter:
        return this.value / 1.0e4;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch:
        return this.value / 1.5500031000062000e3;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter:
        return value;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter:
        return super.internalMultiply(value, 1.0e4);
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch:
        return super.internalMultiply(value, 1.5500031000062000e3);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter:
        return value;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter:
        return value * 1.0e4;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch:
        return value * 1.5500031000062000e3;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricSurfaceChargeDensity to string.
   * Note! the default format for ElectricSurfaceChargeDensity is CoulombsPerSquareMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricSurfaceChargeDensity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricSurfaceChargeDensity.
   */
  toString(unit = ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter, fractionalDigits) {
    switch (unit) {
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter:
        return super.truncateFractionDigits(this.CoulombsPerSquareMeter, fractionalDigits) + ` C/m²`;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter:
        return super.truncateFractionDigits(this.CoulombsPerSquareCentimeter, fractionalDigits) + ` C/cm²`;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch:
        return super.truncateFractionDigits(this.CoulombsPerSquareInch, fractionalDigits) + ` C/in²`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricSurfaceChargeDensity unit abbreviation.
   * Note! the default abbreviation for ElectricSurfaceChargeDensity is CoulombsPerSquareMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricSurfaceChargeDensity.
   * @returns The abbreviation string of ElectricSurfaceChargeDensity.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter) {
    switch (unitAbbreviation) {
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareMeter:
        return `C/m²`;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareCentimeter:
        return `C/cm²`;
      case ElectricSurfaceChargeDensityUnits.CoulombsPerSquareInch:
        return `C/in²`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricSurfaceChargeDensity are equals to the current ElectricSurfaceChargeDensity.
   * @param electricSurfaceChargeDensity The other ElectricSurfaceChargeDensity.
   * @returns True if the given ElectricSurfaceChargeDensity are equal to the current ElectricSurfaceChargeDensity.
   */
  equals(electricSurfaceChargeDensity) {
    return super.internalEquals(this.value, electricSurfaceChargeDensity.BaseValue);
  }
  /**
   * Compare the given ElectricSurfaceChargeDensity against the current ElectricSurfaceChargeDensity.
   * @param electricSurfaceChargeDensity The other ElectricSurfaceChargeDensity.
   * @returns 0 if they are equal, -1 if the current ElectricSurfaceChargeDensity is less then other, 1 if the current ElectricSurfaceChargeDensity is greater then other.
   */
  compareTo(electricSurfaceChargeDensity) {
    return super.internalCompareTo(this.value, electricSurfaceChargeDensity.BaseValue);
  }
  /**
   * Add the given ElectricSurfaceChargeDensity with the current ElectricSurfaceChargeDensity.
   * @param electricSurfaceChargeDensity The other ElectricSurfaceChargeDensity.
   * @returns A new ElectricSurfaceChargeDensity instance with the results.
   */
  add(electricSurfaceChargeDensity) {
    return new ElectricSurfaceChargeDensity(super.internalAdd(this.value, electricSurfaceChargeDensity.BaseValue));
  }
  /**
   * Subtract the given ElectricSurfaceChargeDensity with the current ElectricSurfaceChargeDensity.
   * @param electricSurfaceChargeDensity The other ElectricSurfaceChargeDensity.
   * @returns A new ElectricSurfaceChargeDensity instance with the results.
   */
  subtract(electricSurfaceChargeDensity) {
    return new ElectricSurfaceChargeDensity(super.internalSubtract(this.value, electricSurfaceChargeDensity.BaseValue));
  }
  /**
   * Multiply the given ElectricSurfaceChargeDensity with the current ElectricSurfaceChargeDensity.
   * @param electricSurfaceChargeDensity The other ElectricSurfaceChargeDensity.
   * @returns A new ElectricSurfaceChargeDensity instance with the results.
   */
  multiply(electricSurfaceChargeDensity) {
    return new ElectricSurfaceChargeDensity(super.internalMultiply(this.value, electricSurfaceChargeDensity.BaseValue));
  }
  /**
   * Divide the given ElectricSurfaceChargeDensity with the current ElectricSurfaceChargeDensity.
   * @param electricSurfaceChargeDensity The other ElectricSurfaceChargeDensity.
   * @returns A new ElectricSurfaceChargeDensity instance with the results.
   */
  divide(electricSurfaceChargeDensity) {
    return new ElectricSurfaceChargeDensity(super.internalDivide(this.value, electricSurfaceChargeDensity.BaseValue));
  }
  /**
   * Modulo the given ElectricSurfaceChargeDensity with the current ElectricSurfaceChargeDensity.
   * @param electricSurfaceChargeDensity The other ElectricSurfaceChargeDensity.
   * @returns A new ElectricSurfaceChargeDensity instance with the results.
   */
  modulo(electricSurfaceChargeDensity) {
    return new ElectricSurfaceChargeDensity(super.internalModulo(this.value, electricSurfaceChargeDensity.BaseValue));
  }
  /**
   * Pow the given ElectricSurfaceChargeDensity with the current ElectricSurfaceChargeDensity.
   * @param electricSurfaceChargeDensity The other ElectricSurfaceChargeDensity.
   * @returns A new ElectricSurfaceChargeDensity instance with the results.
   */
  pow(electricSurfaceChargeDensity) {
    return new ElectricSurfaceChargeDensity(super.internalPow(this.value, electricSurfaceChargeDensity.BaseValue));
  }
}
exports.ElectricSurfaceChargeDensity = ElectricSurfaceChargeDensity;