"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricChargeDensity = exports.ElectricChargeDensityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricChargeDensityUnits enumeration */
var ElectricChargeDensityUnits;
(function (ElectricChargeDensityUnits) {
  /** */
  ElectricChargeDensityUnits["CoulombsPerCubicMeter"] = "CoulombPerCubicMeter";
})(ElectricChargeDensityUnits = exports.ElectricChargeDensityUnits || (exports.ElectricChargeDensityUnits = {}));
/** In electromagnetism, charge density is a measure of the amount of electric charge per volume. */
class ElectricChargeDensity extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricChargeDensity.
   * @param value The value.
   * @param fromUnit The ‘ElectricChargeDensity’ unit to create from.
   * The default unit is CoulombsPerCubicMeter
   */
  constructor(value, fromUnit = ElectricChargeDensityUnits.CoulombsPerCubicMeter) {
    super();
    this.coulombspercubicmeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricChargeDensity is CoulombsPerCubicMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricChargeDensityUnits.CoulombsPerCubicMeter;
  }
  /** */
  get CoulombsPerCubicMeter() {
    if (this.coulombspercubicmeterLazy !== null) {
      return this.coulombspercubicmeterLazy;
    }
    return this.coulombspercubicmeterLazy = this.convertFromBase(ElectricChargeDensityUnits.CoulombsPerCubicMeter);
  }
  /**
   * Create a new ElectricChargeDensity instance from a CoulombsPerCubicMeter
   *
   * @param value The unit as CoulombsPerCubicMeter to create a new ElectricChargeDensity from.
   * @returns The new ElectricChargeDensity instance.
   */
  static FromCoulombsPerCubicMeter(value) {
    return new ElectricChargeDensity(value, ElectricChargeDensityUnits.CoulombsPerCubicMeter);
  }
  /**
   * Gets the base unit enumeration associated with ElectricChargeDensity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricChargeDensityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricChargeDensityUnits.CoulombsPerCubicMeter;
  }
  /**
   * Create API DTO represent a ElectricChargeDensity unit.
   * @param holdInUnit The specific ElectricChargeDensity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricChargeDensityUnits.CoulombsPerCubicMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricChargeDensity unit from an API DTO representation.
   * @param dtoElectricChargeDensity The ElectricChargeDensity API DTO representation
   */
  static FromDto(dtoElectricChargeDensity) {
    return new ElectricChargeDensity(dtoElectricChargeDensity.value, dtoElectricChargeDensity.unit);
  }
  /**
   * Convert ElectricChargeDensity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricChargeDensityUnits.CoulombsPerCubicMeter:
        return this.CoulombsPerCubicMeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricChargeDensityUnits.CoulombsPerCubicMeter:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricChargeDensityUnits.CoulombsPerCubicMeter:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricChargeDensityUnits.CoulombsPerCubicMeter:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricChargeDensityUnits.CoulombsPerCubicMeter:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricChargeDensity to string.
   * Note! the default format for ElectricChargeDensity is CoulombsPerCubicMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricChargeDensity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricChargeDensity.
   */
  toString(unit = ElectricChargeDensityUnits.CoulombsPerCubicMeter, fractionalDigits) {
    switch (unit) {
      case ElectricChargeDensityUnits.CoulombsPerCubicMeter:
        return super.truncateFractionDigits(this.CoulombsPerCubicMeter, fractionalDigits) + ` C/m³`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricChargeDensity unit abbreviation.
   * Note! the default abbreviation for ElectricChargeDensity is CoulombsPerCubicMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricChargeDensity.
   * @returns The abbreviation string of ElectricChargeDensity.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricChargeDensityUnits.CoulombsPerCubicMeter) {
    switch (unitAbbreviation) {
      case ElectricChargeDensityUnits.CoulombsPerCubicMeter:
        return `C/m³`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricChargeDensity are equals to the current ElectricChargeDensity.
   * @param electricChargeDensity The other ElectricChargeDensity.
   * @returns True if the given ElectricChargeDensity are equal to the current ElectricChargeDensity.
   */
  equals(electricChargeDensity) {
    return super.internalEquals(this.value, electricChargeDensity.BaseValue);
  }
  /**
   * Compare the given ElectricChargeDensity against the current ElectricChargeDensity.
   * @param electricChargeDensity The other ElectricChargeDensity.
   * @returns 0 if they are equal, -1 if the current ElectricChargeDensity is less then other, 1 if the current ElectricChargeDensity is greater then other.
   */
  compareTo(electricChargeDensity) {
    return super.internalCompareTo(this.value, electricChargeDensity.BaseValue);
  }
  /**
   * Add the given ElectricChargeDensity with the current ElectricChargeDensity.
   * @param electricChargeDensity The other ElectricChargeDensity.
   * @returns A new ElectricChargeDensity instance with the results.
   */
  add(electricChargeDensity) {
    return new ElectricChargeDensity(super.internalAdd(this.value, electricChargeDensity.BaseValue));
  }
  /**
   * Subtract the given ElectricChargeDensity with the current ElectricChargeDensity.
   * @param electricChargeDensity The other ElectricChargeDensity.
   * @returns A new ElectricChargeDensity instance with the results.
   */
  subtract(electricChargeDensity) {
    return new ElectricChargeDensity(super.internalSubtract(this.value, electricChargeDensity.BaseValue));
  }
  /**
   * Multiply the given ElectricChargeDensity with the current ElectricChargeDensity.
   * @param electricChargeDensity The other ElectricChargeDensity.
   * @returns A new ElectricChargeDensity instance with the results.
   */
  multiply(electricChargeDensity) {
    return new ElectricChargeDensity(super.internalMultiply(this.value, electricChargeDensity.BaseValue));
  }
  /**
   * Divide the given ElectricChargeDensity with the current ElectricChargeDensity.
   * @param electricChargeDensity The other ElectricChargeDensity.
   * @returns A new ElectricChargeDensity instance with the results.
   */
  divide(electricChargeDensity) {
    return new ElectricChargeDensity(super.internalDivide(this.value, electricChargeDensity.BaseValue));
  }
  /**
   * Modulo the given ElectricChargeDensity with the current ElectricChargeDensity.
   * @param electricChargeDensity The other ElectricChargeDensity.
   * @returns A new ElectricChargeDensity instance with the results.
   */
  modulo(electricChargeDensity) {
    return new ElectricChargeDensity(super.internalModulo(this.value, electricChargeDensity.BaseValue));
  }
  /**
   * Pow the given ElectricChargeDensity with the current ElectricChargeDensity.
   * @param electricChargeDensity The other ElectricChargeDensity.
   * @returns A new ElectricChargeDensity instance with the results.
   */
  pow(electricChargeDensity) {
    return new ElectricChargeDensity(super.internalPow(this.value, electricChargeDensity.BaseValue));
  }
}
exports.ElectricChargeDensity = ElectricChargeDensity;