"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Volume = exports.VolumeUnits = void 0;
const base_unit_1 = require("../base-unit");
/** VolumeUnits enumeration */
var VolumeUnits;
(function (VolumeUnits) {
  /** */
  VolumeUnits["Liters"] = "Liter";
  /** */
  VolumeUnits["CubicMeters"] = "CubicMeter";
  /** */
  VolumeUnits["CubicKilometers"] = "CubicKilometer";
  /** */
  VolumeUnits["CubicHectometers"] = "CubicHectometer";
  /** */
  VolumeUnits["CubicDecimeters"] = "CubicDecimeter";
  /** */
  VolumeUnits["CubicCentimeters"] = "CubicCentimeter";
  /** */
  VolumeUnits["CubicMillimeters"] = "CubicMillimeter";
  /** */
  VolumeUnits["CubicMicrometers"] = "CubicMicrometer";
  /** */
  VolumeUnits["CubicMiles"] = "CubicMile";
  /** */
  VolumeUnits["CubicYards"] = "CubicYard";
  /** */
  VolumeUnits["CubicFeet"] = "CubicFoot";
  /** */
  VolumeUnits["CubicInches"] = "CubicInch";
  /** The British imperial gallon (frequently called simply "gallon") is defined as exactly 4.54609 litres. */
  VolumeUnits["ImperialGallons"] = "ImperialGallon";
  /** */
  VolumeUnits["ImperialOunces"] = "ImperialOunce";
  /** The US liquid gallon (frequently called simply "gallon") is legally defined as 231 cubic inches, which is exactly 3.785411784 litres. */
  VolumeUnits["UsGallons"] = "UsGallon";
  /** */
  VolumeUnits["UsOunces"] = "UsOunce";
  /** */
  VolumeUnits["UsTablespoons"] = "UsTablespoon";
  /** */
  VolumeUnits["AuTablespoons"] = "AuTablespoon";
  /** */
  VolumeUnits["UkTablespoons"] = "UkTablespoon";
  /** */
  VolumeUnits["MetricTeaspoons"] = "MetricTeaspoon";
  /** */
  VolumeUnits["UsTeaspoons"] = "UsTeaspoon";
  /** */
  VolumeUnits["MetricCups"] = "MetricCup";
  /** */
  VolumeUnits["UsCustomaryCups"] = "UsCustomaryCup";
  /** */
  VolumeUnits["UsLegalCups"] = "UsLegalCup";
  /** */
  VolumeUnits["OilBarrels"] = "OilBarrel";
  /** */
  VolumeUnits["UsBeerBarrels"] = "UsBeerBarrel";
  /** */
  VolumeUnits["ImperialBeerBarrels"] = "ImperialBeerBarrel";
  /** */
  VolumeUnits["UsQuarts"] = "UsQuart";
  /** */
  VolumeUnits["ImperialQuarts"] = "ImperialQuart";
  /** */
  VolumeUnits["UsPints"] = "UsPint";
  /** */
  VolumeUnits["AcreFeet"] = "AcreFoot";
  /** */
  VolumeUnits["ImperialPints"] = "ImperialPint";
  /** */
  VolumeUnits["BoardFeet"] = "BoardFoot";
  /** */
  VolumeUnits["Nanoliters"] = "Nanoliter";
  /** */
  VolumeUnits["Microliters"] = "Microliter";
  /** */
  VolumeUnits["Milliliters"] = "Milliliter";
  /** */
  VolumeUnits["Centiliters"] = "Centiliter";
  /** */
  VolumeUnits["Deciliters"] = "Deciliter";
  /** */
  VolumeUnits["Decaliters"] = "Decaliter";
  /** */
  VolumeUnits["Hectoliters"] = "Hectoliter";
  /** */
  VolumeUnits["Kiloliters"] = "Kiloliter";
  /** */
  VolumeUnits["Megaliters"] = "Megaliter";
  /** */
  VolumeUnits["HectocubicMeters"] = "HectocubicMeter";
  /** */
  VolumeUnits["KilocubicMeters"] = "KilocubicMeter";
  /** */
  VolumeUnits["HectocubicFeet"] = "HectocubicFoot";
  /** */
  VolumeUnits["KilocubicFeet"] = "KilocubicFoot";
  /** */
  VolumeUnits["MegacubicFeet"] = "MegacubicFoot";
  /** */
  VolumeUnits["KiloimperialGallons"] = "KiloimperialGallon";
  /** */
  VolumeUnits["MegaimperialGallons"] = "MegaimperialGallon";
  /** */
  VolumeUnits["DecausGallons"] = "DecausGallon";
  /** */
  VolumeUnits["DeciusGallons"] = "DeciusGallon";
  /** */
  VolumeUnits["HectousGallons"] = "HectousGallon";
  /** */
  VolumeUnits["KilousGallons"] = "KilousGallon";
  /** */
  VolumeUnits["MegausGallons"] = "MegausGallon";
})(VolumeUnits = exports.VolumeUnits || (exports.VolumeUnits = {}));
/** Volume is the quantity of three-dimensional space enclosed by some closed boundary, for example, the space that a substance (solid, liquid, gas, or plasma) or shape occupies or contains.[1] Volume is often quantified numerically using the SI derived unit, the cubic metre. The volume of a container is generally understood to be the capacity of the container, i. e. the amount of fluid (gas or liquid) that the container could hold, rather than the amount of space the container itself displaces. */
class Volume extends base_unit_1.BaseUnit {
  /**
   * Create a new Volume.
   * @param value The value.
   * @param fromUnit The ‘Volume’ unit to create from.
   * The default unit is CubicMeters
   */
  constructor(value, fromUnit = VolumeUnits.CubicMeters) {
    super();
    this.litersLazy = null;
    this.cubicmetersLazy = null;
    this.cubickilometersLazy = null;
    this.cubichectometersLazy = null;
    this.cubicdecimetersLazy = null;
    this.cubiccentimetersLazy = null;
    this.cubicmillimetersLazy = null;
    this.cubicmicrometersLazy = null;
    this.cubicmilesLazy = null;
    this.cubicyardsLazy = null;
    this.cubicfeetLazy = null;
    this.cubicinchesLazy = null;
    this.imperialgallonsLazy = null;
    this.imperialouncesLazy = null;
    this.usgallonsLazy = null;
    this.usouncesLazy = null;
    this.ustablespoonsLazy = null;
    this.autablespoonsLazy = null;
    this.uktablespoonsLazy = null;
    this.metricteaspoonsLazy = null;
    this.usteaspoonsLazy = null;
    this.metriccupsLazy = null;
    this.uscustomarycupsLazy = null;
    this.uslegalcupsLazy = null;
    this.oilbarrelsLazy = null;
    this.usbeerbarrelsLazy = null;
    this.imperialbeerbarrelsLazy = null;
    this.usquartsLazy = null;
    this.imperialquartsLazy = null;
    this.uspintsLazy = null;
    this.acrefeetLazy = null;
    this.imperialpintsLazy = null;
    this.boardfeetLazy = null;
    this.nanolitersLazy = null;
    this.microlitersLazy = null;
    this.millilitersLazy = null;
    this.centilitersLazy = null;
    this.decilitersLazy = null;
    this.decalitersLazy = null;
    this.hectolitersLazy = null;
    this.kilolitersLazy = null;
    this.megalitersLazy = null;
    this.hectocubicmetersLazy = null;
    this.kilocubicmetersLazy = null;
    this.hectocubicfeetLazy = null;
    this.kilocubicfeetLazy = null;
    this.megacubicfeetLazy = null;
    this.kiloimperialgallonsLazy = null;
    this.megaimperialgallonsLazy = null;
    this.decausgallonsLazy = null;
    this.deciusgallonsLazy = null;
    this.hectousgallonsLazy = null;
    this.kilousgallonsLazy = null;
    this.megausgallonsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Volume is CubicMeters.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return VolumeUnits.CubicMeters;
  }
  /** */
  get Liters() {
    if (this.litersLazy !== null) {
      return this.litersLazy;
    }
    return this.litersLazy = this.convertFromBase(VolumeUnits.Liters);
  }
  /** */
  get CubicMeters() {
    if (this.cubicmetersLazy !== null) {
      return this.cubicmetersLazy;
    }
    return this.cubicmetersLazy = this.convertFromBase(VolumeUnits.CubicMeters);
  }
  /** */
  get CubicKilometers() {
    if (this.cubickilometersLazy !== null) {
      return this.cubickilometersLazy;
    }
    return this.cubickilometersLazy = this.convertFromBase(VolumeUnits.CubicKilometers);
  }
  /** */
  get CubicHectometers() {
    if (this.cubichectometersLazy !== null) {
      return this.cubichectometersLazy;
    }
    return this.cubichectometersLazy = this.convertFromBase(VolumeUnits.CubicHectometers);
  }
  /** */
  get CubicDecimeters() {
    if (this.cubicdecimetersLazy !== null) {
      return this.cubicdecimetersLazy;
    }
    return this.cubicdecimetersLazy = this.convertFromBase(VolumeUnits.CubicDecimeters);
  }
  /** */
  get CubicCentimeters() {
    if (this.cubiccentimetersLazy !== null) {
      return this.cubiccentimetersLazy;
    }
    return this.cubiccentimetersLazy = this.convertFromBase(VolumeUnits.CubicCentimeters);
  }
  /** */
  get CubicMillimeters() {
    if (this.cubicmillimetersLazy !== null) {
      return this.cubicmillimetersLazy;
    }
    return this.cubicmillimetersLazy = this.convertFromBase(VolumeUnits.CubicMillimeters);
  }
  /** */
  get CubicMicrometers() {
    if (this.cubicmicrometersLazy !== null) {
      return this.cubicmicrometersLazy;
    }
    return this.cubicmicrometersLazy = this.convertFromBase(VolumeUnits.CubicMicrometers);
  }
  /** */
  get CubicMiles() {
    if (this.cubicmilesLazy !== null) {
      return this.cubicmilesLazy;
    }
    return this.cubicmilesLazy = this.convertFromBase(VolumeUnits.CubicMiles);
  }
  /** */
  get CubicYards() {
    if (this.cubicyardsLazy !== null) {
      return this.cubicyardsLazy;
    }
    return this.cubicyardsLazy = this.convertFromBase(VolumeUnits.CubicYards);
  }
  /** */
  get CubicFeet() {
    if (this.cubicfeetLazy !== null) {
      return this.cubicfeetLazy;
    }
    return this.cubicfeetLazy = this.convertFromBase(VolumeUnits.CubicFeet);
  }
  /** */
  get CubicInches() {
    if (this.cubicinchesLazy !== null) {
      return this.cubicinchesLazy;
    }
    return this.cubicinchesLazy = this.convertFromBase(VolumeUnits.CubicInches);
  }
  /** The British imperial gallon (frequently called simply "gallon") is defined as exactly 4.54609 litres. */
  get ImperialGallons() {
    if (this.imperialgallonsLazy !== null) {
      return this.imperialgallonsLazy;
    }
    return this.imperialgallonsLazy = this.convertFromBase(VolumeUnits.ImperialGallons);
  }
  /** */
  get ImperialOunces() {
    if (this.imperialouncesLazy !== null) {
      return this.imperialouncesLazy;
    }
    return this.imperialouncesLazy = this.convertFromBase(VolumeUnits.ImperialOunces);
  }
  /** The US liquid gallon (frequently called simply "gallon") is legally defined as 231 cubic inches, which is exactly 3.785411784 litres. */
  get UsGallons() {
    if (this.usgallonsLazy !== null) {
      return this.usgallonsLazy;
    }
    return this.usgallonsLazy = this.convertFromBase(VolumeUnits.UsGallons);
  }
  /** */
  get UsOunces() {
    if (this.usouncesLazy !== null) {
      return this.usouncesLazy;
    }
    return this.usouncesLazy = this.convertFromBase(VolumeUnits.UsOunces);
  }
  /** */
  get UsTablespoons() {
    if (this.ustablespoonsLazy !== null) {
      return this.ustablespoonsLazy;
    }
    return this.ustablespoonsLazy = this.convertFromBase(VolumeUnits.UsTablespoons);
  }
  /** */
  get AuTablespoons() {
    if (this.autablespoonsLazy !== null) {
      return this.autablespoonsLazy;
    }
    return this.autablespoonsLazy = this.convertFromBase(VolumeUnits.AuTablespoons);
  }
  /** */
  get UkTablespoons() {
    if (this.uktablespoonsLazy !== null) {
      return this.uktablespoonsLazy;
    }
    return this.uktablespoonsLazy = this.convertFromBase(VolumeUnits.UkTablespoons);
  }
  /** */
  get MetricTeaspoons() {
    if (this.metricteaspoonsLazy !== null) {
      return this.metricteaspoonsLazy;
    }
    return this.metricteaspoonsLazy = this.convertFromBase(VolumeUnits.MetricTeaspoons);
  }
  /** */
  get UsTeaspoons() {
    if (this.usteaspoonsLazy !== null) {
      return this.usteaspoonsLazy;
    }
    return this.usteaspoonsLazy = this.convertFromBase(VolumeUnits.UsTeaspoons);
  }
  /** */
  get MetricCups() {
    if (this.metriccupsLazy !== null) {
      return this.metriccupsLazy;
    }
    return this.metriccupsLazy = this.convertFromBase(VolumeUnits.MetricCups);
  }
  /** */
  get UsCustomaryCups() {
    if (this.uscustomarycupsLazy !== null) {
      return this.uscustomarycupsLazy;
    }
    return this.uscustomarycupsLazy = this.convertFromBase(VolumeUnits.UsCustomaryCups);
  }
  /** */
  get UsLegalCups() {
    if (this.uslegalcupsLazy !== null) {
      return this.uslegalcupsLazy;
    }
    return this.uslegalcupsLazy = this.convertFromBase(VolumeUnits.UsLegalCups);
  }
  /** */
  get OilBarrels() {
    if (this.oilbarrelsLazy !== null) {
      return this.oilbarrelsLazy;
    }
    return this.oilbarrelsLazy = this.convertFromBase(VolumeUnits.OilBarrels);
  }
  /** */
  get UsBeerBarrels() {
    if (this.usbeerbarrelsLazy !== null) {
      return this.usbeerbarrelsLazy;
    }
    return this.usbeerbarrelsLazy = this.convertFromBase(VolumeUnits.UsBeerBarrels);
  }
  /** */
  get ImperialBeerBarrels() {
    if (this.imperialbeerbarrelsLazy !== null) {
      return this.imperialbeerbarrelsLazy;
    }
    return this.imperialbeerbarrelsLazy = this.convertFromBase(VolumeUnits.ImperialBeerBarrels);
  }
  /** */
  get UsQuarts() {
    if (this.usquartsLazy !== null) {
      return this.usquartsLazy;
    }
    return this.usquartsLazy = this.convertFromBase(VolumeUnits.UsQuarts);
  }
  /** */
  get ImperialQuarts() {
    if (this.imperialquartsLazy !== null) {
      return this.imperialquartsLazy;
    }
    return this.imperialquartsLazy = this.convertFromBase(VolumeUnits.ImperialQuarts);
  }
  /** */
  get UsPints() {
    if (this.uspintsLazy !== null) {
      return this.uspintsLazy;
    }
    return this.uspintsLazy = this.convertFromBase(VolumeUnits.UsPints);
  }
  /** */
  get AcreFeet() {
    if (this.acrefeetLazy !== null) {
      return this.acrefeetLazy;
    }
    return this.acrefeetLazy = this.convertFromBase(VolumeUnits.AcreFeet);
  }
  /** */
  get ImperialPints() {
    if (this.imperialpintsLazy !== null) {
      return this.imperialpintsLazy;
    }
    return this.imperialpintsLazy = this.convertFromBase(VolumeUnits.ImperialPints);
  }
  /** */
  get BoardFeet() {
    if (this.boardfeetLazy !== null) {
      return this.boardfeetLazy;
    }
    return this.boardfeetLazy = this.convertFromBase(VolumeUnits.BoardFeet);
  }
  /** */
  get Nanoliters() {
    if (this.nanolitersLazy !== null) {
      return this.nanolitersLazy;
    }
    return this.nanolitersLazy = this.convertFromBase(VolumeUnits.Nanoliters);
  }
  /** */
  get Microliters() {
    if (this.microlitersLazy !== null) {
      return this.microlitersLazy;
    }
    return this.microlitersLazy = this.convertFromBase(VolumeUnits.Microliters);
  }
  /** */
  get Milliliters() {
    if (this.millilitersLazy !== null) {
      return this.millilitersLazy;
    }
    return this.millilitersLazy = this.convertFromBase(VolumeUnits.Milliliters);
  }
  /** */
  get Centiliters() {
    if (this.centilitersLazy !== null) {
      return this.centilitersLazy;
    }
    return this.centilitersLazy = this.convertFromBase(VolumeUnits.Centiliters);
  }
  /** */
  get Deciliters() {
    if (this.decilitersLazy !== null) {
      return this.decilitersLazy;
    }
    return this.decilitersLazy = this.convertFromBase(VolumeUnits.Deciliters);
  }
  /** */
  get Decaliters() {
    if (this.decalitersLazy !== null) {
      return this.decalitersLazy;
    }
    return this.decalitersLazy = this.convertFromBase(VolumeUnits.Decaliters);
  }
  /** */
  get Hectoliters() {
    if (this.hectolitersLazy !== null) {
      return this.hectolitersLazy;
    }
    return this.hectolitersLazy = this.convertFromBase(VolumeUnits.Hectoliters);
  }
  /** */
  get Kiloliters() {
    if (this.kilolitersLazy !== null) {
      return this.kilolitersLazy;
    }
    return this.kilolitersLazy = this.convertFromBase(VolumeUnits.Kiloliters);
  }
  /** */
  get Megaliters() {
    if (this.megalitersLazy !== null) {
      return this.megalitersLazy;
    }
    return this.megalitersLazy = this.convertFromBase(VolumeUnits.Megaliters);
  }
  /** */
  get HectocubicMeters() {
    if (this.hectocubicmetersLazy !== null) {
      return this.hectocubicmetersLazy;
    }
    return this.hectocubicmetersLazy = this.convertFromBase(VolumeUnits.HectocubicMeters);
  }
  /** */
  get KilocubicMeters() {
    if (this.kilocubicmetersLazy !== null) {
      return this.kilocubicmetersLazy;
    }
    return this.kilocubicmetersLazy = this.convertFromBase(VolumeUnits.KilocubicMeters);
  }
  /** */
  get HectocubicFeet() {
    if (this.hectocubicfeetLazy !== null) {
      return this.hectocubicfeetLazy;
    }
    return this.hectocubicfeetLazy = this.convertFromBase(VolumeUnits.HectocubicFeet);
  }
  /** */
  get KilocubicFeet() {
    if (this.kilocubicfeetLazy !== null) {
      return this.kilocubicfeetLazy;
    }
    return this.kilocubicfeetLazy = this.convertFromBase(VolumeUnits.KilocubicFeet);
  }
  /** */
  get MegacubicFeet() {
    if (this.megacubicfeetLazy !== null) {
      return this.megacubicfeetLazy;
    }
    return this.megacubicfeetLazy = this.convertFromBase(VolumeUnits.MegacubicFeet);
  }
  /** */
  get KiloimperialGallons() {
    if (this.kiloimperialgallonsLazy !== null) {
      return this.kiloimperialgallonsLazy;
    }
    return this.kiloimperialgallonsLazy = this.convertFromBase(VolumeUnits.KiloimperialGallons);
  }
  /** */
  get MegaimperialGallons() {
    if (this.megaimperialgallonsLazy !== null) {
      return this.megaimperialgallonsLazy;
    }
    return this.megaimperialgallonsLazy = this.convertFromBase(VolumeUnits.MegaimperialGallons);
  }
  /** */
  get DecausGallons() {
    if (this.decausgallonsLazy !== null) {
      return this.decausgallonsLazy;
    }
    return this.decausgallonsLazy = this.convertFromBase(VolumeUnits.DecausGallons);
  }
  /** */
  get DeciusGallons() {
    if (this.deciusgallonsLazy !== null) {
      return this.deciusgallonsLazy;
    }
    return this.deciusgallonsLazy = this.convertFromBase(VolumeUnits.DeciusGallons);
  }
  /** */
  get HectousGallons() {
    if (this.hectousgallonsLazy !== null) {
      return this.hectousgallonsLazy;
    }
    return this.hectousgallonsLazy = this.convertFromBase(VolumeUnits.HectousGallons);
  }
  /** */
  get KilousGallons() {
    if (this.kilousgallonsLazy !== null) {
      return this.kilousgallonsLazy;
    }
    return this.kilousgallonsLazy = this.convertFromBase(VolumeUnits.KilousGallons);
  }
  /** */
  get MegausGallons() {
    if (this.megausgallonsLazy !== null) {
      return this.megausgallonsLazy;
    }
    return this.megausgallonsLazy = this.convertFromBase(VolumeUnits.MegausGallons);
  }
  /**
   * Create a new Volume instance from a Liters
   *
   * @param value The unit as Liters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromLiters(value) {
    return new Volume(value, VolumeUnits.Liters);
  }
  /**
   * Create a new Volume instance from a CubicMeters
   *
   * @param value The unit as CubicMeters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicMeters(value) {
    return new Volume(value, VolumeUnits.CubicMeters);
  }
  /**
   * Create a new Volume instance from a CubicKilometers
   *
   * @param value The unit as CubicKilometers to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicKilometers(value) {
    return new Volume(value, VolumeUnits.CubicKilometers);
  }
  /**
   * Create a new Volume instance from a CubicHectometers
   *
   * @param value The unit as CubicHectometers to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicHectometers(value) {
    return new Volume(value, VolumeUnits.CubicHectometers);
  }
  /**
   * Create a new Volume instance from a CubicDecimeters
   *
   * @param value The unit as CubicDecimeters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicDecimeters(value) {
    return new Volume(value, VolumeUnits.CubicDecimeters);
  }
  /**
   * Create a new Volume instance from a CubicCentimeters
   *
   * @param value The unit as CubicCentimeters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicCentimeters(value) {
    return new Volume(value, VolumeUnits.CubicCentimeters);
  }
  /**
   * Create a new Volume instance from a CubicMillimeters
   *
   * @param value The unit as CubicMillimeters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicMillimeters(value) {
    return new Volume(value, VolumeUnits.CubicMillimeters);
  }
  /**
   * Create a new Volume instance from a CubicMicrometers
   *
   * @param value The unit as CubicMicrometers to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicMicrometers(value) {
    return new Volume(value, VolumeUnits.CubicMicrometers);
  }
  /**
   * Create a new Volume instance from a CubicMiles
   *
   * @param value The unit as CubicMiles to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicMiles(value) {
    return new Volume(value, VolumeUnits.CubicMiles);
  }
  /**
   * Create a new Volume instance from a CubicYards
   *
   * @param value The unit as CubicYards to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicYards(value) {
    return new Volume(value, VolumeUnits.CubicYards);
  }
  /**
   * Create a new Volume instance from a CubicFeet
   *
   * @param value The unit as CubicFeet to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicFeet(value) {
    return new Volume(value, VolumeUnits.CubicFeet);
  }
  /**
   * Create a new Volume instance from a CubicInches
   *
   * @param value The unit as CubicInches to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCubicInches(value) {
    return new Volume(value, VolumeUnits.CubicInches);
  }
  /**
   * Create a new Volume instance from a ImperialGallons
   * The British imperial gallon (frequently called simply "gallon") is defined as exactly 4.54609 litres.
   * @param value The unit as ImperialGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromImperialGallons(value) {
    return new Volume(value, VolumeUnits.ImperialGallons);
  }
  /**
   * Create a new Volume instance from a ImperialOunces
   *
   * @param value The unit as ImperialOunces to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromImperialOunces(value) {
    return new Volume(value, VolumeUnits.ImperialOunces);
  }
  /**
   * Create a new Volume instance from a UsGallons
   * The US liquid gallon (frequently called simply "gallon") is legally defined as 231 cubic inches, which is exactly 3.785411784 litres.
   * @param value The unit as UsGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsGallons(value) {
    return new Volume(value, VolumeUnits.UsGallons);
  }
  /**
   * Create a new Volume instance from a UsOunces
   *
   * @param value The unit as UsOunces to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsOunces(value) {
    return new Volume(value, VolumeUnits.UsOunces);
  }
  /**
   * Create a new Volume instance from a UsTablespoons
   *
   * @param value The unit as UsTablespoons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsTablespoons(value) {
    return new Volume(value, VolumeUnits.UsTablespoons);
  }
  /**
   * Create a new Volume instance from a AuTablespoons
   *
   * @param value The unit as AuTablespoons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromAuTablespoons(value) {
    return new Volume(value, VolumeUnits.AuTablespoons);
  }
  /**
   * Create a new Volume instance from a UkTablespoons
   *
   * @param value The unit as UkTablespoons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUkTablespoons(value) {
    return new Volume(value, VolumeUnits.UkTablespoons);
  }
  /**
   * Create a new Volume instance from a MetricTeaspoons
   *
   * @param value The unit as MetricTeaspoons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromMetricTeaspoons(value) {
    return new Volume(value, VolumeUnits.MetricTeaspoons);
  }
  /**
   * Create a new Volume instance from a UsTeaspoons
   *
   * @param value The unit as UsTeaspoons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsTeaspoons(value) {
    return new Volume(value, VolumeUnits.UsTeaspoons);
  }
  /**
   * Create a new Volume instance from a MetricCups
   *
   * @param value The unit as MetricCups to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromMetricCups(value) {
    return new Volume(value, VolumeUnits.MetricCups);
  }
  /**
   * Create a new Volume instance from a UsCustomaryCups
   *
   * @param value The unit as UsCustomaryCups to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsCustomaryCups(value) {
    return new Volume(value, VolumeUnits.UsCustomaryCups);
  }
  /**
   * Create a new Volume instance from a UsLegalCups
   *
   * @param value The unit as UsLegalCups to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsLegalCups(value) {
    return new Volume(value, VolumeUnits.UsLegalCups);
  }
  /**
   * Create a new Volume instance from a OilBarrels
   *
   * @param value The unit as OilBarrels to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromOilBarrels(value) {
    return new Volume(value, VolumeUnits.OilBarrels);
  }
  /**
   * Create a new Volume instance from a UsBeerBarrels
   *
   * @param value The unit as UsBeerBarrels to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsBeerBarrels(value) {
    return new Volume(value, VolumeUnits.UsBeerBarrels);
  }
  /**
   * Create a new Volume instance from a ImperialBeerBarrels
   *
   * @param value The unit as ImperialBeerBarrels to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromImperialBeerBarrels(value) {
    return new Volume(value, VolumeUnits.ImperialBeerBarrels);
  }
  /**
   * Create a new Volume instance from a UsQuarts
   *
   * @param value The unit as UsQuarts to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsQuarts(value) {
    return new Volume(value, VolumeUnits.UsQuarts);
  }
  /**
   * Create a new Volume instance from a ImperialQuarts
   *
   * @param value The unit as ImperialQuarts to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromImperialQuarts(value) {
    return new Volume(value, VolumeUnits.ImperialQuarts);
  }
  /**
   * Create a new Volume instance from a UsPints
   *
   * @param value The unit as UsPints to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromUsPints(value) {
    return new Volume(value, VolumeUnits.UsPints);
  }
  /**
   * Create a new Volume instance from a AcreFeet
   *
   * @param value The unit as AcreFeet to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromAcreFeet(value) {
    return new Volume(value, VolumeUnits.AcreFeet);
  }
  /**
   * Create a new Volume instance from a ImperialPints
   *
   * @param value The unit as ImperialPints to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromImperialPints(value) {
    return new Volume(value, VolumeUnits.ImperialPints);
  }
  /**
   * Create a new Volume instance from a BoardFeet
   *
   * @param value The unit as BoardFeet to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromBoardFeet(value) {
    return new Volume(value, VolumeUnits.BoardFeet);
  }
  /**
   * Create a new Volume instance from a Nanoliters
   *
   * @param value The unit as Nanoliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromNanoliters(value) {
    return new Volume(value, VolumeUnits.Nanoliters);
  }
  /**
   * Create a new Volume instance from a Microliters
   *
   * @param value The unit as Microliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromMicroliters(value) {
    return new Volume(value, VolumeUnits.Microliters);
  }
  /**
   * Create a new Volume instance from a Milliliters
   *
   * @param value The unit as Milliliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromMilliliters(value) {
    return new Volume(value, VolumeUnits.Milliliters);
  }
  /**
   * Create a new Volume instance from a Centiliters
   *
   * @param value The unit as Centiliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromCentiliters(value) {
    return new Volume(value, VolumeUnits.Centiliters);
  }
  /**
   * Create a new Volume instance from a Deciliters
   *
   * @param value The unit as Deciliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromDeciliters(value) {
    return new Volume(value, VolumeUnits.Deciliters);
  }
  /**
   * Create a new Volume instance from a Decaliters
   *
   * @param value The unit as Decaliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromDecaliters(value) {
    return new Volume(value, VolumeUnits.Decaliters);
  }
  /**
   * Create a new Volume instance from a Hectoliters
   *
   * @param value The unit as Hectoliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromHectoliters(value) {
    return new Volume(value, VolumeUnits.Hectoliters);
  }
  /**
   * Create a new Volume instance from a Kiloliters
   *
   * @param value The unit as Kiloliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromKiloliters(value) {
    return new Volume(value, VolumeUnits.Kiloliters);
  }
  /**
   * Create a new Volume instance from a Megaliters
   *
   * @param value The unit as Megaliters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromMegaliters(value) {
    return new Volume(value, VolumeUnits.Megaliters);
  }
  /**
   * Create a new Volume instance from a HectocubicMeters
   *
   * @param value The unit as HectocubicMeters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromHectocubicMeters(value) {
    return new Volume(value, VolumeUnits.HectocubicMeters);
  }
  /**
   * Create a new Volume instance from a KilocubicMeters
   *
   * @param value The unit as KilocubicMeters to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromKilocubicMeters(value) {
    return new Volume(value, VolumeUnits.KilocubicMeters);
  }
  /**
   * Create a new Volume instance from a HectocubicFeet
   *
   * @param value The unit as HectocubicFeet to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromHectocubicFeet(value) {
    return new Volume(value, VolumeUnits.HectocubicFeet);
  }
  /**
   * Create a new Volume instance from a KilocubicFeet
   *
   * @param value The unit as KilocubicFeet to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromKilocubicFeet(value) {
    return new Volume(value, VolumeUnits.KilocubicFeet);
  }
  /**
   * Create a new Volume instance from a MegacubicFeet
   *
   * @param value The unit as MegacubicFeet to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromMegacubicFeet(value) {
    return new Volume(value, VolumeUnits.MegacubicFeet);
  }
  /**
   * Create a new Volume instance from a KiloimperialGallons
   *
   * @param value The unit as KiloimperialGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromKiloimperialGallons(value) {
    return new Volume(value, VolumeUnits.KiloimperialGallons);
  }
  /**
   * Create a new Volume instance from a MegaimperialGallons
   *
   * @param value The unit as MegaimperialGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromMegaimperialGallons(value) {
    return new Volume(value, VolumeUnits.MegaimperialGallons);
  }
  /**
   * Create a new Volume instance from a DecausGallons
   *
   * @param value The unit as DecausGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromDecausGallons(value) {
    return new Volume(value, VolumeUnits.DecausGallons);
  }
  /**
   * Create a new Volume instance from a DeciusGallons
   *
   * @param value The unit as DeciusGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromDeciusGallons(value) {
    return new Volume(value, VolumeUnits.DeciusGallons);
  }
  /**
   * Create a new Volume instance from a HectousGallons
   *
   * @param value The unit as HectousGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromHectousGallons(value) {
    return new Volume(value, VolumeUnits.HectousGallons);
  }
  /**
   * Create a new Volume instance from a KilousGallons
   *
   * @param value The unit as KilousGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromKilousGallons(value) {
    return new Volume(value, VolumeUnits.KilousGallons);
  }
  /**
   * Create a new Volume instance from a MegausGallons
   *
   * @param value The unit as MegausGallons to create a new Volume from.
   * @returns The new Volume instance.
   */
  static FromMegausGallons(value) {
    return new Volume(value, VolumeUnits.MegausGallons);
  }
  /**
   * Gets the base unit enumeration associated with Volume
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return VolumeUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return VolumeUnits.CubicMeters;
  }
  /**
   * Create API DTO represent a Volume unit.
   * @param holdInUnit The specific Volume unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = VolumeUnits.CubicMeters) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Volume unit from an API DTO representation.
   * @param dtoVolume The Volume API DTO representation
   */
  static FromDto(dtoVolume) {
    return new Volume(dtoVolume.value, dtoVolume.unit);
  }
  /**
   * Convert Volume to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case VolumeUnits.Liters:
        return this.Liters;
      case VolumeUnits.CubicMeters:
        return this.CubicMeters;
      case VolumeUnits.CubicKilometers:
        return this.CubicKilometers;
      case VolumeUnits.CubicHectometers:
        return this.CubicHectometers;
      case VolumeUnits.CubicDecimeters:
        return this.CubicDecimeters;
      case VolumeUnits.CubicCentimeters:
        return this.CubicCentimeters;
      case VolumeUnits.CubicMillimeters:
        return this.CubicMillimeters;
      case VolumeUnits.CubicMicrometers:
        return this.CubicMicrometers;
      case VolumeUnits.CubicMiles:
        return this.CubicMiles;
      case VolumeUnits.CubicYards:
        return this.CubicYards;
      case VolumeUnits.CubicFeet:
        return this.CubicFeet;
      case VolumeUnits.CubicInches:
        return this.CubicInches;
      case VolumeUnits.ImperialGallons:
        return this.ImperialGallons;
      case VolumeUnits.ImperialOunces:
        return this.ImperialOunces;
      case VolumeUnits.UsGallons:
        return this.UsGallons;
      case VolumeUnits.UsOunces:
        return this.UsOunces;
      case VolumeUnits.UsTablespoons:
        return this.UsTablespoons;
      case VolumeUnits.AuTablespoons:
        return this.AuTablespoons;
      case VolumeUnits.UkTablespoons:
        return this.UkTablespoons;
      case VolumeUnits.MetricTeaspoons:
        return this.MetricTeaspoons;
      case VolumeUnits.UsTeaspoons:
        return this.UsTeaspoons;
      case VolumeUnits.MetricCups:
        return this.MetricCups;
      case VolumeUnits.UsCustomaryCups:
        return this.UsCustomaryCups;
      case VolumeUnits.UsLegalCups:
        return this.UsLegalCups;
      case VolumeUnits.OilBarrels:
        return this.OilBarrels;
      case VolumeUnits.UsBeerBarrels:
        return this.UsBeerBarrels;
      case VolumeUnits.ImperialBeerBarrels:
        return this.ImperialBeerBarrels;
      case VolumeUnits.UsQuarts:
        return this.UsQuarts;
      case VolumeUnits.ImperialQuarts:
        return this.ImperialQuarts;
      case VolumeUnits.UsPints:
        return this.UsPints;
      case VolumeUnits.AcreFeet:
        return this.AcreFeet;
      case VolumeUnits.ImperialPints:
        return this.ImperialPints;
      case VolumeUnits.BoardFeet:
        return this.BoardFeet;
      case VolumeUnits.Nanoliters:
        return this.Nanoliters;
      case VolumeUnits.Microliters:
        return this.Microliters;
      case VolumeUnits.Milliliters:
        return this.Milliliters;
      case VolumeUnits.Centiliters:
        return this.Centiliters;
      case VolumeUnits.Deciliters:
        return this.Deciliters;
      case VolumeUnits.Decaliters:
        return this.Decaliters;
      case VolumeUnits.Hectoliters:
        return this.Hectoliters;
      case VolumeUnits.Kiloliters:
        return this.Kiloliters;
      case VolumeUnits.Megaliters:
        return this.Megaliters;
      case VolumeUnits.HectocubicMeters:
        return this.HectocubicMeters;
      case VolumeUnits.KilocubicMeters:
        return this.KilocubicMeters;
      case VolumeUnits.HectocubicFeet:
        return this.HectocubicFeet;
      case VolumeUnits.KilocubicFeet:
        return this.KilocubicFeet;
      case VolumeUnits.MegacubicFeet:
        return this.MegacubicFeet;
      case VolumeUnits.KiloimperialGallons:
        return this.KiloimperialGallons;
      case VolumeUnits.MegaimperialGallons:
        return this.MegaimperialGallons;
      case VolumeUnits.DecausGallons:
        return this.DecausGallons;
      case VolumeUnits.DeciusGallons:
        return this.DeciusGallons;
      case VolumeUnits.HectousGallons:
        return this.HectousGallons;
      case VolumeUnits.KilousGallons:
        return this.KilousGallons;
      case VolumeUnits.MegausGallons:
        return this.MegausGallons;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case VolumeUnits.Liters:
        return super.internalMultiply(this.value, 1e3);
      case VolumeUnits.CubicMeters:
        return this.value;
      case VolumeUnits.CubicKilometers:
        return super.internalDivide(this.value, 1e9);
      case VolumeUnits.CubicHectometers:
        return super.internalDivide(this.value, 1e6);
      case VolumeUnits.CubicDecimeters:
        return super.internalMultiply(this.value, 1e3);
      case VolumeUnits.CubicCentimeters:
        return super.internalMultiply(this.value, 1e6);
      case VolumeUnits.CubicMillimeters:
        return super.internalMultiply(this.value, 1e9);
      case VolumeUnits.CubicMicrometers:
        return super.internalMultiply(this.value, 1e18);
      case VolumeUnits.CubicMiles:
        return super.internalDivide(this.value, 4.16818182544058e9);
      case VolumeUnits.CubicYards:
        return super.internalDivide(this.value, 0.764554858);
      case VolumeUnits.CubicFeet:
        return super.internalDivide(this.value, 2.8316846592e-2);
      case VolumeUnits.CubicInches:
        return super.internalDivide(this.value, 1.6387064e-5);
      case VolumeUnits.ImperialGallons:
        return super.internalDivide(this.value, 0.00454609);
      case VolumeUnits.ImperialOunces:
        return super.internalDivide(this.value, 2.8413062499962901241875439064617e-5);
      case VolumeUnits.UsGallons:
        return super.internalDivide(this.value, 0.003785411784);
      case VolumeUnits.UsOunces:
        return super.internalDivide(this.value, 2.957352956253760505068307980135e-5);
      case VolumeUnits.UsTablespoons:
        return super.internalDivide(this.value, 1.478676478125e-5);
      case VolumeUnits.AuTablespoons:
        return super.internalDivide(this.value, 2e-5);
      case VolumeUnits.UkTablespoons:
        return super.internalDivide(this.value, 1.5e-5);
      case VolumeUnits.MetricTeaspoons:
        return super.internalDivide(this.value, 0.5e-5);
      case VolumeUnits.UsTeaspoons:
        return super.internalDivide(this.value, 4.92892159375e-6);
      case VolumeUnits.MetricCups:
        return super.internalDivide(this.value, 0.00025);
      case VolumeUnits.UsCustomaryCups:
        return super.internalDivide(this.value, 0.0002365882365);
      case VolumeUnits.UsLegalCups:
        return super.internalDivide(this.value, 0.00024);
      case VolumeUnits.OilBarrels:
        return super.internalDivide(this.value, 0.158987294928);
      case VolumeUnits.UsBeerBarrels:
        return super.internalDivide(this.value, 0.1173477658);
      case VolumeUnits.ImperialBeerBarrels:
        return super.internalDivide(this.value, 0.16365924);
      case VolumeUnits.UsQuarts:
        return super.internalDivide(this.value, 9.46352946e-4);
      case VolumeUnits.ImperialQuarts:
        return super.internalDivide(this.value, 1.1365225e-3);
      case VolumeUnits.UsPints:
        return super.internalDivide(this.value, 4.73176473e-4);
      case VolumeUnits.AcreFeet:
        return super.internalMultiply(this.value, 0.000810714);
      case VolumeUnits.ImperialPints:
        return super.internalDivide(this.value, 5.6826125e-4);
      case VolumeUnits.BoardFeet:
        return super.internalDivide(this.value, 2.3597372158e-3);
      case VolumeUnits.Nanoliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1e-9);
        }
      case VolumeUnits.Microliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.000001);
        }
      case VolumeUnits.Milliliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.001);
        }
      case VolumeUnits.Centiliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.01);
        }
      case VolumeUnits.Deciliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.1);
        }
      case VolumeUnits.Decaliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 10);
        }
      case VolumeUnits.Hectoliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 100);
        }
      case VolumeUnits.Kiloliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1000);
        }
      case VolumeUnits.Megaliters:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeUnits.HectocubicMeters:
        return super.internalDivide(this.value, 100);
      case VolumeUnits.KilocubicMeters:
        return super.internalDivide(this.value, 1000);
      case VolumeUnits.HectocubicFeet:
        {
          const v3 = super.internalDivide(this.value, 2.8316846592e-2);
          return super.internalDivide(v3, 100);
        }
      case VolumeUnits.KilocubicFeet:
        {
          const v3 = super.internalDivide(this.value, 2.8316846592e-2);
          return super.internalDivide(v3, 1000);
        }
      case VolumeUnits.MegacubicFeet:
        {
          const v3 = super.internalDivide(this.value, 2.8316846592e-2);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeUnits.KiloimperialGallons:
        {
          const v3 = super.internalDivide(this.value, 0.00454609);
          return super.internalDivide(v3, 1000);
        }
      case VolumeUnits.MegaimperialGallons:
        {
          const v3 = super.internalDivide(this.value, 0.00454609);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeUnits.DecausGallons:
        {
          const v3 = super.internalDivide(this.value, 0.003785411784);
          return super.internalDivide(v3, 10);
        }
      case VolumeUnits.DeciusGallons:
        {
          const v3 = super.internalDivide(this.value, 0.003785411784);
          return super.internalDivide(v3, 0.1);
        }
      case VolumeUnits.HectousGallons:
        {
          const v3 = super.internalDivide(this.value, 0.003785411784);
          return super.internalDivide(v3, 100);
        }
      case VolumeUnits.KilousGallons:
        {
          const v3 = super.internalDivide(this.value, 0.003785411784);
          return super.internalDivide(v3, 1000);
        }
      case VolumeUnits.MegausGallons:
        {
          const v3 = super.internalDivide(this.value, 0.003785411784);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case VolumeUnits.Liters:
        return this.value * 1e3;
      case VolumeUnits.CubicMeters:
        return this.value;
      case VolumeUnits.CubicKilometers:
        return this.value / 1e9;
      case VolumeUnits.CubicHectometers:
        return this.value / 1e6;
      case VolumeUnits.CubicDecimeters:
        return this.value * 1e3;
      case VolumeUnits.CubicCentimeters:
        return this.value * 1e6;
      case VolumeUnits.CubicMillimeters:
        return this.value * 1e9;
      case VolumeUnits.CubicMicrometers:
        return this.value * 1e18;
      case VolumeUnits.CubicMiles:
        return this.value / 4.16818182544058e9;
      case VolumeUnits.CubicYards:
        return this.value / 0.764554858;
      case VolumeUnits.CubicFeet:
        return this.value / 2.8316846592e-2;
      case VolumeUnits.CubicInches:
        return this.value / 1.6387064e-5;
      case VolumeUnits.ImperialGallons:
        return this.value / 0.00454609;
      case VolumeUnits.ImperialOunces:
        return this.value / 2.8413062499962901241875439064617e-5;
      case VolumeUnits.UsGallons:
        return this.value / 0.003785411784;
      case VolumeUnits.UsOunces:
        return this.value / 2.957352956253760505068307980135e-5;
      case VolumeUnits.UsTablespoons:
        return this.value / 1.478676478125e-5;
      case VolumeUnits.AuTablespoons:
        return this.value / 2e-5;
      case VolumeUnits.UkTablespoons:
        return this.value / 1.5e-5;
      case VolumeUnits.MetricTeaspoons:
        return this.value / 0.5e-5;
      case VolumeUnits.UsTeaspoons:
        return this.value / 4.92892159375e-6;
      case VolumeUnits.MetricCups:
        return this.value / 0.00025;
      case VolumeUnits.UsCustomaryCups:
        return this.value / 0.0002365882365;
      case VolumeUnits.UsLegalCups:
        return this.value / 0.00024;
      case VolumeUnits.OilBarrels:
        return this.value / 0.158987294928;
      case VolumeUnits.UsBeerBarrels:
        return this.value / 0.1173477658;
      case VolumeUnits.ImperialBeerBarrels:
        return this.value / 0.16365924;
      case VolumeUnits.UsQuarts:
        return this.value / 9.46352946e-4;
      case VolumeUnits.ImperialQuarts:
        return this.value / 1.1365225e-3;
      case VolumeUnits.UsPints:
        return this.value / 4.73176473e-4;
      case VolumeUnits.AcreFeet:
        return this.value * 0.000810714;
      case VolumeUnits.ImperialPints:
        return this.value / 5.6826125e-4;
      case VolumeUnits.BoardFeet:
        return this.value / 2.3597372158e-3;
      case VolumeUnits.Nanoliters:
        return this.value * 1e3 / 1e-9;
      case VolumeUnits.Microliters:
        return this.value * 1e3 / 0.000001;
      case VolumeUnits.Milliliters:
        return this.value * 1e3 / 0.001;
      case VolumeUnits.Centiliters:
        return this.value * 1e3 / 0.01;
      case VolumeUnits.Deciliters:
        return this.value * 1e3 / 0.1;
      case VolumeUnits.Decaliters:
        return this.value * 1e3 / 10;
      case VolumeUnits.Hectoliters:
        return this.value * 1e3 / 100;
      case VolumeUnits.Kiloliters:
        return this.value * 1e3 / 1000;
      case VolumeUnits.Megaliters:
        return this.value * 1e3 / 1000000;
      case VolumeUnits.HectocubicMeters:
        return this.value / 100;
      case VolumeUnits.KilocubicMeters:
        return this.value / 1000;
      case VolumeUnits.HectocubicFeet:
        return this.value / 2.8316846592e-2 / 100;
      case VolumeUnits.KilocubicFeet:
        return this.value / 2.8316846592e-2 / 1000;
      case VolumeUnits.MegacubicFeet:
        return this.value / 2.8316846592e-2 / 1000000;
      case VolumeUnits.KiloimperialGallons:
        return this.value / 0.00454609 / 1000;
      case VolumeUnits.MegaimperialGallons:
        return this.value / 0.00454609 / 1000000;
      case VolumeUnits.DecausGallons:
        return this.value / 0.003785411784 / 10;
      case VolumeUnits.DeciusGallons:
        return this.value / 0.003785411784 / 0.1;
      case VolumeUnits.HectousGallons:
        return this.value / 0.003785411784 / 100;
      case VolumeUnits.KilousGallons:
        return this.value / 0.003785411784 / 1000;
      case VolumeUnits.MegausGallons:
        return this.value / 0.003785411784 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case VolumeUnits.Liters:
        return super.internalDivide(value, 1e3);
      case VolumeUnits.CubicMeters:
        return value;
      case VolumeUnits.CubicKilometers:
        return super.internalMultiply(value, 1e9);
      case VolumeUnits.CubicHectometers:
        return super.internalMultiply(value, 1e6);
      case VolumeUnits.CubicDecimeters:
        return super.internalDivide(value, 1e3);
      case VolumeUnits.CubicCentimeters:
        return super.internalDivide(value, 1e6);
      case VolumeUnits.CubicMillimeters:
        return super.internalDivide(value, 1e9);
      case VolumeUnits.CubicMicrometers:
        return super.internalDivide(value, 1e18);
      case VolumeUnits.CubicMiles:
        return super.internalMultiply(value, 4.16818182544058e9);
      case VolumeUnits.CubicYards:
        return super.internalMultiply(value, 0.764554858);
      case VolumeUnits.CubicFeet:
        return super.internalMultiply(value, 2.8316846592e-2);
      case VolumeUnits.CubicInches:
        return super.internalMultiply(value, 1.6387064e-5);
      case VolumeUnits.ImperialGallons:
        return super.internalMultiply(value, 0.00454609);
      case VolumeUnits.ImperialOunces:
        return super.internalMultiply(value, 2.8413062499962901241875439064617e-5);
      case VolumeUnits.UsGallons:
        return super.internalMultiply(value, 0.003785411784);
      case VolumeUnits.UsOunces:
        return super.internalMultiply(value, 2.957352956253760505068307980135e-5);
      case VolumeUnits.UsTablespoons:
        return super.internalMultiply(value, 1.478676478125e-5);
      case VolumeUnits.AuTablespoons:
        return super.internalMultiply(value, 2e-5);
      case VolumeUnits.UkTablespoons:
        return super.internalMultiply(value, 1.5e-5);
      case VolumeUnits.MetricTeaspoons:
        return super.internalMultiply(value, 0.5e-5);
      case VolumeUnits.UsTeaspoons:
        return super.internalMultiply(value, 4.92892159375e-6);
      case VolumeUnits.MetricCups:
        return super.internalMultiply(value, 0.00025);
      case VolumeUnits.UsCustomaryCups:
        return super.internalMultiply(value, 0.0002365882365);
      case VolumeUnits.UsLegalCups:
        return super.internalMultiply(value, 0.00024);
      case VolumeUnits.OilBarrels:
        return super.internalMultiply(value, 0.158987294928);
      case VolumeUnits.UsBeerBarrels:
        return super.internalMultiply(value, 0.1173477658);
      case VolumeUnits.ImperialBeerBarrels:
        return super.internalMultiply(value, 0.16365924);
      case VolumeUnits.UsQuarts:
        return super.internalMultiply(value, 9.46352946e-4);
      case VolumeUnits.ImperialQuarts:
        return super.internalMultiply(value, 1.1365225e-3);
      case VolumeUnits.UsPints:
        return super.internalMultiply(value, 4.73176473e-4);
      case VolumeUnits.AcreFeet:
        return super.internalDivide(value, 0.000810714);
      case VolumeUnits.ImperialPints:
        return super.internalMultiply(value, 5.6826125e-4);
      case VolumeUnits.BoardFeet:
        return super.internalMultiply(value, 2.3597372158e-3);
      case VolumeUnits.Nanoliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1e-9);
        }
      case VolumeUnits.Microliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.000001);
        }
      case VolumeUnits.Milliliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.001);
        }
      case VolumeUnits.Centiliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.01);
        }
      case VolumeUnits.Deciliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.1);
        }
      case VolumeUnits.Decaliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 10);
        }
      case VolumeUnits.Hectoliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 100);
        }
      case VolumeUnits.Kiloliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1000);
        }
      case VolumeUnits.Megaliters:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeUnits.HectocubicMeters:
        return super.internalMultiply(value, 100);
      case VolumeUnits.KilocubicMeters:
        return super.internalMultiply(value, 1000);
      case VolumeUnits.HectocubicFeet:
        {
          const v3 = super.internalMultiply(value, 2.8316846592e-2);
          return super.internalMultiply(v3, 100);
        }
      case VolumeUnits.KilocubicFeet:
        {
          const v3 = super.internalMultiply(value, 2.8316846592e-2);
          return super.internalMultiply(v3, 1000);
        }
      case VolumeUnits.MegacubicFeet:
        {
          const v3 = super.internalMultiply(value, 2.8316846592e-2);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeUnits.KiloimperialGallons:
        {
          const v3 = super.internalMultiply(value, 0.00454609);
          return super.internalMultiply(v3, 1000);
        }
      case VolumeUnits.MegaimperialGallons:
        {
          const v3 = super.internalMultiply(value, 0.00454609);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeUnits.DecausGallons:
        {
          const v3 = super.internalMultiply(value, 0.003785411784);
          return super.internalMultiply(v3, 10);
        }
      case VolumeUnits.DeciusGallons:
        {
          const v3 = super.internalMultiply(value, 0.003785411784);
          return super.internalMultiply(v3, 0.1);
        }
      case VolumeUnits.HectousGallons:
        {
          const v3 = super.internalMultiply(value, 0.003785411784);
          return super.internalMultiply(v3, 100);
        }
      case VolumeUnits.KilousGallons:
        {
          const v3 = super.internalMultiply(value, 0.003785411784);
          return super.internalMultiply(v3, 1000);
        }
      case VolumeUnits.MegausGallons:
        {
          const v3 = super.internalMultiply(value, 0.003785411784);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case VolumeUnits.Liters:
        return value / 1e3;
      case VolumeUnits.CubicMeters:
        return value;
      case VolumeUnits.CubicKilometers:
        return value * 1e9;
      case VolumeUnits.CubicHectometers:
        return value * 1e6;
      case VolumeUnits.CubicDecimeters:
        return value / 1e3;
      case VolumeUnits.CubicCentimeters:
        return value / 1e6;
      case VolumeUnits.CubicMillimeters:
        return value / 1e9;
      case VolumeUnits.CubicMicrometers:
        return value / 1e18;
      case VolumeUnits.CubicMiles:
        return value * 4.16818182544058e9;
      case VolumeUnits.CubicYards:
        return value * 0.764554858;
      case VolumeUnits.CubicFeet:
        return value * 2.8316846592e-2;
      case VolumeUnits.CubicInches:
        return value * 1.6387064e-5;
      case VolumeUnits.ImperialGallons:
        return value * 0.00454609;
      case VolumeUnits.ImperialOunces:
        return value * 2.8413062499962901241875439064617e-5;
      case VolumeUnits.UsGallons:
        return value * 0.003785411784;
      case VolumeUnits.UsOunces:
        return value * 2.957352956253760505068307980135e-5;
      case VolumeUnits.UsTablespoons:
        return value * 1.478676478125e-5;
      case VolumeUnits.AuTablespoons:
        return value * 2e-5;
      case VolumeUnits.UkTablespoons:
        return value * 1.5e-5;
      case VolumeUnits.MetricTeaspoons:
        return value * 0.5e-5;
      case VolumeUnits.UsTeaspoons:
        return value * 4.92892159375e-6;
      case VolumeUnits.MetricCups:
        return value * 0.00025;
      case VolumeUnits.UsCustomaryCups:
        return value * 0.0002365882365;
      case VolumeUnits.UsLegalCups:
        return value * 0.00024;
      case VolumeUnits.OilBarrels:
        return value * 0.158987294928;
      case VolumeUnits.UsBeerBarrels:
        return value * 0.1173477658;
      case VolumeUnits.ImperialBeerBarrels:
        return value * 0.16365924;
      case VolumeUnits.UsQuarts:
        return value * 9.46352946e-4;
      case VolumeUnits.ImperialQuarts:
        return value * 1.1365225e-3;
      case VolumeUnits.UsPints:
        return value * 4.73176473e-4;
      case VolumeUnits.AcreFeet:
        return value / 0.000810714;
      case VolumeUnits.ImperialPints:
        return value * 5.6826125e-4;
      case VolumeUnits.BoardFeet:
        return value * 2.3597372158e-3;
      case VolumeUnits.Nanoliters:
        return value / 1e3 * 1e-9;
      case VolumeUnits.Microliters:
        return value / 1e3 * 0.000001;
      case VolumeUnits.Milliliters:
        return value / 1e3 * 0.001;
      case VolumeUnits.Centiliters:
        return value / 1e3 * 0.01;
      case VolumeUnits.Deciliters:
        return value / 1e3 * 0.1;
      case VolumeUnits.Decaliters:
        return value / 1e3 * 10;
      case VolumeUnits.Hectoliters:
        return value / 1e3 * 100;
      case VolumeUnits.Kiloliters:
        return value / 1e3 * 1000;
      case VolumeUnits.Megaliters:
        return value / 1e3 * 1000000;
      case VolumeUnits.HectocubicMeters:
        return value * 100;
      case VolumeUnits.KilocubicMeters:
        return value * 1000;
      case VolumeUnits.HectocubicFeet:
        return value * 2.8316846592e-2 * 100;
      case VolumeUnits.KilocubicFeet:
        return value * 2.8316846592e-2 * 1000;
      case VolumeUnits.MegacubicFeet:
        return value * 2.8316846592e-2 * 1000000;
      case VolumeUnits.KiloimperialGallons:
        return value * 0.00454609 * 1000;
      case VolumeUnits.MegaimperialGallons:
        return value * 0.00454609 * 1000000;
      case VolumeUnits.DecausGallons:
        return value * 0.003785411784 * 10;
      case VolumeUnits.DeciusGallons:
        return value * 0.003785411784 * 0.1;
      case VolumeUnits.HectousGallons:
        return value * 0.003785411784 * 100;
      case VolumeUnits.KilousGallons:
        return value * 0.003785411784 * 1000;
      case VolumeUnits.MegausGallons:
        return value * 0.003785411784 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Volume to string.
   * Note! the default format for Volume is CubicMeters.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Volume.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Volume.
   */
  toString(unit = VolumeUnits.CubicMeters, fractionalDigits) {
    switch (unit) {
      case VolumeUnits.Liters:
        return super.truncateFractionDigits(this.Liters, fractionalDigits) + ` l`;
      case VolumeUnits.CubicMeters:
        return super.truncateFractionDigits(this.CubicMeters, fractionalDigits) + ` m³`;
      case VolumeUnits.CubicKilometers:
        return super.truncateFractionDigits(this.CubicKilometers, fractionalDigits) + ` km³`;
      case VolumeUnits.CubicHectometers:
        return super.truncateFractionDigits(this.CubicHectometers, fractionalDigits) + ` hm³`;
      case VolumeUnits.CubicDecimeters:
        return super.truncateFractionDigits(this.CubicDecimeters, fractionalDigits) + ` dm³`;
      case VolumeUnits.CubicCentimeters:
        return super.truncateFractionDigits(this.CubicCentimeters, fractionalDigits) + ` cm³`;
      case VolumeUnits.CubicMillimeters:
        return super.truncateFractionDigits(this.CubicMillimeters, fractionalDigits) + ` mm³`;
      case VolumeUnits.CubicMicrometers:
        return super.truncateFractionDigits(this.CubicMicrometers, fractionalDigits) + ` µm³`;
      case VolumeUnits.CubicMiles:
        return super.truncateFractionDigits(this.CubicMiles, fractionalDigits) + ` mi³`;
      case VolumeUnits.CubicYards:
        return super.truncateFractionDigits(this.CubicYards, fractionalDigits) + ` yd³`;
      case VolumeUnits.CubicFeet:
        return super.truncateFractionDigits(this.CubicFeet, fractionalDigits) + ` ft³`;
      case VolumeUnits.CubicInches:
        return super.truncateFractionDigits(this.CubicInches, fractionalDigits) + ` in³`;
      case VolumeUnits.ImperialGallons:
        return super.truncateFractionDigits(this.ImperialGallons, fractionalDigits) + ` gal (imp.)`;
      case VolumeUnits.ImperialOunces:
        return super.truncateFractionDigits(this.ImperialOunces, fractionalDigits) + ` oz (imp.)`;
      case VolumeUnits.UsGallons:
        return super.truncateFractionDigits(this.UsGallons, fractionalDigits) + ` gal (U.S.)`;
      case VolumeUnits.UsOunces:
        return super.truncateFractionDigits(this.UsOunces, fractionalDigits) + ` oz (U.S.)`;
      case VolumeUnits.UsTablespoons:
        return super.truncateFractionDigits(this.UsTablespoons, fractionalDigits) + ` undefined`;
      case VolumeUnits.AuTablespoons:
        return super.truncateFractionDigits(this.AuTablespoons, fractionalDigits) + ` undefined`;
      case VolumeUnits.UkTablespoons:
        return super.truncateFractionDigits(this.UkTablespoons, fractionalDigits) + ` undefined`;
      case VolumeUnits.MetricTeaspoons:
        return super.truncateFractionDigits(this.MetricTeaspoons, fractionalDigits) + ` tsp`;
      case VolumeUnits.UsTeaspoons:
        return super.truncateFractionDigits(this.UsTeaspoons, fractionalDigits) + ` undefined`;
      case VolumeUnits.MetricCups:
        return super.truncateFractionDigits(this.MetricCups, fractionalDigits) + ` undefined`;
      case VolumeUnits.UsCustomaryCups:
        return super.truncateFractionDigits(this.UsCustomaryCups, fractionalDigits) + ` undefined`;
      case VolumeUnits.UsLegalCups:
        return super.truncateFractionDigits(this.UsLegalCups, fractionalDigits) + ` undefined`;
      case VolumeUnits.OilBarrels:
        return super.truncateFractionDigits(this.OilBarrels, fractionalDigits) + ` bbl`;
      case VolumeUnits.UsBeerBarrels:
        return super.truncateFractionDigits(this.UsBeerBarrels, fractionalDigits) + ` bl (U.S.)`;
      case VolumeUnits.ImperialBeerBarrels:
        return super.truncateFractionDigits(this.ImperialBeerBarrels, fractionalDigits) + ` bl (imp.)`;
      case VolumeUnits.UsQuarts:
        return super.truncateFractionDigits(this.UsQuarts, fractionalDigits) + ` qt (U.S.)`;
      case VolumeUnits.ImperialQuarts:
        return super.truncateFractionDigits(this.ImperialQuarts, fractionalDigits) + ` qt (imp.)`;
      case VolumeUnits.UsPints:
        return super.truncateFractionDigits(this.UsPints, fractionalDigits) + ` pt (U.S.)`;
      case VolumeUnits.AcreFeet:
        return super.truncateFractionDigits(this.AcreFeet, fractionalDigits) + ` ac-ft`;
      case VolumeUnits.ImperialPints:
        return super.truncateFractionDigits(this.ImperialPints, fractionalDigits) + ` pt (imp.)`;
      case VolumeUnits.BoardFeet:
        return super.truncateFractionDigits(this.BoardFeet, fractionalDigits) + ` bf`;
      case VolumeUnits.Nanoliters:
        return super.truncateFractionDigits(this.Nanoliters, fractionalDigits) + ` nl`;
      case VolumeUnits.Microliters:
        return super.truncateFractionDigits(this.Microliters, fractionalDigits) + ` μl`;
      case VolumeUnits.Milliliters:
        return super.truncateFractionDigits(this.Milliliters, fractionalDigits) + ` ml`;
      case VolumeUnits.Centiliters:
        return super.truncateFractionDigits(this.Centiliters, fractionalDigits) + ` cl`;
      case VolumeUnits.Deciliters:
        return super.truncateFractionDigits(this.Deciliters, fractionalDigits) + ` dl`;
      case VolumeUnits.Decaliters:
        return super.truncateFractionDigits(this.Decaliters, fractionalDigits) + ` dal`;
      case VolumeUnits.Hectoliters:
        return super.truncateFractionDigits(this.Hectoliters, fractionalDigits) + ` hl`;
      case VolumeUnits.Kiloliters:
        return super.truncateFractionDigits(this.Kiloliters, fractionalDigits) + ` kl`;
      case VolumeUnits.Megaliters:
        return super.truncateFractionDigits(this.Megaliters, fractionalDigits) + ` Ml`;
      case VolumeUnits.HectocubicMeters:
        return super.truncateFractionDigits(this.HectocubicMeters, fractionalDigits) + ` hm³`;
      case VolumeUnits.KilocubicMeters:
        return super.truncateFractionDigits(this.KilocubicMeters, fractionalDigits) + ` km³`;
      case VolumeUnits.HectocubicFeet:
        return super.truncateFractionDigits(this.HectocubicFeet, fractionalDigits) + ` hft³`;
      case VolumeUnits.KilocubicFeet:
        return super.truncateFractionDigits(this.KilocubicFeet, fractionalDigits) + ` kft³`;
      case VolumeUnits.MegacubicFeet:
        return super.truncateFractionDigits(this.MegacubicFeet, fractionalDigits) + ` Mft³`;
      case VolumeUnits.KiloimperialGallons:
        return super.truncateFractionDigits(this.KiloimperialGallons, fractionalDigits) + ` kgal (imp.)`;
      case VolumeUnits.MegaimperialGallons:
        return super.truncateFractionDigits(this.MegaimperialGallons, fractionalDigits) + ` Mgal (imp.)`;
      case VolumeUnits.DecausGallons:
        return super.truncateFractionDigits(this.DecausGallons, fractionalDigits) + ` dagal (U.S.)`;
      case VolumeUnits.DeciusGallons:
        return super.truncateFractionDigits(this.DeciusGallons, fractionalDigits) + ` dgal (U.S.)`;
      case VolumeUnits.HectousGallons:
        return super.truncateFractionDigits(this.HectousGallons, fractionalDigits) + ` hgal (U.S.)`;
      case VolumeUnits.KilousGallons:
        return super.truncateFractionDigits(this.KilousGallons, fractionalDigits) + ` kgal (U.S.)`;
      case VolumeUnits.MegausGallons:
        return super.truncateFractionDigits(this.MegausGallons, fractionalDigits) + ` Mgal (U.S.)`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Volume unit abbreviation.
   * Note! the default abbreviation for Volume is CubicMeters.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Volume.
   * @returns The abbreviation string of Volume.
   */
  getUnitAbbreviation(unitAbbreviation = VolumeUnits.CubicMeters) {
    switch (unitAbbreviation) {
      case VolumeUnits.Liters:
        return `l`;
      case VolumeUnits.CubicMeters:
        return `m³`;
      case VolumeUnits.CubicKilometers:
        return `km³`;
      case VolumeUnits.CubicHectometers:
        return `hm³`;
      case VolumeUnits.CubicDecimeters:
        return `dm³`;
      case VolumeUnits.CubicCentimeters:
        return `cm³`;
      case VolumeUnits.CubicMillimeters:
        return `mm³`;
      case VolumeUnits.CubicMicrometers:
        return `µm³`;
      case VolumeUnits.CubicMiles:
        return `mi³`;
      case VolumeUnits.CubicYards:
        return `yd³`;
      case VolumeUnits.CubicFeet:
        return `ft³`;
      case VolumeUnits.CubicInches:
        return `in³`;
      case VolumeUnits.ImperialGallons:
        return `gal (imp.)`;
      case VolumeUnits.ImperialOunces:
        return `oz (imp.)`;
      case VolumeUnits.UsGallons:
        return `gal (U.S.)`;
      case VolumeUnits.UsOunces:
        return `oz (U.S.)`;
      case VolumeUnits.UsTablespoons:
        return `undefined`;
      case VolumeUnits.AuTablespoons:
        return `undefined`;
      case VolumeUnits.UkTablespoons:
        return `undefined`;
      case VolumeUnits.MetricTeaspoons:
        return `tsp`;
      case VolumeUnits.UsTeaspoons:
        return `undefined`;
      case VolumeUnits.MetricCups:
        return `undefined`;
      case VolumeUnits.UsCustomaryCups:
        return `undefined`;
      case VolumeUnits.UsLegalCups:
        return `undefined`;
      case VolumeUnits.OilBarrels:
        return `bbl`;
      case VolumeUnits.UsBeerBarrels:
        return `bl (U.S.)`;
      case VolumeUnits.ImperialBeerBarrels:
        return `bl (imp.)`;
      case VolumeUnits.UsQuarts:
        return `qt (U.S.)`;
      case VolumeUnits.ImperialQuarts:
        return `qt (imp.)`;
      case VolumeUnits.UsPints:
        return `pt (U.S.)`;
      case VolumeUnits.AcreFeet:
        return `ac-ft`;
      case VolumeUnits.ImperialPints:
        return `pt (imp.)`;
      case VolumeUnits.BoardFeet:
        return `bf`;
      case VolumeUnits.Nanoliters:
        return `nl`;
      case VolumeUnits.Microliters:
        return `μl`;
      case VolumeUnits.Milliliters:
        return `ml`;
      case VolumeUnits.Centiliters:
        return `cl`;
      case VolumeUnits.Deciliters:
        return `dl`;
      case VolumeUnits.Decaliters:
        return `dal`;
      case VolumeUnits.Hectoliters:
        return `hl`;
      case VolumeUnits.Kiloliters:
        return `kl`;
      case VolumeUnits.Megaliters:
        return `Ml`;
      case VolumeUnits.HectocubicMeters:
        return `hm³`;
      case VolumeUnits.KilocubicMeters:
        return `km³`;
      case VolumeUnits.HectocubicFeet:
        return `hft³`;
      case VolumeUnits.KilocubicFeet:
        return `kft³`;
      case VolumeUnits.MegacubicFeet:
        return `Mft³`;
      case VolumeUnits.KiloimperialGallons:
        return `kgal (imp.)`;
      case VolumeUnits.MegaimperialGallons:
        return `Mgal (imp.)`;
      case VolumeUnits.DecausGallons:
        return `dagal (U.S.)`;
      case VolumeUnits.DeciusGallons:
        return `dgal (U.S.)`;
      case VolumeUnits.HectousGallons:
        return `hgal (U.S.)`;
      case VolumeUnits.KilousGallons:
        return `kgal (U.S.)`;
      case VolumeUnits.MegausGallons:
        return `Mgal (U.S.)`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Volume are equals to the current Volume.
   * @param volume The other Volume.
   * @returns True if the given Volume are equal to the current Volume.
   */
  equals(volume) {
    return super.internalEquals(this.value, volume.BaseValue);
  }
  /**
   * Compare the given Volume against the current Volume.
   * @param volume The other Volume.
   * @returns 0 if they are equal, -1 if the current Volume is less then other, 1 if the current Volume is greater then other.
   */
  compareTo(volume) {
    return super.internalCompareTo(this.value, volume.BaseValue);
  }
  /**
   * Add the given Volume with the current Volume.
   * @param volume The other Volume.
   * @returns A new Volume instance with the results.
   */
  add(volume) {
    return new Volume(super.internalAdd(this.value, volume.BaseValue));
  }
  /**
   * Subtract the given Volume with the current Volume.
   * @param volume The other Volume.
   * @returns A new Volume instance with the results.
   */
  subtract(volume) {
    return new Volume(super.internalSubtract(this.value, volume.BaseValue));
  }
  /**
   * Multiply the given Volume with the current Volume.
   * @param volume The other Volume.
   * @returns A new Volume instance with the results.
   */
  multiply(volume) {
    return new Volume(super.internalMultiply(this.value, volume.BaseValue));
  }
  /**
   * Divide the given Volume with the current Volume.
   * @param volume The other Volume.
   * @returns A new Volume instance with the results.
   */
  divide(volume) {
    return new Volume(super.internalDivide(this.value, volume.BaseValue));
  }
  /**
   * Modulo the given Volume with the current Volume.
   * @param volume The other Volume.
   * @returns A new Volume instance with the results.
   */
  modulo(volume) {
    return new Volume(super.internalModulo(this.value, volume.BaseValue));
  }
  /**
   * Pow the given Volume with the current Volume.
   * @param volume The other Volume.
   * @returns A new Volume instance with the results.
   */
  pow(volume) {
    return new Volume(super.internalPow(this.value, volume.BaseValue));
  }
}
exports.Volume = Volume;