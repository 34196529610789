"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricPotentialAc = exports.ElectricPotentialAcUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricPotentialAcUnits enumeration */
var ElectricPotentialAcUnits;
(function (ElectricPotentialAcUnits) {
  /** */
  ElectricPotentialAcUnits["VoltsAc"] = "VoltAc";
  /** */
  ElectricPotentialAcUnits["MicrovoltsAc"] = "MicrovoltAc";
  /** */
  ElectricPotentialAcUnits["MillivoltsAc"] = "MillivoltAc";
  /** */
  ElectricPotentialAcUnits["KilovoltsAc"] = "KilovoltAc";
  /** */
  ElectricPotentialAcUnits["MegavoltsAc"] = "MegavoltAc";
})(ElectricPotentialAcUnits = exports.ElectricPotentialAcUnits || (exports.ElectricPotentialAcUnits = {}));
/** The Electric Potential of a system known to use Alternating Current. */
class ElectricPotentialAc extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricPotentialAc.
   * @param value The value.
   * @param fromUnit The ‘ElectricPotentialAc’ unit to create from.
   * The default unit is VoltsAc
   */
  constructor(value, fromUnit = ElectricPotentialAcUnits.VoltsAc) {
    super();
    this.voltsacLazy = null;
    this.microvoltsacLazy = null;
    this.millivoltsacLazy = null;
    this.kilovoltsacLazy = null;
    this.megavoltsacLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricPotentialAc is VoltsAc.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricPotentialAcUnits.VoltsAc;
  }
  /** */
  get VoltsAc() {
    if (this.voltsacLazy !== null) {
      return this.voltsacLazy;
    }
    return this.voltsacLazy = this.convertFromBase(ElectricPotentialAcUnits.VoltsAc);
  }
  /** */
  get MicrovoltsAc() {
    if (this.microvoltsacLazy !== null) {
      return this.microvoltsacLazy;
    }
    return this.microvoltsacLazy = this.convertFromBase(ElectricPotentialAcUnits.MicrovoltsAc);
  }
  /** */
  get MillivoltsAc() {
    if (this.millivoltsacLazy !== null) {
      return this.millivoltsacLazy;
    }
    return this.millivoltsacLazy = this.convertFromBase(ElectricPotentialAcUnits.MillivoltsAc);
  }
  /** */
  get KilovoltsAc() {
    if (this.kilovoltsacLazy !== null) {
      return this.kilovoltsacLazy;
    }
    return this.kilovoltsacLazy = this.convertFromBase(ElectricPotentialAcUnits.KilovoltsAc);
  }
  /** */
  get MegavoltsAc() {
    if (this.megavoltsacLazy !== null) {
      return this.megavoltsacLazy;
    }
    return this.megavoltsacLazy = this.convertFromBase(ElectricPotentialAcUnits.MegavoltsAc);
  }
  /**
   * Create a new ElectricPotentialAc instance from a VoltsAc
   *
   * @param value The unit as VoltsAc to create a new ElectricPotentialAc from.
   * @returns The new ElectricPotentialAc instance.
   */
  static FromVoltsAc(value) {
    return new ElectricPotentialAc(value, ElectricPotentialAcUnits.VoltsAc);
  }
  /**
   * Create a new ElectricPotentialAc instance from a MicrovoltsAc
   *
   * @param value The unit as MicrovoltsAc to create a new ElectricPotentialAc from.
   * @returns The new ElectricPotentialAc instance.
   */
  static FromMicrovoltsAc(value) {
    return new ElectricPotentialAc(value, ElectricPotentialAcUnits.MicrovoltsAc);
  }
  /**
   * Create a new ElectricPotentialAc instance from a MillivoltsAc
   *
   * @param value The unit as MillivoltsAc to create a new ElectricPotentialAc from.
   * @returns The new ElectricPotentialAc instance.
   */
  static FromMillivoltsAc(value) {
    return new ElectricPotentialAc(value, ElectricPotentialAcUnits.MillivoltsAc);
  }
  /**
   * Create a new ElectricPotentialAc instance from a KilovoltsAc
   *
   * @param value The unit as KilovoltsAc to create a new ElectricPotentialAc from.
   * @returns The new ElectricPotentialAc instance.
   */
  static FromKilovoltsAc(value) {
    return new ElectricPotentialAc(value, ElectricPotentialAcUnits.KilovoltsAc);
  }
  /**
   * Create a new ElectricPotentialAc instance from a MegavoltsAc
   *
   * @param value The unit as MegavoltsAc to create a new ElectricPotentialAc from.
   * @returns The new ElectricPotentialAc instance.
   */
  static FromMegavoltsAc(value) {
    return new ElectricPotentialAc(value, ElectricPotentialAcUnits.MegavoltsAc);
  }
  /**
   * Gets the base unit enumeration associated with ElectricPotentialAc
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricPotentialAcUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricPotentialAcUnits.VoltsAc;
  }
  /**
   * Create API DTO represent a ElectricPotentialAc unit.
   * @param holdInUnit The specific ElectricPotentialAc unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricPotentialAcUnits.VoltsAc) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricPotentialAc unit from an API DTO representation.
   * @param dtoElectricPotentialAc The ElectricPotentialAc API DTO representation
   */
  static FromDto(dtoElectricPotentialAc) {
    return new ElectricPotentialAc(dtoElectricPotentialAc.value, dtoElectricPotentialAc.unit);
  }
  /**
   * Convert ElectricPotentialAc to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricPotentialAcUnits.VoltsAc:
        return this.VoltsAc;
      case ElectricPotentialAcUnits.MicrovoltsAc:
        return this.MicrovoltsAc;
      case ElectricPotentialAcUnits.MillivoltsAc:
        return this.MillivoltsAc;
      case ElectricPotentialAcUnits.KilovoltsAc:
        return this.KilovoltsAc;
      case ElectricPotentialAcUnits.MegavoltsAc:
        return this.MegavoltsAc;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricPotentialAcUnits.VoltsAc:
        return this.value;
      case ElectricPotentialAcUnits.MicrovoltsAc:
        return super.internalDivide(this.value, 0.000001);
      case ElectricPotentialAcUnits.MillivoltsAc:
        return super.internalDivide(this.value, 0.001);
      case ElectricPotentialAcUnits.KilovoltsAc:
        return super.internalDivide(this.value, 1000);
      case ElectricPotentialAcUnits.MegavoltsAc:
        return super.internalDivide(this.value, 1000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricPotentialAcUnits.VoltsAc:
        return this.value;
      case ElectricPotentialAcUnits.MicrovoltsAc:
        return this.value / 0.000001;
      case ElectricPotentialAcUnits.MillivoltsAc:
        return this.value / 0.001;
      case ElectricPotentialAcUnits.KilovoltsAc:
        return this.value / 1000;
      case ElectricPotentialAcUnits.MegavoltsAc:
        return this.value / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricPotentialAcUnits.VoltsAc:
        return value;
      case ElectricPotentialAcUnits.MicrovoltsAc:
        return super.internalMultiply(value, 0.000001);
      case ElectricPotentialAcUnits.MillivoltsAc:
        return super.internalMultiply(value, 0.001);
      case ElectricPotentialAcUnits.KilovoltsAc:
        return super.internalMultiply(value, 1000);
      case ElectricPotentialAcUnits.MegavoltsAc:
        return super.internalMultiply(value, 1000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricPotentialAcUnits.VoltsAc:
        return value;
      case ElectricPotentialAcUnits.MicrovoltsAc:
        return value * 0.000001;
      case ElectricPotentialAcUnits.MillivoltsAc:
        return value * 0.001;
      case ElectricPotentialAcUnits.KilovoltsAc:
        return value * 1000;
      case ElectricPotentialAcUnits.MegavoltsAc:
        return value * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricPotentialAc to string.
   * Note! the default format for ElectricPotentialAc is VoltsAc.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricPotentialAc.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricPotentialAc.
   */
  toString(unit = ElectricPotentialAcUnits.VoltsAc, fractionalDigits) {
    switch (unit) {
      case ElectricPotentialAcUnits.VoltsAc:
        return super.truncateFractionDigits(this.VoltsAc, fractionalDigits) + ` Vac`;
      case ElectricPotentialAcUnits.MicrovoltsAc:
        return super.truncateFractionDigits(this.MicrovoltsAc, fractionalDigits) + ` μVac`;
      case ElectricPotentialAcUnits.MillivoltsAc:
        return super.truncateFractionDigits(this.MillivoltsAc, fractionalDigits) + ` mVac`;
      case ElectricPotentialAcUnits.KilovoltsAc:
        return super.truncateFractionDigits(this.KilovoltsAc, fractionalDigits) + ` kVac`;
      case ElectricPotentialAcUnits.MegavoltsAc:
        return super.truncateFractionDigits(this.MegavoltsAc, fractionalDigits) + ` MVac`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricPotentialAc unit abbreviation.
   * Note! the default abbreviation for ElectricPotentialAc is VoltsAc.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricPotentialAc.
   * @returns The abbreviation string of ElectricPotentialAc.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricPotentialAcUnits.VoltsAc) {
    switch (unitAbbreviation) {
      case ElectricPotentialAcUnits.VoltsAc:
        return `Vac`;
      case ElectricPotentialAcUnits.MicrovoltsAc:
        return `μVac`;
      case ElectricPotentialAcUnits.MillivoltsAc:
        return `mVac`;
      case ElectricPotentialAcUnits.KilovoltsAc:
        return `kVac`;
      case ElectricPotentialAcUnits.MegavoltsAc:
        return `MVac`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricPotentialAc are equals to the current ElectricPotentialAc.
   * @param electricPotentialAc The other ElectricPotentialAc.
   * @returns True if the given ElectricPotentialAc are equal to the current ElectricPotentialAc.
   */
  equals(electricPotentialAc) {
    return super.internalEquals(this.value, electricPotentialAc.BaseValue);
  }
  /**
   * Compare the given ElectricPotentialAc against the current ElectricPotentialAc.
   * @param electricPotentialAc The other ElectricPotentialAc.
   * @returns 0 if they are equal, -1 if the current ElectricPotentialAc is less then other, 1 if the current ElectricPotentialAc is greater then other.
   */
  compareTo(electricPotentialAc) {
    return super.internalCompareTo(this.value, electricPotentialAc.BaseValue);
  }
  /**
   * Add the given ElectricPotentialAc with the current ElectricPotentialAc.
   * @param electricPotentialAc The other ElectricPotentialAc.
   * @returns A new ElectricPotentialAc instance with the results.
   */
  add(electricPotentialAc) {
    return new ElectricPotentialAc(super.internalAdd(this.value, electricPotentialAc.BaseValue));
  }
  /**
   * Subtract the given ElectricPotentialAc with the current ElectricPotentialAc.
   * @param electricPotentialAc The other ElectricPotentialAc.
   * @returns A new ElectricPotentialAc instance with the results.
   */
  subtract(electricPotentialAc) {
    return new ElectricPotentialAc(super.internalSubtract(this.value, electricPotentialAc.BaseValue));
  }
  /**
   * Multiply the given ElectricPotentialAc with the current ElectricPotentialAc.
   * @param electricPotentialAc The other ElectricPotentialAc.
   * @returns A new ElectricPotentialAc instance with the results.
   */
  multiply(electricPotentialAc) {
    return new ElectricPotentialAc(super.internalMultiply(this.value, electricPotentialAc.BaseValue));
  }
  /**
   * Divide the given ElectricPotentialAc with the current ElectricPotentialAc.
   * @param electricPotentialAc The other ElectricPotentialAc.
   * @returns A new ElectricPotentialAc instance with the results.
   */
  divide(electricPotentialAc) {
    return new ElectricPotentialAc(super.internalDivide(this.value, electricPotentialAc.BaseValue));
  }
  /**
   * Modulo the given ElectricPotentialAc with the current ElectricPotentialAc.
   * @param electricPotentialAc The other ElectricPotentialAc.
   * @returns A new ElectricPotentialAc instance with the results.
   */
  modulo(electricPotentialAc) {
    return new ElectricPotentialAc(super.internalModulo(this.value, electricPotentialAc.BaseValue));
  }
  /**
   * Pow the given ElectricPotentialAc with the current ElectricPotentialAc.
   * @param electricPotentialAc The other ElectricPotentialAc.
   * @returns A new ElectricPotentialAc instance with the results.
   */
  pow(electricPotentialAc) {
    return new ElectricPotentialAc(super.internalPow(this.value, electricPotentialAc.BaseValue));
  }
}
exports.ElectricPotentialAc = ElectricPotentialAc;