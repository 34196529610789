"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MagneticFlux = exports.MagneticFluxUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MagneticFluxUnits enumeration */
var MagneticFluxUnits;
(function (MagneticFluxUnits) {
  /** */
  MagneticFluxUnits["Webers"] = "Weber";
})(MagneticFluxUnits = exports.MagneticFluxUnits || (exports.MagneticFluxUnits = {}));
/** In physics, specifically electromagnetism, the magnetic flux through a surface is the surface integral of the normal component of the magnetic field B passing through that surface. */
class MagneticFlux extends base_unit_1.BaseUnit {
  /**
   * Create a new MagneticFlux.
   * @param value The value.
   * @param fromUnit The ‘MagneticFlux’ unit to create from.
   * The default unit is Webers
   */
  constructor(value, fromUnit = MagneticFluxUnits.Webers) {
    super();
    this.webersLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of MagneticFlux is Webers.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MagneticFluxUnits.Webers;
  }
  /** */
  get Webers() {
    if (this.webersLazy !== null) {
      return this.webersLazy;
    }
    return this.webersLazy = this.convertFromBase(MagneticFluxUnits.Webers);
  }
  /**
   * Create a new MagneticFlux instance from a Webers
   *
   * @param value The unit as Webers to create a new MagneticFlux from.
   * @returns The new MagneticFlux instance.
   */
  static FromWebers(value) {
    return new MagneticFlux(value, MagneticFluxUnits.Webers);
  }
  /**
   * Gets the base unit enumeration associated with MagneticFlux
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MagneticFluxUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MagneticFluxUnits.Webers;
  }
  /**
   * Create API DTO represent a MagneticFlux unit.
   * @param holdInUnit The specific MagneticFlux unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MagneticFluxUnits.Webers) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a MagneticFlux unit from an API DTO representation.
   * @param dtoMagneticFlux The MagneticFlux API DTO representation
   */
  static FromDto(dtoMagneticFlux) {
    return new MagneticFlux(dtoMagneticFlux.value, dtoMagneticFlux.unit);
  }
  /**
   * Convert MagneticFlux to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MagneticFluxUnits.Webers:
        return this.Webers;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MagneticFluxUnits.Webers:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MagneticFluxUnits.Webers:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MagneticFluxUnits.Webers:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MagneticFluxUnits.Webers:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the MagneticFlux to string.
   * Note! the default format for MagneticFlux is Webers.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the MagneticFlux.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the MagneticFlux.
   */
  toString(unit = MagneticFluxUnits.Webers, fractionalDigits) {
    switch (unit) {
      case MagneticFluxUnits.Webers:
        return super.truncateFractionDigits(this.Webers, fractionalDigits) + ` Wb`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get MagneticFlux unit abbreviation.
   * Note! the default abbreviation for MagneticFlux is Webers.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the MagneticFlux.
   * @returns The abbreviation string of MagneticFlux.
   */
  getUnitAbbreviation(unitAbbreviation = MagneticFluxUnits.Webers) {
    switch (unitAbbreviation) {
      case MagneticFluxUnits.Webers:
        return `Wb`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given MagneticFlux are equals to the current MagneticFlux.
   * @param magneticFlux The other MagneticFlux.
   * @returns True if the given MagneticFlux are equal to the current MagneticFlux.
   */
  equals(magneticFlux) {
    return super.internalEquals(this.value, magneticFlux.BaseValue);
  }
  /**
   * Compare the given MagneticFlux against the current MagneticFlux.
   * @param magneticFlux The other MagneticFlux.
   * @returns 0 if they are equal, -1 if the current MagneticFlux is less then other, 1 if the current MagneticFlux is greater then other.
   */
  compareTo(magneticFlux) {
    return super.internalCompareTo(this.value, magneticFlux.BaseValue);
  }
  /**
   * Add the given MagneticFlux with the current MagneticFlux.
   * @param magneticFlux The other MagneticFlux.
   * @returns A new MagneticFlux instance with the results.
   */
  add(magneticFlux) {
    return new MagneticFlux(super.internalAdd(this.value, magneticFlux.BaseValue));
  }
  /**
   * Subtract the given MagneticFlux with the current MagneticFlux.
   * @param magneticFlux The other MagneticFlux.
   * @returns A new MagneticFlux instance with the results.
   */
  subtract(magneticFlux) {
    return new MagneticFlux(super.internalSubtract(this.value, magneticFlux.BaseValue));
  }
  /**
   * Multiply the given MagneticFlux with the current MagneticFlux.
   * @param magneticFlux The other MagneticFlux.
   * @returns A new MagneticFlux instance with the results.
   */
  multiply(magneticFlux) {
    return new MagneticFlux(super.internalMultiply(this.value, magneticFlux.BaseValue));
  }
  /**
   * Divide the given MagneticFlux with the current MagneticFlux.
   * @param magneticFlux The other MagneticFlux.
   * @returns A new MagneticFlux instance with the results.
   */
  divide(magneticFlux) {
    return new MagneticFlux(super.internalDivide(this.value, magneticFlux.BaseValue));
  }
  /**
   * Modulo the given MagneticFlux with the current MagneticFlux.
   * @param magneticFlux The other MagneticFlux.
   * @returns A new MagneticFlux instance with the results.
   */
  modulo(magneticFlux) {
    return new MagneticFlux(super.internalModulo(this.value, magneticFlux.BaseValue));
  }
  /**
   * Pow the given MagneticFlux with the current MagneticFlux.
   * @param magneticFlux The other MagneticFlux.
   * @returns A new MagneticFlux instance with the results.
   */
  pow(magneticFlux) {
    return new MagneticFlux(super.internalPow(this.value, magneticFlux.BaseValue));
  }
}
exports.MagneticFlux = MagneticFlux;