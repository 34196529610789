import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { AppConstants } from "../shared/constants";
import { Recipes, STiRResultPO, RecipeFindPO, RecipeSearchPO, RecipeInfoPO, RecipesPO, STiRResultListPO } from "../models";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
    providedIn: "root",
})
export class RecipeService {

    constructor(private baseService: BaseService) {
    }

    GetRecipeDetailsById(recipeId: string): Observable<STiRResultPO<Recipes>> {
        let url = `Recipe/GetRecipeById?id=${recipeId}`;
        return this.baseService.Get(url, AppConstants.Loading);
    }

    GetRecipesByIds(recipeId: string[]): Observable<STiRResultListPO<Recipes>> {
        let url = `Recipe/GetRecipesByIds`;
        return this.baseService.Post(url, recipeId, AppConstants.Loading);
    }

    searchRecipeDetails(data: RecipeSearchPO): Observable<RecipeFindPO[]> {
        let url = 'Recipe/SearchRecipe';
        return this.baseService.Post(url, data, AppConstants.Loading);
    }


    createRecipeName(ot: string): Observable<STiRResultPO<string>> {
        let url = `Recipe/CreateRecipeName?recipeType=${ot}`;
        return this.baseService.Get(url, AppConstants.Loading);
    }

    upsertRecipe(data: RecipesPO): Observable<STiRResultPO<RecipeInfoPO>> {
        let url = `Recipe/UpsertRecipe`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }
}