"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Jerk = exports.JerkUnits = void 0;
const base_unit_1 = require("../base-unit");
/** JerkUnits enumeration */
var JerkUnits;
(function (JerkUnits) {
  /** */
  JerkUnits["MetersPerSecondCubed"] = "MeterPerSecondCubed";
  /** */
  JerkUnits["InchesPerSecondCubed"] = "InchPerSecondCubed";
  /** */
  JerkUnits["FeetPerSecondCubed"] = "FootPerSecondCubed";
  /** */
  JerkUnits["StandardGravitiesPerSecond"] = "StandardGravitiesPerSecond";
  /** */
  JerkUnits["NanometersPerSecondCubed"] = "NanometerPerSecondCubed";
  /** */
  JerkUnits["MicrometersPerSecondCubed"] = "MicrometerPerSecondCubed";
  /** */
  JerkUnits["MillimetersPerSecondCubed"] = "MillimeterPerSecondCubed";
  /** */
  JerkUnits["CentimetersPerSecondCubed"] = "CentimeterPerSecondCubed";
  /** */
  JerkUnits["DecimetersPerSecondCubed"] = "DecimeterPerSecondCubed";
  /** */
  JerkUnits["KilometersPerSecondCubed"] = "KilometerPerSecondCubed";
  /** */
  JerkUnits["MillistandardGravitiesPerSecond"] = "MillistandardGravitiesPerSecond";
})(JerkUnits = exports.JerkUnits || (exports.JerkUnits = {}));
/** Jerk or Jolt, in physics, is the rate at which the acceleration of an object changes over time. The SI unit for jerk is the Meter per second cubed (m/s³). Jerks are vector quantities (they have magnitude and direction) and add according to the parallelogram law. */
class Jerk extends base_unit_1.BaseUnit {
  /**
   * Create a new Jerk.
   * @param value The value.
   * @param fromUnit The ‘Jerk’ unit to create from.
   * The default unit is MetersPerSecondCubed
   */
  constructor(value, fromUnit = JerkUnits.MetersPerSecondCubed) {
    super();
    this.meterspersecondcubedLazy = null;
    this.inchespersecondcubedLazy = null;
    this.feetpersecondcubedLazy = null;
    this.standardgravitiespersecondLazy = null;
    this.nanometerspersecondcubedLazy = null;
    this.micrometerspersecondcubedLazy = null;
    this.millimeterspersecondcubedLazy = null;
    this.centimeterspersecondcubedLazy = null;
    this.decimeterspersecondcubedLazy = null;
    this.kilometerspersecondcubedLazy = null;
    this.millistandardgravitiespersecondLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Jerk is MetersPerSecondCubed.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return JerkUnits.MetersPerSecondCubed;
  }
  /** */
  get MetersPerSecondCubed() {
    if (this.meterspersecondcubedLazy !== null) {
      return this.meterspersecondcubedLazy;
    }
    return this.meterspersecondcubedLazy = this.convertFromBase(JerkUnits.MetersPerSecondCubed);
  }
  /** */
  get InchesPerSecondCubed() {
    if (this.inchespersecondcubedLazy !== null) {
      return this.inchespersecondcubedLazy;
    }
    return this.inchespersecondcubedLazy = this.convertFromBase(JerkUnits.InchesPerSecondCubed);
  }
  /** */
  get FeetPerSecondCubed() {
    if (this.feetpersecondcubedLazy !== null) {
      return this.feetpersecondcubedLazy;
    }
    return this.feetpersecondcubedLazy = this.convertFromBase(JerkUnits.FeetPerSecondCubed);
  }
  /** */
  get StandardGravitiesPerSecond() {
    if (this.standardgravitiespersecondLazy !== null) {
      return this.standardgravitiespersecondLazy;
    }
    return this.standardgravitiespersecondLazy = this.convertFromBase(JerkUnits.StandardGravitiesPerSecond);
  }
  /** */
  get NanometersPerSecondCubed() {
    if (this.nanometerspersecondcubedLazy !== null) {
      return this.nanometerspersecondcubedLazy;
    }
    return this.nanometerspersecondcubedLazy = this.convertFromBase(JerkUnits.NanometersPerSecondCubed);
  }
  /** */
  get MicrometersPerSecondCubed() {
    if (this.micrometerspersecondcubedLazy !== null) {
      return this.micrometerspersecondcubedLazy;
    }
    return this.micrometerspersecondcubedLazy = this.convertFromBase(JerkUnits.MicrometersPerSecondCubed);
  }
  /** */
  get MillimetersPerSecondCubed() {
    if (this.millimeterspersecondcubedLazy !== null) {
      return this.millimeterspersecondcubedLazy;
    }
    return this.millimeterspersecondcubedLazy = this.convertFromBase(JerkUnits.MillimetersPerSecondCubed);
  }
  /** */
  get CentimetersPerSecondCubed() {
    if (this.centimeterspersecondcubedLazy !== null) {
      return this.centimeterspersecondcubedLazy;
    }
    return this.centimeterspersecondcubedLazy = this.convertFromBase(JerkUnits.CentimetersPerSecondCubed);
  }
  /** */
  get DecimetersPerSecondCubed() {
    if (this.decimeterspersecondcubedLazy !== null) {
      return this.decimeterspersecondcubedLazy;
    }
    return this.decimeterspersecondcubedLazy = this.convertFromBase(JerkUnits.DecimetersPerSecondCubed);
  }
  /** */
  get KilometersPerSecondCubed() {
    if (this.kilometerspersecondcubedLazy !== null) {
      return this.kilometerspersecondcubedLazy;
    }
    return this.kilometerspersecondcubedLazy = this.convertFromBase(JerkUnits.KilometersPerSecondCubed);
  }
  /** */
  get MillistandardGravitiesPerSecond() {
    if (this.millistandardgravitiespersecondLazy !== null) {
      return this.millistandardgravitiespersecondLazy;
    }
    return this.millistandardgravitiespersecondLazy = this.convertFromBase(JerkUnits.MillistandardGravitiesPerSecond);
  }
  /**
   * Create a new Jerk instance from a MetersPerSecondCubed
   *
   * @param value The unit as MetersPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromMetersPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.MetersPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a InchesPerSecondCubed
   *
   * @param value The unit as InchesPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromInchesPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.InchesPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a FeetPerSecondCubed
   *
   * @param value The unit as FeetPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromFeetPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.FeetPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a StandardGravitiesPerSecond
   *
   * @param value The unit as StandardGravitiesPerSecond to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromStandardGravitiesPerSecond(value) {
    return new Jerk(value, JerkUnits.StandardGravitiesPerSecond);
  }
  /**
   * Create a new Jerk instance from a NanometersPerSecondCubed
   *
   * @param value The unit as NanometersPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromNanometersPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.NanometersPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a MicrometersPerSecondCubed
   *
   * @param value The unit as MicrometersPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromMicrometersPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.MicrometersPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a MillimetersPerSecondCubed
   *
   * @param value The unit as MillimetersPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromMillimetersPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.MillimetersPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a CentimetersPerSecondCubed
   *
   * @param value The unit as CentimetersPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromCentimetersPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.CentimetersPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a DecimetersPerSecondCubed
   *
   * @param value The unit as DecimetersPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromDecimetersPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.DecimetersPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a KilometersPerSecondCubed
   *
   * @param value The unit as KilometersPerSecondCubed to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromKilometersPerSecondCubed(value) {
    return new Jerk(value, JerkUnits.KilometersPerSecondCubed);
  }
  /**
   * Create a new Jerk instance from a MillistandardGravitiesPerSecond
   *
   * @param value The unit as MillistandardGravitiesPerSecond to create a new Jerk from.
   * @returns The new Jerk instance.
   */
  static FromMillistandardGravitiesPerSecond(value) {
    return new Jerk(value, JerkUnits.MillistandardGravitiesPerSecond);
  }
  /**
   * Gets the base unit enumeration associated with Jerk
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return JerkUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return JerkUnits.MetersPerSecondCubed;
  }
  /**
   * Create API DTO represent a Jerk unit.
   * @param holdInUnit The specific Jerk unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = JerkUnits.MetersPerSecondCubed) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Jerk unit from an API DTO representation.
   * @param dtoJerk The Jerk API DTO representation
   */
  static FromDto(dtoJerk) {
    return new Jerk(dtoJerk.value, dtoJerk.unit);
  }
  /**
   * Convert Jerk to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case JerkUnits.MetersPerSecondCubed:
        return this.MetersPerSecondCubed;
      case JerkUnits.InchesPerSecondCubed:
        return this.InchesPerSecondCubed;
      case JerkUnits.FeetPerSecondCubed:
        return this.FeetPerSecondCubed;
      case JerkUnits.StandardGravitiesPerSecond:
        return this.StandardGravitiesPerSecond;
      case JerkUnits.NanometersPerSecondCubed:
        return this.NanometersPerSecondCubed;
      case JerkUnits.MicrometersPerSecondCubed:
        return this.MicrometersPerSecondCubed;
      case JerkUnits.MillimetersPerSecondCubed:
        return this.MillimetersPerSecondCubed;
      case JerkUnits.CentimetersPerSecondCubed:
        return this.CentimetersPerSecondCubed;
      case JerkUnits.DecimetersPerSecondCubed:
        return this.DecimetersPerSecondCubed;
      case JerkUnits.KilometersPerSecondCubed:
        return this.KilometersPerSecondCubed;
      case JerkUnits.MillistandardGravitiesPerSecond:
        return this.MillistandardGravitiesPerSecond;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case JerkUnits.MetersPerSecondCubed:
        return this.value;
      case JerkUnits.InchesPerSecondCubed:
        return super.internalDivide(this.value, 0.0254);
      case JerkUnits.FeetPerSecondCubed:
        return super.internalDivide(this.value, 0.304800);
      case JerkUnits.StandardGravitiesPerSecond:
        return super.internalDivide(this.value, 9.80665);
      case JerkUnits.NanometersPerSecondCubed:
        return super.internalDivide(this.value, 1e-9);
      case JerkUnits.MicrometersPerSecondCubed:
        return super.internalDivide(this.value, 0.000001);
      case JerkUnits.MillimetersPerSecondCubed:
        return super.internalDivide(this.value, 0.001);
      case JerkUnits.CentimetersPerSecondCubed:
        return super.internalDivide(this.value, 0.01);
      case JerkUnits.DecimetersPerSecondCubed:
        return super.internalDivide(this.value, 0.1);
      case JerkUnits.KilometersPerSecondCubed:
        return super.internalDivide(this.value, 1000);
      case JerkUnits.MillistandardGravitiesPerSecond:
        {
          const v3 = super.internalDivide(this.value, 9.80665);
          return super.internalDivide(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case JerkUnits.MetersPerSecondCubed:
        return this.value;
      case JerkUnits.InchesPerSecondCubed:
        return this.value / 0.0254;
      case JerkUnits.FeetPerSecondCubed:
        return this.value / 0.304800;
      case JerkUnits.StandardGravitiesPerSecond:
        return this.value / 9.80665;
      case JerkUnits.NanometersPerSecondCubed:
        return this.value / 1e-9;
      case JerkUnits.MicrometersPerSecondCubed:
        return this.value / 0.000001;
      case JerkUnits.MillimetersPerSecondCubed:
        return this.value / 0.001;
      case JerkUnits.CentimetersPerSecondCubed:
        return this.value / 0.01;
      case JerkUnits.DecimetersPerSecondCubed:
        return this.value / 0.1;
      case JerkUnits.KilometersPerSecondCubed:
        return this.value / 1000;
      case JerkUnits.MillistandardGravitiesPerSecond:
        return this.value / 9.80665 / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case JerkUnits.MetersPerSecondCubed:
        return value;
      case JerkUnits.InchesPerSecondCubed:
        return super.internalMultiply(value, 0.0254);
      case JerkUnits.FeetPerSecondCubed:
        return super.internalMultiply(value, 0.304800);
      case JerkUnits.StandardGravitiesPerSecond:
        return super.internalMultiply(value, 9.80665);
      case JerkUnits.NanometersPerSecondCubed:
        return super.internalMultiply(value, 1e-9);
      case JerkUnits.MicrometersPerSecondCubed:
        return super.internalMultiply(value, 0.000001);
      case JerkUnits.MillimetersPerSecondCubed:
        return super.internalMultiply(value, 0.001);
      case JerkUnits.CentimetersPerSecondCubed:
        return super.internalMultiply(value, 0.01);
      case JerkUnits.DecimetersPerSecondCubed:
        return super.internalMultiply(value, 0.1);
      case JerkUnits.KilometersPerSecondCubed:
        return super.internalMultiply(value, 1000);
      case JerkUnits.MillistandardGravitiesPerSecond:
        {
          const v3 = super.internalMultiply(value, 9.80665);
          return super.internalMultiply(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case JerkUnits.MetersPerSecondCubed:
        return value;
      case JerkUnits.InchesPerSecondCubed:
        return value * 0.0254;
      case JerkUnits.FeetPerSecondCubed:
        return value * 0.304800;
      case JerkUnits.StandardGravitiesPerSecond:
        return value * 9.80665;
      case JerkUnits.NanometersPerSecondCubed:
        return value * 1e-9;
      case JerkUnits.MicrometersPerSecondCubed:
        return value * 0.000001;
      case JerkUnits.MillimetersPerSecondCubed:
        return value * 0.001;
      case JerkUnits.CentimetersPerSecondCubed:
        return value * 0.01;
      case JerkUnits.DecimetersPerSecondCubed:
        return value * 0.1;
      case JerkUnits.KilometersPerSecondCubed:
        return value * 1000;
      case JerkUnits.MillistandardGravitiesPerSecond:
        return value * 9.80665 * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Jerk to string.
   * Note! the default format for Jerk is MetersPerSecondCubed.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Jerk.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Jerk.
   */
  toString(unit = JerkUnits.MetersPerSecondCubed, fractionalDigits) {
    switch (unit) {
      case JerkUnits.MetersPerSecondCubed:
        return super.truncateFractionDigits(this.MetersPerSecondCubed, fractionalDigits) + ` m/s³`;
      case JerkUnits.InchesPerSecondCubed:
        return super.truncateFractionDigits(this.InchesPerSecondCubed, fractionalDigits) + ` in/s³`;
      case JerkUnits.FeetPerSecondCubed:
        return super.truncateFractionDigits(this.FeetPerSecondCubed, fractionalDigits) + ` ft/s³`;
      case JerkUnits.StandardGravitiesPerSecond:
        return super.truncateFractionDigits(this.StandardGravitiesPerSecond, fractionalDigits) + ` g/s`;
      case JerkUnits.NanometersPerSecondCubed:
        return super.truncateFractionDigits(this.NanometersPerSecondCubed, fractionalDigits) + ` nm/s³`;
      case JerkUnits.MicrometersPerSecondCubed:
        return super.truncateFractionDigits(this.MicrometersPerSecondCubed, fractionalDigits) + ` μm/s³`;
      case JerkUnits.MillimetersPerSecondCubed:
        return super.truncateFractionDigits(this.MillimetersPerSecondCubed, fractionalDigits) + ` mm/s³`;
      case JerkUnits.CentimetersPerSecondCubed:
        return super.truncateFractionDigits(this.CentimetersPerSecondCubed, fractionalDigits) + ` cm/s³`;
      case JerkUnits.DecimetersPerSecondCubed:
        return super.truncateFractionDigits(this.DecimetersPerSecondCubed, fractionalDigits) + ` dm/s³`;
      case JerkUnits.KilometersPerSecondCubed:
        return super.truncateFractionDigits(this.KilometersPerSecondCubed, fractionalDigits) + ` km/s³`;
      case JerkUnits.MillistandardGravitiesPerSecond:
        return super.truncateFractionDigits(this.MillistandardGravitiesPerSecond, fractionalDigits) + ` mg/s`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Jerk unit abbreviation.
   * Note! the default abbreviation for Jerk is MetersPerSecondCubed.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Jerk.
   * @returns The abbreviation string of Jerk.
   */
  getUnitAbbreviation(unitAbbreviation = JerkUnits.MetersPerSecondCubed) {
    switch (unitAbbreviation) {
      case JerkUnits.MetersPerSecondCubed:
        return `m/s³`;
      case JerkUnits.InchesPerSecondCubed:
        return `in/s³`;
      case JerkUnits.FeetPerSecondCubed:
        return `ft/s³`;
      case JerkUnits.StandardGravitiesPerSecond:
        return `g/s`;
      case JerkUnits.NanometersPerSecondCubed:
        return `nm/s³`;
      case JerkUnits.MicrometersPerSecondCubed:
        return `μm/s³`;
      case JerkUnits.MillimetersPerSecondCubed:
        return `mm/s³`;
      case JerkUnits.CentimetersPerSecondCubed:
        return `cm/s³`;
      case JerkUnits.DecimetersPerSecondCubed:
        return `dm/s³`;
      case JerkUnits.KilometersPerSecondCubed:
        return `km/s³`;
      case JerkUnits.MillistandardGravitiesPerSecond:
        return `mg/s`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Jerk are equals to the current Jerk.
   * @param jerk The other Jerk.
   * @returns True if the given Jerk are equal to the current Jerk.
   */
  equals(jerk) {
    return super.internalEquals(this.value, jerk.BaseValue);
  }
  /**
   * Compare the given Jerk against the current Jerk.
   * @param jerk The other Jerk.
   * @returns 0 if they are equal, -1 if the current Jerk is less then other, 1 if the current Jerk is greater then other.
   */
  compareTo(jerk) {
    return super.internalCompareTo(this.value, jerk.BaseValue);
  }
  /**
   * Add the given Jerk with the current Jerk.
   * @param jerk The other Jerk.
   * @returns A new Jerk instance with the results.
   */
  add(jerk) {
    return new Jerk(super.internalAdd(this.value, jerk.BaseValue));
  }
  /**
   * Subtract the given Jerk with the current Jerk.
   * @param jerk The other Jerk.
   * @returns A new Jerk instance with the results.
   */
  subtract(jerk) {
    return new Jerk(super.internalSubtract(this.value, jerk.BaseValue));
  }
  /**
   * Multiply the given Jerk with the current Jerk.
   * @param jerk The other Jerk.
   * @returns A new Jerk instance with the results.
   */
  multiply(jerk) {
    return new Jerk(super.internalMultiply(this.value, jerk.BaseValue));
  }
  /**
   * Divide the given Jerk with the current Jerk.
   * @param jerk The other Jerk.
   * @returns A new Jerk instance with the results.
   */
  divide(jerk) {
    return new Jerk(super.internalDivide(this.value, jerk.BaseValue));
  }
  /**
   * Modulo the given Jerk with the current Jerk.
   * @param jerk The other Jerk.
   * @returns A new Jerk instance with the results.
   */
  modulo(jerk) {
    return new Jerk(super.internalModulo(this.value, jerk.BaseValue));
  }
  /**
   * Pow the given Jerk with the current Jerk.
   * @param jerk The other Jerk.
   * @returns A new Jerk instance with the results.
   */
  pow(jerk) {
    return new Jerk(super.internalPow(this.value, jerk.BaseValue));
  }
}
exports.Jerk = Jerk;