"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Impulse = exports.ImpulseUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ImpulseUnits enumeration */
var ImpulseUnits;
(function (ImpulseUnits) {
  /** */
  ImpulseUnits["KilogramMetersPerSecond"] = "KilogramMeterPerSecond";
  /** */
  ImpulseUnits["NewtonSeconds"] = "NewtonSecond";
  /** */
  ImpulseUnits["PoundFeetPerSecond"] = "PoundFootPerSecond";
  /** */
  ImpulseUnits["PoundForceSeconds"] = "PoundForceSecond";
  /** */
  ImpulseUnits["SlugFeetPerSecond"] = "SlugFootPerSecond";
  /** */
  ImpulseUnits["NanonewtonSeconds"] = "NanonewtonSecond";
  /** */
  ImpulseUnits["MicronewtonSeconds"] = "MicronewtonSecond";
  /** */
  ImpulseUnits["MillinewtonSeconds"] = "MillinewtonSecond";
  /** */
  ImpulseUnits["CentinewtonSeconds"] = "CentinewtonSecond";
  /** */
  ImpulseUnits["DecinewtonSeconds"] = "DecinewtonSecond";
  /** */
  ImpulseUnits["DecanewtonSeconds"] = "DecanewtonSecond";
  /** */
  ImpulseUnits["KilonewtonSeconds"] = "KilonewtonSecond";
  /** */
  ImpulseUnits["MeganewtonSeconds"] = "MeganewtonSecond";
})(ImpulseUnits = exports.ImpulseUnits || (exports.ImpulseUnits = {}));
/** In classical mechanics, impulse is the integral of a force, F, over the time interval, t, for which it acts. Impulse applied to an object produces an equivalent vector change in its linear momentum, also in the resultant direction. */
class Impulse extends base_unit_1.BaseUnit {
  /**
   * Create a new Impulse.
   * @param value The value.
   * @param fromUnit The ‘Impulse’ unit to create from.
   * The default unit is NewtonSeconds
   */
  constructor(value, fromUnit = ImpulseUnits.NewtonSeconds) {
    super();
    this.kilogrammeterspersecondLazy = null;
    this.newtonsecondsLazy = null;
    this.poundfeetpersecondLazy = null;
    this.poundforcesecondsLazy = null;
    this.slugfeetpersecondLazy = null;
    this.nanonewtonsecondsLazy = null;
    this.micronewtonsecondsLazy = null;
    this.millinewtonsecondsLazy = null;
    this.centinewtonsecondsLazy = null;
    this.decinewtonsecondsLazy = null;
    this.decanewtonsecondsLazy = null;
    this.kilonewtonsecondsLazy = null;
    this.meganewtonsecondsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Impulse is NewtonSeconds.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ImpulseUnits.NewtonSeconds;
  }
  /** */
  get KilogramMetersPerSecond() {
    if (this.kilogrammeterspersecondLazy !== null) {
      return this.kilogrammeterspersecondLazy;
    }
    return this.kilogrammeterspersecondLazy = this.convertFromBase(ImpulseUnits.KilogramMetersPerSecond);
  }
  /** */
  get NewtonSeconds() {
    if (this.newtonsecondsLazy !== null) {
      return this.newtonsecondsLazy;
    }
    return this.newtonsecondsLazy = this.convertFromBase(ImpulseUnits.NewtonSeconds);
  }
  /** */
  get PoundFeetPerSecond() {
    if (this.poundfeetpersecondLazy !== null) {
      return this.poundfeetpersecondLazy;
    }
    return this.poundfeetpersecondLazy = this.convertFromBase(ImpulseUnits.PoundFeetPerSecond);
  }
  /** */
  get PoundForceSeconds() {
    if (this.poundforcesecondsLazy !== null) {
      return this.poundforcesecondsLazy;
    }
    return this.poundforcesecondsLazy = this.convertFromBase(ImpulseUnits.PoundForceSeconds);
  }
  /** */
  get SlugFeetPerSecond() {
    if (this.slugfeetpersecondLazy !== null) {
      return this.slugfeetpersecondLazy;
    }
    return this.slugfeetpersecondLazy = this.convertFromBase(ImpulseUnits.SlugFeetPerSecond);
  }
  /** */
  get NanonewtonSeconds() {
    if (this.nanonewtonsecondsLazy !== null) {
      return this.nanonewtonsecondsLazy;
    }
    return this.nanonewtonsecondsLazy = this.convertFromBase(ImpulseUnits.NanonewtonSeconds);
  }
  /** */
  get MicronewtonSeconds() {
    if (this.micronewtonsecondsLazy !== null) {
      return this.micronewtonsecondsLazy;
    }
    return this.micronewtonsecondsLazy = this.convertFromBase(ImpulseUnits.MicronewtonSeconds);
  }
  /** */
  get MillinewtonSeconds() {
    if (this.millinewtonsecondsLazy !== null) {
      return this.millinewtonsecondsLazy;
    }
    return this.millinewtonsecondsLazy = this.convertFromBase(ImpulseUnits.MillinewtonSeconds);
  }
  /** */
  get CentinewtonSeconds() {
    if (this.centinewtonsecondsLazy !== null) {
      return this.centinewtonsecondsLazy;
    }
    return this.centinewtonsecondsLazy = this.convertFromBase(ImpulseUnits.CentinewtonSeconds);
  }
  /** */
  get DecinewtonSeconds() {
    if (this.decinewtonsecondsLazy !== null) {
      return this.decinewtonsecondsLazy;
    }
    return this.decinewtonsecondsLazy = this.convertFromBase(ImpulseUnits.DecinewtonSeconds);
  }
  /** */
  get DecanewtonSeconds() {
    if (this.decanewtonsecondsLazy !== null) {
      return this.decanewtonsecondsLazy;
    }
    return this.decanewtonsecondsLazy = this.convertFromBase(ImpulseUnits.DecanewtonSeconds);
  }
  /** */
  get KilonewtonSeconds() {
    if (this.kilonewtonsecondsLazy !== null) {
      return this.kilonewtonsecondsLazy;
    }
    return this.kilonewtonsecondsLazy = this.convertFromBase(ImpulseUnits.KilonewtonSeconds);
  }
  /** */
  get MeganewtonSeconds() {
    if (this.meganewtonsecondsLazy !== null) {
      return this.meganewtonsecondsLazy;
    }
    return this.meganewtonsecondsLazy = this.convertFromBase(ImpulseUnits.MeganewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a KilogramMetersPerSecond
   *
   * @param value The unit as KilogramMetersPerSecond to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromKilogramMetersPerSecond(value) {
    return new Impulse(value, ImpulseUnits.KilogramMetersPerSecond);
  }
  /**
   * Create a new Impulse instance from a NewtonSeconds
   *
   * @param value The unit as NewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromNewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.NewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a PoundFeetPerSecond
   *
   * @param value The unit as PoundFeetPerSecond to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromPoundFeetPerSecond(value) {
    return new Impulse(value, ImpulseUnits.PoundFeetPerSecond);
  }
  /**
   * Create a new Impulse instance from a PoundForceSeconds
   *
   * @param value The unit as PoundForceSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromPoundForceSeconds(value) {
    return new Impulse(value, ImpulseUnits.PoundForceSeconds);
  }
  /**
   * Create a new Impulse instance from a SlugFeetPerSecond
   *
   * @param value The unit as SlugFeetPerSecond to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromSlugFeetPerSecond(value) {
    return new Impulse(value, ImpulseUnits.SlugFeetPerSecond);
  }
  /**
   * Create a new Impulse instance from a NanonewtonSeconds
   *
   * @param value The unit as NanonewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromNanonewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.NanonewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a MicronewtonSeconds
   *
   * @param value The unit as MicronewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromMicronewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.MicronewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a MillinewtonSeconds
   *
   * @param value The unit as MillinewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromMillinewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.MillinewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a CentinewtonSeconds
   *
   * @param value The unit as CentinewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromCentinewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.CentinewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a DecinewtonSeconds
   *
   * @param value The unit as DecinewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromDecinewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.DecinewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a DecanewtonSeconds
   *
   * @param value The unit as DecanewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromDecanewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.DecanewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a KilonewtonSeconds
   *
   * @param value The unit as KilonewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromKilonewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.KilonewtonSeconds);
  }
  /**
   * Create a new Impulse instance from a MeganewtonSeconds
   *
   * @param value The unit as MeganewtonSeconds to create a new Impulse from.
   * @returns The new Impulse instance.
   */
  static FromMeganewtonSeconds(value) {
    return new Impulse(value, ImpulseUnits.MeganewtonSeconds);
  }
  /**
   * Gets the base unit enumeration associated with Impulse
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ImpulseUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ImpulseUnits.NewtonSeconds;
  }
  /**
   * Create API DTO represent a Impulse unit.
   * @param holdInUnit The specific Impulse unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ImpulseUnits.NewtonSeconds) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Impulse unit from an API DTO representation.
   * @param dtoImpulse The Impulse API DTO representation
   */
  static FromDto(dtoImpulse) {
    return new Impulse(dtoImpulse.value, dtoImpulse.unit);
  }
  /**
   * Convert Impulse to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ImpulseUnits.KilogramMetersPerSecond:
        return this.KilogramMetersPerSecond;
      case ImpulseUnits.NewtonSeconds:
        return this.NewtonSeconds;
      case ImpulseUnits.PoundFeetPerSecond:
        return this.PoundFeetPerSecond;
      case ImpulseUnits.PoundForceSeconds:
        return this.PoundForceSeconds;
      case ImpulseUnits.SlugFeetPerSecond:
        return this.SlugFeetPerSecond;
      case ImpulseUnits.NanonewtonSeconds:
        return this.NanonewtonSeconds;
      case ImpulseUnits.MicronewtonSeconds:
        return this.MicronewtonSeconds;
      case ImpulseUnits.MillinewtonSeconds:
        return this.MillinewtonSeconds;
      case ImpulseUnits.CentinewtonSeconds:
        return this.CentinewtonSeconds;
      case ImpulseUnits.DecinewtonSeconds:
        return this.DecinewtonSeconds;
      case ImpulseUnits.DecanewtonSeconds:
        return this.DecanewtonSeconds;
      case ImpulseUnits.KilonewtonSeconds:
        return this.KilonewtonSeconds;
      case ImpulseUnits.MeganewtonSeconds:
        return this.MeganewtonSeconds;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ImpulseUnits.KilogramMetersPerSecond:
        return this.value;
      case ImpulseUnits.NewtonSeconds:
        return this.value;
      case ImpulseUnits.PoundFeetPerSecond:
        return super.internalMultiply(this.value, 7.230657989877);
      case ImpulseUnits.PoundForceSeconds:
        return super.internalMultiply(this.value, 0.2248089430997);
      case ImpulseUnits.SlugFeetPerSecond:
        return super.internalMultiply(this.value, 0.224735720691);
      case ImpulseUnits.NanonewtonSeconds:
        return super.internalDivide(this.value, 1e-9);
      case ImpulseUnits.MicronewtonSeconds:
        return super.internalDivide(this.value, 0.000001);
      case ImpulseUnits.MillinewtonSeconds:
        return super.internalDivide(this.value, 0.001);
      case ImpulseUnits.CentinewtonSeconds:
        return super.internalDivide(this.value, 0.01);
      case ImpulseUnits.DecinewtonSeconds:
        return super.internalDivide(this.value, 0.1);
      case ImpulseUnits.DecanewtonSeconds:
        return super.internalDivide(this.value, 10);
      case ImpulseUnits.KilonewtonSeconds:
        return super.internalDivide(this.value, 1000);
      case ImpulseUnits.MeganewtonSeconds:
        return super.internalDivide(this.value, 1000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ImpulseUnits.KilogramMetersPerSecond:
        return this.value;
      case ImpulseUnits.NewtonSeconds:
        return this.value;
      case ImpulseUnits.PoundFeetPerSecond:
        return this.value * 7.230657989877;
      case ImpulseUnits.PoundForceSeconds:
        return this.value * 0.2248089430997;
      case ImpulseUnits.SlugFeetPerSecond:
        return this.value * 0.224735720691;
      case ImpulseUnits.NanonewtonSeconds:
        return this.value / 1e-9;
      case ImpulseUnits.MicronewtonSeconds:
        return this.value / 0.000001;
      case ImpulseUnits.MillinewtonSeconds:
        return this.value / 0.001;
      case ImpulseUnits.CentinewtonSeconds:
        return this.value / 0.01;
      case ImpulseUnits.DecinewtonSeconds:
        return this.value / 0.1;
      case ImpulseUnits.DecanewtonSeconds:
        return this.value / 10;
      case ImpulseUnits.KilonewtonSeconds:
        return this.value / 1000;
      case ImpulseUnits.MeganewtonSeconds:
        return this.value / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ImpulseUnits.KilogramMetersPerSecond:
        return value;
      case ImpulseUnits.NewtonSeconds:
        return value;
      case ImpulseUnits.PoundFeetPerSecond:
        return super.internalDivide(value, 7.230657989877);
      case ImpulseUnits.PoundForceSeconds:
        return super.internalDivide(value, 0.2248089430997);
      case ImpulseUnits.SlugFeetPerSecond:
        return super.internalDivide(value, 0.224735720691);
      case ImpulseUnits.NanonewtonSeconds:
        return super.internalMultiply(value, 1e-9);
      case ImpulseUnits.MicronewtonSeconds:
        return super.internalMultiply(value, 0.000001);
      case ImpulseUnits.MillinewtonSeconds:
        return super.internalMultiply(value, 0.001);
      case ImpulseUnits.CentinewtonSeconds:
        return super.internalMultiply(value, 0.01);
      case ImpulseUnits.DecinewtonSeconds:
        return super.internalMultiply(value, 0.1);
      case ImpulseUnits.DecanewtonSeconds:
        return super.internalMultiply(value, 10);
      case ImpulseUnits.KilonewtonSeconds:
        return super.internalMultiply(value, 1000);
      case ImpulseUnits.MeganewtonSeconds:
        return super.internalMultiply(value, 1000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ImpulseUnits.KilogramMetersPerSecond:
        return value;
      case ImpulseUnits.NewtonSeconds:
        return value;
      case ImpulseUnits.PoundFeetPerSecond:
        return value / 7.230657989877;
      case ImpulseUnits.PoundForceSeconds:
        return value / 0.2248089430997;
      case ImpulseUnits.SlugFeetPerSecond:
        return value / 0.224735720691;
      case ImpulseUnits.NanonewtonSeconds:
        return value * 1e-9;
      case ImpulseUnits.MicronewtonSeconds:
        return value * 0.000001;
      case ImpulseUnits.MillinewtonSeconds:
        return value * 0.001;
      case ImpulseUnits.CentinewtonSeconds:
        return value * 0.01;
      case ImpulseUnits.DecinewtonSeconds:
        return value * 0.1;
      case ImpulseUnits.DecanewtonSeconds:
        return value * 10;
      case ImpulseUnits.KilonewtonSeconds:
        return value * 1000;
      case ImpulseUnits.MeganewtonSeconds:
        return value * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Impulse to string.
   * Note! the default format for Impulse is NewtonSeconds.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Impulse.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Impulse.
   */
  toString(unit = ImpulseUnits.NewtonSeconds, fractionalDigits) {
    switch (unit) {
      case ImpulseUnits.KilogramMetersPerSecond:
        return super.truncateFractionDigits(this.KilogramMetersPerSecond, fractionalDigits) + ` kg·m/s`;
      case ImpulseUnits.NewtonSeconds:
        return super.truncateFractionDigits(this.NewtonSeconds, fractionalDigits) + ` N·s`;
      case ImpulseUnits.PoundFeetPerSecond:
        return super.truncateFractionDigits(this.PoundFeetPerSecond, fractionalDigits) + ` lb·ft/s`;
      case ImpulseUnits.PoundForceSeconds:
        return super.truncateFractionDigits(this.PoundForceSeconds, fractionalDigits) + ` lbf·s`;
      case ImpulseUnits.SlugFeetPerSecond:
        return super.truncateFractionDigits(this.SlugFeetPerSecond, fractionalDigits) + ` slug·ft/s`;
      case ImpulseUnits.NanonewtonSeconds:
        return super.truncateFractionDigits(this.NanonewtonSeconds, fractionalDigits) + ` nN·s`;
      case ImpulseUnits.MicronewtonSeconds:
        return super.truncateFractionDigits(this.MicronewtonSeconds, fractionalDigits) + ` μN·s`;
      case ImpulseUnits.MillinewtonSeconds:
        return super.truncateFractionDigits(this.MillinewtonSeconds, fractionalDigits) + ` mN·s`;
      case ImpulseUnits.CentinewtonSeconds:
        return super.truncateFractionDigits(this.CentinewtonSeconds, fractionalDigits) + ` cN·s`;
      case ImpulseUnits.DecinewtonSeconds:
        return super.truncateFractionDigits(this.DecinewtonSeconds, fractionalDigits) + ` dN·s`;
      case ImpulseUnits.DecanewtonSeconds:
        return super.truncateFractionDigits(this.DecanewtonSeconds, fractionalDigits) + ` daN·s`;
      case ImpulseUnits.KilonewtonSeconds:
        return super.truncateFractionDigits(this.KilonewtonSeconds, fractionalDigits) + ` kN·s`;
      case ImpulseUnits.MeganewtonSeconds:
        return super.truncateFractionDigits(this.MeganewtonSeconds, fractionalDigits) + ` MN·s`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Impulse unit abbreviation.
   * Note! the default abbreviation for Impulse is NewtonSeconds.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Impulse.
   * @returns The abbreviation string of Impulse.
   */
  getUnitAbbreviation(unitAbbreviation = ImpulseUnits.NewtonSeconds) {
    switch (unitAbbreviation) {
      case ImpulseUnits.KilogramMetersPerSecond:
        return `kg·m/s`;
      case ImpulseUnits.NewtonSeconds:
        return `N·s`;
      case ImpulseUnits.PoundFeetPerSecond:
        return `lb·ft/s`;
      case ImpulseUnits.PoundForceSeconds:
        return `lbf·s`;
      case ImpulseUnits.SlugFeetPerSecond:
        return `slug·ft/s`;
      case ImpulseUnits.NanonewtonSeconds:
        return `nN·s`;
      case ImpulseUnits.MicronewtonSeconds:
        return `μN·s`;
      case ImpulseUnits.MillinewtonSeconds:
        return `mN·s`;
      case ImpulseUnits.CentinewtonSeconds:
        return `cN·s`;
      case ImpulseUnits.DecinewtonSeconds:
        return `dN·s`;
      case ImpulseUnits.DecanewtonSeconds:
        return `daN·s`;
      case ImpulseUnits.KilonewtonSeconds:
        return `kN·s`;
      case ImpulseUnits.MeganewtonSeconds:
        return `MN·s`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Impulse are equals to the current Impulse.
   * @param impulse The other Impulse.
   * @returns True if the given Impulse are equal to the current Impulse.
   */
  equals(impulse) {
    return super.internalEquals(this.value, impulse.BaseValue);
  }
  /**
   * Compare the given Impulse against the current Impulse.
   * @param impulse The other Impulse.
   * @returns 0 if they are equal, -1 if the current Impulse is less then other, 1 if the current Impulse is greater then other.
   */
  compareTo(impulse) {
    return super.internalCompareTo(this.value, impulse.BaseValue);
  }
  /**
   * Add the given Impulse with the current Impulse.
   * @param impulse The other Impulse.
   * @returns A new Impulse instance with the results.
   */
  add(impulse) {
    return new Impulse(super.internalAdd(this.value, impulse.BaseValue));
  }
  /**
   * Subtract the given Impulse with the current Impulse.
   * @param impulse The other Impulse.
   * @returns A new Impulse instance with the results.
   */
  subtract(impulse) {
    return new Impulse(super.internalSubtract(this.value, impulse.BaseValue));
  }
  /**
   * Multiply the given Impulse with the current Impulse.
   * @param impulse The other Impulse.
   * @returns A new Impulse instance with the results.
   */
  multiply(impulse) {
    return new Impulse(super.internalMultiply(this.value, impulse.BaseValue));
  }
  /**
   * Divide the given Impulse with the current Impulse.
   * @param impulse The other Impulse.
   * @returns A new Impulse instance with the results.
   */
  divide(impulse) {
    return new Impulse(super.internalDivide(this.value, impulse.BaseValue));
  }
  /**
   * Modulo the given Impulse with the current Impulse.
   * @param impulse The other Impulse.
   * @returns A new Impulse instance with the results.
   */
  modulo(impulse) {
    return new Impulse(super.internalModulo(this.value, impulse.BaseValue));
  }
  /**
   * Pow the given Impulse with the current Impulse.
   * @param impulse The other Impulse.
   * @returns A new Impulse instance with the results.
   */
  pow(impulse) {
    return new Impulse(super.internalPow(this.value, impulse.BaseValue));
  }
}
exports.Impulse = Impulse;