"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricPotentialChangeRate = exports.ElectricPotentialChangeRateUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricPotentialChangeRateUnits enumeration */
var ElectricPotentialChangeRateUnits;
(function (ElectricPotentialChangeRateUnits) {
  /** */
  ElectricPotentialChangeRateUnits["VoltsPerSeconds"] = "VoltPerSecond";
  /** */
  ElectricPotentialChangeRateUnits["VoltsPerMicroseconds"] = "VoltPerMicrosecond";
  /** */
  ElectricPotentialChangeRateUnits["VoltsPerMinutes"] = "VoltPerMinute";
  /** */
  ElectricPotentialChangeRateUnits["VoltsPerHours"] = "VoltPerHour";
  /** */
  ElectricPotentialChangeRateUnits["MicrovoltsPerSeconds"] = "MicrovoltPerSecond";
  /** */
  ElectricPotentialChangeRateUnits["MillivoltsPerSeconds"] = "MillivoltPerSecond";
  /** */
  ElectricPotentialChangeRateUnits["KilovoltsPerSeconds"] = "KilovoltPerSecond";
  /** */
  ElectricPotentialChangeRateUnits["MegavoltsPerSeconds"] = "MegavoltPerSecond";
  /** */
  ElectricPotentialChangeRateUnits["MicrovoltsPerMicroseconds"] = "MicrovoltPerMicrosecond";
  /** */
  ElectricPotentialChangeRateUnits["MillivoltsPerMicroseconds"] = "MillivoltPerMicrosecond";
  /** */
  ElectricPotentialChangeRateUnits["KilovoltsPerMicroseconds"] = "KilovoltPerMicrosecond";
  /** */
  ElectricPotentialChangeRateUnits["MegavoltsPerMicroseconds"] = "MegavoltPerMicrosecond";
  /** */
  ElectricPotentialChangeRateUnits["MicrovoltsPerMinutes"] = "MicrovoltPerMinute";
  /** */
  ElectricPotentialChangeRateUnits["MillivoltsPerMinutes"] = "MillivoltPerMinute";
  /** */
  ElectricPotentialChangeRateUnits["KilovoltsPerMinutes"] = "KilovoltPerMinute";
  /** */
  ElectricPotentialChangeRateUnits["MegavoltsPerMinutes"] = "MegavoltPerMinute";
  /** */
  ElectricPotentialChangeRateUnits["MicrovoltsPerHours"] = "MicrovoltPerHour";
  /** */
  ElectricPotentialChangeRateUnits["MillivoltsPerHours"] = "MillivoltPerHour";
  /** */
  ElectricPotentialChangeRateUnits["KilovoltsPerHours"] = "KilovoltPerHour";
  /** */
  ElectricPotentialChangeRateUnits["MegavoltsPerHours"] = "MegavoltPerHour";
})(ElectricPotentialChangeRateUnits = exports.ElectricPotentialChangeRateUnits || (exports.ElectricPotentialChangeRateUnits = {}));
/** ElectricPotential change rate is the ratio of the electric potential change to the time during which the change occurred (value of electric potential changes per unit time). */
class ElectricPotentialChangeRate extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricPotentialChangeRate.
   * @param value The value.
   * @param fromUnit The ‘ElectricPotentialChangeRate’ unit to create from.
   * The default unit is VoltsPerSeconds
   */
  constructor(value, fromUnit = ElectricPotentialChangeRateUnits.VoltsPerSeconds) {
    super();
    this.voltspersecondsLazy = null;
    this.voltspermicrosecondsLazy = null;
    this.voltsperminutesLazy = null;
    this.voltsperhoursLazy = null;
    this.microvoltspersecondsLazy = null;
    this.millivoltspersecondsLazy = null;
    this.kilovoltspersecondsLazy = null;
    this.megavoltspersecondsLazy = null;
    this.microvoltspermicrosecondsLazy = null;
    this.millivoltspermicrosecondsLazy = null;
    this.kilovoltspermicrosecondsLazy = null;
    this.megavoltspermicrosecondsLazy = null;
    this.microvoltsperminutesLazy = null;
    this.millivoltsperminutesLazy = null;
    this.kilovoltsperminutesLazy = null;
    this.megavoltsperminutesLazy = null;
    this.microvoltsperhoursLazy = null;
    this.millivoltsperhoursLazy = null;
    this.kilovoltsperhoursLazy = null;
    this.megavoltsperhoursLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricPotentialChangeRate is VoltsPerSeconds.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricPotentialChangeRateUnits.VoltsPerSeconds;
  }
  /** */
  get VoltsPerSeconds() {
    if (this.voltspersecondsLazy !== null) {
      return this.voltspersecondsLazy;
    }
    return this.voltspersecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.VoltsPerSeconds);
  }
  /** */
  get VoltsPerMicroseconds() {
    if (this.voltspermicrosecondsLazy !== null) {
      return this.voltspermicrosecondsLazy;
    }
    return this.voltspermicrosecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.VoltsPerMicroseconds);
  }
  /** */
  get VoltsPerMinutes() {
    if (this.voltsperminutesLazy !== null) {
      return this.voltsperminutesLazy;
    }
    return this.voltsperminutesLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.VoltsPerMinutes);
  }
  /** */
  get VoltsPerHours() {
    if (this.voltsperhoursLazy !== null) {
      return this.voltsperhoursLazy;
    }
    return this.voltsperhoursLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.VoltsPerHours);
  }
  /** */
  get MicrovoltsPerSeconds() {
    if (this.microvoltspersecondsLazy !== null) {
      return this.microvoltspersecondsLazy;
    }
    return this.microvoltspersecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds);
  }
  /** */
  get MillivoltsPerSeconds() {
    if (this.millivoltspersecondsLazy !== null) {
      return this.millivoltspersecondsLazy;
    }
    return this.millivoltspersecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MillivoltsPerSeconds);
  }
  /** */
  get KilovoltsPerSeconds() {
    if (this.kilovoltspersecondsLazy !== null) {
      return this.kilovoltspersecondsLazy;
    }
    return this.kilovoltspersecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.KilovoltsPerSeconds);
  }
  /** */
  get MegavoltsPerSeconds() {
    if (this.megavoltspersecondsLazy !== null) {
      return this.megavoltspersecondsLazy;
    }
    return this.megavoltspersecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MegavoltsPerSeconds);
  }
  /** */
  get MicrovoltsPerMicroseconds() {
    if (this.microvoltspermicrosecondsLazy !== null) {
      return this.microvoltspermicrosecondsLazy;
    }
    return this.microvoltspermicrosecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds);
  }
  /** */
  get MillivoltsPerMicroseconds() {
    if (this.millivoltspermicrosecondsLazy !== null) {
      return this.millivoltspermicrosecondsLazy;
    }
    return this.millivoltspermicrosecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds);
  }
  /** */
  get KilovoltsPerMicroseconds() {
    if (this.kilovoltspermicrosecondsLazy !== null) {
      return this.kilovoltspermicrosecondsLazy;
    }
    return this.kilovoltspermicrosecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds);
  }
  /** */
  get MegavoltsPerMicroseconds() {
    if (this.megavoltspermicrosecondsLazy !== null) {
      return this.megavoltspermicrosecondsLazy;
    }
    return this.megavoltspermicrosecondsLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds);
  }
  /** */
  get MicrovoltsPerMinutes() {
    if (this.microvoltsperminutesLazy !== null) {
      return this.microvoltsperminutesLazy;
    }
    return this.microvoltsperminutesLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes);
  }
  /** */
  get MillivoltsPerMinutes() {
    if (this.millivoltsperminutesLazy !== null) {
      return this.millivoltsperminutesLazy;
    }
    return this.millivoltsperminutesLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MillivoltsPerMinutes);
  }
  /** */
  get KilovoltsPerMinutes() {
    if (this.kilovoltsperminutesLazy !== null) {
      return this.kilovoltsperminutesLazy;
    }
    return this.kilovoltsperminutesLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.KilovoltsPerMinutes);
  }
  /** */
  get MegavoltsPerMinutes() {
    if (this.megavoltsperminutesLazy !== null) {
      return this.megavoltsperminutesLazy;
    }
    return this.megavoltsperminutesLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MegavoltsPerMinutes);
  }
  /** */
  get MicrovoltsPerHours() {
    if (this.microvoltsperhoursLazy !== null) {
      return this.microvoltsperhoursLazy;
    }
    return this.microvoltsperhoursLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MicrovoltsPerHours);
  }
  /** */
  get MillivoltsPerHours() {
    if (this.millivoltsperhoursLazy !== null) {
      return this.millivoltsperhoursLazy;
    }
    return this.millivoltsperhoursLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MillivoltsPerHours);
  }
  /** */
  get KilovoltsPerHours() {
    if (this.kilovoltsperhoursLazy !== null) {
      return this.kilovoltsperhoursLazy;
    }
    return this.kilovoltsperhoursLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.KilovoltsPerHours);
  }
  /** */
  get MegavoltsPerHours() {
    if (this.megavoltsperhoursLazy !== null) {
      return this.megavoltsperhoursLazy;
    }
    return this.megavoltsperhoursLazy = this.convertFromBase(ElectricPotentialChangeRateUnits.MegavoltsPerHours);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a VoltsPerSeconds
   *
   * @param value The unit as VoltsPerSeconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromVoltsPerSeconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.VoltsPerSeconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a VoltsPerMicroseconds
   *
   * @param value The unit as VoltsPerMicroseconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromVoltsPerMicroseconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.VoltsPerMicroseconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a VoltsPerMinutes
   *
   * @param value The unit as VoltsPerMinutes to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromVoltsPerMinutes(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.VoltsPerMinutes);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a VoltsPerHours
   *
   * @param value The unit as VoltsPerHours to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromVoltsPerHours(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.VoltsPerHours);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MicrovoltsPerSeconds
   *
   * @param value The unit as MicrovoltsPerSeconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMicrovoltsPerSeconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MillivoltsPerSeconds
   *
   * @param value The unit as MillivoltsPerSeconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMillivoltsPerSeconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MillivoltsPerSeconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a KilovoltsPerSeconds
   *
   * @param value The unit as KilovoltsPerSeconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromKilovoltsPerSeconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.KilovoltsPerSeconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MegavoltsPerSeconds
   *
   * @param value The unit as MegavoltsPerSeconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMegavoltsPerSeconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MegavoltsPerSeconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MicrovoltsPerMicroseconds
   *
   * @param value The unit as MicrovoltsPerMicroseconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMicrovoltsPerMicroseconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MillivoltsPerMicroseconds
   *
   * @param value The unit as MillivoltsPerMicroseconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMillivoltsPerMicroseconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a KilovoltsPerMicroseconds
   *
   * @param value The unit as KilovoltsPerMicroseconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromKilovoltsPerMicroseconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MegavoltsPerMicroseconds
   *
   * @param value The unit as MegavoltsPerMicroseconds to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMegavoltsPerMicroseconds(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MicrovoltsPerMinutes
   *
   * @param value The unit as MicrovoltsPerMinutes to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMicrovoltsPerMinutes(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MillivoltsPerMinutes
   *
   * @param value The unit as MillivoltsPerMinutes to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMillivoltsPerMinutes(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MillivoltsPerMinutes);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a KilovoltsPerMinutes
   *
   * @param value The unit as KilovoltsPerMinutes to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromKilovoltsPerMinutes(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.KilovoltsPerMinutes);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MegavoltsPerMinutes
   *
   * @param value The unit as MegavoltsPerMinutes to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMegavoltsPerMinutes(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MegavoltsPerMinutes);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MicrovoltsPerHours
   *
   * @param value The unit as MicrovoltsPerHours to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMicrovoltsPerHours(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MicrovoltsPerHours);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MillivoltsPerHours
   *
   * @param value The unit as MillivoltsPerHours to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMillivoltsPerHours(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MillivoltsPerHours);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a KilovoltsPerHours
   *
   * @param value The unit as KilovoltsPerHours to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromKilovoltsPerHours(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.KilovoltsPerHours);
  }
  /**
   * Create a new ElectricPotentialChangeRate instance from a MegavoltsPerHours
   *
   * @param value The unit as MegavoltsPerHours to create a new ElectricPotentialChangeRate from.
   * @returns The new ElectricPotentialChangeRate instance.
   */
  static FromMegavoltsPerHours(value) {
    return new ElectricPotentialChangeRate(value, ElectricPotentialChangeRateUnits.MegavoltsPerHours);
  }
  /**
   * Gets the base unit enumeration associated with ElectricPotentialChangeRate
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricPotentialChangeRateUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricPotentialChangeRateUnits.VoltsPerSeconds;
  }
  /**
   * Create API DTO represent a ElectricPotentialChangeRate unit.
   * @param holdInUnit The specific ElectricPotentialChangeRate unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricPotentialChangeRateUnits.VoltsPerSeconds) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricPotentialChangeRate unit from an API DTO representation.
   * @param dtoElectricPotentialChangeRate The ElectricPotentialChangeRate API DTO representation
   */
  static FromDto(dtoElectricPotentialChangeRate) {
    return new ElectricPotentialChangeRate(dtoElectricPotentialChangeRate.value, dtoElectricPotentialChangeRate.unit);
  }
  /**
   * Convert ElectricPotentialChangeRate to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricPotentialChangeRateUnits.VoltsPerSeconds:
        return this.VoltsPerSeconds;
      case ElectricPotentialChangeRateUnits.VoltsPerMicroseconds:
        return this.VoltsPerMicroseconds;
      case ElectricPotentialChangeRateUnits.VoltsPerMinutes:
        return this.VoltsPerMinutes;
      case ElectricPotentialChangeRateUnits.VoltsPerHours:
        return this.VoltsPerHours;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds:
        return this.MicrovoltsPerSeconds;
      case ElectricPotentialChangeRateUnits.MillivoltsPerSeconds:
        return this.MillivoltsPerSeconds;
      case ElectricPotentialChangeRateUnits.KilovoltsPerSeconds:
        return this.KilovoltsPerSeconds;
      case ElectricPotentialChangeRateUnits.MegavoltsPerSeconds:
        return this.MegavoltsPerSeconds;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds:
        return this.MicrovoltsPerMicroseconds;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds:
        return this.MillivoltsPerMicroseconds;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds:
        return this.KilovoltsPerMicroseconds;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds:
        return this.MegavoltsPerMicroseconds;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes:
        return this.MicrovoltsPerMinutes;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMinutes:
        return this.MillivoltsPerMinutes;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMinutes:
        return this.KilovoltsPerMinutes;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMinutes:
        return this.MegavoltsPerMinutes;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerHours:
        return this.MicrovoltsPerHours;
      case ElectricPotentialChangeRateUnits.MillivoltsPerHours:
        return this.MillivoltsPerHours;
      case ElectricPotentialChangeRateUnits.KilovoltsPerHours:
        return this.KilovoltsPerHours;
      case ElectricPotentialChangeRateUnits.MegavoltsPerHours:
        return this.MegavoltsPerHours;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricPotentialChangeRateUnits.VoltsPerSeconds:
        return this.value;
      case ElectricPotentialChangeRateUnits.VoltsPerMicroseconds:
        return super.internalDivide(this.value, 1E6);
      case ElectricPotentialChangeRateUnits.VoltsPerMinutes:
        return super.internalMultiply(this.value, 60);
      case ElectricPotentialChangeRateUnits.VoltsPerHours:
        return super.internalMultiply(this.value, 3600);
      case ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds:
        return super.internalDivide(this.value, 0.000001);
      case ElectricPotentialChangeRateUnits.MillivoltsPerSeconds:
        return super.internalDivide(this.value, 0.001);
      case ElectricPotentialChangeRateUnits.KilovoltsPerSeconds:
        return super.internalDivide(this.value, 1000);
      case ElectricPotentialChangeRateUnits.MegavoltsPerSeconds:
        return super.internalDivide(this.value, 1000000);
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds:
        {
          const v3 = super.internalDivide(this.value, 1E6);
          return super.internalDivide(v3, 0.000001);
        }
      case ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds:
        {
          const v3 = super.internalDivide(this.value, 1E6);
          return super.internalDivide(v3, 0.001);
        }
      case ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds:
        {
          const v3 = super.internalDivide(this.value, 1E6);
          return super.internalDivide(v3, 1000);
        }
      case ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds:
        {
          const v3 = super.internalDivide(this.value, 1E6);
          return super.internalDivide(v3, 1000000);
        }
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes:
        {
          const v3 = super.internalMultiply(this.value, 60);
          return super.internalDivide(v3, 0.000001);
        }
      case ElectricPotentialChangeRateUnits.MillivoltsPerMinutes:
        {
          const v3 = super.internalMultiply(this.value, 60);
          return super.internalDivide(v3, 0.001);
        }
      case ElectricPotentialChangeRateUnits.KilovoltsPerMinutes:
        {
          const v3 = super.internalMultiply(this.value, 60);
          return super.internalDivide(v3, 1000);
        }
      case ElectricPotentialChangeRateUnits.MegavoltsPerMinutes:
        {
          const v3 = super.internalMultiply(this.value, 60);
          return super.internalDivide(v3, 1000000);
        }
      case ElectricPotentialChangeRateUnits.MicrovoltsPerHours:
        {
          const v3 = super.internalMultiply(this.value, 3600);
          return super.internalDivide(v3, 0.000001);
        }
      case ElectricPotentialChangeRateUnits.MillivoltsPerHours:
        {
          const v3 = super.internalMultiply(this.value, 3600);
          return super.internalDivide(v3, 0.001);
        }
      case ElectricPotentialChangeRateUnits.KilovoltsPerHours:
        {
          const v3 = super.internalMultiply(this.value, 3600);
          return super.internalDivide(v3, 1000);
        }
      case ElectricPotentialChangeRateUnits.MegavoltsPerHours:
        {
          const v3 = super.internalMultiply(this.value, 3600);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricPotentialChangeRateUnits.VoltsPerSeconds:
        return this.value;
      case ElectricPotentialChangeRateUnits.VoltsPerMicroseconds:
        return this.value / 1E6;
      case ElectricPotentialChangeRateUnits.VoltsPerMinutes:
        return this.value * 60;
      case ElectricPotentialChangeRateUnits.VoltsPerHours:
        return this.value * 3600;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds:
        return this.value / 0.000001;
      case ElectricPotentialChangeRateUnits.MillivoltsPerSeconds:
        return this.value / 0.001;
      case ElectricPotentialChangeRateUnits.KilovoltsPerSeconds:
        return this.value / 1000;
      case ElectricPotentialChangeRateUnits.MegavoltsPerSeconds:
        return this.value / 1000000;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds:
        return this.value / 1E6 / 0.000001;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds:
        return this.value / 1E6 / 0.001;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds:
        return this.value / 1E6 / 1000;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds:
        return this.value / 1E6 / 1000000;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes:
        return this.value * 60 / 0.000001;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMinutes:
        return this.value * 60 / 0.001;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMinutes:
        return this.value * 60 / 1000;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMinutes:
        return this.value * 60 / 1000000;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerHours:
        return this.value * 3600 / 0.000001;
      case ElectricPotentialChangeRateUnits.MillivoltsPerHours:
        return this.value * 3600 / 0.001;
      case ElectricPotentialChangeRateUnits.KilovoltsPerHours:
        return this.value * 3600 / 1000;
      case ElectricPotentialChangeRateUnits.MegavoltsPerHours:
        return this.value * 3600 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricPotentialChangeRateUnits.VoltsPerSeconds:
        return value;
      case ElectricPotentialChangeRateUnits.VoltsPerMicroseconds:
        return super.internalMultiply(value, 1E6);
      case ElectricPotentialChangeRateUnits.VoltsPerMinutes:
        return super.internalDivide(value, 60);
      case ElectricPotentialChangeRateUnits.VoltsPerHours:
        return super.internalDivide(value, 3600);
      case ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds:
        return super.internalMultiply(value, 0.000001);
      case ElectricPotentialChangeRateUnits.MillivoltsPerSeconds:
        return super.internalMultiply(value, 0.001);
      case ElectricPotentialChangeRateUnits.KilovoltsPerSeconds:
        return super.internalMultiply(value, 1000);
      case ElectricPotentialChangeRateUnits.MegavoltsPerSeconds:
        return super.internalMultiply(value, 1000000);
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds:
        {
          const v3 = super.internalMultiply(value, 1E6);
          return super.internalMultiply(v3, 0.000001);
        }
      case ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds:
        {
          const v3 = super.internalMultiply(value, 1E6);
          return super.internalMultiply(v3, 0.001);
        }
      case ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds:
        {
          const v3 = super.internalMultiply(value, 1E6);
          return super.internalMultiply(v3, 1000);
        }
      case ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds:
        {
          const v3 = super.internalMultiply(value, 1E6);
          return super.internalMultiply(v3, 1000000);
        }
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes:
        {
          const v3 = super.internalDivide(value, 60);
          return super.internalMultiply(v3, 0.000001);
        }
      case ElectricPotentialChangeRateUnits.MillivoltsPerMinutes:
        {
          const v3 = super.internalDivide(value, 60);
          return super.internalMultiply(v3, 0.001);
        }
      case ElectricPotentialChangeRateUnits.KilovoltsPerMinutes:
        {
          const v3 = super.internalDivide(value, 60);
          return super.internalMultiply(v3, 1000);
        }
      case ElectricPotentialChangeRateUnits.MegavoltsPerMinutes:
        {
          const v3 = super.internalDivide(value, 60);
          return super.internalMultiply(v3, 1000000);
        }
      case ElectricPotentialChangeRateUnits.MicrovoltsPerHours:
        {
          const v3 = super.internalDivide(value, 3600);
          return super.internalMultiply(v3, 0.000001);
        }
      case ElectricPotentialChangeRateUnits.MillivoltsPerHours:
        {
          const v3 = super.internalDivide(value, 3600);
          return super.internalMultiply(v3, 0.001);
        }
      case ElectricPotentialChangeRateUnits.KilovoltsPerHours:
        {
          const v3 = super.internalDivide(value, 3600);
          return super.internalMultiply(v3, 1000);
        }
      case ElectricPotentialChangeRateUnits.MegavoltsPerHours:
        {
          const v3 = super.internalDivide(value, 3600);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricPotentialChangeRateUnits.VoltsPerSeconds:
        return value;
      case ElectricPotentialChangeRateUnits.VoltsPerMicroseconds:
        return value * 1E6;
      case ElectricPotentialChangeRateUnits.VoltsPerMinutes:
        return value / 60;
      case ElectricPotentialChangeRateUnits.VoltsPerHours:
        return value / 3600;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds:
        return value * 0.000001;
      case ElectricPotentialChangeRateUnits.MillivoltsPerSeconds:
        return value * 0.001;
      case ElectricPotentialChangeRateUnits.KilovoltsPerSeconds:
        return value * 1000;
      case ElectricPotentialChangeRateUnits.MegavoltsPerSeconds:
        return value * 1000000;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds:
        return value * 1E6 * 0.000001;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds:
        return value * 1E6 * 0.001;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds:
        return value * 1E6 * 1000;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds:
        return value * 1E6 * 1000000;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes:
        return value / 60 * 0.000001;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMinutes:
        return value / 60 * 0.001;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMinutes:
        return value / 60 * 1000;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMinutes:
        return value / 60 * 1000000;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerHours:
        return value / 3600 * 0.000001;
      case ElectricPotentialChangeRateUnits.MillivoltsPerHours:
        return value / 3600 * 0.001;
      case ElectricPotentialChangeRateUnits.KilovoltsPerHours:
        return value / 3600 * 1000;
      case ElectricPotentialChangeRateUnits.MegavoltsPerHours:
        return value / 3600 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricPotentialChangeRate to string.
   * Note! the default format for ElectricPotentialChangeRate is VoltsPerSeconds.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricPotentialChangeRate.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricPotentialChangeRate.
   */
  toString(unit = ElectricPotentialChangeRateUnits.VoltsPerSeconds, fractionalDigits) {
    switch (unit) {
      case ElectricPotentialChangeRateUnits.VoltsPerSeconds:
        return super.truncateFractionDigits(this.VoltsPerSeconds, fractionalDigits) + ` V/s`;
      case ElectricPotentialChangeRateUnits.VoltsPerMicroseconds:
        return super.truncateFractionDigits(this.VoltsPerMicroseconds, fractionalDigits) + ` V/μs`;
      case ElectricPotentialChangeRateUnits.VoltsPerMinutes:
        return super.truncateFractionDigits(this.VoltsPerMinutes, fractionalDigits) + ` V/min`;
      case ElectricPotentialChangeRateUnits.VoltsPerHours:
        return super.truncateFractionDigits(this.VoltsPerHours, fractionalDigits) + ` V/h`;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds:
        return super.truncateFractionDigits(this.MicrovoltsPerSeconds, fractionalDigits) + ` μV/s`;
      case ElectricPotentialChangeRateUnits.MillivoltsPerSeconds:
        return super.truncateFractionDigits(this.MillivoltsPerSeconds, fractionalDigits) + ` mV/s`;
      case ElectricPotentialChangeRateUnits.KilovoltsPerSeconds:
        return super.truncateFractionDigits(this.KilovoltsPerSeconds, fractionalDigits) + ` kV/s`;
      case ElectricPotentialChangeRateUnits.MegavoltsPerSeconds:
        return super.truncateFractionDigits(this.MegavoltsPerSeconds, fractionalDigits) + ` MV/s`;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds:
        return super.truncateFractionDigits(this.MicrovoltsPerMicroseconds, fractionalDigits) + ` μV/μs`;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds:
        return super.truncateFractionDigits(this.MillivoltsPerMicroseconds, fractionalDigits) + ` mV/μs`;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds:
        return super.truncateFractionDigits(this.KilovoltsPerMicroseconds, fractionalDigits) + ` kV/μs`;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds:
        return super.truncateFractionDigits(this.MegavoltsPerMicroseconds, fractionalDigits) + ` MV/μs`;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes:
        return super.truncateFractionDigits(this.MicrovoltsPerMinutes, fractionalDigits) + ` μV/min`;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMinutes:
        return super.truncateFractionDigits(this.MillivoltsPerMinutes, fractionalDigits) + ` mV/min`;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMinutes:
        return super.truncateFractionDigits(this.KilovoltsPerMinutes, fractionalDigits) + ` kV/min`;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMinutes:
        return super.truncateFractionDigits(this.MegavoltsPerMinutes, fractionalDigits) + ` MV/min`;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerHours:
        return super.truncateFractionDigits(this.MicrovoltsPerHours, fractionalDigits) + ` μV/h`;
      case ElectricPotentialChangeRateUnits.MillivoltsPerHours:
        return super.truncateFractionDigits(this.MillivoltsPerHours, fractionalDigits) + ` mV/h`;
      case ElectricPotentialChangeRateUnits.KilovoltsPerHours:
        return super.truncateFractionDigits(this.KilovoltsPerHours, fractionalDigits) + ` kV/h`;
      case ElectricPotentialChangeRateUnits.MegavoltsPerHours:
        return super.truncateFractionDigits(this.MegavoltsPerHours, fractionalDigits) + ` MV/h`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricPotentialChangeRate unit abbreviation.
   * Note! the default abbreviation for ElectricPotentialChangeRate is VoltsPerSeconds.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricPotentialChangeRate.
   * @returns The abbreviation string of ElectricPotentialChangeRate.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricPotentialChangeRateUnits.VoltsPerSeconds) {
    switch (unitAbbreviation) {
      case ElectricPotentialChangeRateUnits.VoltsPerSeconds:
        return `V/s`;
      case ElectricPotentialChangeRateUnits.VoltsPerMicroseconds:
        return `V/μs`;
      case ElectricPotentialChangeRateUnits.VoltsPerMinutes:
        return `V/min`;
      case ElectricPotentialChangeRateUnits.VoltsPerHours:
        return `V/h`;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerSeconds:
        return `μV/s`;
      case ElectricPotentialChangeRateUnits.MillivoltsPerSeconds:
        return `mV/s`;
      case ElectricPotentialChangeRateUnits.KilovoltsPerSeconds:
        return `kV/s`;
      case ElectricPotentialChangeRateUnits.MegavoltsPerSeconds:
        return `MV/s`;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMicroseconds:
        return `μV/μs`;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMicroseconds:
        return `mV/μs`;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMicroseconds:
        return `kV/μs`;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMicroseconds:
        return `MV/μs`;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerMinutes:
        return `μV/min`;
      case ElectricPotentialChangeRateUnits.MillivoltsPerMinutes:
        return `mV/min`;
      case ElectricPotentialChangeRateUnits.KilovoltsPerMinutes:
        return `kV/min`;
      case ElectricPotentialChangeRateUnits.MegavoltsPerMinutes:
        return `MV/min`;
      case ElectricPotentialChangeRateUnits.MicrovoltsPerHours:
        return `μV/h`;
      case ElectricPotentialChangeRateUnits.MillivoltsPerHours:
        return `mV/h`;
      case ElectricPotentialChangeRateUnits.KilovoltsPerHours:
        return `kV/h`;
      case ElectricPotentialChangeRateUnits.MegavoltsPerHours:
        return `MV/h`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricPotentialChangeRate are equals to the current ElectricPotentialChangeRate.
   * @param electricPotentialChangeRate The other ElectricPotentialChangeRate.
   * @returns True if the given ElectricPotentialChangeRate are equal to the current ElectricPotentialChangeRate.
   */
  equals(electricPotentialChangeRate) {
    return super.internalEquals(this.value, electricPotentialChangeRate.BaseValue);
  }
  /**
   * Compare the given ElectricPotentialChangeRate against the current ElectricPotentialChangeRate.
   * @param electricPotentialChangeRate The other ElectricPotentialChangeRate.
   * @returns 0 if they are equal, -1 if the current ElectricPotentialChangeRate is less then other, 1 if the current ElectricPotentialChangeRate is greater then other.
   */
  compareTo(electricPotentialChangeRate) {
    return super.internalCompareTo(this.value, electricPotentialChangeRate.BaseValue);
  }
  /**
   * Add the given ElectricPotentialChangeRate with the current ElectricPotentialChangeRate.
   * @param electricPotentialChangeRate The other ElectricPotentialChangeRate.
   * @returns A new ElectricPotentialChangeRate instance with the results.
   */
  add(electricPotentialChangeRate) {
    return new ElectricPotentialChangeRate(super.internalAdd(this.value, electricPotentialChangeRate.BaseValue));
  }
  /**
   * Subtract the given ElectricPotentialChangeRate with the current ElectricPotentialChangeRate.
   * @param electricPotentialChangeRate The other ElectricPotentialChangeRate.
   * @returns A new ElectricPotentialChangeRate instance with the results.
   */
  subtract(electricPotentialChangeRate) {
    return new ElectricPotentialChangeRate(super.internalSubtract(this.value, electricPotentialChangeRate.BaseValue));
  }
  /**
   * Multiply the given ElectricPotentialChangeRate with the current ElectricPotentialChangeRate.
   * @param electricPotentialChangeRate The other ElectricPotentialChangeRate.
   * @returns A new ElectricPotentialChangeRate instance with the results.
   */
  multiply(electricPotentialChangeRate) {
    return new ElectricPotentialChangeRate(super.internalMultiply(this.value, electricPotentialChangeRate.BaseValue));
  }
  /**
   * Divide the given ElectricPotentialChangeRate with the current ElectricPotentialChangeRate.
   * @param electricPotentialChangeRate The other ElectricPotentialChangeRate.
   * @returns A new ElectricPotentialChangeRate instance with the results.
   */
  divide(electricPotentialChangeRate) {
    return new ElectricPotentialChangeRate(super.internalDivide(this.value, electricPotentialChangeRate.BaseValue));
  }
  /**
   * Modulo the given ElectricPotentialChangeRate with the current ElectricPotentialChangeRate.
   * @param electricPotentialChangeRate The other ElectricPotentialChangeRate.
   * @returns A new ElectricPotentialChangeRate instance with the results.
   */
  modulo(electricPotentialChangeRate) {
    return new ElectricPotentialChangeRate(super.internalModulo(this.value, electricPotentialChangeRate.BaseValue));
  }
  /**
   * Pow the given ElectricPotentialChangeRate with the current ElectricPotentialChangeRate.
   * @param electricPotentialChangeRate The other ElectricPotentialChangeRate.
   * @returns A new ElectricPotentialChangeRate instance with the results.
   */
  pow(electricPotentialChangeRate) {
    return new ElectricPotentialChangeRate(super.internalPow(this.value, electricPotentialChangeRate.BaseValue));
  }
}
exports.ElectricPotentialChangeRate = ElectricPotentialChangeRate;