"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricCharge = exports.ElectricChargeUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricChargeUnits enumeration */
var ElectricChargeUnits;
(function (ElectricChargeUnits) {
  /** */
  ElectricChargeUnits["Coulombs"] = "Coulomb";
  /** */
  ElectricChargeUnits["AmpereHours"] = "AmpereHour";
  /** */
  ElectricChargeUnits["Picocoulombs"] = "Picocoulomb";
  /** */
  ElectricChargeUnits["Nanocoulombs"] = "Nanocoulomb";
  /** */
  ElectricChargeUnits["Microcoulombs"] = "Microcoulomb";
  /** */
  ElectricChargeUnits["Millicoulombs"] = "Millicoulomb";
  /** */
  ElectricChargeUnits["Kilocoulombs"] = "Kilocoulomb";
  /** */
  ElectricChargeUnits["Megacoulombs"] = "Megacoulomb";
  /** */
  ElectricChargeUnits["MilliampereHours"] = "MilliampereHour";
  /** */
  ElectricChargeUnits["KiloampereHours"] = "KiloampereHour";
  /** */
  ElectricChargeUnits["MegaampereHours"] = "MegaampereHour";
})(ElectricChargeUnits = exports.ElectricChargeUnits || (exports.ElectricChargeUnits = {}));
/** Electric charge is the physical property of matter that causes it to experience a force when placed in an electromagnetic field. */
class ElectricCharge extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricCharge.
   * @param value The value.
   * @param fromUnit The ‘ElectricCharge’ unit to create from.
   * The default unit is Coulombs
   */
  constructor(value, fromUnit = ElectricChargeUnits.Coulombs) {
    super();
    this.coulombsLazy = null;
    this.amperehoursLazy = null;
    this.picocoulombsLazy = null;
    this.nanocoulombsLazy = null;
    this.microcoulombsLazy = null;
    this.millicoulombsLazy = null;
    this.kilocoulombsLazy = null;
    this.megacoulombsLazy = null;
    this.milliamperehoursLazy = null;
    this.kiloamperehoursLazy = null;
    this.megaamperehoursLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricCharge is Coulombs.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricChargeUnits.Coulombs;
  }
  /** */
  get Coulombs() {
    if (this.coulombsLazy !== null) {
      return this.coulombsLazy;
    }
    return this.coulombsLazy = this.convertFromBase(ElectricChargeUnits.Coulombs);
  }
  /** */
  get AmpereHours() {
    if (this.amperehoursLazy !== null) {
      return this.amperehoursLazy;
    }
    return this.amperehoursLazy = this.convertFromBase(ElectricChargeUnits.AmpereHours);
  }
  /** */
  get Picocoulombs() {
    if (this.picocoulombsLazy !== null) {
      return this.picocoulombsLazy;
    }
    return this.picocoulombsLazy = this.convertFromBase(ElectricChargeUnits.Picocoulombs);
  }
  /** */
  get Nanocoulombs() {
    if (this.nanocoulombsLazy !== null) {
      return this.nanocoulombsLazy;
    }
    return this.nanocoulombsLazy = this.convertFromBase(ElectricChargeUnits.Nanocoulombs);
  }
  /** */
  get Microcoulombs() {
    if (this.microcoulombsLazy !== null) {
      return this.microcoulombsLazy;
    }
    return this.microcoulombsLazy = this.convertFromBase(ElectricChargeUnits.Microcoulombs);
  }
  /** */
  get Millicoulombs() {
    if (this.millicoulombsLazy !== null) {
      return this.millicoulombsLazy;
    }
    return this.millicoulombsLazy = this.convertFromBase(ElectricChargeUnits.Millicoulombs);
  }
  /** */
  get Kilocoulombs() {
    if (this.kilocoulombsLazy !== null) {
      return this.kilocoulombsLazy;
    }
    return this.kilocoulombsLazy = this.convertFromBase(ElectricChargeUnits.Kilocoulombs);
  }
  /** */
  get Megacoulombs() {
    if (this.megacoulombsLazy !== null) {
      return this.megacoulombsLazy;
    }
    return this.megacoulombsLazy = this.convertFromBase(ElectricChargeUnits.Megacoulombs);
  }
  /** */
  get MilliampereHours() {
    if (this.milliamperehoursLazy !== null) {
      return this.milliamperehoursLazy;
    }
    return this.milliamperehoursLazy = this.convertFromBase(ElectricChargeUnits.MilliampereHours);
  }
  /** */
  get KiloampereHours() {
    if (this.kiloamperehoursLazy !== null) {
      return this.kiloamperehoursLazy;
    }
    return this.kiloamperehoursLazy = this.convertFromBase(ElectricChargeUnits.KiloampereHours);
  }
  /** */
  get MegaampereHours() {
    if (this.megaamperehoursLazy !== null) {
      return this.megaamperehoursLazy;
    }
    return this.megaamperehoursLazy = this.convertFromBase(ElectricChargeUnits.MegaampereHours);
  }
  /**
   * Create a new ElectricCharge instance from a Coulombs
   *
   * @param value The unit as Coulombs to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromCoulombs(value) {
    return new ElectricCharge(value, ElectricChargeUnits.Coulombs);
  }
  /**
   * Create a new ElectricCharge instance from a AmpereHours
   *
   * @param value The unit as AmpereHours to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromAmpereHours(value) {
    return new ElectricCharge(value, ElectricChargeUnits.AmpereHours);
  }
  /**
   * Create a new ElectricCharge instance from a Picocoulombs
   *
   * @param value The unit as Picocoulombs to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromPicocoulombs(value) {
    return new ElectricCharge(value, ElectricChargeUnits.Picocoulombs);
  }
  /**
   * Create a new ElectricCharge instance from a Nanocoulombs
   *
   * @param value The unit as Nanocoulombs to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromNanocoulombs(value) {
    return new ElectricCharge(value, ElectricChargeUnits.Nanocoulombs);
  }
  /**
   * Create a new ElectricCharge instance from a Microcoulombs
   *
   * @param value The unit as Microcoulombs to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromMicrocoulombs(value) {
    return new ElectricCharge(value, ElectricChargeUnits.Microcoulombs);
  }
  /**
   * Create a new ElectricCharge instance from a Millicoulombs
   *
   * @param value The unit as Millicoulombs to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromMillicoulombs(value) {
    return new ElectricCharge(value, ElectricChargeUnits.Millicoulombs);
  }
  /**
   * Create a new ElectricCharge instance from a Kilocoulombs
   *
   * @param value The unit as Kilocoulombs to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromKilocoulombs(value) {
    return new ElectricCharge(value, ElectricChargeUnits.Kilocoulombs);
  }
  /**
   * Create a new ElectricCharge instance from a Megacoulombs
   *
   * @param value The unit as Megacoulombs to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromMegacoulombs(value) {
    return new ElectricCharge(value, ElectricChargeUnits.Megacoulombs);
  }
  /**
   * Create a new ElectricCharge instance from a MilliampereHours
   *
   * @param value The unit as MilliampereHours to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromMilliampereHours(value) {
    return new ElectricCharge(value, ElectricChargeUnits.MilliampereHours);
  }
  /**
   * Create a new ElectricCharge instance from a KiloampereHours
   *
   * @param value The unit as KiloampereHours to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromKiloampereHours(value) {
    return new ElectricCharge(value, ElectricChargeUnits.KiloampereHours);
  }
  /**
   * Create a new ElectricCharge instance from a MegaampereHours
   *
   * @param value The unit as MegaampereHours to create a new ElectricCharge from.
   * @returns The new ElectricCharge instance.
   */
  static FromMegaampereHours(value) {
    return new ElectricCharge(value, ElectricChargeUnits.MegaampereHours);
  }
  /**
   * Gets the base unit enumeration associated with ElectricCharge
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricChargeUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricChargeUnits.Coulombs;
  }
  /**
   * Create API DTO represent a ElectricCharge unit.
   * @param holdInUnit The specific ElectricCharge unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricChargeUnits.Coulombs) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricCharge unit from an API DTO representation.
   * @param dtoElectricCharge The ElectricCharge API DTO representation
   */
  static FromDto(dtoElectricCharge) {
    return new ElectricCharge(dtoElectricCharge.value, dtoElectricCharge.unit);
  }
  /**
   * Convert ElectricCharge to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricChargeUnits.Coulombs:
        return this.Coulombs;
      case ElectricChargeUnits.AmpereHours:
        return this.AmpereHours;
      case ElectricChargeUnits.Picocoulombs:
        return this.Picocoulombs;
      case ElectricChargeUnits.Nanocoulombs:
        return this.Nanocoulombs;
      case ElectricChargeUnits.Microcoulombs:
        return this.Microcoulombs;
      case ElectricChargeUnits.Millicoulombs:
        return this.Millicoulombs;
      case ElectricChargeUnits.Kilocoulombs:
        return this.Kilocoulombs;
      case ElectricChargeUnits.Megacoulombs:
        return this.Megacoulombs;
      case ElectricChargeUnits.MilliampereHours:
        return this.MilliampereHours;
      case ElectricChargeUnits.KiloampereHours:
        return this.KiloampereHours;
      case ElectricChargeUnits.MegaampereHours:
        return this.MegaampereHours;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricChargeUnits.Coulombs:
        return this.value;
      case ElectricChargeUnits.AmpereHours:
        return super.internalMultiply(this.value, 2.77777777777e-4);
      case ElectricChargeUnits.Picocoulombs:
        return super.internalDivide(this.value, 1e-12);
      case ElectricChargeUnits.Nanocoulombs:
        return super.internalDivide(this.value, 1e-9);
      case ElectricChargeUnits.Microcoulombs:
        return super.internalDivide(this.value, 0.000001);
      case ElectricChargeUnits.Millicoulombs:
        return super.internalDivide(this.value, 0.001);
      case ElectricChargeUnits.Kilocoulombs:
        return super.internalDivide(this.value, 1000);
      case ElectricChargeUnits.Megacoulombs:
        return super.internalDivide(this.value, 1000000);
      case ElectricChargeUnits.MilliampereHours:
        {
          const v3 = super.internalMultiply(this.value, 2.77777777777e-4);
          return super.internalDivide(v3, 0.001);
        }
      case ElectricChargeUnits.KiloampereHours:
        {
          const v3 = super.internalMultiply(this.value, 2.77777777777e-4);
          return super.internalDivide(v3, 1000);
        }
      case ElectricChargeUnits.MegaampereHours:
        {
          const v3 = super.internalMultiply(this.value, 2.77777777777e-4);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricChargeUnits.Coulombs:
        return this.value;
      case ElectricChargeUnits.AmpereHours:
        return this.value * 2.77777777777e-4;
      case ElectricChargeUnits.Picocoulombs:
        return this.value / 1e-12;
      case ElectricChargeUnits.Nanocoulombs:
        return this.value / 1e-9;
      case ElectricChargeUnits.Microcoulombs:
        return this.value / 0.000001;
      case ElectricChargeUnits.Millicoulombs:
        return this.value / 0.001;
      case ElectricChargeUnits.Kilocoulombs:
        return this.value / 1000;
      case ElectricChargeUnits.Megacoulombs:
        return this.value / 1000000;
      case ElectricChargeUnits.MilliampereHours:
        return this.value * 2.77777777777e-4 / 0.001;
      case ElectricChargeUnits.KiloampereHours:
        return this.value * 2.77777777777e-4 / 1000;
      case ElectricChargeUnits.MegaampereHours:
        return this.value * 2.77777777777e-4 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricChargeUnits.Coulombs:
        return value;
      case ElectricChargeUnits.AmpereHours:
        return super.internalDivide(value, 2.77777777777e-4);
      case ElectricChargeUnits.Picocoulombs:
        return super.internalMultiply(value, 1e-12);
      case ElectricChargeUnits.Nanocoulombs:
        return super.internalMultiply(value, 1e-9);
      case ElectricChargeUnits.Microcoulombs:
        return super.internalMultiply(value, 0.000001);
      case ElectricChargeUnits.Millicoulombs:
        return super.internalMultiply(value, 0.001);
      case ElectricChargeUnits.Kilocoulombs:
        return super.internalMultiply(value, 1000);
      case ElectricChargeUnits.Megacoulombs:
        return super.internalMultiply(value, 1000000);
      case ElectricChargeUnits.MilliampereHours:
        {
          const v3 = super.internalDivide(value, 2.77777777777e-4);
          return super.internalMultiply(v3, 0.001);
        }
      case ElectricChargeUnits.KiloampereHours:
        {
          const v3 = super.internalDivide(value, 2.77777777777e-4);
          return super.internalMultiply(v3, 1000);
        }
      case ElectricChargeUnits.MegaampereHours:
        {
          const v3 = super.internalDivide(value, 2.77777777777e-4);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricChargeUnits.Coulombs:
        return value;
      case ElectricChargeUnits.AmpereHours:
        return value / 2.77777777777e-4;
      case ElectricChargeUnits.Picocoulombs:
        return value * 1e-12;
      case ElectricChargeUnits.Nanocoulombs:
        return value * 1e-9;
      case ElectricChargeUnits.Microcoulombs:
        return value * 0.000001;
      case ElectricChargeUnits.Millicoulombs:
        return value * 0.001;
      case ElectricChargeUnits.Kilocoulombs:
        return value * 1000;
      case ElectricChargeUnits.Megacoulombs:
        return value * 1000000;
      case ElectricChargeUnits.MilliampereHours:
        return value / 2.77777777777e-4 * 0.001;
      case ElectricChargeUnits.KiloampereHours:
        return value / 2.77777777777e-4 * 1000;
      case ElectricChargeUnits.MegaampereHours:
        return value / 2.77777777777e-4 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricCharge to string.
   * Note! the default format for ElectricCharge is Coulombs.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricCharge.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricCharge.
   */
  toString(unit = ElectricChargeUnits.Coulombs, fractionalDigits) {
    switch (unit) {
      case ElectricChargeUnits.Coulombs:
        return super.truncateFractionDigits(this.Coulombs, fractionalDigits) + ` C`;
      case ElectricChargeUnits.AmpereHours:
        return super.truncateFractionDigits(this.AmpereHours, fractionalDigits) + ` A-h`;
      case ElectricChargeUnits.Picocoulombs:
        return super.truncateFractionDigits(this.Picocoulombs, fractionalDigits) + ` pC`;
      case ElectricChargeUnits.Nanocoulombs:
        return super.truncateFractionDigits(this.Nanocoulombs, fractionalDigits) + ` nC`;
      case ElectricChargeUnits.Microcoulombs:
        return super.truncateFractionDigits(this.Microcoulombs, fractionalDigits) + ` μC`;
      case ElectricChargeUnits.Millicoulombs:
        return super.truncateFractionDigits(this.Millicoulombs, fractionalDigits) + ` mC`;
      case ElectricChargeUnits.Kilocoulombs:
        return super.truncateFractionDigits(this.Kilocoulombs, fractionalDigits) + ` kC`;
      case ElectricChargeUnits.Megacoulombs:
        return super.truncateFractionDigits(this.Megacoulombs, fractionalDigits) + ` MC`;
      case ElectricChargeUnits.MilliampereHours:
        return super.truncateFractionDigits(this.MilliampereHours, fractionalDigits) + ` mA-h`;
      case ElectricChargeUnits.KiloampereHours:
        return super.truncateFractionDigits(this.KiloampereHours, fractionalDigits) + ` kA-h`;
      case ElectricChargeUnits.MegaampereHours:
        return super.truncateFractionDigits(this.MegaampereHours, fractionalDigits) + ` MA-h`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricCharge unit abbreviation.
   * Note! the default abbreviation for ElectricCharge is Coulombs.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricCharge.
   * @returns The abbreviation string of ElectricCharge.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricChargeUnits.Coulombs) {
    switch (unitAbbreviation) {
      case ElectricChargeUnits.Coulombs:
        return `C`;
      case ElectricChargeUnits.AmpereHours:
        return `A-h`;
      case ElectricChargeUnits.Picocoulombs:
        return `pC`;
      case ElectricChargeUnits.Nanocoulombs:
        return `nC`;
      case ElectricChargeUnits.Microcoulombs:
        return `μC`;
      case ElectricChargeUnits.Millicoulombs:
        return `mC`;
      case ElectricChargeUnits.Kilocoulombs:
        return `kC`;
      case ElectricChargeUnits.Megacoulombs:
        return `MC`;
      case ElectricChargeUnits.MilliampereHours:
        return `mA-h`;
      case ElectricChargeUnits.KiloampereHours:
        return `kA-h`;
      case ElectricChargeUnits.MegaampereHours:
        return `MA-h`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricCharge are equals to the current ElectricCharge.
   * @param electricCharge The other ElectricCharge.
   * @returns True if the given ElectricCharge are equal to the current ElectricCharge.
   */
  equals(electricCharge) {
    return super.internalEquals(this.value, electricCharge.BaseValue);
  }
  /**
   * Compare the given ElectricCharge against the current ElectricCharge.
   * @param electricCharge The other ElectricCharge.
   * @returns 0 if they are equal, -1 if the current ElectricCharge is less then other, 1 if the current ElectricCharge is greater then other.
   */
  compareTo(electricCharge) {
    return super.internalCompareTo(this.value, electricCharge.BaseValue);
  }
  /**
   * Add the given ElectricCharge with the current ElectricCharge.
   * @param electricCharge The other ElectricCharge.
   * @returns A new ElectricCharge instance with the results.
   */
  add(electricCharge) {
    return new ElectricCharge(super.internalAdd(this.value, electricCharge.BaseValue));
  }
  /**
   * Subtract the given ElectricCharge with the current ElectricCharge.
   * @param electricCharge The other ElectricCharge.
   * @returns A new ElectricCharge instance with the results.
   */
  subtract(electricCharge) {
    return new ElectricCharge(super.internalSubtract(this.value, electricCharge.BaseValue));
  }
  /**
   * Multiply the given ElectricCharge with the current ElectricCharge.
   * @param electricCharge The other ElectricCharge.
   * @returns A new ElectricCharge instance with the results.
   */
  multiply(electricCharge) {
    return new ElectricCharge(super.internalMultiply(this.value, electricCharge.BaseValue));
  }
  /**
   * Divide the given ElectricCharge with the current ElectricCharge.
   * @param electricCharge The other ElectricCharge.
   * @returns A new ElectricCharge instance with the results.
   */
  divide(electricCharge) {
    return new ElectricCharge(super.internalDivide(this.value, electricCharge.BaseValue));
  }
  /**
   * Modulo the given ElectricCharge with the current ElectricCharge.
   * @param electricCharge The other ElectricCharge.
   * @returns A new ElectricCharge instance with the results.
   */
  modulo(electricCharge) {
    return new ElectricCharge(super.internalModulo(this.value, electricCharge.BaseValue));
  }
  /**
   * Pow the given ElectricCharge with the current ElectricCharge.
   * @param electricCharge The other ElectricCharge.
   * @returns A new ElectricCharge instance with the results.
   */
  pow(electricCharge) {
    return new ElectricCharge(super.internalPow(this.value, electricCharge.BaseValue));
  }
}
exports.ElectricCharge = ElectricCharge;