import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, StatusPanelDef } from 'ag-grid-community';
import { IGridPreference } from 'src/app/models';
import { CustomMessageService, SharedService } from 'src/app/services';
import { DialogService } from 'primeng/dynamicdialog';
import * as moment from 'moment';
import { AppConstants } from 'src/app/shared/constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  @Input() selectedGroup: any;
  rowData: any[] = [];
  @Input() stickyGridCols: any;
  dialogStyle: any = { width: '1400px', height: '700px' };
  @Input() openHistoryDialog: boolean = false;
  @Input() id: string = "";
  @Input() type: string = "";
  innerWidth: number = 0;
  gridName: string = "History";
  @Output() closeDialog = new EventEmitter<string>();
  GridPreference: IGridPreference = { gridName: this.gridName, gridColumns: "", filters: "" };

  public columnDefs: ColDef[] = [
    // {
    //   field: 'updatedOn', headerName: 'Action Taken On', width: 150, minWidth: 70, sortable: true, filter: 'agTextColumnFilter', resizable: true, valueFormatter: function (params) {
    //     return params.value ? moment(new Date(new Date(params.value) + " UTC")).format("M/D/YYYY hh:mm:ss A") : params.value;
    //   }
    // },
    {
      field: 'updatedOn', headerName: 'Action Taken On', sortable: true, filter: 'agTextColumnFilter', resizable: true,
      valueFormatter: (data) => { return this.dateFormatter.transform(data.value, 'MM/dd/yyyy') || "" }
    },
    { field: 'updatedBy', headerName: 'Action Taken By', filter: 'agTextColumnFilter', resizable: true, sortable: true },
    { field: 'action', headerName: 'Action', filter: 'agTextColumnFilter', resizable: true, sortable: true },
    { field: 'field', headerName: 'Field Name', filter: 'agTextColumnFilter', resizable: true, sortable: true },
    { field: 'oldVal', headerName: 'Old Value', filter: 'agTextColumnFilter', resizable: true, sortable: true },
    { field: 'newVal', headerName: 'New Value', filter: 'agTextColumnFilter', resizable: true, sortable: true },
  ];

  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent' },
      ],
    };
  excelStyles = [
    {
      id: 'numberType',
      numberFormat: {
        format: '0',
      },
    }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };
  // Data that gets displayed in the grid
  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  detailMaster: boolean = false;

  constructor(private commonService: SharedService, public customMessage: CustomMessageService, public dialogService: DialogService, private dateFormatter: DatePipe) {

  }

  ngOnInit(): void {
    let url = 'GetRecipeHistory';
    if (this.type === 'Ingredient')
      url = 'GetIngredientHistory';
    else if (this.type === 'Recipe')
      url = 'GetRecipeHistory';

    let gridNameLocal = this.type + this.gridName;
    this.GridPreference.gridName = gridNameLocal;
    this.commonService.getGridPreferences(this.GridPreference).subscribe((res: any) => {
      // this.stickyGridCols = res?.preference ? res.preference : "";
      this.GridPreference.gridColumns = res.gridColumns || "";
      this.GridPreference.filters = res.filters;
    });

    this.commonService.getAuditHistory(url, this.id).subscribe((res: any[]) => {
      if (res.length !== 0) {
        this.rowData = res;
      } else {
        this.customMessage.showMessage({ severity: 'warn', summary: 'No History Found', detail: "No History found." });
      }
    });
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1600) {
      this.dialogStyle = { width: '1250px', height: '600px' };
    } else if (this.innerWidth > 1600) {
      this.dialogStyle = { width: '1400px', height: '700px' };
    }



  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1600) {
      this.dialogStyle = { width: '1250px', height: '600px' };
    } else if (this.innerWidth > 1600) {
      this.dialogStyle = { width: '1400px', height: '700px' };
    }
  }

  export() {
    this.agGrid.api.exportDataAsExcel();
  }

  onClose() {
    this.closeDialog.emit(JSON.stringify({ 'closeDialog': true }));
  }

  saveGridPreference() {
    const fixedCols = this.agGrid.api.getColumnState();;
    const filter = this.agGrid.api.getFilterModel();
    let gridNameLocal = this.type + this.gridName;
    const data: IGridPreference = { gridColumns: JSON.stringify(fixedCols), gridName: gridNameLocal, filters: JSON.stringify(filter) };
    this.commonService.saveGridPreferences(data).subscribe((res: any) => {
      this.GridPreference.gridColumns = data.gridColumns || "";
      this.GridPreference.filters = data.filters;
      this.customMessage.showMessage({ 'severity': 'success', summary: 'success', detail: res.message, data: AppConstants.FindRecipe });
    });
  }


  //onGridReady(params: { columnApi: { getColumnState: () => any[]; applyColumnState: (arg0: { state: any; applyOrder: boolean; }) => void; }; }) {
  onGridReady(params: any) {
    // if (this.stickyGridCols) {
    //   const savedCols = JSON.parse(this.stickyGridCols);
    //   params.columnApi.applyColumnState({ state: savedCols, applyOrder: true });
    // }

    if (this.GridPreference.gridColumns !== "") {
      const savedCols = JSON.parse(this.GridPreference.gridColumns || '{}');
      const filters = JSON.parse(this.GridPreference.filters || '{}');
      this.agGrid.api.setFilterModel(filters);
      this.agGrid.api.applyColumnState({ state: savedCols, applyOrder: true });
    }
  }

  getContextMenuItems(params: any) {
    return [
      'copy',
      'copyWithHeaders'
    ];
  }

  ngOnDestroy(): void {
    this.closeDialog.unsubscribe();
  }

}
