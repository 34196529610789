"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThermalConductivity = exports.ThermalConductivityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ThermalConductivityUnits enumeration */
var ThermalConductivityUnits;
(function (ThermalConductivityUnits) {
  /** */
  ThermalConductivityUnits["WattsPerMeterKelvin"] = "WattPerMeterKelvin";
  /** */
  ThermalConductivityUnits["BtusPerHourFootFahrenheit"] = "BtuPerHourFootFahrenheit";
})(ThermalConductivityUnits = exports.ThermalConductivityUnits || (exports.ThermalConductivityUnits = {}));
/** Thermal conductivity is the property of a material to conduct heat. */
class ThermalConductivity extends base_unit_1.BaseUnit {
  /**
   * Create a new ThermalConductivity.
   * @param value The value.
   * @param fromUnit The ‘ThermalConductivity’ unit to create from.
   * The default unit is WattsPerMeterKelvin
   */
  constructor(value, fromUnit = ThermalConductivityUnits.WattsPerMeterKelvin) {
    super();
    this.wattspermeterkelvinLazy = null;
    this.btusperhourfootfahrenheitLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ThermalConductivity is WattsPerMeterKelvin.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ThermalConductivityUnits.WattsPerMeterKelvin;
  }
  /** */
  get WattsPerMeterKelvin() {
    if (this.wattspermeterkelvinLazy !== null) {
      return this.wattspermeterkelvinLazy;
    }
    return this.wattspermeterkelvinLazy = this.convertFromBase(ThermalConductivityUnits.WattsPerMeterKelvin);
  }
  /** */
  get BtusPerHourFootFahrenheit() {
    if (this.btusperhourfootfahrenheitLazy !== null) {
      return this.btusperhourfootfahrenheitLazy;
    }
    return this.btusperhourfootfahrenheitLazy = this.convertFromBase(ThermalConductivityUnits.BtusPerHourFootFahrenheit);
  }
  /**
   * Create a new ThermalConductivity instance from a WattsPerMeterKelvin
   *
   * @param value The unit as WattsPerMeterKelvin to create a new ThermalConductivity from.
   * @returns The new ThermalConductivity instance.
   */
  static FromWattsPerMeterKelvin(value) {
    return new ThermalConductivity(value, ThermalConductivityUnits.WattsPerMeterKelvin);
  }
  /**
   * Create a new ThermalConductivity instance from a BtusPerHourFootFahrenheit
   *
   * @param value The unit as BtusPerHourFootFahrenheit to create a new ThermalConductivity from.
   * @returns The new ThermalConductivity instance.
   */
  static FromBtusPerHourFootFahrenheit(value) {
    return new ThermalConductivity(value, ThermalConductivityUnits.BtusPerHourFootFahrenheit);
  }
  /**
   * Gets the base unit enumeration associated with ThermalConductivity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ThermalConductivityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ThermalConductivityUnits.WattsPerMeterKelvin;
  }
  /**
   * Create API DTO represent a ThermalConductivity unit.
   * @param holdInUnit The specific ThermalConductivity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ThermalConductivityUnits.WattsPerMeterKelvin) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ThermalConductivity unit from an API DTO representation.
   * @param dtoThermalConductivity The ThermalConductivity API DTO representation
   */
  static FromDto(dtoThermalConductivity) {
    return new ThermalConductivity(dtoThermalConductivity.value, dtoThermalConductivity.unit);
  }
  /**
   * Convert ThermalConductivity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ThermalConductivityUnits.WattsPerMeterKelvin:
        return this.WattsPerMeterKelvin;
      case ThermalConductivityUnits.BtusPerHourFootFahrenheit:
        return this.BtusPerHourFootFahrenheit;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ThermalConductivityUnits.WattsPerMeterKelvin:
        return this.value;
      case ThermalConductivityUnits.BtusPerHourFootFahrenheit:
        return super.internalDivide(this.value, 1.73073467);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ThermalConductivityUnits.WattsPerMeterKelvin:
        return this.value;
      case ThermalConductivityUnits.BtusPerHourFootFahrenheit:
        return this.value / 1.73073467;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ThermalConductivityUnits.WattsPerMeterKelvin:
        return value;
      case ThermalConductivityUnits.BtusPerHourFootFahrenheit:
        return super.internalMultiply(value, 1.73073467);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ThermalConductivityUnits.WattsPerMeterKelvin:
        return value;
      case ThermalConductivityUnits.BtusPerHourFootFahrenheit:
        return value * 1.73073467;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ThermalConductivity to string.
   * Note! the default format for ThermalConductivity is WattsPerMeterKelvin.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ThermalConductivity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ThermalConductivity.
   */
  toString(unit = ThermalConductivityUnits.WattsPerMeterKelvin, fractionalDigits) {
    switch (unit) {
      case ThermalConductivityUnits.WattsPerMeterKelvin:
        return super.truncateFractionDigits(this.WattsPerMeterKelvin, fractionalDigits) + ` W/m·K`;
      case ThermalConductivityUnits.BtusPerHourFootFahrenheit:
        return super.truncateFractionDigits(this.BtusPerHourFootFahrenheit, fractionalDigits) + ` BTU/h·ft·°F`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ThermalConductivity unit abbreviation.
   * Note! the default abbreviation for ThermalConductivity is WattsPerMeterKelvin.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ThermalConductivity.
   * @returns The abbreviation string of ThermalConductivity.
   */
  getUnitAbbreviation(unitAbbreviation = ThermalConductivityUnits.WattsPerMeterKelvin) {
    switch (unitAbbreviation) {
      case ThermalConductivityUnits.WattsPerMeterKelvin:
        return `W/m·K`;
      case ThermalConductivityUnits.BtusPerHourFootFahrenheit:
        return `BTU/h·ft·°F`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ThermalConductivity are equals to the current ThermalConductivity.
   * @param thermalConductivity The other ThermalConductivity.
   * @returns True if the given ThermalConductivity are equal to the current ThermalConductivity.
   */
  equals(thermalConductivity) {
    return super.internalEquals(this.value, thermalConductivity.BaseValue);
  }
  /**
   * Compare the given ThermalConductivity against the current ThermalConductivity.
   * @param thermalConductivity The other ThermalConductivity.
   * @returns 0 if they are equal, -1 if the current ThermalConductivity is less then other, 1 if the current ThermalConductivity is greater then other.
   */
  compareTo(thermalConductivity) {
    return super.internalCompareTo(this.value, thermalConductivity.BaseValue);
  }
  /**
   * Add the given ThermalConductivity with the current ThermalConductivity.
   * @param thermalConductivity The other ThermalConductivity.
   * @returns A new ThermalConductivity instance with the results.
   */
  add(thermalConductivity) {
    return new ThermalConductivity(super.internalAdd(this.value, thermalConductivity.BaseValue));
  }
  /**
   * Subtract the given ThermalConductivity with the current ThermalConductivity.
   * @param thermalConductivity The other ThermalConductivity.
   * @returns A new ThermalConductivity instance with the results.
   */
  subtract(thermalConductivity) {
    return new ThermalConductivity(super.internalSubtract(this.value, thermalConductivity.BaseValue));
  }
  /**
   * Multiply the given ThermalConductivity with the current ThermalConductivity.
   * @param thermalConductivity The other ThermalConductivity.
   * @returns A new ThermalConductivity instance with the results.
   */
  multiply(thermalConductivity) {
    return new ThermalConductivity(super.internalMultiply(this.value, thermalConductivity.BaseValue));
  }
  /**
   * Divide the given ThermalConductivity with the current ThermalConductivity.
   * @param thermalConductivity The other ThermalConductivity.
   * @returns A new ThermalConductivity instance with the results.
   */
  divide(thermalConductivity) {
    return new ThermalConductivity(super.internalDivide(this.value, thermalConductivity.BaseValue));
  }
  /**
   * Modulo the given ThermalConductivity with the current ThermalConductivity.
   * @param thermalConductivity The other ThermalConductivity.
   * @returns A new ThermalConductivity instance with the results.
   */
  modulo(thermalConductivity) {
    return new ThermalConductivity(super.internalModulo(this.value, thermalConductivity.BaseValue));
  }
  /**
   * Pow the given ThermalConductivity with the current ThermalConductivity.
   * @param thermalConductivity The other ThermalConductivity.
   * @returns A new ThermalConductivity instance with the results.
   */
  pow(thermalConductivity) {
    return new ThermalConductivity(super.internalPow(this.value, thermalConductivity.BaseValue));
  }
}
exports.ThermalConductivity = ThermalConductivity;