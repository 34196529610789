"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeatTransferCoefficient = exports.HeatTransferCoefficientUnits = void 0;
const base_unit_1 = require("../base-unit");
/** HeatTransferCoefficientUnits enumeration */
var HeatTransferCoefficientUnits;
(function (HeatTransferCoefficientUnits) {
  /** */
  HeatTransferCoefficientUnits["WattsPerSquareMeterKelvin"] = "WattPerSquareMeterKelvin";
  /** */
  HeatTransferCoefficientUnits["WattsPerSquareMeterCelsius"] = "WattPerSquareMeterCelsius";
  /** */
  HeatTransferCoefficientUnits["BtusPerHourSquareFootDegreeFahrenheit"] = "BtuPerHourSquareFootDegreeFahrenheit";
  /** */
  HeatTransferCoefficientUnits["CaloriesPerHourSquareMeterDegreeCelsius"] = "CaloriePerHourSquareMeterDegreeCelsius";
  /** */
  HeatTransferCoefficientUnits["KilocaloriesPerHourSquareMeterDegreeCelsius"] = "KilocaloriePerHourSquareMeterDegreeCelsius";
})(HeatTransferCoefficientUnits = exports.HeatTransferCoefficientUnits || (exports.HeatTransferCoefficientUnits = {}));
/** The heat transfer coefficient or film coefficient, or film effectiveness, in thermodynamics and in mechanics is the proportionality constant between the heat flux and the thermodynamic driving force for the flow of heat (i.e., the temperature difference, ΔT) */
class HeatTransferCoefficient extends base_unit_1.BaseUnit {
  /**
   * Create a new HeatTransferCoefficient.
   * @param value The value.
   * @param fromUnit The ‘HeatTransferCoefficient’ unit to create from.
   * The default unit is WattsPerSquareMeterKelvin
   */
  constructor(value, fromUnit = HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin) {
    super();
    this.wattspersquaremeterkelvinLazy = null;
    this.wattspersquaremetercelsiusLazy = null;
    this.btusperhoursquarefootdegreefahrenheitLazy = null;
    this.caloriesperhoursquaremeterdegreecelsiusLazy = null;
    this.kilocaloriesperhoursquaremeterdegreecelsiusLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of HeatTransferCoefficient is WattsPerSquareMeterKelvin.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin;
  }
  /** */
  get WattsPerSquareMeterKelvin() {
    if (this.wattspersquaremeterkelvinLazy !== null) {
      return this.wattspersquaremeterkelvinLazy;
    }
    return this.wattspersquaremeterkelvinLazy = this.convertFromBase(HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin);
  }
  /** */
  get WattsPerSquareMeterCelsius() {
    if (this.wattspersquaremetercelsiusLazy !== null) {
      return this.wattspersquaremetercelsiusLazy;
    }
    return this.wattspersquaremetercelsiusLazy = this.convertFromBase(HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius);
  }
  /** */
  get BtusPerHourSquareFootDegreeFahrenheit() {
    if (this.btusperhoursquarefootdegreefahrenheitLazy !== null) {
      return this.btusperhoursquarefootdegreefahrenheitLazy;
    }
    return this.btusperhoursquarefootdegreefahrenheitLazy = this.convertFromBase(HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit);
  }
  /** */
  get CaloriesPerHourSquareMeterDegreeCelsius() {
    if (this.caloriesperhoursquaremeterdegreecelsiusLazy !== null) {
      return this.caloriesperhoursquaremeterdegreecelsiusLazy;
    }
    return this.caloriesperhoursquaremeterdegreecelsiusLazy = this.convertFromBase(HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius);
  }
  /** */
  get KilocaloriesPerHourSquareMeterDegreeCelsius() {
    if (this.kilocaloriesperhoursquaremeterdegreecelsiusLazy !== null) {
      return this.kilocaloriesperhoursquaremeterdegreecelsiusLazy;
    }
    return this.kilocaloriesperhoursquaremeterdegreecelsiusLazy = this.convertFromBase(HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius);
  }
  /**
   * Create a new HeatTransferCoefficient instance from a WattsPerSquareMeterKelvin
   *
   * @param value The unit as WattsPerSquareMeterKelvin to create a new HeatTransferCoefficient from.
   * @returns The new HeatTransferCoefficient instance.
   */
  static FromWattsPerSquareMeterKelvin(value) {
    return new HeatTransferCoefficient(value, HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin);
  }
  /**
   * Create a new HeatTransferCoefficient instance from a WattsPerSquareMeterCelsius
   *
   * @param value The unit as WattsPerSquareMeterCelsius to create a new HeatTransferCoefficient from.
   * @returns The new HeatTransferCoefficient instance.
   */
  static FromWattsPerSquareMeterCelsius(value) {
    return new HeatTransferCoefficient(value, HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius);
  }
  /**
   * Create a new HeatTransferCoefficient instance from a BtusPerHourSquareFootDegreeFahrenheit
   *
   * @param value The unit as BtusPerHourSquareFootDegreeFahrenheit to create a new HeatTransferCoefficient from.
   * @returns The new HeatTransferCoefficient instance.
   */
  static FromBtusPerHourSquareFootDegreeFahrenheit(value) {
    return new HeatTransferCoefficient(value, HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit);
  }
  /**
   * Create a new HeatTransferCoefficient instance from a CaloriesPerHourSquareMeterDegreeCelsius
   *
   * @param value The unit as CaloriesPerHourSquareMeterDegreeCelsius to create a new HeatTransferCoefficient from.
   * @returns The new HeatTransferCoefficient instance.
   */
  static FromCaloriesPerHourSquareMeterDegreeCelsius(value) {
    return new HeatTransferCoefficient(value, HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius);
  }
  /**
   * Create a new HeatTransferCoefficient instance from a KilocaloriesPerHourSquareMeterDegreeCelsius
   *
   * @param value The unit as KilocaloriesPerHourSquareMeterDegreeCelsius to create a new HeatTransferCoefficient from.
   * @returns The new HeatTransferCoefficient instance.
   */
  static FromKilocaloriesPerHourSquareMeterDegreeCelsius(value) {
    return new HeatTransferCoefficient(value, HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius);
  }
  /**
   * Gets the base unit enumeration associated with HeatTransferCoefficient
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return HeatTransferCoefficientUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin;
  }
  /**
   * Create API DTO represent a HeatTransferCoefficient unit.
   * @param holdInUnit The specific HeatTransferCoefficient unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a HeatTransferCoefficient unit from an API DTO representation.
   * @param dtoHeatTransferCoefficient The HeatTransferCoefficient API DTO representation
   */
  static FromDto(dtoHeatTransferCoefficient) {
    return new HeatTransferCoefficient(dtoHeatTransferCoefficient.value, dtoHeatTransferCoefficient.unit);
  }
  /**
   * Convert HeatTransferCoefficient to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin:
        return this.WattsPerSquareMeterKelvin;
      case HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius:
        return this.WattsPerSquareMeterCelsius;
      case HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit:
        return this.BtusPerHourSquareFootDegreeFahrenheit;
      case HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius:
        return this.CaloriesPerHourSquareMeterDegreeCelsius;
      case HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius:
        return this.KilocaloriesPerHourSquareMeterDegreeCelsius;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin:
        return this.value;
      case HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius:
        return this.value;
      case HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit:
        return super.internalDivide(this.value, 5.6782633411134878);
      case HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius:
        {
          const v3 = super.internalDivide(this.value, 4.1868);
          return super.internalMultiply(v3, 3600);
        }
      case HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius:
        {
          const v3 = super.internalDivide(this.value, 4.1868);
          const v5 = super.internalMultiply(v3, 3600);
          return super.internalDivide(v5, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin:
        return this.value;
      case HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius:
        return this.value;
      case HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit:
        return this.value / 5.6782633411134878;
      case HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius:
        return this.value / 4.1868 * 3600;
      case HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius:
        return this.value / 4.1868 * 3600 / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin:
        return value;
      case HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius:
        return value;
      case HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit:
        return super.internalMultiply(value, 5.6782633411134878);
      case HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius:
        {
          const v3 = super.internalMultiply(value, 4.1868);
          return super.internalDivide(v3, 3600);
        }
      case HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius:
        {
          const v3 = super.internalMultiply(value, 4.1868);
          const v5 = super.internalDivide(v3, 3600);
          return super.internalMultiply(v5, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin:
        return value;
      case HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius:
        return value;
      case HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit:
        return value * 5.6782633411134878;
      case HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius:
        return value * 4.1868 / 3600;
      case HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius:
        return value * 4.1868 / 3600 * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the HeatTransferCoefficient to string.
   * Note! the default format for HeatTransferCoefficient is WattsPerSquareMeterKelvin.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the HeatTransferCoefficient.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the HeatTransferCoefficient.
   */
  toString(unit = HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin, fractionalDigits) {
    switch (unit) {
      case HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin:
        return super.truncateFractionDigits(this.WattsPerSquareMeterKelvin, fractionalDigits) + ` W/m²·K`;
      case HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius:
        return super.truncateFractionDigits(this.WattsPerSquareMeterCelsius, fractionalDigits) + ` W/m²·°C`;
      case HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit:
        return super.truncateFractionDigits(this.BtusPerHourSquareFootDegreeFahrenheit, fractionalDigits) + ` Btu/h·ft²·°F`;
      case HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius:
        return super.truncateFractionDigits(this.CaloriesPerHourSquareMeterDegreeCelsius, fractionalDigits) + ` kcal/h·m²·°C`;
      case HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius:
        return super.truncateFractionDigits(this.KilocaloriesPerHourSquareMeterDegreeCelsius, fractionalDigits) + ` kkcal/h·m²·°C`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get HeatTransferCoefficient unit abbreviation.
   * Note! the default abbreviation for HeatTransferCoefficient is WattsPerSquareMeterKelvin.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the HeatTransferCoefficient.
   * @returns The abbreviation string of HeatTransferCoefficient.
   */
  getUnitAbbreviation(unitAbbreviation = HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin) {
    switch (unitAbbreviation) {
      case HeatTransferCoefficientUnits.WattsPerSquareMeterKelvin:
        return `W/m²·K`;
      case HeatTransferCoefficientUnits.WattsPerSquareMeterCelsius:
        return `W/m²·°C`;
      case HeatTransferCoefficientUnits.BtusPerHourSquareFootDegreeFahrenheit:
        return `Btu/h·ft²·°F`;
      case HeatTransferCoefficientUnits.CaloriesPerHourSquareMeterDegreeCelsius:
        return `kcal/h·m²·°C`;
      case HeatTransferCoefficientUnits.KilocaloriesPerHourSquareMeterDegreeCelsius:
        return `kkcal/h·m²·°C`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given HeatTransferCoefficient are equals to the current HeatTransferCoefficient.
   * @param heatTransferCoefficient The other HeatTransferCoefficient.
   * @returns True if the given HeatTransferCoefficient are equal to the current HeatTransferCoefficient.
   */
  equals(heatTransferCoefficient) {
    return super.internalEquals(this.value, heatTransferCoefficient.BaseValue);
  }
  /**
   * Compare the given HeatTransferCoefficient against the current HeatTransferCoefficient.
   * @param heatTransferCoefficient The other HeatTransferCoefficient.
   * @returns 0 if they are equal, -1 if the current HeatTransferCoefficient is less then other, 1 if the current HeatTransferCoefficient is greater then other.
   */
  compareTo(heatTransferCoefficient) {
    return super.internalCompareTo(this.value, heatTransferCoefficient.BaseValue);
  }
  /**
   * Add the given HeatTransferCoefficient with the current HeatTransferCoefficient.
   * @param heatTransferCoefficient The other HeatTransferCoefficient.
   * @returns A new HeatTransferCoefficient instance with the results.
   */
  add(heatTransferCoefficient) {
    return new HeatTransferCoefficient(super.internalAdd(this.value, heatTransferCoefficient.BaseValue));
  }
  /**
   * Subtract the given HeatTransferCoefficient with the current HeatTransferCoefficient.
   * @param heatTransferCoefficient The other HeatTransferCoefficient.
   * @returns A new HeatTransferCoefficient instance with the results.
   */
  subtract(heatTransferCoefficient) {
    return new HeatTransferCoefficient(super.internalSubtract(this.value, heatTransferCoefficient.BaseValue));
  }
  /**
   * Multiply the given HeatTransferCoefficient with the current HeatTransferCoefficient.
   * @param heatTransferCoefficient The other HeatTransferCoefficient.
   * @returns A new HeatTransferCoefficient instance with the results.
   */
  multiply(heatTransferCoefficient) {
    return new HeatTransferCoefficient(super.internalMultiply(this.value, heatTransferCoefficient.BaseValue));
  }
  /**
   * Divide the given HeatTransferCoefficient with the current HeatTransferCoefficient.
   * @param heatTransferCoefficient The other HeatTransferCoefficient.
   * @returns A new HeatTransferCoefficient instance with the results.
   */
  divide(heatTransferCoefficient) {
    return new HeatTransferCoefficient(super.internalDivide(this.value, heatTransferCoefficient.BaseValue));
  }
  /**
   * Modulo the given HeatTransferCoefficient with the current HeatTransferCoefficient.
   * @param heatTransferCoefficient The other HeatTransferCoefficient.
   * @returns A new HeatTransferCoefficient instance with the results.
   */
  modulo(heatTransferCoefficient) {
    return new HeatTransferCoefficient(super.internalModulo(this.value, heatTransferCoefficient.BaseValue));
  }
  /**
   * Pow the given HeatTransferCoefficient with the current HeatTransferCoefficient.
   * @param heatTransferCoefficient The other HeatTransferCoefficient.
   * @returns A new HeatTransferCoefficient instance with the results.
   */
  pow(heatTransferCoefficient) {
    return new HeatTransferCoefficient(super.internalPow(this.value, heatTransferCoefficient.BaseValue));
  }
}
exports.HeatTransferCoefficient = HeatTransferCoefficient;