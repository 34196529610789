"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApparentEnergy = exports.ApparentEnergyUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ApparentEnergyUnits enumeration */
var ApparentEnergyUnits;
(function (ApparentEnergyUnits) {
  /** */
  ApparentEnergyUnits["VoltampereHours"] = "VoltampereHour";
  /** */
  ApparentEnergyUnits["KilovoltampereHours"] = "KilovoltampereHour";
  /** */
  ApparentEnergyUnits["MegavoltampereHours"] = "MegavoltampereHour";
})(ApparentEnergyUnits = exports.ApparentEnergyUnits || (exports.ApparentEnergyUnits = {}));
/** A unit for expressing the integral of apparent power over time, equal to the product of 1 volt-ampere and 1 hour, or to 3600 joules. */
class ApparentEnergy extends base_unit_1.BaseUnit {
  /**
   * Create a new ApparentEnergy.
   * @param value The value.
   * @param fromUnit The ‘ApparentEnergy’ unit to create from.
   * The default unit is VoltampereHours
   */
  constructor(value, fromUnit = ApparentEnergyUnits.VoltampereHours) {
    super();
    this.voltamperehoursLazy = null;
    this.kilovoltamperehoursLazy = null;
    this.megavoltamperehoursLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ApparentEnergy is VoltampereHours.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ApparentEnergyUnits.VoltampereHours;
  }
  /** */
  get VoltampereHours() {
    if (this.voltamperehoursLazy !== null) {
      return this.voltamperehoursLazy;
    }
    return this.voltamperehoursLazy = this.convertFromBase(ApparentEnergyUnits.VoltampereHours);
  }
  /** */
  get KilovoltampereHours() {
    if (this.kilovoltamperehoursLazy !== null) {
      return this.kilovoltamperehoursLazy;
    }
    return this.kilovoltamperehoursLazy = this.convertFromBase(ApparentEnergyUnits.KilovoltampereHours);
  }
  /** */
  get MegavoltampereHours() {
    if (this.megavoltamperehoursLazy !== null) {
      return this.megavoltamperehoursLazy;
    }
    return this.megavoltamperehoursLazy = this.convertFromBase(ApparentEnergyUnits.MegavoltampereHours);
  }
  /**
   * Create a new ApparentEnergy instance from a VoltampereHours
   *
   * @param value The unit as VoltampereHours to create a new ApparentEnergy from.
   * @returns The new ApparentEnergy instance.
   */
  static FromVoltampereHours(value) {
    return new ApparentEnergy(value, ApparentEnergyUnits.VoltampereHours);
  }
  /**
   * Create a new ApparentEnergy instance from a KilovoltampereHours
   *
   * @param value The unit as KilovoltampereHours to create a new ApparentEnergy from.
   * @returns The new ApparentEnergy instance.
   */
  static FromKilovoltampereHours(value) {
    return new ApparentEnergy(value, ApparentEnergyUnits.KilovoltampereHours);
  }
  /**
   * Create a new ApparentEnergy instance from a MegavoltampereHours
   *
   * @param value The unit as MegavoltampereHours to create a new ApparentEnergy from.
   * @returns The new ApparentEnergy instance.
   */
  static FromMegavoltampereHours(value) {
    return new ApparentEnergy(value, ApparentEnergyUnits.MegavoltampereHours);
  }
  /**
   * Gets the base unit enumeration associated with ApparentEnergy
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ApparentEnergyUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ApparentEnergyUnits.VoltampereHours;
  }
  /**
   * Create API DTO represent a ApparentEnergy unit.
   * @param holdInUnit The specific ApparentEnergy unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ApparentEnergyUnits.VoltampereHours) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ApparentEnergy unit from an API DTO representation.
   * @param dtoApparentEnergy The ApparentEnergy API DTO representation
   */
  static FromDto(dtoApparentEnergy) {
    return new ApparentEnergy(dtoApparentEnergy.value, dtoApparentEnergy.unit);
  }
  /**
   * Convert ApparentEnergy to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ApparentEnergyUnits.VoltampereHours:
        return this.VoltampereHours;
      case ApparentEnergyUnits.KilovoltampereHours:
        return this.KilovoltampereHours;
      case ApparentEnergyUnits.MegavoltampereHours:
        return this.MegavoltampereHours;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ApparentEnergyUnits.VoltampereHours:
        return this.value;
      case ApparentEnergyUnits.KilovoltampereHours:
        return super.internalDivide(this.value, 1000);
      case ApparentEnergyUnits.MegavoltampereHours:
        return super.internalDivide(this.value, 1000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ApparentEnergyUnits.VoltampereHours:
        return this.value;
      case ApparentEnergyUnits.KilovoltampereHours:
        return this.value / 1000;
      case ApparentEnergyUnits.MegavoltampereHours:
        return this.value / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ApparentEnergyUnits.VoltampereHours:
        return value;
      case ApparentEnergyUnits.KilovoltampereHours:
        return super.internalMultiply(value, 1000);
      case ApparentEnergyUnits.MegavoltampereHours:
        return super.internalMultiply(value, 1000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ApparentEnergyUnits.VoltampereHours:
        return value;
      case ApparentEnergyUnits.KilovoltampereHours:
        return value * 1000;
      case ApparentEnergyUnits.MegavoltampereHours:
        return value * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ApparentEnergy to string.
   * Note! the default format for ApparentEnergy is VoltampereHours.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ApparentEnergy.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ApparentEnergy.
   */
  toString(unit = ApparentEnergyUnits.VoltampereHours, fractionalDigits) {
    switch (unit) {
      case ApparentEnergyUnits.VoltampereHours:
        return super.truncateFractionDigits(this.VoltampereHours, fractionalDigits) + ` VAh`;
      case ApparentEnergyUnits.KilovoltampereHours:
        return super.truncateFractionDigits(this.KilovoltampereHours, fractionalDigits) + ` kVAh`;
      case ApparentEnergyUnits.MegavoltampereHours:
        return super.truncateFractionDigits(this.MegavoltampereHours, fractionalDigits) + ` MVAh`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ApparentEnergy unit abbreviation.
   * Note! the default abbreviation for ApparentEnergy is VoltampereHours.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ApparentEnergy.
   * @returns The abbreviation string of ApparentEnergy.
   */
  getUnitAbbreviation(unitAbbreviation = ApparentEnergyUnits.VoltampereHours) {
    switch (unitAbbreviation) {
      case ApparentEnergyUnits.VoltampereHours:
        return `VAh`;
      case ApparentEnergyUnits.KilovoltampereHours:
        return `kVAh`;
      case ApparentEnergyUnits.MegavoltampereHours:
        return `MVAh`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ApparentEnergy are equals to the current ApparentEnergy.
   * @param apparentEnergy The other ApparentEnergy.
   * @returns True if the given ApparentEnergy are equal to the current ApparentEnergy.
   */
  equals(apparentEnergy) {
    return super.internalEquals(this.value, apparentEnergy.BaseValue);
  }
  /**
   * Compare the given ApparentEnergy against the current ApparentEnergy.
   * @param apparentEnergy The other ApparentEnergy.
   * @returns 0 if they are equal, -1 if the current ApparentEnergy is less then other, 1 if the current ApparentEnergy is greater then other.
   */
  compareTo(apparentEnergy) {
    return super.internalCompareTo(this.value, apparentEnergy.BaseValue);
  }
  /**
   * Add the given ApparentEnergy with the current ApparentEnergy.
   * @param apparentEnergy The other ApparentEnergy.
   * @returns A new ApparentEnergy instance with the results.
   */
  add(apparentEnergy) {
    return new ApparentEnergy(super.internalAdd(this.value, apparentEnergy.BaseValue));
  }
  /**
   * Subtract the given ApparentEnergy with the current ApparentEnergy.
   * @param apparentEnergy The other ApparentEnergy.
   * @returns A new ApparentEnergy instance with the results.
   */
  subtract(apparentEnergy) {
    return new ApparentEnergy(super.internalSubtract(this.value, apparentEnergy.BaseValue));
  }
  /**
   * Multiply the given ApparentEnergy with the current ApparentEnergy.
   * @param apparentEnergy The other ApparentEnergy.
   * @returns A new ApparentEnergy instance with the results.
   */
  multiply(apparentEnergy) {
    return new ApparentEnergy(super.internalMultiply(this.value, apparentEnergy.BaseValue));
  }
  /**
   * Divide the given ApparentEnergy with the current ApparentEnergy.
   * @param apparentEnergy The other ApparentEnergy.
   * @returns A new ApparentEnergy instance with the results.
   */
  divide(apparentEnergy) {
    return new ApparentEnergy(super.internalDivide(this.value, apparentEnergy.BaseValue));
  }
  /**
   * Modulo the given ApparentEnergy with the current ApparentEnergy.
   * @param apparentEnergy The other ApparentEnergy.
   * @returns A new ApparentEnergy instance with the results.
   */
  modulo(apparentEnergy) {
    return new ApparentEnergy(super.internalModulo(this.value, apparentEnergy.BaseValue));
  }
  /**
   * Pow the given ApparentEnergy with the current ApparentEnergy.
   * @param apparentEnergy The other ApparentEnergy.
   * @returns A new ApparentEnergy instance with the results.
   */
  pow(apparentEnergy) {
    return new ApparentEnergy(super.internalPow(this.value, apparentEnergy.BaseValue));
  }
}
exports.ApparentEnergy = ApparentEnergy;