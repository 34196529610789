"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReciprocalLength = exports.ReciprocalLengthUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ReciprocalLengthUnits enumeration */
var ReciprocalLengthUnits;
(function (ReciprocalLengthUnits) {
  /** */
  ReciprocalLengthUnits["InverseMeters"] = "InverseMeter";
  /** */
  ReciprocalLengthUnits["InverseCentimeters"] = "InverseCentimeter";
  /** */
  ReciprocalLengthUnits["InverseMillimeters"] = "InverseMillimeter";
  /** */
  ReciprocalLengthUnits["InverseMiles"] = "InverseMile";
  /** */
  ReciprocalLengthUnits["InverseYards"] = "InverseYard";
  /** */
  ReciprocalLengthUnits["InverseFeet"] = "InverseFoot";
  /** */
  ReciprocalLengthUnits["InverseUsSurveyFeet"] = "InverseUsSurveyFoot";
  /** */
  ReciprocalLengthUnits["InverseInches"] = "InverseInch";
  /** */
  ReciprocalLengthUnits["InverseMils"] = "InverseMil";
  /** */
  ReciprocalLengthUnits["InverseMicroinches"] = "InverseMicroinch";
})(ReciprocalLengthUnits = exports.ReciprocalLengthUnits || (exports.ReciprocalLengthUnits = {}));
/** Reciprocal (Inverse) Length is used in various fields of science and mathematics. It is defined as the inverse value of a length unit. */
class ReciprocalLength extends base_unit_1.BaseUnit {
  /**
   * Create a new ReciprocalLength.
   * @param value The value.
   * @param fromUnit The ‘ReciprocalLength’ unit to create from.
   * The default unit is InverseMeters
   */
  constructor(value, fromUnit = ReciprocalLengthUnits.InverseMeters) {
    super();
    this.inversemetersLazy = null;
    this.inversecentimetersLazy = null;
    this.inversemillimetersLazy = null;
    this.inversemilesLazy = null;
    this.inverseyardsLazy = null;
    this.inversefeetLazy = null;
    this.inverseussurveyfeetLazy = null;
    this.inverseinchesLazy = null;
    this.inversemilsLazy = null;
    this.inversemicroinchesLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ReciprocalLength is InverseMeters.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ReciprocalLengthUnits.InverseMeters;
  }
  /** */
  get InverseMeters() {
    if (this.inversemetersLazy !== null) {
      return this.inversemetersLazy;
    }
    return this.inversemetersLazy = this.convertFromBase(ReciprocalLengthUnits.InverseMeters);
  }
  /** */
  get InverseCentimeters() {
    if (this.inversecentimetersLazy !== null) {
      return this.inversecentimetersLazy;
    }
    return this.inversecentimetersLazy = this.convertFromBase(ReciprocalLengthUnits.InverseCentimeters);
  }
  /** */
  get InverseMillimeters() {
    if (this.inversemillimetersLazy !== null) {
      return this.inversemillimetersLazy;
    }
    return this.inversemillimetersLazy = this.convertFromBase(ReciprocalLengthUnits.InverseMillimeters);
  }
  /** */
  get InverseMiles() {
    if (this.inversemilesLazy !== null) {
      return this.inversemilesLazy;
    }
    return this.inversemilesLazy = this.convertFromBase(ReciprocalLengthUnits.InverseMiles);
  }
  /** */
  get InverseYards() {
    if (this.inverseyardsLazy !== null) {
      return this.inverseyardsLazy;
    }
    return this.inverseyardsLazy = this.convertFromBase(ReciprocalLengthUnits.InverseYards);
  }
  /** */
  get InverseFeet() {
    if (this.inversefeetLazy !== null) {
      return this.inversefeetLazy;
    }
    return this.inversefeetLazy = this.convertFromBase(ReciprocalLengthUnits.InverseFeet);
  }
  /** */
  get InverseUsSurveyFeet() {
    if (this.inverseussurveyfeetLazy !== null) {
      return this.inverseussurveyfeetLazy;
    }
    return this.inverseussurveyfeetLazy = this.convertFromBase(ReciprocalLengthUnits.InverseUsSurveyFeet);
  }
  /** */
  get InverseInches() {
    if (this.inverseinchesLazy !== null) {
      return this.inverseinchesLazy;
    }
    return this.inverseinchesLazy = this.convertFromBase(ReciprocalLengthUnits.InverseInches);
  }
  /** */
  get InverseMils() {
    if (this.inversemilsLazy !== null) {
      return this.inversemilsLazy;
    }
    return this.inversemilsLazy = this.convertFromBase(ReciprocalLengthUnits.InverseMils);
  }
  /** */
  get InverseMicroinches() {
    if (this.inversemicroinchesLazy !== null) {
      return this.inversemicroinchesLazy;
    }
    return this.inversemicroinchesLazy = this.convertFromBase(ReciprocalLengthUnits.InverseMicroinches);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseMeters
   *
   * @param value The unit as InverseMeters to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseMeters(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseMeters);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseCentimeters
   *
   * @param value The unit as InverseCentimeters to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseCentimeters(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseCentimeters);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseMillimeters
   *
   * @param value The unit as InverseMillimeters to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseMillimeters(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseMillimeters);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseMiles
   *
   * @param value The unit as InverseMiles to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseMiles(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseMiles);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseYards
   *
   * @param value The unit as InverseYards to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseYards(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseYards);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseFeet
   *
   * @param value The unit as InverseFeet to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseFeet(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseFeet);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseUsSurveyFeet
   *
   * @param value The unit as InverseUsSurveyFeet to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseUsSurveyFeet(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseUsSurveyFeet);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseInches
   *
   * @param value The unit as InverseInches to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseInches(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseInches);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseMils
   *
   * @param value The unit as InverseMils to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseMils(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseMils);
  }
  /**
   * Create a new ReciprocalLength instance from a InverseMicroinches
   *
   * @param value The unit as InverseMicroinches to create a new ReciprocalLength from.
   * @returns The new ReciprocalLength instance.
   */
  static FromInverseMicroinches(value) {
    return new ReciprocalLength(value, ReciprocalLengthUnits.InverseMicroinches);
  }
  /**
   * Gets the base unit enumeration associated with ReciprocalLength
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ReciprocalLengthUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ReciprocalLengthUnits.InverseMeters;
  }
  /**
   * Create API DTO represent a ReciprocalLength unit.
   * @param holdInUnit The specific ReciprocalLength unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ReciprocalLengthUnits.InverseMeters) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ReciprocalLength unit from an API DTO representation.
   * @param dtoReciprocalLength The ReciprocalLength API DTO representation
   */
  static FromDto(dtoReciprocalLength) {
    return new ReciprocalLength(dtoReciprocalLength.value, dtoReciprocalLength.unit);
  }
  /**
   * Convert ReciprocalLength to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ReciprocalLengthUnits.InverseMeters:
        return this.InverseMeters;
      case ReciprocalLengthUnits.InverseCentimeters:
        return this.InverseCentimeters;
      case ReciprocalLengthUnits.InverseMillimeters:
        return this.InverseMillimeters;
      case ReciprocalLengthUnits.InverseMiles:
        return this.InverseMiles;
      case ReciprocalLengthUnits.InverseYards:
        return this.InverseYards;
      case ReciprocalLengthUnits.InverseFeet:
        return this.InverseFeet;
      case ReciprocalLengthUnits.InverseUsSurveyFeet:
        return this.InverseUsSurveyFeet;
      case ReciprocalLengthUnits.InverseInches:
        return this.InverseInches;
      case ReciprocalLengthUnits.InverseMils:
        return this.InverseMils;
      case ReciprocalLengthUnits.InverseMicroinches:
        return this.InverseMicroinches;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ReciprocalLengthUnits.InverseMeters:
        return this.value;
      case ReciprocalLengthUnits.InverseCentimeters:
        return super.internalDivide(this.value, 1e2);
      case ReciprocalLengthUnits.InverseMillimeters:
        return super.internalDivide(this.value, 1e3);
      case ReciprocalLengthUnits.InverseMiles:
        return super.internalMultiply(this.value, 1609.344);
      case ReciprocalLengthUnits.InverseYards:
        return super.internalMultiply(this.value, 0.9144);
      case ReciprocalLengthUnits.InverseFeet:
        return super.internalMultiply(this.value, 0.3048);
      case ReciprocalLengthUnits.InverseUsSurveyFeet:
        {
          const v4 = super.internalDivide(1200, 3937);
          return super.internalMultiply(this.value, v4);
        }
      case ReciprocalLengthUnits.InverseInches:
        return super.internalMultiply(this.value, 2.54e-2);
      case ReciprocalLengthUnits.InverseMils:
        return super.internalMultiply(this.value, 2.54e-5);
      case ReciprocalLengthUnits.InverseMicroinches:
        return super.internalMultiply(this.value, 2.54e-8);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ReciprocalLengthUnits.InverseMeters:
        return this.value;
      case ReciprocalLengthUnits.InverseCentimeters:
        return this.value / 1e2;
      case ReciprocalLengthUnits.InverseMillimeters:
        return this.value / 1e3;
      case ReciprocalLengthUnits.InverseMiles:
        return this.value * 1609.344;
      case ReciprocalLengthUnits.InverseYards:
        return this.value * 0.9144;
      case ReciprocalLengthUnits.InverseFeet:
        return this.value * 0.3048;
      case ReciprocalLengthUnits.InverseUsSurveyFeet:
        return this.value * 1200 / 3937;
      case ReciprocalLengthUnits.InverseInches:
        return this.value * 2.54e-2;
      case ReciprocalLengthUnits.InverseMils:
        return this.value * 2.54e-5;
      case ReciprocalLengthUnits.InverseMicroinches:
        return this.value * 2.54e-8;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ReciprocalLengthUnits.InverseMeters:
        return value;
      case ReciprocalLengthUnits.InverseCentimeters:
        return super.internalMultiply(value, 1e2);
      case ReciprocalLengthUnits.InverseMillimeters:
        return super.internalMultiply(value, 1e3);
      case ReciprocalLengthUnits.InverseMiles:
        return super.internalDivide(value, 1609.344);
      case ReciprocalLengthUnits.InverseYards:
        return super.internalDivide(value, 0.9144);
      case ReciprocalLengthUnits.InverseFeet:
        return super.internalDivide(value, 0.3048);
      case ReciprocalLengthUnits.InverseUsSurveyFeet:
        {
          const v4 = super.internalDivide(3937, 1200);
          return super.internalMultiply(value, v4);
        }
      case ReciprocalLengthUnits.InverseInches:
        return super.internalDivide(value, 2.54e-2);
      case ReciprocalLengthUnits.InverseMils:
        return super.internalDivide(value, 2.54e-5);
      case ReciprocalLengthUnits.InverseMicroinches:
        return super.internalDivide(value, 2.54e-8);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ReciprocalLengthUnits.InverseMeters:
        return value;
      case ReciprocalLengthUnits.InverseCentimeters:
        return value * 1e2;
      case ReciprocalLengthUnits.InverseMillimeters:
        return value * 1e3;
      case ReciprocalLengthUnits.InverseMiles:
        return value / 1609.344;
      case ReciprocalLengthUnits.InverseYards:
        return value / 0.9144;
      case ReciprocalLengthUnits.InverseFeet:
        return value / 0.3048;
      case ReciprocalLengthUnits.InverseUsSurveyFeet:
        return value * 3937 / 1200;
      case ReciprocalLengthUnits.InverseInches:
        return value / 2.54e-2;
      case ReciprocalLengthUnits.InverseMils:
        return value / 2.54e-5;
      case ReciprocalLengthUnits.InverseMicroinches:
        return value / 2.54e-8;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ReciprocalLength to string.
   * Note! the default format for ReciprocalLength is InverseMeters.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ReciprocalLength.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ReciprocalLength.
   */
  toString(unit = ReciprocalLengthUnits.InverseMeters, fractionalDigits) {
    switch (unit) {
      case ReciprocalLengthUnits.InverseMeters:
        return super.truncateFractionDigits(this.InverseMeters, fractionalDigits) + ` m⁻¹`;
      case ReciprocalLengthUnits.InverseCentimeters:
        return super.truncateFractionDigits(this.InverseCentimeters, fractionalDigits) + ` cm⁻¹`;
      case ReciprocalLengthUnits.InverseMillimeters:
        return super.truncateFractionDigits(this.InverseMillimeters, fractionalDigits) + ` mm⁻¹`;
      case ReciprocalLengthUnits.InverseMiles:
        return super.truncateFractionDigits(this.InverseMiles, fractionalDigits) + ` mi⁻¹`;
      case ReciprocalLengthUnits.InverseYards:
        return super.truncateFractionDigits(this.InverseYards, fractionalDigits) + ` yd⁻¹`;
      case ReciprocalLengthUnits.InverseFeet:
        return super.truncateFractionDigits(this.InverseFeet, fractionalDigits) + ` ft⁻¹`;
      case ReciprocalLengthUnits.InverseUsSurveyFeet:
        return super.truncateFractionDigits(this.InverseUsSurveyFeet, fractionalDigits) + ` ftUS⁻¹`;
      case ReciprocalLengthUnits.InverseInches:
        return super.truncateFractionDigits(this.InverseInches, fractionalDigits) + ` in⁻¹`;
      case ReciprocalLengthUnits.InverseMils:
        return super.truncateFractionDigits(this.InverseMils, fractionalDigits) + ` mil⁻¹`;
      case ReciprocalLengthUnits.InverseMicroinches:
        return super.truncateFractionDigits(this.InverseMicroinches, fractionalDigits) + ` µin⁻¹`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ReciprocalLength unit abbreviation.
   * Note! the default abbreviation for ReciprocalLength is InverseMeters.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ReciprocalLength.
   * @returns The abbreviation string of ReciprocalLength.
   */
  getUnitAbbreviation(unitAbbreviation = ReciprocalLengthUnits.InverseMeters) {
    switch (unitAbbreviation) {
      case ReciprocalLengthUnits.InverseMeters:
        return `m⁻¹`;
      case ReciprocalLengthUnits.InverseCentimeters:
        return `cm⁻¹`;
      case ReciprocalLengthUnits.InverseMillimeters:
        return `mm⁻¹`;
      case ReciprocalLengthUnits.InverseMiles:
        return `mi⁻¹`;
      case ReciprocalLengthUnits.InverseYards:
        return `yd⁻¹`;
      case ReciprocalLengthUnits.InverseFeet:
        return `ft⁻¹`;
      case ReciprocalLengthUnits.InverseUsSurveyFeet:
        return `ftUS⁻¹`;
      case ReciprocalLengthUnits.InverseInches:
        return `in⁻¹`;
      case ReciprocalLengthUnits.InverseMils:
        return `mil⁻¹`;
      case ReciprocalLengthUnits.InverseMicroinches:
        return `µin⁻¹`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ReciprocalLength are equals to the current ReciprocalLength.
   * @param reciprocalLength The other ReciprocalLength.
   * @returns True if the given ReciprocalLength are equal to the current ReciprocalLength.
   */
  equals(reciprocalLength) {
    return super.internalEquals(this.value, reciprocalLength.BaseValue);
  }
  /**
   * Compare the given ReciprocalLength against the current ReciprocalLength.
   * @param reciprocalLength The other ReciprocalLength.
   * @returns 0 if they are equal, -1 if the current ReciprocalLength is less then other, 1 if the current ReciprocalLength is greater then other.
   */
  compareTo(reciprocalLength) {
    return super.internalCompareTo(this.value, reciprocalLength.BaseValue);
  }
  /**
   * Add the given ReciprocalLength with the current ReciprocalLength.
   * @param reciprocalLength The other ReciprocalLength.
   * @returns A new ReciprocalLength instance with the results.
   */
  add(reciprocalLength) {
    return new ReciprocalLength(super.internalAdd(this.value, reciprocalLength.BaseValue));
  }
  /**
   * Subtract the given ReciprocalLength with the current ReciprocalLength.
   * @param reciprocalLength The other ReciprocalLength.
   * @returns A new ReciprocalLength instance with the results.
   */
  subtract(reciprocalLength) {
    return new ReciprocalLength(super.internalSubtract(this.value, reciprocalLength.BaseValue));
  }
  /**
   * Multiply the given ReciprocalLength with the current ReciprocalLength.
   * @param reciprocalLength The other ReciprocalLength.
   * @returns A new ReciprocalLength instance with the results.
   */
  multiply(reciprocalLength) {
    return new ReciprocalLength(super.internalMultiply(this.value, reciprocalLength.BaseValue));
  }
  /**
   * Divide the given ReciprocalLength with the current ReciprocalLength.
   * @param reciprocalLength The other ReciprocalLength.
   * @returns A new ReciprocalLength instance with the results.
   */
  divide(reciprocalLength) {
    return new ReciprocalLength(super.internalDivide(this.value, reciprocalLength.BaseValue));
  }
  /**
   * Modulo the given ReciprocalLength with the current ReciprocalLength.
   * @param reciprocalLength The other ReciprocalLength.
   * @returns A new ReciprocalLength instance with the results.
   */
  modulo(reciprocalLength) {
    return new ReciprocalLength(super.internalModulo(this.value, reciprocalLength.BaseValue));
  }
  /**
   * Pow the given ReciprocalLength with the current ReciprocalLength.
   * @param reciprocalLength The other ReciprocalLength.
   * @returns A new ReciprocalLength instance with the results.
   */
  pow(reciprocalLength) {
    return new ReciprocalLength(super.internalPow(this.value, reciprocalLength.BaseValue));
  }
}
exports.ReciprocalLength = ReciprocalLength;