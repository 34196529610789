"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricCurrentDensity = exports.ElectricCurrentDensityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricCurrentDensityUnits enumeration */
var ElectricCurrentDensityUnits;
(function (ElectricCurrentDensityUnits) {
  /** */
  ElectricCurrentDensityUnits["AmperesPerSquareMeter"] = "AmperePerSquareMeter";
  /** */
  ElectricCurrentDensityUnits["AmperesPerSquareInch"] = "AmperePerSquareInch";
  /** */
  ElectricCurrentDensityUnits["AmperesPerSquareFoot"] = "AmperePerSquareFoot";
})(ElectricCurrentDensityUnits = exports.ElectricCurrentDensityUnits || (exports.ElectricCurrentDensityUnits = {}));
/** In electromagnetism, current density is the electric current per unit area of cross section. */
class ElectricCurrentDensity extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricCurrentDensity.
   * @param value The value.
   * @param fromUnit The ‘ElectricCurrentDensity’ unit to create from.
   * The default unit is AmperesPerSquareMeter
   */
  constructor(value, fromUnit = ElectricCurrentDensityUnits.AmperesPerSquareMeter) {
    super();
    this.amperespersquaremeterLazy = null;
    this.amperespersquareinchLazy = null;
    this.amperespersquarefootLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricCurrentDensity is AmperesPerSquareMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricCurrentDensityUnits.AmperesPerSquareMeter;
  }
  /** */
  get AmperesPerSquareMeter() {
    if (this.amperespersquaremeterLazy !== null) {
      return this.amperespersquaremeterLazy;
    }
    return this.amperespersquaremeterLazy = this.convertFromBase(ElectricCurrentDensityUnits.AmperesPerSquareMeter);
  }
  /** */
  get AmperesPerSquareInch() {
    if (this.amperespersquareinchLazy !== null) {
      return this.amperespersquareinchLazy;
    }
    return this.amperespersquareinchLazy = this.convertFromBase(ElectricCurrentDensityUnits.AmperesPerSquareInch);
  }
  /** */
  get AmperesPerSquareFoot() {
    if (this.amperespersquarefootLazy !== null) {
      return this.amperespersquarefootLazy;
    }
    return this.amperespersquarefootLazy = this.convertFromBase(ElectricCurrentDensityUnits.AmperesPerSquareFoot);
  }
  /**
   * Create a new ElectricCurrentDensity instance from a AmperesPerSquareMeter
   *
   * @param value The unit as AmperesPerSquareMeter to create a new ElectricCurrentDensity from.
   * @returns The new ElectricCurrentDensity instance.
   */
  static FromAmperesPerSquareMeter(value) {
    return new ElectricCurrentDensity(value, ElectricCurrentDensityUnits.AmperesPerSquareMeter);
  }
  /**
   * Create a new ElectricCurrentDensity instance from a AmperesPerSquareInch
   *
   * @param value The unit as AmperesPerSquareInch to create a new ElectricCurrentDensity from.
   * @returns The new ElectricCurrentDensity instance.
   */
  static FromAmperesPerSquareInch(value) {
    return new ElectricCurrentDensity(value, ElectricCurrentDensityUnits.AmperesPerSquareInch);
  }
  /**
   * Create a new ElectricCurrentDensity instance from a AmperesPerSquareFoot
   *
   * @param value The unit as AmperesPerSquareFoot to create a new ElectricCurrentDensity from.
   * @returns The new ElectricCurrentDensity instance.
   */
  static FromAmperesPerSquareFoot(value) {
    return new ElectricCurrentDensity(value, ElectricCurrentDensityUnits.AmperesPerSquareFoot);
  }
  /**
   * Gets the base unit enumeration associated with ElectricCurrentDensity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricCurrentDensityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricCurrentDensityUnits.AmperesPerSquareMeter;
  }
  /**
   * Create API DTO represent a ElectricCurrentDensity unit.
   * @param holdInUnit The specific ElectricCurrentDensity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricCurrentDensityUnits.AmperesPerSquareMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricCurrentDensity unit from an API DTO representation.
   * @param dtoElectricCurrentDensity The ElectricCurrentDensity API DTO representation
   */
  static FromDto(dtoElectricCurrentDensity) {
    return new ElectricCurrentDensity(dtoElectricCurrentDensity.value, dtoElectricCurrentDensity.unit);
  }
  /**
   * Convert ElectricCurrentDensity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricCurrentDensityUnits.AmperesPerSquareMeter:
        return this.AmperesPerSquareMeter;
      case ElectricCurrentDensityUnits.AmperesPerSquareInch:
        return this.AmperesPerSquareInch;
      case ElectricCurrentDensityUnits.AmperesPerSquareFoot:
        return this.AmperesPerSquareFoot;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricCurrentDensityUnits.AmperesPerSquareMeter:
        return this.value;
      case ElectricCurrentDensityUnits.AmperesPerSquareInch:
        return super.internalDivide(this.value, 1.5500031000062000e3);
      case ElectricCurrentDensityUnits.AmperesPerSquareFoot:
        return super.internalDivide(this.value, 1.0763910416709722e1);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricCurrentDensityUnits.AmperesPerSquareMeter:
        return this.value;
      case ElectricCurrentDensityUnits.AmperesPerSquareInch:
        return this.value / 1.5500031000062000e3;
      case ElectricCurrentDensityUnits.AmperesPerSquareFoot:
        return this.value / 1.0763910416709722e1;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricCurrentDensityUnits.AmperesPerSquareMeter:
        return value;
      case ElectricCurrentDensityUnits.AmperesPerSquareInch:
        return super.internalMultiply(value, 1.5500031000062000e3);
      case ElectricCurrentDensityUnits.AmperesPerSquareFoot:
        return super.internalMultiply(value, 1.0763910416709722e1);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricCurrentDensityUnits.AmperesPerSquareMeter:
        return value;
      case ElectricCurrentDensityUnits.AmperesPerSquareInch:
        return value * 1.5500031000062000e3;
      case ElectricCurrentDensityUnits.AmperesPerSquareFoot:
        return value * 1.0763910416709722e1;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricCurrentDensity to string.
   * Note! the default format for ElectricCurrentDensity is AmperesPerSquareMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricCurrentDensity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricCurrentDensity.
   */
  toString(unit = ElectricCurrentDensityUnits.AmperesPerSquareMeter, fractionalDigits) {
    switch (unit) {
      case ElectricCurrentDensityUnits.AmperesPerSquareMeter:
        return super.truncateFractionDigits(this.AmperesPerSquareMeter, fractionalDigits) + ` A/m²`;
      case ElectricCurrentDensityUnits.AmperesPerSquareInch:
        return super.truncateFractionDigits(this.AmperesPerSquareInch, fractionalDigits) + ` A/in²`;
      case ElectricCurrentDensityUnits.AmperesPerSquareFoot:
        return super.truncateFractionDigits(this.AmperesPerSquareFoot, fractionalDigits) + ` A/ft²`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricCurrentDensity unit abbreviation.
   * Note! the default abbreviation for ElectricCurrentDensity is AmperesPerSquareMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricCurrentDensity.
   * @returns The abbreviation string of ElectricCurrentDensity.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricCurrentDensityUnits.AmperesPerSquareMeter) {
    switch (unitAbbreviation) {
      case ElectricCurrentDensityUnits.AmperesPerSquareMeter:
        return `A/m²`;
      case ElectricCurrentDensityUnits.AmperesPerSquareInch:
        return `A/in²`;
      case ElectricCurrentDensityUnits.AmperesPerSquareFoot:
        return `A/ft²`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricCurrentDensity are equals to the current ElectricCurrentDensity.
   * @param electricCurrentDensity The other ElectricCurrentDensity.
   * @returns True if the given ElectricCurrentDensity are equal to the current ElectricCurrentDensity.
   */
  equals(electricCurrentDensity) {
    return super.internalEquals(this.value, electricCurrentDensity.BaseValue);
  }
  /**
   * Compare the given ElectricCurrentDensity against the current ElectricCurrentDensity.
   * @param electricCurrentDensity The other ElectricCurrentDensity.
   * @returns 0 if they are equal, -1 if the current ElectricCurrentDensity is less then other, 1 if the current ElectricCurrentDensity is greater then other.
   */
  compareTo(electricCurrentDensity) {
    return super.internalCompareTo(this.value, electricCurrentDensity.BaseValue);
  }
  /**
   * Add the given ElectricCurrentDensity with the current ElectricCurrentDensity.
   * @param electricCurrentDensity The other ElectricCurrentDensity.
   * @returns A new ElectricCurrentDensity instance with the results.
   */
  add(electricCurrentDensity) {
    return new ElectricCurrentDensity(super.internalAdd(this.value, electricCurrentDensity.BaseValue));
  }
  /**
   * Subtract the given ElectricCurrentDensity with the current ElectricCurrentDensity.
   * @param electricCurrentDensity The other ElectricCurrentDensity.
   * @returns A new ElectricCurrentDensity instance with the results.
   */
  subtract(electricCurrentDensity) {
    return new ElectricCurrentDensity(super.internalSubtract(this.value, electricCurrentDensity.BaseValue));
  }
  /**
   * Multiply the given ElectricCurrentDensity with the current ElectricCurrentDensity.
   * @param electricCurrentDensity The other ElectricCurrentDensity.
   * @returns A new ElectricCurrentDensity instance with the results.
   */
  multiply(electricCurrentDensity) {
    return new ElectricCurrentDensity(super.internalMultiply(this.value, electricCurrentDensity.BaseValue));
  }
  /**
   * Divide the given ElectricCurrentDensity with the current ElectricCurrentDensity.
   * @param electricCurrentDensity The other ElectricCurrentDensity.
   * @returns A new ElectricCurrentDensity instance with the results.
   */
  divide(electricCurrentDensity) {
    return new ElectricCurrentDensity(super.internalDivide(this.value, electricCurrentDensity.BaseValue));
  }
  /**
   * Modulo the given ElectricCurrentDensity with the current ElectricCurrentDensity.
   * @param electricCurrentDensity The other ElectricCurrentDensity.
   * @returns A new ElectricCurrentDensity instance with the results.
   */
  modulo(electricCurrentDensity) {
    return new ElectricCurrentDensity(super.internalModulo(this.value, electricCurrentDensity.BaseValue));
  }
  /**
   * Pow the given ElectricCurrentDensity with the current ElectricCurrentDensity.
   * @param electricCurrentDensity The other ElectricCurrentDensity.
   * @returns A new ElectricCurrentDensity instance with the results.
   */
  pow(electricCurrentDensity) {
    return new ElectricCurrentDensity(super.internalPow(this.value, electricCurrentDensity.BaseValue));
  }
}
exports.ElectricCurrentDensity = ElectricCurrentDensity;