"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricCurrent = exports.ElectricCurrentUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricCurrentUnits enumeration */
var ElectricCurrentUnits;
(function (ElectricCurrentUnits) {
  /** */
  ElectricCurrentUnits["Amperes"] = "Ampere";
  /** */
  ElectricCurrentUnits["Femtoamperes"] = "Femtoampere";
  /** */
  ElectricCurrentUnits["Picoamperes"] = "Picoampere";
  /** */
  ElectricCurrentUnits["Nanoamperes"] = "Nanoampere";
  /** */
  ElectricCurrentUnits["Microamperes"] = "Microampere";
  /** */
  ElectricCurrentUnits["Milliamperes"] = "Milliampere";
  /** */
  ElectricCurrentUnits["Centiamperes"] = "Centiampere";
  /** */
  ElectricCurrentUnits["Kiloamperes"] = "Kiloampere";
  /** */
  ElectricCurrentUnits["Megaamperes"] = "Megaampere";
})(ElectricCurrentUnits = exports.ElectricCurrentUnits || (exports.ElectricCurrentUnits = {}));
/** An electric current is a flow of electric charge. In electric circuits this charge is often carried by moving electrons in a wire. It can also be carried by ions in an electrolyte, or by both ions and electrons such as in a plasma. */
class ElectricCurrent extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricCurrent.
   * @param value The value.
   * @param fromUnit The ‘ElectricCurrent’ unit to create from.
   * The default unit is Amperes
   */
  constructor(value, fromUnit = ElectricCurrentUnits.Amperes) {
    super();
    this.amperesLazy = null;
    this.femtoamperesLazy = null;
    this.picoamperesLazy = null;
    this.nanoamperesLazy = null;
    this.microamperesLazy = null;
    this.milliamperesLazy = null;
    this.centiamperesLazy = null;
    this.kiloamperesLazy = null;
    this.megaamperesLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricCurrent is Amperes.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricCurrentUnits.Amperes;
  }
  /** */
  get Amperes() {
    if (this.amperesLazy !== null) {
      return this.amperesLazy;
    }
    return this.amperesLazy = this.convertFromBase(ElectricCurrentUnits.Amperes);
  }
  /** */
  get Femtoamperes() {
    if (this.femtoamperesLazy !== null) {
      return this.femtoamperesLazy;
    }
    return this.femtoamperesLazy = this.convertFromBase(ElectricCurrentUnits.Femtoamperes);
  }
  /** */
  get Picoamperes() {
    if (this.picoamperesLazy !== null) {
      return this.picoamperesLazy;
    }
    return this.picoamperesLazy = this.convertFromBase(ElectricCurrentUnits.Picoamperes);
  }
  /** */
  get Nanoamperes() {
    if (this.nanoamperesLazy !== null) {
      return this.nanoamperesLazy;
    }
    return this.nanoamperesLazy = this.convertFromBase(ElectricCurrentUnits.Nanoamperes);
  }
  /** */
  get Microamperes() {
    if (this.microamperesLazy !== null) {
      return this.microamperesLazy;
    }
    return this.microamperesLazy = this.convertFromBase(ElectricCurrentUnits.Microamperes);
  }
  /** */
  get Milliamperes() {
    if (this.milliamperesLazy !== null) {
      return this.milliamperesLazy;
    }
    return this.milliamperesLazy = this.convertFromBase(ElectricCurrentUnits.Milliamperes);
  }
  /** */
  get Centiamperes() {
    if (this.centiamperesLazy !== null) {
      return this.centiamperesLazy;
    }
    return this.centiamperesLazy = this.convertFromBase(ElectricCurrentUnits.Centiamperes);
  }
  /** */
  get Kiloamperes() {
    if (this.kiloamperesLazy !== null) {
      return this.kiloamperesLazy;
    }
    return this.kiloamperesLazy = this.convertFromBase(ElectricCurrentUnits.Kiloamperes);
  }
  /** */
  get Megaamperes() {
    if (this.megaamperesLazy !== null) {
      return this.megaamperesLazy;
    }
    return this.megaamperesLazy = this.convertFromBase(ElectricCurrentUnits.Megaamperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Amperes
   *
   * @param value The unit as Amperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromAmperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Amperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Femtoamperes
   *
   * @param value The unit as Femtoamperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromFemtoamperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Femtoamperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Picoamperes
   *
   * @param value The unit as Picoamperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromPicoamperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Picoamperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Nanoamperes
   *
   * @param value The unit as Nanoamperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromNanoamperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Nanoamperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Microamperes
   *
   * @param value The unit as Microamperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromMicroamperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Microamperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Milliamperes
   *
   * @param value The unit as Milliamperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromMilliamperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Milliamperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Centiamperes
   *
   * @param value The unit as Centiamperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromCentiamperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Centiamperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Kiloamperes
   *
   * @param value The unit as Kiloamperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromKiloamperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Kiloamperes);
  }
  /**
   * Create a new ElectricCurrent instance from a Megaamperes
   *
   * @param value The unit as Megaamperes to create a new ElectricCurrent from.
   * @returns The new ElectricCurrent instance.
   */
  static FromMegaamperes(value) {
    return new ElectricCurrent(value, ElectricCurrentUnits.Megaamperes);
  }
  /**
   * Gets the base unit enumeration associated with ElectricCurrent
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricCurrentUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricCurrentUnits.Amperes;
  }
  /**
   * Create API DTO represent a ElectricCurrent unit.
   * @param holdInUnit The specific ElectricCurrent unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricCurrentUnits.Amperes) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricCurrent unit from an API DTO representation.
   * @param dtoElectricCurrent The ElectricCurrent API DTO representation
   */
  static FromDto(dtoElectricCurrent) {
    return new ElectricCurrent(dtoElectricCurrent.value, dtoElectricCurrent.unit);
  }
  /**
   * Convert ElectricCurrent to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricCurrentUnits.Amperes:
        return this.Amperes;
      case ElectricCurrentUnits.Femtoamperes:
        return this.Femtoamperes;
      case ElectricCurrentUnits.Picoamperes:
        return this.Picoamperes;
      case ElectricCurrentUnits.Nanoamperes:
        return this.Nanoamperes;
      case ElectricCurrentUnits.Microamperes:
        return this.Microamperes;
      case ElectricCurrentUnits.Milliamperes:
        return this.Milliamperes;
      case ElectricCurrentUnits.Centiamperes:
        return this.Centiamperes;
      case ElectricCurrentUnits.Kiloamperes:
        return this.Kiloamperes;
      case ElectricCurrentUnits.Megaamperes:
        return this.Megaamperes;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricCurrentUnits.Amperes:
        return this.value;
      case ElectricCurrentUnits.Femtoamperes:
        return super.internalDivide(this.value, 1e-15);
      case ElectricCurrentUnits.Picoamperes:
        return super.internalDivide(this.value, 1e-12);
      case ElectricCurrentUnits.Nanoamperes:
        return super.internalDivide(this.value, 1e-9);
      case ElectricCurrentUnits.Microamperes:
        return super.internalDivide(this.value, 0.000001);
      case ElectricCurrentUnits.Milliamperes:
        return super.internalDivide(this.value, 0.001);
      case ElectricCurrentUnits.Centiamperes:
        return super.internalDivide(this.value, 0.01);
      case ElectricCurrentUnits.Kiloamperes:
        return super.internalDivide(this.value, 1000);
      case ElectricCurrentUnits.Megaamperes:
        return super.internalDivide(this.value, 1000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricCurrentUnits.Amperes:
        return this.value;
      case ElectricCurrentUnits.Femtoamperes:
        return this.value / 1e-15;
      case ElectricCurrentUnits.Picoamperes:
        return this.value / 1e-12;
      case ElectricCurrentUnits.Nanoamperes:
        return this.value / 1e-9;
      case ElectricCurrentUnits.Microamperes:
        return this.value / 0.000001;
      case ElectricCurrentUnits.Milliamperes:
        return this.value / 0.001;
      case ElectricCurrentUnits.Centiamperes:
        return this.value / 0.01;
      case ElectricCurrentUnits.Kiloamperes:
        return this.value / 1000;
      case ElectricCurrentUnits.Megaamperes:
        return this.value / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricCurrentUnits.Amperes:
        return value;
      case ElectricCurrentUnits.Femtoamperes:
        return super.internalMultiply(value, 1e-15);
      case ElectricCurrentUnits.Picoamperes:
        return super.internalMultiply(value, 1e-12);
      case ElectricCurrentUnits.Nanoamperes:
        return super.internalMultiply(value, 1e-9);
      case ElectricCurrentUnits.Microamperes:
        return super.internalMultiply(value, 0.000001);
      case ElectricCurrentUnits.Milliamperes:
        return super.internalMultiply(value, 0.001);
      case ElectricCurrentUnits.Centiamperes:
        return super.internalMultiply(value, 0.01);
      case ElectricCurrentUnits.Kiloamperes:
        return super.internalMultiply(value, 1000);
      case ElectricCurrentUnits.Megaamperes:
        return super.internalMultiply(value, 1000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricCurrentUnits.Amperes:
        return value;
      case ElectricCurrentUnits.Femtoamperes:
        return value * 1e-15;
      case ElectricCurrentUnits.Picoamperes:
        return value * 1e-12;
      case ElectricCurrentUnits.Nanoamperes:
        return value * 1e-9;
      case ElectricCurrentUnits.Microamperes:
        return value * 0.000001;
      case ElectricCurrentUnits.Milliamperes:
        return value * 0.001;
      case ElectricCurrentUnits.Centiamperes:
        return value * 0.01;
      case ElectricCurrentUnits.Kiloamperes:
        return value * 1000;
      case ElectricCurrentUnits.Megaamperes:
        return value * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricCurrent to string.
   * Note! the default format for ElectricCurrent is Amperes.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricCurrent.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricCurrent.
   */
  toString(unit = ElectricCurrentUnits.Amperes, fractionalDigits) {
    switch (unit) {
      case ElectricCurrentUnits.Amperes:
        return super.truncateFractionDigits(this.Amperes, fractionalDigits) + ` A`;
      case ElectricCurrentUnits.Femtoamperes:
        return super.truncateFractionDigits(this.Femtoamperes, fractionalDigits) + ` fA`;
      case ElectricCurrentUnits.Picoamperes:
        return super.truncateFractionDigits(this.Picoamperes, fractionalDigits) + ` pA`;
      case ElectricCurrentUnits.Nanoamperes:
        return super.truncateFractionDigits(this.Nanoamperes, fractionalDigits) + ` nA`;
      case ElectricCurrentUnits.Microamperes:
        return super.truncateFractionDigits(this.Microamperes, fractionalDigits) + ` μA`;
      case ElectricCurrentUnits.Milliamperes:
        return super.truncateFractionDigits(this.Milliamperes, fractionalDigits) + ` mA`;
      case ElectricCurrentUnits.Centiamperes:
        return super.truncateFractionDigits(this.Centiamperes, fractionalDigits) + ` cA`;
      case ElectricCurrentUnits.Kiloamperes:
        return super.truncateFractionDigits(this.Kiloamperes, fractionalDigits) + ` kA`;
      case ElectricCurrentUnits.Megaamperes:
        return super.truncateFractionDigits(this.Megaamperes, fractionalDigits) + ` MA`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricCurrent unit abbreviation.
   * Note! the default abbreviation for ElectricCurrent is Amperes.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricCurrent.
   * @returns The abbreviation string of ElectricCurrent.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricCurrentUnits.Amperes) {
    switch (unitAbbreviation) {
      case ElectricCurrentUnits.Amperes:
        return `A`;
      case ElectricCurrentUnits.Femtoamperes:
        return `fA`;
      case ElectricCurrentUnits.Picoamperes:
        return `pA`;
      case ElectricCurrentUnits.Nanoamperes:
        return `nA`;
      case ElectricCurrentUnits.Microamperes:
        return `μA`;
      case ElectricCurrentUnits.Milliamperes:
        return `mA`;
      case ElectricCurrentUnits.Centiamperes:
        return `cA`;
      case ElectricCurrentUnits.Kiloamperes:
        return `kA`;
      case ElectricCurrentUnits.Megaamperes:
        return `MA`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricCurrent are equals to the current ElectricCurrent.
   * @param electricCurrent The other ElectricCurrent.
   * @returns True if the given ElectricCurrent are equal to the current ElectricCurrent.
   */
  equals(electricCurrent) {
    return super.internalEquals(this.value, electricCurrent.BaseValue);
  }
  /**
   * Compare the given ElectricCurrent against the current ElectricCurrent.
   * @param electricCurrent The other ElectricCurrent.
   * @returns 0 if they are equal, -1 if the current ElectricCurrent is less then other, 1 if the current ElectricCurrent is greater then other.
   */
  compareTo(electricCurrent) {
    return super.internalCompareTo(this.value, electricCurrent.BaseValue);
  }
  /**
   * Add the given ElectricCurrent with the current ElectricCurrent.
   * @param electricCurrent The other ElectricCurrent.
   * @returns A new ElectricCurrent instance with the results.
   */
  add(electricCurrent) {
    return new ElectricCurrent(super.internalAdd(this.value, electricCurrent.BaseValue));
  }
  /**
   * Subtract the given ElectricCurrent with the current ElectricCurrent.
   * @param electricCurrent The other ElectricCurrent.
   * @returns A new ElectricCurrent instance with the results.
   */
  subtract(electricCurrent) {
    return new ElectricCurrent(super.internalSubtract(this.value, electricCurrent.BaseValue));
  }
  /**
   * Multiply the given ElectricCurrent with the current ElectricCurrent.
   * @param electricCurrent The other ElectricCurrent.
   * @returns A new ElectricCurrent instance with the results.
   */
  multiply(electricCurrent) {
    return new ElectricCurrent(super.internalMultiply(this.value, electricCurrent.BaseValue));
  }
  /**
   * Divide the given ElectricCurrent with the current ElectricCurrent.
   * @param electricCurrent The other ElectricCurrent.
   * @returns A new ElectricCurrent instance with the results.
   */
  divide(electricCurrent) {
    return new ElectricCurrent(super.internalDivide(this.value, electricCurrent.BaseValue));
  }
  /**
   * Modulo the given ElectricCurrent with the current ElectricCurrent.
   * @param electricCurrent The other ElectricCurrent.
   * @returns A new ElectricCurrent instance with the results.
   */
  modulo(electricCurrent) {
    return new ElectricCurrent(super.internalModulo(this.value, electricCurrent.BaseValue));
  }
  /**
   * Pow the given ElectricCurrent with the current ElectricCurrent.
   * @param electricCurrent The other ElectricCurrent.
   * @returns A new ElectricCurrent instance with the results.
   */
  pow(electricCurrent) {
    return new ElectricCurrent(super.internalPow(this.value, electricCurrent.BaseValue));
  }
}
exports.ElectricCurrent = ElectricCurrent;