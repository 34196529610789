import { Injectable } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomMessageService {
  private errorMsgs = new Subject<any>();
  errorMsgs$ = this.errorMsgs.asObservable();
  
  constructor(private messageService : MessageService) {}

  showMessage(msgObj : Message) {
    this.messageService.add(msgObj);
    this.addErrorMessage(msgObj)
  }

  addErrorMessage(error: any) {
    error['dateTime'] =  this.getDateString(new Date());
    this.errorMsgs.next(error);
  }

  public getDateString(date: Date) {   
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    const dateText = (m < 10 ? '0' : '') + m + '/' + (d < 10 ? '0' : '') + d + '/' + y + " ";
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const mins = minutes < 10 ? '0' + minutes : minutes;
    const secs = seconds < 10 ? '0' + seconds : seconds;
    const timeText = hours + ':' + mins + ':' + secs + ' ' + ampm;
    return dateText + timeText;
  }    
}