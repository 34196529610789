import { EventEmitter, Injectable, Output } from '@angular/core';
//import { AgStatusbarComponent } from "./../components/ag-statusbarall/ag-statusbar/ag-statusbar.component";

@Injectable({
  providedIn: 'root'
})
export class AgEventhandlerService {

  @Output() selectionChangedEvent = new EventEmitter<number>();
  @Output() updateChangedEvent = new EventEmitter<number>();

  constructor() { }

  emitSelectionChange(count: number) {
    this.selectionChangedEvent.emit(count);
  }


  getGridStatusBar(): any {
    return {
      statusPanels: [
        { statusPanel: "agTotalRowCountComponent", align: 'left' },
        { statusPanel: "agFilteredRowCountComponent", align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
      ],
    };
  }


  getGridDefaults(): any {
    return {
      resizable: true,
      sortable: true,
      filter: true,
      lockPinned: true,
      flex: 1
    };
  }

}
