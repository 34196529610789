import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { InputNumberInputEvent } from 'primeng/inputnumber';

@Component({
  selector: 'app-custom-cell-decimal-editor',
  templateUrl: './custom-cell-decimal-editor.component.html',
  styleUrl: './custom-cell-decimal-editor.component.scss'
})
export class CustomCellDecimalEditorComponent implements ICellEditorAngularComp {

  private params: any;
  public value: any;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
  }

  getValue(): any {
    return this.value;
  }
}
