"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Level = exports.LevelUnits = void 0;
const base_unit_1 = require("../base-unit");
/** LevelUnits enumeration */
var LevelUnits;
(function (LevelUnits) {
  /** */
  LevelUnits["Decibels"] = "Decibel";
  /** */
  LevelUnits["Nepers"] = "Neper";
})(LevelUnits = exports.LevelUnits || (exports.LevelUnits = {}));
/** Level is the logarithm of the ratio of a quantity Q to a reference value of that quantity, Q₀, expressed in dimensionless units. */
class Level extends base_unit_1.BaseUnit {
  /**
   * Create a new Level.
   * @param value The value.
   * @param fromUnit The ‘Level’ unit to create from.
   * The default unit is Decibels
   */
  constructor(value, fromUnit = LevelUnits.Decibels) {
    super();
    this.decibelsLazy = null;
    this.nepersLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Level is Decibels.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return LevelUnits.Decibels;
  }
  /** */
  get Decibels() {
    if (this.decibelsLazy !== null) {
      return this.decibelsLazy;
    }
    return this.decibelsLazy = this.convertFromBase(LevelUnits.Decibels);
  }
  /** */
  get Nepers() {
    if (this.nepersLazy !== null) {
      return this.nepersLazy;
    }
    return this.nepersLazy = this.convertFromBase(LevelUnits.Nepers);
  }
  /**
   * Create a new Level instance from a Decibels
   *
   * @param value The unit as Decibels to create a new Level from.
   * @returns The new Level instance.
   */
  static FromDecibels(value) {
    return new Level(value, LevelUnits.Decibels);
  }
  /**
   * Create a new Level instance from a Nepers
   *
   * @param value The unit as Nepers to create a new Level from.
   * @returns The new Level instance.
   */
  static FromNepers(value) {
    return new Level(value, LevelUnits.Nepers);
  }
  /**
   * Gets the base unit enumeration associated with Level
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return LevelUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return LevelUnits.Decibels;
  }
  /**
   * Create API DTO represent a Level unit.
   * @param holdInUnit The specific Level unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = LevelUnits.Decibels) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Level unit from an API DTO representation.
   * @param dtoLevel The Level API DTO representation
   */
  static FromDto(dtoLevel) {
    return new Level(dtoLevel.value, dtoLevel.unit);
  }
  /**
   * Convert Level to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case LevelUnits.Decibels:
        return this.Decibels;
      case LevelUnits.Nepers:
        return this.Nepers;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case LevelUnits.Decibels:
        return this.value;
      case LevelUnits.Nepers:
        return super.internalMultiply(0.115129254, this.value);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case LevelUnits.Decibels:
        return this.value;
      case LevelUnits.Nepers:
        return 0.115129254 * this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case LevelUnits.Decibels:
        return value;
      case LevelUnits.Nepers:
        {
          const v3 = super.internalDivide(1, 0.115129254);
          return super.internalMultiply(v3, value);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case LevelUnits.Decibels:
        return value;
      case LevelUnits.Nepers:
        return 1 / 0.115129254 * value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Level to string.
   * Note! the default format for Level is Decibels.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Level.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Level.
   */
  toString(unit = LevelUnits.Decibels, fractionalDigits) {
    switch (unit) {
      case LevelUnits.Decibels:
        return super.truncateFractionDigits(this.Decibels, fractionalDigits) + ` dB`;
      case LevelUnits.Nepers:
        return super.truncateFractionDigits(this.Nepers, fractionalDigits) + ` Np`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Level unit abbreviation.
   * Note! the default abbreviation for Level is Decibels.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Level.
   * @returns The abbreviation string of Level.
   */
  getUnitAbbreviation(unitAbbreviation = LevelUnits.Decibels) {
    switch (unitAbbreviation) {
      case LevelUnits.Decibels:
        return `dB`;
      case LevelUnits.Nepers:
        return `Np`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Level are equals to the current Level.
   * @param level The other Level.
   * @returns True if the given Level are equal to the current Level.
   */
  equals(level) {
    return super.internalEquals(this.value, level.BaseValue);
  }
  /**
   * Compare the given Level against the current Level.
   * @param level The other Level.
   * @returns 0 if they are equal, -1 if the current Level is less then other, 1 if the current Level is greater then other.
   */
  compareTo(level) {
    return super.internalCompareTo(this.value, level.BaseValue);
  }
  /**
   * Add the given Level with the current Level.
   * @param level The other Level.
   * @returns A new Level instance with the results.
   */
  add(level) {
    return new Level(super.internalAdd(this.value, level.BaseValue));
  }
  /**
   * Subtract the given Level with the current Level.
   * @param level The other Level.
   * @returns A new Level instance with the results.
   */
  subtract(level) {
    return new Level(super.internalSubtract(this.value, level.BaseValue));
  }
  /**
   * Multiply the given Level with the current Level.
   * @param level The other Level.
   * @returns A new Level instance with the results.
   */
  multiply(level) {
    return new Level(super.internalMultiply(this.value, level.BaseValue));
  }
  /**
   * Divide the given Level with the current Level.
   * @param level The other Level.
   * @returns A new Level instance with the results.
   */
  divide(level) {
    return new Level(super.internalDivide(this.value, level.BaseValue));
  }
  /**
   * Modulo the given Level with the current Level.
   * @param level The other Level.
   * @returns A new Level instance with the results.
   */
  modulo(level) {
    return new Level(super.internalModulo(this.value, level.BaseValue));
  }
  /**
   * Pow the given Level with the current Level.
   * @param level The other Level.
   * @returns A new Level instance with the results.
   */
  pow(level) {
    return new Level(super.internalPow(this.value, level.BaseValue));
  }
}
exports.Level = Level;