"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReactivePower = exports.ReactivePowerUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ReactivePowerUnits enumeration */
var ReactivePowerUnits;
(function (ReactivePowerUnits) {
  /** */
  ReactivePowerUnits["VoltamperesReactive"] = "VoltampereReactive";
  /** */
  ReactivePowerUnits["KilovoltamperesReactive"] = "KilovoltampereReactive";
  /** */
  ReactivePowerUnits["MegavoltamperesReactive"] = "MegavoltampereReactive";
  /** */
  ReactivePowerUnits["GigavoltamperesReactive"] = "GigavoltampereReactive";
})(ReactivePowerUnits = exports.ReactivePowerUnits || (exports.ReactivePowerUnits = {}));
/** Volt-ampere reactive (var) is a unit by which reactive power is expressed in an AC electric power system. Reactive power exists in an AC circuit when the current and voltage are not in phase. */
class ReactivePower extends base_unit_1.BaseUnit {
  /**
   * Create a new ReactivePower.
   * @param value The value.
   * @param fromUnit The ‘ReactivePower’ unit to create from.
   * The default unit is VoltamperesReactive
   */
  constructor(value, fromUnit = ReactivePowerUnits.VoltamperesReactive) {
    super();
    this.voltamperesreactiveLazy = null;
    this.kilovoltamperesreactiveLazy = null;
    this.megavoltamperesreactiveLazy = null;
    this.gigavoltamperesreactiveLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ReactivePower is VoltamperesReactive.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ReactivePowerUnits.VoltamperesReactive;
  }
  /** */
  get VoltamperesReactive() {
    if (this.voltamperesreactiveLazy !== null) {
      return this.voltamperesreactiveLazy;
    }
    return this.voltamperesreactiveLazy = this.convertFromBase(ReactivePowerUnits.VoltamperesReactive);
  }
  /** */
  get KilovoltamperesReactive() {
    if (this.kilovoltamperesreactiveLazy !== null) {
      return this.kilovoltamperesreactiveLazy;
    }
    return this.kilovoltamperesreactiveLazy = this.convertFromBase(ReactivePowerUnits.KilovoltamperesReactive);
  }
  /** */
  get MegavoltamperesReactive() {
    if (this.megavoltamperesreactiveLazy !== null) {
      return this.megavoltamperesreactiveLazy;
    }
    return this.megavoltamperesreactiveLazy = this.convertFromBase(ReactivePowerUnits.MegavoltamperesReactive);
  }
  /** */
  get GigavoltamperesReactive() {
    if (this.gigavoltamperesreactiveLazy !== null) {
      return this.gigavoltamperesreactiveLazy;
    }
    return this.gigavoltamperesreactiveLazy = this.convertFromBase(ReactivePowerUnits.GigavoltamperesReactive);
  }
  /**
   * Create a new ReactivePower instance from a VoltamperesReactive
   *
   * @param value The unit as VoltamperesReactive to create a new ReactivePower from.
   * @returns The new ReactivePower instance.
   */
  static FromVoltamperesReactive(value) {
    return new ReactivePower(value, ReactivePowerUnits.VoltamperesReactive);
  }
  /**
   * Create a new ReactivePower instance from a KilovoltamperesReactive
   *
   * @param value The unit as KilovoltamperesReactive to create a new ReactivePower from.
   * @returns The new ReactivePower instance.
   */
  static FromKilovoltamperesReactive(value) {
    return new ReactivePower(value, ReactivePowerUnits.KilovoltamperesReactive);
  }
  /**
   * Create a new ReactivePower instance from a MegavoltamperesReactive
   *
   * @param value The unit as MegavoltamperesReactive to create a new ReactivePower from.
   * @returns The new ReactivePower instance.
   */
  static FromMegavoltamperesReactive(value) {
    return new ReactivePower(value, ReactivePowerUnits.MegavoltamperesReactive);
  }
  /**
   * Create a new ReactivePower instance from a GigavoltamperesReactive
   *
   * @param value The unit as GigavoltamperesReactive to create a new ReactivePower from.
   * @returns The new ReactivePower instance.
   */
  static FromGigavoltamperesReactive(value) {
    return new ReactivePower(value, ReactivePowerUnits.GigavoltamperesReactive);
  }
  /**
   * Gets the base unit enumeration associated with ReactivePower
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ReactivePowerUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ReactivePowerUnits.VoltamperesReactive;
  }
  /**
   * Create API DTO represent a ReactivePower unit.
   * @param holdInUnit The specific ReactivePower unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ReactivePowerUnits.VoltamperesReactive) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ReactivePower unit from an API DTO representation.
   * @param dtoReactivePower The ReactivePower API DTO representation
   */
  static FromDto(dtoReactivePower) {
    return new ReactivePower(dtoReactivePower.value, dtoReactivePower.unit);
  }
  /**
   * Convert ReactivePower to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ReactivePowerUnits.VoltamperesReactive:
        return this.VoltamperesReactive;
      case ReactivePowerUnits.KilovoltamperesReactive:
        return this.KilovoltamperesReactive;
      case ReactivePowerUnits.MegavoltamperesReactive:
        return this.MegavoltamperesReactive;
      case ReactivePowerUnits.GigavoltamperesReactive:
        return this.GigavoltamperesReactive;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ReactivePowerUnits.VoltamperesReactive:
        return this.value;
      case ReactivePowerUnits.KilovoltamperesReactive:
        return super.internalDivide(this.value, 1000);
      case ReactivePowerUnits.MegavoltamperesReactive:
        return super.internalDivide(this.value, 1000000);
      case ReactivePowerUnits.GigavoltamperesReactive:
        return super.internalDivide(this.value, 1000000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ReactivePowerUnits.VoltamperesReactive:
        return this.value;
      case ReactivePowerUnits.KilovoltamperesReactive:
        return this.value / 1000;
      case ReactivePowerUnits.MegavoltamperesReactive:
        return this.value / 1000000;
      case ReactivePowerUnits.GigavoltamperesReactive:
        return this.value / 1000000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ReactivePowerUnits.VoltamperesReactive:
        return value;
      case ReactivePowerUnits.KilovoltamperesReactive:
        return super.internalMultiply(value, 1000);
      case ReactivePowerUnits.MegavoltamperesReactive:
        return super.internalMultiply(value, 1000000);
      case ReactivePowerUnits.GigavoltamperesReactive:
        return super.internalMultiply(value, 1000000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ReactivePowerUnits.VoltamperesReactive:
        return value;
      case ReactivePowerUnits.KilovoltamperesReactive:
        return value * 1000;
      case ReactivePowerUnits.MegavoltamperesReactive:
        return value * 1000000;
      case ReactivePowerUnits.GigavoltamperesReactive:
        return value * 1000000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ReactivePower to string.
   * Note! the default format for ReactivePower is VoltamperesReactive.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ReactivePower.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ReactivePower.
   */
  toString(unit = ReactivePowerUnits.VoltamperesReactive, fractionalDigits) {
    switch (unit) {
      case ReactivePowerUnits.VoltamperesReactive:
        return super.truncateFractionDigits(this.VoltamperesReactive, fractionalDigits) + ` var`;
      case ReactivePowerUnits.KilovoltamperesReactive:
        return super.truncateFractionDigits(this.KilovoltamperesReactive, fractionalDigits) + ` kvar`;
      case ReactivePowerUnits.MegavoltamperesReactive:
        return super.truncateFractionDigits(this.MegavoltamperesReactive, fractionalDigits) + ` Mvar`;
      case ReactivePowerUnits.GigavoltamperesReactive:
        return super.truncateFractionDigits(this.GigavoltamperesReactive, fractionalDigits) + ` Gvar`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ReactivePower unit abbreviation.
   * Note! the default abbreviation for ReactivePower is VoltamperesReactive.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ReactivePower.
   * @returns The abbreviation string of ReactivePower.
   */
  getUnitAbbreviation(unitAbbreviation = ReactivePowerUnits.VoltamperesReactive) {
    switch (unitAbbreviation) {
      case ReactivePowerUnits.VoltamperesReactive:
        return `var`;
      case ReactivePowerUnits.KilovoltamperesReactive:
        return `kvar`;
      case ReactivePowerUnits.MegavoltamperesReactive:
        return `Mvar`;
      case ReactivePowerUnits.GigavoltamperesReactive:
        return `Gvar`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ReactivePower are equals to the current ReactivePower.
   * @param reactivePower The other ReactivePower.
   * @returns True if the given ReactivePower are equal to the current ReactivePower.
   */
  equals(reactivePower) {
    return super.internalEquals(this.value, reactivePower.BaseValue);
  }
  /**
   * Compare the given ReactivePower against the current ReactivePower.
   * @param reactivePower The other ReactivePower.
   * @returns 0 if they are equal, -1 if the current ReactivePower is less then other, 1 if the current ReactivePower is greater then other.
   */
  compareTo(reactivePower) {
    return super.internalCompareTo(this.value, reactivePower.BaseValue);
  }
  /**
   * Add the given ReactivePower with the current ReactivePower.
   * @param reactivePower The other ReactivePower.
   * @returns A new ReactivePower instance with the results.
   */
  add(reactivePower) {
    return new ReactivePower(super.internalAdd(this.value, reactivePower.BaseValue));
  }
  /**
   * Subtract the given ReactivePower with the current ReactivePower.
   * @param reactivePower The other ReactivePower.
   * @returns A new ReactivePower instance with the results.
   */
  subtract(reactivePower) {
    return new ReactivePower(super.internalSubtract(this.value, reactivePower.BaseValue));
  }
  /**
   * Multiply the given ReactivePower with the current ReactivePower.
   * @param reactivePower The other ReactivePower.
   * @returns A new ReactivePower instance with the results.
   */
  multiply(reactivePower) {
    return new ReactivePower(super.internalMultiply(this.value, reactivePower.BaseValue));
  }
  /**
   * Divide the given ReactivePower with the current ReactivePower.
   * @param reactivePower The other ReactivePower.
   * @returns A new ReactivePower instance with the results.
   */
  divide(reactivePower) {
    return new ReactivePower(super.internalDivide(this.value, reactivePower.BaseValue));
  }
  /**
   * Modulo the given ReactivePower with the current ReactivePower.
   * @param reactivePower The other ReactivePower.
   * @returns A new ReactivePower instance with the results.
   */
  modulo(reactivePower) {
    return new ReactivePower(super.internalModulo(this.value, reactivePower.BaseValue));
  }
  /**
   * Pow the given ReactivePower with the current ReactivePower.
   * @param reactivePower The other ReactivePower.
   * @returns A new ReactivePower instance with the results.
   */
  pow(reactivePower) {
    return new ReactivePower(super.internalPow(this.value, reactivePower.BaseValue));
  }
}
exports.ReactivePower = ReactivePower;