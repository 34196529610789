"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricCurrentGradient = exports.ElectricCurrentGradientUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricCurrentGradientUnits enumeration */
var ElectricCurrentGradientUnits;
(function (ElectricCurrentGradientUnits) {
  /** */
  ElectricCurrentGradientUnits["AmperesPerSecond"] = "AmperePerSecond";
  /** */
  ElectricCurrentGradientUnits["AmperesPerMinute"] = "AmperePerMinute";
  /** */
  ElectricCurrentGradientUnits["AmperesPerMillisecond"] = "AmperePerMillisecond";
  /** */
  ElectricCurrentGradientUnits["AmperesPerMicrosecond"] = "AmperePerMicrosecond";
  /** */
  ElectricCurrentGradientUnits["AmperesPerNanosecond"] = "AmperePerNanosecond";
  /** */
  ElectricCurrentGradientUnits["MilliamperesPerSecond"] = "MilliamperePerSecond";
  /** */
  ElectricCurrentGradientUnits["MilliamperesPerMinute"] = "MilliamperePerMinute";
})(ElectricCurrentGradientUnits = exports.ElectricCurrentGradientUnits || (exports.ElectricCurrentGradientUnits = {}));
/** In electromagnetism, the current gradient describes how the current changes in time. */
class ElectricCurrentGradient extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricCurrentGradient.
   * @param value The value.
   * @param fromUnit The ‘ElectricCurrentGradient’ unit to create from.
   * The default unit is AmperesPerSecond
   */
  constructor(value, fromUnit = ElectricCurrentGradientUnits.AmperesPerSecond) {
    super();
    this.amperespersecondLazy = null;
    this.amperesperminuteLazy = null;
    this.amperespermillisecondLazy = null;
    this.amperespermicrosecondLazy = null;
    this.amperespernanosecondLazy = null;
    this.milliamperespersecondLazy = null;
    this.milliamperesperminuteLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricCurrentGradient is AmperesPerSecond.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricCurrentGradientUnits.AmperesPerSecond;
  }
  /** */
  get AmperesPerSecond() {
    if (this.amperespersecondLazy !== null) {
      return this.amperespersecondLazy;
    }
    return this.amperespersecondLazy = this.convertFromBase(ElectricCurrentGradientUnits.AmperesPerSecond);
  }
  /** */
  get AmperesPerMinute() {
    if (this.amperesperminuteLazy !== null) {
      return this.amperesperminuteLazy;
    }
    return this.amperesperminuteLazy = this.convertFromBase(ElectricCurrentGradientUnits.AmperesPerMinute);
  }
  /** */
  get AmperesPerMillisecond() {
    if (this.amperespermillisecondLazy !== null) {
      return this.amperespermillisecondLazy;
    }
    return this.amperespermillisecondLazy = this.convertFromBase(ElectricCurrentGradientUnits.AmperesPerMillisecond);
  }
  /** */
  get AmperesPerMicrosecond() {
    if (this.amperespermicrosecondLazy !== null) {
      return this.amperespermicrosecondLazy;
    }
    return this.amperespermicrosecondLazy = this.convertFromBase(ElectricCurrentGradientUnits.AmperesPerMicrosecond);
  }
  /** */
  get AmperesPerNanosecond() {
    if (this.amperespernanosecondLazy !== null) {
      return this.amperespernanosecondLazy;
    }
    return this.amperespernanosecondLazy = this.convertFromBase(ElectricCurrentGradientUnits.AmperesPerNanosecond);
  }
  /** */
  get MilliamperesPerSecond() {
    if (this.milliamperespersecondLazy !== null) {
      return this.milliamperespersecondLazy;
    }
    return this.milliamperespersecondLazy = this.convertFromBase(ElectricCurrentGradientUnits.MilliamperesPerSecond);
  }
  /** */
  get MilliamperesPerMinute() {
    if (this.milliamperesperminuteLazy !== null) {
      return this.milliamperesperminuteLazy;
    }
    return this.milliamperesperminuteLazy = this.convertFromBase(ElectricCurrentGradientUnits.MilliamperesPerMinute);
  }
  /**
   * Create a new ElectricCurrentGradient instance from a AmperesPerSecond
   *
   * @param value The unit as AmperesPerSecond to create a new ElectricCurrentGradient from.
   * @returns The new ElectricCurrentGradient instance.
   */
  static FromAmperesPerSecond(value) {
    return new ElectricCurrentGradient(value, ElectricCurrentGradientUnits.AmperesPerSecond);
  }
  /**
   * Create a new ElectricCurrentGradient instance from a AmperesPerMinute
   *
   * @param value The unit as AmperesPerMinute to create a new ElectricCurrentGradient from.
   * @returns The new ElectricCurrentGradient instance.
   */
  static FromAmperesPerMinute(value) {
    return new ElectricCurrentGradient(value, ElectricCurrentGradientUnits.AmperesPerMinute);
  }
  /**
   * Create a new ElectricCurrentGradient instance from a AmperesPerMillisecond
   *
   * @param value The unit as AmperesPerMillisecond to create a new ElectricCurrentGradient from.
   * @returns The new ElectricCurrentGradient instance.
   */
  static FromAmperesPerMillisecond(value) {
    return new ElectricCurrentGradient(value, ElectricCurrentGradientUnits.AmperesPerMillisecond);
  }
  /**
   * Create a new ElectricCurrentGradient instance from a AmperesPerMicrosecond
   *
   * @param value The unit as AmperesPerMicrosecond to create a new ElectricCurrentGradient from.
   * @returns The new ElectricCurrentGradient instance.
   */
  static FromAmperesPerMicrosecond(value) {
    return new ElectricCurrentGradient(value, ElectricCurrentGradientUnits.AmperesPerMicrosecond);
  }
  /**
   * Create a new ElectricCurrentGradient instance from a AmperesPerNanosecond
   *
   * @param value The unit as AmperesPerNanosecond to create a new ElectricCurrentGradient from.
   * @returns The new ElectricCurrentGradient instance.
   */
  static FromAmperesPerNanosecond(value) {
    return new ElectricCurrentGradient(value, ElectricCurrentGradientUnits.AmperesPerNanosecond);
  }
  /**
   * Create a new ElectricCurrentGradient instance from a MilliamperesPerSecond
   *
   * @param value The unit as MilliamperesPerSecond to create a new ElectricCurrentGradient from.
   * @returns The new ElectricCurrentGradient instance.
   */
  static FromMilliamperesPerSecond(value) {
    return new ElectricCurrentGradient(value, ElectricCurrentGradientUnits.MilliamperesPerSecond);
  }
  /**
   * Create a new ElectricCurrentGradient instance from a MilliamperesPerMinute
   *
   * @param value The unit as MilliamperesPerMinute to create a new ElectricCurrentGradient from.
   * @returns The new ElectricCurrentGradient instance.
   */
  static FromMilliamperesPerMinute(value) {
    return new ElectricCurrentGradient(value, ElectricCurrentGradientUnits.MilliamperesPerMinute);
  }
  /**
   * Gets the base unit enumeration associated with ElectricCurrentGradient
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricCurrentGradientUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricCurrentGradientUnits.AmperesPerSecond;
  }
  /**
   * Create API DTO represent a ElectricCurrentGradient unit.
   * @param holdInUnit The specific ElectricCurrentGradient unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricCurrentGradientUnits.AmperesPerSecond) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricCurrentGradient unit from an API DTO representation.
   * @param dtoElectricCurrentGradient The ElectricCurrentGradient API DTO representation
   */
  static FromDto(dtoElectricCurrentGradient) {
    return new ElectricCurrentGradient(dtoElectricCurrentGradient.value, dtoElectricCurrentGradient.unit);
  }
  /**
   * Convert ElectricCurrentGradient to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricCurrentGradientUnits.AmperesPerSecond:
        return this.AmperesPerSecond;
      case ElectricCurrentGradientUnits.AmperesPerMinute:
        return this.AmperesPerMinute;
      case ElectricCurrentGradientUnits.AmperesPerMillisecond:
        return this.AmperesPerMillisecond;
      case ElectricCurrentGradientUnits.AmperesPerMicrosecond:
        return this.AmperesPerMicrosecond;
      case ElectricCurrentGradientUnits.AmperesPerNanosecond:
        return this.AmperesPerNanosecond;
      case ElectricCurrentGradientUnits.MilliamperesPerSecond:
        return this.MilliamperesPerSecond;
      case ElectricCurrentGradientUnits.MilliamperesPerMinute:
        return this.MilliamperesPerMinute;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricCurrentGradientUnits.AmperesPerSecond:
        return this.value;
      case ElectricCurrentGradientUnits.AmperesPerMinute:
        return super.internalMultiply(this.value, 60);
      case ElectricCurrentGradientUnits.AmperesPerMillisecond:
        return super.internalDivide(this.value, 1E3);
      case ElectricCurrentGradientUnits.AmperesPerMicrosecond:
        return super.internalDivide(this.value, 1E6);
      case ElectricCurrentGradientUnits.AmperesPerNanosecond:
        return super.internalDivide(this.value, 1E9);
      case ElectricCurrentGradientUnits.MilliamperesPerSecond:
        return super.internalDivide(this.value, 0.001);
      case ElectricCurrentGradientUnits.MilliamperesPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60);
          return super.internalDivide(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricCurrentGradientUnits.AmperesPerSecond:
        return this.value;
      case ElectricCurrentGradientUnits.AmperesPerMinute:
        return this.value * 60;
      case ElectricCurrentGradientUnits.AmperesPerMillisecond:
        return this.value / 1E3;
      case ElectricCurrentGradientUnits.AmperesPerMicrosecond:
        return this.value / 1E6;
      case ElectricCurrentGradientUnits.AmperesPerNanosecond:
        return this.value / 1E9;
      case ElectricCurrentGradientUnits.MilliamperesPerSecond:
        return this.value / 0.001;
      case ElectricCurrentGradientUnits.MilliamperesPerMinute:
        return this.value * 60 / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricCurrentGradientUnits.AmperesPerSecond:
        return value;
      case ElectricCurrentGradientUnits.AmperesPerMinute:
        return super.internalDivide(value, 60);
      case ElectricCurrentGradientUnits.AmperesPerMillisecond:
        return super.internalMultiply(value, 1E3);
      case ElectricCurrentGradientUnits.AmperesPerMicrosecond:
        return super.internalMultiply(value, 1E6);
      case ElectricCurrentGradientUnits.AmperesPerNanosecond:
        return super.internalMultiply(value, 1E9);
      case ElectricCurrentGradientUnits.MilliamperesPerSecond:
        return super.internalMultiply(value, 0.001);
      case ElectricCurrentGradientUnits.MilliamperesPerMinute:
        {
          const v3 = super.internalDivide(value, 60);
          return super.internalMultiply(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricCurrentGradientUnits.AmperesPerSecond:
        return value;
      case ElectricCurrentGradientUnits.AmperesPerMinute:
        return value / 60;
      case ElectricCurrentGradientUnits.AmperesPerMillisecond:
        return value * 1E3;
      case ElectricCurrentGradientUnits.AmperesPerMicrosecond:
        return value * 1E6;
      case ElectricCurrentGradientUnits.AmperesPerNanosecond:
        return value * 1E9;
      case ElectricCurrentGradientUnits.MilliamperesPerSecond:
        return value * 0.001;
      case ElectricCurrentGradientUnits.MilliamperesPerMinute:
        return value / 60 * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricCurrentGradient to string.
   * Note! the default format for ElectricCurrentGradient is AmperesPerSecond.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricCurrentGradient.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricCurrentGradient.
   */
  toString(unit = ElectricCurrentGradientUnits.AmperesPerSecond, fractionalDigits) {
    switch (unit) {
      case ElectricCurrentGradientUnits.AmperesPerSecond:
        return super.truncateFractionDigits(this.AmperesPerSecond, fractionalDigits) + ` A/s`;
      case ElectricCurrentGradientUnits.AmperesPerMinute:
        return super.truncateFractionDigits(this.AmperesPerMinute, fractionalDigits) + ` A/min`;
      case ElectricCurrentGradientUnits.AmperesPerMillisecond:
        return super.truncateFractionDigits(this.AmperesPerMillisecond, fractionalDigits) + ` A/ms`;
      case ElectricCurrentGradientUnits.AmperesPerMicrosecond:
        return super.truncateFractionDigits(this.AmperesPerMicrosecond, fractionalDigits) + ` A/μs`;
      case ElectricCurrentGradientUnits.AmperesPerNanosecond:
        return super.truncateFractionDigits(this.AmperesPerNanosecond, fractionalDigits) + ` A/ns`;
      case ElectricCurrentGradientUnits.MilliamperesPerSecond:
        return super.truncateFractionDigits(this.MilliamperesPerSecond, fractionalDigits) + ` mA/s`;
      case ElectricCurrentGradientUnits.MilliamperesPerMinute:
        return super.truncateFractionDigits(this.MilliamperesPerMinute, fractionalDigits) + ` mA/min`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricCurrentGradient unit abbreviation.
   * Note! the default abbreviation for ElectricCurrentGradient is AmperesPerSecond.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricCurrentGradient.
   * @returns The abbreviation string of ElectricCurrentGradient.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricCurrentGradientUnits.AmperesPerSecond) {
    switch (unitAbbreviation) {
      case ElectricCurrentGradientUnits.AmperesPerSecond:
        return `A/s`;
      case ElectricCurrentGradientUnits.AmperesPerMinute:
        return `A/min`;
      case ElectricCurrentGradientUnits.AmperesPerMillisecond:
        return `A/ms`;
      case ElectricCurrentGradientUnits.AmperesPerMicrosecond:
        return `A/μs`;
      case ElectricCurrentGradientUnits.AmperesPerNanosecond:
        return `A/ns`;
      case ElectricCurrentGradientUnits.MilliamperesPerSecond:
        return `mA/s`;
      case ElectricCurrentGradientUnits.MilliamperesPerMinute:
        return `mA/min`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricCurrentGradient are equals to the current ElectricCurrentGradient.
   * @param electricCurrentGradient The other ElectricCurrentGradient.
   * @returns True if the given ElectricCurrentGradient are equal to the current ElectricCurrentGradient.
   */
  equals(electricCurrentGradient) {
    return super.internalEquals(this.value, electricCurrentGradient.BaseValue);
  }
  /**
   * Compare the given ElectricCurrentGradient against the current ElectricCurrentGradient.
   * @param electricCurrentGradient The other ElectricCurrentGradient.
   * @returns 0 if they are equal, -1 if the current ElectricCurrentGradient is less then other, 1 if the current ElectricCurrentGradient is greater then other.
   */
  compareTo(electricCurrentGradient) {
    return super.internalCompareTo(this.value, electricCurrentGradient.BaseValue);
  }
  /**
   * Add the given ElectricCurrentGradient with the current ElectricCurrentGradient.
   * @param electricCurrentGradient The other ElectricCurrentGradient.
   * @returns A new ElectricCurrentGradient instance with the results.
   */
  add(electricCurrentGradient) {
    return new ElectricCurrentGradient(super.internalAdd(this.value, electricCurrentGradient.BaseValue));
  }
  /**
   * Subtract the given ElectricCurrentGradient with the current ElectricCurrentGradient.
   * @param electricCurrentGradient The other ElectricCurrentGradient.
   * @returns A new ElectricCurrentGradient instance with the results.
   */
  subtract(electricCurrentGradient) {
    return new ElectricCurrentGradient(super.internalSubtract(this.value, electricCurrentGradient.BaseValue));
  }
  /**
   * Multiply the given ElectricCurrentGradient with the current ElectricCurrentGradient.
   * @param electricCurrentGradient The other ElectricCurrentGradient.
   * @returns A new ElectricCurrentGradient instance with the results.
   */
  multiply(electricCurrentGradient) {
    return new ElectricCurrentGradient(super.internalMultiply(this.value, electricCurrentGradient.BaseValue));
  }
  /**
   * Divide the given ElectricCurrentGradient with the current ElectricCurrentGradient.
   * @param electricCurrentGradient The other ElectricCurrentGradient.
   * @returns A new ElectricCurrentGradient instance with the results.
   */
  divide(electricCurrentGradient) {
    return new ElectricCurrentGradient(super.internalDivide(this.value, electricCurrentGradient.BaseValue));
  }
  /**
   * Modulo the given ElectricCurrentGradient with the current ElectricCurrentGradient.
   * @param electricCurrentGradient The other ElectricCurrentGradient.
   * @returns A new ElectricCurrentGradient instance with the results.
   */
  modulo(electricCurrentGradient) {
    return new ElectricCurrentGradient(super.internalModulo(this.value, electricCurrentGradient.BaseValue));
  }
  /**
   * Pow the given ElectricCurrentGradient with the current ElectricCurrentGradient.
   * @param electricCurrentGradient The other ElectricCurrentGradient.
   * @returns A new ElectricCurrentGradient instance with the results.
   */
  pow(electricCurrentGradient) {
    return new ElectricCurrentGradient(super.internalPow(this.value, electricCurrentGradient.BaseValue));
  }
}
exports.ElectricCurrentGradient = ElectricCurrentGradient;