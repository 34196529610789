"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AreaDensity = exports.AreaDensityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** AreaDensityUnits enumeration */
var AreaDensityUnits;
(function (AreaDensityUnits) {
  /** */
  AreaDensityUnits["KilogramsPerSquareMeter"] = "KilogramPerSquareMeter";
  /** Also known as grammage for paper industry. In fiber industry used with abbreviation 'gsm'. */
  AreaDensityUnits["GramsPerSquareMeter"] = "GramPerSquareMeter";
  /** */
  AreaDensityUnits["MilligramsPerSquareMeter"] = "MilligramPerSquareMeter";
})(AreaDensityUnits = exports.AreaDensityUnits || (exports.AreaDensityUnits = {}));
/** The area density of a two-dimensional object is calculated as the mass per unit area. For paper this is also called grammage. */
class AreaDensity extends base_unit_1.BaseUnit {
  /**
   * Create a new AreaDensity.
   * @param value The value.
   * @param fromUnit The ‘AreaDensity’ unit to create from.
   * The default unit is KilogramsPerSquareMeter
   */
  constructor(value, fromUnit = AreaDensityUnits.KilogramsPerSquareMeter) {
    super();
    this.kilogramspersquaremeterLazy = null;
    this.gramspersquaremeterLazy = null;
    this.milligramspersquaremeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of AreaDensity is KilogramsPerSquareMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return AreaDensityUnits.KilogramsPerSquareMeter;
  }
  /** */
  get KilogramsPerSquareMeter() {
    if (this.kilogramspersquaremeterLazy !== null) {
      return this.kilogramspersquaremeterLazy;
    }
    return this.kilogramspersquaremeterLazy = this.convertFromBase(AreaDensityUnits.KilogramsPerSquareMeter);
  }
  /** Also known as grammage for paper industry. In fiber industry used with abbreviation 'gsm'. */
  get GramsPerSquareMeter() {
    if (this.gramspersquaremeterLazy !== null) {
      return this.gramspersquaremeterLazy;
    }
    return this.gramspersquaremeterLazy = this.convertFromBase(AreaDensityUnits.GramsPerSquareMeter);
  }
  /** */
  get MilligramsPerSquareMeter() {
    if (this.milligramspersquaremeterLazy !== null) {
      return this.milligramspersquaremeterLazy;
    }
    return this.milligramspersquaremeterLazy = this.convertFromBase(AreaDensityUnits.MilligramsPerSquareMeter);
  }
  /**
   * Create a new AreaDensity instance from a KilogramsPerSquareMeter
   *
   * @param value The unit as KilogramsPerSquareMeter to create a new AreaDensity from.
   * @returns The new AreaDensity instance.
   */
  static FromKilogramsPerSquareMeter(value) {
    return new AreaDensity(value, AreaDensityUnits.KilogramsPerSquareMeter);
  }
  /**
   * Create a new AreaDensity instance from a GramsPerSquareMeter
   * Also known as grammage for paper industry. In fiber industry used with abbreviation 'gsm'.
   * @param value The unit as GramsPerSquareMeter to create a new AreaDensity from.
   * @returns The new AreaDensity instance.
   */
  static FromGramsPerSquareMeter(value) {
    return new AreaDensity(value, AreaDensityUnits.GramsPerSquareMeter);
  }
  /**
   * Create a new AreaDensity instance from a MilligramsPerSquareMeter
   *
   * @param value The unit as MilligramsPerSquareMeter to create a new AreaDensity from.
   * @returns The new AreaDensity instance.
   */
  static FromMilligramsPerSquareMeter(value) {
    return new AreaDensity(value, AreaDensityUnits.MilligramsPerSquareMeter);
  }
  /**
   * Gets the base unit enumeration associated with AreaDensity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return AreaDensityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return AreaDensityUnits.KilogramsPerSquareMeter;
  }
  /**
   * Create API DTO represent a AreaDensity unit.
   * @param holdInUnit The specific AreaDensity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = AreaDensityUnits.KilogramsPerSquareMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a AreaDensity unit from an API DTO representation.
   * @param dtoAreaDensity The AreaDensity API DTO representation
   */
  static FromDto(dtoAreaDensity) {
    return new AreaDensity(dtoAreaDensity.value, dtoAreaDensity.unit);
  }
  /**
   * Convert AreaDensity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case AreaDensityUnits.KilogramsPerSquareMeter:
        return this.KilogramsPerSquareMeter;
      case AreaDensityUnits.GramsPerSquareMeter:
        return this.GramsPerSquareMeter;
      case AreaDensityUnits.MilligramsPerSquareMeter:
        return this.MilligramsPerSquareMeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case AreaDensityUnits.KilogramsPerSquareMeter:
        return this.value;
      case AreaDensityUnits.GramsPerSquareMeter:
        return super.internalMultiply(this.value, 1000);
      case AreaDensityUnits.MilligramsPerSquareMeter:
        return super.internalMultiply(this.value, 1000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case AreaDensityUnits.KilogramsPerSquareMeter:
        return this.value;
      case AreaDensityUnits.GramsPerSquareMeter:
        return this.value * 1000;
      case AreaDensityUnits.MilligramsPerSquareMeter:
        return this.value * 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case AreaDensityUnits.KilogramsPerSquareMeter:
        return value;
      case AreaDensityUnits.GramsPerSquareMeter:
        return super.internalDivide(value, 1000);
      case AreaDensityUnits.MilligramsPerSquareMeter:
        return super.internalDivide(value, 1000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case AreaDensityUnits.KilogramsPerSquareMeter:
        return value;
      case AreaDensityUnits.GramsPerSquareMeter:
        return value / 1000;
      case AreaDensityUnits.MilligramsPerSquareMeter:
        return value / 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the AreaDensity to string.
   * Note! the default format for AreaDensity is KilogramsPerSquareMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the AreaDensity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the AreaDensity.
   */
  toString(unit = AreaDensityUnits.KilogramsPerSquareMeter, fractionalDigits) {
    switch (unit) {
      case AreaDensityUnits.KilogramsPerSquareMeter:
        return super.truncateFractionDigits(this.KilogramsPerSquareMeter, fractionalDigits) + ` kg/m²`;
      case AreaDensityUnits.GramsPerSquareMeter:
        return super.truncateFractionDigits(this.GramsPerSquareMeter, fractionalDigits) + ` g/m²`;
      case AreaDensityUnits.MilligramsPerSquareMeter:
        return super.truncateFractionDigits(this.MilligramsPerSquareMeter, fractionalDigits) + ` mg/m²`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get AreaDensity unit abbreviation.
   * Note! the default abbreviation for AreaDensity is KilogramsPerSquareMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the AreaDensity.
   * @returns The abbreviation string of AreaDensity.
   */
  getUnitAbbreviation(unitAbbreviation = AreaDensityUnits.KilogramsPerSquareMeter) {
    switch (unitAbbreviation) {
      case AreaDensityUnits.KilogramsPerSquareMeter:
        return `kg/m²`;
      case AreaDensityUnits.GramsPerSquareMeter:
        return `g/m²`;
      case AreaDensityUnits.MilligramsPerSquareMeter:
        return `mg/m²`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given AreaDensity are equals to the current AreaDensity.
   * @param areaDensity The other AreaDensity.
   * @returns True if the given AreaDensity are equal to the current AreaDensity.
   */
  equals(areaDensity) {
    return super.internalEquals(this.value, areaDensity.BaseValue);
  }
  /**
   * Compare the given AreaDensity against the current AreaDensity.
   * @param areaDensity The other AreaDensity.
   * @returns 0 if they are equal, -1 if the current AreaDensity is less then other, 1 if the current AreaDensity is greater then other.
   */
  compareTo(areaDensity) {
    return super.internalCompareTo(this.value, areaDensity.BaseValue);
  }
  /**
   * Add the given AreaDensity with the current AreaDensity.
   * @param areaDensity The other AreaDensity.
   * @returns A new AreaDensity instance with the results.
   */
  add(areaDensity) {
    return new AreaDensity(super.internalAdd(this.value, areaDensity.BaseValue));
  }
  /**
   * Subtract the given AreaDensity with the current AreaDensity.
   * @param areaDensity The other AreaDensity.
   * @returns A new AreaDensity instance with the results.
   */
  subtract(areaDensity) {
    return new AreaDensity(super.internalSubtract(this.value, areaDensity.BaseValue));
  }
  /**
   * Multiply the given AreaDensity with the current AreaDensity.
   * @param areaDensity The other AreaDensity.
   * @returns A new AreaDensity instance with the results.
   */
  multiply(areaDensity) {
    return new AreaDensity(super.internalMultiply(this.value, areaDensity.BaseValue));
  }
  /**
   * Divide the given AreaDensity with the current AreaDensity.
   * @param areaDensity The other AreaDensity.
   * @returns A new AreaDensity instance with the results.
   */
  divide(areaDensity) {
    return new AreaDensity(super.internalDivide(this.value, areaDensity.BaseValue));
  }
  /**
   * Modulo the given AreaDensity with the current AreaDensity.
   * @param areaDensity The other AreaDensity.
   * @returns A new AreaDensity instance with the results.
   */
  modulo(areaDensity) {
    return new AreaDensity(super.internalModulo(this.value, areaDensity.BaseValue));
  }
  /**
   * Pow the given AreaDensity with the current AreaDensity.
   * @param areaDensity The other AreaDensity.
   * @returns A new AreaDensity instance with the results.
   */
  pow(areaDensity) {
    return new AreaDensity(super.internalPow(this.value, areaDensity.BaseValue));
  }
}
exports.AreaDensity = AreaDensity;