"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Compressibility = exports.CompressibilityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** CompressibilityUnits enumeration */
var CompressibilityUnits;
(function (CompressibilityUnits) {
  /** */
  CompressibilityUnits["InversePascals"] = "InversePascal";
  /** */
  CompressibilityUnits["InverseKilopascals"] = "InverseKilopascal";
  /** */
  CompressibilityUnits["InverseMegapascals"] = "InverseMegapascal";
  /** */
  CompressibilityUnits["InverseAtmospheres"] = "InverseAtmosphere";
  /** */
  CompressibilityUnits["InverseMillibars"] = "InverseMillibar";
  /** */
  CompressibilityUnits["InverseBars"] = "InverseBar";
  /** */
  CompressibilityUnits["InversePoundsForcePerSquareInch"] = "InversePoundForcePerSquareInch";
})(CompressibilityUnits = exports.CompressibilityUnits || (exports.CompressibilityUnits = {}));
/** Compressibility is the measure of the relative volume change of a fluid or solid in response to pressure changes. */
class Compressibility extends base_unit_1.BaseUnit {
  /**
   * Create a new Compressibility.
   * @param value The value.
   * @param fromUnit The ‘Compressibility’ unit to create from.
   * The default unit is InversePascals
   */
  constructor(value, fromUnit = CompressibilityUnits.InversePascals) {
    super();
    this.inversepascalsLazy = null;
    this.inversekilopascalsLazy = null;
    this.inversemegapascalsLazy = null;
    this.inverseatmospheresLazy = null;
    this.inversemillibarsLazy = null;
    this.inversebarsLazy = null;
    this.inversepoundsforcepersquareinchLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Compressibility is InversePascals.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return CompressibilityUnits.InversePascals;
  }
  /** */
  get InversePascals() {
    if (this.inversepascalsLazy !== null) {
      return this.inversepascalsLazy;
    }
    return this.inversepascalsLazy = this.convertFromBase(CompressibilityUnits.InversePascals);
  }
  /** */
  get InverseKilopascals() {
    if (this.inversekilopascalsLazy !== null) {
      return this.inversekilopascalsLazy;
    }
    return this.inversekilopascalsLazy = this.convertFromBase(CompressibilityUnits.InverseKilopascals);
  }
  /** */
  get InverseMegapascals() {
    if (this.inversemegapascalsLazy !== null) {
      return this.inversemegapascalsLazy;
    }
    return this.inversemegapascalsLazy = this.convertFromBase(CompressibilityUnits.InverseMegapascals);
  }
  /** */
  get InverseAtmospheres() {
    if (this.inverseatmospheresLazy !== null) {
      return this.inverseatmospheresLazy;
    }
    return this.inverseatmospheresLazy = this.convertFromBase(CompressibilityUnits.InverseAtmospheres);
  }
  /** */
  get InverseMillibars() {
    if (this.inversemillibarsLazy !== null) {
      return this.inversemillibarsLazy;
    }
    return this.inversemillibarsLazy = this.convertFromBase(CompressibilityUnits.InverseMillibars);
  }
  /** */
  get InverseBars() {
    if (this.inversebarsLazy !== null) {
      return this.inversebarsLazy;
    }
    return this.inversebarsLazy = this.convertFromBase(CompressibilityUnits.InverseBars);
  }
  /** */
  get InversePoundsForcePerSquareInch() {
    if (this.inversepoundsforcepersquareinchLazy !== null) {
      return this.inversepoundsforcepersquareinchLazy;
    }
    return this.inversepoundsforcepersquareinchLazy = this.convertFromBase(CompressibilityUnits.InversePoundsForcePerSquareInch);
  }
  /**
   * Create a new Compressibility instance from a InversePascals
   *
   * @param value The unit as InversePascals to create a new Compressibility from.
   * @returns The new Compressibility instance.
   */
  static FromInversePascals(value) {
    return new Compressibility(value, CompressibilityUnits.InversePascals);
  }
  /**
   * Create a new Compressibility instance from a InverseKilopascals
   *
   * @param value The unit as InverseKilopascals to create a new Compressibility from.
   * @returns The new Compressibility instance.
   */
  static FromInverseKilopascals(value) {
    return new Compressibility(value, CompressibilityUnits.InverseKilopascals);
  }
  /**
   * Create a new Compressibility instance from a InverseMegapascals
   *
   * @param value The unit as InverseMegapascals to create a new Compressibility from.
   * @returns The new Compressibility instance.
   */
  static FromInverseMegapascals(value) {
    return new Compressibility(value, CompressibilityUnits.InverseMegapascals);
  }
  /**
   * Create a new Compressibility instance from a InverseAtmospheres
   *
   * @param value The unit as InverseAtmospheres to create a new Compressibility from.
   * @returns The new Compressibility instance.
   */
  static FromInverseAtmospheres(value) {
    return new Compressibility(value, CompressibilityUnits.InverseAtmospheres);
  }
  /**
   * Create a new Compressibility instance from a InverseMillibars
   *
   * @param value The unit as InverseMillibars to create a new Compressibility from.
   * @returns The new Compressibility instance.
   */
  static FromInverseMillibars(value) {
    return new Compressibility(value, CompressibilityUnits.InverseMillibars);
  }
  /**
   * Create a new Compressibility instance from a InverseBars
   *
   * @param value The unit as InverseBars to create a new Compressibility from.
   * @returns The new Compressibility instance.
   */
  static FromInverseBars(value) {
    return new Compressibility(value, CompressibilityUnits.InverseBars);
  }
  /**
   * Create a new Compressibility instance from a InversePoundsForcePerSquareInch
   *
   * @param value The unit as InversePoundsForcePerSquareInch to create a new Compressibility from.
   * @returns The new Compressibility instance.
   */
  static FromInversePoundsForcePerSquareInch(value) {
    return new Compressibility(value, CompressibilityUnits.InversePoundsForcePerSquareInch);
  }
  /**
   * Gets the base unit enumeration associated with Compressibility
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return CompressibilityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return CompressibilityUnits.InversePascals;
  }
  /**
   * Create API DTO represent a Compressibility unit.
   * @param holdInUnit The specific Compressibility unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = CompressibilityUnits.InversePascals) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Compressibility unit from an API DTO representation.
   * @param dtoCompressibility The Compressibility API DTO representation
   */
  static FromDto(dtoCompressibility) {
    return new Compressibility(dtoCompressibility.value, dtoCompressibility.unit);
  }
  /**
   * Convert Compressibility to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case CompressibilityUnits.InversePascals:
        return this.InversePascals;
      case CompressibilityUnits.InverseKilopascals:
        return this.InverseKilopascals;
      case CompressibilityUnits.InverseMegapascals:
        return this.InverseMegapascals;
      case CompressibilityUnits.InverseAtmospheres:
        return this.InverseAtmospheres;
      case CompressibilityUnits.InverseMillibars:
        return this.InverseMillibars;
      case CompressibilityUnits.InverseBars:
        return this.InverseBars;
      case CompressibilityUnits.InversePoundsForcePerSquareInch:
        return this.InversePoundsForcePerSquareInch;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case CompressibilityUnits.InversePascals:
        return this.value;
      case CompressibilityUnits.InverseKilopascals:
        return super.internalDivide(this.value, 1e3);
      case CompressibilityUnits.InverseMegapascals:
        return super.internalDivide(this.value, 1e6);
      case CompressibilityUnits.InverseAtmospheres:
        return super.internalDivide(this.value, 101325);
      case CompressibilityUnits.InverseMillibars:
        return super.internalDivide(this.value, 100);
      case CompressibilityUnits.InverseBars:
        return super.internalDivide(this.value, 1e5);
      case CompressibilityUnits.InversePoundsForcePerSquareInch:
        return super.internalDivide(this.value, 6.894757293168361e3);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case CompressibilityUnits.InversePascals:
        return this.value;
      case CompressibilityUnits.InverseKilopascals:
        return this.value / 1e3;
      case CompressibilityUnits.InverseMegapascals:
        return this.value / 1e6;
      case CompressibilityUnits.InverseAtmospheres:
        return this.value / 101325;
      case CompressibilityUnits.InverseMillibars:
        return this.value / 100;
      case CompressibilityUnits.InverseBars:
        return this.value / 1e5;
      case CompressibilityUnits.InversePoundsForcePerSquareInch:
        return this.value / 6.894757293168361e3;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case CompressibilityUnits.InversePascals:
        return value;
      case CompressibilityUnits.InverseKilopascals:
        return super.internalMultiply(value, 1e3);
      case CompressibilityUnits.InverseMegapascals:
        return super.internalMultiply(value, 1e6);
      case CompressibilityUnits.InverseAtmospheres:
        return super.internalMultiply(value, 101325);
      case CompressibilityUnits.InverseMillibars:
        return super.internalMultiply(value, 100);
      case CompressibilityUnits.InverseBars:
        return super.internalMultiply(value, 1e5);
      case CompressibilityUnits.InversePoundsForcePerSquareInch:
        return super.internalMultiply(value, 6.894757293168361e3);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case CompressibilityUnits.InversePascals:
        return value;
      case CompressibilityUnits.InverseKilopascals:
        return value * 1e3;
      case CompressibilityUnits.InverseMegapascals:
        return value * 1e6;
      case CompressibilityUnits.InverseAtmospheres:
        return value * 101325;
      case CompressibilityUnits.InverseMillibars:
        return value * 100;
      case CompressibilityUnits.InverseBars:
        return value * 1e5;
      case CompressibilityUnits.InversePoundsForcePerSquareInch:
        return value * 6.894757293168361e3;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Compressibility to string.
   * Note! the default format for Compressibility is InversePascals.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Compressibility.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Compressibility.
   */
  toString(unit = CompressibilityUnits.InversePascals, fractionalDigits) {
    switch (unit) {
      case CompressibilityUnits.InversePascals:
        return super.truncateFractionDigits(this.InversePascals, fractionalDigits) + ` Pa⁻¹`;
      case CompressibilityUnits.InverseKilopascals:
        return super.truncateFractionDigits(this.InverseKilopascals, fractionalDigits) + ` kPa⁻¹`;
      case CompressibilityUnits.InverseMegapascals:
        return super.truncateFractionDigits(this.InverseMegapascals, fractionalDigits) + ` MPa⁻¹`;
      case CompressibilityUnits.InverseAtmospheres:
        return super.truncateFractionDigits(this.InverseAtmospheres, fractionalDigits) + ` atm⁻¹`;
      case CompressibilityUnits.InverseMillibars:
        return super.truncateFractionDigits(this.InverseMillibars, fractionalDigits) + ` mbar⁻¹`;
      case CompressibilityUnits.InverseBars:
        return super.truncateFractionDigits(this.InverseBars, fractionalDigits) + ` bar⁻¹`;
      case CompressibilityUnits.InversePoundsForcePerSquareInch:
        return super.truncateFractionDigits(this.InversePoundsForcePerSquareInch, fractionalDigits) + ` psi⁻¹`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Compressibility unit abbreviation.
   * Note! the default abbreviation for Compressibility is InversePascals.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Compressibility.
   * @returns The abbreviation string of Compressibility.
   */
  getUnitAbbreviation(unitAbbreviation = CompressibilityUnits.InversePascals) {
    switch (unitAbbreviation) {
      case CompressibilityUnits.InversePascals:
        return `Pa⁻¹`;
      case CompressibilityUnits.InverseKilopascals:
        return `kPa⁻¹`;
      case CompressibilityUnits.InverseMegapascals:
        return `MPa⁻¹`;
      case CompressibilityUnits.InverseAtmospheres:
        return `atm⁻¹`;
      case CompressibilityUnits.InverseMillibars:
        return `mbar⁻¹`;
      case CompressibilityUnits.InverseBars:
        return `bar⁻¹`;
      case CompressibilityUnits.InversePoundsForcePerSquareInch:
        return `psi⁻¹`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Compressibility are equals to the current Compressibility.
   * @param compressibility The other Compressibility.
   * @returns True if the given Compressibility are equal to the current Compressibility.
   */
  equals(compressibility) {
    return super.internalEquals(this.value, compressibility.BaseValue);
  }
  /**
   * Compare the given Compressibility against the current Compressibility.
   * @param compressibility The other Compressibility.
   * @returns 0 if they are equal, -1 if the current Compressibility is less then other, 1 if the current Compressibility is greater then other.
   */
  compareTo(compressibility) {
    return super.internalCompareTo(this.value, compressibility.BaseValue);
  }
  /**
   * Add the given Compressibility with the current Compressibility.
   * @param compressibility The other Compressibility.
   * @returns A new Compressibility instance with the results.
   */
  add(compressibility) {
    return new Compressibility(super.internalAdd(this.value, compressibility.BaseValue));
  }
  /**
   * Subtract the given Compressibility with the current Compressibility.
   * @param compressibility The other Compressibility.
   * @returns A new Compressibility instance with the results.
   */
  subtract(compressibility) {
    return new Compressibility(super.internalSubtract(this.value, compressibility.BaseValue));
  }
  /**
   * Multiply the given Compressibility with the current Compressibility.
   * @param compressibility The other Compressibility.
   * @returns A new Compressibility instance with the results.
   */
  multiply(compressibility) {
    return new Compressibility(super.internalMultiply(this.value, compressibility.BaseValue));
  }
  /**
   * Divide the given Compressibility with the current Compressibility.
   * @param compressibility The other Compressibility.
   * @returns A new Compressibility instance with the results.
   */
  divide(compressibility) {
    return new Compressibility(super.internalDivide(this.value, compressibility.BaseValue));
  }
  /**
   * Modulo the given Compressibility with the current Compressibility.
   * @param compressibility The other Compressibility.
   * @returns A new Compressibility instance with the results.
   */
  modulo(compressibility) {
    return new Compressibility(super.internalModulo(this.value, compressibility.BaseValue));
  }
  /**
   * Pow the given Compressibility with the current Compressibility.
   * @param compressibility The other Compressibility.
   * @returns A new Compressibility instance with the results.
   */
  pow(compressibility) {
    return new Compressibility(super.internalPow(this.value, compressibility.BaseValue));
  }
}
exports.Compressibility = Compressibility;