"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Length = exports.LengthUnits = void 0;
const base_unit_1 = require("../base-unit");
/** LengthUnits enumeration */
var LengthUnits;
(function (LengthUnits) {
  /** */
  LengthUnits["Meters"] = "Meter";
  /** The statute mile was standardised between the British Commonwealth and the United States by an international agreement in 1959, when it was formally redefined with respect to SI units as exactly 1,609.344 metres. */
  LengthUnits["Miles"] = "Mile";
  /** The yard (symbol: yd) is an English unit of length in both the British imperial and US customary systems of measurement equalling 3 feet (or 36 inches). Since 1959 the yard has been by international agreement standardized as exactly 0.9144 meter. A distance of 1,760 yards is equal to 1 mile. */
  LengthUnits["Yards"] = "Yard";
  /** */
  LengthUnits["Feet"] = "Foot";
  /** In the United States, the foot was defined as 12 inches, with the inch being defined by the Mendenhall Order of 1893 as 39.37 inches = 1 m. This makes a U.S. survey foot exactly 1200/3937 meters. */
  LengthUnits["UsSurveyFeet"] = "UsSurveyFoot";
  /** */
  LengthUnits["Inches"] = "Inch";
  /** */
  LengthUnits["Mils"] = "Mil";
  /** */
  LengthUnits["NauticalMiles"] = "NauticalMile";
  /** */
  LengthUnits["Fathoms"] = "Fathom";
  /** */
  LengthUnits["Shackles"] = "Shackle";
  /** */
  LengthUnits["Microinches"] = "Microinch";
  /** */
  LengthUnits["PrinterPoints"] = "PrinterPoint";
  /** */
  LengthUnits["DtpPoints"] = "DtpPoint";
  /** */
  LengthUnits["PrinterPicas"] = "PrinterPica";
  /** */
  LengthUnits["DtpPicas"] = "DtpPica";
  /** */
  LengthUnits["Twips"] = "Twip";
  /** */
  LengthUnits["Hands"] = "Hand";
  /** One Astronomical Unit is the distance from the solar system Star, the sun, to planet Earth. */
  LengthUnits["AstronomicalUnits"] = "AstronomicalUnit";
  /** A parsec is defined as the distance at which one astronomical unit (AU) subtends an angle of one arcsecond. */
  LengthUnits["Parsecs"] = "Parsec";
  /** A Light Year (ly) is the distance that light travel during an Earth year, ie 365 days. */
  LengthUnits["LightYears"] = "LightYear";
  /** Solar radius is a ratio unit to the radius of the solar system star, the sun. */
  LengthUnits["SolarRadiuses"] = "SolarRadius";
  /** */
  LengthUnits["Chains"] = "Chain";
  /** Angstrom is a metric unit of length equal to 1e-10 meter */
  LengthUnits["Angstroms"] = "Angstrom";
  /** In radar-related subjects and in JTIDS, a data mile is a unit of distance equal to 6000 feet (1.8288 kilometres or 0.987 nautical miles). */
  LengthUnits["DataMiles"] = "DataMile";
  /** */
  LengthUnits["Femtometers"] = "Femtometer";
  /** */
  LengthUnits["Picometers"] = "Picometer";
  /** */
  LengthUnits["Nanometers"] = "Nanometer";
  /** */
  LengthUnits["Micrometers"] = "Micrometer";
  /** */
  LengthUnits["Millimeters"] = "Millimeter";
  /** */
  LengthUnits["Centimeters"] = "Centimeter";
  /** */
  LengthUnits["Decimeters"] = "Decimeter";
  /** */
  LengthUnits["Decameters"] = "Decameter";
  /** */
  LengthUnits["Hectometers"] = "Hectometer";
  /** */
  LengthUnits["Kilometers"] = "Kilometer";
  /** */
  LengthUnits["Megameters"] = "Megameter";
  /** */
  LengthUnits["Gigameters"] = "Gigameter";
  /** */
  LengthUnits["Kiloyards"] = "Kiloyard";
  /** */
  LengthUnits["Kilofeet"] = "Kilofoot";
  /** */
  LengthUnits["Kiloparsecs"] = "Kiloparsec";
  /** */
  LengthUnits["Megaparsecs"] = "Megaparsec";
  /** */
  LengthUnits["KilolightYears"] = "KilolightYear";
  /** */
  LengthUnits["MegalightYears"] = "MegalightYear";
})(LengthUnits = exports.LengthUnits || (exports.LengthUnits = {}));
/** Many different units of length have been used around the world. The main units in modern use are U.S. customary units in the United States and the Metric system elsewhere. British Imperial units are still used for some purposes in the United Kingdom and some other countries. The metric system is sub-divided into SI and non-SI units. */
class Length extends base_unit_1.BaseUnit {
  /**
   * Create a new Length.
   * @param value The value.
   * @param fromUnit The ‘Length’ unit to create from.
   * The default unit is Meters
   */
  constructor(value, fromUnit = LengthUnits.Meters) {
    super();
    this.metersLazy = null;
    this.milesLazy = null;
    this.yardsLazy = null;
    this.feetLazy = null;
    this.ussurveyfeetLazy = null;
    this.inchesLazy = null;
    this.milsLazy = null;
    this.nauticalmilesLazy = null;
    this.fathomsLazy = null;
    this.shacklesLazy = null;
    this.microinchesLazy = null;
    this.printerpointsLazy = null;
    this.dtppointsLazy = null;
    this.printerpicasLazy = null;
    this.dtppicasLazy = null;
    this.twipsLazy = null;
    this.handsLazy = null;
    this.astronomicalunitsLazy = null;
    this.parsecsLazy = null;
    this.lightyearsLazy = null;
    this.solarradiusesLazy = null;
    this.chainsLazy = null;
    this.angstromsLazy = null;
    this.datamilesLazy = null;
    this.femtometersLazy = null;
    this.picometersLazy = null;
    this.nanometersLazy = null;
    this.micrometersLazy = null;
    this.millimetersLazy = null;
    this.centimetersLazy = null;
    this.decimetersLazy = null;
    this.decametersLazy = null;
    this.hectometersLazy = null;
    this.kilometersLazy = null;
    this.megametersLazy = null;
    this.gigametersLazy = null;
    this.kiloyardsLazy = null;
    this.kilofeetLazy = null;
    this.kiloparsecsLazy = null;
    this.megaparsecsLazy = null;
    this.kilolightyearsLazy = null;
    this.megalightyearsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Length is Meters.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return LengthUnits.Meters;
  }
  /** */
  get Meters() {
    if (this.metersLazy !== null) {
      return this.metersLazy;
    }
    return this.metersLazy = this.convertFromBase(LengthUnits.Meters);
  }
  /** The statute mile was standardised between the British Commonwealth and the United States by an international agreement in 1959, when it was formally redefined with respect to SI units as exactly 1,609.344 metres. */
  get Miles() {
    if (this.milesLazy !== null) {
      return this.milesLazy;
    }
    return this.milesLazy = this.convertFromBase(LengthUnits.Miles);
  }
  /** The yard (symbol: yd) is an English unit of length in both the British imperial and US customary systems of measurement equalling 3 feet (or 36 inches). Since 1959 the yard has been by international agreement standardized as exactly 0.9144 meter. A distance of 1,760 yards is equal to 1 mile. */
  get Yards() {
    if (this.yardsLazy !== null) {
      return this.yardsLazy;
    }
    return this.yardsLazy = this.convertFromBase(LengthUnits.Yards);
  }
  /** */
  get Feet() {
    if (this.feetLazy !== null) {
      return this.feetLazy;
    }
    return this.feetLazy = this.convertFromBase(LengthUnits.Feet);
  }
  /** In the United States, the foot was defined as 12 inches, with the inch being defined by the Mendenhall Order of 1893 as 39.37 inches = 1 m. This makes a U.S. survey foot exactly 1200/3937 meters. */
  get UsSurveyFeet() {
    if (this.ussurveyfeetLazy !== null) {
      return this.ussurveyfeetLazy;
    }
    return this.ussurveyfeetLazy = this.convertFromBase(LengthUnits.UsSurveyFeet);
  }
  /** */
  get Inches() {
    if (this.inchesLazy !== null) {
      return this.inchesLazy;
    }
    return this.inchesLazy = this.convertFromBase(LengthUnits.Inches);
  }
  /** */
  get Mils() {
    if (this.milsLazy !== null) {
      return this.milsLazy;
    }
    return this.milsLazy = this.convertFromBase(LengthUnits.Mils);
  }
  /** */
  get NauticalMiles() {
    if (this.nauticalmilesLazy !== null) {
      return this.nauticalmilesLazy;
    }
    return this.nauticalmilesLazy = this.convertFromBase(LengthUnits.NauticalMiles);
  }
  /** */
  get Fathoms() {
    if (this.fathomsLazy !== null) {
      return this.fathomsLazy;
    }
    return this.fathomsLazy = this.convertFromBase(LengthUnits.Fathoms);
  }
  /** */
  get Shackles() {
    if (this.shacklesLazy !== null) {
      return this.shacklesLazy;
    }
    return this.shacklesLazy = this.convertFromBase(LengthUnits.Shackles);
  }
  /** */
  get Microinches() {
    if (this.microinchesLazy !== null) {
      return this.microinchesLazy;
    }
    return this.microinchesLazy = this.convertFromBase(LengthUnits.Microinches);
  }
  /** */
  get PrinterPoints() {
    if (this.printerpointsLazy !== null) {
      return this.printerpointsLazy;
    }
    return this.printerpointsLazy = this.convertFromBase(LengthUnits.PrinterPoints);
  }
  /** */
  get DtpPoints() {
    if (this.dtppointsLazy !== null) {
      return this.dtppointsLazy;
    }
    return this.dtppointsLazy = this.convertFromBase(LengthUnits.DtpPoints);
  }
  /** */
  get PrinterPicas() {
    if (this.printerpicasLazy !== null) {
      return this.printerpicasLazy;
    }
    return this.printerpicasLazy = this.convertFromBase(LengthUnits.PrinterPicas);
  }
  /** */
  get DtpPicas() {
    if (this.dtppicasLazy !== null) {
      return this.dtppicasLazy;
    }
    return this.dtppicasLazy = this.convertFromBase(LengthUnits.DtpPicas);
  }
  /** */
  get Twips() {
    if (this.twipsLazy !== null) {
      return this.twipsLazy;
    }
    return this.twipsLazy = this.convertFromBase(LengthUnits.Twips);
  }
  /** */
  get Hands() {
    if (this.handsLazy !== null) {
      return this.handsLazy;
    }
    return this.handsLazy = this.convertFromBase(LengthUnits.Hands);
  }
  /** One Astronomical Unit is the distance from the solar system Star, the sun, to planet Earth. */
  get AstronomicalUnits() {
    if (this.astronomicalunitsLazy !== null) {
      return this.astronomicalunitsLazy;
    }
    return this.astronomicalunitsLazy = this.convertFromBase(LengthUnits.AstronomicalUnits);
  }
  /** A parsec is defined as the distance at which one astronomical unit (AU) subtends an angle of one arcsecond. */
  get Parsecs() {
    if (this.parsecsLazy !== null) {
      return this.parsecsLazy;
    }
    return this.parsecsLazy = this.convertFromBase(LengthUnits.Parsecs);
  }
  /** A Light Year (ly) is the distance that light travel during an Earth year, ie 365 days. */
  get LightYears() {
    if (this.lightyearsLazy !== null) {
      return this.lightyearsLazy;
    }
    return this.lightyearsLazy = this.convertFromBase(LengthUnits.LightYears);
  }
  /** Solar radius is a ratio unit to the radius of the solar system star, the sun. */
  get SolarRadiuses() {
    if (this.solarradiusesLazy !== null) {
      return this.solarradiusesLazy;
    }
    return this.solarradiusesLazy = this.convertFromBase(LengthUnits.SolarRadiuses);
  }
  /** */
  get Chains() {
    if (this.chainsLazy !== null) {
      return this.chainsLazy;
    }
    return this.chainsLazy = this.convertFromBase(LengthUnits.Chains);
  }
  /** Angstrom is a metric unit of length equal to 1e-10 meter */
  get Angstroms() {
    if (this.angstromsLazy !== null) {
      return this.angstromsLazy;
    }
    return this.angstromsLazy = this.convertFromBase(LengthUnits.Angstroms);
  }
  /** In radar-related subjects and in JTIDS, a data mile is a unit of distance equal to 6000 feet (1.8288 kilometres or 0.987 nautical miles). */
  get DataMiles() {
    if (this.datamilesLazy !== null) {
      return this.datamilesLazy;
    }
    return this.datamilesLazy = this.convertFromBase(LengthUnits.DataMiles);
  }
  /** */
  get Femtometers() {
    if (this.femtometersLazy !== null) {
      return this.femtometersLazy;
    }
    return this.femtometersLazy = this.convertFromBase(LengthUnits.Femtometers);
  }
  /** */
  get Picometers() {
    if (this.picometersLazy !== null) {
      return this.picometersLazy;
    }
    return this.picometersLazy = this.convertFromBase(LengthUnits.Picometers);
  }
  /** */
  get Nanometers() {
    if (this.nanometersLazy !== null) {
      return this.nanometersLazy;
    }
    return this.nanometersLazy = this.convertFromBase(LengthUnits.Nanometers);
  }
  /** */
  get Micrometers() {
    if (this.micrometersLazy !== null) {
      return this.micrometersLazy;
    }
    return this.micrometersLazy = this.convertFromBase(LengthUnits.Micrometers);
  }
  /** */
  get Millimeters() {
    if (this.millimetersLazy !== null) {
      return this.millimetersLazy;
    }
    return this.millimetersLazy = this.convertFromBase(LengthUnits.Millimeters);
  }
  /** */
  get Centimeters() {
    if (this.centimetersLazy !== null) {
      return this.centimetersLazy;
    }
    return this.centimetersLazy = this.convertFromBase(LengthUnits.Centimeters);
  }
  /** */
  get Decimeters() {
    if (this.decimetersLazy !== null) {
      return this.decimetersLazy;
    }
    return this.decimetersLazy = this.convertFromBase(LengthUnits.Decimeters);
  }
  /** */
  get Decameters() {
    if (this.decametersLazy !== null) {
      return this.decametersLazy;
    }
    return this.decametersLazy = this.convertFromBase(LengthUnits.Decameters);
  }
  /** */
  get Hectometers() {
    if (this.hectometersLazy !== null) {
      return this.hectometersLazy;
    }
    return this.hectometersLazy = this.convertFromBase(LengthUnits.Hectometers);
  }
  /** */
  get Kilometers() {
    if (this.kilometersLazy !== null) {
      return this.kilometersLazy;
    }
    return this.kilometersLazy = this.convertFromBase(LengthUnits.Kilometers);
  }
  /** */
  get Megameters() {
    if (this.megametersLazy !== null) {
      return this.megametersLazy;
    }
    return this.megametersLazy = this.convertFromBase(LengthUnits.Megameters);
  }
  /** */
  get Gigameters() {
    if (this.gigametersLazy !== null) {
      return this.gigametersLazy;
    }
    return this.gigametersLazy = this.convertFromBase(LengthUnits.Gigameters);
  }
  /** */
  get Kiloyards() {
    if (this.kiloyardsLazy !== null) {
      return this.kiloyardsLazy;
    }
    return this.kiloyardsLazy = this.convertFromBase(LengthUnits.Kiloyards);
  }
  /** */
  get Kilofeet() {
    if (this.kilofeetLazy !== null) {
      return this.kilofeetLazy;
    }
    return this.kilofeetLazy = this.convertFromBase(LengthUnits.Kilofeet);
  }
  /** */
  get Kiloparsecs() {
    if (this.kiloparsecsLazy !== null) {
      return this.kiloparsecsLazy;
    }
    return this.kiloparsecsLazy = this.convertFromBase(LengthUnits.Kiloparsecs);
  }
  /** */
  get Megaparsecs() {
    if (this.megaparsecsLazy !== null) {
      return this.megaparsecsLazy;
    }
    return this.megaparsecsLazy = this.convertFromBase(LengthUnits.Megaparsecs);
  }
  /** */
  get KilolightYears() {
    if (this.kilolightyearsLazy !== null) {
      return this.kilolightyearsLazy;
    }
    return this.kilolightyearsLazy = this.convertFromBase(LengthUnits.KilolightYears);
  }
  /** */
  get MegalightYears() {
    if (this.megalightyearsLazy !== null) {
      return this.megalightyearsLazy;
    }
    return this.megalightyearsLazy = this.convertFromBase(LengthUnits.MegalightYears);
  }
  /**
   * Create a new Length instance from a Meters
   *
   * @param value The unit as Meters to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMeters(value) {
    return new Length(value, LengthUnits.Meters);
  }
  /**
   * Create a new Length instance from a Miles
   * The statute mile was standardised between the British Commonwealth and the United States by an international agreement in 1959, when it was formally redefined with respect to SI units as exactly 1,609.344 metres.
   * @param value The unit as Miles to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMiles(value) {
    return new Length(value, LengthUnits.Miles);
  }
  /**
   * Create a new Length instance from a Yards
   * The yard (symbol: yd) is an English unit of length in both the British imperial and US customary systems of measurement equalling 3 feet (or 36 inches). Since 1959 the yard has been by international agreement standardized as exactly 0.9144 meter. A distance of 1,760 yards is equal to 1 mile.
   * @param value The unit as Yards to create a new Length from.
   * @returns The new Length instance.
   */
  static FromYards(value) {
    return new Length(value, LengthUnits.Yards);
  }
  /**
   * Create a new Length instance from a Feet
   *
   * @param value The unit as Feet to create a new Length from.
   * @returns The new Length instance.
   */
  static FromFeet(value) {
    return new Length(value, LengthUnits.Feet);
  }
  /**
   * Create a new Length instance from a UsSurveyFeet
   * In the United States, the foot was defined as 12 inches, with the inch being defined by the Mendenhall Order of 1893 as 39.37 inches = 1 m. This makes a U.S. survey foot exactly 1200/3937 meters.
   * @param value The unit as UsSurveyFeet to create a new Length from.
   * @returns The new Length instance.
   */
  static FromUsSurveyFeet(value) {
    return new Length(value, LengthUnits.UsSurveyFeet);
  }
  /**
   * Create a new Length instance from a Inches
   *
   * @param value The unit as Inches to create a new Length from.
   * @returns The new Length instance.
   */
  static FromInches(value) {
    return new Length(value, LengthUnits.Inches);
  }
  /**
   * Create a new Length instance from a Mils
   *
   * @param value The unit as Mils to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMils(value) {
    return new Length(value, LengthUnits.Mils);
  }
  /**
   * Create a new Length instance from a NauticalMiles
   *
   * @param value The unit as NauticalMiles to create a new Length from.
   * @returns The new Length instance.
   */
  static FromNauticalMiles(value) {
    return new Length(value, LengthUnits.NauticalMiles);
  }
  /**
   * Create a new Length instance from a Fathoms
   *
   * @param value The unit as Fathoms to create a new Length from.
   * @returns The new Length instance.
   */
  static FromFathoms(value) {
    return new Length(value, LengthUnits.Fathoms);
  }
  /**
   * Create a new Length instance from a Shackles
   *
   * @param value The unit as Shackles to create a new Length from.
   * @returns The new Length instance.
   */
  static FromShackles(value) {
    return new Length(value, LengthUnits.Shackles);
  }
  /**
   * Create a new Length instance from a Microinches
   *
   * @param value The unit as Microinches to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMicroinches(value) {
    return new Length(value, LengthUnits.Microinches);
  }
  /**
   * Create a new Length instance from a PrinterPoints
   *
   * @param value The unit as PrinterPoints to create a new Length from.
   * @returns The new Length instance.
   */
  static FromPrinterPoints(value) {
    return new Length(value, LengthUnits.PrinterPoints);
  }
  /**
   * Create a new Length instance from a DtpPoints
   *
   * @param value The unit as DtpPoints to create a new Length from.
   * @returns The new Length instance.
   */
  static FromDtpPoints(value) {
    return new Length(value, LengthUnits.DtpPoints);
  }
  /**
   * Create a new Length instance from a PrinterPicas
   *
   * @param value The unit as PrinterPicas to create a new Length from.
   * @returns The new Length instance.
   */
  static FromPrinterPicas(value) {
    return new Length(value, LengthUnits.PrinterPicas);
  }
  /**
   * Create a new Length instance from a DtpPicas
   *
   * @param value The unit as DtpPicas to create a new Length from.
   * @returns The new Length instance.
   */
  static FromDtpPicas(value) {
    return new Length(value, LengthUnits.DtpPicas);
  }
  /**
   * Create a new Length instance from a Twips
   *
   * @param value The unit as Twips to create a new Length from.
   * @returns The new Length instance.
   */
  static FromTwips(value) {
    return new Length(value, LengthUnits.Twips);
  }
  /**
   * Create a new Length instance from a Hands
   *
   * @param value The unit as Hands to create a new Length from.
   * @returns The new Length instance.
   */
  static FromHands(value) {
    return new Length(value, LengthUnits.Hands);
  }
  /**
   * Create a new Length instance from a AstronomicalUnits
   * One Astronomical Unit is the distance from the solar system Star, the sun, to planet Earth.
   * @param value The unit as AstronomicalUnits to create a new Length from.
   * @returns The new Length instance.
   */
  static FromAstronomicalUnits(value) {
    return new Length(value, LengthUnits.AstronomicalUnits);
  }
  /**
   * Create a new Length instance from a Parsecs
   * A parsec is defined as the distance at which one astronomical unit (AU) subtends an angle of one arcsecond.
   * @param value The unit as Parsecs to create a new Length from.
   * @returns The new Length instance.
   */
  static FromParsecs(value) {
    return new Length(value, LengthUnits.Parsecs);
  }
  /**
   * Create a new Length instance from a LightYears
   * A Light Year (ly) is the distance that light travel during an Earth year, ie 365 days.
   * @param value The unit as LightYears to create a new Length from.
   * @returns The new Length instance.
   */
  static FromLightYears(value) {
    return new Length(value, LengthUnits.LightYears);
  }
  /**
   * Create a new Length instance from a SolarRadiuses
   * Solar radius is a ratio unit to the radius of the solar system star, the sun.
   * @param value The unit as SolarRadiuses to create a new Length from.
   * @returns The new Length instance.
   */
  static FromSolarRadiuses(value) {
    return new Length(value, LengthUnits.SolarRadiuses);
  }
  /**
   * Create a new Length instance from a Chains
   *
   * @param value The unit as Chains to create a new Length from.
   * @returns The new Length instance.
   */
  static FromChains(value) {
    return new Length(value, LengthUnits.Chains);
  }
  /**
   * Create a new Length instance from a Angstroms
   * Angstrom is a metric unit of length equal to 1e-10 meter
   * @param value The unit as Angstroms to create a new Length from.
   * @returns The new Length instance.
   */
  static FromAngstroms(value) {
    return new Length(value, LengthUnits.Angstroms);
  }
  /**
   * Create a new Length instance from a DataMiles
   * In radar-related subjects and in JTIDS, a data mile is a unit of distance equal to 6000 feet (1.8288 kilometres or 0.987 nautical miles).
   * @param value The unit as DataMiles to create a new Length from.
   * @returns The new Length instance.
   */
  static FromDataMiles(value) {
    return new Length(value, LengthUnits.DataMiles);
  }
  /**
   * Create a new Length instance from a Femtometers
   *
   * @param value The unit as Femtometers to create a new Length from.
   * @returns The new Length instance.
   */
  static FromFemtometers(value) {
    return new Length(value, LengthUnits.Femtometers);
  }
  /**
   * Create a new Length instance from a Picometers
   *
   * @param value The unit as Picometers to create a new Length from.
   * @returns The new Length instance.
   */
  static FromPicometers(value) {
    return new Length(value, LengthUnits.Picometers);
  }
  /**
   * Create a new Length instance from a Nanometers
   *
   * @param value The unit as Nanometers to create a new Length from.
   * @returns The new Length instance.
   */
  static FromNanometers(value) {
    return new Length(value, LengthUnits.Nanometers);
  }
  /**
   * Create a new Length instance from a Micrometers
   *
   * @param value The unit as Micrometers to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMicrometers(value) {
    return new Length(value, LengthUnits.Micrometers);
  }
  /**
   * Create a new Length instance from a Millimeters
   *
   * @param value The unit as Millimeters to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMillimeters(value) {
    return new Length(value, LengthUnits.Millimeters);
  }
  /**
   * Create a new Length instance from a Centimeters
   *
   * @param value The unit as Centimeters to create a new Length from.
   * @returns The new Length instance.
   */
  static FromCentimeters(value) {
    return new Length(value, LengthUnits.Centimeters);
  }
  /**
   * Create a new Length instance from a Decimeters
   *
   * @param value The unit as Decimeters to create a new Length from.
   * @returns The new Length instance.
   */
  static FromDecimeters(value) {
    return new Length(value, LengthUnits.Decimeters);
  }
  /**
   * Create a new Length instance from a Decameters
   *
   * @param value The unit as Decameters to create a new Length from.
   * @returns The new Length instance.
   */
  static FromDecameters(value) {
    return new Length(value, LengthUnits.Decameters);
  }
  /**
   * Create a new Length instance from a Hectometers
   *
   * @param value The unit as Hectometers to create a new Length from.
   * @returns The new Length instance.
   */
  static FromHectometers(value) {
    return new Length(value, LengthUnits.Hectometers);
  }
  /**
   * Create a new Length instance from a Kilometers
   *
   * @param value The unit as Kilometers to create a new Length from.
   * @returns The new Length instance.
   */
  static FromKilometers(value) {
    return new Length(value, LengthUnits.Kilometers);
  }
  /**
   * Create a new Length instance from a Megameters
   *
   * @param value The unit as Megameters to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMegameters(value) {
    return new Length(value, LengthUnits.Megameters);
  }
  /**
   * Create a new Length instance from a Gigameters
   *
   * @param value The unit as Gigameters to create a new Length from.
   * @returns The new Length instance.
   */
  static FromGigameters(value) {
    return new Length(value, LengthUnits.Gigameters);
  }
  /**
   * Create a new Length instance from a Kiloyards
   *
   * @param value The unit as Kiloyards to create a new Length from.
   * @returns The new Length instance.
   */
  static FromKiloyards(value) {
    return new Length(value, LengthUnits.Kiloyards);
  }
  /**
   * Create a new Length instance from a Kilofeet
   *
   * @param value The unit as Kilofeet to create a new Length from.
   * @returns The new Length instance.
   */
  static FromKilofeet(value) {
    return new Length(value, LengthUnits.Kilofeet);
  }
  /**
   * Create a new Length instance from a Kiloparsecs
   *
   * @param value The unit as Kiloparsecs to create a new Length from.
   * @returns The new Length instance.
   */
  static FromKiloparsecs(value) {
    return new Length(value, LengthUnits.Kiloparsecs);
  }
  /**
   * Create a new Length instance from a Megaparsecs
   *
   * @param value The unit as Megaparsecs to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMegaparsecs(value) {
    return new Length(value, LengthUnits.Megaparsecs);
  }
  /**
   * Create a new Length instance from a KilolightYears
   *
   * @param value The unit as KilolightYears to create a new Length from.
   * @returns The new Length instance.
   */
  static FromKilolightYears(value) {
    return new Length(value, LengthUnits.KilolightYears);
  }
  /**
   * Create a new Length instance from a MegalightYears
   *
   * @param value The unit as MegalightYears to create a new Length from.
   * @returns The new Length instance.
   */
  static FromMegalightYears(value) {
    return new Length(value, LengthUnits.MegalightYears);
  }
  /**
   * Gets the base unit enumeration associated with Length
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return LengthUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return LengthUnits.Meters;
  }
  /**
   * Create API DTO represent a Length unit.
   * @param holdInUnit The specific Length unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = LengthUnits.Meters) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Length unit from an API DTO representation.
   * @param dtoLength The Length API DTO representation
   */
  static FromDto(dtoLength) {
    return new Length(dtoLength.value, dtoLength.unit);
  }
  /**
   * Convert Length to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case LengthUnits.Meters:
        return this.Meters;
      case LengthUnits.Miles:
        return this.Miles;
      case LengthUnits.Yards:
        return this.Yards;
      case LengthUnits.Feet:
        return this.Feet;
      case LengthUnits.UsSurveyFeet:
        return this.UsSurveyFeet;
      case LengthUnits.Inches:
        return this.Inches;
      case LengthUnits.Mils:
        return this.Mils;
      case LengthUnits.NauticalMiles:
        return this.NauticalMiles;
      case LengthUnits.Fathoms:
        return this.Fathoms;
      case LengthUnits.Shackles:
        return this.Shackles;
      case LengthUnits.Microinches:
        return this.Microinches;
      case LengthUnits.PrinterPoints:
        return this.PrinterPoints;
      case LengthUnits.DtpPoints:
        return this.DtpPoints;
      case LengthUnits.PrinterPicas:
        return this.PrinterPicas;
      case LengthUnits.DtpPicas:
        return this.DtpPicas;
      case LengthUnits.Twips:
        return this.Twips;
      case LengthUnits.Hands:
        return this.Hands;
      case LengthUnits.AstronomicalUnits:
        return this.AstronomicalUnits;
      case LengthUnits.Parsecs:
        return this.Parsecs;
      case LengthUnits.LightYears:
        return this.LightYears;
      case LengthUnits.SolarRadiuses:
        return this.SolarRadiuses;
      case LengthUnits.Chains:
        return this.Chains;
      case LengthUnits.Angstroms:
        return this.Angstroms;
      case LengthUnits.DataMiles:
        return this.DataMiles;
      case LengthUnits.Femtometers:
        return this.Femtometers;
      case LengthUnits.Picometers:
        return this.Picometers;
      case LengthUnits.Nanometers:
        return this.Nanometers;
      case LengthUnits.Micrometers:
        return this.Micrometers;
      case LengthUnits.Millimeters:
        return this.Millimeters;
      case LengthUnits.Centimeters:
        return this.Centimeters;
      case LengthUnits.Decimeters:
        return this.Decimeters;
      case LengthUnits.Decameters:
        return this.Decameters;
      case LengthUnits.Hectometers:
        return this.Hectometers;
      case LengthUnits.Kilometers:
        return this.Kilometers;
      case LengthUnits.Megameters:
        return this.Megameters;
      case LengthUnits.Gigameters:
        return this.Gigameters;
      case LengthUnits.Kiloyards:
        return this.Kiloyards;
      case LengthUnits.Kilofeet:
        return this.Kilofeet;
      case LengthUnits.Kiloparsecs:
        return this.Kiloparsecs;
      case LengthUnits.Megaparsecs:
        return this.Megaparsecs;
      case LengthUnits.KilolightYears:
        return this.KilolightYears;
      case LengthUnits.MegalightYears:
        return this.MegalightYears;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case LengthUnits.Meters:
        return this.value;
      case LengthUnits.Miles:
        return super.internalDivide(this.value, 1609.344);
      case LengthUnits.Yards:
        return super.internalDivide(this.value, 0.9144);
      case LengthUnits.Feet:
        return super.internalDivide(this.value, 0.3048);
      case LengthUnits.UsSurveyFeet:
        {
          const v4 = super.internalDivide(3937, 1200);
          return super.internalMultiply(this.value, v4);
        }
      case LengthUnits.Inches:
        return super.internalDivide(this.value, 2.54e-2);
      case LengthUnits.Mils:
        return super.internalDivide(this.value, 2.54e-5);
      case LengthUnits.NauticalMiles:
        return super.internalDivide(this.value, 1852);
      case LengthUnits.Fathoms:
        return super.internalDivide(this.value, 1.8288);
      case LengthUnits.Shackles:
        return super.internalDivide(this.value, 27.432);
      case LengthUnits.Microinches:
        return super.internalDivide(this.value, 2.54e-8);
      case LengthUnits.PrinterPoints:
        {
          const v3 = super.internalDivide(this.value, 2.54e-2);
          return super.internalMultiply(v3, 72.27);
        }
      case LengthUnits.DtpPoints:
        {
          const v3 = super.internalDivide(this.value, 2.54e-2);
          return super.internalMultiply(v3, 72);
        }
      case LengthUnits.PrinterPicas:
        return super.internalMultiply(this.value, 237.106301584);
      case LengthUnits.DtpPicas:
        return super.internalMultiply(this.value, 236.220472441);
      case LengthUnits.Twips:
        return super.internalMultiply(this.value, 56692.913385826);
      case LengthUnits.Hands:
        return super.internalDivide(this.value, 1.016e-1);
      case LengthUnits.AstronomicalUnits:
        return super.internalDivide(this.value, 1.4959787070e11);
      case LengthUnits.Parsecs:
        return super.internalDivide(this.value, 3.08567758128e16);
      case LengthUnits.LightYears:
        return super.internalDivide(this.value, 9.46073047258e15);
      case LengthUnits.SolarRadiuses:
        return super.internalDivide(this.value, 6.95510000E+08);
      case LengthUnits.Chains:
        return super.internalDivide(this.value, 20.1168);
      case LengthUnits.Angstroms:
        return super.internalDivide(this.value, 1e-10);
      case LengthUnits.DataMiles:
        return super.internalDivide(this.value, 1828.8);
      case LengthUnits.Femtometers:
        return super.internalDivide(this.value, 1e-15);
      case LengthUnits.Picometers:
        return super.internalDivide(this.value, 1e-12);
      case LengthUnits.Nanometers:
        return super.internalDivide(this.value, 1e-9);
      case LengthUnits.Micrometers:
        return super.internalDivide(this.value, 0.000001);
      case LengthUnits.Millimeters:
        return super.internalDivide(this.value, 0.001);
      case LengthUnits.Centimeters:
        return super.internalDivide(this.value, 0.01);
      case LengthUnits.Decimeters:
        return super.internalDivide(this.value, 0.1);
      case LengthUnits.Decameters:
        return super.internalDivide(this.value, 10);
      case LengthUnits.Hectometers:
        return super.internalDivide(this.value, 100);
      case LengthUnits.Kilometers:
        return super.internalDivide(this.value, 1000);
      case LengthUnits.Megameters:
        return super.internalDivide(this.value, 1000000);
      case LengthUnits.Gigameters:
        return super.internalDivide(this.value, 1000000000);
      case LengthUnits.Kiloyards:
        {
          const v3 = super.internalDivide(this.value, 0.9144);
          return super.internalDivide(v3, 1000);
        }
      case LengthUnits.Kilofeet:
        {
          const v3 = super.internalDivide(this.value, 0.3048);
          return super.internalDivide(v3, 1000);
        }
      case LengthUnits.Kiloparsecs:
        {
          const v3 = super.internalDivide(this.value, 3.08567758128e16);
          return super.internalDivide(v3, 1000);
        }
      case LengthUnits.Megaparsecs:
        {
          const v3 = super.internalDivide(this.value, 3.08567758128e16);
          return super.internalDivide(v3, 1000000);
        }
      case LengthUnits.KilolightYears:
        {
          const v3 = super.internalDivide(this.value, 9.46073047258e15);
          return super.internalDivide(v3, 1000);
        }
      case LengthUnits.MegalightYears:
        {
          const v3 = super.internalDivide(this.value, 9.46073047258e15);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case LengthUnits.Meters:
        return this.value;
      case LengthUnits.Miles:
        return this.value / 1609.344;
      case LengthUnits.Yards:
        return this.value / 0.9144;
      case LengthUnits.Feet:
        return this.value / 0.3048;
      case LengthUnits.UsSurveyFeet:
        return this.value * 3937 / 1200;
      case LengthUnits.Inches:
        return this.value / 2.54e-2;
      case LengthUnits.Mils:
        return this.value / 2.54e-5;
      case LengthUnits.NauticalMiles:
        return this.value / 1852;
      case LengthUnits.Fathoms:
        return this.value / 1.8288;
      case LengthUnits.Shackles:
        return this.value / 27.432;
      case LengthUnits.Microinches:
        return this.value / 2.54e-8;
      case LengthUnits.PrinterPoints:
        return this.value / 2.54e-2 * 72.27;
      case LengthUnits.DtpPoints:
        return this.value / 2.54e-2 * 72;
      case LengthUnits.PrinterPicas:
        return this.value * 237.106301584;
      case LengthUnits.DtpPicas:
        return this.value * 236.220472441;
      case LengthUnits.Twips:
        return this.value * 56692.913385826;
      case LengthUnits.Hands:
        return this.value / 1.016e-1;
      case LengthUnits.AstronomicalUnits:
        return this.value / 1.4959787070e11;
      case LengthUnits.Parsecs:
        return this.value / 3.08567758128e16;
      case LengthUnits.LightYears:
        return this.value / 9.46073047258e15;
      case LengthUnits.SolarRadiuses:
        return this.value / 6.95510000E+08;
      case LengthUnits.Chains:
        return this.value / 20.1168;
      case LengthUnits.Angstroms:
        return this.value / 1e-10;
      case LengthUnits.DataMiles:
        return this.value / 1828.8;
      case LengthUnits.Femtometers:
        return this.value / 1e-15;
      case LengthUnits.Picometers:
        return this.value / 1e-12;
      case LengthUnits.Nanometers:
        return this.value / 1e-9;
      case LengthUnits.Micrometers:
        return this.value / 0.000001;
      case LengthUnits.Millimeters:
        return this.value / 0.001;
      case LengthUnits.Centimeters:
        return this.value / 0.01;
      case LengthUnits.Decimeters:
        return this.value / 0.1;
      case LengthUnits.Decameters:
        return this.value / 10;
      case LengthUnits.Hectometers:
        return this.value / 100;
      case LengthUnits.Kilometers:
        return this.value / 1000;
      case LengthUnits.Megameters:
        return this.value / 1000000;
      case LengthUnits.Gigameters:
        return this.value / 1000000000;
      case LengthUnits.Kiloyards:
        return this.value / 0.9144 / 1000;
      case LengthUnits.Kilofeet:
        return this.value / 0.3048 / 1000;
      case LengthUnits.Kiloparsecs:
        return this.value / 3.08567758128e16 / 1000;
      case LengthUnits.Megaparsecs:
        return this.value / 3.08567758128e16 / 1000000;
      case LengthUnits.KilolightYears:
        return this.value / 9.46073047258e15 / 1000;
      case LengthUnits.MegalightYears:
        return this.value / 9.46073047258e15 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case LengthUnits.Meters:
        return value;
      case LengthUnits.Miles:
        return super.internalMultiply(value, 1609.344);
      case LengthUnits.Yards:
        return super.internalMultiply(value, 0.9144);
      case LengthUnits.Feet:
        return super.internalMultiply(value, 0.3048);
      case LengthUnits.UsSurveyFeet:
        {
          const v4 = super.internalDivide(1200, 3937);
          return super.internalMultiply(value, v4);
        }
      case LengthUnits.Inches:
        return super.internalMultiply(value, 2.54e-2);
      case LengthUnits.Mils:
        return super.internalMultiply(value, 2.54e-5);
      case LengthUnits.NauticalMiles:
        return super.internalMultiply(value, 1852);
      case LengthUnits.Fathoms:
        return super.internalMultiply(value, 1.8288);
      case LengthUnits.Shackles:
        return super.internalMultiply(value, 27.432);
      case LengthUnits.Microinches:
        return super.internalMultiply(value, 2.54e-8);
      case LengthUnits.PrinterPoints:
        {
          const v3 = super.internalDivide(value, 72.27);
          return super.internalMultiply(v3, 2.54e-2);
        }
      case LengthUnits.DtpPoints:
        {
          const v3 = super.internalDivide(value, 72);
          return super.internalMultiply(v3, 2.54e-2);
        }
      case LengthUnits.PrinterPicas:
        return super.internalDivide(value, 237.106301584);
      case LengthUnits.DtpPicas:
        return super.internalDivide(value, 236.220472441);
      case LengthUnits.Twips:
        return super.internalDivide(value, 56692.913385826);
      case LengthUnits.Hands:
        return super.internalMultiply(value, 1.016e-1);
      case LengthUnits.AstronomicalUnits:
        return super.internalMultiply(value, 1.4959787070e11);
      case LengthUnits.Parsecs:
        return super.internalMultiply(value, 3.08567758128e16);
      case LengthUnits.LightYears:
        return super.internalMultiply(value, 9.46073047258e15);
      case LengthUnits.SolarRadiuses:
        return super.internalMultiply(value, 6.95510000E+08);
      case LengthUnits.Chains:
        return super.internalMultiply(value, 20.1168);
      case LengthUnits.Angstroms:
        return super.internalMultiply(value, 1e-10);
      case LengthUnits.DataMiles:
        return super.internalMultiply(value, 1828.8);
      case LengthUnits.Femtometers:
        return super.internalMultiply(value, 1e-15);
      case LengthUnits.Picometers:
        return super.internalMultiply(value, 1e-12);
      case LengthUnits.Nanometers:
        return super.internalMultiply(value, 1e-9);
      case LengthUnits.Micrometers:
        return super.internalMultiply(value, 0.000001);
      case LengthUnits.Millimeters:
        return super.internalMultiply(value, 0.001);
      case LengthUnits.Centimeters:
        return super.internalMultiply(value, 0.01);
      case LengthUnits.Decimeters:
        return super.internalMultiply(value, 0.1);
      case LengthUnits.Decameters:
        return super.internalMultiply(value, 10);
      case LengthUnits.Hectometers:
        return super.internalMultiply(value, 100);
      case LengthUnits.Kilometers:
        return super.internalMultiply(value, 1000);
      case LengthUnits.Megameters:
        return super.internalMultiply(value, 1000000);
      case LengthUnits.Gigameters:
        return super.internalMultiply(value, 1000000000);
      case LengthUnits.Kiloyards:
        {
          const v3 = super.internalMultiply(value, 0.9144);
          return super.internalMultiply(v3, 1000);
        }
      case LengthUnits.Kilofeet:
        {
          const v3 = super.internalMultiply(value, 0.3048);
          return super.internalMultiply(v3, 1000);
        }
      case LengthUnits.Kiloparsecs:
        {
          const v3 = super.internalMultiply(value, 3.08567758128e16);
          return super.internalMultiply(v3, 1000);
        }
      case LengthUnits.Megaparsecs:
        {
          const v3 = super.internalMultiply(value, 3.08567758128e16);
          return super.internalMultiply(v3, 1000000);
        }
      case LengthUnits.KilolightYears:
        {
          const v3 = super.internalMultiply(value, 9.46073047258e15);
          return super.internalMultiply(v3, 1000);
        }
      case LengthUnits.MegalightYears:
        {
          const v3 = super.internalMultiply(value, 9.46073047258e15);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case LengthUnits.Meters:
        return value;
      case LengthUnits.Miles:
        return value * 1609.344;
      case LengthUnits.Yards:
        return value * 0.9144;
      case LengthUnits.Feet:
        return value * 0.3048;
      case LengthUnits.UsSurveyFeet:
        return value * 1200 / 3937;
      case LengthUnits.Inches:
        return value * 2.54e-2;
      case LengthUnits.Mils:
        return value * 2.54e-5;
      case LengthUnits.NauticalMiles:
        return value * 1852;
      case LengthUnits.Fathoms:
        return value * 1.8288;
      case LengthUnits.Shackles:
        return value * 27.432;
      case LengthUnits.Microinches:
        return value * 2.54e-8;
      case LengthUnits.PrinterPoints:
        return value / 72.27 * 2.54e-2;
      case LengthUnits.DtpPoints:
        return value / 72 * 2.54e-2;
      case LengthUnits.PrinterPicas:
        return value / 237.106301584;
      case LengthUnits.DtpPicas:
        return value / 236.220472441;
      case LengthUnits.Twips:
        return value / 56692.913385826;
      case LengthUnits.Hands:
        return value * 1.016e-1;
      case LengthUnits.AstronomicalUnits:
        return value * 1.4959787070e11;
      case LengthUnits.Parsecs:
        return value * 3.08567758128e16;
      case LengthUnits.LightYears:
        return value * 9.46073047258e15;
      case LengthUnits.SolarRadiuses:
        return value * 6.95510000E+08;
      case LengthUnits.Chains:
        return value * 20.1168;
      case LengthUnits.Angstroms:
        return value * 1e-10;
      case LengthUnits.DataMiles:
        return value * 1828.8;
      case LengthUnits.Femtometers:
        return value * 1e-15;
      case LengthUnits.Picometers:
        return value * 1e-12;
      case LengthUnits.Nanometers:
        return value * 1e-9;
      case LengthUnits.Micrometers:
        return value * 0.000001;
      case LengthUnits.Millimeters:
        return value * 0.001;
      case LengthUnits.Centimeters:
        return value * 0.01;
      case LengthUnits.Decimeters:
        return value * 0.1;
      case LengthUnits.Decameters:
        return value * 10;
      case LengthUnits.Hectometers:
        return value * 100;
      case LengthUnits.Kilometers:
        return value * 1000;
      case LengthUnits.Megameters:
        return value * 1000000;
      case LengthUnits.Gigameters:
        return value * 1000000000;
      case LengthUnits.Kiloyards:
        return value * 0.9144 * 1000;
      case LengthUnits.Kilofeet:
        return value * 0.3048 * 1000;
      case LengthUnits.Kiloparsecs:
        return value * 3.08567758128e16 * 1000;
      case LengthUnits.Megaparsecs:
        return value * 3.08567758128e16 * 1000000;
      case LengthUnits.KilolightYears:
        return value * 9.46073047258e15 * 1000;
      case LengthUnits.MegalightYears:
        return value * 9.46073047258e15 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Length to string.
   * Note! the default format for Length is Meters.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Length.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Length.
   */
  toString(unit = LengthUnits.Meters, fractionalDigits) {
    switch (unit) {
      case LengthUnits.Meters:
        return super.truncateFractionDigits(this.Meters, fractionalDigits) + ` m`;
      case LengthUnits.Miles:
        return super.truncateFractionDigits(this.Miles, fractionalDigits) + ` mi`;
      case LengthUnits.Yards:
        return super.truncateFractionDigits(this.Yards, fractionalDigits) + ` yd`;
      case LengthUnits.Feet:
        return super.truncateFractionDigits(this.Feet, fractionalDigits) + ` ft`;
      case LengthUnits.UsSurveyFeet:
        return super.truncateFractionDigits(this.UsSurveyFeet, fractionalDigits) + ` ftUS`;
      case LengthUnits.Inches:
        return super.truncateFractionDigits(this.Inches, fractionalDigits) + ` in`;
      case LengthUnits.Mils:
        return super.truncateFractionDigits(this.Mils, fractionalDigits) + ` mil`;
      case LengthUnits.NauticalMiles:
        return super.truncateFractionDigits(this.NauticalMiles, fractionalDigits) + ` NM`;
      case LengthUnits.Fathoms:
        return super.truncateFractionDigits(this.Fathoms, fractionalDigits) + ` fathom`;
      case LengthUnits.Shackles:
        return super.truncateFractionDigits(this.Shackles, fractionalDigits) + ` shackle`;
      case LengthUnits.Microinches:
        return super.truncateFractionDigits(this.Microinches, fractionalDigits) + ` µin`;
      case LengthUnits.PrinterPoints:
        return super.truncateFractionDigits(this.PrinterPoints, fractionalDigits) + ` pt`;
      case LengthUnits.DtpPoints:
        return super.truncateFractionDigits(this.DtpPoints, fractionalDigits) + ` pt`;
      case LengthUnits.PrinterPicas:
        return super.truncateFractionDigits(this.PrinterPicas, fractionalDigits) + ` pica`;
      case LengthUnits.DtpPicas:
        return super.truncateFractionDigits(this.DtpPicas, fractionalDigits) + ` pica`;
      case LengthUnits.Twips:
        return super.truncateFractionDigits(this.Twips, fractionalDigits) + ` twip`;
      case LengthUnits.Hands:
        return super.truncateFractionDigits(this.Hands, fractionalDigits) + ` h`;
      case LengthUnits.AstronomicalUnits:
        return super.truncateFractionDigits(this.AstronomicalUnits, fractionalDigits) + ` au`;
      case LengthUnits.Parsecs:
        return super.truncateFractionDigits(this.Parsecs, fractionalDigits) + ` pc`;
      case LengthUnits.LightYears:
        return super.truncateFractionDigits(this.LightYears, fractionalDigits) + ` ly`;
      case LengthUnits.SolarRadiuses:
        return super.truncateFractionDigits(this.SolarRadiuses, fractionalDigits) + ` R⊙`;
      case LengthUnits.Chains:
        return super.truncateFractionDigits(this.Chains, fractionalDigits) + ` ch`;
      case LengthUnits.Angstroms:
        return super.truncateFractionDigits(this.Angstroms, fractionalDigits) + ` Å`;
      case LengthUnits.DataMiles:
        return super.truncateFractionDigits(this.DataMiles, fractionalDigits) + ` DM`;
      case LengthUnits.Femtometers:
        return super.truncateFractionDigits(this.Femtometers, fractionalDigits) + ` fm`;
      case LengthUnits.Picometers:
        return super.truncateFractionDigits(this.Picometers, fractionalDigits) + ` pm`;
      case LengthUnits.Nanometers:
        return super.truncateFractionDigits(this.Nanometers, fractionalDigits) + ` nm`;
      case LengthUnits.Micrometers:
        return super.truncateFractionDigits(this.Micrometers, fractionalDigits) + ` μm`;
      case LengthUnits.Millimeters:
        return super.truncateFractionDigits(this.Millimeters, fractionalDigits) + ` mm`;
      case LengthUnits.Centimeters:
        return super.truncateFractionDigits(this.Centimeters, fractionalDigits) + ` cm`;
      case LengthUnits.Decimeters:
        return super.truncateFractionDigits(this.Decimeters, fractionalDigits) + ` dm`;
      case LengthUnits.Decameters:
        return super.truncateFractionDigits(this.Decameters, fractionalDigits) + ` dam`;
      case LengthUnits.Hectometers:
        return super.truncateFractionDigits(this.Hectometers, fractionalDigits) + ` hm`;
      case LengthUnits.Kilometers:
        return super.truncateFractionDigits(this.Kilometers, fractionalDigits) + ` km`;
      case LengthUnits.Megameters:
        return super.truncateFractionDigits(this.Megameters, fractionalDigits) + ` Mm`;
      case LengthUnits.Gigameters:
        return super.truncateFractionDigits(this.Gigameters, fractionalDigits) + ` Gm`;
      case LengthUnits.Kiloyards:
        return super.truncateFractionDigits(this.Kiloyards, fractionalDigits) + ` kyd`;
      case LengthUnits.Kilofeet:
        return super.truncateFractionDigits(this.Kilofeet, fractionalDigits) + ` kft`;
      case LengthUnits.Kiloparsecs:
        return super.truncateFractionDigits(this.Kiloparsecs, fractionalDigits) + ` kpc`;
      case LengthUnits.Megaparsecs:
        return super.truncateFractionDigits(this.Megaparsecs, fractionalDigits) + ` Mpc`;
      case LengthUnits.KilolightYears:
        return super.truncateFractionDigits(this.KilolightYears, fractionalDigits) + ` kly`;
      case LengthUnits.MegalightYears:
        return super.truncateFractionDigits(this.MegalightYears, fractionalDigits) + ` Mly`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Length unit abbreviation.
   * Note! the default abbreviation for Length is Meters.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Length.
   * @returns The abbreviation string of Length.
   */
  getUnitAbbreviation(unitAbbreviation = LengthUnits.Meters) {
    switch (unitAbbreviation) {
      case LengthUnits.Meters:
        return `m`;
      case LengthUnits.Miles:
        return `mi`;
      case LengthUnits.Yards:
        return `yd`;
      case LengthUnits.Feet:
        return `ft`;
      case LengthUnits.UsSurveyFeet:
        return `ftUS`;
      case LengthUnits.Inches:
        return `in`;
      case LengthUnits.Mils:
        return `mil`;
      case LengthUnits.NauticalMiles:
        return `NM`;
      case LengthUnits.Fathoms:
        return `fathom`;
      case LengthUnits.Shackles:
        return `shackle`;
      case LengthUnits.Microinches:
        return `µin`;
      case LengthUnits.PrinterPoints:
        return `pt`;
      case LengthUnits.DtpPoints:
        return `pt`;
      case LengthUnits.PrinterPicas:
        return `pica`;
      case LengthUnits.DtpPicas:
        return `pica`;
      case LengthUnits.Twips:
        return `twip`;
      case LengthUnits.Hands:
        return `h`;
      case LengthUnits.AstronomicalUnits:
        return `au`;
      case LengthUnits.Parsecs:
        return `pc`;
      case LengthUnits.LightYears:
        return `ly`;
      case LengthUnits.SolarRadiuses:
        return `R⊙`;
      case LengthUnits.Chains:
        return `ch`;
      case LengthUnits.Angstroms:
        return `Å`;
      case LengthUnits.DataMiles:
        return `DM`;
      case LengthUnits.Femtometers:
        return `fm`;
      case LengthUnits.Picometers:
        return `pm`;
      case LengthUnits.Nanometers:
        return `nm`;
      case LengthUnits.Micrometers:
        return `μm`;
      case LengthUnits.Millimeters:
        return `mm`;
      case LengthUnits.Centimeters:
        return `cm`;
      case LengthUnits.Decimeters:
        return `dm`;
      case LengthUnits.Decameters:
        return `dam`;
      case LengthUnits.Hectometers:
        return `hm`;
      case LengthUnits.Kilometers:
        return `km`;
      case LengthUnits.Megameters:
        return `Mm`;
      case LengthUnits.Gigameters:
        return `Gm`;
      case LengthUnits.Kiloyards:
        return `kyd`;
      case LengthUnits.Kilofeet:
        return `kft`;
      case LengthUnits.Kiloparsecs:
        return `kpc`;
      case LengthUnits.Megaparsecs:
        return `Mpc`;
      case LengthUnits.KilolightYears:
        return `kly`;
      case LengthUnits.MegalightYears:
        return `Mly`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Length are equals to the current Length.
   * @param length The other Length.
   * @returns True if the given Length are equal to the current Length.
   */
  equals(length) {
    return super.internalEquals(this.value, length.BaseValue);
  }
  /**
   * Compare the given Length against the current Length.
   * @param length The other Length.
   * @returns 0 if they are equal, -1 if the current Length is less then other, 1 if the current Length is greater then other.
   */
  compareTo(length) {
    return super.internalCompareTo(this.value, length.BaseValue);
  }
  /**
   * Add the given Length with the current Length.
   * @param length The other Length.
   * @returns A new Length instance with the results.
   */
  add(length) {
    return new Length(super.internalAdd(this.value, length.BaseValue));
  }
  /**
   * Subtract the given Length with the current Length.
   * @param length The other Length.
   * @returns A new Length instance with the results.
   */
  subtract(length) {
    return new Length(super.internalSubtract(this.value, length.BaseValue));
  }
  /**
   * Multiply the given Length with the current Length.
   * @param length The other Length.
   * @returns A new Length instance with the results.
   */
  multiply(length) {
    return new Length(super.internalMultiply(this.value, length.BaseValue));
  }
  /**
   * Divide the given Length with the current Length.
   * @param length The other Length.
   * @returns A new Length instance with the results.
   */
  divide(length) {
    return new Length(super.internalDivide(this.value, length.BaseValue));
  }
  /**
   * Modulo the given Length with the current Length.
   * @param length The other Length.
   * @returns A new Length instance with the results.
   */
  modulo(length) {
    return new Length(super.internalModulo(this.value, length.BaseValue));
  }
  /**
   * Pow the given Length with the current Length.
   * @param length The other Length.
   * @returns A new Length instance with the results.
   */
  pow(length) {
    return new Length(super.internalPow(this.value, length.BaseValue));
  }
}
exports.Length = Length;