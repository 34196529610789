"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MolarEntropy = exports.MolarEntropyUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MolarEntropyUnits enumeration */
var MolarEntropyUnits;
(function (MolarEntropyUnits) {
  /** */
  MolarEntropyUnits["JoulesPerMoleKelvin"] = "JoulePerMoleKelvin";
  /** */
  MolarEntropyUnits["KilojoulesPerMoleKelvin"] = "KilojoulePerMoleKelvin";
  /** */
  MolarEntropyUnits["MegajoulesPerMoleKelvin"] = "MegajoulePerMoleKelvin";
})(MolarEntropyUnits = exports.MolarEntropyUnits || (exports.MolarEntropyUnits = {}));
/** Molar entropy is amount of energy required to increase temperature of 1 mole substance by 1 Kelvin. */
class MolarEntropy extends base_unit_1.BaseUnit {
  /**
   * Create a new MolarEntropy.
   * @param value The value.
   * @param fromUnit The ‘MolarEntropy’ unit to create from.
   * The default unit is JoulesPerMoleKelvin
   */
  constructor(value, fromUnit = MolarEntropyUnits.JoulesPerMoleKelvin) {
    super();
    this.joulespermolekelvinLazy = null;
    this.kilojoulespermolekelvinLazy = null;
    this.megajoulespermolekelvinLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of MolarEntropy is JoulesPerMoleKelvin.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MolarEntropyUnits.JoulesPerMoleKelvin;
  }
  /** */
  get JoulesPerMoleKelvin() {
    if (this.joulespermolekelvinLazy !== null) {
      return this.joulespermolekelvinLazy;
    }
    return this.joulespermolekelvinLazy = this.convertFromBase(MolarEntropyUnits.JoulesPerMoleKelvin);
  }
  /** */
  get KilojoulesPerMoleKelvin() {
    if (this.kilojoulespermolekelvinLazy !== null) {
      return this.kilojoulespermolekelvinLazy;
    }
    return this.kilojoulespermolekelvinLazy = this.convertFromBase(MolarEntropyUnits.KilojoulesPerMoleKelvin);
  }
  /** */
  get MegajoulesPerMoleKelvin() {
    if (this.megajoulespermolekelvinLazy !== null) {
      return this.megajoulespermolekelvinLazy;
    }
    return this.megajoulespermolekelvinLazy = this.convertFromBase(MolarEntropyUnits.MegajoulesPerMoleKelvin);
  }
  /**
   * Create a new MolarEntropy instance from a JoulesPerMoleKelvin
   *
   * @param value The unit as JoulesPerMoleKelvin to create a new MolarEntropy from.
   * @returns The new MolarEntropy instance.
   */
  static FromJoulesPerMoleKelvin(value) {
    return new MolarEntropy(value, MolarEntropyUnits.JoulesPerMoleKelvin);
  }
  /**
   * Create a new MolarEntropy instance from a KilojoulesPerMoleKelvin
   *
   * @param value The unit as KilojoulesPerMoleKelvin to create a new MolarEntropy from.
   * @returns The new MolarEntropy instance.
   */
  static FromKilojoulesPerMoleKelvin(value) {
    return new MolarEntropy(value, MolarEntropyUnits.KilojoulesPerMoleKelvin);
  }
  /**
   * Create a new MolarEntropy instance from a MegajoulesPerMoleKelvin
   *
   * @param value The unit as MegajoulesPerMoleKelvin to create a new MolarEntropy from.
   * @returns The new MolarEntropy instance.
   */
  static FromMegajoulesPerMoleKelvin(value) {
    return new MolarEntropy(value, MolarEntropyUnits.MegajoulesPerMoleKelvin);
  }
  /**
   * Gets the base unit enumeration associated with MolarEntropy
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MolarEntropyUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MolarEntropyUnits.JoulesPerMoleKelvin;
  }
  /**
   * Create API DTO represent a MolarEntropy unit.
   * @param holdInUnit The specific MolarEntropy unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MolarEntropyUnits.JoulesPerMoleKelvin) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a MolarEntropy unit from an API DTO representation.
   * @param dtoMolarEntropy The MolarEntropy API DTO representation
   */
  static FromDto(dtoMolarEntropy) {
    return new MolarEntropy(dtoMolarEntropy.value, dtoMolarEntropy.unit);
  }
  /**
   * Convert MolarEntropy to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MolarEntropyUnits.JoulesPerMoleKelvin:
        return this.JoulesPerMoleKelvin;
      case MolarEntropyUnits.KilojoulesPerMoleKelvin:
        return this.KilojoulesPerMoleKelvin;
      case MolarEntropyUnits.MegajoulesPerMoleKelvin:
        return this.MegajoulesPerMoleKelvin;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MolarEntropyUnits.JoulesPerMoleKelvin:
        return this.value;
      case MolarEntropyUnits.KilojoulesPerMoleKelvin:
        return super.internalDivide(this.value, 1000);
      case MolarEntropyUnits.MegajoulesPerMoleKelvin:
        return super.internalDivide(this.value, 1000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MolarEntropyUnits.JoulesPerMoleKelvin:
        return this.value;
      case MolarEntropyUnits.KilojoulesPerMoleKelvin:
        return this.value / 1000;
      case MolarEntropyUnits.MegajoulesPerMoleKelvin:
        return this.value / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MolarEntropyUnits.JoulesPerMoleKelvin:
        return value;
      case MolarEntropyUnits.KilojoulesPerMoleKelvin:
        return super.internalMultiply(value, 1000);
      case MolarEntropyUnits.MegajoulesPerMoleKelvin:
        return super.internalMultiply(value, 1000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MolarEntropyUnits.JoulesPerMoleKelvin:
        return value;
      case MolarEntropyUnits.KilojoulesPerMoleKelvin:
        return value * 1000;
      case MolarEntropyUnits.MegajoulesPerMoleKelvin:
        return value * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the MolarEntropy to string.
   * Note! the default format for MolarEntropy is JoulesPerMoleKelvin.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the MolarEntropy.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the MolarEntropy.
   */
  toString(unit = MolarEntropyUnits.JoulesPerMoleKelvin, fractionalDigits) {
    switch (unit) {
      case MolarEntropyUnits.JoulesPerMoleKelvin:
        return super.truncateFractionDigits(this.JoulesPerMoleKelvin, fractionalDigits) + ` J/(mol*K)`;
      case MolarEntropyUnits.KilojoulesPerMoleKelvin:
        return super.truncateFractionDigits(this.KilojoulesPerMoleKelvin, fractionalDigits) + ` kJ/(mol*K)`;
      case MolarEntropyUnits.MegajoulesPerMoleKelvin:
        return super.truncateFractionDigits(this.MegajoulesPerMoleKelvin, fractionalDigits) + ` MJ/(mol*K)`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get MolarEntropy unit abbreviation.
   * Note! the default abbreviation for MolarEntropy is JoulesPerMoleKelvin.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the MolarEntropy.
   * @returns The abbreviation string of MolarEntropy.
   */
  getUnitAbbreviation(unitAbbreviation = MolarEntropyUnits.JoulesPerMoleKelvin) {
    switch (unitAbbreviation) {
      case MolarEntropyUnits.JoulesPerMoleKelvin:
        return `J/(mol*K)`;
      case MolarEntropyUnits.KilojoulesPerMoleKelvin:
        return `kJ/(mol*K)`;
      case MolarEntropyUnits.MegajoulesPerMoleKelvin:
        return `MJ/(mol*K)`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given MolarEntropy are equals to the current MolarEntropy.
   * @param molarEntropy The other MolarEntropy.
   * @returns True if the given MolarEntropy are equal to the current MolarEntropy.
   */
  equals(molarEntropy) {
    return super.internalEquals(this.value, molarEntropy.BaseValue);
  }
  /**
   * Compare the given MolarEntropy against the current MolarEntropy.
   * @param molarEntropy The other MolarEntropy.
   * @returns 0 if they are equal, -1 if the current MolarEntropy is less then other, 1 if the current MolarEntropy is greater then other.
   */
  compareTo(molarEntropy) {
    return super.internalCompareTo(this.value, molarEntropy.BaseValue);
  }
  /**
   * Add the given MolarEntropy with the current MolarEntropy.
   * @param molarEntropy The other MolarEntropy.
   * @returns A new MolarEntropy instance with the results.
   */
  add(molarEntropy) {
    return new MolarEntropy(super.internalAdd(this.value, molarEntropy.BaseValue));
  }
  /**
   * Subtract the given MolarEntropy with the current MolarEntropy.
   * @param molarEntropy The other MolarEntropy.
   * @returns A new MolarEntropy instance with the results.
   */
  subtract(molarEntropy) {
    return new MolarEntropy(super.internalSubtract(this.value, molarEntropy.BaseValue));
  }
  /**
   * Multiply the given MolarEntropy with the current MolarEntropy.
   * @param molarEntropy The other MolarEntropy.
   * @returns A new MolarEntropy instance with the results.
   */
  multiply(molarEntropy) {
    return new MolarEntropy(super.internalMultiply(this.value, molarEntropy.BaseValue));
  }
  /**
   * Divide the given MolarEntropy with the current MolarEntropy.
   * @param molarEntropy The other MolarEntropy.
   * @returns A new MolarEntropy instance with the results.
   */
  divide(molarEntropy) {
    return new MolarEntropy(super.internalDivide(this.value, molarEntropy.BaseValue));
  }
  /**
   * Modulo the given MolarEntropy with the current MolarEntropy.
   * @param molarEntropy The other MolarEntropy.
   * @returns A new MolarEntropy instance with the results.
   */
  modulo(molarEntropy) {
    return new MolarEntropy(super.internalModulo(this.value, molarEntropy.BaseValue));
  }
  /**
   * Pow the given MolarEntropy with the current MolarEntropy.
   * @param molarEntropy The other MolarEntropy.
   * @returns A new MolarEntropy instance with the results.
   */
  pow(molarEntropy) {
    return new MolarEntropy(super.internalPow(this.value, molarEntropy.BaseValue));
  }
}
exports.MolarEntropy = MolarEntropy;