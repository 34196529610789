import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { MultiSelect } from 'primeng/multiselect';
import { TreatmentPO } from 'src/app/models/treatmentsPO';

@Component({
  selector: 'app-multiselect-cell-editor',
  templateUrl: './multiselect-cell-editor.component.html',
  styleUrl: './multiselect-cell-editor.component.scss'
})
export class MultiSelectCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: any;
  options: any[] = [];
  toolTip: string = '';
  @ViewChild('myMultiSelect', { static: false }) myMultiSelect: MultiSelect;

  ngAfterViewInit(): void {
    this.myMultiSelect.show();
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;

    if (this.params.dataSourceType) {
      if (this.params.dataSourceType === "R3BatchCharacteristics") {
        this.params.values.forEach((val: TreatmentPO) => this.options.push({ name: val.code, value: val.code }));
        this.toolTip = "R3BatchCharacteristics";
      }
    }
  }

  getValue(): any {
    return this.value;
  }

  onValueChange(event: any): void {
    this.value = event.value;
  }

}
