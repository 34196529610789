"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VolumeFlow = exports.VolumeFlowUnits = void 0;
const base_unit_1 = require("../base-unit");
/** VolumeFlowUnits enumeration */
var VolumeFlowUnits;
(function (VolumeFlowUnits) {
  /** */
  VolumeFlowUnits["CubicMetersPerSecond"] = "CubicMeterPerSecond";
  /** */
  VolumeFlowUnits["CubicMetersPerMinute"] = "CubicMeterPerMinute";
  /** */
  VolumeFlowUnits["CubicMetersPerHour"] = "CubicMeterPerHour";
  /** */
  VolumeFlowUnits["CubicMetersPerDay"] = "CubicMeterPerDay";
  /** */
  VolumeFlowUnits["CubicFeetPerSecond"] = "CubicFootPerSecond";
  /** */
  VolumeFlowUnits["CubicFeetPerMinute"] = "CubicFootPerMinute";
  /** */
  VolumeFlowUnits["CubicFeetPerHour"] = "CubicFootPerHour";
  /** */
  VolumeFlowUnits["CubicYardsPerSecond"] = "CubicYardPerSecond";
  /** */
  VolumeFlowUnits["CubicYardsPerMinute"] = "CubicYardPerMinute";
  /** */
  VolumeFlowUnits["CubicYardsPerHour"] = "CubicYardPerHour";
  /** */
  VolumeFlowUnits["CubicYardsPerDay"] = "CubicYardPerDay";
  /** */
  VolumeFlowUnits["MillionUsGallonsPerDay"] = "MillionUsGallonPerDay";
  /** */
  VolumeFlowUnits["UsGallonsPerDay"] = "UsGallonPerDay";
  /** */
  VolumeFlowUnits["LitersPerSecond"] = "LiterPerSecond";
  /** */
  VolumeFlowUnits["LitersPerMinute"] = "LiterPerMinute";
  /** */
  VolumeFlowUnits["LitersPerHour"] = "LiterPerHour";
  /** */
  VolumeFlowUnits["LitersPerDay"] = "LiterPerDay";
  /** */
  VolumeFlowUnits["UsGallonsPerSecond"] = "UsGallonPerSecond";
  /** */
  VolumeFlowUnits["UsGallonsPerMinute"] = "UsGallonPerMinute";
  /** */
  VolumeFlowUnits["UkGallonsPerDay"] = "UkGallonPerDay";
  /** */
  VolumeFlowUnits["UkGallonsPerHour"] = "UkGallonPerHour";
  /** */
  VolumeFlowUnits["UkGallonsPerMinute"] = "UkGallonPerMinute";
  /** */
  VolumeFlowUnits["UkGallonsPerSecond"] = "UkGallonPerSecond";
  /** */
  VolumeFlowUnits["KilousGallonsPerMinute"] = "KilousGallonPerMinute";
  /** */
  VolumeFlowUnits["UsGallonsPerHour"] = "UsGallonPerHour";
  /** */
  VolumeFlowUnits["CubicDecimetersPerMinute"] = "CubicDecimeterPerMinute";
  /** */
  VolumeFlowUnits["OilBarrelsPerDay"] = "OilBarrelPerDay";
  /** */
  VolumeFlowUnits["OilBarrelsPerMinute"] = "OilBarrelPerMinute";
  /** */
  VolumeFlowUnits["OilBarrelsPerHour"] = "OilBarrelPerHour";
  /** */
  VolumeFlowUnits["OilBarrelsPerSecond"] = "OilBarrelPerSecond";
  /** */
  VolumeFlowUnits["CubicMillimetersPerSecond"] = "CubicMillimeterPerSecond";
  /** */
  VolumeFlowUnits["AcreFeetPerSecond"] = "AcreFootPerSecond";
  /** */
  VolumeFlowUnits["AcreFeetPerMinute"] = "AcreFootPerMinute";
  /** */
  VolumeFlowUnits["AcreFeetPerHour"] = "AcreFootPerHour";
  /** */
  VolumeFlowUnits["AcreFeetPerDay"] = "AcreFootPerDay";
  /** */
  VolumeFlowUnits["CubicCentimetersPerMinute"] = "CubicCentimeterPerMinute";
  /** */
  VolumeFlowUnits["MegausGallonsPerDay"] = "MegausGallonPerDay";
  /** */
  VolumeFlowUnits["NanolitersPerSecond"] = "NanoliterPerSecond";
  /** */
  VolumeFlowUnits["MicrolitersPerSecond"] = "MicroliterPerSecond";
  /** */
  VolumeFlowUnits["MillilitersPerSecond"] = "MilliliterPerSecond";
  /** */
  VolumeFlowUnits["CentilitersPerSecond"] = "CentiliterPerSecond";
  /** */
  VolumeFlowUnits["DecilitersPerSecond"] = "DeciliterPerSecond";
  /** */
  VolumeFlowUnits["DecalitersPerSecond"] = "DecaliterPerSecond";
  /** */
  VolumeFlowUnits["HectolitersPerSecond"] = "HectoliterPerSecond";
  /** */
  VolumeFlowUnits["KilolitersPerSecond"] = "KiloliterPerSecond";
  /** */
  VolumeFlowUnits["MegalitersPerSecond"] = "MegaliterPerSecond";
  /** */
  VolumeFlowUnits["NanolitersPerMinute"] = "NanoliterPerMinute";
  /** */
  VolumeFlowUnits["MicrolitersPerMinute"] = "MicroliterPerMinute";
  /** */
  VolumeFlowUnits["MillilitersPerMinute"] = "MilliliterPerMinute";
  /** */
  VolumeFlowUnits["CentilitersPerMinute"] = "CentiliterPerMinute";
  /** */
  VolumeFlowUnits["DecilitersPerMinute"] = "DeciliterPerMinute";
  /** */
  VolumeFlowUnits["DecalitersPerMinute"] = "DecaliterPerMinute";
  /** */
  VolumeFlowUnits["HectolitersPerMinute"] = "HectoliterPerMinute";
  /** */
  VolumeFlowUnits["KilolitersPerMinute"] = "KiloliterPerMinute";
  /** */
  VolumeFlowUnits["MegalitersPerMinute"] = "MegaliterPerMinute";
  /** */
  VolumeFlowUnits["NanolitersPerHour"] = "NanoliterPerHour";
  /** */
  VolumeFlowUnits["MicrolitersPerHour"] = "MicroliterPerHour";
  /** */
  VolumeFlowUnits["MillilitersPerHour"] = "MilliliterPerHour";
  /** */
  VolumeFlowUnits["CentilitersPerHour"] = "CentiliterPerHour";
  /** */
  VolumeFlowUnits["DecilitersPerHour"] = "DeciliterPerHour";
  /** */
  VolumeFlowUnits["DecalitersPerHour"] = "DecaliterPerHour";
  /** */
  VolumeFlowUnits["HectolitersPerHour"] = "HectoliterPerHour";
  /** */
  VolumeFlowUnits["KilolitersPerHour"] = "KiloliterPerHour";
  /** */
  VolumeFlowUnits["MegalitersPerHour"] = "MegaliterPerHour";
  /** */
  VolumeFlowUnits["NanolitersPerDay"] = "NanoliterPerDay";
  /** */
  VolumeFlowUnits["MicrolitersPerDay"] = "MicroliterPerDay";
  /** */
  VolumeFlowUnits["MillilitersPerDay"] = "MilliliterPerDay";
  /** */
  VolumeFlowUnits["CentilitersPerDay"] = "CentiliterPerDay";
  /** */
  VolumeFlowUnits["DecilitersPerDay"] = "DeciliterPerDay";
  /** */
  VolumeFlowUnits["DecalitersPerDay"] = "DecaliterPerDay";
  /** */
  VolumeFlowUnits["HectolitersPerDay"] = "HectoliterPerDay";
  /** */
  VolumeFlowUnits["KilolitersPerDay"] = "KiloliterPerDay";
  /** */
  VolumeFlowUnits["MegalitersPerDay"] = "MegaliterPerDay";
  /** */
  VolumeFlowUnits["MegaukGallonsPerDay"] = "MegaukGallonPerDay";
  /** */
  VolumeFlowUnits["MegaukGallonsPerSecond"] = "MegaukGallonPerSecond";
})(VolumeFlowUnits = exports.VolumeFlowUnits || (exports.VolumeFlowUnits = {}));
/** In physics and engineering, in particular fluid dynamics and hydrometry, the volumetric flow rate, (also known as volume flow rate, rate of fluid flow or volume velocity) is the volume of fluid which passes through a given surface per unit time. The SI unit is m³/s (cubic meters per second). In US Customary Units and British Imperial Units, volumetric flow rate is often expressed as ft³/s (cubic feet per second). It is usually represented by the symbol Q. */
class VolumeFlow extends base_unit_1.BaseUnit {
  /**
   * Create a new VolumeFlow.
   * @param value The value.
   * @param fromUnit The ‘VolumeFlow’ unit to create from.
   * The default unit is CubicMetersPerSecond
   */
  constructor(value, fromUnit = VolumeFlowUnits.CubicMetersPerSecond) {
    super();
    this.cubicmeterspersecondLazy = null;
    this.cubicmetersperminuteLazy = null;
    this.cubicmetersperhourLazy = null;
    this.cubicmetersperdayLazy = null;
    this.cubicfeetpersecondLazy = null;
    this.cubicfeetperminuteLazy = null;
    this.cubicfeetperhourLazy = null;
    this.cubicyardspersecondLazy = null;
    this.cubicyardsperminuteLazy = null;
    this.cubicyardsperhourLazy = null;
    this.cubicyardsperdayLazy = null;
    this.millionusgallonsperdayLazy = null;
    this.usgallonsperdayLazy = null;
    this.literspersecondLazy = null;
    this.litersperminuteLazy = null;
    this.litersperhourLazy = null;
    this.litersperdayLazy = null;
    this.usgallonspersecondLazy = null;
    this.usgallonsperminuteLazy = null;
    this.ukgallonsperdayLazy = null;
    this.ukgallonsperhourLazy = null;
    this.ukgallonsperminuteLazy = null;
    this.ukgallonspersecondLazy = null;
    this.kilousgallonsperminuteLazy = null;
    this.usgallonsperhourLazy = null;
    this.cubicdecimetersperminuteLazy = null;
    this.oilbarrelsperdayLazy = null;
    this.oilbarrelsperminuteLazy = null;
    this.oilbarrelsperhourLazy = null;
    this.oilbarrelspersecondLazy = null;
    this.cubicmillimeterspersecondLazy = null;
    this.acrefeetpersecondLazy = null;
    this.acrefeetperminuteLazy = null;
    this.acrefeetperhourLazy = null;
    this.acrefeetperdayLazy = null;
    this.cubiccentimetersperminuteLazy = null;
    this.megausgallonsperdayLazy = null;
    this.nanoliterspersecondLazy = null;
    this.microliterspersecondLazy = null;
    this.milliliterspersecondLazy = null;
    this.centiliterspersecondLazy = null;
    this.deciliterspersecondLazy = null;
    this.decaliterspersecondLazy = null;
    this.hectoliterspersecondLazy = null;
    this.kiloliterspersecondLazy = null;
    this.megaliterspersecondLazy = null;
    this.nanolitersperminuteLazy = null;
    this.microlitersperminuteLazy = null;
    this.millilitersperminuteLazy = null;
    this.centilitersperminuteLazy = null;
    this.decilitersperminuteLazy = null;
    this.decalitersperminuteLazy = null;
    this.hectolitersperminuteLazy = null;
    this.kilolitersperminuteLazy = null;
    this.megalitersperminuteLazy = null;
    this.nanolitersperhourLazy = null;
    this.microlitersperhourLazy = null;
    this.millilitersperhourLazy = null;
    this.centilitersperhourLazy = null;
    this.decilitersperhourLazy = null;
    this.decalitersperhourLazy = null;
    this.hectolitersperhourLazy = null;
    this.kilolitersperhourLazy = null;
    this.megalitersperhourLazy = null;
    this.nanolitersperdayLazy = null;
    this.microlitersperdayLazy = null;
    this.millilitersperdayLazy = null;
    this.centilitersperdayLazy = null;
    this.decilitersperdayLazy = null;
    this.decalitersperdayLazy = null;
    this.hectolitersperdayLazy = null;
    this.kilolitersperdayLazy = null;
    this.megalitersperdayLazy = null;
    this.megaukgallonsperdayLazy = null;
    this.megaukgallonspersecondLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of VolumeFlow is CubicMetersPerSecond.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return VolumeFlowUnits.CubicMetersPerSecond;
  }
  /** */
  get CubicMetersPerSecond() {
    if (this.cubicmeterspersecondLazy !== null) {
      return this.cubicmeterspersecondLazy;
    }
    return this.cubicmeterspersecondLazy = this.convertFromBase(VolumeFlowUnits.CubicMetersPerSecond);
  }
  /** */
  get CubicMetersPerMinute() {
    if (this.cubicmetersperminuteLazy !== null) {
      return this.cubicmetersperminuteLazy;
    }
    return this.cubicmetersperminuteLazy = this.convertFromBase(VolumeFlowUnits.CubicMetersPerMinute);
  }
  /** */
  get CubicMetersPerHour() {
    if (this.cubicmetersperhourLazy !== null) {
      return this.cubicmetersperhourLazy;
    }
    return this.cubicmetersperhourLazy = this.convertFromBase(VolumeFlowUnits.CubicMetersPerHour);
  }
  /** */
  get CubicMetersPerDay() {
    if (this.cubicmetersperdayLazy !== null) {
      return this.cubicmetersperdayLazy;
    }
    return this.cubicmetersperdayLazy = this.convertFromBase(VolumeFlowUnits.CubicMetersPerDay);
  }
  /** */
  get CubicFeetPerSecond() {
    if (this.cubicfeetpersecondLazy !== null) {
      return this.cubicfeetpersecondLazy;
    }
    return this.cubicfeetpersecondLazy = this.convertFromBase(VolumeFlowUnits.CubicFeetPerSecond);
  }
  /** */
  get CubicFeetPerMinute() {
    if (this.cubicfeetperminuteLazy !== null) {
      return this.cubicfeetperminuteLazy;
    }
    return this.cubicfeetperminuteLazy = this.convertFromBase(VolumeFlowUnits.CubicFeetPerMinute);
  }
  /** */
  get CubicFeetPerHour() {
    if (this.cubicfeetperhourLazy !== null) {
      return this.cubicfeetperhourLazy;
    }
    return this.cubicfeetperhourLazy = this.convertFromBase(VolumeFlowUnits.CubicFeetPerHour);
  }
  /** */
  get CubicYardsPerSecond() {
    if (this.cubicyardspersecondLazy !== null) {
      return this.cubicyardspersecondLazy;
    }
    return this.cubicyardspersecondLazy = this.convertFromBase(VolumeFlowUnits.CubicYardsPerSecond);
  }
  /** */
  get CubicYardsPerMinute() {
    if (this.cubicyardsperminuteLazy !== null) {
      return this.cubicyardsperminuteLazy;
    }
    return this.cubicyardsperminuteLazy = this.convertFromBase(VolumeFlowUnits.CubicYardsPerMinute);
  }
  /** */
  get CubicYardsPerHour() {
    if (this.cubicyardsperhourLazy !== null) {
      return this.cubicyardsperhourLazy;
    }
    return this.cubicyardsperhourLazy = this.convertFromBase(VolumeFlowUnits.CubicYardsPerHour);
  }
  /** */
  get CubicYardsPerDay() {
    if (this.cubicyardsperdayLazy !== null) {
      return this.cubicyardsperdayLazy;
    }
    return this.cubicyardsperdayLazy = this.convertFromBase(VolumeFlowUnits.CubicYardsPerDay);
  }
  /** */
  get MillionUsGallonsPerDay() {
    if (this.millionusgallonsperdayLazy !== null) {
      return this.millionusgallonsperdayLazy;
    }
    return this.millionusgallonsperdayLazy = this.convertFromBase(VolumeFlowUnits.MillionUsGallonsPerDay);
  }
  /** */
  get UsGallonsPerDay() {
    if (this.usgallonsperdayLazy !== null) {
      return this.usgallonsperdayLazy;
    }
    return this.usgallonsperdayLazy = this.convertFromBase(VolumeFlowUnits.UsGallonsPerDay);
  }
  /** */
  get LitersPerSecond() {
    if (this.literspersecondLazy !== null) {
      return this.literspersecondLazy;
    }
    return this.literspersecondLazy = this.convertFromBase(VolumeFlowUnits.LitersPerSecond);
  }
  /** */
  get LitersPerMinute() {
    if (this.litersperminuteLazy !== null) {
      return this.litersperminuteLazy;
    }
    return this.litersperminuteLazy = this.convertFromBase(VolumeFlowUnits.LitersPerMinute);
  }
  /** */
  get LitersPerHour() {
    if (this.litersperhourLazy !== null) {
      return this.litersperhourLazy;
    }
    return this.litersperhourLazy = this.convertFromBase(VolumeFlowUnits.LitersPerHour);
  }
  /** */
  get LitersPerDay() {
    if (this.litersperdayLazy !== null) {
      return this.litersperdayLazy;
    }
    return this.litersperdayLazy = this.convertFromBase(VolumeFlowUnits.LitersPerDay);
  }
  /** */
  get UsGallonsPerSecond() {
    if (this.usgallonspersecondLazy !== null) {
      return this.usgallonspersecondLazy;
    }
    return this.usgallonspersecondLazy = this.convertFromBase(VolumeFlowUnits.UsGallonsPerSecond);
  }
  /** */
  get UsGallonsPerMinute() {
    if (this.usgallonsperminuteLazy !== null) {
      return this.usgallonsperminuteLazy;
    }
    return this.usgallonsperminuteLazy = this.convertFromBase(VolumeFlowUnits.UsGallonsPerMinute);
  }
  /** */
  get UkGallonsPerDay() {
    if (this.ukgallonsperdayLazy !== null) {
      return this.ukgallonsperdayLazy;
    }
    return this.ukgallonsperdayLazy = this.convertFromBase(VolumeFlowUnits.UkGallonsPerDay);
  }
  /** */
  get UkGallonsPerHour() {
    if (this.ukgallonsperhourLazy !== null) {
      return this.ukgallonsperhourLazy;
    }
    return this.ukgallonsperhourLazy = this.convertFromBase(VolumeFlowUnits.UkGallonsPerHour);
  }
  /** */
  get UkGallonsPerMinute() {
    if (this.ukgallonsperminuteLazy !== null) {
      return this.ukgallonsperminuteLazy;
    }
    return this.ukgallonsperminuteLazy = this.convertFromBase(VolumeFlowUnits.UkGallonsPerMinute);
  }
  /** */
  get UkGallonsPerSecond() {
    if (this.ukgallonspersecondLazy !== null) {
      return this.ukgallonspersecondLazy;
    }
    return this.ukgallonspersecondLazy = this.convertFromBase(VolumeFlowUnits.UkGallonsPerSecond);
  }
  /** */
  get KilousGallonsPerMinute() {
    if (this.kilousgallonsperminuteLazy !== null) {
      return this.kilousgallonsperminuteLazy;
    }
    return this.kilousgallonsperminuteLazy = this.convertFromBase(VolumeFlowUnits.KilousGallonsPerMinute);
  }
  /** */
  get UsGallonsPerHour() {
    if (this.usgallonsperhourLazy !== null) {
      return this.usgallonsperhourLazy;
    }
    return this.usgallonsperhourLazy = this.convertFromBase(VolumeFlowUnits.UsGallonsPerHour);
  }
  /** */
  get CubicDecimetersPerMinute() {
    if (this.cubicdecimetersperminuteLazy !== null) {
      return this.cubicdecimetersperminuteLazy;
    }
    return this.cubicdecimetersperminuteLazy = this.convertFromBase(VolumeFlowUnits.CubicDecimetersPerMinute);
  }
  /** */
  get OilBarrelsPerDay() {
    if (this.oilbarrelsperdayLazy !== null) {
      return this.oilbarrelsperdayLazy;
    }
    return this.oilbarrelsperdayLazy = this.convertFromBase(VolumeFlowUnits.OilBarrelsPerDay);
  }
  /** */
  get OilBarrelsPerMinute() {
    if (this.oilbarrelsperminuteLazy !== null) {
      return this.oilbarrelsperminuteLazy;
    }
    return this.oilbarrelsperminuteLazy = this.convertFromBase(VolumeFlowUnits.OilBarrelsPerMinute);
  }
  /** */
  get OilBarrelsPerHour() {
    if (this.oilbarrelsperhourLazy !== null) {
      return this.oilbarrelsperhourLazy;
    }
    return this.oilbarrelsperhourLazy = this.convertFromBase(VolumeFlowUnits.OilBarrelsPerHour);
  }
  /** */
  get OilBarrelsPerSecond() {
    if (this.oilbarrelspersecondLazy !== null) {
      return this.oilbarrelspersecondLazy;
    }
    return this.oilbarrelspersecondLazy = this.convertFromBase(VolumeFlowUnits.OilBarrelsPerSecond);
  }
  /** */
  get CubicMillimetersPerSecond() {
    if (this.cubicmillimeterspersecondLazy !== null) {
      return this.cubicmillimeterspersecondLazy;
    }
    return this.cubicmillimeterspersecondLazy = this.convertFromBase(VolumeFlowUnits.CubicMillimetersPerSecond);
  }
  /** */
  get AcreFeetPerSecond() {
    if (this.acrefeetpersecondLazy !== null) {
      return this.acrefeetpersecondLazy;
    }
    return this.acrefeetpersecondLazy = this.convertFromBase(VolumeFlowUnits.AcreFeetPerSecond);
  }
  /** */
  get AcreFeetPerMinute() {
    if (this.acrefeetperminuteLazy !== null) {
      return this.acrefeetperminuteLazy;
    }
    return this.acrefeetperminuteLazy = this.convertFromBase(VolumeFlowUnits.AcreFeetPerMinute);
  }
  /** */
  get AcreFeetPerHour() {
    if (this.acrefeetperhourLazy !== null) {
      return this.acrefeetperhourLazy;
    }
    return this.acrefeetperhourLazy = this.convertFromBase(VolumeFlowUnits.AcreFeetPerHour);
  }
  /** */
  get AcreFeetPerDay() {
    if (this.acrefeetperdayLazy !== null) {
      return this.acrefeetperdayLazy;
    }
    return this.acrefeetperdayLazy = this.convertFromBase(VolumeFlowUnits.AcreFeetPerDay);
  }
  /** */
  get CubicCentimetersPerMinute() {
    if (this.cubiccentimetersperminuteLazy !== null) {
      return this.cubiccentimetersperminuteLazy;
    }
    return this.cubiccentimetersperminuteLazy = this.convertFromBase(VolumeFlowUnits.CubicCentimetersPerMinute);
  }
  /** */
  get MegausGallonsPerDay() {
    if (this.megausgallonsperdayLazy !== null) {
      return this.megausgallonsperdayLazy;
    }
    return this.megausgallonsperdayLazy = this.convertFromBase(VolumeFlowUnits.MegausGallonsPerDay);
  }
  /** */
  get NanolitersPerSecond() {
    if (this.nanoliterspersecondLazy !== null) {
      return this.nanoliterspersecondLazy;
    }
    return this.nanoliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.NanolitersPerSecond);
  }
  /** */
  get MicrolitersPerSecond() {
    if (this.microliterspersecondLazy !== null) {
      return this.microliterspersecondLazy;
    }
    return this.microliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.MicrolitersPerSecond);
  }
  /** */
  get MillilitersPerSecond() {
    if (this.milliliterspersecondLazy !== null) {
      return this.milliliterspersecondLazy;
    }
    return this.milliliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.MillilitersPerSecond);
  }
  /** */
  get CentilitersPerSecond() {
    if (this.centiliterspersecondLazy !== null) {
      return this.centiliterspersecondLazy;
    }
    return this.centiliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.CentilitersPerSecond);
  }
  /** */
  get DecilitersPerSecond() {
    if (this.deciliterspersecondLazy !== null) {
      return this.deciliterspersecondLazy;
    }
    return this.deciliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.DecilitersPerSecond);
  }
  /** */
  get DecalitersPerSecond() {
    if (this.decaliterspersecondLazy !== null) {
      return this.decaliterspersecondLazy;
    }
    return this.decaliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.DecalitersPerSecond);
  }
  /** */
  get HectolitersPerSecond() {
    if (this.hectoliterspersecondLazy !== null) {
      return this.hectoliterspersecondLazy;
    }
    return this.hectoliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.HectolitersPerSecond);
  }
  /** */
  get KilolitersPerSecond() {
    if (this.kiloliterspersecondLazy !== null) {
      return this.kiloliterspersecondLazy;
    }
    return this.kiloliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.KilolitersPerSecond);
  }
  /** */
  get MegalitersPerSecond() {
    if (this.megaliterspersecondLazy !== null) {
      return this.megaliterspersecondLazy;
    }
    return this.megaliterspersecondLazy = this.convertFromBase(VolumeFlowUnits.MegalitersPerSecond);
  }
  /** */
  get NanolitersPerMinute() {
    if (this.nanolitersperminuteLazy !== null) {
      return this.nanolitersperminuteLazy;
    }
    return this.nanolitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.NanolitersPerMinute);
  }
  /** */
  get MicrolitersPerMinute() {
    if (this.microlitersperminuteLazy !== null) {
      return this.microlitersperminuteLazy;
    }
    return this.microlitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.MicrolitersPerMinute);
  }
  /** */
  get MillilitersPerMinute() {
    if (this.millilitersperminuteLazy !== null) {
      return this.millilitersperminuteLazy;
    }
    return this.millilitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.MillilitersPerMinute);
  }
  /** */
  get CentilitersPerMinute() {
    if (this.centilitersperminuteLazy !== null) {
      return this.centilitersperminuteLazy;
    }
    return this.centilitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.CentilitersPerMinute);
  }
  /** */
  get DecilitersPerMinute() {
    if (this.decilitersperminuteLazy !== null) {
      return this.decilitersperminuteLazy;
    }
    return this.decilitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.DecilitersPerMinute);
  }
  /** */
  get DecalitersPerMinute() {
    if (this.decalitersperminuteLazy !== null) {
      return this.decalitersperminuteLazy;
    }
    return this.decalitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.DecalitersPerMinute);
  }
  /** */
  get HectolitersPerMinute() {
    if (this.hectolitersperminuteLazy !== null) {
      return this.hectolitersperminuteLazy;
    }
    return this.hectolitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.HectolitersPerMinute);
  }
  /** */
  get KilolitersPerMinute() {
    if (this.kilolitersperminuteLazy !== null) {
      return this.kilolitersperminuteLazy;
    }
    return this.kilolitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.KilolitersPerMinute);
  }
  /** */
  get MegalitersPerMinute() {
    if (this.megalitersperminuteLazy !== null) {
      return this.megalitersperminuteLazy;
    }
    return this.megalitersperminuteLazy = this.convertFromBase(VolumeFlowUnits.MegalitersPerMinute);
  }
  /** */
  get NanolitersPerHour() {
    if (this.nanolitersperhourLazy !== null) {
      return this.nanolitersperhourLazy;
    }
    return this.nanolitersperhourLazy = this.convertFromBase(VolumeFlowUnits.NanolitersPerHour);
  }
  /** */
  get MicrolitersPerHour() {
    if (this.microlitersperhourLazy !== null) {
      return this.microlitersperhourLazy;
    }
    return this.microlitersperhourLazy = this.convertFromBase(VolumeFlowUnits.MicrolitersPerHour);
  }
  /** */
  get MillilitersPerHour() {
    if (this.millilitersperhourLazy !== null) {
      return this.millilitersperhourLazy;
    }
    return this.millilitersperhourLazy = this.convertFromBase(VolumeFlowUnits.MillilitersPerHour);
  }
  /** */
  get CentilitersPerHour() {
    if (this.centilitersperhourLazy !== null) {
      return this.centilitersperhourLazy;
    }
    return this.centilitersperhourLazy = this.convertFromBase(VolumeFlowUnits.CentilitersPerHour);
  }
  /** */
  get DecilitersPerHour() {
    if (this.decilitersperhourLazy !== null) {
      return this.decilitersperhourLazy;
    }
    return this.decilitersperhourLazy = this.convertFromBase(VolumeFlowUnits.DecilitersPerHour);
  }
  /** */
  get DecalitersPerHour() {
    if (this.decalitersperhourLazy !== null) {
      return this.decalitersperhourLazy;
    }
    return this.decalitersperhourLazy = this.convertFromBase(VolumeFlowUnits.DecalitersPerHour);
  }
  /** */
  get HectolitersPerHour() {
    if (this.hectolitersperhourLazy !== null) {
      return this.hectolitersperhourLazy;
    }
    return this.hectolitersperhourLazy = this.convertFromBase(VolumeFlowUnits.HectolitersPerHour);
  }
  /** */
  get KilolitersPerHour() {
    if (this.kilolitersperhourLazy !== null) {
      return this.kilolitersperhourLazy;
    }
    return this.kilolitersperhourLazy = this.convertFromBase(VolumeFlowUnits.KilolitersPerHour);
  }
  /** */
  get MegalitersPerHour() {
    if (this.megalitersperhourLazy !== null) {
      return this.megalitersperhourLazy;
    }
    return this.megalitersperhourLazy = this.convertFromBase(VolumeFlowUnits.MegalitersPerHour);
  }
  /** */
  get NanolitersPerDay() {
    if (this.nanolitersperdayLazy !== null) {
      return this.nanolitersperdayLazy;
    }
    return this.nanolitersperdayLazy = this.convertFromBase(VolumeFlowUnits.NanolitersPerDay);
  }
  /** */
  get MicrolitersPerDay() {
    if (this.microlitersperdayLazy !== null) {
      return this.microlitersperdayLazy;
    }
    return this.microlitersperdayLazy = this.convertFromBase(VolumeFlowUnits.MicrolitersPerDay);
  }
  /** */
  get MillilitersPerDay() {
    if (this.millilitersperdayLazy !== null) {
      return this.millilitersperdayLazy;
    }
    return this.millilitersperdayLazy = this.convertFromBase(VolumeFlowUnits.MillilitersPerDay);
  }
  /** */
  get CentilitersPerDay() {
    if (this.centilitersperdayLazy !== null) {
      return this.centilitersperdayLazy;
    }
    return this.centilitersperdayLazy = this.convertFromBase(VolumeFlowUnits.CentilitersPerDay);
  }
  /** */
  get DecilitersPerDay() {
    if (this.decilitersperdayLazy !== null) {
      return this.decilitersperdayLazy;
    }
    return this.decilitersperdayLazy = this.convertFromBase(VolumeFlowUnits.DecilitersPerDay);
  }
  /** */
  get DecalitersPerDay() {
    if (this.decalitersperdayLazy !== null) {
      return this.decalitersperdayLazy;
    }
    return this.decalitersperdayLazy = this.convertFromBase(VolumeFlowUnits.DecalitersPerDay);
  }
  /** */
  get HectolitersPerDay() {
    if (this.hectolitersperdayLazy !== null) {
      return this.hectolitersperdayLazy;
    }
    return this.hectolitersperdayLazy = this.convertFromBase(VolumeFlowUnits.HectolitersPerDay);
  }
  /** */
  get KilolitersPerDay() {
    if (this.kilolitersperdayLazy !== null) {
      return this.kilolitersperdayLazy;
    }
    return this.kilolitersperdayLazy = this.convertFromBase(VolumeFlowUnits.KilolitersPerDay);
  }
  /** */
  get MegalitersPerDay() {
    if (this.megalitersperdayLazy !== null) {
      return this.megalitersperdayLazy;
    }
    return this.megalitersperdayLazy = this.convertFromBase(VolumeFlowUnits.MegalitersPerDay);
  }
  /** */
  get MegaukGallonsPerDay() {
    if (this.megaukgallonsperdayLazy !== null) {
      return this.megaukgallonsperdayLazy;
    }
    return this.megaukgallonsperdayLazy = this.convertFromBase(VolumeFlowUnits.MegaukGallonsPerDay);
  }
  /** */
  get MegaukGallonsPerSecond() {
    if (this.megaukgallonspersecondLazy !== null) {
      return this.megaukgallonspersecondLazy;
    }
    return this.megaukgallonspersecondLazy = this.convertFromBase(VolumeFlowUnits.MegaukGallonsPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a CubicMetersPerSecond
   *
   * @param value The unit as CubicMetersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicMetersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicMetersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a CubicMetersPerMinute
   *
   * @param value The unit as CubicMetersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicMetersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicMetersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a CubicMetersPerHour
   *
   * @param value The unit as CubicMetersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicMetersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicMetersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a CubicMetersPerDay
   *
   * @param value The unit as CubicMetersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicMetersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicMetersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a CubicFeetPerSecond
   *
   * @param value The unit as CubicFeetPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicFeetPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicFeetPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a CubicFeetPerMinute
   *
   * @param value The unit as CubicFeetPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicFeetPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicFeetPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a CubicFeetPerHour
   *
   * @param value The unit as CubicFeetPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicFeetPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicFeetPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a CubicYardsPerSecond
   *
   * @param value The unit as CubicYardsPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicYardsPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicYardsPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a CubicYardsPerMinute
   *
   * @param value The unit as CubicYardsPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicYardsPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicYardsPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a CubicYardsPerHour
   *
   * @param value The unit as CubicYardsPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicYardsPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicYardsPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a CubicYardsPerDay
   *
   * @param value The unit as CubicYardsPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicYardsPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicYardsPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a MillionUsGallonsPerDay
   *
   * @param value The unit as MillionUsGallonsPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMillionUsGallonsPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MillionUsGallonsPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a UsGallonsPerDay
   *
   * @param value The unit as UsGallonsPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromUsGallonsPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.UsGallonsPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a LitersPerSecond
   *
   * @param value The unit as LitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromLitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.LitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a LitersPerMinute
   *
   * @param value The unit as LitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromLitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.LitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a LitersPerHour
   *
   * @param value The unit as LitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromLitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.LitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a LitersPerDay
   *
   * @param value The unit as LitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromLitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.LitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a UsGallonsPerSecond
   *
   * @param value The unit as UsGallonsPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromUsGallonsPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.UsGallonsPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a UsGallonsPerMinute
   *
   * @param value The unit as UsGallonsPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromUsGallonsPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.UsGallonsPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a UkGallonsPerDay
   *
   * @param value The unit as UkGallonsPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromUkGallonsPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.UkGallonsPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a UkGallonsPerHour
   *
   * @param value The unit as UkGallonsPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromUkGallonsPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.UkGallonsPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a UkGallonsPerMinute
   *
   * @param value The unit as UkGallonsPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromUkGallonsPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.UkGallonsPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a UkGallonsPerSecond
   *
   * @param value The unit as UkGallonsPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromUkGallonsPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.UkGallonsPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a KilousGallonsPerMinute
   *
   * @param value The unit as KilousGallonsPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromKilousGallonsPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.KilousGallonsPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a UsGallonsPerHour
   *
   * @param value The unit as UsGallonsPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromUsGallonsPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.UsGallonsPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a CubicDecimetersPerMinute
   *
   * @param value The unit as CubicDecimetersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicDecimetersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicDecimetersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a OilBarrelsPerDay
   *
   * @param value The unit as OilBarrelsPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromOilBarrelsPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.OilBarrelsPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a OilBarrelsPerMinute
   *
   * @param value The unit as OilBarrelsPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromOilBarrelsPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.OilBarrelsPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a OilBarrelsPerHour
   *
   * @param value The unit as OilBarrelsPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromOilBarrelsPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.OilBarrelsPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a OilBarrelsPerSecond
   *
   * @param value The unit as OilBarrelsPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromOilBarrelsPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.OilBarrelsPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a CubicMillimetersPerSecond
   *
   * @param value The unit as CubicMillimetersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicMillimetersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicMillimetersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a AcreFeetPerSecond
   *
   * @param value The unit as AcreFeetPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromAcreFeetPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.AcreFeetPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a AcreFeetPerMinute
   *
   * @param value The unit as AcreFeetPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromAcreFeetPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.AcreFeetPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a AcreFeetPerHour
   *
   * @param value The unit as AcreFeetPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromAcreFeetPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.AcreFeetPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a AcreFeetPerDay
   *
   * @param value The unit as AcreFeetPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromAcreFeetPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.AcreFeetPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a CubicCentimetersPerMinute
   *
   * @param value The unit as CubicCentimetersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCubicCentimetersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CubicCentimetersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a MegausGallonsPerDay
   *
   * @param value The unit as MegausGallonsPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMegausGallonsPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MegausGallonsPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a NanolitersPerSecond
   *
   * @param value The unit as NanolitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromNanolitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.NanolitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a MicrolitersPerSecond
   *
   * @param value The unit as MicrolitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMicrolitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MicrolitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a MillilitersPerSecond
   *
   * @param value The unit as MillilitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMillilitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MillilitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a CentilitersPerSecond
   *
   * @param value The unit as CentilitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCentilitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CentilitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a DecilitersPerSecond
   *
   * @param value The unit as DecilitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromDecilitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.DecilitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a DecalitersPerSecond
   *
   * @param value The unit as DecalitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromDecalitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.DecalitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a HectolitersPerSecond
   *
   * @param value The unit as HectolitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromHectolitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.HectolitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a KilolitersPerSecond
   *
   * @param value The unit as KilolitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromKilolitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.KilolitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a MegalitersPerSecond
   *
   * @param value The unit as MegalitersPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMegalitersPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MegalitersPerSecond);
  }
  /**
   * Create a new VolumeFlow instance from a NanolitersPerMinute
   *
   * @param value The unit as NanolitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromNanolitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.NanolitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a MicrolitersPerMinute
   *
   * @param value The unit as MicrolitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMicrolitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MicrolitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a MillilitersPerMinute
   *
   * @param value The unit as MillilitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMillilitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MillilitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a CentilitersPerMinute
   *
   * @param value The unit as CentilitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCentilitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CentilitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a DecilitersPerMinute
   *
   * @param value The unit as DecilitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromDecilitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.DecilitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a DecalitersPerMinute
   *
   * @param value The unit as DecalitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromDecalitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.DecalitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a HectolitersPerMinute
   *
   * @param value The unit as HectolitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromHectolitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.HectolitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a KilolitersPerMinute
   *
   * @param value The unit as KilolitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromKilolitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.KilolitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a MegalitersPerMinute
   *
   * @param value The unit as MegalitersPerMinute to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMegalitersPerMinute(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MegalitersPerMinute);
  }
  /**
   * Create a new VolumeFlow instance from a NanolitersPerHour
   *
   * @param value The unit as NanolitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromNanolitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.NanolitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a MicrolitersPerHour
   *
   * @param value The unit as MicrolitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMicrolitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MicrolitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a MillilitersPerHour
   *
   * @param value The unit as MillilitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMillilitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MillilitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a CentilitersPerHour
   *
   * @param value The unit as CentilitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCentilitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CentilitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a DecilitersPerHour
   *
   * @param value The unit as DecilitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromDecilitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.DecilitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a DecalitersPerHour
   *
   * @param value The unit as DecalitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromDecalitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.DecalitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a HectolitersPerHour
   *
   * @param value The unit as HectolitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromHectolitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.HectolitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a KilolitersPerHour
   *
   * @param value The unit as KilolitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromKilolitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.KilolitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a MegalitersPerHour
   *
   * @param value The unit as MegalitersPerHour to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMegalitersPerHour(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MegalitersPerHour);
  }
  /**
   * Create a new VolumeFlow instance from a NanolitersPerDay
   *
   * @param value The unit as NanolitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromNanolitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.NanolitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a MicrolitersPerDay
   *
   * @param value The unit as MicrolitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMicrolitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MicrolitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a MillilitersPerDay
   *
   * @param value The unit as MillilitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMillilitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MillilitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a CentilitersPerDay
   *
   * @param value The unit as CentilitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromCentilitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.CentilitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a DecilitersPerDay
   *
   * @param value The unit as DecilitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromDecilitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.DecilitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a DecalitersPerDay
   *
   * @param value The unit as DecalitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromDecalitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.DecalitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a HectolitersPerDay
   *
   * @param value The unit as HectolitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromHectolitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.HectolitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a KilolitersPerDay
   *
   * @param value The unit as KilolitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromKilolitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.KilolitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a MegalitersPerDay
   *
   * @param value The unit as MegalitersPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMegalitersPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MegalitersPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a MegaukGallonsPerDay
   *
   * @param value The unit as MegaukGallonsPerDay to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMegaukGallonsPerDay(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MegaukGallonsPerDay);
  }
  /**
   * Create a new VolumeFlow instance from a MegaukGallonsPerSecond
   *
   * @param value The unit as MegaukGallonsPerSecond to create a new VolumeFlow from.
   * @returns The new VolumeFlow instance.
   */
  static FromMegaukGallonsPerSecond(value) {
    return new VolumeFlow(value, VolumeFlowUnits.MegaukGallonsPerSecond);
  }
  /**
   * Gets the base unit enumeration associated with VolumeFlow
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return VolumeFlowUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return VolumeFlowUnits.CubicMetersPerSecond;
  }
  /**
   * Create API DTO represent a VolumeFlow unit.
   * @param holdInUnit The specific VolumeFlow unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = VolumeFlowUnits.CubicMetersPerSecond) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a VolumeFlow unit from an API DTO representation.
   * @param dtoVolumeFlow The VolumeFlow API DTO representation
   */
  static FromDto(dtoVolumeFlow) {
    return new VolumeFlow(dtoVolumeFlow.value, dtoVolumeFlow.unit);
  }
  /**
   * Convert VolumeFlow to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case VolumeFlowUnits.CubicMetersPerSecond:
        return this.CubicMetersPerSecond;
      case VolumeFlowUnits.CubicMetersPerMinute:
        return this.CubicMetersPerMinute;
      case VolumeFlowUnits.CubicMetersPerHour:
        return this.CubicMetersPerHour;
      case VolumeFlowUnits.CubicMetersPerDay:
        return this.CubicMetersPerDay;
      case VolumeFlowUnits.CubicFeetPerSecond:
        return this.CubicFeetPerSecond;
      case VolumeFlowUnits.CubicFeetPerMinute:
        return this.CubicFeetPerMinute;
      case VolumeFlowUnits.CubicFeetPerHour:
        return this.CubicFeetPerHour;
      case VolumeFlowUnits.CubicYardsPerSecond:
        return this.CubicYardsPerSecond;
      case VolumeFlowUnits.CubicYardsPerMinute:
        return this.CubicYardsPerMinute;
      case VolumeFlowUnits.CubicYardsPerHour:
        return this.CubicYardsPerHour;
      case VolumeFlowUnits.CubicYardsPerDay:
        return this.CubicYardsPerDay;
      case VolumeFlowUnits.MillionUsGallonsPerDay:
        return this.MillionUsGallonsPerDay;
      case VolumeFlowUnits.UsGallonsPerDay:
        return this.UsGallonsPerDay;
      case VolumeFlowUnits.LitersPerSecond:
        return this.LitersPerSecond;
      case VolumeFlowUnits.LitersPerMinute:
        return this.LitersPerMinute;
      case VolumeFlowUnits.LitersPerHour:
        return this.LitersPerHour;
      case VolumeFlowUnits.LitersPerDay:
        return this.LitersPerDay;
      case VolumeFlowUnits.UsGallonsPerSecond:
        return this.UsGallonsPerSecond;
      case VolumeFlowUnits.UsGallonsPerMinute:
        return this.UsGallonsPerMinute;
      case VolumeFlowUnits.UkGallonsPerDay:
        return this.UkGallonsPerDay;
      case VolumeFlowUnits.UkGallonsPerHour:
        return this.UkGallonsPerHour;
      case VolumeFlowUnits.UkGallonsPerMinute:
        return this.UkGallonsPerMinute;
      case VolumeFlowUnits.UkGallonsPerSecond:
        return this.UkGallonsPerSecond;
      case VolumeFlowUnits.KilousGallonsPerMinute:
        return this.KilousGallonsPerMinute;
      case VolumeFlowUnits.UsGallonsPerHour:
        return this.UsGallonsPerHour;
      case VolumeFlowUnits.CubicDecimetersPerMinute:
        return this.CubicDecimetersPerMinute;
      case VolumeFlowUnits.OilBarrelsPerDay:
        return this.OilBarrelsPerDay;
      case VolumeFlowUnits.OilBarrelsPerMinute:
        return this.OilBarrelsPerMinute;
      case VolumeFlowUnits.OilBarrelsPerHour:
        return this.OilBarrelsPerHour;
      case VolumeFlowUnits.OilBarrelsPerSecond:
        return this.OilBarrelsPerSecond;
      case VolumeFlowUnits.CubicMillimetersPerSecond:
        return this.CubicMillimetersPerSecond;
      case VolumeFlowUnits.AcreFeetPerSecond:
        return this.AcreFeetPerSecond;
      case VolumeFlowUnits.AcreFeetPerMinute:
        return this.AcreFeetPerMinute;
      case VolumeFlowUnits.AcreFeetPerHour:
        return this.AcreFeetPerHour;
      case VolumeFlowUnits.AcreFeetPerDay:
        return this.AcreFeetPerDay;
      case VolumeFlowUnits.CubicCentimetersPerMinute:
        return this.CubicCentimetersPerMinute;
      case VolumeFlowUnits.MegausGallonsPerDay:
        return this.MegausGallonsPerDay;
      case VolumeFlowUnits.NanolitersPerSecond:
        return this.NanolitersPerSecond;
      case VolumeFlowUnits.MicrolitersPerSecond:
        return this.MicrolitersPerSecond;
      case VolumeFlowUnits.MillilitersPerSecond:
        return this.MillilitersPerSecond;
      case VolumeFlowUnits.CentilitersPerSecond:
        return this.CentilitersPerSecond;
      case VolumeFlowUnits.DecilitersPerSecond:
        return this.DecilitersPerSecond;
      case VolumeFlowUnits.DecalitersPerSecond:
        return this.DecalitersPerSecond;
      case VolumeFlowUnits.HectolitersPerSecond:
        return this.HectolitersPerSecond;
      case VolumeFlowUnits.KilolitersPerSecond:
        return this.KilolitersPerSecond;
      case VolumeFlowUnits.MegalitersPerSecond:
        return this.MegalitersPerSecond;
      case VolumeFlowUnits.NanolitersPerMinute:
        return this.NanolitersPerMinute;
      case VolumeFlowUnits.MicrolitersPerMinute:
        return this.MicrolitersPerMinute;
      case VolumeFlowUnits.MillilitersPerMinute:
        return this.MillilitersPerMinute;
      case VolumeFlowUnits.CentilitersPerMinute:
        return this.CentilitersPerMinute;
      case VolumeFlowUnits.DecilitersPerMinute:
        return this.DecilitersPerMinute;
      case VolumeFlowUnits.DecalitersPerMinute:
        return this.DecalitersPerMinute;
      case VolumeFlowUnits.HectolitersPerMinute:
        return this.HectolitersPerMinute;
      case VolumeFlowUnits.KilolitersPerMinute:
        return this.KilolitersPerMinute;
      case VolumeFlowUnits.MegalitersPerMinute:
        return this.MegalitersPerMinute;
      case VolumeFlowUnits.NanolitersPerHour:
        return this.NanolitersPerHour;
      case VolumeFlowUnits.MicrolitersPerHour:
        return this.MicrolitersPerHour;
      case VolumeFlowUnits.MillilitersPerHour:
        return this.MillilitersPerHour;
      case VolumeFlowUnits.CentilitersPerHour:
        return this.CentilitersPerHour;
      case VolumeFlowUnits.DecilitersPerHour:
        return this.DecilitersPerHour;
      case VolumeFlowUnits.DecalitersPerHour:
        return this.DecalitersPerHour;
      case VolumeFlowUnits.HectolitersPerHour:
        return this.HectolitersPerHour;
      case VolumeFlowUnits.KilolitersPerHour:
        return this.KilolitersPerHour;
      case VolumeFlowUnits.MegalitersPerHour:
        return this.MegalitersPerHour;
      case VolumeFlowUnits.NanolitersPerDay:
        return this.NanolitersPerDay;
      case VolumeFlowUnits.MicrolitersPerDay:
        return this.MicrolitersPerDay;
      case VolumeFlowUnits.MillilitersPerDay:
        return this.MillilitersPerDay;
      case VolumeFlowUnits.CentilitersPerDay:
        return this.CentilitersPerDay;
      case VolumeFlowUnits.DecilitersPerDay:
        return this.DecilitersPerDay;
      case VolumeFlowUnits.DecalitersPerDay:
        return this.DecalitersPerDay;
      case VolumeFlowUnits.HectolitersPerDay:
        return this.HectolitersPerDay;
      case VolumeFlowUnits.KilolitersPerDay:
        return this.KilolitersPerDay;
      case VolumeFlowUnits.MegalitersPerDay:
        return this.MegalitersPerDay;
      case VolumeFlowUnits.MegaukGallonsPerDay:
        return this.MegaukGallonsPerDay;
      case VolumeFlowUnits.MegaukGallonsPerSecond:
        return this.MegaukGallonsPerSecond;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case VolumeFlowUnits.CubicMetersPerSecond:
        return this.value;
      case VolumeFlowUnits.CubicMetersPerMinute:
        return super.internalMultiply(this.value, 60);
      case VolumeFlowUnits.CubicMetersPerHour:
        return super.internalMultiply(this.value, 3600);
      case VolumeFlowUnits.CubicMetersPerDay:
        return super.internalMultiply(this.value, 86400);
      case VolumeFlowUnits.CubicFeetPerSecond:
        return super.internalMultiply(this.value, 35.314666721);
      case VolumeFlowUnits.CubicFeetPerMinute:
        return super.internalMultiply(this.value, 2118.88000326);
      case VolumeFlowUnits.CubicFeetPerHour:
        return super.internalDivide(this.value, 7.8657907199999087346816086183876e-6);
      case VolumeFlowUnits.CubicYardsPerSecond:
        return super.internalDivide(this.value, 0.764554857984);
      case VolumeFlowUnits.CubicYardsPerMinute:
        return super.internalDivide(this.value, 0.0127425809664);
      case VolumeFlowUnits.CubicYardsPerHour:
        return super.internalDivide(this.value, 2.1237634944E-4);
      case VolumeFlowUnits.CubicYardsPerDay:
        return super.internalMultiply(this.value, 113007);
      case VolumeFlowUnits.MillionUsGallonsPerDay:
        return super.internalMultiply(this.value, 22.824465227);
      case VolumeFlowUnits.UsGallonsPerDay:
        return super.internalMultiply(this.value, 22824465.227);
      case VolumeFlowUnits.LitersPerSecond:
        return super.internalMultiply(this.value, 1000);
      case VolumeFlowUnits.LitersPerMinute:
        return super.internalMultiply(this.value, 60000.00000);
      case VolumeFlowUnits.LitersPerHour:
        return super.internalMultiply(this.value, 3600000.000);
      case VolumeFlowUnits.LitersPerDay:
        return super.internalMultiply(this.value, 86400000);
      case VolumeFlowUnits.UsGallonsPerSecond:
        return super.internalMultiply(this.value, 264.1720523581484);
      case VolumeFlowUnits.UsGallonsPerMinute:
        return super.internalMultiply(this.value, 15850.323141489);
      case VolumeFlowUnits.UkGallonsPerDay:
        return super.internalMultiply(this.value, 19005304);
      case VolumeFlowUnits.UkGallonsPerHour:
        return super.internalMultiply(this.value, 791887.667);
      case VolumeFlowUnits.UkGallonsPerMinute:
        return super.internalMultiply(this.value, 13198.2);
      case VolumeFlowUnits.UkGallonsPerSecond:
        return super.internalMultiply(this.value, 219.969);
      case VolumeFlowUnits.KilousGallonsPerMinute:
        return super.internalMultiply(this.value, 15.850323141489);
      case VolumeFlowUnits.UsGallonsPerHour:
        return super.internalMultiply(this.value, 951019.38848933424);
      case VolumeFlowUnits.CubicDecimetersPerMinute:
        return super.internalMultiply(this.value, 60000.00000);
      case VolumeFlowUnits.OilBarrelsPerDay:
        return super.internalDivide(this.value, 1.8401307283333333333333333333333e-6);
      case VolumeFlowUnits.OilBarrelsPerMinute:
        return super.internalDivide(this.value, 2.64978825e-3);
      case VolumeFlowUnits.OilBarrelsPerHour:
        return super.internalDivide(this.value, 4.41631375e-5);
      case VolumeFlowUnits.OilBarrelsPerSecond:
        return super.internalMultiply(this.value, 6.28981);
      case VolumeFlowUnits.CubicMillimetersPerSecond:
        return super.internalDivide(this.value, 1e-9);
      case VolumeFlowUnits.AcreFeetPerSecond:
        return super.internalMultiply(this.value, 0.000810713194);
      case VolumeFlowUnits.AcreFeetPerMinute:
        return super.internalMultiply(this.value, 0.0486427916);
      case VolumeFlowUnits.AcreFeetPerHour:
        return super.internalMultiply(this.value, 2.91857);
      case VolumeFlowUnits.AcreFeetPerDay:
        return super.internalMultiply(this.value, 70.0457);
      case VolumeFlowUnits.CubicCentimetersPerMinute:
        return super.internalDivide(this.value, 1.6666666666667e-8);
      case VolumeFlowUnits.MegausGallonsPerDay:
        {
          const v3 = super.internalMultiply(this.value, 22824465.227);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeFlowUnits.NanolitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 1e-9);
        }
      case VolumeFlowUnits.MicrolitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 0.000001);
        }
      case VolumeFlowUnits.MillilitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 0.001);
        }
      case VolumeFlowUnits.CentilitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 0.01);
        }
      case VolumeFlowUnits.DecilitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 0.1);
        }
      case VolumeFlowUnits.DecalitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 10);
        }
      case VolumeFlowUnits.HectolitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 100);
        }
      case VolumeFlowUnits.KilolitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 1000);
        }
      case VolumeFlowUnits.MegalitersPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 1000);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeFlowUnits.NanolitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 1e-9);
        }
      case VolumeFlowUnits.MicrolitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 0.000001);
        }
      case VolumeFlowUnits.MillilitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 0.001);
        }
      case VolumeFlowUnits.CentilitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 0.01);
        }
      case VolumeFlowUnits.DecilitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 0.1);
        }
      case VolumeFlowUnits.DecalitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 10);
        }
      case VolumeFlowUnits.HectolitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 100);
        }
      case VolumeFlowUnits.KilolitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 1000);
        }
      case VolumeFlowUnits.MegalitersPerMinute:
        {
          const v3 = super.internalMultiply(this.value, 60000.00000);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeFlowUnits.NanolitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 1e-9);
        }
      case VolumeFlowUnits.MicrolitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 0.000001);
        }
      case VolumeFlowUnits.MillilitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 0.001);
        }
      case VolumeFlowUnits.CentilitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 0.01);
        }
      case VolumeFlowUnits.DecilitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 0.1);
        }
      case VolumeFlowUnits.DecalitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 10);
        }
      case VolumeFlowUnits.HectolitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 100);
        }
      case VolumeFlowUnits.KilolitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 1000);
        }
      case VolumeFlowUnits.MegalitersPerHour:
        {
          const v3 = super.internalMultiply(this.value, 3600000.000);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeFlowUnits.NanolitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 1e-9);
        }
      case VolumeFlowUnits.MicrolitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 0.000001);
        }
      case VolumeFlowUnits.MillilitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 0.001);
        }
      case VolumeFlowUnits.CentilitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 0.01);
        }
      case VolumeFlowUnits.DecilitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 0.1);
        }
      case VolumeFlowUnits.DecalitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 10);
        }
      case VolumeFlowUnits.HectolitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 100);
        }
      case VolumeFlowUnits.KilolitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 1000);
        }
      case VolumeFlowUnits.MegalitersPerDay:
        {
          const v3 = super.internalMultiply(this.value, 86400000);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeFlowUnits.MegaukGallonsPerDay:
        {
          const v3 = super.internalMultiply(this.value, 19005304);
          return super.internalDivide(v3, 1000000);
        }
      case VolumeFlowUnits.MegaukGallonsPerSecond:
        {
          const v3 = super.internalMultiply(this.value, 219.969);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case VolumeFlowUnits.CubicMetersPerSecond:
        return this.value;
      case VolumeFlowUnits.CubicMetersPerMinute:
        return this.value * 60;
      case VolumeFlowUnits.CubicMetersPerHour:
        return this.value * 3600;
      case VolumeFlowUnits.CubicMetersPerDay:
        return this.value * 86400;
      case VolumeFlowUnits.CubicFeetPerSecond:
        return this.value * 35.314666721;
      case VolumeFlowUnits.CubicFeetPerMinute:
        return this.value * 2118.88000326;
      case VolumeFlowUnits.CubicFeetPerHour:
        return this.value / 7.8657907199999087346816086183876e-6;
      case VolumeFlowUnits.CubicYardsPerSecond:
        return this.value / 0.764554857984;
      case VolumeFlowUnits.CubicYardsPerMinute:
        return this.value / 0.0127425809664;
      case VolumeFlowUnits.CubicYardsPerHour:
        return this.value / 2.1237634944E-4;
      case VolumeFlowUnits.CubicYardsPerDay:
        return this.value * 113007;
      case VolumeFlowUnits.MillionUsGallonsPerDay:
        return this.value * 22.824465227;
      case VolumeFlowUnits.UsGallonsPerDay:
        return this.value * 22824465.227;
      case VolumeFlowUnits.LitersPerSecond:
        return this.value * 1000;
      case VolumeFlowUnits.LitersPerMinute:
        return this.value * 60000.00000;
      case VolumeFlowUnits.LitersPerHour:
        return this.value * 3600000.000;
      case VolumeFlowUnits.LitersPerDay:
        return this.value * 86400000;
      case VolumeFlowUnits.UsGallonsPerSecond:
        return this.value * 264.1720523581484;
      case VolumeFlowUnits.UsGallonsPerMinute:
        return this.value * 15850.323141489;
      case VolumeFlowUnits.UkGallonsPerDay:
        return this.value * 19005304;
      case VolumeFlowUnits.UkGallonsPerHour:
        return this.value * 791887.667;
      case VolumeFlowUnits.UkGallonsPerMinute:
        return this.value * 13198.2;
      case VolumeFlowUnits.UkGallonsPerSecond:
        return this.value * 219.969;
      case VolumeFlowUnits.KilousGallonsPerMinute:
        return this.value * 15.850323141489;
      case VolumeFlowUnits.UsGallonsPerHour:
        return this.value * 951019.38848933424;
      case VolumeFlowUnits.CubicDecimetersPerMinute:
        return this.value * 60000.00000;
      case VolumeFlowUnits.OilBarrelsPerDay:
        return this.value / 1.8401307283333333333333333333333e-6;
      case VolumeFlowUnits.OilBarrelsPerMinute:
        return this.value / 2.64978825e-3;
      case VolumeFlowUnits.OilBarrelsPerHour:
        return this.value / 4.41631375e-5;
      case VolumeFlowUnits.OilBarrelsPerSecond:
        return this.value * 6.28981;
      case VolumeFlowUnits.CubicMillimetersPerSecond:
        return this.value / 1e-9;
      case VolumeFlowUnits.AcreFeetPerSecond:
        return this.value * 0.000810713194;
      case VolumeFlowUnits.AcreFeetPerMinute:
        return this.value * 0.0486427916;
      case VolumeFlowUnits.AcreFeetPerHour:
        return this.value * 2.91857;
      case VolumeFlowUnits.AcreFeetPerDay:
        return this.value * 70.0457;
      case VolumeFlowUnits.CubicCentimetersPerMinute:
        return this.value / 1.6666666666667e-8;
      case VolumeFlowUnits.MegausGallonsPerDay:
        return this.value * 22824465.227 / 1000000;
      case VolumeFlowUnits.NanolitersPerSecond:
        return this.value * 1000 / 1e-9;
      case VolumeFlowUnits.MicrolitersPerSecond:
        return this.value * 1000 / 0.000001;
      case VolumeFlowUnits.MillilitersPerSecond:
        return this.value * 1000 / 0.001;
      case VolumeFlowUnits.CentilitersPerSecond:
        return this.value * 1000 / 0.01;
      case VolumeFlowUnits.DecilitersPerSecond:
        return this.value * 1000 / 0.1;
      case VolumeFlowUnits.DecalitersPerSecond:
        return this.value * 1000 / 10;
      case VolumeFlowUnits.HectolitersPerSecond:
        return this.value * 1000 / 100;
      case VolumeFlowUnits.KilolitersPerSecond:
        return this.value * 1000 / 1000;
      case VolumeFlowUnits.MegalitersPerSecond:
        return this.value * 1000 / 1000000;
      case VolumeFlowUnits.NanolitersPerMinute:
        return this.value * 60000.00000 / 1e-9;
      case VolumeFlowUnits.MicrolitersPerMinute:
        return this.value * 60000.00000 / 0.000001;
      case VolumeFlowUnits.MillilitersPerMinute:
        return this.value * 60000.00000 / 0.001;
      case VolumeFlowUnits.CentilitersPerMinute:
        return this.value * 60000.00000 / 0.01;
      case VolumeFlowUnits.DecilitersPerMinute:
        return this.value * 60000.00000 / 0.1;
      case VolumeFlowUnits.DecalitersPerMinute:
        return this.value * 60000.00000 / 10;
      case VolumeFlowUnits.HectolitersPerMinute:
        return this.value * 60000.00000 / 100;
      case VolumeFlowUnits.KilolitersPerMinute:
        return this.value * 60000.00000 / 1000;
      case VolumeFlowUnits.MegalitersPerMinute:
        return this.value * 60000.00000 / 1000000;
      case VolumeFlowUnits.NanolitersPerHour:
        return this.value * 3600000.000 / 1e-9;
      case VolumeFlowUnits.MicrolitersPerHour:
        return this.value * 3600000.000 / 0.000001;
      case VolumeFlowUnits.MillilitersPerHour:
        return this.value * 3600000.000 / 0.001;
      case VolumeFlowUnits.CentilitersPerHour:
        return this.value * 3600000.000 / 0.01;
      case VolumeFlowUnits.DecilitersPerHour:
        return this.value * 3600000.000 / 0.1;
      case VolumeFlowUnits.DecalitersPerHour:
        return this.value * 3600000.000 / 10;
      case VolumeFlowUnits.HectolitersPerHour:
        return this.value * 3600000.000 / 100;
      case VolumeFlowUnits.KilolitersPerHour:
        return this.value * 3600000.000 / 1000;
      case VolumeFlowUnits.MegalitersPerHour:
        return this.value * 3600000.000 / 1000000;
      case VolumeFlowUnits.NanolitersPerDay:
        return this.value * 86400000 / 1e-9;
      case VolumeFlowUnits.MicrolitersPerDay:
        return this.value * 86400000 / 0.000001;
      case VolumeFlowUnits.MillilitersPerDay:
        return this.value * 86400000 / 0.001;
      case VolumeFlowUnits.CentilitersPerDay:
        return this.value * 86400000 / 0.01;
      case VolumeFlowUnits.DecilitersPerDay:
        return this.value * 86400000 / 0.1;
      case VolumeFlowUnits.DecalitersPerDay:
        return this.value * 86400000 / 10;
      case VolumeFlowUnits.HectolitersPerDay:
        return this.value * 86400000 / 100;
      case VolumeFlowUnits.KilolitersPerDay:
        return this.value * 86400000 / 1000;
      case VolumeFlowUnits.MegalitersPerDay:
        return this.value * 86400000 / 1000000;
      case VolumeFlowUnits.MegaukGallonsPerDay:
        return this.value * 19005304 / 1000000;
      case VolumeFlowUnits.MegaukGallonsPerSecond:
        return this.value * 219.969 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case VolumeFlowUnits.CubicMetersPerSecond:
        return value;
      case VolumeFlowUnits.CubicMetersPerMinute:
        return super.internalDivide(value, 60);
      case VolumeFlowUnits.CubicMetersPerHour:
        return super.internalDivide(value, 3600);
      case VolumeFlowUnits.CubicMetersPerDay:
        return super.internalDivide(value, 86400);
      case VolumeFlowUnits.CubicFeetPerSecond:
        return super.internalDivide(value, 35.314666721);
      case VolumeFlowUnits.CubicFeetPerMinute:
        return super.internalDivide(value, 2118.88000326);
      case VolumeFlowUnits.CubicFeetPerHour:
        return super.internalMultiply(value, 7.8657907199999087346816086183876e-6);
      case VolumeFlowUnits.CubicYardsPerSecond:
        return super.internalMultiply(value, 0.764554857984);
      case VolumeFlowUnits.CubicYardsPerMinute:
        return super.internalMultiply(value, 0.0127425809664);
      case VolumeFlowUnits.CubicYardsPerHour:
        return super.internalMultiply(value, 2.1237634944E-4);
      case VolumeFlowUnits.CubicYardsPerDay:
        return super.internalDivide(value, 113007);
      case VolumeFlowUnits.MillionUsGallonsPerDay:
        return super.internalDivide(value, 22.824465227);
      case VolumeFlowUnits.UsGallonsPerDay:
        return super.internalDivide(value, 22824465.227);
      case VolumeFlowUnits.LitersPerSecond:
        return super.internalDivide(value, 1000);
      case VolumeFlowUnits.LitersPerMinute:
        return super.internalDivide(value, 60000.00000);
      case VolumeFlowUnits.LitersPerHour:
        return super.internalDivide(value, 3600000.000);
      case VolumeFlowUnits.LitersPerDay:
        return super.internalDivide(value, 86400000);
      case VolumeFlowUnits.UsGallonsPerSecond:
        return super.internalDivide(value, 264.1720523581484);
      case VolumeFlowUnits.UsGallonsPerMinute:
        return super.internalDivide(value, 15850.323141489);
      case VolumeFlowUnits.UkGallonsPerDay:
        return super.internalDivide(value, 19005304);
      case VolumeFlowUnits.UkGallonsPerHour:
        return super.internalDivide(value, 791887.667);
      case VolumeFlowUnits.UkGallonsPerMinute:
        return super.internalDivide(value, 13198.2);
      case VolumeFlowUnits.UkGallonsPerSecond:
        return super.internalDivide(value, 219.969);
      case VolumeFlowUnits.KilousGallonsPerMinute:
        return super.internalDivide(value, 15.850323141489);
      case VolumeFlowUnits.UsGallonsPerHour:
        return super.internalDivide(value, 951019.38848933424);
      case VolumeFlowUnits.CubicDecimetersPerMinute:
        return super.internalDivide(value, 60000.00000);
      case VolumeFlowUnits.OilBarrelsPerDay:
        return super.internalMultiply(value, 1.8401307283333333333333333333333e-6);
      case VolumeFlowUnits.OilBarrelsPerMinute:
        return super.internalMultiply(value, 2.64978825e-3);
      case VolumeFlowUnits.OilBarrelsPerHour:
        return super.internalMultiply(value, 4.41631375e-5);
      case VolumeFlowUnits.OilBarrelsPerSecond:
        return super.internalDivide(value, 6.28981);
      case VolumeFlowUnits.CubicMillimetersPerSecond:
        return super.internalMultiply(value, 1e-9);
      case VolumeFlowUnits.AcreFeetPerSecond:
        return super.internalDivide(value, 0.000810713194);
      case VolumeFlowUnits.AcreFeetPerMinute:
        return super.internalDivide(value, 0.0486427916);
      case VolumeFlowUnits.AcreFeetPerHour:
        return super.internalDivide(value, 2.91857);
      case VolumeFlowUnits.AcreFeetPerDay:
        return super.internalDivide(value, 70.0457);
      case VolumeFlowUnits.CubicCentimetersPerMinute:
        return super.internalMultiply(value, 1.6666666666667e-8);
      case VolumeFlowUnits.MegausGallonsPerDay:
        {
          const v3 = super.internalDivide(value, 22824465.227);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeFlowUnits.NanolitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 1e-9);
        }
      case VolumeFlowUnits.MicrolitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 0.000001);
        }
      case VolumeFlowUnits.MillilitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 0.001);
        }
      case VolumeFlowUnits.CentilitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 0.01);
        }
      case VolumeFlowUnits.DecilitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 0.1);
        }
      case VolumeFlowUnits.DecalitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 10);
        }
      case VolumeFlowUnits.HectolitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 100);
        }
      case VolumeFlowUnits.KilolitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 1000);
        }
      case VolumeFlowUnits.MegalitersPerSecond:
        {
          const v3 = super.internalDivide(value, 1000);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeFlowUnits.NanolitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 1e-9);
        }
      case VolumeFlowUnits.MicrolitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 0.000001);
        }
      case VolumeFlowUnits.MillilitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 0.001);
        }
      case VolumeFlowUnits.CentilitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 0.01);
        }
      case VolumeFlowUnits.DecilitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 0.1);
        }
      case VolumeFlowUnits.DecalitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 10);
        }
      case VolumeFlowUnits.HectolitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 100);
        }
      case VolumeFlowUnits.KilolitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 1000);
        }
      case VolumeFlowUnits.MegalitersPerMinute:
        {
          const v3 = super.internalDivide(value, 60000.00000);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeFlowUnits.NanolitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 1e-9);
        }
      case VolumeFlowUnits.MicrolitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 0.000001);
        }
      case VolumeFlowUnits.MillilitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 0.001);
        }
      case VolumeFlowUnits.CentilitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 0.01);
        }
      case VolumeFlowUnits.DecilitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 0.1);
        }
      case VolumeFlowUnits.DecalitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 10);
        }
      case VolumeFlowUnits.HectolitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 100);
        }
      case VolumeFlowUnits.KilolitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 1000);
        }
      case VolumeFlowUnits.MegalitersPerHour:
        {
          const v3 = super.internalDivide(value, 3600000.000);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeFlowUnits.NanolitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 1e-9);
        }
      case VolumeFlowUnits.MicrolitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 0.000001);
        }
      case VolumeFlowUnits.MillilitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 0.001);
        }
      case VolumeFlowUnits.CentilitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 0.01);
        }
      case VolumeFlowUnits.DecilitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 0.1);
        }
      case VolumeFlowUnits.DecalitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 10);
        }
      case VolumeFlowUnits.HectolitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 100);
        }
      case VolumeFlowUnits.KilolitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 1000);
        }
      case VolumeFlowUnits.MegalitersPerDay:
        {
          const v3 = super.internalDivide(value, 86400000);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeFlowUnits.MegaukGallonsPerDay:
        {
          const v3 = super.internalDivide(value, 19005304);
          return super.internalMultiply(v3, 1000000);
        }
      case VolumeFlowUnits.MegaukGallonsPerSecond:
        {
          const v3 = super.internalDivide(value, 219.969);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case VolumeFlowUnits.CubicMetersPerSecond:
        return value;
      case VolumeFlowUnits.CubicMetersPerMinute:
        return value / 60;
      case VolumeFlowUnits.CubicMetersPerHour:
        return value / 3600;
      case VolumeFlowUnits.CubicMetersPerDay:
        return value / 86400;
      case VolumeFlowUnits.CubicFeetPerSecond:
        return value / 35.314666721;
      case VolumeFlowUnits.CubicFeetPerMinute:
        return value / 2118.88000326;
      case VolumeFlowUnits.CubicFeetPerHour:
        return value * 7.8657907199999087346816086183876e-6;
      case VolumeFlowUnits.CubicYardsPerSecond:
        return value * 0.764554857984;
      case VolumeFlowUnits.CubicYardsPerMinute:
        return value * 0.0127425809664;
      case VolumeFlowUnits.CubicYardsPerHour:
        return value * 2.1237634944E-4;
      case VolumeFlowUnits.CubicYardsPerDay:
        return value / 113007;
      case VolumeFlowUnits.MillionUsGallonsPerDay:
        return value / 22.824465227;
      case VolumeFlowUnits.UsGallonsPerDay:
        return value / 22824465.227;
      case VolumeFlowUnits.LitersPerSecond:
        return value / 1000;
      case VolumeFlowUnits.LitersPerMinute:
        return value / 60000.00000;
      case VolumeFlowUnits.LitersPerHour:
        return value / 3600000.000;
      case VolumeFlowUnits.LitersPerDay:
        return value / 86400000;
      case VolumeFlowUnits.UsGallonsPerSecond:
        return value / 264.1720523581484;
      case VolumeFlowUnits.UsGallonsPerMinute:
        return value / 15850.323141489;
      case VolumeFlowUnits.UkGallonsPerDay:
        return value / 19005304;
      case VolumeFlowUnits.UkGallonsPerHour:
        return value / 791887.667;
      case VolumeFlowUnits.UkGallonsPerMinute:
        return value / 13198.2;
      case VolumeFlowUnits.UkGallonsPerSecond:
        return value / 219.969;
      case VolumeFlowUnits.KilousGallonsPerMinute:
        return value / 15.850323141489;
      case VolumeFlowUnits.UsGallonsPerHour:
        return value / 951019.38848933424;
      case VolumeFlowUnits.CubicDecimetersPerMinute:
        return value / 60000.00000;
      case VolumeFlowUnits.OilBarrelsPerDay:
        return value * 1.8401307283333333333333333333333e-6;
      case VolumeFlowUnits.OilBarrelsPerMinute:
        return value * 2.64978825e-3;
      case VolumeFlowUnits.OilBarrelsPerHour:
        return value * 4.41631375e-5;
      case VolumeFlowUnits.OilBarrelsPerSecond:
        return value / 6.28981;
      case VolumeFlowUnits.CubicMillimetersPerSecond:
        return value * 1e-9;
      case VolumeFlowUnits.AcreFeetPerSecond:
        return value / 0.000810713194;
      case VolumeFlowUnits.AcreFeetPerMinute:
        return value / 0.0486427916;
      case VolumeFlowUnits.AcreFeetPerHour:
        return value / 2.91857;
      case VolumeFlowUnits.AcreFeetPerDay:
        return value / 70.0457;
      case VolumeFlowUnits.CubicCentimetersPerMinute:
        return value * 1.6666666666667e-8;
      case VolumeFlowUnits.MegausGallonsPerDay:
        return value / 22824465.227 * 1000000;
      case VolumeFlowUnits.NanolitersPerSecond:
        return value / 1000 * 1e-9;
      case VolumeFlowUnits.MicrolitersPerSecond:
        return value / 1000 * 0.000001;
      case VolumeFlowUnits.MillilitersPerSecond:
        return value / 1000 * 0.001;
      case VolumeFlowUnits.CentilitersPerSecond:
        return value / 1000 * 0.01;
      case VolumeFlowUnits.DecilitersPerSecond:
        return value / 1000 * 0.1;
      case VolumeFlowUnits.DecalitersPerSecond:
        return value / 1000 * 10;
      case VolumeFlowUnits.HectolitersPerSecond:
        return value / 1000 * 100;
      case VolumeFlowUnits.KilolitersPerSecond:
        return value / 1000 * 1000;
      case VolumeFlowUnits.MegalitersPerSecond:
        return value / 1000 * 1000000;
      case VolumeFlowUnits.NanolitersPerMinute:
        return value / 60000.00000 * 1e-9;
      case VolumeFlowUnits.MicrolitersPerMinute:
        return value / 60000.00000 * 0.000001;
      case VolumeFlowUnits.MillilitersPerMinute:
        return value / 60000.00000 * 0.001;
      case VolumeFlowUnits.CentilitersPerMinute:
        return value / 60000.00000 * 0.01;
      case VolumeFlowUnits.DecilitersPerMinute:
        return value / 60000.00000 * 0.1;
      case VolumeFlowUnits.DecalitersPerMinute:
        return value / 60000.00000 * 10;
      case VolumeFlowUnits.HectolitersPerMinute:
        return value / 60000.00000 * 100;
      case VolumeFlowUnits.KilolitersPerMinute:
        return value / 60000.00000 * 1000;
      case VolumeFlowUnits.MegalitersPerMinute:
        return value / 60000.00000 * 1000000;
      case VolumeFlowUnits.NanolitersPerHour:
        return value / 3600000.000 * 1e-9;
      case VolumeFlowUnits.MicrolitersPerHour:
        return value / 3600000.000 * 0.000001;
      case VolumeFlowUnits.MillilitersPerHour:
        return value / 3600000.000 * 0.001;
      case VolumeFlowUnits.CentilitersPerHour:
        return value / 3600000.000 * 0.01;
      case VolumeFlowUnits.DecilitersPerHour:
        return value / 3600000.000 * 0.1;
      case VolumeFlowUnits.DecalitersPerHour:
        return value / 3600000.000 * 10;
      case VolumeFlowUnits.HectolitersPerHour:
        return value / 3600000.000 * 100;
      case VolumeFlowUnits.KilolitersPerHour:
        return value / 3600000.000 * 1000;
      case VolumeFlowUnits.MegalitersPerHour:
        return value / 3600000.000 * 1000000;
      case VolumeFlowUnits.NanolitersPerDay:
        return value / 86400000 * 1e-9;
      case VolumeFlowUnits.MicrolitersPerDay:
        return value / 86400000 * 0.000001;
      case VolumeFlowUnits.MillilitersPerDay:
        return value / 86400000 * 0.001;
      case VolumeFlowUnits.CentilitersPerDay:
        return value / 86400000 * 0.01;
      case VolumeFlowUnits.DecilitersPerDay:
        return value / 86400000 * 0.1;
      case VolumeFlowUnits.DecalitersPerDay:
        return value / 86400000 * 10;
      case VolumeFlowUnits.HectolitersPerDay:
        return value / 86400000 * 100;
      case VolumeFlowUnits.KilolitersPerDay:
        return value / 86400000 * 1000;
      case VolumeFlowUnits.MegalitersPerDay:
        return value / 86400000 * 1000000;
      case VolumeFlowUnits.MegaukGallonsPerDay:
        return value / 19005304 * 1000000;
      case VolumeFlowUnits.MegaukGallonsPerSecond:
        return value / 219.969 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the VolumeFlow to string.
   * Note! the default format for VolumeFlow is CubicMetersPerSecond.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the VolumeFlow.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the VolumeFlow.
   */
  toString(unit = VolumeFlowUnits.CubicMetersPerSecond, fractionalDigits) {
    switch (unit) {
      case VolumeFlowUnits.CubicMetersPerSecond:
        return super.truncateFractionDigits(this.CubicMetersPerSecond, fractionalDigits) + ` m³/s`;
      case VolumeFlowUnits.CubicMetersPerMinute:
        return super.truncateFractionDigits(this.CubicMetersPerMinute, fractionalDigits) + ` m³/min`;
      case VolumeFlowUnits.CubicMetersPerHour:
        return super.truncateFractionDigits(this.CubicMetersPerHour, fractionalDigits) + ` m³/h`;
      case VolumeFlowUnits.CubicMetersPerDay:
        return super.truncateFractionDigits(this.CubicMetersPerDay, fractionalDigits) + ` m³/d`;
      case VolumeFlowUnits.CubicFeetPerSecond:
        return super.truncateFractionDigits(this.CubicFeetPerSecond, fractionalDigits) + ` ft³/s`;
      case VolumeFlowUnits.CubicFeetPerMinute:
        return super.truncateFractionDigits(this.CubicFeetPerMinute, fractionalDigits) + ` ft³/min`;
      case VolumeFlowUnits.CubicFeetPerHour:
        return super.truncateFractionDigits(this.CubicFeetPerHour, fractionalDigits) + ` ft³/h`;
      case VolumeFlowUnits.CubicYardsPerSecond:
        return super.truncateFractionDigits(this.CubicYardsPerSecond, fractionalDigits) + ` yd³/s`;
      case VolumeFlowUnits.CubicYardsPerMinute:
        return super.truncateFractionDigits(this.CubicYardsPerMinute, fractionalDigits) + ` yd³/min`;
      case VolumeFlowUnits.CubicYardsPerHour:
        return super.truncateFractionDigits(this.CubicYardsPerHour, fractionalDigits) + ` yd³/h`;
      case VolumeFlowUnits.CubicYardsPerDay:
        return super.truncateFractionDigits(this.CubicYardsPerDay, fractionalDigits) + ` cy/day`;
      case VolumeFlowUnits.MillionUsGallonsPerDay:
        return super.truncateFractionDigits(this.MillionUsGallonsPerDay, fractionalDigits) + ` MGD`;
      case VolumeFlowUnits.UsGallonsPerDay:
        return super.truncateFractionDigits(this.UsGallonsPerDay, fractionalDigits) + ` gpd`;
      case VolumeFlowUnits.LitersPerSecond:
        return super.truncateFractionDigits(this.LitersPerSecond, fractionalDigits) + ` L/s`;
      case VolumeFlowUnits.LitersPerMinute:
        return super.truncateFractionDigits(this.LitersPerMinute, fractionalDigits) + ` L/min`;
      case VolumeFlowUnits.LitersPerHour:
        return super.truncateFractionDigits(this.LitersPerHour, fractionalDigits) + ` L/h`;
      case VolumeFlowUnits.LitersPerDay:
        return super.truncateFractionDigits(this.LitersPerDay, fractionalDigits) + ` l/day`;
      case VolumeFlowUnits.UsGallonsPerSecond:
        return super.truncateFractionDigits(this.UsGallonsPerSecond, fractionalDigits) + ` gal (U.S.)/s`;
      case VolumeFlowUnits.UsGallonsPerMinute:
        return super.truncateFractionDigits(this.UsGallonsPerMinute, fractionalDigits) + ` gal (U.S.)/min`;
      case VolumeFlowUnits.UkGallonsPerDay:
        return super.truncateFractionDigits(this.UkGallonsPerDay, fractionalDigits) + ` gal (U. K.)/d`;
      case VolumeFlowUnits.UkGallonsPerHour:
        return super.truncateFractionDigits(this.UkGallonsPerHour, fractionalDigits) + ` gal (imp.)/h`;
      case VolumeFlowUnits.UkGallonsPerMinute:
        return super.truncateFractionDigits(this.UkGallonsPerMinute, fractionalDigits) + ` gal (imp.)/min`;
      case VolumeFlowUnits.UkGallonsPerSecond:
        return super.truncateFractionDigits(this.UkGallonsPerSecond, fractionalDigits) + ` gal (imp.)/s`;
      case VolumeFlowUnits.KilousGallonsPerMinute:
        return super.truncateFractionDigits(this.KilousGallonsPerMinute, fractionalDigits) + ` kgal (U.S.)/min`;
      case VolumeFlowUnits.UsGallonsPerHour:
        return super.truncateFractionDigits(this.UsGallonsPerHour, fractionalDigits) + ` gal (U.S.)/h`;
      case VolumeFlowUnits.CubicDecimetersPerMinute:
        return super.truncateFractionDigits(this.CubicDecimetersPerMinute, fractionalDigits) + ` dm³/min`;
      case VolumeFlowUnits.OilBarrelsPerDay:
        return super.truncateFractionDigits(this.OilBarrelsPerDay, fractionalDigits) + ` bbl/d`;
      case VolumeFlowUnits.OilBarrelsPerMinute:
        return super.truncateFractionDigits(this.OilBarrelsPerMinute, fractionalDigits) + ` bbl/min`;
      case VolumeFlowUnits.OilBarrelsPerHour:
        return super.truncateFractionDigits(this.OilBarrelsPerHour, fractionalDigits) + ` bbl/hr`;
      case VolumeFlowUnits.OilBarrelsPerSecond:
        return super.truncateFractionDigits(this.OilBarrelsPerSecond, fractionalDigits) + ` bbl/s`;
      case VolumeFlowUnits.CubicMillimetersPerSecond:
        return super.truncateFractionDigits(this.CubicMillimetersPerSecond, fractionalDigits) + ` mm³/s`;
      case VolumeFlowUnits.AcreFeetPerSecond:
        return super.truncateFractionDigits(this.AcreFeetPerSecond, fractionalDigits) + ` af/s`;
      case VolumeFlowUnits.AcreFeetPerMinute:
        return super.truncateFractionDigits(this.AcreFeetPerMinute, fractionalDigits) + ` af/m`;
      case VolumeFlowUnits.AcreFeetPerHour:
        return super.truncateFractionDigits(this.AcreFeetPerHour, fractionalDigits) + ` af/h`;
      case VolumeFlowUnits.AcreFeetPerDay:
        return super.truncateFractionDigits(this.AcreFeetPerDay, fractionalDigits) + ` af/d`;
      case VolumeFlowUnits.CubicCentimetersPerMinute:
        return super.truncateFractionDigits(this.CubicCentimetersPerMinute, fractionalDigits) + ` cm³/min`;
      case VolumeFlowUnits.MegausGallonsPerDay:
        return super.truncateFractionDigits(this.MegausGallonsPerDay, fractionalDigits) + ` Mgpd`;
      case VolumeFlowUnits.NanolitersPerSecond:
        return super.truncateFractionDigits(this.NanolitersPerSecond, fractionalDigits) + ` nL/s`;
      case VolumeFlowUnits.MicrolitersPerSecond:
        return super.truncateFractionDigits(this.MicrolitersPerSecond, fractionalDigits) + ` μL/s`;
      case VolumeFlowUnits.MillilitersPerSecond:
        return super.truncateFractionDigits(this.MillilitersPerSecond, fractionalDigits) + ` mL/s`;
      case VolumeFlowUnits.CentilitersPerSecond:
        return super.truncateFractionDigits(this.CentilitersPerSecond, fractionalDigits) + ` cL/s`;
      case VolumeFlowUnits.DecilitersPerSecond:
        return super.truncateFractionDigits(this.DecilitersPerSecond, fractionalDigits) + ` dL/s`;
      case VolumeFlowUnits.DecalitersPerSecond:
        return super.truncateFractionDigits(this.DecalitersPerSecond, fractionalDigits) + ` daL/s`;
      case VolumeFlowUnits.HectolitersPerSecond:
        return super.truncateFractionDigits(this.HectolitersPerSecond, fractionalDigits) + ` hL/s`;
      case VolumeFlowUnits.KilolitersPerSecond:
        return super.truncateFractionDigits(this.KilolitersPerSecond, fractionalDigits) + ` kL/s`;
      case VolumeFlowUnits.MegalitersPerSecond:
        return super.truncateFractionDigits(this.MegalitersPerSecond, fractionalDigits) + ` ML/s`;
      case VolumeFlowUnits.NanolitersPerMinute:
        return super.truncateFractionDigits(this.NanolitersPerMinute, fractionalDigits) + ` nL/min`;
      case VolumeFlowUnits.MicrolitersPerMinute:
        return super.truncateFractionDigits(this.MicrolitersPerMinute, fractionalDigits) + ` μL/min`;
      case VolumeFlowUnits.MillilitersPerMinute:
        return super.truncateFractionDigits(this.MillilitersPerMinute, fractionalDigits) + ` mL/min`;
      case VolumeFlowUnits.CentilitersPerMinute:
        return super.truncateFractionDigits(this.CentilitersPerMinute, fractionalDigits) + ` cL/min`;
      case VolumeFlowUnits.DecilitersPerMinute:
        return super.truncateFractionDigits(this.DecilitersPerMinute, fractionalDigits) + ` dL/min`;
      case VolumeFlowUnits.DecalitersPerMinute:
        return super.truncateFractionDigits(this.DecalitersPerMinute, fractionalDigits) + ` daL/min`;
      case VolumeFlowUnits.HectolitersPerMinute:
        return super.truncateFractionDigits(this.HectolitersPerMinute, fractionalDigits) + ` hL/min`;
      case VolumeFlowUnits.KilolitersPerMinute:
        return super.truncateFractionDigits(this.KilolitersPerMinute, fractionalDigits) + ` kL/min`;
      case VolumeFlowUnits.MegalitersPerMinute:
        return super.truncateFractionDigits(this.MegalitersPerMinute, fractionalDigits) + ` ML/min`;
      case VolumeFlowUnits.NanolitersPerHour:
        return super.truncateFractionDigits(this.NanolitersPerHour, fractionalDigits) + ` nL/h`;
      case VolumeFlowUnits.MicrolitersPerHour:
        return super.truncateFractionDigits(this.MicrolitersPerHour, fractionalDigits) + ` μL/h`;
      case VolumeFlowUnits.MillilitersPerHour:
        return super.truncateFractionDigits(this.MillilitersPerHour, fractionalDigits) + ` mL/h`;
      case VolumeFlowUnits.CentilitersPerHour:
        return super.truncateFractionDigits(this.CentilitersPerHour, fractionalDigits) + ` cL/h`;
      case VolumeFlowUnits.DecilitersPerHour:
        return super.truncateFractionDigits(this.DecilitersPerHour, fractionalDigits) + ` dL/h`;
      case VolumeFlowUnits.DecalitersPerHour:
        return super.truncateFractionDigits(this.DecalitersPerHour, fractionalDigits) + ` daL/h`;
      case VolumeFlowUnits.HectolitersPerHour:
        return super.truncateFractionDigits(this.HectolitersPerHour, fractionalDigits) + ` hL/h`;
      case VolumeFlowUnits.KilolitersPerHour:
        return super.truncateFractionDigits(this.KilolitersPerHour, fractionalDigits) + ` kL/h`;
      case VolumeFlowUnits.MegalitersPerHour:
        return super.truncateFractionDigits(this.MegalitersPerHour, fractionalDigits) + ` ML/h`;
      case VolumeFlowUnits.NanolitersPerDay:
        return super.truncateFractionDigits(this.NanolitersPerDay, fractionalDigits) + ` nl/day`;
      case VolumeFlowUnits.MicrolitersPerDay:
        return super.truncateFractionDigits(this.MicrolitersPerDay, fractionalDigits) + ` μl/day`;
      case VolumeFlowUnits.MillilitersPerDay:
        return super.truncateFractionDigits(this.MillilitersPerDay, fractionalDigits) + ` ml/day`;
      case VolumeFlowUnits.CentilitersPerDay:
        return super.truncateFractionDigits(this.CentilitersPerDay, fractionalDigits) + ` cl/day`;
      case VolumeFlowUnits.DecilitersPerDay:
        return super.truncateFractionDigits(this.DecilitersPerDay, fractionalDigits) + ` dl/day`;
      case VolumeFlowUnits.DecalitersPerDay:
        return super.truncateFractionDigits(this.DecalitersPerDay, fractionalDigits) + ` dal/day`;
      case VolumeFlowUnits.HectolitersPerDay:
        return super.truncateFractionDigits(this.HectolitersPerDay, fractionalDigits) + ` hl/day`;
      case VolumeFlowUnits.KilolitersPerDay:
        return super.truncateFractionDigits(this.KilolitersPerDay, fractionalDigits) + ` kl/day`;
      case VolumeFlowUnits.MegalitersPerDay:
        return super.truncateFractionDigits(this.MegalitersPerDay, fractionalDigits) + ` Ml/day`;
      case VolumeFlowUnits.MegaukGallonsPerDay:
        return super.truncateFractionDigits(this.MegaukGallonsPerDay, fractionalDigits) + ` Mgal (U. K.)/d`;
      case VolumeFlowUnits.MegaukGallonsPerSecond:
        return super.truncateFractionDigits(this.MegaukGallonsPerSecond, fractionalDigits) + ` Mgal (imp.)/s`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get VolumeFlow unit abbreviation.
   * Note! the default abbreviation for VolumeFlow is CubicMetersPerSecond.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the VolumeFlow.
   * @returns The abbreviation string of VolumeFlow.
   */
  getUnitAbbreviation(unitAbbreviation = VolumeFlowUnits.CubicMetersPerSecond) {
    switch (unitAbbreviation) {
      case VolumeFlowUnits.CubicMetersPerSecond:
        return `m³/s`;
      case VolumeFlowUnits.CubicMetersPerMinute:
        return `m³/min`;
      case VolumeFlowUnits.CubicMetersPerHour:
        return `m³/h`;
      case VolumeFlowUnits.CubicMetersPerDay:
        return `m³/d`;
      case VolumeFlowUnits.CubicFeetPerSecond:
        return `ft³/s`;
      case VolumeFlowUnits.CubicFeetPerMinute:
        return `ft³/min`;
      case VolumeFlowUnits.CubicFeetPerHour:
        return `ft³/h`;
      case VolumeFlowUnits.CubicYardsPerSecond:
        return `yd³/s`;
      case VolumeFlowUnits.CubicYardsPerMinute:
        return `yd³/min`;
      case VolumeFlowUnits.CubicYardsPerHour:
        return `yd³/h`;
      case VolumeFlowUnits.CubicYardsPerDay:
        return `cy/day`;
      case VolumeFlowUnits.MillionUsGallonsPerDay:
        return `MGD`;
      case VolumeFlowUnits.UsGallonsPerDay:
        return `gpd`;
      case VolumeFlowUnits.LitersPerSecond:
        return `L/s`;
      case VolumeFlowUnits.LitersPerMinute:
        return `L/min`;
      case VolumeFlowUnits.LitersPerHour:
        return `L/h`;
      case VolumeFlowUnits.LitersPerDay:
        return `l/day`;
      case VolumeFlowUnits.UsGallonsPerSecond:
        return `gal (U.S.)/s`;
      case VolumeFlowUnits.UsGallonsPerMinute:
        return `gal (U.S.)/min`;
      case VolumeFlowUnits.UkGallonsPerDay:
        return `gal (U. K.)/d`;
      case VolumeFlowUnits.UkGallonsPerHour:
        return `gal (imp.)/h`;
      case VolumeFlowUnits.UkGallonsPerMinute:
        return `gal (imp.)/min`;
      case VolumeFlowUnits.UkGallonsPerSecond:
        return `gal (imp.)/s`;
      case VolumeFlowUnits.KilousGallonsPerMinute:
        return `kgal (U.S.)/min`;
      case VolumeFlowUnits.UsGallonsPerHour:
        return `gal (U.S.)/h`;
      case VolumeFlowUnits.CubicDecimetersPerMinute:
        return `dm³/min`;
      case VolumeFlowUnits.OilBarrelsPerDay:
        return `bbl/d`;
      case VolumeFlowUnits.OilBarrelsPerMinute:
        return `bbl/min`;
      case VolumeFlowUnits.OilBarrelsPerHour:
        return `bbl/hr`;
      case VolumeFlowUnits.OilBarrelsPerSecond:
        return `bbl/s`;
      case VolumeFlowUnits.CubicMillimetersPerSecond:
        return `mm³/s`;
      case VolumeFlowUnits.AcreFeetPerSecond:
        return `af/s`;
      case VolumeFlowUnits.AcreFeetPerMinute:
        return `af/m`;
      case VolumeFlowUnits.AcreFeetPerHour:
        return `af/h`;
      case VolumeFlowUnits.AcreFeetPerDay:
        return `af/d`;
      case VolumeFlowUnits.CubicCentimetersPerMinute:
        return `cm³/min`;
      case VolumeFlowUnits.MegausGallonsPerDay:
        return `Mgpd`;
      case VolumeFlowUnits.NanolitersPerSecond:
        return `nL/s`;
      case VolumeFlowUnits.MicrolitersPerSecond:
        return `μL/s`;
      case VolumeFlowUnits.MillilitersPerSecond:
        return `mL/s`;
      case VolumeFlowUnits.CentilitersPerSecond:
        return `cL/s`;
      case VolumeFlowUnits.DecilitersPerSecond:
        return `dL/s`;
      case VolumeFlowUnits.DecalitersPerSecond:
        return `daL/s`;
      case VolumeFlowUnits.HectolitersPerSecond:
        return `hL/s`;
      case VolumeFlowUnits.KilolitersPerSecond:
        return `kL/s`;
      case VolumeFlowUnits.MegalitersPerSecond:
        return `ML/s`;
      case VolumeFlowUnits.NanolitersPerMinute:
        return `nL/min`;
      case VolumeFlowUnits.MicrolitersPerMinute:
        return `μL/min`;
      case VolumeFlowUnits.MillilitersPerMinute:
        return `mL/min`;
      case VolumeFlowUnits.CentilitersPerMinute:
        return `cL/min`;
      case VolumeFlowUnits.DecilitersPerMinute:
        return `dL/min`;
      case VolumeFlowUnits.DecalitersPerMinute:
        return `daL/min`;
      case VolumeFlowUnits.HectolitersPerMinute:
        return `hL/min`;
      case VolumeFlowUnits.KilolitersPerMinute:
        return `kL/min`;
      case VolumeFlowUnits.MegalitersPerMinute:
        return `ML/min`;
      case VolumeFlowUnits.NanolitersPerHour:
        return `nL/h`;
      case VolumeFlowUnits.MicrolitersPerHour:
        return `μL/h`;
      case VolumeFlowUnits.MillilitersPerHour:
        return `mL/h`;
      case VolumeFlowUnits.CentilitersPerHour:
        return `cL/h`;
      case VolumeFlowUnits.DecilitersPerHour:
        return `dL/h`;
      case VolumeFlowUnits.DecalitersPerHour:
        return `daL/h`;
      case VolumeFlowUnits.HectolitersPerHour:
        return `hL/h`;
      case VolumeFlowUnits.KilolitersPerHour:
        return `kL/h`;
      case VolumeFlowUnits.MegalitersPerHour:
        return `ML/h`;
      case VolumeFlowUnits.NanolitersPerDay:
        return `nl/day`;
      case VolumeFlowUnits.MicrolitersPerDay:
        return `μl/day`;
      case VolumeFlowUnits.MillilitersPerDay:
        return `ml/day`;
      case VolumeFlowUnits.CentilitersPerDay:
        return `cl/day`;
      case VolumeFlowUnits.DecilitersPerDay:
        return `dl/day`;
      case VolumeFlowUnits.DecalitersPerDay:
        return `dal/day`;
      case VolumeFlowUnits.HectolitersPerDay:
        return `hl/day`;
      case VolumeFlowUnits.KilolitersPerDay:
        return `kl/day`;
      case VolumeFlowUnits.MegalitersPerDay:
        return `Ml/day`;
      case VolumeFlowUnits.MegaukGallonsPerDay:
        return `Mgal (U. K.)/d`;
      case VolumeFlowUnits.MegaukGallonsPerSecond:
        return `Mgal (imp.)/s`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given VolumeFlow are equals to the current VolumeFlow.
   * @param volumeFlow The other VolumeFlow.
   * @returns True if the given VolumeFlow are equal to the current VolumeFlow.
   */
  equals(volumeFlow) {
    return super.internalEquals(this.value, volumeFlow.BaseValue);
  }
  /**
   * Compare the given VolumeFlow against the current VolumeFlow.
   * @param volumeFlow The other VolumeFlow.
   * @returns 0 if they are equal, -1 if the current VolumeFlow is less then other, 1 if the current VolumeFlow is greater then other.
   */
  compareTo(volumeFlow) {
    return super.internalCompareTo(this.value, volumeFlow.BaseValue);
  }
  /**
   * Add the given VolumeFlow with the current VolumeFlow.
   * @param volumeFlow The other VolumeFlow.
   * @returns A new VolumeFlow instance with the results.
   */
  add(volumeFlow) {
    return new VolumeFlow(super.internalAdd(this.value, volumeFlow.BaseValue));
  }
  /**
   * Subtract the given VolumeFlow with the current VolumeFlow.
   * @param volumeFlow The other VolumeFlow.
   * @returns A new VolumeFlow instance with the results.
   */
  subtract(volumeFlow) {
    return new VolumeFlow(super.internalSubtract(this.value, volumeFlow.BaseValue));
  }
  /**
   * Multiply the given VolumeFlow with the current VolumeFlow.
   * @param volumeFlow The other VolumeFlow.
   * @returns A new VolumeFlow instance with the results.
   */
  multiply(volumeFlow) {
    return new VolumeFlow(super.internalMultiply(this.value, volumeFlow.BaseValue));
  }
  /**
   * Divide the given VolumeFlow with the current VolumeFlow.
   * @param volumeFlow The other VolumeFlow.
   * @returns A new VolumeFlow instance with the results.
   */
  divide(volumeFlow) {
    return new VolumeFlow(super.internalDivide(this.value, volumeFlow.BaseValue));
  }
  /**
   * Modulo the given VolumeFlow with the current VolumeFlow.
   * @param volumeFlow The other VolumeFlow.
   * @returns A new VolumeFlow instance with the results.
   */
  modulo(volumeFlow) {
    return new VolumeFlow(super.internalModulo(this.value, volumeFlow.BaseValue));
  }
  /**
   * Pow the given VolumeFlow with the current VolumeFlow.
   * @param volumeFlow The other VolumeFlow.
   * @returns A new VolumeFlow instance with the results.
   */
  pow(volumeFlow) {
    return new VolumeFlow(super.internalPow(this.value, volumeFlow.BaseValue));
  }
}
exports.VolumeFlow = VolumeFlow;