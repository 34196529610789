import { AppConstants } from 'src/app/shared/constants';
import { STiRResultListPO, CountryLabelPO, StationsPO, STiRResult } from 'src/app/models';
import { AgEventhandlerService, CustomMessageService, IngredientsService, SharedService } from 'src/app/services';
import { Component, OnInit } from '@angular/core';
import { CellEditingStoppedEvent, ColDef, StatusPanelDef, IRichCellEditorParams, CellEditingStartedEvent } from 'ag-grid-community';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-ingredient-country-label',
  templateUrl: './ingredient-country-label.component.html',
  styleUrl: './ingredient-country-label.component.scss'
})
export class IngredientCountryLabelComponent implements OnInit {
  dsCountries: StationsPO[] = [];
  countryLabelIngredients: CountryLabelPO[] = [];
  tempIdCount: number = 0;
  ingredientId: string = '';
  inputRow: CountryLabelPO = { countryName: '', labelAs: '', ingredientId: '', isActive: 'Active', id: '', tempId: null };
  updatedRows: CountryLabelPO[] = [];
  isCountryLabelDialogOpen: boolean = false;
  dsCountryLabels: CountryLabelPO[] = [];
  gridcountryLabelName = AppConstants.CountryLabelAs;
  gridCountryLabelParams!: any;
  columnCountryLabelDefs: ColDef[] = [];
  defaultColDef!: ColDef;
  saveToolTip: string = "";
  roleToolTip: string = "";
  gridCountryLabelOptions = {
    columnDefs: this.columnCountryLabelDefs,
    defaultColDef: this.defaultColDef,
    rowData: this.dsCountryLabels,
    stopEditingWhenCellsLoseFocus: true,
    context: {
      id: this.gridcountryLabelName,
    },
  };
  instance: DynamicDialogComponent | undefined;
  isAdmin: boolean = false;
  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
      statusPanels: [
        { statusPanel: "agTotalRowCountComponent", align: 'left' },
        { statusPanel: "agFilteredRowCountComponent", align: 'left' },
      ],
    };

  isCellEditorStart: boolean = false;
  selectedCoubtryLabelRows: CountryLabelPO[] = [];

  constructor(private ref: DynamicDialogRef, private dialogService: DialogService, private sharedSvc: SharedService, private ingredientsService: IngredientsService,
    private agSvc: AgEventhandlerService, private customMessageService: CustomMessageService, private confirmationService: ConfirmationService) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    this.isAdmin = this.sharedSvc.hasAdmin();
    this.defaultColDef = this.agSvc.getGridDefaults();
    this.setCountryLabelColDef();
    // this.sharedSvc.FetchCountries().subscribe({
    //   next: (res: StationsPO[]) => {
    //     this.dsCountries = res;
    //     this.updateDropdownValues();
    //     if (this.instance && this.instance.data) {
    //       this.ingredientId = this.instance.data['ingredientId'];
    //       this.countryLabelIngredients = this.instance.data['countryLabelIngredients'];
    //       this.countryLabelIngredients.forEach(d => {
    //         if (d.isActive === true)
    //           d.isActive = 'Active';
    //         else if (d.isActive === false)
    //           d.isActive = 'Inactive';
    //       });
    //       this.dsCountryLabels = this.countryLabelIngredients;
    //       this.gridCountryLabelParams.api.setGridOption("rowData", this.dsCountryLabels);
    //     }
    //   },
    //   error: (err: any) => {
    //     this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: err.message, data: AppConstants.Manage_Standards });
    //   }
    // });

    setTimeout(() => {
      if (this.instance && this.instance.data) {
        this.dsCountries = this.instance.data['countries'];
        this.updateDropdownValues();
        this.ingredientId = this.instance.data['ingredientId'];
        this.countryLabelIngredients = this.instance.data['countryLabelIngredients'];

        this.countryLabelIngredients.forEach(d => {
          if (d.isActive === true)
            d.isActive = 'Active';
          else if (d.isActive === false)
            d.isActive = 'Inactive';
        });

        this.dsCountryLabels = this.countryLabelIngredients;
        this.gridCountryLabelParams.api.setGridOption("rowData", this.dsCountryLabels);
      }
    }, 10);

  }

  setCountryLabelColDef() {
    this.columnCountryLabelDefs = [{
      headerName: "",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      showDisabledCheckboxes: true,
      width: 70,
      lockPosition: 'left',
      pinned: 'left',
      suppressHeaderFilterButton: true, suppressFiltersToolPanel: true,
      suppressHeaderMenuButton: true, suppressHeaderContextMenu: true,
      checkboxSelection: true
    },
    {
      headerName: "Country", field: "countryName", editable: this.isAdmin, cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: [],
        allowTyping: true,
        filterList: true,
        highlightMatch: true
      } as IRichCellEditorParams
    },
    { headerName: "Label As", field: "labelAs", editable: this.isAdmin },
    {
      headerName: "Status", field: "isActive", editable: this.isAdmin, cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["Active", "Inactive"],
        allowTyping: true,
        filterList: true,
        highlightMatch: true
      } as IRichCellEditorParams
    }
    ];
    this.gridCountryLabelOptions.defaultColDef = this.defaultColDef;
    this.gridCountryLabelOptions.columnDefs = this.columnCountryLabelDefs;
  }

  updateDropdownValues() {
    const colDefs = this.gridCountryLabelParams.api.getColumnDefs();
    colDefs.find((col: any) => col.field === 'countryName').cellEditorParams.values = this.dsCountries.map(z => z.country);
    this.gridCountryLabelParams.api.setGridOption('columnDefs', colDefs);
  }

  onCounrtyLabelIngredientDelete() {

    let selectedIngredients = this.gridCountryLabelParams.api.getSelectedRows() as CountryLabelPO[];

    if (selectedIngredients.length > 0) {

      let selectedIngredientsLocal = selectedIngredients.filter(d => d.id === "");
      let selectedIngredientsDB = selectedIngredients.filter(d => d.id !== "");

      if (selectedIngredientsLocal.length > 0) {
        const newArray = this.dsCountryLabels.filter((value) => {
          return !selectedIngredientsLocal.includes(value)
        });

        const newUpdatedRow = this.updatedRows.filter((value) => {
          return !selectedIngredientsLocal.includes(value)
        });

        this.updatedRows = newUpdatedRow;
        this.dsCountryLabels = newArray;
        this.gridCountryLabelParams.api.setGridOption("rowData", this.dsCountryLabels);
      }

      if (selectedIngredientsDB.length > 0) {
        this.confirmationService.confirm({
          key: "Country Label As",
          header: 'Country Label As Confirmation',
          icon: 'pi pi-exclamation-triangle',
          message: 'removed row from grid will remove from the database:  Ok to continue?',
          accept: () => {

            let countryLabelAsIds = selectedIngredientsDB.map(d => d.id);

            this.ingredientsService.removeCountryLabelIngredients(countryLabelAsIds).subscribe({
              next: (data: STiRResult) => {
                if (data.isError) {
                  this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: data.message, data: AppConstants.Ingredients });
                } else {
                  this.customMessageService.showMessage({ 'severity': 'success', summary: 'Success', detail: data.message, data: AppConstants.Ingredients });

                  const newArray = this.dsCountryLabels.filter((value) => {
                    return !selectedIngredientsDB.includes(value)
                  });
                  this.dsCountryLabels = newArray;
                  this.gridCountryLabelParams.api.setGridOption("rowData", this.dsCountryLabels);

                  const newUpdatedRow = this.updatedRows.filter((value) => {
                    return !selectedIngredientsDB.includes(value)
                  });

                  this.updatedRows = newUpdatedRow;
                }
              },
              error: (err: any) => {
                this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: err.message, data: AppConstants.Ingredients });
              }
            })
          },
        });
      }
    } else {
      this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: "Please select the row to remove", data: AppConstants.Ingredients });
    }
  }

  saveCounrtyLabelIngredient() {

    //var cellDefs = this.gridCountryLabelParams.api.getEditingCells();
    this.gridCountryLabelParams.api.stopEditing();

    // if (this.dsCountryLabels.length === 1) {
    //   let cellEditorCurrentValue = this.dsCountryLabels[0] as CountryLabelPO;
    //   this.updatedRows.push(cellEditorCurrentValue);
    // }
    // else {
    //   let cellEditorCurrentValue = this.dsCountryLabels[cellDefs[0].rowIndex] as CountryLabelPO;
    //   this.updatedRows.push(cellEditorCurrentValue);
    // }

    if (this.listHasEmptyProperties(this.updatedRows)) {
      this.customMessageService.showMessage({ 'severity': 'warn', summary: 'Warning', detail: "Grid contains empty cells", data: AppConstants.Manage_Standards });
      return;
    }


    if (this.hasDuplicateCombinations(this.dsCountryLabels, 'countryName', 'labelAs')) {
      this.customMessageService.showMessage({ 'severity': 'warn', summary: 'Warning', detail: "Duplicate combination found for country name and labelAs", data: AppConstants.Manage_Standards });
      return;
    }

    this.updatedRows.forEach(d => {
      if (d.isActive === 'Active')
        d.isActive = true;
      else if (d.isActive === 'Inactive')
        d.isActive = false;
    })

    this.ingredientsService.SaveCountryLabelIngredients(this.updatedRows).subscribe({
      next: (data: STiRResultListPO<CountryLabelPO>) => {
        if (data.isError) {
          this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: data.message, data: AppConstants.Ingredients });
        } else {
          this.customMessageService.showMessage({ 'severity': 'success', summary: 'Success', detail: data.message, data: AppConstants.Ingredients });
          data.sTiRResultList.forEach(d => {
            if (d.isActive === true)
              d.isActive = 'Active';
            else if (d.isActive === false)
              d.isActive = 'Inactive';
          });
          this.dsCountryLabels = data.sTiRResultList;
          this.gridCountryLabelParams.api.setGridOption("rowData", this.dsCountryLabels);
          this.updatedRows = [];
        }
      },
      error: (err: any) => {
        this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: err.message, data: AppConstants.Ingredients });
      }
    })
  }

  listHasEmptyProperties(list: CountryLabelPO[]): boolean {
    return list.some(item => this.hasEmptyProperties(item));
  }

  hasEmptyProperties(obj: any): boolean {
    return Object.keys(obj).filter(x => x === "countryName" || x === "labelAs").
      some(key => obj[key] === '' || obj[key] === null || obj[key] === undefined);
  }

  // Function to check for duplicate combinations
  hasDuplicateCombinations(list: any[], prop1: string, prop2: string): boolean {
    const seenKeys = new Set();
    return list.some(item => {
      const key = this.generateKey(item, prop1, prop2);
      if (seenKeys.has(key)) {
        return true;
      }
      seenKeys.add(key);
      return false;
    });
  }

  // Function to generate a unique key for each object based on three properties
  generateKey(obj: any, prop1: string, prop2: string): string {
    return `${obj[prop1]}|${obj[prop2]}}`;
  }

  onCellEditingStarted(params: CellEditingStartedEvent) {
    this.isCellEditorStart = true;
  }

  onCellEditingStopped(params: CellEditingStoppedEvent) {
    if (params.colDef.field === "countryName") {
      let isCountryExist = this.dsCountryLabels.filter(z => z.countryName.toLowerCase() === params.value.toLowerCase());
      if (isCountryExist.length > 1) {
        params.data.countryName = params.oldValue;
        params.newValue = params.oldValue;
        params.value = params.oldValue;
        params.node.setData(params.data);
        this.customMessageService.showMessage({ 'severity': 'warn', summary: 'Warning', detail: "Selected country exist in Label As list", data: AppConstants.Manage_Standards });
        return;
      }
    }

    if (this.updatedRows.length === 0) {
      this.updatedRows.push(params.data);
    } else {
      if (params.data.tempId !== null && params.data.tempId !== 0 && params.data.tempId !== undefined) {
        let isRecordFound = this.updatedRows.filter(x => x.tempId === params.data.tempId)
        if (isRecordFound.length > 0) {
          isRecordFound[0] = params.data;
        } else {
          this.updatedRows.push(params.data);
        }
      } else {
        let isRecordFound = this.updatedRows.filter(x => x.id === params.data.id)
        if (isRecordFound.length > 0) {
          isRecordFound[0] = params.data;
        } else {
          this.updatedRows.push(params.data);
        }
      }
    }
  }
  get isSaveEnable() {
    if (this.isAdmin) {
      if (this.updatedRows.length === 0) {
        setTimeout(() => {
          this.saveToolTip = "Please add or update the rows to enable";
        }, 0);
        return true
      } else {
        setTimeout(() => {
          this.saveToolTip = "All pending changes will be update to database";
        }, 0);
        return false;
      }
    } else {
      setTimeout(() => {
        this.saveToolTip = "Admin role is required to enable";
      }, 0);
      return true;
    }
  }

  onCountryLabelGridReady(params: any) {
    this.gridCountryLabelParams = params;
  }

  onCountryLabelModalCancel() {
    this.updatedRows = [];
    this.ref.close({ 'countryLabels': this.dsCountryLabels.filter(d => d.id !== ''), 'ingredientId': this.ingredientId });
  }

  addFactstoIngredient() {
    if (this.dsCountries.length === 0) {
      this.customMessageService.showMessage({
        'severity': 'error', summary: 'Error', detail: "Countries loading..",
        data: AppConstants.Ingredients
      });
      return;
    }
    let input = { ...this.inputRow };
    input.countryName = '';
    input.labelAs = '';
    input.id = '';
    input.ingredientId = this.ingredientId;
    input.isActive = 'Active',//true;
      input.tempId = ++this.tempIdCount;
    this.updatedRows.push(input);
    this.dsCountryLabels = [input, ...this.dsCountryLabels];
    this.gridCountryLabelParams.api.setGridOption("rowData", this.dsCountryLabels);
  }

  onCountryLabelSelectionChanged(ev: any) {
    this.selectedCoubtryLabelRows = this.gridCountryLabelParams.api.getSelectedRows();
  }
}
