"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricResistance = exports.ElectricResistanceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricResistanceUnits enumeration */
var ElectricResistanceUnits;
(function (ElectricResistanceUnits) {
  /** */
  ElectricResistanceUnits["Ohms"] = "Ohm";
  /** */
  ElectricResistanceUnits["Microohms"] = "Microohm";
  /** */
  ElectricResistanceUnits["Milliohms"] = "Milliohm";
  /** */
  ElectricResistanceUnits["Kiloohms"] = "Kiloohm";
  /** */
  ElectricResistanceUnits["Megaohms"] = "Megaohm";
  /** */
  ElectricResistanceUnits["Gigaohms"] = "Gigaohm";
  /** */
  ElectricResistanceUnits["Teraohms"] = "Teraohm";
})(ElectricResistanceUnits = exports.ElectricResistanceUnits || (exports.ElectricResistanceUnits = {}));
/** The electrical resistance of an electrical conductor is the opposition to the passage of an electric current through that conductor. */
class ElectricResistance extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricResistance.
   * @param value The value.
   * @param fromUnit The ‘ElectricResistance’ unit to create from.
   * The default unit is Ohms
   */
  constructor(value, fromUnit = ElectricResistanceUnits.Ohms) {
    super();
    this.ohmsLazy = null;
    this.microohmsLazy = null;
    this.milliohmsLazy = null;
    this.kiloohmsLazy = null;
    this.megaohmsLazy = null;
    this.gigaohmsLazy = null;
    this.teraohmsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricResistance is Ohms.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricResistanceUnits.Ohms;
  }
  /** */
  get Ohms() {
    if (this.ohmsLazy !== null) {
      return this.ohmsLazy;
    }
    return this.ohmsLazy = this.convertFromBase(ElectricResistanceUnits.Ohms);
  }
  /** */
  get Microohms() {
    if (this.microohmsLazy !== null) {
      return this.microohmsLazy;
    }
    return this.microohmsLazy = this.convertFromBase(ElectricResistanceUnits.Microohms);
  }
  /** */
  get Milliohms() {
    if (this.milliohmsLazy !== null) {
      return this.milliohmsLazy;
    }
    return this.milliohmsLazy = this.convertFromBase(ElectricResistanceUnits.Milliohms);
  }
  /** */
  get Kiloohms() {
    if (this.kiloohmsLazy !== null) {
      return this.kiloohmsLazy;
    }
    return this.kiloohmsLazy = this.convertFromBase(ElectricResistanceUnits.Kiloohms);
  }
  /** */
  get Megaohms() {
    if (this.megaohmsLazy !== null) {
      return this.megaohmsLazy;
    }
    return this.megaohmsLazy = this.convertFromBase(ElectricResistanceUnits.Megaohms);
  }
  /** */
  get Gigaohms() {
    if (this.gigaohmsLazy !== null) {
      return this.gigaohmsLazy;
    }
    return this.gigaohmsLazy = this.convertFromBase(ElectricResistanceUnits.Gigaohms);
  }
  /** */
  get Teraohms() {
    if (this.teraohmsLazy !== null) {
      return this.teraohmsLazy;
    }
    return this.teraohmsLazy = this.convertFromBase(ElectricResistanceUnits.Teraohms);
  }
  /**
   * Create a new ElectricResistance instance from a Ohms
   *
   * @param value The unit as Ohms to create a new ElectricResistance from.
   * @returns The new ElectricResistance instance.
   */
  static FromOhms(value) {
    return new ElectricResistance(value, ElectricResistanceUnits.Ohms);
  }
  /**
   * Create a new ElectricResistance instance from a Microohms
   *
   * @param value The unit as Microohms to create a new ElectricResistance from.
   * @returns The new ElectricResistance instance.
   */
  static FromMicroohms(value) {
    return new ElectricResistance(value, ElectricResistanceUnits.Microohms);
  }
  /**
   * Create a new ElectricResistance instance from a Milliohms
   *
   * @param value The unit as Milliohms to create a new ElectricResistance from.
   * @returns The new ElectricResistance instance.
   */
  static FromMilliohms(value) {
    return new ElectricResistance(value, ElectricResistanceUnits.Milliohms);
  }
  /**
   * Create a new ElectricResistance instance from a Kiloohms
   *
   * @param value The unit as Kiloohms to create a new ElectricResistance from.
   * @returns The new ElectricResistance instance.
   */
  static FromKiloohms(value) {
    return new ElectricResistance(value, ElectricResistanceUnits.Kiloohms);
  }
  /**
   * Create a new ElectricResistance instance from a Megaohms
   *
   * @param value The unit as Megaohms to create a new ElectricResistance from.
   * @returns The new ElectricResistance instance.
   */
  static FromMegaohms(value) {
    return new ElectricResistance(value, ElectricResistanceUnits.Megaohms);
  }
  /**
   * Create a new ElectricResistance instance from a Gigaohms
   *
   * @param value The unit as Gigaohms to create a new ElectricResistance from.
   * @returns The new ElectricResistance instance.
   */
  static FromGigaohms(value) {
    return new ElectricResistance(value, ElectricResistanceUnits.Gigaohms);
  }
  /**
   * Create a new ElectricResistance instance from a Teraohms
   *
   * @param value The unit as Teraohms to create a new ElectricResistance from.
   * @returns The new ElectricResistance instance.
   */
  static FromTeraohms(value) {
    return new ElectricResistance(value, ElectricResistanceUnits.Teraohms);
  }
  /**
   * Gets the base unit enumeration associated with ElectricResistance
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricResistanceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricResistanceUnits.Ohms;
  }
  /**
   * Create API DTO represent a ElectricResistance unit.
   * @param holdInUnit The specific ElectricResistance unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricResistanceUnits.Ohms) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricResistance unit from an API DTO representation.
   * @param dtoElectricResistance The ElectricResistance API DTO representation
   */
  static FromDto(dtoElectricResistance) {
    return new ElectricResistance(dtoElectricResistance.value, dtoElectricResistance.unit);
  }
  /**
   * Convert ElectricResistance to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricResistanceUnits.Ohms:
        return this.Ohms;
      case ElectricResistanceUnits.Microohms:
        return this.Microohms;
      case ElectricResistanceUnits.Milliohms:
        return this.Milliohms;
      case ElectricResistanceUnits.Kiloohms:
        return this.Kiloohms;
      case ElectricResistanceUnits.Megaohms:
        return this.Megaohms;
      case ElectricResistanceUnits.Gigaohms:
        return this.Gigaohms;
      case ElectricResistanceUnits.Teraohms:
        return this.Teraohms;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricResistanceUnits.Ohms:
        return this.value;
      case ElectricResistanceUnits.Microohms:
        return super.internalDivide(this.value, 0.000001);
      case ElectricResistanceUnits.Milliohms:
        return super.internalDivide(this.value, 0.001);
      case ElectricResistanceUnits.Kiloohms:
        return super.internalDivide(this.value, 1000);
      case ElectricResistanceUnits.Megaohms:
        return super.internalDivide(this.value, 1000000);
      case ElectricResistanceUnits.Gigaohms:
        return super.internalDivide(this.value, 1000000000);
      case ElectricResistanceUnits.Teraohms:
        return super.internalDivide(this.value, 1000000000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricResistanceUnits.Ohms:
        return this.value;
      case ElectricResistanceUnits.Microohms:
        return this.value / 0.000001;
      case ElectricResistanceUnits.Milliohms:
        return this.value / 0.001;
      case ElectricResistanceUnits.Kiloohms:
        return this.value / 1000;
      case ElectricResistanceUnits.Megaohms:
        return this.value / 1000000;
      case ElectricResistanceUnits.Gigaohms:
        return this.value / 1000000000;
      case ElectricResistanceUnits.Teraohms:
        return this.value / 1000000000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricResistanceUnits.Ohms:
        return value;
      case ElectricResistanceUnits.Microohms:
        return super.internalMultiply(value, 0.000001);
      case ElectricResistanceUnits.Milliohms:
        return super.internalMultiply(value, 0.001);
      case ElectricResistanceUnits.Kiloohms:
        return super.internalMultiply(value, 1000);
      case ElectricResistanceUnits.Megaohms:
        return super.internalMultiply(value, 1000000);
      case ElectricResistanceUnits.Gigaohms:
        return super.internalMultiply(value, 1000000000);
      case ElectricResistanceUnits.Teraohms:
        return super.internalMultiply(value, 1000000000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricResistanceUnits.Ohms:
        return value;
      case ElectricResistanceUnits.Microohms:
        return value * 0.000001;
      case ElectricResistanceUnits.Milliohms:
        return value * 0.001;
      case ElectricResistanceUnits.Kiloohms:
        return value * 1000;
      case ElectricResistanceUnits.Megaohms:
        return value * 1000000;
      case ElectricResistanceUnits.Gigaohms:
        return value * 1000000000;
      case ElectricResistanceUnits.Teraohms:
        return value * 1000000000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricResistance to string.
   * Note! the default format for ElectricResistance is Ohms.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricResistance.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricResistance.
   */
  toString(unit = ElectricResistanceUnits.Ohms, fractionalDigits) {
    switch (unit) {
      case ElectricResistanceUnits.Ohms:
        return super.truncateFractionDigits(this.Ohms, fractionalDigits) + ` Ω`;
      case ElectricResistanceUnits.Microohms:
        return super.truncateFractionDigits(this.Microohms, fractionalDigits) + ` μΩ`;
      case ElectricResistanceUnits.Milliohms:
        return super.truncateFractionDigits(this.Milliohms, fractionalDigits) + ` mΩ`;
      case ElectricResistanceUnits.Kiloohms:
        return super.truncateFractionDigits(this.Kiloohms, fractionalDigits) + ` kΩ`;
      case ElectricResistanceUnits.Megaohms:
        return super.truncateFractionDigits(this.Megaohms, fractionalDigits) + ` MΩ`;
      case ElectricResistanceUnits.Gigaohms:
        return super.truncateFractionDigits(this.Gigaohms, fractionalDigits) + ` GΩ`;
      case ElectricResistanceUnits.Teraohms:
        return super.truncateFractionDigits(this.Teraohms, fractionalDigits) + ` TΩ`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricResistance unit abbreviation.
   * Note! the default abbreviation for ElectricResistance is Ohms.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricResistance.
   * @returns The abbreviation string of ElectricResistance.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricResistanceUnits.Ohms) {
    switch (unitAbbreviation) {
      case ElectricResistanceUnits.Ohms:
        return `Ω`;
      case ElectricResistanceUnits.Microohms:
        return `μΩ`;
      case ElectricResistanceUnits.Milliohms:
        return `mΩ`;
      case ElectricResistanceUnits.Kiloohms:
        return `kΩ`;
      case ElectricResistanceUnits.Megaohms:
        return `MΩ`;
      case ElectricResistanceUnits.Gigaohms:
        return `GΩ`;
      case ElectricResistanceUnits.Teraohms:
        return `TΩ`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricResistance are equals to the current ElectricResistance.
   * @param electricResistance The other ElectricResistance.
   * @returns True if the given ElectricResistance are equal to the current ElectricResistance.
   */
  equals(electricResistance) {
    return super.internalEquals(this.value, electricResistance.BaseValue);
  }
  /**
   * Compare the given ElectricResistance against the current ElectricResistance.
   * @param electricResistance The other ElectricResistance.
   * @returns 0 if they are equal, -1 if the current ElectricResistance is less then other, 1 if the current ElectricResistance is greater then other.
   */
  compareTo(electricResistance) {
    return super.internalCompareTo(this.value, electricResistance.BaseValue);
  }
  /**
   * Add the given ElectricResistance with the current ElectricResistance.
   * @param electricResistance The other ElectricResistance.
   * @returns A new ElectricResistance instance with the results.
   */
  add(electricResistance) {
    return new ElectricResistance(super.internalAdd(this.value, electricResistance.BaseValue));
  }
  /**
   * Subtract the given ElectricResistance with the current ElectricResistance.
   * @param electricResistance The other ElectricResistance.
   * @returns A new ElectricResistance instance with the results.
   */
  subtract(electricResistance) {
    return new ElectricResistance(super.internalSubtract(this.value, electricResistance.BaseValue));
  }
  /**
   * Multiply the given ElectricResistance with the current ElectricResistance.
   * @param electricResistance The other ElectricResistance.
   * @returns A new ElectricResistance instance with the results.
   */
  multiply(electricResistance) {
    return new ElectricResistance(super.internalMultiply(this.value, electricResistance.BaseValue));
  }
  /**
   * Divide the given ElectricResistance with the current ElectricResistance.
   * @param electricResistance The other ElectricResistance.
   * @returns A new ElectricResistance instance with the results.
   */
  divide(electricResistance) {
    return new ElectricResistance(super.internalDivide(this.value, electricResistance.BaseValue));
  }
  /**
   * Modulo the given ElectricResistance with the current ElectricResistance.
   * @param electricResistance The other ElectricResistance.
   * @returns A new ElectricResistance instance with the results.
   */
  modulo(electricResistance) {
    return new ElectricResistance(super.internalModulo(this.value, electricResistance.BaseValue));
  }
  /**
   * Pow the given ElectricResistance with the current ElectricResistance.
   * @param electricResistance The other ElectricResistance.
   * @returns A new ElectricResistance instance with the results.
   */
  pow(electricResistance) {
    return new ElectricResistance(super.internalPow(this.value, electricResistance.BaseValue));
  }
}
exports.ElectricResistance = ElectricResistance;