"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KinematicViscosity = exports.KinematicViscosityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** KinematicViscosityUnits enumeration */
var KinematicViscosityUnits;
(function (KinematicViscosityUnits) {
  /** */
  KinematicViscosityUnits["SquareMetersPerSecond"] = "SquareMeterPerSecond";
  /** */
  KinematicViscosityUnits["Stokes"] = "Stokes";
  /** */
  KinematicViscosityUnits["SquareFeetPerSecond"] = "SquareFootPerSecond";
  /** */
  KinematicViscosityUnits["Nanostokes"] = "Nanostokes";
  /** */
  KinematicViscosityUnits["Microstokes"] = "Microstokes";
  /** */
  KinematicViscosityUnits["Millistokes"] = "Millistokes";
  /** */
  KinematicViscosityUnits["Centistokes"] = "Centistokes";
  /** */
  KinematicViscosityUnits["Decistokes"] = "Decistokes";
  /** */
  KinematicViscosityUnits["Kilostokes"] = "Kilostokes";
})(KinematicViscosityUnits = exports.KinematicViscosityUnits || (exports.KinematicViscosityUnits = {}));
/** The viscosity of a fluid is a measure of its resistance to gradual deformation by shear stress or tensile stress. */
class KinematicViscosity extends base_unit_1.BaseUnit {
  /**
   * Create a new KinematicViscosity.
   * @param value The value.
   * @param fromUnit The ‘KinematicViscosity’ unit to create from.
   * The default unit is SquareMetersPerSecond
   */
  constructor(value, fromUnit = KinematicViscosityUnits.SquareMetersPerSecond) {
    super();
    this.squaremeterspersecondLazy = null;
    this.stokesLazy = null;
    this.squarefeetpersecondLazy = null;
    this.nanostokesLazy = null;
    this.microstokesLazy = null;
    this.millistokesLazy = null;
    this.centistokesLazy = null;
    this.decistokesLazy = null;
    this.kilostokesLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of KinematicViscosity is SquareMetersPerSecond.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return KinematicViscosityUnits.SquareMetersPerSecond;
  }
  /** */
  get SquareMetersPerSecond() {
    if (this.squaremeterspersecondLazy !== null) {
      return this.squaremeterspersecondLazy;
    }
    return this.squaremeterspersecondLazy = this.convertFromBase(KinematicViscosityUnits.SquareMetersPerSecond);
  }
  /** */
  get Stokes() {
    if (this.stokesLazy !== null) {
      return this.stokesLazy;
    }
    return this.stokesLazy = this.convertFromBase(KinematicViscosityUnits.Stokes);
  }
  /** */
  get SquareFeetPerSecond() {
    if (this.squarefeetpersecondLazy !== null) {
      return this.squarefeetpersecondLazy;
    }
    return this.squarefeetpersecondLazy = this.convertFromBase(KinematicViscosityUnits.SquareFeetPerSecond);
  }
  /** */
  get Nanostokes() {
    if (this.nanostokesLazy !== null) {
      return this.nanostokesLazy;
    }
    return this.nanostokesLazy = this.convertFromBase(KinematicViscosityUnits.Nanostokes);
  }
  /** */
  get Microstokes() {
    if (this.microstokesLazy !== null) {
      return this.microstokesLazy;
    }
    return this.microstokesLazy = this.convertFromBase(KinematicViscosityUnits.Microstokes);
  }
  /** */
  get Millistokes() {
    if (this.millistokesLazy !== null) {
      return this.millistokesLazy;
    }
    return this.millistokesLazy = this.convertFromBase(KinematicViscosityUnits.Millistokes);
  }
  /** */
  get Centistokes() {
    if (this.centistokesLazy !== null) {
      return this.centistokesLazy;
    }
    return this.centistokesLazy = this.convertFromBase(KinematicViscosityUnits.Centistokes);
  }
  /** */
  get Decistokes() {
    if (this.decistokesLazy !== null) {
      return this.decistokesLazy;
    }
    return this.decistokesLazy = this.convertFromBase(KinematicViscosityUnits.Decistokes);
  }
  /** */
  get Kilostokes() {
    if (this.kilostokesLazy !== null) {
      return this.kilostokesLazy;
    }
    return this.kilostokesLazy = this.convertFromBase(KinematicViscosityUnits.Kilostokes);
  }
  /**
   * Create a new KinematicViscosity instance from a SquareMetersPerSecond
   *
   * @param value The unit as SquareMetersPerSecond to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromSquareMetersPerSecond(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.SquareMetersPerSecond);
  }
  /**
   * Create a new KinematicViscosity instance from a Stokes
   *
   * @param value The unit as Stokes to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromStokes(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.Stokes);
  }
  /**
   * Create a new KinematicViscosity instance from a SquareFeetPerSecond
   *
   * @param value The unit as SquareFeetPerSecond to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromSquareFeetPerSecond(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.SquareFeetPerSecond);
  }
  /**
   * Create a new KinematicViscosity instance from a Nanostokes
   *
   * @param value The unit as Nanostokes to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromNanostokes(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.Nanostokes);
  }
  /**
   * Create a new KinematicViscosity instance from a Microstokes
   *
   * @param value The unit as Microstokes to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromMicrostokes(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.Microstokes);
  }
  /**
   * Create a new KinematicViscosity instance from a Millistokes
   *
   * @param value The unit as Millistokes to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromMillistokes(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.Millistokes);
  }
  /**
   * Create a new KinematicViscosity instance from a Centistokes
   *
   * @param value The unit as Centistokes to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromCentistokes(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.Centistokes);
  }
  /**
   * Create a new KinematicViscosity instance from a Decistokes
   *
   * @param value The unit as Decistokes to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromDecistokes(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.Decistokes);
  }
  /**
   * Create a new KinematicViscosity instance from a Kilostokes
   *
   * @param value The unit as Kilostokes to create a new KinematicViscosity from.
   * @returns The new KinematicViscosity instance.
   */
  static FromKilostokes(value) {
    return new KinematicViscosity(value, KinematicViscosityUnits.Kilostokes);
  }
  /**
   * Gets the base unit enumeration associated with KinematicViscosity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return KinematicViscosityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return KinematicViscosityUnits.SquareMetersPerSecond;
  }
  /**
   * Create API DTO represent a KinematicViscosity unit.
   * @param holdInUnit The specific KinematicViscosity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = KinematicViscosityUnits.SquareMetersPerSecond) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a KinematicViscosity unit from an API DTO representation.
   * @param dtoKinematicViscosity The KinematicViscosity API DTO representation
   */
  static FromDto(dtoKinematicViscosity) {
    return new KinematicViscosity(dtoKinematicViscosity.value, dtoKinematicViscosity.unit);
  }
  /**
   * Convert KinematicViscosity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case KinematicViscosityUnits.SquareMetersPerSecond:
        return this.SquareMetersPerSecond;
      case KinematicViscosityUnits.Stokes:
        return this.Stokes;
      case KinematicViscosityUnits.SquareFeetPerSecond:
        return this.SquareFeetPerSecond;
      case KinematicViscosityUnits.Nanostokes:
        return this.Nanostokes;
      case KinematicViscosityUnits.Microstokes:
        return this.Microstokes;
      case KinematicViscosityUnits.Millistokes:
        return this.Millistokes;
      case KinematicViscosityUnits.Centistokes:
        return this.Centistokes;
      case KinematicViscosityUnits.Decistokes:
        return this.Decistokes;
      case KinematicViscosityUnits.Kilostokes:
        return this.Kilostokes;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case KinematicViscosityUnits.SquareMetersPerSecond:
        return this.value;
      case KinematicViscosityUnits.Stokes:
        return super.internalMultiply(this.value, 1e4);
      case KinematicViscosityUnits.SquareFeetPerSecond:
        return super.internalMultiply(this.value, 10.7639);
      case KinematicViscosityUnits.Nanostokes:
        {
          const v3 = super.internalMultiply(this.value, 1e4);
          return super.internalDivide(v3, 1e-9);
        }
      case KinematicViscosityUnits.Microstokes:
        {
          const v3 = super.internalMultiply(this.value, 1e4);
          return super.internalDivide(v3, 0.000001);
        }
      case KinematicViscosityUnits.Millistokes:
        {
          const v3 = super.internalMultiply(this.value, 1e4);
          return super.internalDivide(v3, 0.001);
        }
      case KinematicViscosityUnits.Centistokes:
        {
          const v3 = super.internalMultiply(this.value, 1e4);
          return super.internalDivide(v3, 0.01);
        }
      case KinematicViscosityUnits.Decistokes:
        {
          const v3 = super.internalMultiply(this.value, 1e4);
          return super.internalDivide(v3, 0.1);
        }
      case KinematicViscosityUnits.Kilostokes:
        {
          const v3 = super.internalMultiply(this.value, 1e4);
          return super.internalDivide(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case KinematicViscosityUnits.SquareMetersPerSecond:
        return this.value;
      case KinematicViscosityUnits.Stokes:
        return this.value * 1e4;
      case KinematicViscosityUnits.SquareFeetPerSecond:
        return this.value * 10.7639;
      case KinematicViscosityUnits.Nanostokes:
        return this.value * 1e4 / 1e-9;
      case KinematicViscosityUnits.Microstokes:
        return this.value * 1e4 / 0.000001;
      case KinematicViscosityUnits.Millistokes:
        return this.value * 1e4 / 0.001;
      case KinematicViscosityUnits.Centistokes:
        return this.value * 1e4 / 0.01;
      case KinematicViscosityUnits.Decistokes:
        return this.value * 1e4 / 0.1;
      case KinematicViscosityUnits.Kilostokes:
        return this.value * 1e4 / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case KinematicViscosityUnits.SquareMetersPerSecond:
        return value;
      case KinematicViscosityUnits.Stokes:
        return super.internalDivide(value, 1e4);
      case KinematicViscosityUnits.SquareFeetPerSecond:
        return super.internalDivide(value, 10.7639);
      case KinematicViscosityUnits.Nanostokes:
        {
          const v3 = super.internalDivide(value, 1e4);
          return super.internalMultiply(v3, 1e-9);
        }
      case KinematicViscosityUnits.Microstokes:
        {
          const v3 = super.internalDivide(value, 1e4);
          return super.internalMultiply(v3, 0.000001);
        }
      case KinematicViscosityUnits.Millistokes:
        {
          const v3 = super.internalDivide(value, 1e4);
          return super.internalMultiply(v3, 0.001);
        }
      case KinematicViscosityUnits.Centistokes:
        {
          const v3 = super.internalDivide(value, 1e4);
          return super.internalMultiply(v3, 0.01);
        }
      case KinematicViscosityUnits.Decistokes:
        {
          const v3 = super.internalDivide(value, 1e4);
          return super.internalMultiply(v3, 0.1);
        }
      case KinematicViscosityUnits.Kilostokes:
        {
          const v3 = super.internalDivide(value, 1e4);
          return super.internalMultiply(v3, 1000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case KinematicViscosityUnits.SquareMetersPerSecond:
        return value;
      case KinematicViscosityUnits.Stokes:
        return value / 1e4;
      case KinematicViscosityUnits.SquareFeetPerSecond:
        return value / 10.7639;
      case KinematicViscosityUnits.Nanostokes:
        return value / 1e4 * 1e-9;
      case KinematicViscosityUnits.Microstokes:
        return value / 1e4 * 0.000001;
      case KinematicViscosityUnits.Millistokes:
        return value / 1e4 * 0.001;
      case KinematicViscosityUnits.Centistokes:
        return value / 1e4 * 0.01;
      case KinematicViscosityUnits.Decistokes:
        return value / 1e4 * 0.1;
      case KinematicViscosityUnits.Kilostokes:
        return value / 1e4 * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the KinematicViscosity to string.
   * Note! the default format for KinematicViscosity is SquareMetersPerSecond.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the KinematicViscosity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the KinematicViscosity.
   */
  toString(unit = KinematicViscosityUnits.SquareMetersPerSecond, fractionalDigits) {
    switch (unit) {
      case KinematicViscosityUnits.SquareMetersPerSecond:
        return super.truncateFractionDigits(this.SquareMetersPerSecond, fractionalDigits) + ` m²/s`;
      case KinematicViscosityUnits.Stokes:
        return super.truncateFractionDigits(this.Stokes, fractionalDigits) + ` St`;
      case KinematicViscosityUnits.SquareFeetPerSecond:
        return super.truncateFractionDigits(this.SquareFeetPerSecond, fractionalDigits) + ` ft²/s`;
      case KinematicViscosityUnits.Nanostokes:
        return super.truncateFractionDigits(this.Nanostokes, fractionalDigits) + ` nSt`;
      case KinematicViscosityUnits.Microstokes:
        return super.truncateFractionDigits(this.Microstokes, fractionalDigits) + ` μSt`;
      case KinematicViscosityUnits.Millistokes:
        return super.truncateFractionDigits(this.Millistokes, fractionalDigits) + ` mSt`;
      case KinematicViscosityUnits.Centistokes:
        return super.truncateFractionDigits(this.Centistokes, fractionalDigits) + ` cSt`;
      case KinematicViscosityUnits.Decistokes:
        return super.truncateFractionDigits(this.Decistokes, fractionalDigits) + ` dSt`;
      case KinematicViscosityUnits.Kilostokes:
        return super.truncateFractionDigits(this.Kilostokes, fractionalDigits) + ` kSt`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get KinematicViscosity unit abbreviation.
   * Note! the default abbreviation for KinematicViscosity is SquareMetersPerSecond.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the KinematicViscosity.
   * @returns The abbreviation string of KinematicViscosity.
   */
  getUnitAbbreviation(unitAbbreviation = KinematicViscosityUnits.SquareMetersPerSecond) {
    switch (unitAbbreviation) {
      case KinematicViscosityUnits.SquareMetersPerSecond:
        return `m²/s`;
      case KinematicViscosityUnits.Stokes:
        return `St`;
      case KinematicViscosityUnits.SquareFeetPerSecond:
        return `ft²/s`;
      case KinematicViscosityUnits.Nanostokes:
        return `nSt`;
      case KinematicViscosityUnits.Microstokes:
        return `μSt`;
      case KinematicViscosityUnits.Millistokes:
        return `mSt`;
      case KinematicViscosityUnits.Centistokes:
        return `cSt`;
      case KinematicViscosityUnits.Decistokes:
        return `dSt`;
      case KinematicViscosityUnits.Kilostokes:
        return `kSt`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given KinematicViscosity are equals to the current KinematicViscosity.
   * @param kinematicViscosity The other KinematicViscosity.
   * @returns True if the given KinematicViscosity are equal to the current KinematicViscosity.
   */
  equals(kinematicViscosity) {
    return super.internalEquals(this.value, kinematicViscosity.BaseValue);
  }
  /**
   * Compare the given KinematicViscosity against the current KinematicViscosity.
   * @param kinematicViscosity The other KinematicViscosity.
   * @returns 0 if they are equal, -1 if the current KinematicViscosity is less then other, 1 if the current KinematicViscosity is greater then other.
   */
  compareTo(kinematicViscosity) {
    return super.internalCompareTo(this.value, kinematicViscosity.BaseValue);
  }
  /**
   * Add the given KinematicViscosity with the current KinematicViscosity.
   * @param kinematicViscosity The other KinematicViscosity.
   * @returns A new KinematicViscosity instance with the results.
   */
  add(kinematicViscosity) {
    return new KinematicViscosity(super.internalAdd(this.value, kinematicViscosity.BaseValue));
  }
  /**
   * Subtract the given KinematicViscosity with the current KinematicViscosity.
   * @param kinematicViscosity The other KinematicViscosity.
   * @returns A new KinematicViscosity instance with the results.
   */
  subtract(kinematicViscosity) {
    return new KinematicViscosity(super.internalSubtract(this.value, kinematicViscosity.BaseValue));
  }
  /**
   * Multiply the given KinematicViscosity with the current KinematicViscosity.
   * @param kinematicViscosity The other KinematicViscosity.
   * @returns A new KinematicViscosity instance with the results.
   */
  multiply(kinematicViscosity) {
    return new KinematicViscosity(super.internalMultiply(this.value, kinematicViscosity.BaseValue));
  }
  /**
   * Divide the given KinematicViscosity with the current KinematicViscosity.
   * @param kinematicViscosity The other KinematicViscosity.
   * @returns A new KinematicViscosity instance with the results.
   */
  divide(kinematicViscosity) {
    return new KinematicViscosity(super.internalDivide(this.value, kinematicViscosity.BaseValue));
  }
  /**
   * Modulo the given KinematicViscosity with the current KinematicViscosity.
   * @param kinematicViscosity The other KinematicViscosity.
   * @returns A new KinematicViscosity instance with the results.
   */
  modulo(kinematicViscosity) {
    return new KinematicViscosity(super.internalModulo(this.value, kinematicViscosity.BaseValue));
  }
  /**
   * Pow the given KinematicViscosity with the current KinematicViscosity.
   * @param kinematicViscosity The other KinematicViscosity.
   * @returns A new KinematicViscosity instance with the results.
   */
  pow(kinematicViscosity) {
    return new KinematicViscosity(super.internalPow(this.value, kinematicViscosity.BaseValue));
  }
}
exports.KinematicViscosity = KinematicViscosity;