"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Mass = exports.MassUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MassUnits enumeration */
var MassUnits;
(function (MassUnits) {
  /** */
  MassUnits["Grams"] = "Gram";
  /** */
  MassUnits["Tonnes"] = "Tonne";
  /** The short ton is a unit of mass equal to 2,000 pounds (907.18474 kg), that is most commonly used in the United States – known there simply as the ton. */
  MassUnits["ShortTons"] = "ShortTon";
  /** Long ton (weight ton or Imperial ton) is a unit of mass equal to 2,240 pounds (1,016 kg) and is the name for the unit called the "ton" in the avoirdupois or Imperial system of measurements that was used in the United Kingdom and several other Commonwealth countries before metrication. */
  MassUnits["LongTons"] = "LongTon";
  /** The pound or pound-mass (abbreviations: lb, lbm) is a unit of mass used in the imperial, United States customary and other systems of measurement. A number of different definitions have been used, the most common today being the international avoirdupois pound which is legally defined as exactly 0.45359237 kilograms, and which is divided into 16 avoirdupois ounces. */
  MassUnits["Pounds"] = "Pound";
  /** The international avoirdupois ounce (abbreviated oz) is defined as exactly 28.349523125 g under the international yard and pound agreement of 1959, signed by the United States and countries of the Commonwealth of Nations. 16 oz make up an avoirdupois pound. */
  MassUnits["Ounces"] = "Ounce";
  /** The slug (abbreviation slug) is a unit of mass that is accelerated by 1 ft/s² when a force of one pound (lbf) is exerted on it. */
  MassUnits["Slugs"] = "Slug";
  /** The stone (abbreviation st) is a unit of mass equal to 14 pounds avoirdupois (about 6.35 kilograms) used in Great Britain and Ireland for measuring human body weight. */
  MassUnits["Stone"] = "Stone";
  /** The short hundredweight (abbreviation cwt) is a unit of mass equal to 100 pounds in US and Canada. In British English, the short hundredweight is referred to as the "cental". */
  MassUnits["ShortHundredweight"] = "ShortHundredweight";
  /** The long or imperial hundredweight (abbreviation cwt) is a unit of mass equal to 112 pounds in US and Canada. */
  MassUnits["LongHundredweight"] = "LongHundredweight";
  /** A grain is a unit of measurement of mass, and in the troy weight, avoirdupois, and Apothecaries' system, equal to exactly 64.79891 milligrams. */
  MassUnits["Grains"] = "Grain";
  /** Solar mass is a ratio unit to the mass of the solar system star, the sun. */
  MassUnits["SolarMasses"] = "SolarMass";
  /** Earth mass is a ratio unit to the mass of planet Earth. */
  MassUnits["EarthMasses"] = "EarthMass";
  /** */
  MassUnits["Femtograms"] = "Femtogram";
  /** */
  MassUnits["Picograms"] = "Picogram";
  /** */
  MassUnits["Nanograms"] = "Nanogram";
  /** */
  MassUnits["Micrograms"] = "Microgram";
  /** */
  MassUnits["Milligrams"] = "Milligram";
  /** */
  MassUnits["Centigrams"] = "Centigram";
  /** */
  MassUnits["Decigrams"] = "Decigram";
  /** */
  MassUnits["Decagrams"] = "Decagram";
  /** */
  MassUnits["Hectograms"] = "Hectogram";
  /** */
  MassUnits["Kilograms"] = "Kilogram";
  /** */
  MassUnits["Kilotonnes"] = "Kilotonne";
  /** */
  MassUnits["Megatonnes"] = "Megatonne";
  /** */
  MassUnits["Kilopounds"] = "Kilopound";
  /** */
  MassUnits["Megapounds"] = "Megapound";
})(MassUnits = exports.MassUnits || (exports.MassUnits = {}));
/** In physics, mass (from Greek μᾶζα "barley cake, lump [of dough]") is a property of a physical system or body, giving rise to the phenomena of the body's resistance to being accelerated by a force and the strength of its mutual gravitational attraction with other bodies. Instruments such as mass balances or scales use those phenomena to measure mass. The SI unit of mass is the kilogram (kg). */
class Mass extends base_unit_1.BaseUnit {
  /**
   * Create a new Mass.
   * @param value The value.
   * @param fromUnit The ‘Mass’ unit to create from.
   * The default unit is Kilograms
   */
  constructor(value, fromUnit = MassUnits.Kilograms) {
    super();
    this.gramsLazy = null;
    this.tonnesLazy = null;
    this.shorttonsLazy = null;
    this.longtonsLazy = null;
    this.poundsLazy = null;
    this.ouncesLazy = null;
    this.slugsLazy = null;
    this.stoneLazy = null;
    this.shorthundredweightLazy = null;
    this.longhundredweightLazy = null;
    this.grainsLazy = null;
    this.solarmassesLazy = null;
    this.earthmassesLazy = null;
    this.femtogramsLazy = null;
    this.picogramsLazy = null;
    this.nanogramsLazy = null;
    this.microgramsLazy = null;
    this.milligramsLazy = null;
    this.centigramsLazy = null;
    this.decigramsLazy = null;
    this.decagramsLazy = null;
    this.hectogramsLazy = null;
    this.kilogramsLazy = null;
    this.kilotonnesLazy = null;
    this.megatonnesLazy = null;
    this.kilopoundsLazy = null;
    this.megapoundsLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Mass is Kilograms.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MassUnits.Kilograms;
  }
  /** */
  get Grams() {
    if (this.gramsLazy !== null) {
      return this.gramsLazy;
    }
    return this.gramsLazy = this.convertFromBase(MassUnits.Grams);
  }
  /** */
  get Tonnes() {
    if (this.tonnesLazy !== null) {
      return this.tonnesLazy;
    }
    return this.tonnesLazy = this.convertFromBase(MassUnits.Tonnes);
  }
  /** The short ton is a unit of mass equal to 2,000 pounds (907.18474 kg), that is most commonly used in the United States – known there simply as the ton. */
  get ShortTons() {
    if (this.shorttonsLazy !== null) {
      return this.shorttonsLazy;
    }
    return this.shorttonsLazy = this.convertFromBase(MassUnits.ShortTons);
  }
  /** Long ton (weight ton or Imperial ton) is a unit of mass equal to 2,240 pounds (1,016 kg) and is the name for the unit called the "ton" in the avoirdupois or Imperial system of measurements that was used in the United Kingdom and several other Commonwealth countries before metrication. */
  get LongTons() {
    if (this.longtonsLazy !== null) {
      return this.longtonsLazy;
    }
    return this.longtonsLazy = this.convertFromBase(MassUnits.LongTons);
  }
  /** The pound or pound-mass (abbreviations: lb, lbm) is a unit of mass used in the imperial, United States customary and other systems of measurement. A number of different definitions have been used, the most common today being the international avoirdupois pound which is legally defined as exactly 0.45359237 kilograms, and which is divided into 16 avoirdupois ounces. */
  get Pounds() {
    if (this.poundsLazy !== null) {
      return this.poundsLazy;
    }
    return this.poundsLazy = this.convertFromBase(MassUnits.Pounds);
  }
  /** The international avoirdupois ounce (abbreviated oz) is defined as exactly 28.349523125 g under the international yard and pound agreement of 1959, signed by the United States and countries of the Commonwealth of Nations. 16 oz make up an avoirdupois pound. */
  get Ounces() {
    if (this.ouncesLazy !== null) {
      return this.ouncesLazy;
    }
    return this.ouncesLazy = this.convertFromBase(MassUnits.Ounces);
  }
  /** The slug (abbreviation slug) is a unit of mass that is accelerated by 1 ft/s² when a force of one pound (lbf) is exerted on it. */
  get Slugs() {
    if (this.slugsLazy !== null) {
      return this.slugsLazy;
    }
    return this.slugsLazy = this.convertFromBase(MassUnits.Slugs);
  }
  /** The stone (abbreviation st) is a unit of mass equal to 14 pounds avoirdupois (about 6.35 kilograms) used in Great Britain and Ireland for measuring human body weight. */
  get Stone() {
    if (this.stoneLazy !== null) {
      return this.stoneLazy;
    }
    return this.stoneLazy = this.convertFromBase(MassUnits.Stone);
  }
  /** The short hundredweight (abbreviation cwt) is a unit of mass equal to 100 pounds in US and Canada. In British English, the short hundredweight is referred to as the "cental". */
  get ShortHundredweight() {
    if (this.shorthundredweightLazy !== null) {
      return this.shorthundredweightLazy;
    }
    return this.shorthundredweightLazy = this.convertFromBase(MassUnits.ShortHundredweight);
  }
  /** The long or imperial hundredweight (abbreviation cwt) is a unit of mass equal to 112 pounds in US and Canada. */
  get LongHundredweight() {
    if (this.longhundredweightLazy !== null) {
      return this.longhundredweightLazy;
    }
    return this.longhundredweightLazy = this.convertFromBase(MassUnits.LongHundredweight);
  }
  /** A grain is a unit of measurement of mass, and in the troy weight, avoirdupois, and Apothecaries' system, equal to exactly 64.79891 milligrams. */
  get Grains() {
    if (this.grainsLazy !== null) {
      return this.grainsLazy;
    }
    return this.grainsLazy = this.convertFromBase(MassUnits.Grains);
  }
  /** Solar mass is a ratio unit to the mass of the solar system star, the sun. */
  get SolarMasses() {
    if (this.solarmassesLazy !== null) {
      return this.solarmassesLazy;
    }
    return this.solarmassesLazy = this.convertFromBase(MassUnits.SolarMasses);
  }
  /** Earth mass is a ratio unit to the mass of planet Earth. */
  get EarthMasses() {
    if (this.earthmassesLazy !== null) {
      return this.earthmassesLazy;
    }
    return this.earthmassesLazy = this.convertFromBase(MassUnits.EarthMasses);
  }
  /** */
  get Femtograms() {
    if (this.femtogramsLazy !== null) {
      return this.femtogramsLazy;
    }
    return this.femtogramsLazy = this.convertFromBase(MassUnits.Femtograms);
  }
  /** */
  get Picograms() {
    if (this.picogramsLazy !== null) {
      return this.picogramsLazy;
    }
    return this.picogramsLazy = this.convertFromBase(MassUnits.Picograms);
  }
  /** */
  get Nanograms() {
    if (this.nanogramsLazy !== null) {
      return this.nanogramsLazy;
    }
    return this.nanogramsLazy = this.convertFromBase(MassUnits.Nanograms);
  }
  /** */
  get Micrograms() {
    if (this.microgramsLazy !== null) {
      return this.microgramsLazy;
    }
    return this.microgramsLazy = this.convertFromBase(MassUnits.Micrograms);
  }
  /** */
  get Milligrams() {
    if (this.milligramsLazy !== null) {
      return this.milligramsLazy;
    }
    return this.milligramsLazy = this.convertFromBase(MassUnits.Milligrams);
  }
  /** */
  get Centigrams() {
    if (this.centigramsLazy !== null) {
      return this.centigramsLazy;
    }
    return this.centigramsLazy = this.convertFromBase(MassUnits.Centigrams);
  }
  /** */
  get Decigrams() {
    if (this.decigramsLazy !== null) {
      return this.decigramsLazy;
    }
    return this.decigramsLazy = this.convertFromBase(MassUnits.Decigrams);
  }
  /** */
  get Decagrams() {
    if (this.decagramsLazy !== null) {
      return this.decagramsLazy;
    }
    return this.decagramsLazy = this.convertFromBase(MassUnits.Decagrams);
  }
  /** */
  get Hectograms() {
    if (this.hectogramsLazy !== null) {
      return this.hectogramsLazy;
    }
    return this.hectogramsLazy = this.convertFromBase(MassUnits.Hectograms);
  }
  /** */
  get Kilograms() {
    if (this.kilogramsLazy !== null) {
      return this.kilogramsLazy;
    }
    return this.kilogramsLazy = this.convertFromBase(MassUnits.Kilograms);
  }
  /** */
  get Kilotonnes() {
    if (this.kilotonnesLazy !== null) {
      return this.kilotonnesLazy;
    }
    return this.kilotonnesLazy = this.convertFromBase(MassUnits.Kilotonnes);
  }
  /** */
  get Megatonnes() {
    if (this.megatonnesLazy !== null) {
      return this.megatonnesLazy;
    }
    return this.megatonnesLazy = this.convertFromBase(MassUnits.Megatonnes);
  }
  /** */
  get Kilopounds() {
    if (this.kilopoundsLazy !== null) {
      return this.kilopoundsLazy;
    }
    return this.kilopoundsLazy = this.convertFromBase(MassUnits.Kilopounds);
  }
  /** */
  get Megapounds() {
    if (this.megapoundsLazy !== null) {
      return this.megapoundsLazy;
    }
    return this.megapoundsLazy = this.convertFromBase(MassUnits.Megapounds);
  }
  /**
   * Create a new Mass instance from a Grams
   *
   * @param value The unit as Grams to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromGrams(value) {
    return new Mass(value, MassUnits.Grams);
  }
  /**
   * Create a new Mass instance from a Tonnes
   *
   * @param value The unit as Tonnes to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromTonnes(value) {
    return new Mass(value, MassUnits.Tonnes);
  }
  /**
   * Create a new Mass instance from a ShortTons
   * The short ton is a unit of mass equal to 2,000 pounds (907.18474 kg), that is most commonly used in the United States – known there simply as the ton.
   * @param value The unit as ShortTons to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromShortTons(value) {
    return new Mass(value, MassUnits.ShortTons);
  }
  /**
   * Create a new Mass instance from a LongTons
   * Long ton (weight ton or Imperial ton) is a unit of mass equal to 2,240 pounds (1,016 kg) and is the name for the unit called the "ton" in the avoirdupois or Imperial system of measurements that was used in the United Kingdom and several other Commonwealth countries before metrication.
   * @param value The unit as LongTons to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromLongTons(value) {
    return new Mass(value, MassUnits.LongTons);
  }
  /**
   * Create a new Mass instance from a Pounds
   * The pound or pound-mass (abbreviations: lb, lbm) is a unit of mass used in the imperial, United States customary and other systems of measurement. A number of different definitions have been used, the most common today being the international avoirdupois pound which is legally defined as exactly 0.45359237 kilograms, and which is divided into 16 avoirdupois ounces.
   * @param value The unit as Pounds to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromPounds(value) {
    return new Mass(value, MassUnits.Pounds);
  }
  /**
   * Create a new Mass instance from a Ounces
   * The international avoirdupois ounce (abbreviated oz) is defined as exactly 28.349523125 g under the international yard and pound agreement of 1959, signed by the United States and countries of the Commonwealth of Nations. 16 oz make up an avoirdupois pound.
   * @param value The unit as Ounces to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromOunces(value) {
    return new Mass(value, MassUnits.Ounces);
  }
  /**
   * Create a new Mass instance from a Slugs
   * The slug (abbreviation slug) is a unit of mass that is accelerated by 1 ft/s² when a force of one pound (lbf) is exerted on it.
   * @param value The unit as Slugs to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromSlugs(value) {
    return new Mass(value, MassUnits.Slugs);
  }
  /**
   * Create a new Mass instance from a Stone
   * The stone (abbreviation st) is a unit of mass equal to 14 pounds avoirdupois (about 6.35 kilograms) used in Great Britain and Ireland for measuring human body weight.
   * @param value The unit as Stone to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromStone(value) {
    return new Mass(value, MassUnits.Stone);
  }
  /**
   * Create a new Mass instance from a ShortHundredweight
   * The short hundredweight (abbreviation cwt) is a unit of mass equal to 100 pounds in US and Canada. In British English, the short hundredweight is referred to as the "cental".
   * @param value The unit as ShortHundredweight to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromShortHundredweight(value) {
    return new Mass(value, MassUnits.ShortHundredweight);
  }
  /**
   * Create a new Mass instance from a LongHundredweight
   * The long or imperial hundredweight (abbreviation cwt) is a unit of mass equal to 112 pounds in US and Canada.
   * @param value The unit as LongHundredweight to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromLongHundredweight(value) {
    return new Mass(value, MassUnits.LongHundredweight);
  }
  /**
   * Create a new Mass instance from a Grains
   * A grain is a unit of measurement of mass, and in the troy weight, avoirdupois, and Apothecaries' system, equal to exactly 64.79891 milligrams.
   * @param value The unit as Grains to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromGrains(value) {
    return new Mass(value, MassUnits.Grains);
  }
  /**
   * Create a new Mass instance from a SolarMasses
   * Solar mass is a ratio unit to the mass of the solar system star, the sun.
   * @param value The unit as SolarMasses to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromSolarMasses(value) {
    return new Mass(value, MassUnits.SolarMasses);
  }
  /**
   * Create a new Mass instance from a EarthMasses
   * Earth mass is a ratio unit to the mass of planet Earth.
   * @param value The unit as EarthMasses to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromEarthMasses(value) {
    return new Mass(value, MassUnits.EarthMasses);
  }
  /**
   * Create a new Mass instance from a Femtograms
   *
   * @param value The unit as Femtograms to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromFemtograms(value) {
    return new Mass(value, MassUnits.Femtograms);
  }
  /**
   * Create a new Mass instance from a Picograms
   *
   * @param value The unit as Picograms to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromPicograms(value) {
    return new Mass(value, MassUnits.Picograms);
  }
  /**
   * Create a new Mass instance from a Nanograms
   *
   * @param value The unit as Nanograms to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromNanograms(value) {
    return new Mass(value, MassUnits.Nanograms);
  }
  /**
   * Create a new Mass instance from a Micrograms
   *
   * @param value The unit as Micrograms to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromMicrograms(value) {
    return new Mass(value, MassUnits.Micrograms);
  }
  /**
   * Create a new Mass instance from a Milligrams
   *
   * @param value The unit as Milligrams to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromMilligrams(value) {
    return new Mass(value, MassUnits.Milligrams);
  }
  /**
   * Create a new Mass instance from a Centigrams
   *
   * @param value The unit as Centigrams to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromCentigrams(value) {
    return new Mass(value, MassUnits.Centigrams);
  }
  /**
   * Create a new Mass instance from a Decigrams
   *
   * @param value The unit as Decigrams to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromDecigrams(value) {
    return new Mass(value, MassUnits.Decigrams);
  }
  /**
   * Create a new Mass instance from a Decagrams
   *
   * @param value The unit as Decagrams to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromDecagrams(value) {
    return new Mass(value, MassUnits.Decagrams);
  }
  /**
   * Create a new Mass instance from a Hectograms
   *
   * @param value The unit as Hectograms to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromHectograms(value) {
    return new Mass(value, MassUnits.Hectograms);
  }
  /**
   * Create a new Mass instance from a Kilograms
   *
   * @param value The unit as Kilograms to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromKilograms(value) {
    return new Mass(value, MassUnits.Kilograms);
  }
  /**
   * Create a new Mass instance from a Kilotonnes
   *
   * @param value The unit as Kilotonnes to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromKilotonnes(value) {
    return new Mass(value, MassUnits.Kilotonnes);
  }
  /**
   * Create a new Mass instance from a Megatonnes
   *
   * @param value The unit as Megatonnes to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromMegatonnes(value) {
    return new Mass(value, MassUnits.Megatonnes);
  }
  /**
   * Create a new Mass instance from a Kilopounds
   *
   * @param value The unit as Kilopounds to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromKilopounds(value) {
    return new Mass(value, MassUnits.Kilopounds);
  }
  /**
   * Create a new Mass instance from a Megapounds
   *
   * @param value The unit as Megapounds to create a new Mass from.
   * @returns The new Mass instance.
   */
  static FromMegapounds(value) {
    return new Mass(value, MassUnits.Megapounds);
  }
  /**
   * Gets the base unit enumeration associated with Mass
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MassUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MassUnits.Kilograms;
  }
  /**
   * Create API DTO represent a Mass unit.
   * @param holdInUnit The specific Mass unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MassUnits.Kilograms) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Mass unit from an API DTO representation.
   * @param dtoMass The Mass API DTO representation
   */
  static FromDto(dtoMass) {
    return new Mass(dtoMass.value, dtoMass.unit);
  }
  /**
   * Convert Mass to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MassUnits.Grams:
        return this.Grams;
      case MassUnits.Tonnes:
        return this.Tonnes;
      case MassUnits.ShortTons:
        return this.ShortTons;
      case MassUnits.LongTons:
        return this.LongTons;
      case MassUnits.Pounds:
        return this.Pounds;
      case MassUnits.Ounces:
        return this.Ounces;
      case MassUnits.Slugs:
        return this.Slugs;
      case MassUnits.Stone:
        return this.Stone;
      case MassUnits.ShortHundredweight:
        return this.ShortHundredweight;
      case MassUnits.LongHundredweight:
        return this.LongHundredweight;
      case MassUnits.Grains:
        return this.Grains;
      case MassUnits.SolarMasses:
        return this.SolarMasses;
      case MassUnits.EarthMasses:
        return this.EarthMasses;
      case MassUnits.Femtograms:
        return this.Femtograms;
      case MassUnits.Picograms:
        return this.Picograms;
      case MassUnits.Nanograms:
        return this.Nanograms;
      case MassUnits.Micrograms:
        return this.Micrograms;
      case MassUnits.Milligrams:
        return this.Milligrams;
      case MassUnits.Centigrams:
        return this.Centigrams;
      case MassUnits.Decigrams:
        return this.Decigrams;
      case MassUnits.Decagrams:
        return this.Decagrams;
      case MassUnits.Hectograms:
        return this.Hectograms;
      case MassUnits.Kilograms:
        return this.Kilograms;
      case MassUnits.Kilotonnes:
        return this.Kilotonnes;
      case MassUnits.Megatonnes:
        return this.Megatonnes;
      case MassUnits.Kilopounds:
        return this.Kilopounds;
      case MassUnits.Megapounds:
        return this.Megapounds;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MassUnits.Grams:
        return super.internalMultiply(this.value, 1e3);
      case MassUnits.Tonnes:
        return super.internalDivide(this.value, 1e3);
      case MassUnits.ShortTons:
        return super.internalDivide(this.value, 9.0718474e2);
      case MassUnits.LongTons:
        return super.internalDivide(this.value, 1.0160469088e3);
      case MassUnits.Pounds:
        return super.internalDivide(this.value, 0.45359237);
      case MassUnits.Ounces:
        return super.internalDivide(this.value, 0.028349523125);
      case MassUnits.Slugs:
        return super.internalMultiply(this.value, 6.852176556196105e-2);
      case MassUnits.Stone:
        return super.internalMultiply(this.value, 0.1574731728702698);
      case MassUnits.ShortHundredweight:
        return super.internalMultiply(this.value, 0.022046226218487758);
      case MassUnits.LongHundredweight:
        return super.internalMultiply(this.value, 0.01968413055222121);
      case MassUnits.Grains:
        return super.internalMultiply(this.value, 15432.358352941431);
      case MassUnits.SolarMasses:
        return super.internalDivide(this.value, 1.98947e30);
      case MassUnits.EarthMasses:
        return super.internalDivide(this.value, 5.9722E+24);
      case MassUnits.Femtograms:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1e-15);
        }
      case MassUnits.Picograms:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1e-12);
        }
      case MassUnits.Nanograms:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1e-9);
        }
      case MassUnits.Micrograms:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.000001);
        }
      case MassUnits.Milligrams:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.001);
        }
      case MassUnits.Centigrams:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.01);
        }
      case MassUnits.Decigrams:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 0.1);
        }
      case MassUnits.Decagrams:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 10);
        }
      case MassUnits.Hectograms:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 100);
        }
      case MassUnits.Kilograms:
        {
          const v3 = super.internalMultiply(this.value, 1e3);
          return super.internalDivide(v3, 1000);
        }
      case MassUnits.Kilotonnes:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 1000);
        }
      case MassUnits.Megatonnes:
        {
          const v3 = super.internalDivide(this.value, 1e3);
          return super.internalDivide(v3, 1000000);
        }
      case MassUnits.Kilopounds:
        {
          const v3 = super.internalDivide(this.value, 0.45359237);
          return super.internalDivide(v3, 1000);
        }
      case MassUnits.Megapounds:
        {
          const v3 = super.internalDivide(this.value, 0.45359237);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MassUnits.Grams:
        return this.value * 1e3;
      case MassUnits.Tonnes:
        return this.value / 1e3;
      case MassUnits.ShortTons:
        return this.value / 9.0718474e2;
      case MassUnits.LongTons:
        return this.value / 1.0160469088e3;
      case MassUnits.Pounds:
        return this.value / 0.45359237;
      case MassUnits.Ounces:
        return this.value / 0.028349523125;
      case MassUnits.Slugs:
        return this.value * 6.852176556196105e-2;
      case MassUnits.Stone:
        return this.value * 0.1574731728702698;
      case MassUnits.ShortHundredweight:
        return this.value * 0.022046226218487758;
      case MassUnits.LongHundredweight:
        return this.value * 0.01968413055222121;
      case MassUnits.Grains:
        return this.value * 15432.358352941431;
      case MassUnits.SolarMasses:
        return this.value / 1.98947e30;
      case MassUnits.EarthMasses:
        return this.value / 5.9722E+24;
      case MassUnits.Femtograms:
        return this.value * 1e3 / 1e-15;
      case MassUnits.Picograms:
        return this.value * 1e3 / 1e-12;
      case MassUnits.Nanograms:
        return this.value * 1e3 / 1e-9;
      case MassUnits.Micrograms:
        return this.value * 1e3 / 0.000001;
      case MassUnits.Milligrams:
        return this.value * 1e3 / 0.001;
      case MassUnits.Centigrams:
        return this.value * 1e3 / 0.01;
      case MassUnits.Decigrams:
        return this.value * 1e3 / 0.1;
      case MassUnits.Decagrams:
        return this.value * 1e3 / 10;
      case MassUnits.Hectograms:
        return this.value * 1e3 / 100;
      case MassUnits.Kilograms:
        return this.value * 1e3 / 1000;
      case MassUnits.Kilotonnes:
        return this.value / 1e3 / 1000;
      case MassUnits.Megatonnes:
        return this.value / 1e3 / 1000000;
      case MassUnits.Kilopounds:
        return this.value / 0.45359237 / 1000;
      case MassUnits.Megapounds:
        return this.value / 0.45359237 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MassUnits.Grams:
        return super.internalDivide(value, 1e3);
      case MassUnits.Tonnes:
        return super.internalMultiply(value, 1e3);
      case MassUnits.ShortTons:
        return super.internalMultiply(value, 9.0718474e2);
      case MassUnits.LongTons:
        return super.internalMultiply(value, 1.0160469088e3);
      case MassUnits.Pounds:
        return super.internalMultiply(value, 0.45359237);
      case MassUnits.Ounces:
        return super.internalMultiply(value, 0.028349523125);
      case MassUnits.Slugs:
        return super.internalDivide(value, 6.852176556196105e-2);
      case MassUnits.Stone:
        return super.internalDivide(value, 0.1574731728702698);
      case MassUnits.ShortHundredweight:
        return super.internalDivide(value, 0.022046226218487758);
      case MassUnits.LongHundredweight:
        return super.internalDivide(value, 0.01968413055222121);
      case MassUnits.Grains:
        return super.internalDivide(value, 15432.358352941431);
      case MassUnits.SolarMasses:
        return super.internalMultiply(value, 1.98947e30);
      case MassUnits.EarthMasses:
        return super.internalMultiply(value, 5.9722E+24);
      case MassUnits.Femtograms:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1e-15);
        }
      case MassUnits.Picograms:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1e-12);
        }
      case MassUnits.Nanograms:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1e-9);
        }
      case MassUnits.Micrograms:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.000001);
        }
      case MassUnits.Milligrams:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.001);
        }
      case MassUnits.Centigrams:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.01);
        }
      case MassUnits.Decigrams:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 0.1);
        }
      case MassUnits.Decagrams:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 10);
        }
      case MassUnits.Hectograms:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 100);
        }
      case MassUnits.Kilograms:
        {
          const v3 = super.internalDivide(value, 1e3);
          return super.internalMultiply(v3, 1000);
        }
      case MassUnits.Kilotonnes:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 1000);
        }
      case MassUnits.Megatonnes:
        {
          const v3 = super.internalMultiply(value, 1e3);
          return super.internalMultiply(v3, 1000000);
        }
      case MassUnits.Kilopounds:
        {
          const v3 = super.internalMultiply(value, 0.45359237);
          return super.internalMultiply(v3, 1000);
        }
      case MassUnits.Megapounds:
        {
          const v3 = super.internalMultiply(value, 0.45359237);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MassUnits.Grams:
        return value / 1e3;
      case MassUnits.Tonnes:
        return value * 1e3;
      case MassUnits.ShortTons:
        return value * 9.0718474e2;
      case MassUnits.LongTons:
        return value * 1.0160469088e3;
      case MassUnits.Pounds:
        return value * 0.45359237;
      case MassUnits.Ounces:
        return value * 0.028349523125;
      case MassUnits.Slugs:
        return value / 6.852176556196105e-2;
      case MassUnits.Stone:
        return value / 0.1574731728702698;
      case MassUnits.ShortHundredweight:
        return value / 0.022046226218487758;
      case MassUnits.LongHundredweight:
        return value / 0.01968413055222121;
      case MassUnits.Grains:
        return value / 15432.358352941431;
      case MassUnits.SolarMasses:
        return value * 1.98947e30;
      case MassUnits.EarthMasses:
        return value * 5.9722E+24;
      case MassUnits.Femtograms:
        return value / 1e3 * 1e-15;
      case MassUnits.Picograms:
        return value / 1e3 * 1e-12;
      case MassUnits.Nanograms:
        return value / 1e3 * 1e-9;
      case MassUnits.Micrograms:
        return value / 1e3 * 0.000001;
      case MassUnits.Milligrams:
        return value / 1e3 * 0.001;
      case MassUnits.Centigrams:
        return value / 1e3 * 0.01;
      case MassUnits.Decigrams:
        return value / 1e3 * 0.1;
      case MassUnits.Decagrams:
        return value / 1e3 * 10;
      case MassUnits.Hectograms:
        return value / 1e3 * 100;
      case MassUnits.Kilograms:
        return value / 1e3 * 1000;
      case MassUnits.Kilotonnes:
        return value * 1e3 * 1000;
      case MassUnits.Megatonnes:
        return value * 1e3 * 1000000;
      case MassUnits.Kilopounds:
        return value * 0.45359237 * 1000;
      case MassUnits.Megapounds:
        return value * 0.45359237 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Mass to string.
   * Note! the default format for Mass is Kilograms.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Mass.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Mass.
   */
  toString(unit = MassUnits.Kilograms, fractionalDigits) {
    switch (unit) {
      case MassUnits.Grams:
        return super.truncateFractionDigits(this.Grams, fractionalDigits) + ` g`;
      case MassUnits.Tonnes:
        return super.truncateFractionDigits(this.Tonnes, fractionalDigits) + ` t`;
      case MassUnits.ShortTons:
        return super.truncateFractionDigits(this.ShortTons, fractionalDigits) + ` t (short)`;
      case MassUnits.LongTons:
        return super.truncateFractionDigits(this.LongTons, fractionalDigits) + ` long tn`;
      case MassUnits.Pounds:
        return super.truncateFractionDigits(this.Pounds, fractionalDigits) + ` lb`;
      case MassUnits.Ounces:
        return super.truncateFractionDigits(this.Ounces, fractionalDigits) + ` oz`;
      case MassUnits.Slugs:
        return super.truncateFractionDigits(this.Slugs, fractionalDigits) + ` slug`;
      case MassUnits.Stone:
        return super.truncateFractionDigits(this.Stone, fractionalDigits) + ` st`;
      case MassUnits.ShortHundredweight:
        return super.truncateFractionDigits(this.ShortHundredweight, fractionalDigits) + ` cwt`;
      case MassUnits.LongHundredweight:
        return super.truncateFractionDigits(this.LongHundredweight, fractionalDigits) + ` cwt`;
      case MassUnits.Grains:
        return super.truncateFractionDigits(this.Grains, fractionalDigits) + ` gr`;
      case MassUnits.SolarMasses:
        return super.truncateFractionDigits(this.SolarMasses, fractionalDigits) + ` M☉`;
      case MassUnits.EarthMasses:
        return super.truncateFractionDigits(this.EarthMasses, fractionalDigits) + ` em`;
      case MassUnits.Femtograms:
        return super.truncateFractionDigits(this.Femtograms, fractionalDigits) + ` fg`;
      case MassUnits.Picograms:
        return super.truncateFractionDigits(this.Picograms, fractionalDigits) + ` pg`;
      case MassUnits.Nanograms:
        return super.truncateFractionDigits(this.Nanograms, fractionalDigits) + ` ng`;
      case MassUnits.Micrograms:
        return super.truncateFractionDigits(this.Micrograms, fractionalDigits) + ` μg`;
      case MassUnits.Milligrams:
        return super.truncateFractionDigits(this.Milligrams, fractionalDigits) + ` mg`;
      case MassUnits.Centigrams:
        return super.truncateFractionDigits(this.Centigrams, fractionalDigits) + ` cg`;
      case MassUnits.Decigrams:
        return super.truncateFractionDigits(this.Decigrams, fractionalDigits) + ` dg`;
      case MassUnits.Decagrams:
        return super.truncateFractionDigits(this.Decagrams, fractionalDigits) + ` dag`;
      case MassUnits.Hectograms:
        return super.truncateFractionDigits(this.Hectograms, fractionalDigits) + ` hg`;
      case MassUnits.Kilograms:
        return super.truncateFractionDigits(this.Kilograms, fractionalDigits) + ` kg`;
      case MassUnits.Kilotonnes:
        return super.truncateFractionDigits(this.Kilotonnes, fractionalDigits) + ` kt`;
      case MassUnits.Megatonnes:
        return super.truncateFractionDigits(this.Megatonnes, fractionalDigits) + ` Mt`;
      case MassUnits.Kilopounds:
        return super.truncateFractionDigits(this.Kilopounds, fractionalDigits) + ` klb`;
      case MassUnits.Megapounds:
        return super.truncateFractionDigits(this.Megapounds, fractionalDigits) + ` Mlb`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Mass unit abbreviation.
   * Note! the default abbreviation for Mass is Kilograms.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Mass.
   * @returns The abbreviation string of Mass.
   */
  getUnitAbbreviation(unitAbbreviation = MassUnits.Kilograms) {
    switch (unitAbbreviation) {
      case MassUnits.Grams:
        return `g`;
      case MassUnits.Tonnes:
        return `t`;
      case MassUnits.ShortTons:
        return `t (short)`;
      case MassUnits.LongTons:
        return `long tn`;
      case MassUnits.Pounds:
        return `lb`;
      case MassUnits.Ounces:
        return `oz`;
      case MassUnits.Slugs:
        return `slug`;
      case MassUnits.Stone:
        return `st`;
      case MassUnits.ShortHundredweight:
        return `cwt`;
      case MassUnits.LongHundredweight:
        return `cwt`;
      case MassUnits.Grains:
        return `gr`;
      case MassUnits.SolarMasses:
        return `M☉`;
      case MassUnits.EarthMasses:
        return `em`;
      case MassUnits.Femtograms:
        return `fg`;
      case MassUnits.Picograms:
        return `pg`;
      case MassUnits.Nanograms:
        return `ng`;
      case MassUnits.Micrograms:
        return `μg`;
      case MassUnits.Milligrams:
        return `mg`;
      case MassUnits.Centigrams:
        return `cg`;
      case MassUnits.Decigrams:
        return `dg`;
      case MassUnits.Decagrams:
        return `dag`;
      case MassUnits.Hectograms:
        return `hg`;
      case MassUnits.Kilograms:
        return `kg`;
      case MassUnits.Kilotonnes:
        return `kt`;
      case MassUnits.Megatonnes:
        return `Mt`;
      case MassUnits.Kilopounds:
        return `klb`;
      case MassUnits.Megapounds:
        return `Mlb`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Mass are equals to the current Mass.
   * @param mass The other Mass.
   * @returns True if the given Mass are equal to the current Mass.
   */
  equals(mass) {
    return super.internalEquals(this.value, mass.BaseValue);
  }
  /**
   * Compare the given Mass against the current Mass.
   * @param mass The other Mass.
   * @returns 0 if they are equal, -1 if the current Mass is less then other, 1 if the current Mass is greater then other.
   */
  compareTo(mass) {
    return super.internalCompareTo(this.value, mass.BaseValue);
  }
  /**
   * Add the given Mass with the current Mass.
   * @param mass The other Mass.
   * @returns A new Mass instance with the results.
   */
  add(mass) {
    return new Mass(super.internalAdd(this.value, mass.BaseValue));
  }
  /**
   * Subtract the given Mass with the current Mass.
   * @param mass The other Mass.
   * @returns A new Mass instance with the results.
   */
  subtract(mass) {
    return new Mass(super.internalSubtract(this.value, mass.BaseValue));
  }
  /**
   * Multiply the given Mass with the current Mass.
   * @param mass The other Mass.
   * @returns A new Mass instance with the results.
   */
  multiply(mass) {
    return new Mass(super.internalMultiply(this.value, mass.BaseValue));
  }
  /**
   * Divide the given Mass with the current Mass.
   * @param mass The other Mass.
   * @returns A new Mass instance with the results.
   */
  divide(mass) {
    return new Mass(super.internalDivide(this.value, mass.BaseValue));
  }
  /**
   * Modulo the given Mass with the current Mass.
   * @param mass The other Mass.
   * @returns A new Mass instance with the results.
   */
  modulo(mass) {
    return new Mass(super.internalModulo(this.value, mass.BaseValue));
  }
  /**
   * Pow the given Mass with the current Mass.
   * @param mass The other Mass.
   * @returns A new Mass instance with the results.
   */
  pow(mass) {
    return new Mass(super.internalPow(this.value, mass.BaseValue));
  }
}
exports.Mass = Mass;