"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApparentPower = exports.ApparentPowerUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ApparentPowerUnits enumeration */
var ApparentPowerUnits;
(function (ApparentPowerUnits) {
  /** */
  ApparentPowerUnits["Voltamperes"] = "Voltampere";
  /** */
  ApparentPowerUnits["Microvoltamperes"] = "Microvoltampere";
  /** */
  ApparentPowerUnits["Millivoltamperes"] = "Millivoltampere";
  /** */
  ApparentPowerUnits["Kilovoltamperes"] = "Kilovoltampere";
  /** */
  ApparentPowerUnits["Megavoltamperes"] = "Megavoltampere";
  /** */
  ApparentPowerUnits["Gigavoltamperes"] = "Gigavoltampere";
})(ApparentPowerUnits = exports.ApparentPowerUnits || (exports.ApparentPowerUnits = {}));
/** Power engineers measure apparent power as the magnitude of the vector sum of active and reactive power. Apparent power is the product of the root-mean-square of voltage and current. */
class ApparentPower extends base_unit_1.BaseUnit {
  /**
   * Create a new ApparentPower.
   * @param value The value.
   * @param fromUnit The ‘ApparentPower’ unit to create from.
   * The default unit is Voltamperes
   */
  constructor(value, fromUnit = ApparentPowerUnits.Voltamperes) {
    super();
    this.voltamperesLazy = null;
    this.microvoltamperesLazy = null;
    this.millivoltamperesLazy = null;
    this.kilovoltamperesLazy = null;
    this.megavoltamperesLazy = null;
    this.gigavoltamperesLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ApparentPower is Voltamperes.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ApparentPowerUnits.Voltamperes;
  }
  /** */
  get Voltamperes() {
    if (this.voltamperesLazy !== null) {
      return this.voltamperesLazy;
    }
    return this.voltamperesLazy = this.convertFromBase(ApparentPowerUnits.Voltamperes);
  }
  /** */
  get Microvoltamperes() {
    if (this.microvoltamperesLazy !== null) {
      return this.microvoltamperesLazy;
    }
    return this.microvoltamperesLazy = this.convertFromBase(ApparentPowerUnits.Microvoltamperes);
  }
  /** */
  get Millivoltamperes() {
    if (this.millivoltamperesLazy !== null) {
      return this.millivoltamperesLazy;
    }
    return this.millivoltamperesLazy = this.convertFromBase(ApparentPowerUnits.Millivoltamperes);
  }
  /** */
  get Kilovoltamperes() {
    if (this.kilovoltamperesLazy !== null) {
      return this.kilovoltamperesLazy;
    }
    return this.kilovoltamperesLazy = this.convertFromBase(ApparentPowerUnits.Kilovoltamperes);
  }
  /** */
  get Megavoltamperes() {
    if (this.megavoltamperesLazy !== null) {
      return this.megavoltamperesLazy;
    }
    return this.megavoltamperesLazy = this.convertFromBase(ApparentPowerUnits.Megavoltamperes);
  }
  /** */
  get Gigavoltamperes() {
    if (this.gigavoltamperesLazy !== null) {
      return this.gigavoltamperesLazy;
    }
    return this.gigavoltamperesLazy = this.convertFromBase(ApparentPowerUnits.Gigavoltamperes);
  }
  /**
   * Create a new ApparentPower instance from a Voltamperes
   *
   * @param value The unit as Voltamperes to create a new ApparentPower from.
   * @returns The new ApparentPower instance.
   */
  static FromVoltamperes(value) {
    return new ApparentPower(value, ApparentPowerUnits.Voltamperes);
  }
  /**
   * Create a new ApparentPower instance from a Microvoltamperes
   *
   * @param value The unit as Microvoltamperes to create a new ApparentPower from.
   * @returns The new ApparentPower instance.
   */
  static FromMicrovoltamperes(value) {
    return new ApparentPower(value, ApparentPowerUnits.Microvoltamperes);
  }
  /**
   * Create a new ApparentPower instance from a Millivoltamperes
   *
   * @param value The unit as Millivoltamperes to create a new ApparentPower from.
   * @returns The new ApparentPower instance.
   */
  static FromMillivoltamperes(value) {
    return new ApparentPower(value, ApparentPowerUnits.Millivoltamperes);
  }
  /**
   * Create a new ApparentPower instance from a Kilovoltamperes
   *
   * @param value The unit as Kilovoltamperes to create a new ApparentPower from.
   * @returns The new ApparentPower instance.
   */
  static FromKilovoltamperes(value) {
    return new ApparentPower(value, ApparentPowerUnits.Kilovoltamperes);
  }
  /**
   * Create a new ApparentPower instance from a Megavoltamperes
   *
   * @param value The unit as Megavoltamperes to create a new ApparentPower from.
   * @returns The new ApparentPower instance.
   */
  static FromMegavoltamperes(value) {
    return new ApparentPower(value, ApparentPowerUnits.Megavoltamperes);
  }
  /**
   * Create a new ApparentPower instance from a Gigavoltamperes
   *
   * @param value The unit as Gigavoltamperes to create a new ApparentPower from.
   * @returns The new ApparentPower instance.
   */
  static FromGigavoltamperes(value) {
    return new ApparentPower(value, ApparentPowerUnits.Gigavoltamperes);
  }
  /**
   * Gets the base unit enumeration associated with ApparentPower
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ApparentPowerUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ApparentPowerUnits.Voltamperes;
  }
  /**
   * Create API DTO represent a ApparentPower unit.
   * @param holdInUnit The specific ApparentPower unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ApparentPowerUnits.Voltamperes) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ApparentPower unit from an API DTO representation.
   * @param dtoApparentPower The ApparentPower API DTO representation
   */
  static FromDto(dtoApparentPower) {
    return new ApparentPower(dtoApparentPower.value, dtoApparentPower.unit);
  }
  /**
   * Convert ApparentPower to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ApparentPowerUnits.Voltamperes:
        return this.Voltamperes;
      case ApparentPowerUnits.Microvoltamperes:
        return this.Microvoltamperes;
      case ApparentPowerUnits.Millivoltamperes:
        return this.Millivoltamperes;
      case ApparentPowerUnits.Kilovoltamperes:
        return this.Kilovoltamperes;
      case ApparentPowerUnits.Megavoltamperes:
        return this.Megavoltamperes;
      case ApparentPowerUnits.Gigavoltamperes:
        return this.Gigavoltamperes;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ApparentPowerUnits.Voltamperes:
        return this.value;
      case ApparentPowerUnits.Microvoltamperes:
        return super.internalDivide(this.value, 0.000001);
      case ApparentPowerUnits.Millivoltamperes:
        return super.internalDivide(this.value, 0.001);
      case ApparentPowerUnits.Kilovoltamperes:
        return super.internalDivide(this.value, 1000);
      case ApparentPowerUnits.Megavoltamperes:
        return super.internalDivide(this.value, 1000000);
      case ApparentPowerUnits.Gigavoltamperes:
        return super.internalDivide(this.value, 1000000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ApparentPowerUnits.Voltamperes:
        return this.value;
      case ApparentPowerUnits.Microvoltamperes:
        return this.value / 0.000001;
      case ApparentPowerUnits.Millivoltamperes:
        return this.value / 0.001;
      case ApparentPowerUnits.Kilovoltamperes:
        return this.value / 1000;
      case ApparentPowerUnits.Megavoltamperes:
        return this.value / 1000000;
      case ApparentPowerUnits.Gigavoltamperes:
        return this.value / 1000000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ApparentPowerUnits.Voltamperes:
        return value;
      case ApparentPowerUnits.Microvoltamperes:
        return super.internalMultiply(value, 0.000001);
      case ApparentPowerUnits.Millivoltamperes:
        return super.internalMultiply(value, 0.001);
      case ApparentPowerUnits.Kilovoltamperes:
        return super.internalMultiply(value, 1000);
      case ApparentPowerUnits.Megavoltamperes:
        return super.internalMultiply(value, 1000000);
      case ApparentPowerUnits.Gigavoltamperes:
        return super.internalMultiply(value, 1000000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ApparentPowerUnits.Voltamperes:
        return value;
      case ApparentPowerUnits.Microvoltamperes:
        return value * 0.000001;
      case ApparentPowerUnits.Millivoltamperes:
        return value * 0.001;
      case ApparentPowerUnits.Kilovoltamperes:
        return value * 1000;
      case ApparentPowerUnits.Megavoltamperes:
        return value * 1000000;
      case ApparentPowerUnits.Gigavoltamperes:
        return value * 1000000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ApparentPower to string.
   * Note! the default format for ApparentPower is Voltamperes.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ApparentPower.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ApparentPower.
   */
  toString(unit = ApparentPowerUnits.Voltamperes, fractionalDigits) {
    switch (unit) {
      case ApparentPowerUnits.Voltamperes:
        return super.truncateFractionDigits(this.Voltamperes, fractionalDigits) + ` VA`;
      case ApparentPowerUnits.Microvoltamperes:
        return super.truncateFractionDigits(this.Microvoltamperes, fractionalDigits) + ` μVA`;
      case ApparentPowerUnits.Millivoltamperes:
        return super.truncateFractionDigits(this.Millivoltamperes, fractionalDigits) + ` mVA`;
      case ApparentPowerUnits.Kilovoltamperes:
        return super.truncateFractionDigits(this.Kilovoltamperes, fractionalDigits) + ` kVA`;
      case ApparentPowerUnits.Megavoltamperes:
        return super.truncateFractionDigits(this.Megavoltamperes, fractionalDigits) + ` MVA`;
      case ApparentPowerUnits.Gigavoltamperes:
        return super.truncateFractionDigits(this.Gigavoltamperes, fractionalDigits) + ` GVA`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ApparentPower unit abbreviation.
   * Note! the default abbreviation for ApparentPower is Voltamperes.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ApparentPower.
   * @returns The abbreviation string of ApparentPower.
   */
  getUnitAbbreviation(unitAbbreviation = ApparentPowerUnits.Voltamperes) {
    switch (unitAbbreviation) {
      case ApparentPowerUnits.Voltamperes:
        return `VA`;
      case ApparentPowerUnits.Microvoltamperes:
        return `μVA`;
      case ApparentPowerUnits.Millivoltamperes:
        return `mVA`;
      case ApparentPowerUnits.Kilovoltamperes:
        return `kVA`;
      case ApparentPowerUnits.Megavoltamperes:
        return `MVA`;
      case ApparentPowerUnits.Gigavoltamperes:
        return `GVA`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ApparentPower are equals to the current ApparentPower.
   * @param apparentPower The other ApparentPower.
   * @returns True if the given ApparentPower are equal to the current ApparentPower.
   */
  equals(apparentPower) {
    return super.internalEquals(this.value, apparentPower.BaseValue);
  }
  /**
   * Compare the given ApparentPower against the current ApparentPower.
   * @param apparentPower The other ApparentPower.
   * @returns 0 if they are equal, -1 if the current ApparentPower is less then other, 1 if the current ApparentPower is greater then other.
   */
  compareTo(apparentPower) {
    return super.internalCompareTo(this.value, apparentPower.BaseValue);
  }
  /**
   * Add the given ApparentPower with the current ApparentPower.
   * @param apparentPower The other ApparentPower.
   * @returns A new ApparentPower instance with the results.
   */
  add(apparentPower) {
    return new ApparentPower(super.internalAdd(this.value, apparentPower.BaseValue));
  }
  /**
   * Subtract the given ApparentPower with the current ApparentPower.
   * @param apparentPower The other ApparentPower.
   * @returns A new ApparentPower instance with the results.
   */
  subtract(apparentPower) {
    return new ApparentPower(super.internalSubtract(this.value, apparentPower.BaseValue));
  }
  /**
   * Multiply the given ApparentPower with the current ApparentPower.
   * @param apparentPower The other ApparentPower.
   * @returns A new ApparentPower instance with the results.
   */
  multiply(apparentPower) {
    return new ApparentPower(super.internalMultiply(this.value, apparentPower.BaseValue));
  }
  /**
   * Divide the given ApparentPower with the current ApparentPower.
   * @param apparentPower The other ApparentPower.
   * @returns A new ApparentPower instance with the results.
   */
  divide(apparentPower) {
    return new ApparentPower(super.internalDivide(this.value, apparentPower.BaseValue));
  }
  /**
   * Modulo the given ApparentPower with the current ApparentPower.
   * @param apparentPower The other ApparentPower.
   * @returns A new ApparentPower instance with the results.
   */
  modulo(apparentPower) {
    return new ApparentPower(super.internalModulo(this.value, apparentPower.BaseValue));
  }
  /**
   * Pow the given ApparentPower with the current ApparentPower.
   * @param apparentPower The other ApparentPower.
   * @returns A new ApparentPower instance with the results.
   */
  pow(apparentPower) {
    return new ApparentPower(super.internalPow(this.value, apparentPower.BaseValue));
  }
}
exports.ApparentPower = ApparentPower;