"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Angle = exports.AngleUnits = void 0;
const base_unit_1 = require("../base-unit");
/** AngleUnits enumeration */
var AngleUnits;
(function (AngleUnits) {
  /** */
  AngleUnits["Radians"] = "Radian";
  /** */
  AngleUnits["Degrees"] = "Degree";
  /** */
  AngleUnits["Arcminutes"] = "Arcminute";
  /** */
  AngleUnits["Arcseconds"] = "Arcsecond";
  /** */
  AngleUnits["Gradians"] = "Gradian";
  /** */
  AngleUnits["NatoMils"] = "NatoMil";
  /** */
  AngleUnits["Revolutions"] = "Revolution";
  /** */
  AngleUnits["Tilt"] = "Tilt";
  /** */
  AngleUnits["Nanoradians"] = "Nanoradian";
  /** */
  AngleUnits["Microradians"] = "Microradian";
  /** */
  AngleUnits["Milliradians"] = "Milliradian";
  /** */
  AngleUnits["Centiradians"] = "Centiradian";
  /** */
  AngleUnits["Deciradians"] = "Deciradian";
  /** */
  AngleUnits["Nanodegrees"] = "Nanodegree";
  /** */
  AngleUnits["Microdegrees"] = "Microdegree";
  /** */
  AngleUnits["Millidegrees"] = "Millidegree";
})(AngleUnits = exports.AngleUnits || (exports.AngleUnits = {}));
/** In geometry, an angle is the figure formed by two rays, called the sides of the angle, sharing a common endpoint, called the vertex of the angle. */
class Angle extends base_unit_1.BaseUnit {
  /**
   * Create a new Angle.
   * @param value The value.
   * @param fromUnit The ‘Angle’ unit to create from.
   * The default unit is Degrees
   */
  constructor(value, fromUnit = AngleUnits.Degrees) {
    super();
    this.radiansLazy = null;
    this.degreesLazy = null;
    this.arcminutesLazy = null;
    this.arcsecondsLazy = null;
    this.gradiansLazy = null;
    this.natomilsLazy = null;
    this.revolutionsLazy = null;
    this.tiltLazy = null;
    this.nanoradiansLazy = null;
    this.microradiansLazy = null;
    this.milliradiansLazy = null;
    this.centiradiansLazy = null;
    this.deciradiansLazy = null;
    this.nanodegreesLazy = null;
    this.microdegreesLazy = null;
    this.millidegreesLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Angle is Degrees.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return AngleUnits.Degrees;
  }
  /** */
  get Radians() {
    if (this.radiansLazy !== null) {
      return this.radiansLazy;
    }
    return this.radiansLazy = this.convertFromBase(AngleUnits.Radians);
  }
  /** */
  get Degrees() {
    if (this.degreesLazy !== null) {
      return this.degreesLazy;
    }
    return this.degreesLazy = this.convertFromBase(AngleUnits.Degrees);
  }
  /** */
  get Arcminutes() {
    if (this.arcminutesLazy !== null) {
      return this.arcminutesLazy;
    }
    return this.arcminutesLazy = this.convertFromBase(AngleUnits.Arcminutes);
  }
  /** */
  get Arcseconds() {
    if (this.arcsecondsLazy !== null) {
      return this.arcsecondsLazy;
    }
    return this.arcsecondsLazy = this.convertFromBase(AngleUnits.Arcseconds);
  }
  /** */
  get Gradians() {
    if (this.gradiansLazy !== null) {
      return this.gradiansLazy;
    }
    return this.gradiansLazy = this.convertFromBase(AngleUnits.Gradians);
  }
  /** */
  get NatoMils() {
    if (this.natomilsLazy !== null) {
      return this.natomilsLazy;
    }
    return this.natomilsLazy = this.convertFromBase(AngleUnits.NatoMils);
  }
  /** */
  get Revolutions() {
    if (this.revolutionsLazy !== null) {
      return this.revolutionsLazy;
    }
    return this.revolutionsLazy = this.convertFromBase(AngleUnits.Revolutions);
  }
  /** */
  get Tilt() {
    if (this.tiltLazy !== null) {
      return this.tiltLazy;
    }
    return this.tiltLazy = this.convertFromBase(AngleUnits.Tilt);
  }
  /** */
  get Nanoradians() {
    if (this.nanoradiansLazy !== null) {
      return this.nanoradiansLazy;
    }
    return this.nanoradiansLazy = this.convertFromBase(AngleUnits.Nanoradians);
  }
  /** */
  get Microradians() {
    if (this.microradiansLazy !== null) {
      return this.microradiansLazy;
    }
    return this.microradiansLazy = this.convertFromBase(AngleUnits.Microradians);
  }
  /** */
  get Milliradians() {
    if (this.milliradiansLazy !== null) {
      return this.milliradiansLazy;
    }
    return this.milliradiansLazy = this.convertFromBase(AngleUnits.Milliradians);
  }
  /** */
  get Centiradians() {
    if (this.centiradiansLazy !== null) {
      return this.centiradiansLazy;
    }
    return this.centiradiansLazy = this.convertFromBase(AngleUnits.Centiradians);
  }
  /** */
  get Deciradians() {
    if (this.deciradiansLazy !== null) {
      return this.deciradiansLazy;
    }
    return this.deciradiansLazy = this.convertFromBase(AngleUnits.Deciradians);
  }
  /** */
  get Nanodegrees() {
    if (this.nanodegreesLazy !== null) {
      return this.nanodegreesLazy;
    }
    return this.nanodegreesLazy = this.convertFromBase(AngleUnits.Nanodegrees);
  }
  /** */
  get Microdegrees() {
    if (this.microdegreesLazy !== null) {
      return this.microdegreesLazy;
    }
    return this.microdegreesLazy = this.convertFromBase(AngleUnits.Microdegrees);
  }
  /** */
  get Millidegrees() {
    if (this.millidegreesLazy !== null) {
      return this.millidegreesLazy;
    }
    return this.millidegreesLazy = this.convertFromBase(AngleUnits.Millidegrees);
  }
  /**
   * Create a new Angle instance from a Radians
   *
   * @param value The unit as Radians to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromRadians(value) {
    return new Angle(value, AngleUnits.Radians);
  }
  /**
   * Create a new Angle instance from a Degrees
   *
   * @param value The unit as Degrees to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromDegrees(value) {
    return new Angle(value, AngleUnits.Degrees);
  }
  /**
   * Create a new Angle instance from a Arcminutes
   *
   * @param value The unit as Arcminutes to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromArcminutes(value) {
    return new Angle(value, AngleUnits.Arcminutes);
  }
  /**
   * Create a new Angle instance from a Arcseconds
   *
   * @param value The unit as Arcseconds to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromArcseconds(value) {
    return new Angle(value, AngleUnits.Arcseconds);
  }
  /**
   * Create a new Angle instance from a Gradians
   *
   * @param value The unit as Gradians to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromGradians(value) {
    return new Angle(value, AngleUnits.Gradians);
  }
  /**
   * Create a new Angle instance from a NatoMils
   *
   * @param value The unit as NatoMils to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromNatoMils(value) {
    return new Angle(value, AngleUnits.NatoMils);
  }
  /**
   * Create a new Angle instance from a Revolutions
   *
   * @param value The unit as Revolutions to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromRevolutions(value) {
    return new Angle(value, AngleUnits.Revolutions);
  }
  /**
   * Create a new Angle instance from a Tilt
   *
   * @param value The unit as Tilt to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromTilt(value) {
    return new Angle(value, AngleUnits.Tilt);
  }
  /**
   * Create a new Angle instance from a Nanoradians
   *
   * @param value The unit as Nanoradians to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromNanoradians(value) {
    return new Angle(value, AngleUnits.Nanoradians);
  }
  /**
   * Create a new Angle instance from a Microradians
   *
   * @param value The unit as Microradians to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromMicroradians(value) {
    return new Angle(value, AngleUnits.Microradians);
  }
  /**
   * Create a new Angle instance from a Milliradians
   *
   * @param value The unit as Milliradians to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromMilliradians(value) {
    return new Angle(value, AngleUnits.Milliradians);
  }
  /**
   * Create a new Angle instance from a Centiradians
   *
   * @param value The unit as Centiradians to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromCentiradians(value) {
    return new Angle(value, AngleUnits.Centiradians);
  }
  /**
   * Create a new Angle instance from a Deciradians
   *
   * @param value The unit as Deciradians to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromDeciradians(value) {
    return new Angle(value, AngleUnits.Deciradians);
  }
  /**
   * Create a new Angle instance from a Nanodegrees
   *
   * @param value The unit as Nanodegrees to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromNanodegrees(value) {
    return new Angle(value, AngleUnits.Nanodegrees);
  }
  /**
   * Create a new Angle instance from a Microdegrees
   *
   * @param value The unit as Microdegrees to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromMicrodegrees(value) {
    return new Angle(value, AngleUnits.Microdegrees);
  }
  /**
   * Create a new Angle instance from a Millidegrees
   *
   * @param value The unit as Millidegrees to create a new Angle from.
   * @returns The new Angle instance.
   */
  static FromMillidegrees(value) {
    return new Angle(value, AngleUnits.Millidegrees);
  }
  /**
   * Gets the base unit enumeration associated with Angle
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return AngleUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return AngleUnits.Degrees;
  }
  /**
   * Create API DTO represent a Angle unit.
   * @param holdInUnit The specific Angle unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = AngleUnits.Degrees) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Angle unit from an API DTO representation.
   * @param dtoAngle The Angle API DTO representation
   */
  static FromDto(dtoAngle) {
    return new Angle(dtoAngle.value, dtoAngle.unit);
  }
  /**
   * Convert Angle to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case AngleUnits.Radians:
        return this.Radians;
      case AngleUnits.Degrees:
        return this.Degrees;
      case AngleUnits.Arcminutes:
        return this.Arcminutes;
      case AngleUnits.Arcseconds:
        return this.Arcseconds;
      case AngleUnits.Gradians:
        return this.Gradians;
      case AngleUnits.NatoMils:
        return this.NatoMils;
      case AngleUnits.Revolutions:
        return this.Revolutions;
      case AngleUnits.Tilt:
        return this.Tilt;
      case AngleUnits.Nanoradians:
        return this.Nanoradians;
      case AngleUnits.Microradians:
        return this.Microradians;
      case AngleUnits.Milliradians:
        return this.Milliradians;
      case AngleUnits.Centiradians:
        return this.Centiradians;
      case AngleUnits.Deciradians:
        return this.Deciradians;
      case AngleUnits.Nanodegrees:
        return this.Nanodegrees;
      case AngleUnits.Microdegrees:
        return this.Microdegrees;
      case AngleUnits.Millidegrees:
        return this.Millidegrees;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case AngleUnits.Radians:
        {
          const v3 = super.internalDivide(this.value, 180);
          return super.internalMultiply(v3, Math.PI);
        }
      case AngleUnits.Degrees:
        return this.value;
      case AngleUnits.Arcminutes:
        return super.internalMultiply(this.value, 60);
      case AngleUnits.Arcseconds:
        return super.internalMultiply(this.value, 3600);
      case AngleUnits.Gradians:
        return super.internalDivide(this.value, 0.9);
      case AngleUnits.NatoMils:
        {
          const v4 = super.internalDivide(160, 9);
          return super.internalMultiply(this.value, v4);
        }
      case AngleUnits.Revolutions:
        return super.internalDivide(this.value, 360);
      case AngleUnits.Tilt:
        {
          const v3 = super.internalDivide(this.value, 180);
          return super.internalMultiply(v3, Math.PI);
        }
      case AngleUnits.Nanoradians:
        {
          const v3 = super.internalDivide(this.value, 180);
          const v5 = super.internalMultiply(v3, Math.PI);
          return super.internalDivide(v5, 1e-9);
        }
      case AngleUnits.Microradians:
        {
          const v3 = super.internalDivide(this.value, 180);
          const v5 = super.internalMultiply(v3, Math.PI);
          return super.internalDivide(v5, 0.000001);
        }
      case AngleUnits.Milliradians:
        {
          const v3 = super.internalDivide(this.value, 180);
          const v5 = super.internalMultiply(v3, Math.PI);
          return super.internalDivide(v5, 0.001);
        }
      case AngleUnits.Centiradians:
        {
          const v3 = super.internalDivide(this.value, 180);
          const v5 = super.internalMultiply(v3, Math.PI);
          return super.internalDivide(v5, 0.01);
        }
      case AngleUnits.Deciradians:
        {
          const v3 = super.internalDivide(this.value, 180);
          const v5 = super.internalMultiply(v3, Math.PI);
          return super.internalDivide(v5, 0.1);
        }
      case AngleUnits.Nanodegrees:
        return super.internalDivide(this.value, 1e-9);
      case AngleUnits.Microdegrees:
        return super.internalDivide(this.value, 0.000001);
      case AngleUnits.Millidegrees:
        return super.internalDivide(this.value, 0.001);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case AngleUnits.Radians:
        return this.value / 180 * Math.PI;
      case AngleUnits.Degrees:
        return this.value;
      case AngleUnits.Arcminutes:
        return this.value * 60;
      case AngleUnits.Arcseconds:
        return this.value * 3600;
      case AngleUnits.Gradians:
        return this.value / 0.9;
      case AngleUnits.NatoMils:
        return this.value * 160 / 9;
      case AngleUnits.Revolutions:
        return this.value / 360;
      case AngleUnits.Tilt:
        return Math.sin(this.value / 180 * Math.PI);
      case AngleUnits.Nanoradians:
        return this.value / 180 * Math.PI / 1e-9;
      case AngleUnits.Microradians:
        return this.value / 180 * Math.PI / 0.000001;
      case AngleUnits.Milliradians:
        return this.value / 180 * Math.PI / 0.001;
      case AngleUnits.Centiradians:
        return this.value / 180 * Math.PI / 0.01;
      case AngleUnits.Deciradians:
        return this.value / 180 * Math.PI / 0.1;
      case AngleUnits.Nanodegrees:
        return this.value / 1e-9;
      case AngleUnits.Microdegrees:
        return this.value / 0.000001;
      case AngleUnits.Millidegrees:
        return this.value / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case AngleUnits.Radians:
        {
          const v4 = super.internalDivide(180, Math.PI);
          return super.internalMultiply(value, v4);
        }
      case AngleUnits.Degrees:
        return value;
      case AngleUnits.Arcminutes:
        return super.internalDivide(value, 60);
      case AngleUnits.Arcseconds:
        return super.internalDivide(value, 3600);
      case AngleUnits.Gradians:
        return super.internalMultiply(value, 0.9);
      case AngleUnits.NatoMils:
        {
          const v4 = super.internalDivide(9, 160);
          return super.internalMultiply(value, v4);
        }
      case AngleUnits.Revolutions:
        return super.internalMultiply(value, 360);
      case AngleUnits.Tilt:
        {
          const v4 = super.internalDivide(180, Math.PI);
          return super.internalMultiply(value, v4);
        }
      case AngleUnits.Nanoradians:
        {
          const v4 = super.internalDivide(180, Math.PI);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 1e-9);
        }
      case AngleUnits.Microradians:
        {
          const v4 = super.internalDivide(180, Math.PI);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 0.000001);
        }
      case AngleUnits.Milliradians:
        {
          const v4 = super.internalDivide(180, Math.PI);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 0.001);
        }
      case AngleUnits.Centiradians:
        {
          const v4 = super.internalDivide(180, Math.PI);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 0.01);
        }
      case AngleUnits.Deciradians:
        {
          const v4 = super.internalDivide(180, Math.PI);
          const v5 = super.internalMultiply(value, v4);
          return super.internalMultiply(v5, 0.1);
        }
      case AngleUnits.Nanodegrees:
        return super.internalMultiply(value, 1e-9);
      case AngleUnits.Microdegrees:
        return super.internalMultiply(value, 0.000001);
      case AngleUnits.Millidegrees:
        return super.internalMultiply(value, 0.001);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case AngleUnits.Radians:
        return value * 180 / Math.PI;
      case AngleUnits.Degrees:
        return value;
      case AngleUnits.Arcminutes:
        return value / 60;
      case AngleUnits.Arcseconds:
        return value / 3600;
      case AngleUnits.Gradians:
        return value * 0.9;
      case AngleUnits.NatoMils:
        return value * 9 / 160;
      case AngleUnits.Revolutions:
        return value * 360;
      case AngleUnits.Tilt:
        return Math.asin(value) * 180 / Math.PI;
      case AngleUnits.Nanoradians:
        return value * 180 / Math.PI * 1e-9;
      case AngleUnits.Microradians:
        return value * 180 / Math.PI * 0.000001;
      case AngleUnits.Milliradians:
        return value * 180 / Math.PI * 0.001;
      case AngleUnits.Centiradians:
        return value * 180 / Math.PI * 0.01;
      case AngleUnits.Deciradians:
        return value * 180 / Math.PI * 0.1;
      case AngleUnits.Nanodegrees:
        return value * 1e-9;
      case AngleUnits.Microdegrees:
        return value * 0.000001;
      case AngleUnits.Millidegrees:
        return value * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Angle to string.
   * Note! the default format for Angle is Degrees.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Angle.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Angle.
   */
  toString(unit = AngleUnits.Degrees, fractionalDigits) {
    switch (unit) {
      case AngleUnits.Radians:
        return super.truncateFractionDigits(this.Radians, fractionalDigits) + ` rad`;
      case AngleUnits.Degrees:
        return super.truncateFractionDigits(this.Degrees, fractionalDigits) + ` °`;
      case AngleUnits.Arcminutes:
        return super.truncateFractionDigits(this.Arcminutes, fractionalDigits) + ` '`;
      case AngleUnits.Arcseconds:
        return super.truncateFractionDigits(this.Arcseconds, fractionalDigits) + ` ″`;
      case AngleUnits.Gradians:
        return super.truncateFractionDigits(this.Gradians, fractionalDigits) + ` g`;
      case AngleUnits.NatoMils:
        return super.truncateFractionDigits(this.NatoMils, fractionalDigits) + ` mil`;
      case AngleUnits.Revolutions:
        return super.truncateFractionDigits(this.Revolutions, fractionalDigits) + ` r`;
      case AngleUnits.Tilt:
        return super.truncateFractionDigits(this.Tilt, fractionalDigits) + ` sin(θ)`;
      case AngleUnits.Nanoradians:
        return super.truncateFractionDigits(this.Nanoradians, fractionalDigits) + ` nrad`;
      case AngleUnits.Microradians:
        return super.truncateFractionDigits(this.Microradians, fractionalDigits) + ` μrad`;
      case AngleUnits.Milliradians:
        return super.truncateFractionDigits(this.Milliradians, fractionalDigits) + ` mrad`;
      case AngleUnits.Centiradians:
        return super.truncateFractionDigits(this.Centiradians, fractionalDigits) + ` crad`;
      case AngleUnits.Deciradians:
        return super.truncateFractionDigits(this.Deciradians, fractionalDigits) + ` drad`;
      case AngleUnits.Nanodegrees:
        return super.truncateFractionDigits(this.Nanodegrees, fractionalDigits) + ` n°`;
      case AngleUnits.Microdegrees:
        return super.truncateFractionDigits(this.Microdegrees, fractionalDigits) + ` μ°`;
      case AngleUnits.Millidegrees:
        return super.truncateFractionDigits(this.Millidegrees, fractionalDigits) + ` m°`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Angle unit abbreviation.
   * Note! the default abbreviation for Angle is Degrees.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Angle.
   * @returns The abbreviation string of Angle.
   */
  getUnitAbbreviation(unitAbbreviation = AngleUnits.Degrees) {
    switch (unitAbbreviation) {
      case AngleUnits.Radians:
        return `rad`;
      case AngleUnits.Degrees:
        return `°`;
      case AngleUnits.Arcminutes:
        return `'`;
      case AngleUnits.Arcseconds:
        return `″`;
      case AngleUnits.Gradians:
        return `g`;
      case AngleUnits.NatoMils:
        return `mil`;
      case AngleUnits.Revolutions:
        return `r`;
      case AngleUnits.Tilt:
        return `sin(θ)`;
      case AngleUnits.Nanoradians:
        return `nrad`;
      case AngleUnits.Microradians:
        return `μrad`;
      case AngleUnits.Milliradians:
        return `mrad`;
      case AngleUnits.Centiradians:
        return `crad`;
      case AngleUnits.Deciradians:
        return `drad`;
      case AngleUnits.Nanodegrees:
        return `n°`;
      case AngleUnits.Microdegrees:
        return `μ°`;
      case AngleUnits.Millidegrees:
        return `m°`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Angle are equals to the current Angle.
   * @param angle The other Angle.
   * @returns True if the given Angle are equal to the current Angle.
   */
  equals(angle) {
    return super.internalEquals(this.value, angle.BaseValue);
  }
  /**
   * Compare the given Angle against the current Angle.
   * @param angle The other Angle.
   * @returns 0 if they are equal, -1 if the current Angle is less then other, 1 if the current Angle is greater then other.
   */
  compareTo(angle) {
    return super.internalCompareTo(this.value, angle.BaseValue);
  }
  /**
   * Add the given Angle with the current Angle.
   * @param angle The other Angle.
   * @returns A new Angle instance with the results.
   */
  add(angle) {
    return new Angle(super.internalAdd(this.value, angle.BaseValue));
  }
  /**
   * Subtract the given Angle with the current Angle.
   * @param angle The other Angle.
   * @returns A new Angle instance with the results.
   */
  subtract(angle) {
    return new Angle(super.internalSubtract(this.value, angle.BaseValue));
  }
  /**
   * Multiply the given Angle with the current Angle.
   * @param angle The other Angle.
   * @returns A new Angle instance with the results.
   */
  multiply(angle) {
    return new Angle(super.internalMultiply(this.value, angle.BaseValue));
  }
  /**
   * Divide the given Angle with the current Angle.
   * @param angle The other Angle.
   * @returns A new Angle instance with the results.
   */
  divide(angle) {
    return new Angle(super.internalDivide(this.value, angle.BaseValue));
  }
  /**
   * Modulo the given Angle with the current Angle.
   * @param angle The other Angle.
   * @returns A new Angle instance with the results.
   */
  modulo(angle) {
    return new Angle(super.internalModulo(this.value, angle.BaseValue));
  }
  /**
   * Pow the given Angle with the current Angle.
   * @param angle The other Angle.
   * @returns A new Angle instance with the results.
   */
  pow(angle) {
    return new Angle(super.internalPow(this.value, angle.BaseValue));
  }
}
exports.Angle = Angle;