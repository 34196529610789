"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Ratio = exports.RatioUnits = void 0;
const base_unit_1 = require("../base-unit");
/** RatioUnits enumeration */
var RatioUnits;
(function (RatioUnits) {
  /** */
  RatioUnits["DecimalFractions"] = "DecimalFraction";
  /** */
  RatioUnits["Percent"] = "Percent";
  /** */
  RatioUnits["PartsPerThousand"] = "PartPerThousand";
  /** */
  RatioUnits["PartsPerMillion"] = "PartPerMillion";
  /** */
  RatioUnits["PartsPerBillion"] = "PartPerBillion";
  /** */
  RatioUnits["PartsPerTrillion"] = "PartPerTrillion";
})(RatioUnits = exports.RatioUnits || (exports.RatioUnits = {}));
/** In mathematics, a ratio is a relationship between two numbers of the same kind (e.g., objects, persons, students, spoonfuls, units of whatever identical dimension), usually expressed as "a to b" or a:b, sometimes expressed arithmetically as a dimensionless quotient of the two that explicitly indicates how many times the first number contains the second (not necessarily an integer). */
class Ratio extends base_unit_1.BaseUnit {
  /**
   * Create a new Ratio.
   * @param value The value.
   * @param fromUnit The ‘Ratio’ unit to create from.
   * The default unit is DecimalFractions
   */
  constructor(value, fromUnit = RatioUnits.DecimalFractions) {
    super();
    this.decimalfractionsLazy = null;
    this.percentLazy = null;
    this.partsperthousandLazy = null;
    this.partspermillionLazy = null;
    this.partsperbillionLazy = null;
    this.partspertrillionLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Ratio is DecimalFractions.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return RatioUnits.DecimalFractions;
  }
  /** */
  get DecimalFractions() {
    if (this.decimalfractionsLazy !== null) {
      return this.decimalfractionsLazy;
    }
    return this.decimalfractionsLazy = this.convertFromBase(RatioUnits.DecimalFractions);
  }
  /** */
  get Percent() {
    if (this.percentLazy !== null) {
      return this.percentLazy;
    }
    return this.percentLazy = this.convertFromBase(RatioUnits.Percent);
  }
  /** */
  get PartsPerThousand() {
    if (this.partsperthousandLazy !== null) {
      return this.partsperthousandLazy;
    }
    return this.partsperthousandLazy = this.convertFromBase(RatioUnits.PartsPerThousand);
  }
  /** */
  get PartsPerMillion() {
    if (this.partspermillionLazy !== null) {
      return this.partspermillionLazy;
    }
    return this.partspermillionLazy = this.convertFromBase(RatioUnits.PartsPerMillion);
  }
  /** */
  get PartsPerBillion() {
    if (this.partsperbillionLazy !== null) {
      return this.partsperbillionLazy;
    }
    return this.partsperbillionLazy = this.convertFromBase(RatioUnits.PartsPerBillion);
  }
  /** */
  get PartsPerTrillion() {
    if (this.partspertrillionLazy !== null) {
      return this.partspertrillionLazy;
    }
    return this.partspertrillionLazy = this.convertFromBase(RatioUnits.PartsPerTrillion);
  }
  /**
   * Create a new Ratio instance from a DecimalFractions
   *
   * @param value The unit as DecimalFractions to create a new Ratio from.
   * @returns The new Ratio instance.
   */
  static FromDecimalFractions(value) {
    return new Ratio(value, RatioUnits.DecimalFractions);
  }
  /**
   * Create a new Ratio instance from a Percent
   *
   * @param value The unit as Percent to create a new Ratio from.
   * @returns The new Ratio instance.
   */
  static FromPercent(value) {
    return new Ratio(value, RatioUnits.Percent);
  }
  /**
   * Create a new Ratio instance from a PartsPerThousand
   *
   * @param value The unit as PartsPerThousand to create a new Ratio from.
   * @returns The new Ratio instance.
   */
  static FromPartsPerThousand(value) {
    return new Ratio(value, RatioUnits.PartsPerThousand);
  }
  /**
   * Create a new Ratio instance from a PartsPerMillion
   *
   * @param value The unit as PartsPerMillion to create a new Ratio from.
   * @returns The new Ratio instance.
   */
  static FromPartsPerMillion(value) {
    return new Ratio(value, RatioUnits.PartsPerMillion);
  }
  /**
   * Create a new Ratio instance from a PartsPerBillion
   *
   * @param value The unit as PartsPerBillion to create a new Ratio from.
   * @returns The new Ratio instance.
   */
  static FromPartsPerBillion(value) {
    return new Ratio(value, RatioUnits.PartsPerBillion);
  }
  /**
   * Create a new Ratio instance from a PartsPerTrillion
   *
   * @param value The unit as PartsPerTrillion to create a new Ratio from.
   * @returns The new Ratio instance.
   */
  static FromPartsPerTrillion(value) {
    return new Ratio(value, RatioUnits.PartsPerTrillion);
  }
  /**
   * Gets the base unit enumeration associated with Ratio
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return RatioUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return RatioUnits.DecimalFractions;
  }
  /**
   * Create API DTO represent a Ratio unit.
   * @param holdInUnit The specific Ratio unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = RatioUnits.DecimalFractions) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Ratio unit from an API DTO representation.
   * @param dtoRatio The Ratio API DTO representation
   */
  static FromDto(dtoRatio) {
    return new Ratio(dtoRatio.value, dtoRatio.unit);
  }
  /**
   * Convert Ratio to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case RatioUnits.DecimalFractions:
        return this.DecimalFractions;
      case RatioUnits.Percent:
        return this.Percent;
      case RatioUnits.PartsPerThousand:
        return this.PartsPerThousand;
      case RatioUnits.PartsPerMillion:
        return this.PartsPerMillion;
      case RatioUnits.PartsPerBillion:
        return this.PartsPerBillion;
      case RatioUnits.PartsPerTrillion:
        return this.PartsPerTrillion;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case RatioUnits.DecimalFractions:
        return this.value;
      case RatioUnits.Percent:
        return super.internalMultiply(this.value, 1e2);
      case RatioUnits.PartsPerThousand:
        return super.internalMultiply(this.value, 1e3);
      case RatioUnits.PartsPerMillion:
        return super.internalMultiply(this.value, 1e6);
      case RatioUnits.PartsPerBillion:
        return super.internalMultiply(this.value, 1e9);
      case RatioUnits.PartsPerTrillion:
        return super.internalMultiply(this.value, 1e12);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case RatioUnits.DecimalFractions:
        return this.value;
      case RatioUnits.Percent:
        return this.value * 1e2;
      case RatioUnits.PartsPerThousand:
        return this.value * 1e3;
      case RatioUnits.PartsPerMillion:
        return this.value * 1e6;
      case RatioUnits.PartsPerBillion:
        return this.value * 1e9;
      case RatioUnits.PartsPerTrillion:
        return this.value * 1e12;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case RatioUnits.DecimalFractions:
        return value;
      case RatioUnits.Percent:
        return super.internalDivide(value, 1e2);
      case RatioUnits.PartsPerThousand:
        return super.internalDivide(value, 1e3);
      case RatioUnits.PartsPerMillion:
        return super.internalDivide(value, 1e6);
      case RatioUnits.PartsPerBillion:
        return super.internalDivide(value, 1e9);
      case RatioUnits.PartsPerTrillion:
        return super.internalDivide(value, 1e12);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case RatioUnits.DecimalFractions:
        return value;
      case RatioUnits.Percent:
        return value / 1e2;
      case RatioUnits.PartsPerThousand:
        return value / 1e3;
      case RatioUnits.PartsPerMillion:
        return value / 1e6;
      case RatioUnits.PartsPerBillion:
        return value / 1e9;
      case RatioUnits.PartsPerTrillion:
        return value / 1e12;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Ratio to string.
   * Note! the default format for Ratio is DecimalFractions.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Ratio.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Ratio.
   */
  toString(unit = RatioUnits.DecimalFractions, fractionalDigits) {
    switch (unit) {
      case RatioUnits.DecimalFractions:
        return super.truncateFractionDigits(this.DecimalFractions, fractionalDigits) + ` `;
      case RatioUnits.Percent:
        return super.truncateFractionDigits(this.Percent, fractionalDigits) + ` %`;
      case RatioUnits.PartsPerThousand:
        return super.truncateFractionDigits(this.PartsPerThousand, fractionalDigits) + ` ‰`;
      case RatioUnits.PartsPerMillion:
        return super.truncateFractionDigits(this.PartsPerMillion, fractionalDigits) + ` ppm`;
      case RatioUnits.PartsPerBillion:
        return super.truncateFractionDigits(this.PartsPerBillion, fractionalDigits) + ` ppb`;
      case RatioUnits.PartsPerTrillion:
        return super.truncateFractionDigits(this.PartsPerTrillion, fractionalDigits) + ` ppt`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Ratio unit abbreviation.
   * Note! the default abbreviation for Ratio is DecimalFractions.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Ratio.
   * @returns The abbreviation string of Ratio.
   */
  getUnitAbbreviation(unitAbbreviation = RatioUnits.DecimalFractions) {
    switch (unitAbbreviation) {
      case RatioUnits.DecimalFractions:
        return ``;
      case RatioUnits.Percent:
        return `%`;
      case RatioUnits.PartsPerThousand:
        return `‰`;
      case RatioUnits.PartsPerMillion:
        return `ppm`;
      case RatioUnits.PartsPerBillion:
        return `ppb`;
      case RatioUnits.PartsPerTrillion:
        return `ppt`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Ratio are equals to the current Ratio.
   * @param ratio The other Ratio.
   * @returns True if the given Ratio are equal to the current Ratio.
   */
  equals(ratio) {
    return super.internalEquals(this.value, ratio.BaseValue);
  }
  /**
   * Compare the given Ratio against the current Ratio.
   * @param ratio The other Ratio.
   * @returns 0 if they are equal, -1 if the current Ratio is less then other, 1 if the current Ratio is greater then other.
   */
  compareTo(ratio) {
    return super.internalCompareTo(this.value, ratio.BaseValue);
  }
  /**
   * Add the given Ratio with the current Ratio.
   * @param ratio The other Ratio.
   * @returns A new Ratio instance with the results.
   */
  add(ratio) {
    return new Ratio(super.internalAdd(this.value, ratio.BaseValue));
  }
  /**
   * Subtract the given Ratio with the current Ratio.
   * @param ratio The other Ratio.
   * @returns A new Ratio instance with the results.
   */
  subtract(ratio) {
    return new Ratio(super.internalSubtract(this.value, ratio.BaseValue));
  }
  /**
   * Multiply the given Ratio with the current Ratio.
   * @param ratio The other Ratio.
   * @returns A new Ratio instance with the results.
   */
  multiply(ratio) {
    return new Ratio(super.internalMultiply(this.value, ratio.BaseValue));
  }
  /**
   * Divide the given Ratio with the current Ratio.
   * @param ratio The other Ratio.
   * @returns A new Ratio instance with the results.
   */
  divide(ratio) {
    return new Ratio(super.internalDivide(this.value, ratio.BaseValue));
  }
  /**
   * Modulo the given Ratio with the current Ratio.
   * @param ratio The other Ratio.
   * @returns A new Ratio instance with the results.
   */
  modulo(ratio) {
    return new Ratio(super.internalModulo(this.value, ratio.BaseValue));
  }
  /**
   * Pow the given Ratio with the current Ratio.
   * @param ratio The other Ratio.
   * @returns A new Ratio instance with the results.
   */
  pow(ratio) {
    return new Ratio(super.internalPow(this.value, ratio.BaseValue));
  }
}
exports.Ratio = Ratio;