"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Scalar = exports.ScalarUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ScalarUnits enumeration */
var ScalarUnits;
(function (ScalarUnits) {
  /** */
  ScalarUnits["Amount"] = "Amount";
})(ScalarUnits = exports.ScalarUnits || (exports.ScalarUnits = {}));
/** A way of representing a number of items. */
class Scalar extends base_unit_1.BaseUnit {
  /**
   * Create a new Scalar.
   * @param value The value.
   * @param fromUnit The ‘Scalar’ unit to create from.
   * The default unit is Amount
   */
  constructor(value, fromUnit = ScalarUnits.Amount) {
    super();
    this.amountLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Scalar is Amount.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ScalarUnits.Amount;
  }
  /** */
  get Amount() {
    if (this.amountLazy !== null) {
      return this.amountLazy;
    }
    return this.amountLazy = this.convertFromBase(ScalarUnits.Amount);
  }
  /**
   * Create a new Scalar instance from a Amount
   *
   * @param value The unit as Amount to create a new Scalar from.
   * @returns The new Scalar instance.
   */
  static FromAmount(value) {
    return new Scalar(value, ScalarUnits.Amount);
  }
  /**
   * Gets the base unit enumeration associated with Scalar
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ScalarUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ScalarUnits.Amount;
  }
  /**
   * Create API DTO represent a Scalar unit.
   * @param holdInUnit The specific Scalar unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ScalarUnits.Amount) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Scalar unit from an API DTO representation.
   * @param dtoScalar The Scalar API DTO representation
   */
  static FromDto(dtoScalar) {
    return new Scalar(dtoScalar.value, dtoScalar.unit);
  }
  /**
   * Convert Scalar to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ScalarUnits.Amount:
        return this.Amount;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ScalarUnits.Amount:
        return this.value;
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ScalarUnits.Amount:
        return this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ScalarUnits.Amount:
        return value;
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ScalarUnits.Amount:
        return value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Scalar to string.
   * Note! the default format for Scalar is Amount.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Scalar.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Scalar.
   */
  toString(unit = ScalarUnits.Amount, fractionalDigits) {
    switch (unit) {
      case ScalarUnits.Amount:
        return super.truncateFractionDigits(this.Amount, fractionalDigits) + ` `;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Scalar unit abbreviation.
   * Note! the default abbreviation for Scalar is Amount.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Scalar.
   * @returns The abbreviation string of Scalar.
   */
  getUnitAbbreviation(unitAbbreviation = ScalarUnits.Amount) {
    switch (unitAbbreviation) {
      case ScalarUnits.Amount:
        return ``;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Scalar are equals to the current Scalar.
   * @param scalar The other Scalar.
   * @returns True if the given Scalar are equal to the current Scalar.
   */
  equals(scalar) {
    return super.internalEquals(this.value, scalar.BaseValue);
  }
  /**
   * Compare the given Scalar against the current Scalar.
   * @param scalar The other Scalar.
   * @returns 0 if they are equal, -1 if the current Scalar is less then other, 1 if the current Scalar is greater then other.
   */
  compareTo(scalar) {
    return super.internalCompareTo(this.value, scalar.BaseValue);
  }
  /**
   * Add the given Scalar with the current Scalar.
   * @param scalar The other Scalar.
   * @returns A new Scalar instance with the results.
   */
  add(scalar) {
    return new Scalar(super.internalAdd(this.value, scalar.BaseValue));
  }
  /**
   * Subtract the given Scalar with the current Scalar.
   * @param scalar The other Scalar.
   * @returns A new Scalar instance with the results.
   */
  subtract(scalar) {
    return new Scalar(super.internalSubtract(this.value, scalar.BaseValue));
  }
  /**
   * Multiply the given Scalar with the current Scalar.
   * @param scalar The other Scalar.
   * @returns A new Scalar instance with the results.
   */
  multiply(scalar) {
    return new Scalar(super.internalMultiply(this.value, scalar.BaseValue));
  }
  /**
   * Divide the given Scalar with the current Scalar.
   * @param scalar The other Scalar.
   * @returns A new Scalar instance with the results.
   */
  divide(scalar) {
    return new Scalar(super.internalDivide(this.value, scalar.BaseValue));
  }
  /**
   * Modulo the given Scalar with the current Scalar.
   * @param scalar The other Scalar.
   * @returns A new Scalar instance with the results.
   */
  modulo(scalar) {
    return new Scalar(super.internalModulo(this.value, scalar.BaseValue));
  }
  /**
   * Pow the given Scalar with the current Scalar.
   * @param scalar The other Scalar.
   * @returns A new Scalar instance with the results.
   */
  pow(scalar) {
    return new Scalar(super.internalPow(this.value, scalar.BaseValue));
  }
}
exports.Scalar = Scalar;