"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Luminance = exports.LuminanceUnits = void 0;
const base_unit_1 = require("../base-unit");
/** LuminanceUnits enumeration */
var LuminanceUnits;
(function (LuminanceUnits) {
  /** */
  LuminanceUnits["CandelasPerSquareMeter"] = "CandelaPerSquareMeter";
  /** */
  LuminanceUnits["CandelasPerSquareFoot"] = "CandelaPerSquareFoot";
  /** */
  LuminanceUnits["CandelasPerSquareInch"] = "CandelaPerSquareInch";
  /** */
  LuminanceUnits["Nits"] = "Nit";
  /** */
  LuminanceUnits["NanocandelasPerSquareMeter"] = "NanocandelaPerSquareMeter";
  /** */
  LuminanceUnits["MicrocandelasPerSquareMeter"] = "MicrocandelaPerSquareMeter";
  /** */
  LuminanceUnits["MillicandelasPerSquareMeter"] = "MillicandelaPerSquareMeter";
  /** */
  LuminanceUnits["CenticandelasPerSquareMeter"] = "CenticandelaPerSquareMeter";
  /** */
  LuminanceUnits["DecicandelasPerSquareMeter"] = "DecicandelaPerSquareMeter";
  /** */
  LuminanceUnits["KilocandelasPerSquareMeter"] = "KilocandelaPerSquareMeter";
})(LuminanceUnits = exports.LuminanceUnits || (exports.LuminanceUnits = {}));
/** Luminance is a photometric measure of the luminous intensity per unit area of light travelling in a given direction. */
class Luminance extends base_unit_1.BaseUnit {
  /**
   * Create a new Luminance.
   * @param value The value.
   * @param fromUnit The ‘Luminance’ unit to create from.
   * The default unit is CandelasPerSquareMeter
   */
  constructor(value, fromUnit = LuminanceUnits.CandelasPerSquareMeter) {
    super();
    this.candelaspersquaremeterLazy = null;
    this.candelaspersquarefootLazy = null;
    this.candelaspersquareinchLazy = null;
    this.nitsLazy = null;
    this.nanocandelaspersquaremeterLazy = null;
    this.microcandelaspersquaremeterLazy = null;
    this.millicandelaspersquaremeterLazy = null;
    this.centicandelaspersquaremeterLazy = null;
    this.decicandelaspersquaremeterLazy = null;
    this.kilocandelaspersquaremeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of Luminance is CandelasPerSquareMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return LuminanceUnits.CandelasPerSquareMeter;
  }
  /** */
  get CandelasPerSquareMeter() {
    if (this.candelaspersquaremeterLazy !== null) {
      return this.candelaspersquaremeterLazy;
    }
    return this.candelaspersquaremeterLazy = this.convertFromBase(LuminanceUnits.CandelasPerSquareMeter);
  }
  /** */
  get CandelasPerSquareFoot() {
    if (this.candelaspersquarefootLazy !== null) {
      return this.candelaspersquarefootLazy;
    }
    return this.candelaspersquarefootLazy = this.convertFromBase(LuminanceUnits.CandelasPerSquareFoot);
  }
  /** */
  get CandelasPerSquareInch() {
    if (this.candelaspersquareinchLazy !== null) {
      return this.candelaspersquareinchLazy;
    }
    return this.candelaspersquareinchLazy = this.convertFromBase(LuminanceUnits.CandelasPerSquareInch);
  }
  /** */
  get Nits() {
    if (this.nitsLazy !== null) {
      return this.nitsLazy;
    }
    return this.nitsLazy = this.convertFromBase(LuminanceUnits.Nits);
  }
  /** */
  get NanocandelasPerSquareMeter() {
    if (this.nanocandelaspersquaremeterLazy !== null) {
      return this.nanocandelaspersquaremeterLazy;
    }
    return this.nanocandelaspersquaremeterLazy = this.convertFromBase(LuminanceUnits.NanocandelasPerSquareMeter);
  }
  /** */
  get MicrocandelasPerSquareMeter() {
    if (this.microcandelaspersquaremeterLazy !== null) {
      return this.microcandelaspersquaremeterLazy;
    }
    return this.microcandelaspersquaremeterLazy = this.convertFromBase(LuminanceUnits.MicrocandelasPerSquareMeter);
  }
  /** */
  get MillicandelasPerSquareMeter() {
    if (this.millicandelaspersquaremeterLazy !== null) {
      return this.millicandelaspersquaremeterLazy;
    }
    return this.millicandelaspersquaremeterLazy = this.convertFromBase(LuminanceUnits.MillicandelasPerSquareMeter);
  }
  /** */
  get CenticandelasPerSquareMeter() {
    if (this.centicandelaspersquaremeterLazy !== null) {
      return this.centicandelaspersquaremeterLazy;
    }
    return this.centicandelaspersquaremeterLazy = this.convertFromBase(LuminanceUnits.CenticandelasPerSquareMeter);
  }
  /** */
  get DecicandelasPerSquareMeter() {
    if (this.decicandelaspersquaremeterLazy !== null) {
      return this.decicandelaspersquaremeterLazy;
    }
    return this.decicandelaspersquaremeterLazy = this.convertFromBase(LuminanceUnits.DecicandelasPerSquareMeter);
  }
  /** */
  get KilocandelasPerSquareMeter() {
    if (this.kilocandelaspersquaremeterLazy !== null) {
      return this.kilocandelaspersquaremeterLazy;
    }
    return this.kilocandelaspersquaremeterLazy = this.convertFromBase(LuminanceUnits.KilocandelasPerSquareMeter);
  }
  /**
   * Create a new Luminance instance from a CandelasPerSquareMeter
   *
   * @param value The unit as CandelasPerSquareMeter to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromCandelasPerSquareMeter(value) {
    return new Luminance(value, LuminanceUnits.CandelasPerSquareMeter);
  }
  /**
   * Create a new Luminance instance from a CandelasPerSquareFoot
   *
   * @param value The unit as CandelasPerSquareFoot to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromCandelasPerSquareFoot(value) {
    return new Luminance(value, LuminanceUnits.CandelasPerSquareFoot);
  }
  /**
   * Create a new Luminance instance from a CandelasPerSquareInch
   *
   * @param value The unit as CandelasPerSquareInch to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromCandelasPerSquareInch(value) {
    return new Luminance(value, LuminanceUnits.CandelasPerSquareInch);
  }
  /**
   * Create a new Luminance instance from a Nits
   *
   * @param value The unit as Nits to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromNits(value) {
    return new Luminance(value, LuminanceUnits.Nits);
  }
  /**
   * Create a new Luminance instance from a NanocandelasPerSquareMeter
   *
   * @param value The unit as NanocandelasPerSquareMeter to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromNanocandelasPerSquareMeter(value) {
    return new Luminance(value, LuminanceUnits.NanocandelasPerSquareMeter);
  }
  /**
   * Create a new Luminance instance from a MicrocandelasPerSquareMeter
   *
   * @param value The unit as MicrocandelasPerSquareMeter to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromMicrocandelasPerSquareMeter(value) {
    return new Luminance(value, LuminanceUnits.MicrocandelasPerSquareMeter);
  }
  /**
   * Create a new Luminance instance from a MillicandelasPerSquareMeter
   *
   * @param value The unit as MillicandelasPerSquareMeter to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromMillicandelasPerSquareMeter(value) {
    return new Luminance(value, LuminanceUnits.MillicandelasPerSquareMeter);
  }
  /**
   * Create a new Luminance instance from a CenticandelasPerSquareMeter
   *
   * @param value The unit as CenticandelasPerSquareMeter to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromCenticandelasPerSquareMeter(value) {
    return new Luminance(value, LuminanceUnits.CenticandelasPerSquareMeter);
  }
  /**
   * Create a new Luminance instance from a DecicandelasPerSquareMeter
   *
   * @param value The unit as DecicandelasPerSquareMeter to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromDecicandelasPerSquareMeter(value) {
    return new Luminance(value, LuminanceUnits.DecicandelasPerSquareMeter);
  }
  /**
   * Create a new Luminance instance from a KilocandelasPerSquareMeter
   *
   * @param value The unit as KilocandelasPerSquareMeter to create a new Luminance from.
   * @returns The new Luminance instance.
   */
  static FromKilocandelasPerSquareMeter(value) {
    return new Luminance(value, LuminanceUnits.KilocandelasPerSquareMeter);
  }
  /**
   * Gets the base unit enumeration associated with Luminance
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return LuminanceUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return LuminanceUnits.CandelasPerSquareMeter;
  }
  /**
   * Create API DTO represent a Luminance unit.
   * @param holdInUnit The specific Luminance unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = LuminanceUnits.CandelasPerSquareMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a Luminance unit from an API DTO representation.
   * @param dtoLuminance The Luminance API DTO representation
   */
  static FromDto(dtoLuminance) {
    return new Luminance(dtoLuminance.value, dtoLuminance.unit);
  }
  /**
   * Convert Luminance to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case LuminanceUnits.CandelasPerSquareMeter:
        return this.CandelasPerSquareMeter;
      case LuminanceUnits.CandelasPerSquareFoot:
        return this.CandelasPerSquareFoot;
      case LuminanceUnits.CandelasPerSquareInch:
        return this.CandelasPerSquareInch;
      case LuminanceUnits.Nits:
        return this.Nits;
      case LuminanceUnits.NanocandelasPerSquareMeter:
        return this.NanocandelasPerSquareMeter;
      case LuminanceUnits.MicrocandelasPerSquareMeter:
        return this.MicrocandelasPerSquareMeter;
      case LuminanceUnits.MillicandelasPerSquareMeter:
        return this.MillicandelasPerSquareMeter;
      case LuminanceUnits.CenticandelasPerSquareMeter:
        return this.CenticandelasPerSquareMeter;
      case LuminanceUnits.DecicandelasPerSquareMeter:
        return this.DecicandelasPerSquareMeter;
      case LuminanceUnits.KilocandelasPerSquareMeter:
        return this.KilocandelasPerSquareMeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case LuminanceUnits.CandelasPerSquareMeter:
        return this.value;
      case LuminanceUnits.CandelasPerSquareFoot:
        return super.internalDivide(this.value, 1.07639e1);
      case LuminanceUnits.CandelasPerSquareInch:
        return super.internalDivide(this.value, 1.5500031e3);
      case LuminanceUnits.Nits:
        return this.value;
      case LuminanceUnits.NanocandelasPerSquareMeter:
        return super.internalDivide(this.value, 1e-9);
      case LuminanceUnits.MicrocandelasPerSquareMeter:
        return super.internalDivide(this.value, 0.000001);
      case LuminanceUnits.MillicandelasPerSquareMeter:
        return super.internalDivide(this.value, 0.001);
      case LuminanceUnits.CenticandelasPerSquareMeter:
        return super.internalDivide(this.value, 0.01);
      case LuminanceUnits.DecicandelasPerSquareMeter:
        return super.internalDivide(this.value, 0.1);
      case LuminanceUnits.KilocandelasPerSquareMeter:
        return super.internalDivide(this.value, 1000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case LuminanceUnits.CandelasPerSquareMeter:
        return this.value;
      case LuminanceUnits.CandelasPerSquareFoot:
        return this.value / 1.07639e1;
      case LuminanceUnits.CandelasPerSquareInch:
        return this.value / 1.5500031e3;
      case LuminanceUnits.Nits:
        return this.value;
      case LuminanceUnits.NanocandelasPerSquareMeter:
        return this.value / 1e-9;
      case LuminanceUnits.MicrocandelasPerSquareMeter:
        return this.value / 0.000001;
      case LuminanceUnits.MillicandelasPerSquareMeter:
        return this.value / 0.001;
      case LuminanceUnits.CenticandelasPerSquareMeter:
        return this.value / 0.01;
      case LuminanceUnits.DecicandelasPerSquareMeter:
        return this.value / 0.1;
      case LuminanceUnits.KilocandelasPerSquareMeter:
        return this.value / 1000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case LuminanceUnits.CandelasPerSquareMeter:
        return value;
      case LuminanceUnits.CandelasPerSquareFoot:
        return super.internalMultiply(value, 1.07639e1);
      case LuminanceUnits.CandelasPerSquareInch:
        return super.internalMultiply(value, 1.5500031e3);
      case LuminanceUnits.Nits:
        return value;
      case LuminanceUnits.NanocandelasPerSquareMeter:
        return super.internalMultiply(value, 1e-9);
      case LuminanceUnits.MicrocandelasPerSquareMeter:
        return super.internalMultiply(value, 0.000001);
      case LuminanceUnits.MillicandelasPerSquareMeter:
        return super.internalMultiply(value, 0.001);
      case LuminanceUnits.CenticandelasPerSquareMeter:
        return super.internalMultiply(value, 0.01);
      case LuminanceUnits.DecicandelasPerSquareMeter:
        return super.internalMultiply(value, 0.1);
      case LuminanceUnits.KilocandelasPerSquareMeter:
        return super.internalMultiply(value, 1000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case LuminanceUnits.CandelasPerSquareMeter:
        return value;
      case LuminanceUnits.CandelasPerSquareFoot:
        return value * 1.07639e1;
      case LuminanceUnits.CandelasPerSquareInch:
        return value * 1.5500031e3;
      case LuminanceUnits.Nits:
        return value;
      case LuminanceUnits.NanocandelasPerSquareMeter:
        return value * 1e-9;
      case LuminanceUnits.MicrocandelasPerSquareMeter:
        return value * 0.000001;
      case LuminanceUnits.MillicandelasPerSquareMeter:
        return value * 0.001;
      case LuminanceUnits.CenticandelasPerSquareMeter:
        return value * 0.01;
      case LuminanceUnits.DecicandelasPerSquareMeter:
        return value * 0.1;
      case LuminanceUnits.KilocandelasPerSquareMeter:
        return value * 1000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the Luminance to string.
   * Note! the default format for Luminance is CandelasPerSquareMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the Luminance.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the Luminance.
   */
  toString(unit = LuminanceUnits.CandelasPerSquareMeter, fractionalDigits) {
    switch (unit) {
      case LuminanceUnits.CandelasPerSquareMeter:
        return super.truncateFractionDigits(this.CandelasPerSquareMeter, fractionalDigits) + ` Cd/m²`;
      case LuminanceUnits.CandelasPerSquareFoot:
        return super.truncateFractionDigits(this.CandelasPerSquareFoot, fractionalDigits) + ` Cd/ft²`;
      case LuminanceUnits.CandelasPerSquareInch:
        return super.truncateFractionDigits(this.CandelasPerSquareInch, fractionalDigits) + ` Cd/in²`;
      case LuminanceUnits.Nits:
        return super.truncateFractionDigits(this.Nits, fractionalDigits) + ` nt`;
      case LuminanceUnits.NanocandelasPerSquareMeter:
        return super.truncateFractionDigits(this.NanocandelasPerSquareMeter, fractionalDigits) + ` nCd/m²`;
      case LuminanceUnits.MicrocandelasPerSquareMeter:
        return super.truncateFractionDigits(this.MicrocandelasPerSquareMeter, fractionalDigits) + ` μCd/m²`;
      case LuminanceUnits.MillicandelasPerSquareMeter:
        return super.truncateFractionDigits(this.MillicandelasPerSquareMeter, fractionalDigits) + ` mCd/m²`;
      case LuminanceUnits.CenticandelasPerSquareMeter:
        return super.truncateFractionDigits(this.CenticandelasPerSquareMeter, fractionalDigits) + ` cCd/m²`;
      case LuminanceUnits.DecicandelasPerSquareMeter:
        return super.truncateFractionDigits(this.DecicandelasPerSquareMeter, fractionalDigits) + ` dCd/m²`;
      case LuminanceUnits.KilocandelasPerSquareMeter:
        return super.truncateFractionDigits(this.KilocandelasPerSquareMeter, fractionalDigits) + ` kCd/m²`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get Luminance unit abbreviation.
   * Note! the default abbreviation for Luminance is CandelasPerSquareMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the Luminance.
   * @returns The abbreviation string of Luminance.
   */
  getUnitAbbreviation(unitAbbreviation = LuminanceUnits.CandelasPerSquareMeter) {
    switch (unitAbbreviation) {
      case LuminanceUnits.CandelasPerSquareMeter:
        return `Cd/m²`;
      case LuminanceUnits.CandelasPerSquareFoot:
        return `Cd/ft²`;
      case LuminanceUnits.CandelasPerSquareInch:
        return `Cd/in²`;
      case LuminanceUnits.Nits:
        return `nt`;
      case LuminanceUnits.NanocandelasPerSquareMeter:
        return `nCd/m²`;
      case LuminanceUnits.MicrocandelasPerSquareMeter:
        return `μCd/m²`;
      case LuminanceUnits.MillicandelasPerSquareMeter:
        return `mCd/m²`;
      case LuminanceUnits.CenticandelasPerSquareMeter:
        return `cCd/m²`;
      case LuminanceUnits.DecicandelasPerSquareMeter:
        return `dCd/m²`;
      case LuminanceUnits.KilocandelasPerSquareMeter:
        return `kCd/m²`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given Luminance are equals to the current Luminance.
   * @param luminance The other Luminance.
   * @returns True if the given Luminance are equal to the current Luminance.
   */
  equals(luminance) {
    return super.internalEquals(this.value, luminance.BaseValue);
  }
  /**
   * Compare the given Luminance against the current Luminance.
   * @param luminance The other Luminance.
   * @returns 0 if they are equal, -1 if the current Luminance is less then other, 1 if the current Luminance is greater then other.
   */
  compareTo(luminance) {
    return super.internalCompareTo(this.value, luminance.BaseValue);
  }
  /**
   * Add the given Luminance with the current Luminance.
   * @param luminance The other Luminance.
   * @returns A new Luminance instance with the results.
   */
  add(luminance) {
    return new Luminance(super.internalAdd(this.value, luminance.BaseValue));
  }
  /**
   * Subtract the given Luminance with the current Luminance.
   * @param luminance The other Luminance.
   * @returns A new Luminance instance with the results.
   */
  subtract(luminance) {
    return new Luminance(super.internalSubtract(this.value, luminance.BaseValue));
  }
  /**
   * Multiply the given Luminance with the current Luminance.
   * @param luminance The other Luminance.
   * @returns A new Luminance instance with the results.
   */
  multiply(luminance) {
    return new Luminance(super.internalMultiply(this.value, luminance.BaseValue));
  }
  /**
   * Divide the given Luminance with the current Luminance.
   * @param luminance The other Luminance.
   * @returns A new Luminance instance with the results.
   */
  divide(luminance) {
    return new Luminance(super.internalDivide(this.value, luminance.BaseValue));
  }
  /**
   * Modulo the given Luminance with the current Luminance.
   * @param luminance The other Luminance.
   * @returns A new Luminance instance with the results.
   */
  modulo(luminance) {
    return new Luminance(super.internalModulo(this.value, luminance.BaseValue));
  }
  /**
   * Pow the given Luminance with the current Luminance.
   * @param luminance The other Luminance.
   * @returns A new Luminance instance with the results.
   */
  pow(luminance) {
    return new Luminance(super.internalPow(this.value, luminance.BaseValue));
  }
}
exports.Luminance = Luminance;