"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricResistivity = exports.ElectricResistivityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricResistivityUnits enumeration */
var ElectricResistivityUnits;
(function (ElectricResistivityUnits) {
  /** */
  ElectricResistivityUnits["OhmMeters"] = "OhmMeter";
  /** */
  ElectricResistivityUnits["OhmsCentimeter"] = "OhmCentimeter";
  /** */
  ElectricResistivityUnits["PicoohmMeters"] = "PicoohmMeter";
  /** */
  ElectricResistivityUnits["NanoohmMeters"] = "NanoohmMeter";
  /** */
  ElectricResistivityUnits["MicroohmMeters"] = "MicroohmMeter";
  /** */
  ElectricResistivityUnits["MilliohmMeters"] = "MilliohmMeter";
  /** */
  ElectricResistivityUnits["KiloohmMeters"] = "KiloohmMeter";
  /** */
  ElectricResistivityUnits["MegaohmMeters"] = "MegaohmMeter";
  /** */
  ElectricResistivityUnits["PicoohmsCentimeter"] = "PicoohmCentimeter";
  /** */
  ElectricResistivityUnits["NanoohmsCentimeter"] = "NanoohmCentimeter";
  /** */
  ElectricResistivityUnits["MicroohmsCentimeter"] = "MicroohmCentimeter";
  /** */
  ElectricResistivityUnits["MilliohmsCentimeter"] = "MilliohmCentimeter";
  /** */
  ElectricResistivityUnits["KiloohmsCentimeter"] = "KiloohmCentimeter";
  /** */
  ElectricResistivityUnits["MegaohmsCentimeter"] = "MegaohmCentimeter";
})(ElectricResistivityUnits = exports.ElectricResistivityUnits || (exports.ElectricResistivityUnits = {}));
/** Electrical resistivity (also known as resistivity, specific electrical resistance, or volume resistivity) is a fundamental property that quantifies how strongly a given material opposes the flow of electric current. */
class ElectricResistivity extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricResistivity.
   * @param value The value.
   * @param fromUnit The ‘ElectricResistivity’ unit to create from.
   * The default unit is OhmMeters
   */
  constructor(value, fromUnit = ElectricResistivityUnits.OhmMeters) {
    super();
    this.ohmmetersLazy = null;
    this.ohmscentimeterLazy = null;
    this.picoohmmetersLazy = null;
    this.nanoohmmetersLazy = null;
    this.microohmmetersLazy = null;
    this.milliohmmetersLazy = null;
    this.kiloohmmetersLazy = null;
    this.megaohmmetersLazy = null;
    this.picoohmscentimeterLazy = null;
    this.nanoohmscentimeterLazy = null;
    this.microohmscentimeterLazy = null;
    this.milliohmscentimeterLazy = null;
    this.kiloohmscentimeterLazy = null;
    this.megaohmscentimeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricResistivity is OhmMeters.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricResistivityUnits.OhmMeters;
  }
  /** */
  get OhmMeters() {
    if (this.ohmmetersLazy !== null) {
      return this.ohmmetersLazy;
    }
    return this.ohmmetersLazy = this.convertFromBase(ElectricResistivityUnits.OhmMeters);
  }
  /** */
  get OhmsCentimeter() {
    if (this.ohmscentimeterLazy !== null) {
      return this.ohmscentimeterLazy;
    }
    return this.ohmscentimeterLazy = this.convertFromBase(ElectricResistivityUnits.OhmsCentimeter);
  }
  /** */
  get PicoohmMeters() {
    if (this.picoohmmetersLazy !== null) {
      return this.picoohmmetersLazy;
    }
    return this.picoohmmetersLazy = this.convertFromBase(ElectricResistivityUnits.PicoohmMeters);
  }
  /** */
  get NanoohmMeters() {
    if (this.nanoohmmetersLazy !== null) {
      return this.nanoohmmetersLazy;
    }
    return this.nanoohmmetersLazy = this.convertFromBase(ElectricResistivityUnits.NanoohmMeters);
  }
  /** */
  get MicroohmMeters() {
    if (this.microohmmetersLazy !== null) {
      return this.microohmmetersLazy;
    }
    return this.microohmmetersLazy = this.convertFromBase(ElectricResistivityUnits.MicroohmMeters);
  }
  /** */
  get MilliohmMeters() {
    if (this.milliohmmetersLazy !== null) {
      return this.milliohmmetersLazy;
    }
    return this.milliohmmetersLazy = this.convertFromBase(ElectricResistivityUnits.MilliohmMeters);
  }
  /** */
  get KiloohmMeters() {
    if (this.kiloohmmetersLazy !== null) {
      return this.kiloohmmetersLazy;
    }
    return this.kiloohmmetersLazy = this.convertFromBase(ElectricResistivityUnits.KiloohmMeters);
  }
  /** */
  get MegaohmMeters() {
    if (this.megaohmmetersLazy !== null) {
      return this.megaohmmetersLazy;
    }
    return this.megaohmmetersLazy = this.convertFromBase(ElectricResistivityUnits.MegaohmMeters);
  }
  /** */
  get PicoohmsCentimeter() {
    if (this.picoohmscentimeterLazy !== null) {
      return this.picoohmscentimeterLazy;
    }
    return this.picoohmscentimeterLazy = this.convertFromBase(ElectricResistivityUnits.PicoohmsCentimeter);
  }
  /** */
  get NanoohmsCentimeter() {
    if (this.nanoohmscentimeterLazy !== null) {
      return this.nanoohmscentimeterLazy;
    }
    return this.nanoohmscentimeterLazy = this.convertFromBase(ElectricResistivityUnits.NanoohmsCentimeter);
  }
  /** */
  get MicroohmsCentimeter() {
    if (this.microohmscentimeterLazy !== null) {
      return this.microohmscentimeterLazy;
    }
    return this.microohmscentimeterLazy = this.convertFromBase(ElectricResistivityUnits.MicroohmsCentimeter);
  }
  /** */
  get MilliohmsCentimeter() {
    if (this.milliohmscentimeterLazy !== null) {
      return this.milliohmscentimeterLazy;
    }
    return this.milliohmscentimeterLazy = this.convertFromBase(ElectricResistivityUnits.MilliohmsCentimeter);
  }
  /** */
  get KiloohmsCentimeter() {
    if (this.kiloohmscentimeterLazy !== null) {
      return this.kiloohmscentimeterLazy;
    }
    return this.kiloohmscentimeterLazy = this.convertFromBase(ElectricResistivityUnits.KiloohmsCentimeter);
  }
  /** */
  get MegaohmsCentimeter() {
    if (this.megaohmscentimeterLazy !== null) {
      return this.megaohmscentimeterLazy;
    }
    return this.megaohmscentimeterLazy = this.convertFromBase(ElectricResistivityUnits.MegaohmsCentimeter);
  }
  /**
   * Create a new ElectricResistivity instance from a OhmMeters
   *
   * @param value The unit as OhmMeters to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromOhmMeters(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.OhmMeters);
  }
  /**
   * Create a new ElectricResistivity instance from a OhmsCentimeter
   *
   * @param value The unit as OhmsCentimeter to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromOhmsCentimeter(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.OhmsCentimeter);
  }
  /**
   * Create a new ElectricResistivity instance from a PicoohmMeters
   *
   * @param value The unit as PicoohmMeters to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromPicoohmMeters(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.PicoohmMeters);
  }
  /**
   * Create a new ElectricResistivity instance from a NanoohmMeters
   *
   * @param value The unit as NanoohmMeters to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromNanoohmMeters(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.NanoohmMeters);
  }
  /**
   * Create a new ElectricResistivity instance from a MicroohmMeters
   *
   * @param value The unit as MicroohmMeters to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromMicroohmMeters(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.MicroohmMeters);
  }
  /**
   * Create a new ElectricResistivity instance from a MilliohmMeters
   *
   * @param value The unit as MilliohmMeters to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromMilliohmMeters(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.MilliohmMeters);
  }
  /**
   * Create a new ElectricResistivity instance from a KiloohmMeters
   *
   * @param value The unit as KiloohmMeters to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromKiloohmMeters(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.KiloohmMeters);
  }
  /**
   * Create a new ElectricResistivity instance from a MegaohmMeters
   *
   * @param value The unit as MegaohmMeters to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromMegaohmMeters(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.MegaohmMeters);
  }
  /**
   * Create a new ElectricResistivity instance from a PicoohmsCentimeter
   *
   * @param value The unit as PicoohmsCentimeter to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromPicoohmsCentimeter(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.PicoohmsCentimeter);
  }
  /**
   * Create a new ElectricResistivity instance from a NanoohmsCentimeter
   *
   * @param value The unit as NanoohmsCentimeter to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromNanoohmsCentimeter(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.NanoohmsCentimeter);
  }
  /**
   * Create a new ElectricResistivity instance from a MicroohmsCentimeter
   *
   * @param value The unit as MicroohmsCentimeter to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromMicroohmsCentimeter(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.MicroohmsCentimeter);
  }
  /**
   * Create a new ElectricResistivity instance from a MilliohmsCentimeter
   *
   * @param value The unit as MilliohmsCentimeter to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromMilliohmsCentimeter(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.MilliohmsCentimeter);
  }
  /**
   * Create a new ElectricResistivity instance from a KiloohmsCentimeter
   *
   * @param value The unit as KiloohmsCentimeter to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromKiloohmsCentimeter(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.KiloohmsCentimeter);
  }
  /**
   * Create a new ElectricResistivity instance from a MegaohmsCentimeter
   *
   * @param value The unit as MegaohmsCentimeter to create a new ElectricResistivity from.
   * @returns The new ElectricResistivity instance.
   */
  static FromMegaohmsCentimeter(value) {
    return new ElectricResistivity(value, ElectricResistivityUnits.MegaohmsCentimeter);
  }
  /**
   * Gets the base unit enumeration associated with ElectricResistivity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricResistivityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricResistivityUnits.OhmMeters;
  }
  /**
   * Create API DTO represent a ElectricResistivity unit.
   * @param holdInUnit The specific ElectricResistivity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricResistivityUnits.OhmMeters) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricResistivity unit from an API DTO representation.
   * @param dtoElectricResistivity The ElectricResistivity API DTO representation
   */
  static FromDto(dtoElectricResistivity) {
    return new ElectricResistivity(dtoElectricResistivity.value, dtoElectricResistivity.unit);
  }
  /**
   * Convert ElectricResistivity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricResistivityUnits.OhmMeters:
        return this.OhmMeters;
      case ElectricResistivityUnits.OhmsCentimeter:
        return this.OhmsCentimeter;
      case ElectricResistivityUnits.PicoohmMeters:
        return this.PicoohmMeters;
      case ElectricResistivityUnits.NanoohmMeters:
        return this.NanoohmMeters;
      case ElectricResistivityUnits.MicroohmMeters:
        return this.MicroohmMeters;
      case ElectricResistivityUnits.MilliohmMeters:
        return this.MilliohmMeters;
      case ElectricResistivityUnits.KiloohmMeters:
        return this.KiloohmMeters;
      case ElectricResistivityUnits.MegaohmMeters:
        return this.MegaohmMeters;
      case ElectricResistivityUnits.PicoohmsCentimeter:
        return this.PicoohmsCentimeter;
      case ElectricResistivityUnits.NanoohmsCentimeter:
        return this.NanoohmsCentimeter;
      case ElectricResistivityUnits.MicroohmsCentimeter:
        return this.MicroohmsCentimeter;
      case ElectricResistivityUnits.MilliohmsCentimeter:
        return this.MilliohmsCentimeter;
      case ElectricResistivityUnits.KiloohmsCentimeter:
        return this.KiloohmsCentimeter;
      case ElectricResistivityUnits.MegaohmsCentimeter:
        return this.MegaohmsCentimeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricResistivityUnits.OhmMeters:
        return this.value;
      case ElectricResistivityUnits.OhmsCentimeter:
        return super.internalMultiply(this.value, 100);
      case ElectricResistivityUnits.PicoohmMeters:
        return super.internalDivide(this.value, 1e-12);
      case ElectricResistivityUnits.NanoohmMeters:
        return super.internalDivide(this.value, 1e-9);
      case ElectricResistivityUnits.MicroohmMeters:
        return super.internalDivide(this.value, 0.000001);
      case ElectricResistivityUnits.MilliohmMeters:
        return super.internalDivide(this.value, 0.001);
      case ElectricResistivityUnits.KiloohmMeters:
        return super.internalDivide(this.value, 1000);
      case ElectricResistivityUnits.MegaohmMeters:
        return super.internalDivide(this.value, 1000000);
      case ElectricResistivityUnits.PicoohmsCentimeter:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 1e-12);
        }
      case ElectricResistivityUnits.NanoohmsCentimeter:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 1e-9);
        }
      case ElectricResistivityUnits.MicroohmsCentimeter:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 0.000001);
        }
      case ElectricResistivityUnits.MilliohmsCentimeter:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 0.001);
        }
      case ElectricResistivityUnits.KiloohmsCentimeter:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 1000);
        }
      case ElectricResistivityUnits.MegaohmsCentimeter:
        {
          const v3 = super.internalMultiply(this.value, 100);
          return super.internalDivide(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricResistivityUnits.OhmMeters:
        return this.value;
      case ElectricResistivityUnits.OhmsCentimeter:
        return this.value * 100;
      case ElectricResistivityUnits.PicoohmMeters:
        return this.value / 1e-12;
      case ElectricResistivityUnits.NanoohmMeters:
        return this.value / 1e-9;
      case ElectricResistivityUnits.MicroohmMeters:
        return this.value / 0.000001;
      case ElectricResistivityUnits.MilliohmMeters:
        return this.value / 0.001;
      case ElectricResistivityUnits.KiloohmMeters:
        return this.value / 1000;
      case ElectricResistivityUnits.MegaohmMeters:
        return this.value / 1000000;
      case ElectricResistivityUnits.PicoohmsCentimeter:
        return this.value * 100 / 1e-12;
      case ElectricResistivityUnits.NanoohmsCentimeter:
        return this.value * 100 / 1e-9;
      case ElectricResistivityUnits.MicroohmsCentimeter:
        return this.value * 100 / 0.000001;
      case ElectricResistivityUnits.MilliohmsCentimeter:
        return this.value * 100 / 0.001;
      case ElectricResistivityUnits.KiloohmsCentimeter:
        return this.value * 100 / 1000;
      case ElectricResistivityUnits.MegaohmsCentimeter:
        return this.value * 100 / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricResistivityUnits.OhmMeters:
        return value;
      case ElectricResistivityUnits.OhmsCentimeter:
        return super.internalDivide(value, 100);
      case ElectricResistivityUnits.PicoohmMeters:
        return super.internalMultiply(value, 1e-12);
      case ElectricResistivityUnits.NanoohmMeters:
        return super.internalMultiply(value, 1e-9);
      case ElectricResistivityUnits.MicroohmMeters:
        return super.internalMultiply(value, 0.000001);
      case ElectricResistivityUnits.MilliohmMeters:
        return super.internalMultiply(value, 0.001);
      case ElectricResistivityUnits.KiloohmMeters:
        return super.internalMultiply(value, 1000);
      case ElectricResistivityUnits.MegaohmMeters:
        return super.internalMultiply(value, 1000000);
      case ElectricResistivityUnits.PicoohmsCentimeter:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 1e-12);
        }
      case ElectricResistivityUnits.NanoohmsCentimeter:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 1e-9);
        }
      case ElectricResistivityUnits.MicroohmsCentimeter:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 0.000001);
        }
      case ElectricResistivityUnits.MilliohmsCentimeter:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 0.001);
        }
      case ElectricResistivityUnits.KiloohmsCentimeter:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 1000);
        }
      case ElectricResistivityUnits.MegaohmsCentimeter:
        {
          const v3 = super.internalDivide(value, 100);
          return super.internalMultiply(v3, 1000000);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricResistivityUnits.OhmMeters:
        return value;
      case ElectricResistivityUnits.OhmsCentimeter:
        return value / 100;
      case ElectricResistivityUnits.PicoohmMeters:
        return value * 1e-12;
      case ElectricResistivityUnits.NanoohmMeters:
        return value * 1e-9;
      case ElectricResistivityUnits.MicroohmMeters:
        return value * 0.000001;
      case ElectricResistivityUnits.MilliohmMeters:
        return value * 0.001;
      case ElectricResistivityUnits.KiloohmMeters:
        return value * 1000;
      case ElectricResistivityUnits.MegaohmMeters:
        return value * 1000000;
      case ElectricResistivityUnits.PicoohmsCentimeter:
        return value / 100 * 1e-12;
      case ElectricResistivityUnits.NanoohmsCentimeter:
        return value / 100 * 1e-9;
      case ElectricResistivityUnits.MicroohmsCentimeter:
        return value / 100 * 0.000001;
      case ElectricResistivityUnits.MilliohmsCentimeter:
        return value / 100 * 0.001;
      case ElectricResistivityUnits.KiloohmsCentimeter:
        return value / 100 * 1000;
      case ElectricResistivityUnits.MegaohmsCentimeter:
        return value / 100 * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricResistivity to string.
   * Note! the default format for ElectricResistivity is OhmMeters.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricResistivity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricResistivity.
   */
  toString(unit = ElectricResistivityUnits.OhmMeters, fractionalDigits) {
    switch (unit) {
      case ElectricResistivityUnits.OhmMeters:
        return super.truncateFractionDigits(this.OhmMeters, fractionalDigits) + ` Ω·m`;
      case ElectricResistivityUnits.OhmsCentimeter:
        return super.truncateFractionDigits(this.OhmsCentimeter, fractionalDigits) + ` Ω·cm`;
      case ElectricResistivityUnits.PicoohmMeters:
        return super.truncateFractionDigits(this.PicoohmMeters, fractionalDigits) + ` pΩ·m`;
      case ElectricResistivityUnits.NanoohmMeters:
        return super.truncateFractionDigits(this.NanoohmMeters, fractionalDigits) + ` nΩ·m`;
      case ElectricResistivityUnits.MicroohmMeters:
        return super.truncateFractionDigits(this.MicroohmMeters, fractionalDigits) + ` μΩ·m`;
      case ElectricResistivityUnits.MilliohmMeters:
        return super.truncateFractionDigits(this.MilliohmMeters, fractionalDigits) + ` mΩ·m`;
      case ElectricResistivityUnits.KiloohmMeters:
        return super.truncateFractionDigits(this.KiloohmMeters, fractionalDigits) + ` kΩ·m`;
      case ElectricResistivityUnits.MegaohmMeters:
        return super.truncateFractionDigits(this.MegaohmMeters, fractionalDigits) + ` MΩ·m`;
      case ElectricResistivityUnits.PicoohmsCentimeter:
        return super.truncateFractionDigits(this.PicoohmsCentimeter, fractionalDigits) + ` pΩ·cm`;
      case ElectricResistivityUnits.NanoohmsCentimeter:
        return super.truncateFractionDigits(this.NanoohmsCentimeter, fractionalDigits) + ` nΩ·cm`;
      case ElectricResistivityUnits.MicroohmsCentimeter:
        return super.truncateFractionDigits(this.MicroohmsCentimeter, fractionalDigits) + ` μΩ·cm`;
      case ElectricResistivityUnits.MilliohmsCentimeter:
        return super.truncateFractionDigits(this.MilliohmsCentimeter, fractionalDigits) + ` mΩ·cm`;
      case ElectricResistivityUnits.KiloohmsCentimeter:
        return super.truncateFractionDigits(this.KiloohmsCentimeter, fractionalDigits) + ` kΩ·cm`;
      case ElectricResistivityUnits.MegaohmsCentimeter:
        return super.truncateFractionDigits(this.MegaohmsCentimeter, fractionalDigits) + ` MΩ·cm`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricResistivity unit abbreviation.
   * Note! the default abbreviation for ElectricResistivity is OhmMeters.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricResistivity.
   * @returns The abbreviation string of ElectricResistivity.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricResistivityUnits.OhmMeters) {
    switch (unitAbbreviation) {
      case ElectricResistivityUnits.OhmMeters:
        return `Ω·m`;
      case ElectricResistivityUnits.OhmsCentimeter:
        return `Ω·cm`;
      case ElectricResistivityUnits.PicoohmMeters:
        return `pΩ·m`;
      case ElectricResistivityUnits.NanoohmMeters:
        return `nΩ·m`;
      case ElectricResistivityUnits.MicroohmMeters:
        return `μΩ·m`;
      case ElectricResistivityUnits.MilliohmMeters:
        return `mΩ·m`;
      case ElectricResistivityUnits.KiloohmMeters:
        return `kΩ·m`;
      case ElectricResistivityUnits.MegaohmMeters:
        return `MΩ·m`;
      case ElectricResistivityUnits.PicoohmsCentimeter:
        return `pΩ·cm`;
      case ElectricResistivityUnits.NanoohmsCentimeter:
        return `nΩ·cm`;
      case ElectricResistivityUnits.MicroohmsCentimeter:
        return `μΩ·cm`;
      case ElectricResistivityUnits.MilliohmsCentimeter:
        return `mΩ·cm`;
      case ElectricResistivityUnits.KiloohmsCentimeter:
        return `kΩ·cm`;
      case ElectricResistivityUnits.MegaohmsCentimeter:
        return `MΩ·cm`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricResistivity are equals to the current ElectricResistivity.
   * @param electricResistivity The other ElectricResistivity.
   * @returns True if the given ElectricResistivity are equal to the current ElectricResistivity.
   */
  equals(electricResistivity) {
    return super.internalEquals(this.value, electricResistivity.BaseValue);
  }
  /**
   * Compare the given ElectricResistivity against the current ElectricResistivity.
   * @param electricResistivity The other ElectricResistivity.
   * @returns 0 if they are equal, -1 if the current ElectricResistivity is less then other, 1 if the current ElectricResistivity is greater then other.
   */
  compareTo(electricResistivity) {
    return super.internalCompareTo(this.value, electricResistivity.BaseValue);
  }
  /**
   * Add the given ElectricResistivity with the current ElectricResistivity.
   * @param electricResistivity The other ElectricResistivity.
   * @returns A new ElectricResistivity instance with the results.
   */
  add(electricResistivity) {
    return new ElectricResistivity(super.internalAdd(this.value, electricResistivity.BaseValue));
  }
  /**
   * Subtract the given ElectricResistivity with the current ElectricResistivity.
   * @param electricResistivity The other ElectricResistivity.
   * @returns A new ElectricResistivity instance with the results.
   */
  subtract(electricResistivity) {
    return new ElectricResistivity(super.internalSubtract(this.value, electricResistivity.BaseValue));
  }
  /**
   * Multiply the given ElectricResistivity with the current ElectricResistivity.
   * @param electricResistivity The other ElectricResistivity.
   * @returns A new ElectricResistivity instance with the results.
   */
  multiply(electricResistivity) {
    return new ElectricResistivity(super.internalMultiply(this.value, electricResistivity.BaseValue));
  }
  /**
   * Divide the given ElectricResistivity with the current ElectricResistivity.
   * @param electricResistivity The other ElectricResistivity.
   * @returns A new ElectricResistivity instance with the results.
   */
  divide(electricResistivity) {
    return new ElectricResistivity(super.internalDivide(this.value, electricResistivity.BaseValue));
  }
  /**
   * Modulo the given ElectricResistivity with the current ElectricResistivity.
   * @param electricResistivity The other ElectricResistivity.
   * @returns A new ElectricResistivity instance with the results.
   */
  modulo(electricResistivity) {
    return new ElectricResistivity(super.internalModulo(this.value, electricResistivity.BaseValue));
  }
  /**
   * Pow the given ElectricResistivity with the current ElectricResistivity.
   * @param electricResistivity The other ElectricResistivity.
   * @returns A new ElectricResistivity instance with the results.
   */
  pow(electricResistivity) {
    return new ElectricResistivity(super.internalPow(this.value, electricResistivity.BaseValue));
  }
}
exports.ElectricResistivity = ElectricResistivity;