import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ILoggingData } from "../models/logging";
import { BaseService } from "./base.service";
import { AppConstants } from '../shared/constants';
import { DatePipe } from "@angular/common";

@Injectable({
    providedIn: "root",
})
export class LoggingService {

    constructor(private baseService: BaseService, public datepipe: DatePipe) {
    }

    loggingObject: ILoggingData[] = [];
    screenName: string = '';

    getLoggingObject() {
        return this.loggingObject;
    }

    setScreenName(name: string) {
        this.screenName = name;
    }

    setLoggingObject(type: string, message: string, email: string, img: any) {
        let obj: ILoggingData = {
            id: null,
            email: "",
            screen: "",
            type: "",
            message: "",
            exception: "",
            screenShot: "",
            isCleared: false,
            actionBy: "",
            actionOn: this.datepipe.transform(new Date(), 'MM/dd/yyyy hh:mm:ss') || ""
        };
        if (type === AppConstants.Error) {
            // this.capture.capturetobase64().then((canvas: any) => {
            //obj.screenShot = canvas.toDataURL("image/jpeg", 0.1);
            obj.screenShot = img;
            obj.screen = this.screenName;
            obj.type = type;
            obj.message = message;
            obj.email = email;
            obj.actionOn = obj.actionOn;
            this.loggingObject.push(obj);
            // })
        }
        else {
            obj.screen = this.screenName;
            obj.type = type;
            obj.message = message;
            obj.email = email;
            obj.actionOn = obj.actionOn;
            this.loggingObject.push(obj);
        }
    }

    bulkLoggingUpdate(data: ILoggingData[]) {
        this.loggingObject = data
    }

    clearLoggingObject() {
        this.loggingObject = [];
    }

    fetchLoggingData(email: string): Observable<ILoggingData[]> {

        let params = new HttpParams();
        params = params.set('email', email);
        let url = 'UserActivity/FetchUserActivities';
        return this.baseService.Post(url, params).pipe(
            map((result: any) => result, (error: any) => error))
    }
    updateLoggingObject(email: string) {
        let params = new HttpParams();
        params = params.set('email', email);
        let url = 'UserActivity/ClearUserActivities';
        return this.baseService.Post(url, params).pipe(
            map((result: any) => result, (error: any) => error))
    }
    saveLoggingData(email: string) {
        let url = 'UserActivity/SubmitUserActivities';
        let unsaved = this.loggingObject.filter(s => s.id == null);
        if (unsaved.length > 0) {
            this.loggingObject.map(s => {
                s.email = email;
            })
            return this.baseService.Post(url, unsaved).pipe(
                map((result: any) => result, (error: any) => error));
        }
        else
            return of([]);
    }
}