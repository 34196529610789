import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { LoadingComponent } from './components/loading/loading.component';
import { PrimeNGModule } from './primeng.module';
import { CustomButtonComponent } from './AgGrid/custom-button/custom-button.component';
import { RecipeDetailsComponent } from './components/recipe-details/recipe-details.component';
import { CustomCellEditorComponent } from './AgGrid/custom-cell-editor/custom-cell-editor.component';
import { IngredientCountryLabelComponent } from './components/ingredient-country-label/ingredient-country-label.component';
import { CustomViewComponent } from './AgGrid/custom-view/custom-view.component';
import { HistoryComponent } from './components/history/history.component';
import { CustomCellDecimalEditorComponent } from './AgGrid/custom-cell-decimal-editor/custom-cell-decimal-editor.component';
import { MultiSelectCellEditorComponent } from './AgGrid/multiselect-cell-editor/multiselect-cell-editor.component';

@NgModule({
    declarations: [
        LoadingComponent,
        CustomButtonComponent,
        RecipeDetailsComponent,
        IngredientCountryLabelComponent,
        CustomCellEditorComponent,
        CustomViewComponent,
        HistoryComponent,
        CustomCellDecimalEditorComponent,
        MultiSelectCellEditorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule,
        PrimeNGModule
    ],
    exports: [
        LoadingComponent,
        CustomButtonComponent,
        RecipeDetailsComponent,
        CustomCellEditorComponent,
        CustomViewComponent,
        AgGridModule,
        PrimeNGModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        IngredientCountryLabelComponent,
        HistoryComponent,
        CustomCellDecimalEditorComponent,
        MultiSelectCellEditorComponent
    ]
})
export class SharedModule { }
