"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RotationalAcceleration = exports.RotationalAccelerationUnits = void 0;
const base_unit_1 = require("../base-unit");
/** RotationalAccelerationUnits enumeration */
var RotationalAccelerationUnits;
(function (RotationalAccelerationUnits) {
  /** */
  RotationalAccelerationUnits["RadiansPerSecondSquared"] = "RadianPerSecondSquared";
  /** */
  RotationalAccelerationUnits["DegreesPerSecondSquared"] = "DegreePerSecondSquared";
  /** */
  RotationalAccelerationUnits["RevolutionsPerMinutePerSecond"] = "RevolutionPerMinutePerSecond";
  /** */
  RotationalAccelerationUnits["RevolutionsPerSecondSquared"] = "RevolutionPerSecondSquared";
})(RotationalAccelerationUnits = exports.RotationalAccelerationUnits || (exports.RotationalAccelerationUnits = {}));
/** Angular acceleration is the rate of change of rotational speed. */
class RotationalAcceleration extends base_unit_1.BaseUnit {
  /**
   * Create a new RotationalAcceleration.
   * @param value The value.
   * @param fromUnit The ‘RotationalAcceleration’ unit to create from.
   * The default unit is RadiansPerSecondSquared
   */
  constructor(value, fromUnit = RotationalAccelerationUnits.RadiansPerSecondSquared) {
    super();
    this.radianspersecondsquaredLazy = null;
    this.degreespersecondsquaredLazy = null;
    this.revolutionsperminutepersecondLazy = null;
    this.revolutionspersecondsquaredLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of RotationalAcceleration is RadiansPerSecondSquared.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return RotationalAccelerationUnits.RadiansPerSecondSquared;
  }
  /** */
  get RadiansPerSecondSquared() {
    if (this.radianspersecondsquaredLazy !== null) {
      return this.radianspersecondsquaredLazy;
    }
    return this.radianspersecondsquaredLazy = this.convertFromBase(RotationalAccelerationUnits.RadiansPerSecondSquared);
  }
  /** */
  get DegreesPerSecondSquared() {
    if (this.degreespersecondsquaredLazy !== null) {
      return this.degreespersecondsquaredLazy;
    }
    return this.degreespersecondsquaredLazy = this.convertFromBase(RotationalAccelerationUnits.DegreesPerSecondSquared);
  }
  /** */
  get RevolutionsPerMinutePerSecond() {
    if (this.revolutionsperminutepersecondLazy !== null) {
      return this.revolutionsperminutepersecondLazy;
    }
    return this.revolutionsperminutepersecondLazy = this.convertFromBase(RotationalAccelerationUnits.RevolutionsPerMinutePerSecond);
  }
  /** */
  get RevolutionsPerSecondSquared() {
    if (this.revolutionspersecondsquaredLazy !== null) {
      return this.revolutionspersecondsquaredLazy;
    }
    return this.revolutionspersecondsquaredLazy = this.convertFromBase(RotationalAccelerationUnits.RevolutionsPerSecondSquared);
  }
  /**
   * Create a new RotationalAcceleration instance from a RadiansPerSecondSquared
   *
   * @param value The unit as RadiansPerSecondSquared to create a new RotationalAcceleration from.
   * @returns The new RotationalAcceleration instance.
   */
  static FromRadiansPerSecondSquared(value) {
    return new RotationalAcceleration(value, RotationalAccelerationUnits.RadiansPerSecondSquared);
  }
  /**
   * Create a new RotationalAcceleration instance from a DegreesPerSecondSquared
   *
   * @param value The unit as DegreesPerSecondSquared to create a new RotationalAcceleration from.
   * @returns The new RotationalAcceleration instance.
   */
  static FromDegreesPerSecondSquared(value) {
    return new RotationalAcceleration(value, RotationalAccelerationUnits.DegreesPerSecondSquared);
  }
  /**
   * Create a new RotationalAcceleration instance from a RevolutionsPerMinutePerSecond
   *
   * @param value The unit as RevolutionsPerMinutePerSecond to create a new RotationalAcceleration from.
   * @returns The new RotationalAcceleration instance.
   */
  static FromRevolutionsPerMinutePerSecond(value) {
    return new RotationalAcceleration(value, RotationalAccelerationUnits.RevolutionsPerMinutePerSecond);
  }
  /**
   * Create a new RotationalAcceleration instance from a RevolutionsPerSecondSquared
   *
   * @param value The unit as RevolutionsPerSecondSquared to create a new RotationalAcceleration from.
   * @returns The new RotationalAcceleration instance.
   */
  static FromRevolutionsPerSecondSquared(value) {
    return new RotationalAcceleration(value, RotationalAccelerationUnits.RevolutionsPerSecondSquared);
  }
  /**
   * Gets the base unit enumeration associated with RotationalAcceleration
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return RotationalAccelerationUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return RotationalAccelerationUnits.RadiansPerSecondSquared;
  }
  /**
   * Create API DTO represent a RotationalAcceleration unit.
   * @param holdInUnit The specific RotationalAcceleration unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = RotationalAccelerationUnits.RadiansPerSecondSquared) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a RotationalAcceleration unit from an API DTO representation.
   * @param dtoRotationalAcceleration The RotationalAcceleration API DTO representation
   */
  static FromDto(dtoRotationalAcceleration) {
    return new RotationalAcceleration(dtoRotationalAcceleration.value, dtoRotationalAcceleration.unit);
  }
  /**
   * Convert RotationalAcceleration to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case RotationalAccelerationUnits.RadiansPerSecondSquared:
        return this.RadiansPerSecondSquared;
      case RotationalAccelerationUnits.DegreesPerSecondSquared:
        return this.DegreesPerSecondSquared;
      case RotationalAccelerationUnits.RevolutionsPerMinutePerSecond:
        return this.RevolutionsPerMinutePerSecond;
      case RotationalAccelerationUnits.RevolutionsPerSecondSquared:
        return this.RevolutionsPerSecondSquared;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case RotationalAccelerationUnits.RadiansPerSecondSquared:
        return this.value;
      case RotationalAccelerationUnits.DegreesPerSecondSquared:
        {
          const v3 = super.internalDivide(180, Math.PI);
          return super.internalMultiply(v3, this.value);
        }
      case RotationalAccelerationUnits.RevolutionsPerMinutePerSecond:
        {
          const v4 = super.internalMultiply(2, Math.PI);
          const v5 = super.internalDivide(60, v4);
          return super.internalMultiply(v5, this.value);
        }
      case RotationalAccelerationUnits.RevolutionsPerSecondSquared:
        {
          const v4 = super.internalMultiply(2, Math.PI);
          const v5 = super.internalDivide(1, v4);
          return super.internalMultiply(v5, this.value);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case RotationalAccelerationUnits.RadiansPerSecondSquared:
        return this.value;
      case RotationalAccelerationUnits.DegreesPerSecondSquared:
        return 180 / Math.PI * this.value;
      case RotationalAccelerationUnits.RevolutionsPerMinutePerSecond:
        return 60 / (2 * Math.PI) * this.value;
      case RotationalAccelerationUnits.RevolutionsPerSecondSquared:
        return 1 / (2 * Math.PI) * this.value;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case RotationalAccelerationUnits.RadiansPerSecondSquared:
        return value;
      case RotationalAccelerationUnits.DegreesPerSecondSquared:
        {
          const v3 = super.internalDivide(Math.PI, 180);
          return super.internalMultiply(v3, value);
        }
      case RotationalAccelerationUnits.RevolutionsPerMinutePerSecond:
        {
          const v3 = super.internalMultiply(2, Math.PI);
          const v5 = super.internalDivide(v3, 60);
          return super.internalMultiply(v5, value);
        }
      case RotationalAccelerationUnits.RevolutionsPerSecondSquared:
        {
          const v3 = super.internalMultiply(2, Math.PI);
          return super.internalMultiply(v3, value);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case RotationalAccelerationUnits.RadiansPerSecondSquared:
        return value;
      case RotationalAccelerationUnits.DegreesPerSecondSquared:
        return Math.PI / 180 * value;
      case RotationalAccelerationUnits.RevolutionsPerMinutePerSecond:
        return 2 * Math.PI / 60 * value;
      case RotationalAccelerationUnits.RevolutionsPerSecondSquared:
        return 2 * Math.PI * value;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the RotationalAcceleration to string.
   * Note! the default format for RotationalAcceleration is RadiansPerSecondSquared.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the RotationalAcceleration.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the RotationalAcceleration.
   */
  toString(unit = RotationalAccelerationUnits.RadiansPerSecondSquared, fractionalDigits) {
    switch (unit) {
      case RotationalAccelerationUnits.RadiansPerSecondSquared:
        return super.truncateFractionDigits(this.RadiansPerSecondSquared, fractionalDigits) + ` rad/s²`;
      case RotationalAccelerationUnits.DegreesPerSecondSquared:
        return super.truncateFractionDigits(this.DegreesPerSecondSquared, fractionalDigits) + ` °/s²`;
      case RotationalAccelerationUnits.RevolutionsPerMinutePerSecond:
        return super.truncateFractionDigits(this.RevolutionsPerMinutePerSecond, fractionalDigits) + ` rpm/s`;
      case RotationalAccelerationUnits.RevolutionsPerSecondSquared:
        return super.truncateFractionDigits(this.RevolutionsPerSecondSquared, fractionalDigits) + ` r/s²`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get RotationalAcceleration unit abbreviation.
   * Note! the default abbreviation for RotationalAcceleration is RadiansPerSecondSquared.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the RotationalAcceleration.
   * @returns The abbreviation string of RotationalAcceleration.
   */
  getUnitAbbreviation(unitAbbreviation = RotationalAccelerationUnits.RadiansPerSecondSquared) {
    switch (unitAbbreviation) {
      case RotationalAccelerationUnits.RadiansPerSecondSquared:
        return `rad/s²`;
      case RotationalAccelerationUnits.DegreesPerSecondSquared:
        return `°/s²`;
      case RotationalAccelerationUnits.RevolutionsPerMinutePerSecond:
        return `rpm/s`;
      case RotationalAccelerationUnits.RevolutionsPerSecondSquared:
        return `r/s²`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given RotationalAcceleration are equals to the current RotationalAcceleration.
   * @param rotationalAcceleration The other RotationalAcceleration.
   * @returns True if the given RotationalAcceleration are equal to the current RotationalAcceleration.
   */
  equals(rotationalAcceleration) {
    return super.internalEquals(this.value, rotationalAcceleration.BaseValue);
  }
  /**
   * Compare the given RotationalAcceleration against the current RotationalAcceleration.
   * @param rotationalAcceleration The other RotationalAcceleration.
   * @returns 0 if they are equal, -1 if the current RotationalAcceleration is less then other, 1 if the current RotationalAcceleration is greater then other.
   */
  compareTo(rotationalAcceleration) {
    return super.internalCompareTo(this.value, rotationalAcceleration.BaseValue);
  }
  /**
   * Add the given RotationalAcceleration with the current RotationalAcceleration.
   * @param rotationalAcceleration The other RotationalAcceleration.
   * @returns A new RotationalAcceleration instance with the results.
   */
  add(rotationalAcceleration) {
    return new RotationalAcceleration(super.internalAdd(this.value, rotationalAcceleration.BaseValue));
  }
  /**
   * Subtract the given RotationalAcceleration with the current RotationalAcceleration.
   * @param rotationalAcceleration The other RotationalAcceleration.
   * @returns A new RotationalAcceleration instance with the results.
   */
  subtract(rotationalAcceleration) {
    return new RotationalAcceleration(super.internalSubtract(this.value, rotationalAcceleration.BaseValue));
  }
  /**
   * Multiply the given RotationalAcceleration with the current RotationalAcceleration.
   * @param rotationalAcceleration The other RotationalAcceleration.
   * @returns A new RotationalAcceleration instance with the results.
   */
  multiply(rotationalAcceleration) {
    return new RotationalAcceleration(super.internalMultiply(this.value, rotationalAcceleration.BaseValue));
  }
  /**
   * Divide the given RotationalAcceleration with the current RotationalAcceleration.
   * @param rotationalAcceleration The other RotationalAcceleration.
   * @returns A new RotationalAcceleration instance with the results.
   */
  divide(rotationalAcceleration) {
    return new RotationalAcceleration(super.internalDivide(this.value, rotationalAcceleration.BaseValue));
  }
  /**
   * Modulo the given RotationalAcceleration with the current RotationalAcceleration.
   * @param rotationalAcceleration The other RotationalAcceleration.
   * @returns A new RotationalAcceleration instance with the results.
   */
  modulo(rotationalAcceleration) {
    return new RotationalAcceleration(super.internalModulo(this.value, rotationalAcceleration.BaseValue));
  }
  /**
   * Pow the given RotationalAcceleration with the current RotationalAcceleration.
   * @param rotationalAcceleration The other RotationalAcceleration.
   * @returns A new RotationalAcceleration instance with the results.
   */
  pow(rotationalAcceleration) {
    return new RotationalAcceleration(super.internalPow(this.value, rotationalAcceleration.BaseValue));
  }
}
exports.RotationalAcceleration = RotationalAcceleration;