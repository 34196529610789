<div class="container-fluid">
    <div class="row">

        <div class="col-xs-12 mb-2 text-right">
            <p-button label="Add" (onClick)="addFactstoIngredient()" [disabled]="!isAdmin" pTooltip="{{roleToolTip}}"
                icon="pi pi-plus" tooltipPosition="bottom" class="mr-2" />
            <p-button label="Save" (onClick)="saveCounrtyLabelIngredient()" [disabled]="!isAdmin || !isCellEditorStart"
                icon="pi pi-save" pTooltip="{{roleToolTip}}" tooltipPosition="bottom" class="mr-2" />
            <p-button label="Delete" (onClick)="onCounrtyLabelIngredientDelete()"
                [disabled]="!isAdmin || selectedCoubtryLabelRows.length===0" pTooltip="{{roleToolTip}}"
                tooltipPosition="bottom" class="mr-2" icon="pi pi-minus" />
            <p-button label="Cancel" (onClick)="onCountryLabelModalCancel()" [disabled]="!isAdmin" icon="pi pi-times"
                pTooltip="{{roleToolTip}}" tooltipPosition="bottom" class="mr-2" />
        </div>
    </div>
    <p-confirmDialog key="Country Label As"></p-confirmDialog>

    <ag-grid-angular [gridOptions]="gridCountryLabelOptions" class="ag-theme-alpine grid-size" [animateRows]="true"
        (gridReady)="onCountryLabelGridReady($event)" [statusBar]="statusBar" [rowSelection]="'multiple'"
        [enableRangeSelection]="true" (cellEditingStopped)="onCellEditingStopped($event)"
        (selectionChanged)='onCountryLabelSelectionChanged($event)'
        (cellEditingStarted)="onCellEditingStarted($event)"></ag-grid-angular>
</div>