"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MolarEnergy = exports.MolarEnergyUnits = void 0;
const base_unit_1 = require("../base-unit");
/** MolarEnergyUnits enumeration */
var MolarEnergyUnits;
(function (MolarEnergyUnits) {
  /** */
  MolarEnergyUnits["JoulesPerMole"] = "JoulePerMole";
  /** */
  MolarEnergyUnits["KilojoulesPerMole"] = "KilojoulePerMole";
  /** */
  MolarEnergyUnits["MegajoulesPerMole"] = "MegajoulePerMole";
})(MolarEnergyUnits = exports.MolarEnergyUnits || (exports.MolarEnergyUnits = {}));
/** Molar energy is the amount of energy stored in 1 mole of a substance. */
class MolarEnergy extends base_unit_1.BaseUnit {
  /**
   * Create a new MolarEnergy.
   * @param value The value.
   * @param fromUnit The ‘MolarEnergy’ unit to create from.
   * The default unit is JoulesPerMole
   */
  constructor(value, fromUnit = MolarEnergyUnits.JoulesPerMole) {
    super();
    this.joulespermoleLazy = null;
    this.kilojoulespermoleLazy = null;
    this.megajoulespermoleLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of MolarEnergy is JoulesPerMole.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return MolarEnergyUnits.JoulesPerMole;
  }
  /** */
  get JoulesPerMole() {
    if (this.joulespermoleLazy !== null) {
      return this.joulespermoleLazy;
    }
    return this.joulespermoleLazy = this.convertFromBase(MolarEnergyUnits.JoulesPerMole);
  }
  /** */
  get KilojoulesPerMole() {
    if (this.kilojoulespermoleLazy !== null) {
      return this.kilojoulespermoleLazy;
    }
    return this.kilojoulespermoleLazy = this.convertFromBase(MolarEnergyUnits.KilojoulesPerMole);
  }
  /** */
  get MegajoulesPerMole() {
    if (this.megajoulespermoleLazy !== null) {
      return this.megajoulespermoleLazy;
    }
    return this.megajoulespermoleLazy = this.convertFromBase(MolarEnergyUnits.MegajoulesPerMole);
  }
  /**
   * Create a new MolarEnergy instance from a JoulesPerMole
   *
   * @param value The unit as JoulesPerMole to create a new MolarEnergy from.
   * @returns The new MolarEnergy instance.
   */
  static FromJoulesPerMole(value) {
    return new MolarEnergy(value, MolarEnergyUnits.JoulesPerMole);
  }
  /**
   * Create a new MolarEnergy instance from a KilojoulesPerMole
   *
   * @param value The unit as KilojoulesPerMole to create a new MolarEnergy from.
   * @returns The new MolarEnergy instance.
   */
  static FromKilojoulesPerMole(value) {
    return new MolarEnergy(value, MolarEnergyUnits.KilojoulesPerMole);
  }
  /**
   * Create a new MolarEnergy instance from a MegajoulesPerMole
   *
   * @param value The unit as MegajoulesPerMole to create a new MolarEnergy from.
   * @returns The new MolarEnergy instance.
   */
  static FromMegajoulesPerMole(value) {
    return new MolarEnergy(value, MolarEnergyUnits.MegajoulesPerMole);
  }
  /**
   * Gets the base unit enumeration associated with MolarEnergy
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return MolarEnergyUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return MolarEnergyUnits.JoulesPerMole;
  }
  /**
   * Create API DTO represent a MolarEnergy unit.
   * @param holdInUnit The specific MolarEnergy unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = MolarEnergyUnits.JoulesPerMole) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a MolarEnergy unit from an API DTO representation.
   * @param dtoMolarEnergy The MolarEnergy API DTO representation
   */
  static FromDto(dtoMolarEnergy) {
    return new MolarEnergy(dtoMolarEnergy.value, dtoMolarEnergy.unit);
  }
  /**
   * Convert MolarEnergy to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case MolarEnergyUnits.JoulesPerMole:
        return this.JoulesPerMole;
      case MolarEnergyUnits.KilojoulesPerMole:
        return this.KilojoulesPerMole;
      case MolarEnergyUnits.MegajoulesPerMole:
        return this.MegajoulesPerMole;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case MolarEnergyUnits.JoulesPerMole:
        return this.value;
      case MolarEnergyUnits.KilojoulesPerMole:
        return super.internalDivide(this.value, 1000);
      case MolarEnergyUnits.MegajoulesPerMole:
        return super.internalDivide(this.value, 1000000);
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case MolarEnergyUnits.JoulesPerMole:
        return this.value;
      case MolarEnergyUnits.KilojoulesPerMole:
        return this.value / 1000;
      case MolarEnergyUnits.MegajoulesPerMole:
        return this.value / 1000000;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case MolarEnergyUnits.JoulesPerMole:
        return value;
      case MolarEnergyUnits.KilojoulesPerMole:
        return super.internalMultiply(value, 1000);
      case MolarEnergyUnits.MegajoulesPerMole:
        return super.internalMultiply(value, 1000000);
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case MolarEnergyUnits.JoulesPerMole:
        return value;
      case MolarEnergyUnits.KilojoulesPerMole:
        return value * 1000;
      case MolarEnergyUnits.MegajoulesPerMole:
        return value * 1000000;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the MolarEnergy to string.
   * Note! the default format for MolarEnergy is JoulesPerMole.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the MolarEnergy.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the MolarEnergy.
   */
  toString(unit = MolarEnergyUnits.JoulesPerMole, fractionalDigits) {
    switch (unit) {
      case MolarEnergyUnits.JoulesPerMole:
        return super.truncateFractionDigits(this.JoulesPerMole, fractionalDigits) + ` J/mol`;
      case MolarEnergyUnits.KilojoulesPerMole:
        return super.truncateFractionDigits(this.KilojoulesPerMole, fractionalDigits) + ` kJ/mol`;
      case MolarEnergyUnits.MegajoulesPerMole:
        return super.truncateFractionDigits(this.MegajoulesPerMole, fractionalDigits) + ` MJ/mol`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get MolarEnergy unit abbreviation.
   * Note! the default abbreviation for MolarEnergy is JoulesPerMole.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the MolarEnergy.
   * @returns The abbreviation string of MolarEnergy.
   */
  getUnitAbbreviation(unitAbbreviation = MolarEnergyUnits.JoulesPerMole) {
    switch (unitAbbreviation) {
      case MolarEnergyUnits.JoulesPerMole:
        return `J/mol`;
      case MolarEnergyUnits.KilojoulesPerMole:
        return `kJ/mol`;
      case MolarEnergyUnits.MegajoulesPerMole:
        return `MJ/mol`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given MolarEnergy are equals to the current MolarEnergy.
   * @param molarEnergy The other MolarEnergy.
   * @returns True if the given MolarEnergy are equal to the current MolarEnergy.
   */
  equals(molarEnergy) {
    return super.internalEquals(this.value, molarEnergy.BaseValue);
  }
  /**
   * Compare the given MolarEnergy against the current MolarEnergy.
   * @param molarEnergy The other MolarEnergy.
   * @returns 0 if they are equal, -1 if the current MolarEnergy is less then other, 1 if the current MolarEnergy is greater then other.
   */
  compareTo(molarEnergy) {
    return super.internalCompareTo(this.value, molarEnergy.BaseValue);
  }
  /**
   * Add the given MolarEnergy with the current MolarEnergy.
   * @param molarEnergy The other MolarEnergy.
   * @returns A new MolarEnergy instance with the results.
   */
  add(molarEnergy) {
    return new MolarEnergy(super.internalAdd(this.value, molarEnergy.BaseValue));
  }
  /**
   * Subtract the given MolarEnergy with the current MolarEnergy.
   * @param molarEnergy The other MolarEnergy.
   * @returns A new MolarEnergy instance with the results.
   */
  subtract(molarEnergy) {
    return new MolarEnergy(super.internalSubtract(this.value, molarEnergy.BaseValue));
  }
  /**
   * Multiply the given MolarEnergy with the current MolarEnergy.
   * @param molarEnergy The other MolarEnergy.
   * @returns A new MolarEnergy instance with the results.
   */
  multiply(molarEnergy) {
    return new MolarEnergy(super.internalMultiply(this.value, molarEnergy.BaseValue));
  }
  /**
   * Divide the given MolarEnergy with the current MolarEnergy.
   * @param molarEnergy The other MolarEnergy.
   * @returns A new MolarEnergy instance with the results.
   */
  divide(molarEnergy) {
    return new MolarEnergy(super.internalDivide(this.value, molarEnergy.BaseValue));
  }
  /**
   * Modulo the given MolarEnergy with the current MolarEnergy.
   * @param molarEnergy The other MolarEnergy.
   * @returns A new MolarEnergy instance with the results.
   */
  modulo(molarEnergy) {
    return new MolarEnergy(super.internalModulo(this.value, molarEnergy.BaseValue));
  }
  /**
   * Pow the given MolarEnergy with the current MolarEnergy.
   * @param molarEnergy The other MolarEnergy.
   * @returns A new MolarEnergy instance with the results.
   */
  pow(molarEnergy) {
    return new MolarEnergy(super.internalPow(this.value, molarEnergy.BaseValue));
  }
}
exports.MolarEnergy = MolarEnergy;