<p-dialog [blockScroll]="true" [(visible)]="openHistoryDialog" [draggable]="false" (onHide)="onClose()" [modal]="true"
  [contentStyle]="{'overflow':'visible'}" [style]="dialogStyle" [baseZIndex]="10000" [dismissableMask]="true"
  appendTo="body">
  <!-- <p-header>
    <div class="row" style="width: 100%;height: 40px;">
      <div class="col-5" style="font-weight: bold;margin-top:5px; margin-left: 20px;"> {{type}} History </div>
      <div class="col">
        <span>
          <button [disabled]="rowData.length === 0" type="button" pButton (click)="saveGridPreference()"
            style="float: right; margin-right: 20px; margin-top: 4px;" data-toggle="tooltip" data-placement="top"
            [title]="'Save column order and size to retain across sessions'">Save Grid Preference</button>
        </span>
      </div>
    </div>
  </p-header> -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 mb-2">
        {{type}} History
      </div>
      <div class="col-md-6 mb-2 text-right">
        <p-button label="Save Grid Preference" icon="pi pi-times" (onClick)="saveGridPreference()" icon="pi pi-save"
          [disabled]="rowData.length === 0" pTooltip="Save Grid Preference" tooltipPosition="bottom" class="mr-2" />
      </div>
    </div>
  </div>

  <!-- AG Grid Angular Component -->
  <div>
    <ag-grid-angular id="agGrid" *ngIf="rowData.length > 0"
     
      class="ag-theme-alpine grid-size" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [animateRows]="true"
      [rowData]="rowData" [keepDetailRows]="false" [getContextMenuItems]="getContextMenuItems"
      [suppressRowClickSelection]="true" [enableRangeSelection]=true [statusBar]="statusBar"
      (gridReady)="onGridReady($event)" [rowSelection]="'multiple'" [animateRows]="true"></ag-grid-angular>
  </div>
</p-dialog>