"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElectricConductivity = exports.ElectricConductivityUnits = void 0;
const base_unit_1 = require("../base-unit");
/** ElectricConductivityUnits enumeration */
var ElectricConductivityUnits;
(function (ElectricConductivityUnits) {
  /** */
  ElectricConductivityUnits["SiemensPerMeter"] = "SiemensPerMeter";
  /** */
  ElectricConductivityUnits["SiemensPerInch"] = "SiemensPerInch";
  /** */
  ElectricConductivityUnits["SiemensPerFoot"] = "SiemensPerFoot";
  /** */
  ElectricConductivityUnits["SiemensPerCentimeter"] = "SiemensPerCentimeter";
  /** */
  ElectricConductivityUnits["MicrosiemensPerCentimeter"] = "MicrosiemensPerCentimeter";
  /** */
  ElectricConductivityUnits["MillisiemensPerCentimeter"] = "MillisiemensPerCentimeter";
})(ElectricConductivityUnits = exports.ElectricConductivityUnits || (exports.ElectricConductivityUnits = {}));
/** Electrical conductivity or specific conductance is the reciprocal of electrical resistivity, and measures a material's ability to conduct an electric current. */
class ElectricConductivity extends base_unit_1.BaseUnit {
  /**
   * Create a new ElectricConductivity.
   * @param value The value.
   * @param fromUnit The ‘ElectricConductivity’ unit to create from.
   * The default unit is SiemensPerMeter
   */
  constructor(value, fromUnit = ElectricConductivityUnits.SiemensPerMeter) {
    super();
    this.siemenspermeterLazy = null;
    this.siemensperinchLazy = null;
    this.siemensperfootLazy = null;
    this.siemenspercentimeterLazy = null;
    this.microsiemenspercentimeterLazy = null;
    this.millisiemenspercentimeterLazy = null;
    if (value === undefined || value === null || Number.isNaN(value)) {
      throw new TypeError('invalid unit value ‘' + value + '’');
    }
    this.value = this.convertToBase(value, fromUnit);
  }
  /**
   * The base value of ElectricConductivity is SiemensPerMeter.
   * This accessor used when needs a value for calculations and it's better to use directly the base value
   */
  get BaseValue() {
    return this.value;
  }
  /** Gets the default unit used when creating instances of the unit or its DTO */
  get baseUnit() {
    return ElectricConductivityUnits.SiemensPerMeter;
  }
  /** */
  get SiemensPerMeter() {
    if (this.siemenspermeterLazy !== null) {
      return this.siemenspermeterLazy;
    }
    return this.siemenspermeterLazy = this.convertFromBase(ElectricConductivityUnits.SiemensPerMeter);
  }
  /** */
  get SiemensPerInch() {
    if (this.siemensperinchLazy !== null) {
      return this.siemensperinchLazy;
    }
    return this.siemensperinchLazy = this.convertFromBase(ElectricConductivityUnits.SiemensPerInch);
  }
  /** */
  get SiemensPerFoot() {
    if (this.siemensperfootLazy !== null) {
      return this.siemensperfootLazy;
    }
    return this.siemensperfootLazy = this.convertFromBase(ElectricConductivityUnits.SiemensPerFoot);
  }
  /** */
  get SiemensPerCentimeter() {
    if (this.siemenspercentimeterLazy !== null) {
      return this.siemenspercentimeterLazy;
    }
    return this.siemenspercentimeterLazy = this.convertFromBase(ElectricConductivityUnits.SiemensPerCentimeter);
  }
  /** */
  get MicrosiemensPerCentimeter() {
    if (this.microsiemenspercentimeterLazy !== null) {
      return this.microsiemenspercentimeterLazy;
    }
    return this.microsiemenspercentimeterLazy = this.convertFromBase(ElectricConductivityUnits.MicrosiemensPerCentimeter);
  }
  /** */
  get MillisiemensPerCentimeter() {
    if (this.millisiemenspercentimeterLazy !== null) {
      return this.millisiemenspercentimeterLazy;
    }
    return this.millisiemenspercentimeterLazy = this.convertFromBase(ElectricConductivityUnits.MillisiemensPerCentimeter);
  }
  /**
   * Create a new ElectricConductivity instance from a SiemensPerMeter
   *
   * @param value The unit as SiemensPerMeter to create a new ElectricConductivity from.
   * @returns The new ElectricConductivity instance.
   */
  static FromSiemensPerMeter(value) {
    return new ElectricConductivity(value, ElectricConductivityUnits.SiemensPerMeter);
  }
  /**
   * Create a new ElectricConductivity instance from a SiemensPerInch
   *
   * @param value The unit as SiemensPerInch to create a new ElectricConductivity from.
   * @returns The new ElectricConductivity instance.
   */
  static FromSiemensPerInch(value) {
    return new ElectricConductivity(value, ElectricConductivityUnits.SiemensPerInch);
  }
  /**
   * Create a new ElectricConductivity instance from a SiemensPerFoot
   *
   * @param value The unit as SiemensPerFoot to create a new ElectricConductivity from.
   * @returns The new ElectricConductivity instance.
   */
  static FromSiemensPerFoot(value) {
    return new ElectricConductivity(value, ElectricConductivityUnits.SiemensPerFoot);
  }
  /**
   * Create a new ElectricConductivity instance from a SiemensPerCentimeter
   *
   * @param value The unit as SiemensPerCentimeter to create a new ElectricConductivity from.
   * @returns The new ElectricConductivity instance.
   */
  static FromSiemensPerCentimeter(value) {
    return new ElectricConductivity(value, ElectricConductivityUnits.SiemensPerCentimeter);
  }
  /**
   * Create a new ElectricConductivity instance from a MicrosiemensPerCentimeter
   *
   * @param value The unit as MicrosiemensPerCentimeter to create a new ElectricConductivity from.
   * @returns The new ElectricConductivity instance.
   */
  static FromMicrosiemensPerCentimeter(value) {
    return new ElectricConductivity(value, ElectricConductivityUnits.MicrosiemensPerCentimeter);
  }
  /**
   * Create a new ElectricConductivity instance from a MillisiemensPerCentimeter
   *
   * @param value The unit as MillisiemensPerCentimeter to create a new ElectricConductivity from.
   * @returns The new ElectricConductivity instance.
   */
  static FromMillisiemensPerCentimeter(value) {
    return new ElectricConductivity(value, ElectricConductivityUnits.MillisiemensPerCentimeter);
  }
  /**
   * Gets the base unit enumeration associated with ElectricConductivity
   * @returns The unit enumeration that can be used to interact with this type
   */
  static getUnitEnum() {
    return ElectricConductivityUnits;
  }
  /**
   * Gets the default unit used when creating instances of the unit or its DTO
   * @returns The unit enumeration value used as a default parameter in constructor and DTO methods
   */
  static getBaseUnit() {
    return ElectricConductivityUnits.SiemensPerMeter;
  }
  /**
   * Create API DTO represent a ElectricConductivity unit.
   * @param holdInUnit The specific ElectricConductivity unit to be used in the unit representation at the DTO
   */
  toDto(holdInUnit = ElectricConductivityUnits.SiemensPerMeter) {
    return {
      value: this.convert(holdInUnit),
      unit: holdInUnit
    };
  }
  /**
   * Create a ElectricConductivity unit from an API DTO representation.
   * @param dtoElectricConductivity The ElectricConductivity API DTO representation
   */
  static FromDto(dtoElectricConductivity) {
    return new ElectricConductivity(dtoElectricConductivity.value, dtoElectricConductivity.unit);
  }
  /**
   * Convert ElectricConductivity to a specific unit value.
   * @param toUnit The specific unit to convert to
   * @returns The value of the specific unit provided.
   */
  convert(toUnit) {
    switch (toUnit) {
      case ElectricConductivityUnits.SiemensPerMeter:
        return this.SiemensPerMeter;
      case ElectricConductivityUnits.SiemensPerInch:
        return this.SiemensPerInch;
      case ElectricConductivityUnits.SiemensPerFoot:
        return this.SiemensPerFoot;
      case ElectricConductivityUnits.SiemensPerCentimeter:
        return this.SiemensPerCentimeter;
      case ElectricConductivityUnits.MicrosiemensPerCentimeter:
        return this.MicrosiemensPerCentimeter;
      case ElectricConductivityUnits.MillisiemensPerCentimeter:
        return this.MillisiemensPerCentimeter;
      default:
        break;
    }
    return Number.NaN;
  }
  convertFromBase(toUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (toUnit) {
      case ElectricConductivityUnits.SiemensPerMeter:
        return this.value;
      case ElectricConductivityUnits.SiemensPerInch:
        return super.internalDivide(this.value, 3.937007874015748e1);
      case ElectricConductivityUnits.SiemensPerFoot:
        return super.internalDivide(this.value, 3.2808398950131234);
      case ElectricConductivityUnits.SiemensPerCentimeter:
        return super.internalDivide(this.value, 1e2);
      case ElectricConductivityUnits.MicrosiemensPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 0.000001);
        }
      case ElectricConductivityUnits.MillisiemensPerCentimeter:
        {
          const v3 = super.internalDivide(this.value, 1e2);
          return super.internalDivide(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (toUnit) {
      case ElectricConductivityUnits.SiemensPerMeter:
        return this.value;
      case ElectricConductivityUnits.SiemensPerInch:
        return this.value / 3.937007874015748e1;
      case ElectricConductivityUnits.SiemensPerFoot:
        return this.value / 3.2808398950131234;
      case ElectricConductivityUnits.SiemensPerCentimeter:
        return this.value / 1e2;
      case ElectricConductivityUnits.MicrosiemensPerCentimeter:
        return this.value / 1e2 / 0.000001;
      case ElectricConductivityUnits.MillisiemensPerCentimeter:
        return this.value / 1e2 / 0.001;
      default:
        return Number.NaN;
    }
  }
  convertToBase(value, fromUnit) {
    if (base_unit_1.areAnyOperatorsOverridden()) switch (fromUnit) {
      case ElectricConductivityUnits.SiemensPerMeter:
        return value;
      case ElectricConductivityUnits.SiemensPerInch:
        return super.internalMultiply(value, 3.937007874015748e1);
      case ElectricConductivityUnits.SiemensPerFoot:
        return super.internalMultiply(value, 3.2808398950131234);
      case ElectricConductivityUnits.SiemensPerCentimeter:
        return super.internalMultiply(value, 1e2);
      case ElectricConductivityUnits.MicrosiemensPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 0.000001);
        }
      case ElectricConductivityUnits.MillisiemensPerCentimeter:
        {
          const v3 = super.internalMultiply(value, 1e2);
          return super.internalMultiply(v3, 0.001);
        }
      default:
        return Number.NaN;
    }
    switch (fromUnit) {
      case ElectricConductivityUnits.SiemensPerMeter:
        return value;
      case ElectricConductivityUnits.SiemensPerInch:
        return value * 3.937007874015748e1;
      case ElectricConductivityUnits.SiemensPerFoot:
        return value * 3.2808398950131234;
      case ElectricConductivityUnits.SiemensPerCentimeter:
        return value * 1e2;
      case ElectricConductivityUnits.MicrosiemensPerCentimeter:
        return value * 1e2 * 0.000001;
      case ElectricConductivityUnits.MillisiemensPerCentimeter:
        return value * 1e2 * 0.001;
      default:
        return Number.NaN;
    }
  }
  /**
   * Format the ElectricConductivity to string.
   * Note! the default format for ElectricConductivity is SiemensPerMeter.
   * To specify the unit format set the 'unit' parameter.
   * @param unit The unit to format the ElectricConductivity.
   * @param fractionalDigits The number of fractional digits to keep.
   * @returns The string format of the ElectricConductivity.
   */
  toString(unit = ElectricConductivityUnits.SiemensPerMeter, fractionalDigits) {
    switch (unit) {
      case ElectricConductivityUnits.SiemensPerMeter:
        return super.truncateFractionDigits(this.SiemensPerMeter, fractionalDigits) + ` S/m`;
      case ElectricConductivityUnits.SiemensPerInch:
        return super.truncateFractionDigits(this.SiemensPerInch, fractionalDigits) + ` S/in`;
      case ElectricConductivityUnits.SiemensPerFoot:
        return super.truncateFractionDigits(this.SiemensPerFoot, fractionalDigits) + ` S/ft`;
      case ElectricConductivityUnits.SiemensPerCentimeter:
        return super.truncateFractionDigits(this.SiemensPerCentimeter, fractionalDigits) + ` S/cm`;
      case ElectricConductivityUnits.MicrosiemensPerCentimeter:
        return super.truncateFractionDigits(this.MicrosiemensPerCentimeter, fractionalDigits) + ` μS/cm`;
      case ElectricConductivityUnits.MillisiemensPerCentimeter:
        return super.truncateFractionDigits(this.MillisiemensPerCentimeter, fractionalDigits) + ` mS/cm`;
      default:
        break;
    }
    return this.value.toString();
  }
  /**
   * Get ElectricConductivity unit abbreviation.
   * Note! the default abbreviation for ElectricConductivity is SiemensPerMeter.
   * To specify the unit abbreviation set the 'unitAbbreviation' parameter.
   * @param unitAbbreviation The unit abbreviation of the ElectricConductivity.
   * @returns The abbreviation string of ElectricConductivity.
   */
  getUnitAbbreviation(unitAbbreviation = ElectricConductivityUnits.SiemensPerMeter) {
    switch (unitAbbreviation) {
      case ElectricConductivityUnits.SiemensPerMeter:
        return `S/m`;
      case ElectricConductivityUnits.SiemensPerInch:
        return `S/in`;
      case ElectricConductivityUnits.SiemensPerFoot:
        return `S/ft`;
      case ElectricConductivityUnits.SiemensPerCentimeter:
        return `S/cm`;
      case ElectricConductivityUnits.MicrosiemensPerCentimeter:
        return `μS/cm`;
      case ElectricConductivityUnits.MillisiemensPerCentimeter:
        return `mS/cm`;
      default:
        break;
    }
    return '';
  }
  /**
   * Check if the given ElectricConductivity are equals to the current ElectricConductivity.
   * @param electricConductivity The other ElectricConductivity.
   * @returns True if the given ElectricConductivity are equal to the current ElectricConductivity.
   */
  equals(electricConductivity) {
    return super.internalEquals(this.value, electricConductivity.BaseValue);
  }
  /**
   * Compare the given ElectricConductivity against the current ElectricConductivity.
   * @param electricConductivity The other ElectricConductivity.
   * @returns 0 if they are equal, -1 if the current ElectricConductivity is less then other, 1 if the current ElectricConductivity is greater then other.
   */
  compareTo(electricConductivity) {
    return super.internalCompareTo(this.value, electricConductivity.BaseValue);
  }
  /**
   * Add the given ElectricConductivity with the current ElectricConductivity.
   * @param electricConductivity The other ElectricConductivity.
   * @returns A new ElectricConductivity instance with the results.
   */
  add(electricConductivity) {
    return new ElectricConductivity(super.internalAdd(this.value, electricConductivity.BaseValue));
  }
  /**
   * Subtract the given ElectricConductivity with the current ElectricConductivity.
   * @param electricConductivity The other ElectricConductivity.
   * @returns A new ElectricConductivity instance with the results.
   */
  subtract(electricConductivity) {
    return new ElectricConductivity(super.internalSubtract(this.value, electricConductivity.BaseValue));
  }
  /**
   * Multiply the given ElectricConductivity with the current ElectricConductivity.
   * @param electricConductivity The other ElectricConductivity.
   * @returns A new ElectricConductivity instance with the results.
   */
  multiply(electricConductivity) {
    return new ElectricConductivity(super.internalMultiply(this.value, electricConductivity.BaseValue));
  }
  /**
   * Divide the given ElectricConductivity with the current ElectricConductivity.
   * @param electricConductivity The other ElectricConductivity.
   * @returns A new ElectricConductivity instance with the results.
   */
  divide(electricConductivity) {
    return new ElectricConductivity(super.internalDivide(this.value, electricConductivity.BaseValue));
  }
  /**
   * Modulo the given ElectricConductivity with the current ElectricConductivity.
   * @param electricConductivity The other ElectricConductivity.
   * @returns A new ElectricConductivity instance with the results.
   */
  modulo(electricConductivity) {
    return new ElectricConductivity(super.internalModulo(this.value, electricConductivity.BaseValue));
  }
  /**
   * Pow the given ElectricConductivity with the current ElectricConductivity.
   * @param electricConductivity The other ElectricConductivity.
   * @returns A new ElectricConductivity instance with the results.
   */
  pow(electricConductivity) {
    return new ElectricConductivity(super.internalPow(this.value, electricConductivity.BaseValue));
  }
}
exports.ElectricConductivity = ElectricConductivity;